export interface CheckEngagmentsLanguageModel {
    
   
    titulo: string 
    nombreDocumento: string 
    buscar: string
    
}

export const CheckEngagmentsLanguageEnglish: CheckEngagmentsLanguageModel ={
    
    
    titulo: 'Completed Compromises',
    nombreDocumento: 'Completed',
    buscar: 'Search',

   
}


export const CheckEngagmentsLanguageSpanish: CheckEngagmentsLanguageModel ={
    
    
    titulo: 'Cumplimiento de Compromisos',
    nombreDocumento: 'Cumplimientos',
    buscar: 'Buscar',
    

}