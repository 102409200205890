export interface AuthorizationDictumWarrantyLanguage {
    loadingDictum: string;
    engagments: string;
    createCompromises: string;
    fulfillments: string;
    currentCompromises: string;
    folio: string;
    type: string;
    conditions: string;
    errorLoadCompromises: string;
    dictum: string;
    label1: string;
    label2: string;
    label3: string;
    authorize: string;
    authorizing: string;
    reject: string;
    return: string;
    rejecting: string;
    errorLoadDictum: string;
    reintent: string;
    areYouSureReject: string;
    cancel: string;
    authorizeLoan: string;
    areYouSureAuthorize: string;
    writeObservation: string;
    autorizeLoanAmount: string;
    autorizeLoanPercent: string;
    authorized: string;
    observations: string;
    selectReason: string;
    seleactAReason: string;
    sorryReason: string;
    reasons: string;
    createEngagments: string;
    errSustitution: string;
    errAutorizacion: string;
    msgErrAutorizacion: string;
    close: string;
}

export const AuthorizationDictumWarrantyLanguageEnglish: AuthorizationDictumWarrantyLanguage = {
    loadingDictum: 'Loading Opinion of the Loan ',
    engagments: 'Compromises',
    createCompromises: 'Create Compromise',
    fulfillments: 'fulfillment of the commitment',
    currentCompromises: 'Current Compromises',
    folio: 'Compromise Folio',
    type: 'Type',
    conditions: 'Terms',
    errorLoadCompromises: 'Sorry something happened while loading commits, please check your connection and try again.',
    dictum: 'Dictum',
    label1: 'The Principal',
    label2: 'with Folio number',
    label3: 'is',
    authorize: 'Authorize',
    authorizing: 'Authorizing',
    reject: 'Reject',
    return: 'Return to Loans Review ',
    rejecting: 'Rejecting',
    errorLoadDictum: 'We\'re sorry, something has happened while loading the Dictum, check your connection and try again.',
    reintent: 'Retry',
    areYouSureReject: 'Are you sure you want to Reject this Loan?',
    cancel: 'Cancel',
    authorizeLoan: 'Authorize Loan',
    areYouSureAuthorize: 'Are you sure you want to Authorize this Loan?',
    writeObservation: 'Write an observation:',
    autorizeLoanAmount: 'A Loan cannot be authorized without first having authorized the amount.',
    autorizeLoanPercent: 'A Loan cannot be authorized without first having authorized the Weighting percentage.',
    authorized: 'Authorized',
    observations: 'Observations',
    selectReason: 'Select Rejection Reasons:',
    seleactAReason: 'Select at least one reason for rejection, please, to be able to save them',
    sorryReason: 'Sorry something happened loading the Reasons list, please check your connection and try again.',
    reasons: 'Rejection Reasons',
    createEngagments: 'Create a Engagment',
    errSustitution: 'Substitution failed',
    errAutorizacion: 'Authorization',
    msgErrAutorizacion: 'It does not have the authority to authorize this weighting percentage',
    close: 'Close'


}
export const AuthorizationDictumWarrantyLanguageSpanish: AuthorizationDictumWarrantyLanguage = {
    loadingDictum: 'Cargando Dictamen de La Garantía',
    engagments: 'Compromisos',
    createCompromises: 'Crear Compromiso',
    fulfillments: 'Cumplimientos del compromiso',
    currentCompromises: 'Compromisos Vigentes',
    folio: 'Folio Compromiso',
    type: 'Tipo',
    conditions: 'Condiciones',
    errorLoadCompromises: 'Lo sentimos algo, ha ocurrido al cargar compromisos, revise su conexión e intente de nuevo.',
    dictum: 'Dictamen',
    label1: 'La Garantía',
    label2: 'con el número de Folio',
    label3: 'esta',
    authorize: 'Autorizar',
    authorizing: 'Autorizando',
    reject: 'Rechazar',
    return: 'Regresar a Revisión de Garantías',
    rejecting: 'Rejecting',
    errorLoadDictum: 'Lo sentimos algo, ha ocurrido al cargar el Dictamen de La Garantía, revise su conexión e intente de nuevo.',
    reintent: 'Reintentar',
    areYouSureReject: '¿Estás Seguro de que quieres Rechazar esta Garantía?',
    cancel: 'Cancelar',
    authorizeLoan: 'Autorizar Garantía',
    areYouSureAuthorize: '¿Estás Seguro de que quieres Autorizar esta Garantía?',
    writeObservation: 'Escribe una observación:',
    autorizeLoanAmount: 'No se puede autorizar una Garantía sin antes haber autorizado el monto.',
    autorizeLoanPercent: 'No se puede autorizar una Garantía sin antes haber autorizado el porcentaje de ponderación.',
    authorized: 'Autorizado',
    observations: 'Observaciones',
    selectReason: 'Selecciona las Razones de Rechazo:',
    seleactAReason: 'Selecciona al menos un motivo de rechazo, por favor, para poder guardarlos',
    sorryReason: 'Lo sentimos algo ha ocurrido al cargar la lista de Motivos, revise su conexión e intente de nuevo.',
    reasons: 'Razones de rechazo',
    createEngagments: 'Crear un compromiso',
    errSustitution: 'Error en la sustitución',
    errAutorizacion: 'Autorización ',
    msgErrAutorizacion:'No tiene facultades para autorizar este porcentaje de ponderación',
    close: 'Cerrar'



}
