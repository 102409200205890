import { Component } from 'react';
import './HistoryEngagements.scss';
import {
    Button,
    Modal
} from "@lmig/lmds-react";
import TableHistoryEngagements from '../table-history-engagements/TableHistoryEngagements';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from "../../../Store";
import { connect } from "react-redux";



interface Historyprops {
    idCompromiso: number;
    language: DefaultStateI

}




class HistoryEngagements extends Component<Historyprops, ExpedientState> {

    public idCompromiso= 0;

    constructor(props: any) {
        super(props);
        this.idCompromiso = this.props.idCompromiso
        this.state = {
            openModalClose: false,
        }
    }

    render(): JSX.Element {

        return (
            <div className={"expedient-warranty"}>

                <a className="open-expedient text-center" onClick={() => {
                    this.setState({ openModalClose: true })
                }}>
                    
                    <Button variant="primary"
                        className="float-right w-100">
                        {this.props.language.language?.createEngagement.historial}
                    </Button>
                      
                </a>

                <Modal
                    isOpen={this.state.openModalClose}
                    size="large"
                    modalStyle="standard"
                    title= {this.props.language.language?.createEngagement.historialTituloModal2}
                    closeFunc={() => {
                        this.setState({ openModalClose: false })
                    }}>


                    <div className="row ">
                        <div className="col-12">
                            <TableHistoryEngagements idCompromiso={this.idCompromiso}  />
                        </div>
                    </div>

                </Modal>
            </div>
        );

    }

}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(HistoryEngagements);



interface ExpedientState {
    openModalClose?: boolean;

}
