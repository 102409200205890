
export enum estadoVigenciaGarantia {
    enCaptura = 1,
    enviada = 2,
    enRevision = 6,
    autorizada = 3,
    rechazada = 4,
    vencida = 5,
    sustituida = 7,
    baja = 8
}
