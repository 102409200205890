import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {LineaAfinzamiento} from "../../../models/garantias/LineaAfinzamiento";
import './DeleteLineaAfianzamiento.scss';
import {Button} from "@lmig/lmds-react";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
interface DeleteLineaAfianzamientoProps {
    deleteLineaAfianzamiento: LineaAfinzamiento;
    deletedLinea: (lineaAfinzamiento: LineaAfinzamiento) => void;
    closeModal: () => void;
}
export const DeleteLineaAfianzamiento: React.FC<DeleteLineaAfianzamientoProps> = ({deleteLineaAfianzamiento, deletedLinea, closeModal}:DeleteLineaAfianzamientoProps)=> {

    const languageState = useSelector((state: RootStore) => state.language.language?.lineaCreditoLanguage);

    const [deletingLinea, setDeletingLinea] = useState<boolean>(false);
    
    const onClickDeleteLinea = async (deleteLineaAfianzamiento: LineaAfinzamiento) => {
        setDeletingLinea(true);
        try {
            const res = await garantiasService.deleteLineaAfianzamiento(deleteLineaAfianzamiento.idLineaAf!);
            setDeletingLinea(false);
            deletedLinea(deleteLineaAfianzamiento);

        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setDeletingLinea(false);
            }
        }
    }

    useEffect(() => {

    },[deleteLineaAfianzamiento]);

    return (
        <>
            <div id="delete-linea-afianzamiento" className="">
                <h5 className="text-center">
                    {languageState?.areYouSureDelete}&nbsp;
                    {deleteLineaAfianzamiento.folio}?
                </h5>
                <div className="row" id="buttons-linea">
                    <div className="col-md-6 ">
                        <Button dynamicWidth
                                onClick={() => {closeModal()}}
                                className="w-100"
                                variant="secondary">
                            {languageState?.cancel}
                        </Button>
                    </div>
                    <div className="col-md-6 ">
                        <Button dynamicWidth
                                disabled={deletingLinea? true: false}
                                className="w-100"
                                onClick={() => {onClickDeleteLinea(deleteLineaAfianzamiento)}}
                                variant="primary">
                            {(deletingLinea)?languageState?.deleting: languageState?.delete}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

