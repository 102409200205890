import React, {Component, createRef, useRef} from "react";
import { RouteComponentProps } from "react-router-dom";
import './DocumentsLoans.scss';
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { LocalStorageClass } from "../../../class/LocalStorageClass";


interface Params {
    urlPdf: string;
}

interface LocationState {
    ownerName: string;
}

interface SC {
    statusCode?: number;
}

interface PdfRenderProps {
    language: DefaultStateI
}

class DocumentsLoans extends Component<PdfRenderProps & RouteComponentProps<Params, SC, LocationState>, PdfRenderState>{
    public localStorageClass = new LocalStorageClass();

    public iframeElement = createRef<HTMLIFrameElement>();


    public divElement = createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);
        this.state = {
            urlPdf: this.props.match.params.urlPdf
        }
    }

    render() {
        return <div id="documents-loans" ref={this.divElement}>
                {/*#toolbar=0&navpanes=0&scrollbar=0*/}

                <iframe
                    ref={this.iframeElement}
                    id={"idFrame"}
                    src={`${this.state.urlPdf}#toolbar=0&navpanes=0&scrollbar=0`}
                    onContextMenu={this.contextMenu}
                    frameBorder="0"></iframe>
            </div>

    }

    componentDidMount() {

        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        const url = urlParams.get('urlPDF');
        if (url !== null) {
            this.setState({
                urlPdf: url!
            })
        }

       /* this.iframeElement.current!.oncontextmenu = function() {
            return false;
        };*/

        this.divElement.current!.addEventListener('contextmenu', function (e) {
            alert('disable')
            e.preventDefault();
        }, false);

        this.iframeElement.current!.addEventListener('contextmenu', function (e) {
            alert('disable')
            e.preventDefault();
        }, false);
    }

    contextMenu(e: any) {
    e.preventDefault();
    alert(e)
    }


}

interface PdfRenderState {
    urlPdf: string;
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(DocumentsLoans);
