import { Component } from 'react';
import './FindClient.scss';
import { Button, Modal } from "@lmig/lmds-react";
import Autocomplete from 'react-autocomplete';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { RootStore } from '../../../Store';
import { connect } from 'react-redux';
import { UserModel } from '../../../models/public/UserModel';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { Funcionarios } from '../../../models/compromisos/Funcionarios';
import { CodigoRespuesta } from '../../../models/compromisos/CodigoRespuesta';
import { DefaultStateI } from '../../../reducer/LanguageReducer';



class FindFuncionario extends Component<FindProps, FindState> {


    public idFuncionario: number;
    public idCompromiso: number;
    public busquedas: string;

    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        this.idFuncionario = 0;
        this.idCompromiso = this.props.idCompromiso;
        this.busquedas = '';


        const PERFIL = this.localStorageClass.getProfile();


        this.state = {
            idFuncionarioSelecccionado: 0,
            openModalClose: false,
            notificacion: {
                codigo: 0,
                descripcion: ''
            },
            listFuncionarios: [],
            val: '',
            PERFIL: PERFIL,

        }
        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
        this.handleOnMenu = this.handleOnMenu.bind(this)
        this.handleSend = this.handleSend.bind(this)
    }

    componentDidMount() {

        console.log("ID COMPROMISO: " + this.idCompromiso)
        this.getFuncionarios('a', this.idCompromiso);
    }


    render(): JSX.Element {


        return (


            <div className={"expedient-warranty"}>


                <a className="open-expedient text-center" onClick={() => {
                    this.setState({ openModalClose: true })
                }}>

                    <Button variant="primary"
                        className="float-right w-100">
                        {this.props.language.language?.findClient.continuar}
                    </Button>
                </a>

                <Modal
                    isOpen={this.state.openModalClose}
                    size="large"
                    modalStyle="standard"
                    title={this.props.language.language?.findClient.seleccionaF}
                    className="modalF"
                    closeFunc={() => {
                        this.setState({ openModalClose: false })
                    }}>

                    <h3> {this.props.language.language?.findClient.leyenda}
                        <br />  {this.props.language.language?.findClient.leyenda2}<br /> <br />
                    </h3>

                    <div className="autocomplete-wrapper-cliente">
                        <Autocomplete
                            value={this.state.val}
                            items={this.state.listFuncionarios}
                            getItemValue={item => item.nombre + item.idFuncionario}
                            shouldItemRender={this.renderClientName}
                            renderMenu={item => (
                                <div className="dropdown">
                                    {item}
                                </div>
                            )}
                            renderItem={(item, isHighlighted) =>
                                <div className={`item ${isHighlighted ? 'selected-item' : 'title'}`} >
                                    {`${item.nombre}`}
                                </div>
                            }

                            onMenuVisibilityChange={this.handleOnMenu}
                            onChange={(event, val) => this.setState({ val })}
                            //onSelect={val => this.setState({ val }) }  
                            onSelect={this.handleInputChangeSelect}
                        />
                    </div>

                    <br />
                    <br />

                    <div className="select-fiados">
                        {this.state.idFuncionarioSelecccionado == 0 ? (
                            <Button variant="primary" disabled>
                                {this.props.language.language?.findClient.sent}
                            </Button>

                        ) : <Button href="/compromisos"
                            variant="primary" onClick={this.handleSend}>
                            {this.props.language.language?.findClient.sent}
                        </Button>}
                    </div>
                </Modal>
            </div>
        );

    }

    async getFuncionarios(busqueda: string, idCompromiso: number): Promise<void> {


        const res = await engagementService.getFuncionarios(busqueda, idCompromiso);
        this.setState({ listFuncionarios: res.data });
        //console.log("Se encuentran Funcionarios con el Compromiso: " +  this.props.idCompromiso)
        //console.log(res.data) 

        this.clientData();
    }


    async postNotificaFuncionario(idCompromiso: number, idFuncionario: number): Promise<void> {

        const res = await engagementService.postNotificaFuncionario(idCompromiso, idFuncionario);
        this.setState({ notificacion: res.data })

        if (this.state.notificacion.codigo == 1) {

            console.log("Se envió correctamente la notificacion al funcionario")
        } else {

            console.log("Algo ocurrió en la notificacion")
        }

    }


    renderClientName(state: { nombre: string, rfc: string }, val: string) {

        const nombreCompleto = state.nombre + '--' + state.rfc

        return (

            (nombreCompleto.toLowerCase().indexOf(val.toLowerCase()) !== -1)

        );
    }

    clientData() {
        let listaClientesAuxiliar: Funcionarios[];
        listaClientesAuxiliar = []

        for (const condition of this.state.listFuncionarios) {

            listaClientesAuxiliar.push({ idFuncionario: condition.idFuncionario, nombre: condition.nombre })

        }
        this.setState({ listFuncionarios: listaClientesAuxiliar })


        return [
            this.state.listFuncionarios,
            //console.log("Se obtiene la lista de Funcionarios")

        ]
    }

    handleInputChangeSelect(value: string, item: Funcionarios): void {

        console.log("ID FUNCIONARIO: " + item.idFuncionario)
        console.log("NOMBRE FUNCIONARIO: " + item.nombre)
        //console.log("VALUE: " + value)

        if (item.idFuncionario != 0) {
            this.setState({ val: item.nombre })
            this.idFuncionario = item.idFuncionario;
            this.setState({ idFuncionarioSelecccionado: this.idFuncionario })

        } else {
            this.setState({ idFuncionarioSelecccionado: 0 })
        }
        //this.postNotificaFuncionario(this.idCompromiso, this.idFuncionario.toString())
    }

    handleSend(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void {
        console.log("Funcionario: " + this.state.idFuncionarioSelecccionado)

        if (this.state.idFuncionarioSelecccionado != 0) {
            this.postNotificaFuncionario(this.idCompromiso, this.idFuncionario)
        } else {
            console.log("No has seleccionado un funcionario")
        }
    }

    handleOnMenu(isOpen: boolean): void {

        if (isOpen == true) {
            this.setState({ idFuncionarioSelecccionado: 0 })
            //console.log("Entro al menu")
            this.getFuncionarios('a', this.idCompromiso);
        } else {
            this.setState({ idFuncionarioSelecccionado: 0 })
        }
    }

}
const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(FindFuncionario);


interface FindProps {

    idCompromiso: number;
    language: DefaultStateI;
}

interface IListState {
    //cliente: Cliente[],

}

interface FindState {

    listFuncionarios: Funcionarios[],
    openModalClose?: boolean,
    val: string,
    PERFIL: UserModel;
    notificacion: CodigoRespuesta;
    idFuncionarioSelecccionado: number;


}



