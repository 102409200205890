import React, {} from "react";
import { useHistory} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import './BreadCumb.scss';
export interface BreadCumbListModel {
    title: string,
    url: string
}
interface BreadCumbProps {
    title: string
    listBreadcumbs?: BreadCumbListModel[]
}
export const BreadCumb: React.FC<BreadCumbProps> = ({ title, listBreadcumbs}:BreadCumbProps) => {
    const history = useHistory();

    const redirect = (url: string) => {
        history.push(url)
    }

    return <>
        <div className="row" id="bread-cumb">
            <div className="col-md-12">
                <h3 className="title-seccion">
                    {title}<br/>
                </h3>
                {
                    listBreadcumbs? (
                    <Breadcrumb>
                        {// @ts-ignore
                            listBreadcumbs?.map((i, index) =>
                                (
                                    <Breadcrumb.Item key={index}>
                                        <span onClick={() => {
                                            redirect(i.url)
                                        }}>
                                            {i.title}
                                        </span>

                                    </Breadcrumb.Item>


                                ))
                        }

                    </Breadcrumb>
                ): null
                }

            </div>
        </div>
    </>;
};

