import React, {useEffect, useState} from 'react';
import './StatusRowPrincipal.scss';
import {ButtonGroup, Dropdown} from "react-bootstrap";
import { IconSeeMore, Modal} from "@lmig/lmds-react";
import {Link, useHistory} from "react-router-dom";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {openNewWindow, validRoles} from "../../../class/CommonClass";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AxiosError} from "axios";
import {PillButton} from "@lmig/lmds-react-pill-button";
import {RolResumenEnum} from "../../../models/garantias/RolResumenEnum";
import {LockPrincipal} from "../lock-principal/LockPrincipal";
import {UnlockPrincipal} from "../unlock-principal/UnlockPrincipal";
import {AddObligor} from "../add-obligor/AddObligor";
import {ModalExpedienteIdentification} from "../modal-expediente-identification/ModalExpedienteIdentification";
import * as apiService from "../../../services/api-gral/ApiGralService";
import {LockCommissionPrincipal} from "../lock-commission-principal/LockCommissionPrincipal";
import {UnLockCommissionPrincipal} from "../unlock-commission-principal/UnLockCommissionPrincipal";
import {ModalDeleteOs} from "../modal-delete-os/ModalDeleteOs";
import {LOCK, LOCKCOMMISSION, UNLOCK, UNLOCKCOMMISSION} from "../../../actions/FiadoActionTypes";
import {dispatchAddOsPrincipal} from "../../../actions/FiadoActions";
import {ButtonStatusWarranty} from "../button-status-warranty/ButtonStatusWarranty";
import {estadoVigenciaGarantia} from "../../../models/garantias/EstadoVigenciaGarantia";

interface StatusRowPrincipalProps {
    idFiado: number
    idCliente: number;
    name: string;
    rol: RolResumenEnum;
    estadoBloqueo: boolean;
    estadoBloqueoComision: boolean
    returnDeleteOS: () => void;


}

export const StatusRowPrincipal: React.FC<StatusRowPrincipalProps> = ({idFiado,
                                                                          idCliente,
                                                                          name,
                                                                          rol,
                                                                          estadoBloqueo,
                                                                          estadoBloqueoComision,
                                                                          returnDeleteOS }:StatusRowPrincipalProps)=> {

    const languageState = useSelector((state: RootStore) => state.language.language?.statusRowPrincipal);

    const fiadoAction = useSelector((state: RootStore) => state.fiadoAction.fiadoAction);

    const history = useHistory();

    const dispatch = useDispatch();

    const  localStorageClass = new LocalStorageClass();

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [showModalLock, setShowModalLock] = useState<boolean>(false);

    const [showModalUnLock, setShowModalUnLock] = useState<boolean>(false);

    const [showModalLockCommission, setShowModalLockCommission] = useState<boolean>(false);

    const [showModalUnLockCommission, setShowModalUnLockCommission] = useState<boolean>(false);

    const [showModalDeleteOS, setShowModalDeleteOS] = useState<boolean>(false);

    const [openModalObligor, setOpenModalObligor] = useState<boolean>(false);

    const [openModalExpediente, setOpenModalExpediente] = useState<boolean>(false);

    const [lockClient, setLockClient] = useState<boolean>(false);

    const [lockCommissionClient, setLockCommissionClient] = useState<boolean>(false);

    const getClientInverview = async (idPrincipal: number) => {
       // setLoadingInterview(true);
       // setErrorInterview(false)
        try {
            const res = await apiService.getObtenerEntrevista(idPrincipal);
         //   setInterview(res.data);
          //  setLoadingInterview(false);
            openNewWindow(res.data.url!);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
            //    setLoadingInterview(false);
             //   setErrorInterview(false);
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    useEffect(() => {
        setLockClient(estadoBloqueo);
        setLockCommissionClient(estadoBloqueoComision)
        updateFiadoAction();
    },[ fiadoAction?.action, estadoBloqueo, estadoBloqueoComision]);

    const updateFiadoAction = () => {

        switch (fiadoAction?.action) {
            case LOCK:
                if (fiadoAction?.idFiado == idCliente){
                    setLockClient(true);
                }
                break;
            case UNLOCK:
                if (fiadoAction?.idFiado == idCliente){
                    setLockClient(false);
                }
                break;
            case LOCKCOMMISSION:
                if (fiadoAction?.idFiado == idCliente){
                    setLockCommissionClient(true);
                }
                break;
            case UNLOCKCOMMISSION:
                console.log('id cliente '+ idCliente + ' === ' + JSON.stringify(fiadoAction) )
                if (fiadoAction?.idFiado == idCliente){
                    setLockCommissionClient(false);
                }
                break;

        }

    }



    return (
        <>

            <Dropdown as={ButtonGroup} id="status-row-principal">
                {((validRoles(PERFIL.roles, localStorageClass.AGENTE)  ||
                    validRoles(PERFIL.roles, localStorageClass.PROMOTOR) ||
                    validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO)  ||
                    validRoles(PERFIL.roles, localStorageClass.AUXILIAR_JURIDICO)
                )) ?
                        null
                    :
                    <Link to={'/garantias/crear-garantia/fiado/'+ idCliente}>
                        <PillButton variant="primary" size="small">
                            {languageState?.createWarranty}
                        </PillButton>
                    </Link>
                }


                <Dropdown.Toggle split variant="light" id="dropdown-split-basic" >
                    <IconSeeMore></IconSeeMore>
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    {(rol == RolResumenEnum.obligadoSolidario) &&
                        <Dropdown.Item
                            onClick={ () => {
                                history.push('/garantias/obligado-solidario/'+idCliente+'?fiado='+idFiado)
                            }}>
                            Ver
                        </Dropdown.Item>
                    }


                    {(rol == RolResumenEnum.fiado)?
                        <>
                        {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
                        validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA))?
                            <Dropdown.Item
                                onClick={ () => { setOpenModalObligor(true)}}>
                                {languageState?.addOs}
                            </Dropdown.Item>

                        : null}
                        </>:
                        null
                    }


                    <Dropdown.Item
                        className="interview"
                        onClick={ () => {
                            getClientInverview(idCliente)
                        }}>

                        {languageState?.interview!}

                    </Dropdown.Item>
                    {(validRoles(PERFIL.roles, localStorageClass.AGENTE) || validRoles(PERFIL.roles, localStorageClass.PROMOTOR)) &&
                    validRoles(PERFIL.roles, localStorageClass.AUDITOR) ?
                        <></> :
                        <Dropdown.Item
                            onClick={ () => {
                                setOpenModalExpediente(true)
                            }}>
                            {languageState?.expedientIdentification!}
                        </Dropdown.Item>
                    }


                    {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS))?
                    <>
                        {(lockCommissionClient == true)?(
                                <Dropdown.Item
                                    onClick={ () => {
                                        setShowModalUnLockCommission(true);
                                    }}>
                                    {languageState?.unlockComission!}
                                </Dropdown.Item>
                            ):
                            <Dropdown.Item
                                onClick={ () => {
                                    setShowModalLockCommission(true);
                                }}>
                                {languageState?.lockComission!}
                            </Dropdown.Item>

                        }

                        {(lockClient==true)?
                            <Dropdown.Item
                                onClick={ () => {
                                    setShowModalUnLock(true);
                                }}>
                                {(rol == RolResumenEnum.fiado)&& languageState?.unlockCliente!}
                                {(rol == RolResumenEnum.obligadoSolidario)&& languageState?.unlockOs!}

                            </Dropdown.Item>:
                            <Dropdown.Item
                                onClick={ () => {
                                    setShowModalLock(true);
                                }}>

                                {(rol == RolResumenEnum.fiado)&& languageState?.lockClient!}
                                {(rol == RolResumenEnum.obligadoSolidario)&& languageState?.lockOs!}
                            </Dropdown.Item>
                        }
                    </>:
                     null
                    }

                  {/*  {(rol == RolResumenEnum.obligadoSolidario) &&
                    validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)  ?
                        <Dropdown.Item
                            onClick={ () => {
                                setShowModalDeleteOS(true);
                            }}>
                            {languageState?.deleteOs!}

                        </Dropdown.Item>:
                        null
                    }*/}



                </Dropdown.Menu>
            </Dropdown>

            {/*Modal de agregar O.S */}
            <Modal
                isOpen={openModalObligor}
                size="medium"
                modalStyle="standard"
                title={languageState?.addOs}
                closeFunc={() => {
                    setOpenModalObligor(false);
                }}
            >
                <AddObligor
                    idClient={idFiado}
                    showModal={openModalObligor}
                    clickShowModal={show => setOpenModalObligor(show)}
                    added={() => {
                        dispatch(dispatchAddOsPrincipal(idCliente));
                    }}
                />
            </Modal>

            {/*Modal Expediente Identificacion*/}
            <ModalExpedienteIdentification
                idPrincipal={idCliente}
                name={name}
                openModal={openModalExpediente}
                closeModal={() => {setOpenModalExpediente(false)}}
            />


            {/*Modal de bloquear Fiado*/}
            <LockPrincipal
                idPrincipal={idCliente}
                namePrincipal={name}
                showModal={showModalLock}
                clickShowModal={show => setShowModalLock(show)}
            />

            {/*Modal de desbloquear Fiado*/}
            <UnlockPrincipal
                idPrincipal={idCliente}
                namePrincipal={name}
                showModal={showModalUnLock}
                clickShowModal={show => setShowModalUnLock(show)}
            />

            {/*Modal de bloquear comision Fiado*/}
            <LockCommissionPrincipal
                idPrincipal={idCliente}
                namePrincipal={name}
                showModal={showModalLockCommission}
                clickShowModal={show => setShowModalLockCommission(show)}
            />
            {/*Modal de desbloquear Comissión Fiado*/}
            <UnLockCommissionPrincipal
                idPrincipal={idCliente}
                namePrincipal={name}
                motivoBloqueo={''}
                showModal={showModalUnLockCommission}
                clickShowModal={show => setShowModalUnLockCommission(show)}
            />

            {/*Modal de eliminar un O.S*/}
            <ModalDeleteOs
                idOS={idCliente}
                nameOs={name}
                showModal={showModalDeleteOS}
                clickShowModal={show => setOpenModalObligor(show)}
                deleted={returnDeleteOS}
            />
        </>
    );
};




