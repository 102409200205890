import {

    SPECIAL_ACTIONS_FAIL,
    SPECIAL_ACTIONS_LOADING,
    SPECIAL_ACTIONS_SUCCESS, SpecialActionsDispatchTypes,
} from "../actions/SpecialActionTypes";
import {SpecialAction} from "../models/public/SpecialAction";

interface DefaultStateI {
    loading: boolean,
    specialActions?: SpecialAction[]
}

const defaultState: DefaultStateI = {
    loading: false
};

const specialActionReducer = (state: DefaultStateI = defaultState, action: SpecialActionsDispatchTypes) : DefaultStateI => {
    switch (action.type) {
        case SPECIAL_ACTIONS_FAIL:
            return {
                loading: false,
            }
        case SPECIAL_ACTIONS_LOADING:
            return {
                loading: true,
            }
        case SPECIAL_ACTIONS_SUCCESS:
            return {
                loading: false,
                specialActions: action.payload
            }
        default:
            return state
    }
};


export default specialActionReducer