import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import './DepositosGarantia.scss';
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import NavBar from "../../../components/public/nav-bar/NavBar";
import { AxiosError } from 'axios';
import {
    Alert, Badge, AlphanumericInput, Button, IconSearch,
    IconErrorAlert, IconWarning, IconSuccessAlert, Modal, Table, TableCell, TableRow, IconButton,
    IconUpload, CheckboxGroup, Checkbox, Content, IconSeeMore,
    Link as LinkLiberty,
    IconHelp, IconInfo
} from "@lmig/lmds-react";
import * as apiService from '../../../services/recibo-caja/ReciboCajaService';
import { DepositoModel } from "../../../models/tesoreria/DepositoModel";
import { ReferenciaModel } from "../../../models/tesoreria/ReferenciaModel";
import { EstadisticasModel } from "../../../models/tesoreria/EstadisticasModel";
import { PaginacionModel } from "../../../models/public/PaginacionModel";
import { Dropdown, Card, CardDeck, Spinner } from 'react-bootstrap';
import { formatCurrency } from "../../../class/CommonClass";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import { Document, Page, pdfjs } from "react-pdf";
import { InputChange, maxDate } from "../../../class/CommonClass";
import { NegativeAlert } from "../../../components/public/negative-alert/NegativeAlert";
import { FiltroEnum } from "../../../models/tesoreria/FiltroEnum";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import ReactPaginate from 'react-paginate';
import { UserModel } from "../../../models/public/UserModel";
import { InfoUserModel } from "../../../models/tesoreria/InfoUserModel";
import environment from "../../../environments/environment";



interface DepositosGarantiaProps {
    language: DefaultStateI
}

class DepositosGarantia extends Component<DepositosGarantiaProps & RouteComponentProps, DepositosGarantiaState>{
    public localStorageClass = new LocalStorageClass();
    constructor(props: any) {
        super(props);

        document.title = "Depositos de Garantías"
        const PERFIL = this.localStorageClass.getProfile();
        this.state = {
            modalRecibo: false,
            modalCarga: false,
            modalPago: false,
            modalError: false,
            formData: new FormData(),
            listaDepositos: [],
            nombreArchivo: '',
            alertFile: false,
            listaReferencias: [],
            disEnviar: true,
            //textAlertt: '',
            listPaginacion: [],
            numPaginas: 1,
            currentPage: 1,
            numTotalDepositos: 0,
            numRegistrosMax: 10,
            numRegistrosMin: 1,
            //showTable: true,
            //showLote: '',
            reciboBase64: '',
            nombreRecibo: 'Recibo',
            estatusRecibo: 0,
            queryin: '',
            deposito: {
                fiado: '',
                estatus: 0,
                fecha: '',
                nombre: '',
                aPaterno: '',
                aMaterno: '',
                fianza: '',
                idFiado: 0,
                idReferencia: '',
                importe: 0,
                idDepositoGarantias: 0,
                idDepositoPrendaria: 0,
                descripcion: '',
                correo: '',
                firmado: 0,
                fechaRegistro: '',
                usuario: ''
            },
            modalAutorizarPago: false,
            referenciaPago: '',
            listIdPrendaria: [],
            modalExito: false,
            loadingReferencias: false,
            loadingRecibo: false,
            loadingDetalle: false,
            loadingPostFile: false,
            checkboxPago: false,
            valueSearch: '',
            textExito: '',
            textError: '',
            urlFirmaPAgo: '',
            disFirmar: true,
            fechaInicial: '',
            fechaFinal: '',
            filterSearch: {
                refGeneradas: false,
                depositosRealizados: false,
                coinciliados: false
            },
            filterSearchValue: [],
            msgFechaI: '',
            msgFechaF: '',
            listStatistics: [],
            loadingStatistics: false,
            listProspecto: [],
            checkboxProspecto: false,
            checkboxInversion: false,
            modalCambioEstatus: false,
            valueSearchPago: '',
            infoUser: {
                nombre: 'string;',
                aPaterno: '',
                aMaterno: '',
                correo: '',
            },
            correoFiado: '',
            rgbStatistics: 'rgba(6, 116, 140, 0.5)',
            idPrendaria: 0,
            PERFIL: PERFIL,
            coorY: 0,
            coorX: 0,
            paramIdPrendaria: '',
        }



    }

    handleFileDrop = (e: any) => {
        e.preventDefault();
        const formDataAux = this.state.formData;
        const file = e.dataTransfer.files[0];
        if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            formDataAux.append('excel', e.dataTransfer.files[0]);
            this.setState({ formData: formDataAux, nombreArchivo: file.name, disEnviar: false, alertFile: false })
        } else {
            this.setState({ alertFile: true, disEnviar: true });
        }

    }

    handleFileChange = (e: any) => {
        const formDataAux = this.state.formData;
        const cadena = e.target.value.split('\\');
        formDataAux.append('excel', e.target.files![0]);
        this.setState({ formData: formDataAux, nombreArchivo: cadena[cadena.length - 1], disEnviar: false });
        e.target.value = '';
    }

    handlePaginator = (opcion: number) => {
        let pag: number = this.state.currentPage;

        if (opcion === 1) {
            pag = this.state.currentPage + 1;
            this.setState({ currentPage: pag });
        } else {
            pag = this.state.currentPage - 1;
            this.setState({ currentPage: pag });
        }

        this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, pag, this.state.queryin, 10, 0);
    }
    openWindow = (url: string) => {
        window.open(url, '_blank');
        this.setState({ modalRecibo: false, reciboBase64: '' });
        //window.open(url, '_self');
    }


    openModalCarga = (e: any) => {
        e.preventDefault();
        this.setState({ modalCarga: true, disEnviar: true, nombreArchivo: '', formData: new FormData() })
    }

    openModalRecibo = async (depositoP: DepositoModel, e: any) => {
        e.preventDefault();

        this.setState({ loadingRecibo: true, modalRecibo: true, referenciaPago: depositoP.idReferencia, deposito: depositoP, paramIdPrendaria: '' + depositoP.idDepositoPrendaria })
        if (depositoP.firmado === 0) {
            await this.getRecibo(depositoP.idDepositoGarantias);
        } else {
            await this.getInfoDocumento(depositoP.firmado);
            await this.getReciboFirmado(depositoP.firmado);
        }



    }
    openModalPago = async (depositoP: DepositoModel, e: any) => {
        e.preventDefault();

        await this.setState({ referenciaPago: depositoP.idReferencia, valueSearchPago: '', listIdPrendaria: [] })
        this.getReferencias('')
        this.setState({ modalPago: true, deposito: depositoP })


    }

    openModalCambioEstatus = (e: any) => {
        e.preventDefault();
        this.setState({ modalCambioEstatus: true })
    }

    formatearFecha = (fecha: string) => {
        if (fecha != null) {
            const date = new Date(fecha)
            let dd = String(date.getDate() + 1).padStart(2, '0');
            let mm = String(date.getMonth() + 1).padStart(2, '0');
            let yyyy = date.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        } else return '';
    }

    formatearFechaBusqueda = (fecha: string) => {
        if (fecha !== '') {
            const date = new Date(fecha)
            let dd = String(date.getDate() + 1).padStart(2, '0');
            let mm = String(date.getMonth() + 1).padStart(2, '0');
            let yyyy = date.getFullYear();
            return dd + '/' + mm + '/' + yyyy;
        } else return '';
    }


    render() {

        return <>
            <NavBar />
            <Content id="depositos-garantia" className="container-fluid" fullWidth={false} >
                {/* <div className="row">
                    <div className="col-md-12 mt-12">
                        <h2 id={"title-seccion"}>
                            {this.props.language.language?.depositosGarantias.titlePage} <br /><br />
                        </h2>
                    </div>
                </div>*/}

                <div className="row">
                    <div className="col-md-12">
                        <h2 className="title-seccion mt-2" >
                            {this.props.language.language?.depositosGarantias.titlePage} <br /><br />
                        </h2>
                    </div>
                </div>
                {this.state.loadingStatistics ? (
                    <div className="col-12">
                        <SpinnerLoading text={this.props.language.language?.depositosGarantias.loadingStatics} />
                    </div>
                ) :
                    <CardDeck className="cards-percentage">
                        {this.state.listStatistics.map((i, index) =>
                        (
                            <Card key={i.idEstadistica}>
                                <Card.Body>
                                    <Card.Title>{i.descripcion}</Card.Title>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <CircularProgressbar
                                                value={i.porcentaje}
                                                text={`${i.porcentaje}%`}
                                                styles={buildStyles({
                                                    // Rotation of path and trail, in number of turns (0-1)
                                                    rotation: 0.25,
                                                    // Text size
                                                    textSize: '16px',
                                                    // How long animation takes to go from one percentage to another, in seconds
                                                    pathTransitionDuration: 0.5,
                                                    // Colors
                                                    pathColor: `${this.state.rgbStatistics}`,
                                                    textColor: '#06748C',

                                                })} />
                                        </div>
                                        <div className="col-md-6 ">
                                            <Card.Text>

                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                                <Card.Footer className="text-right bg-white">
                                    <LinkLiberty
                                        className={"h6"} variant="standalone" onClick={(e) => this.handleCheckBoxChangeFiltro(e, i.idCategoria.toString())}>
                                        {this.props.language.language?.depositosGarantias.chkWatch}
                                    </LinkLiberty>
                                </Card.Footer>
                            </Card>
                        ))
                        }
                    </CardDeck>
                }
                {/*<EjemploTesoreria />*/}
                <div className="seccion-botons">
                    <div className="row">
                        <div className="col-md-2 col-lg-2">
                            <input
                                type="date"
                                name="fechaInicial"
                                id="fechaInicial"
                                value={this.state.fechaInicial}
                                className='input-date input'
                                onChange={(e) => this.handleInputChangeSelect(e)}
                                onMouseLeave={(e: React.MouseEvent<any>) => this.validFechaInicial()}
                                onSelect={event => this.validFechaInicial()}
                                onBlur={(event: React.FocusEvent<any>) => this.validFechaInicial()}
                            />
                            <div className="mt 2">
                                <NegativeAlert textAlert={this.state.msgFechaI!} /></div>
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <input
                                type="date"
                                name="fechaFinal"
                                id="fechaFinal"
                                value={this.state.fechaFinal}
                                className='input-date input'
                                onChange={(e) => this.handleInputChangeSelect(e)}
                                onMouseLeave={(e: React.MouseEvent<any>) => this.validFechaFinal()}
                                onSelect={event => this.validFechaFinal()}
                                onBlur={(event: React.FocusEvent<any>) => this.validFechaFinal()}

                            /><div className="mt-2">
                                <NegativeAlert textAlert={this.state.msgFechaF!} /><br />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <AlphanumericInput
                                type='text'
                                onChange={(e) => this.buscar(e.target.value)}
                                className='input float-right w-100'
                                value={this.state.valueSearch}
                                labelVisual={
                                    <div id='input-buscar'>
                                        <IconSearch size={'16'}></IconSearch>
                                        {this.props.language.language?.depositosGarantias.searchBar}
                                    </div>}
                            >
                            </AlphanumericInput>
                        </div>
                        <div className="">
                            <div className="col-md-12 col-lg-12 mt-2">
                                {this.state.checkboxProspecto || this.state.checkboxInversion ?
                                    <Button
                                        variant='primary'
                                        size='small'
                                        type='button'
                                        className="float-right w-100"
                                        onClick={(e) => this.openModalCambioEstatus(e)}
                                    >{!this.state.checkboxProspecto ? "Solicitar" : "Invertir     "}</Button> :
                                    <Button
                                        variant='primary'
                                        size='small'
                                        type='button'
                                        className="float-right w-100"
                                        onClick={(e) => this.openModalCarga(e)}
                                    >+ {this.props.language.language?.depositosGarantias.btnUpload}</Button>}
                            </div>
                            {//descarga del archivo se deja para la 2da version
                                /* <div className="col-md-5 col-lg-5 mt-2">
                                    
                                   <Button
                                        variant='primary'
                                        size='small'
                                        type='button'
                                        className=""
                                        onClick={() => this.getReporte()}
                                    >Descargar</Button>
                                </div>*/
                            }
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12 mt-2">
                        <CheckboxGroup name="filtro-check" >
                            <div className={"row"}>
                                <div className={"col-4"}>
                                    <Checkbox
                                        onChange={(e) => this.handleCheckBoxChangeFiltro(e, 'refGeneradas')}
                                        name={FiltroEnum.refGeneradas}
                                        checked={this.state.filterSearch.refGeneradas}
                                        labelVisual={this.props.language.language?.depositosGarantias.chkReferencesG} />
                                </div> <div className="col-2 "></div>
                                <div className={"col-2"}>
                                    <Checkbox
                                        onChange={(e) => this.handleCheckBoxChangeFiltro(e, 'depositosRealizados')}
                                        name={FiltroEnum.depositosRealizados}
                                        checked={this.state.filterSearch.depositosRealizados}
                                        labelVisual={this.props.language.language?.depositosGarantias.chkUnreconciled} />
                                </div>
                                <div className="col-3 "></div>
                                <div className="col-1 container-right">
                                    <div className="row container-right">
                                        <Checkbox
                                            onChange={(e) => this.handleCheckBoxChangeFiltro(e, 'conciliados')}
                                            name={FiltroEnum.conciliados}
                                            className="container-right"
                                            checked={this.state.filterSearch.coinciliados}
                                            labelVisual={this.props.language.language?.depositosGarantias.chkReconciled} />
                                    </div>
                                </div>
                            </div>
                        </CheckboxGroup>
                    </div>

                </div>




                {this.state.loadingReferencias ? (
                    <div className="col-12 mt-5">
                        <SpinnerLoading text={this.props.language.language?.depositosGarantias.loadingReferences} />
                    </div>
                ) : <div >
                    <Table id='tbl-depositos' className="mt-2">
                        <thead>
                            <TableRow>
                                {//seleccion de prospecto e inversion se comenta para la 2da version 
                               /*<TableCell type="colHead">
                               </TableCell>*/}
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.reference}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.amount}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.date}
                                </TableCell>

                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.bond}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.surety}
                                </TableCell>
                                <TableCell type="colHead" className="colStatus">
                                    {this.props.language.language?.depositosGarantias.status}
                                </TableCell>
                                <TableCell type="colHead" className="colStatus">
                                    {this.props.language.language?.depositosGarantias.actions}
                                </TableCell>
                            </TableRow>
                        </thead>
                        <tbody>
                            {this.state.listaDepositos.map((referencia, index) => (
                                <TableRow key={index}>
                                    {////seleccion de prospecto e inersion se comenta para la 2da version
                                        /*<TableCell type="rowHead">
                                        {//seleccion de prospecto se comenta para la 2da version
                                        referencia.estatus === 2 ?
                                            <CheckboxGroup >
                                                <Checkbox disabled={this.state.checkboxProspecto} id={`prospecto-${referencia.idReferencia}`} labelVisual=""
                                                    onChange={() => this.handleCheckBoxChange(referencia.idDepositoGarantias, referencia.estatus)} />
                                            </CheckboxGroup> : null 
                                        }
                                        {//seleccion de inversion se comenta para la 2da version
                                       referencia.estatus === 4 ?
                                            <CheckboxGroup >
                                                <Checkbox disabled={this.state.checkboxInversion} id={`inversion-${referencia.idReferencia}`} labelVisual=""
                                                    onChange={() => this.handleCheckBoxChange(referencia.idDepositoGarantias, referencia.estatus)} />
                                        </CheckboxGroup> : null}
                                        </TableCell>*/}
                                    <TableCell type="rowHead">{referencia.idReferencia}</TableCell>
                                    <TableCell type="rowHead"> {formatCurrency(referencia.importe)}</TableCell>
                                    <TableCell type="rowHead">{this.formatearFecha(referencia.fecha)}</TableCell>

                                    <TableCell type="rowHead">{referencia.fianza}</TableCell>
                                    <TableCell type="rowHead">{referencia.fiado}</TableCell>
                                    <TableCell type="rowHead">
                                        <Badge
                                            className='row d-block mx-auto thumbs cursor'
                                            /*onClick={(e) => this.openModalRecibo(referencia, e)}*/
                                            highlightType={
                                                referencia.estatus === 0 ? 'informative' :
                                                    referencia.estatus === 1 ? 'negative' :
                                                        referencia.estatus === 2 ? 'positive' :
                                                            referencia.estatus === 3 ? 'informative' :
                                                                referencia.estatus === 4 ? 'caution' :
                                                                    'informative'
                                            }
                                            icon={
                                                referencia.estatus === 0 ? <IconHelp color="positive" size="24" /> :
                                                    referencia.estatus === 1 ? <IconErrorAlert color="negative" size="24" /> :
                                                        referencia.estatus === 2 ? <IconSuccessAlert color="positive" size="24" /> :
                                                            referencia.estatus === 3 ? <IconInfo color="informative" size="24" /> :
                                                                referencia.estatus === 4 ? <IconSuccessAlert color="caution" size="24" /> :
                                                                    <IconSuccessAlert color="informative" size="24" />
                                            }
                                        >{
                                                referencia.descripcion
                                            }
                                        </Badge>
                                    </TableCell>
                                    <TableCell type="rowHead">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" className="dropdown-more-options" id="dropdown-basic-2" disabled={referencia.estatus === 0}>
                                                <IconSeeMore></IconSeeMore>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align='right'>

                                                {referencia.estatus === 1 ? <Dropdown.Item onClick={(e) => this.openModalPago(referencia, e)}>
                                                    {this.props.language.language?.depositosGarantias.applyManualPayment}
                                                </Dropdown.Item> : null}
                                                {
                                                    referencia.estatus === 2 ?
                                                        <Dropdown.Item onClick={(e) => this.openModalRecibo(referencia, e)}>
                                                            {referencia.firmado === 0 ?
                                                                this.props.language.language?.depositosGarantias.generateReceipt :
                                                                this.props.language.language?.depositosGarantias.checkReceipt}
                                                        </Dropdown.Item> : null
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                    <div className="container-center mt-5" hidden={this.state.listaDepositos.length > 0} >
                        <h3>{this.props.language.language?.depositosGarantias.noResults} </h3>
                    </div>
                    <div className="row mt-5" hidden={this.state.listaDepositos.length < 1}>
                        <div className="col-md-5 show-registers">
                            {this.props.language.language?.depositosGarantias.showing} {this.state.numRegistrosMin} {this.props.language.language?.depositosGarantias.showingTo} {this.state.numTotalDepositos > 10 ? this.state.numRegistrosMax : this.state.numTotalDepositos} {this.props.language.language?.depositosGarantias.showingTotal}  {this.state.numTotalDepositos} {this.props.language.language?.depositosGarantias.records}.
                        </div>
                        <div className="col-md-7">
                            <div className="float-md-right">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="›"
                                    onPageChange={(e) => (this.setState({ currentPage: e.selected + 1 }), this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, e.selected + 1, this.state.queryin, 10, 1))}
                                    pageRangeDisplayed={10}
                                    marginPagesDisplayed={1}
                                    pageCount={this.state.numPaginas}
                                    previousLabel="‹"

                                    containerClassName="justify-content-center pagination"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    activeClassName="active"
                                    disabledClassName="disabled"
                                    initialPage={this.state.currentPage - 1}
                                    disableInitialCallback={true}
                                //renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>}

                <Modal
                    id='modal-recibo'
                    isOpen={this.state.modalRecibo}
                    size="large"
                    modalStyle="standard"
                    className="container-centerr modalRecibo"
                    closeFunc={() => this.setState({ modalRecibo: false, reciboBase64: '' })}>
                    {//se comenta respecto al firmado de documento
                        this.state.estatusRecibo === 3 ?
                            (<div className="container-center"><h4>{this.props.language.language?.depositosGarantias.lblProcessOfSigning}</h4></div>) : null}
                    <div className=" col-md-12 col-lg-12 marco-button-recibo">
                        {//se comenta respecto al firmado de documento
                            this.state.estatusRecibo === 0 ? (<Button
                                type='button'
                                variant='primary'
                                size='small'
                                hidden={this.state.reciboBase64 === ''}
                                className=" ml-2 mb-2 mt-2 button-recibo"
                                disabled={!this.state.disFirmar || this.state.reciboBase64 === ''}
                                onClick={() => this.getUrlRecibo(this.state.reciboBase64, this.state.referenciaPago)}                        >
                                {this.props.language.language?.depositosGarantias.btnSign} {!this.state.disFirmar ? <Spinner animation="border" size="sm" /> : null
                                }</Button>) : null}
                        <Button
                            type='button'
                            variant='primary'
                            size='small'
                            className=" ml-2 mb-2 mt-2 button-recibo"
                            disabled={this.state.reciboBase64 === ''}
                            onClick={() => this.descargaReciboPdf()}>
                            {this.props.language.language?.depositosGarantias.btnDownload}
                        </Button>
                    </div>
                    <div className="marco-recibo col-md-12 col-lg-12">
                        {this.state.loadingRecibo ? (
                            <div className="container-center">
                                <SpinnerLoading text='Cargando recibo' />
                            </div>
                        ) : <div id="parent">
                            <div className="mb-3 mt-2">
                                {this.state.reciboBase64 !== '' ? (
                                    <Document file={`data:application/pdf;base64,${this.state.reciboBase64}`}
                                        className='container-center'>
                                        <Page pageNumber={1} scale={1.1} />
                                    </Document>) : null}
                            </div>
                            <h1 hidden={this.state.reciboBase64 !== ''}>
                                {this.props.language.language?.depositosGarantias.lblReceiptError}</h1>
                        </div>}
                    </div>
                </Modal>

                <Modal
                    id='modal-carga'
                    isOpen={this.state.modalCarga}
                    size="medium"
                    modalStyle="standard"
                    /*title="Cargar Archivo"*/
                    className="container-center"
                    closeFunc={() => this.setState({ modalCarga: false, disEnviar: true, nombreArchivo: '', formData: new FormData() })}
                >
                    <div className="container-center">
                        <h3>{this.props.language.language?.depositosGarantias.lblFileUpload}</h3>
                    </div>
                    <form id='form-recibo' onSubmit={(e) => this.postFile(e)} className='form-row form-border'>
                        <div className="drag-area container-center"
                            onDragOver={(e) => { e.preventDefault(); }}
                            onDragLeave={(e) => { e.preventDefault(); }}
                            onDrop={(e) => this.handleFileDrop(e)}>
                            <div className='col-12 mt-5'>
                                <IconButton id='boton-upload' className='cursor mb-4'
                                    onClick={() => document.getElementById('input-file')?.click()} background='teal'>
                                    <IconUpload label={this.props.language.language?.depositosGarantias.lblLoad} size={'64'}></IconUpload>
                                </IconButton>
                                <br />
                                <p>{this.props.language.language?.depositosGarantias.lblSelectFile}</p>
                            </div>
                            <div className='col-12'>
                                <label id='lblFile'>
                                    {this.state.nombreArchivo}
                                    <input id='input-file' type='file' name='file' accept='.xlsx' hidden
                                        onChange={(e) => this.handleFileChange(e)}></input>
                                    {this.state.alertFile ?
                                        <Alert compact highlightType="negative">{this.props.language.language?.depositosGarantias.lblErrorFormat}</Alert> : null
                                    }
                                    {this.state.loadingPostFile ? (
                                        <div className="col-12">
                                            <SpinnerLoading text={this.props.language.language?.depositosGarantias.lblUploadingFile} />
                                        </div>
                                    ) : null}
                                </label><br /><br />
                            </div>
                            <Button type='submit' variant='primary' size='medium' className='mb-2' disabled={this.state.disEnviar}>
                                {this.props.language.language?.depositosGarantias.btnSend}</Button>
                        </div>
                    </form>
                </Modal>
                <Modal
                    id='modal-pago'
                    isOpen={this.state.modalPago}
                    size="large"
                    modalStyle="standard"
                    /*title="Aplicar Pago"*/
                    className='modalPago'
                    closeFunc={() => this.setState({ modalPago: false, valueSearchPago: '', listIdPrendaria: [] })}>
                    <div className="container-center">
                        <h3>{this.props.language.language?.depositosGarantias.lblApplyPayment}</h3>
                    </div>
                    <Table id='tbl-moduloRH'>
                        <thead>
                            <TableRow>
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.reference}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.amount}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.date}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.depositosGarantias.depositor}
                                </TableCell>
                            </TableRow>
                        </thead>
                        <tbody>
                            <TableRow >
                                <TableCell type="rowHead">{this.state.deposito.idReferencia}</TableCell>
                                <TableCell type="rowHead">{this.state.deposito.importe}</TableCell>
                                <TableCell type="rowHead">{this.state.deposito.fecha}</TableCell>
                                <TableCell type="rowHead">{this.state.deposito.fiado}</TableCell>
                            </TableRow>
                        </tbody>
                    </Table>

                    <div className="row">
                        <AlphanumericInput
                            type='text'
                            onChange={(e) => this.getReferencias(e.target.value)}
                            className='input input-apply'
                            value={this.state.valueSearchPago}
                            labelVisual={
                                <div id='input-buscar'>
                                    <IconSearch size={'16'}></IconSearch>
                                    {this.props.language.language?.depositosGarantias.bond} / {this.props.language.language?.depositosGarantias.reference} / {this.props.language.language?.depositosGarantias.surety}
                                </div>}>
                        </AlphanumericInput>

                        <Button
                            variant='primary'
                            size='small'
                            type='button'
                            className="btn-apply"
                            disabled={this.state.listIdPrendaria.length > 0 ? false : true}
                            onClick={() => { this.setState({ modalPago: false, modalAutorizarPago: true, checkboxPago: false, valueSearchPago: '' }) }}
                        >{this.props.language.language?.depositosGarantias.btnApply}</Button>
                    </div>
                    {this.state.loadingReferencias ? (
                        <div className="col-12 mt-1">
                            <SpinnerLoading text='Cargando referencias' />
                        </div>
                    ) : <div>
                        <Table id='tbl-referencia' className="mt-3">
                            <thead>
                                <TableRow>
                                    <TableCell type="colHead">
                                    </TableCell>
                                    <TableCell type="colHead">
                                        {this.props.language.language?.depositosGarantias.reference}
                                    </TableCell>
                                    <TableCell type="colHead">
                                        {this.props.language.language?.depositosGarantias.amount}
                                    </TableCell>
                                    <TableCell type="colHead">
                                        {this.props.language.language?.depositosGarantias.bond}
                                    </TableCell>
                                    <TableCell type="colHead">
                                        {this.props.language.language?.depositosGarantias.lblResourceOwner}
                                    </TableCell>
                                    <TableCell type="colHead">
                                        {this.props.language.language?.depositosGarantias.surety}
                                    </TableCell>
                                </TableRow>
                            </thead>
                            <tbody>
                                {this.state.listaReferencias.map((referencia, index) => (
                                    <TableRow key={index}>
                                        <TableCell type="rowHead">
                                            {/*<CheckboxGroup >
                                                <Checkbox id="checkbox-pago" checked={this.state.checkboxPago} labelVisual="" name="checkbox-pago"
                                                    onChange={() => { this.setState({ modalPago: false, modalAutorizarPago: true, checkboxPago: false, idPrendaria: referencia.idPrendaria, valueSearchPago: '' }) }} />
                                            </CheckboxGroup>*/}
                                            <CheckboxGroup >
                                                <Checkbox id={`checkbox-pago-${referencia.idPrendaria}`}
                                                    checked={(this.state.listIdPrendaria.find(element => element === referencia.idPrendaria)) === undefined ? false : true}
                                                    labelVisual="" name={`checkbox-pago-${referencia.idPrendaria}`}
                                                    onChange={() => this.handleCheckBoxChangePago(referencia.idPrendaria)} />
                                            </CheckboxGroup>
                                        </TableCell>
                                        <TableCell type="rowHead">{referencia.idReferencia}</TableCell>
                                        <TableCell type="rowHead">{referencia.importe}</TableCell>
                                        <TableCell type="rowHead">{referencia.fianza}</TableCell>
                                        <TableCell type="rowHead">{referencia.depositante}</TableCell>
                                        <TableCell type="rowHead">{referencia.fiado}</TableCell>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                        <div hidden={this.state.listaReferencias.length > 0}>
                            {this.props.language.language?.depositosGarantias.noResults}
                        </div>
                    </div>}
                </Modal>
                <Modal
                    id='modal-autorizar-pago'
                    isOpen={this.state.modalAutorizarPago}
                    size="medium"
                    modalStyle="standard"
                    /*</Content>title='Autorizar Pago'*/
                    className='container-center'
                    closeFunc={() => this.setState({ modalAutorizarPago: false })}
                >
                    <div className="container-center">
                        <h3>{this.props.language.language?.depositosGarantias.lblAuthorizePayment}</h3>
                    </div>
                    <div className='container' >
                        <div className='row container-center'>
                            {this.props.language.language?.depositosGarantias.lblToAuthorize} <strong>{this.state.referenciaPago}</strong>
                        </div>
                        <div className=" mt-4">
                            <Button
                                type='button'
                                variant='primary'
                                size='small'
                                onClick={() => this.putPago(this.state.deposito.idDepositoGarantias)}
                            >{this.props.language.language?.depositosGarantias.btnAccept}</Button>
                            <Button
                                type='button'
                                variant='secondary'
                                size='small'
                                onClick={() => this.setState({ modalAutorizarPago: false })}
                            >{this.props.language.language?.depositosGarantias.btnCancel}</Button>

                        </div>
                    </div>
                </Modal>
                <Modal
                    id='modal-cambio-estatus'
                    isOpen={this.state.modalCambioEstatus}
                    size="medium"
                    modalStyle="standard"
                    /*</Content>title='Autorizar Pago'*/
                    className='container-center'
                    closeFunc={() => this.setState({ modalCambioEstatus: false })}
                >
                    <div className="container-center">
                    </div>
                    <div className='container' >
                        <div className='row container-center'>
                            <h5> {
                                !this.state.checkboxProspecto ? "¿Está seguro que desea mandar para prospecto?" :
                                    "¿Desea solicitar autorización para inversión?"
                            }
                            </h5>
                        </div>
                        <div className=" mt-4">
                            <Button
                                type='button'
                                variant='primary'
                                size='small'
                                onClick={() => this.putCambioEstatus()}
                            >{this.props.language.language?.depositosGarantias.btnAccept}</Button>
                            <Button
                                type='button'
                                variant='secondary'
                                size='small'
                                onClick={() => this.setState({ modalCambioEstatus: false })}
                            >{this.props.language.language?.depositosGarantias.btnCancel}</Button>

                        </div>
                    </div>
                </Modal>
                <Modal
                    id='modal-exito'
                    isOpen={this.state.modalExito}
                    size="small"
                    modalStyle="standard"
                    className='container-center'
                    closeFunc={() => this.setState({ modalExito: false })}
                >
                    <form id='form-envio-exitoso' onSubmit={(e) => this.handleFileChange} className='form-row'>
                        <div className='container mt-5'>
                            <div className='row mt-5 mb-5'>
                                <div className='col-12 mb-5'>
                                    <IconSuccessAlert color='positive' size='64'></IconSuccessAlert>
                                </div>
                                <div className='col-12 mb-5'>
                                    <h2>{this.state.textExito}</h2>
                                </div>
                                <div className='col-12'>
                                    <Button variant='primary' size='small' onClick={() => this.setState({ modalExito: false, modalPago: false, valueSearchPago: '' })}
                                    >{this.props.language.language?.depositosGarantias.btnAccept}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>
                <div className="modal-radius">
                    <Modal
                        id='modal-error'
                        isOpen={this.state.modalError}
                        size="medium"
                        modalStyle="standard"
                        className='container-center'
                        closeFunc={() => this.setState({ modalError: false })}
                    >
                        <div className='container '>
                            <div className='row mt-2 mb-3'>
                                <div className='col-12 mb-3'>
                                    <IconWarning color='caution' size='64' />
                                </div>
                                <div className='col-12 mb-3'>
                                    <br />
                                    <h2>{this.state.textError}</h2>
                                    <br />
                                </div>
                                <div className='col-12'>
                                    <Button variant='primary' size='small' onClick={() => this.setState({ modalError: false })}
                                    >{this.props.language.language?.depositosGarantias.btnAccept}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Content>

        </>
    }
    componentDidMount() {
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        const ref = urlParams.get('ref');
        this.getEstadisticas();
        if (ref === null) {
            this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, 1, "", 10, 0);
        } else {
            this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, 1, ref, 10, 0);
            this.setState({
                valueSearch: ref,
                loadingReferencias: true
            })
        }

        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    }

    public async getDepositos(fFin: string, fInicio: string, filtro: number[], page: number, queryin: string, size: number, CurrentPage: number) {
        this.setState({
            loadingReferencias: true,
            checkboxInversion: false,
            checkboxProspecto: false,
            listProspecto: []
        })
        if (CurrentPage !== 1) {
            this.setState({ currentPage: 1 })
            page = 1;
        }
        try {
            page > 1 ? this.setState({ numRegistrosMin: 1 + (10 * (page - 1)) }) : this.setState({ numRegistrosMin: 1 });
            page > 1 ? this.setState({
                numRegistrosMax:
                    ((10 * page) > this.state.numTotalDepositos ?
                        this.state.numTotalDepositos : ((10 * page - 1) + this.state.numTotalDepositos % this.state.numPaginas)
                    )
            }) :
                this.setState({ numRegistrosMax: 10 });
            const res = await apiService.getDepositos(this.formatearFechaBusqueda(fFin), this.formatearFechaBusqueda(fInicio), filtro, page, queryin, size);
            const response: { paginacion: PaginacionModel, referencia: DepositoModel[] } = res.data;
            if (response.referencia === null) {
                this.setState({
                    listaDepositos: [],
                    loadingReferencias: false,
                })
                return;
            }
            this.setState({
                listaDepositos: response.referencia,
                numPaginas: response.paginacion.totalPages,
                numTotalDepositos: response.paginacion.totalItems,
                loadingReferencias: false,
            })
            const array: number[] = [];
            for (let i = 1; i <= this.state.numPaginas; i++) {
                array.push(i);
            }
            this.setState({ listPaginacion: array })

        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
                this.setState({
                    listaDepositos: [],
                    loadingReferencias: false,
                })
            }
        }
    }

    public async getRecibo(idDeposito: number) {

        try {
            var res = await apiService.getReciboCaja(idDeposito);
            this.setState({
                estatusRecibo: 0,
            })
            const response: { name: string, documentLink: string } = res.data;
            if (response.documentLink === '' || response.documentLink === null || response.documentLink === undefined) {
                this.setState({
                    modalRecibo: false,
                    textError: this.props.language.language?.depositosGarantias.lblReceiptError!,
                    modalError: true,
                    loadingRecibo: false,
                    //se comenta respecto al firmado de documento
                    estatusRecibo: 1,
                    reciboBase64: ''
                })
                return
            }
            this.setState({
                reciboBase64: response.documentLink,
                loadingRecibo: false,
            })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                this.setState({
                    reciboBase64: '',
                    loadingRecibo: false,
                    estatusRecibo: 1
                })
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    public async getReciboFirmado(idDocumento: number) {

        try {
            var res = await apiService.getReciboFirmado(idDocumento);
            if (res.data === '' || res.data === null || res.data === undefined) {
                this.setState({
                    modalRecibo: false,
                    textError: this.props.language.language?.depositosGarantias.lblReceiptError!,
                    modalError: true,
                    loadingRecibo: false,
                    //se comenta respecto al firmado de documento
                    estatusRecibo: 1,
                    reciboBase64: ''
                })
                return
            }
            this.setState({
                reciboBase64: res.data,
                loadingRecibo: false,
            })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                this.setState({
                    reciboBase64: '',
                    loadingRecibo: false,
                    estatusRecibo: 1
                })
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    public async getInfoDocumento(idDocumento: number) {

        try {
            var res = await apiService.getInfoDocumento(idDocumento);
            if (res.data.estatus === 'Firmado') {
                this.setState({
                    estatusRecibo: 1,
                })
            } else {
                this.setState({
                    estatusRecibo: 3,
                })
            }


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    //se comenta respecto al firmado de documento
    public async getUrlRecibo(reciboBase64: string, idReferencia: string) {
        try {
            await this.getInfoUsuario(this.state.PERFIL.username);
            let URLactual = window.location;
            this.setState({ disFirmar: false });
            //gregorio.licona@libertyfianzas.com
            //jovanie.avendano@libertyfianzas.com
            await this.getCoordenadas(1);


            await this.getInfoUsuario(this.state.PERFIL.username);
            const response = await apiService.getUrlFirmaRecibo(reciboBase64,
                URLactual.origin,
                this.state.infoUser,
                this.state.coorX,
                this.state.coorY,
                this.state.paramIdPrendaria,
                idReferencia);
            //const responseCorreo = await apiService.getUrlFirmaRecibo(reciboBase64, URLactual.origin, "gregorio.licona@libertyfianzas.com","correo");
            let auxEnlace = response.data[0];
            this.setState({ urlFirmaPAgo: '' + auxEnlace.enlace, disFirmar: response.data.length > 0 ? true : false });
            //this.openWindow(auxEnlace.enlace)
            window.location.href = auxEnlace.enlace;

        } catch (e) {
            const error = e as AxiosError;
            this.setState({ disFirmar: true });
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
            }
        }
    }


    public async getReferencias(queryin: string) {
        try {
            queryin = queryin.trim()
            if (queryin.length < 4 && queryin.length > 0) {
                return;
            }
            this.setState({
                valueSearchPago: queryin,
                loadingReferencias: true
            })
            if (queryin === "") {
                queryin = String(this.state.referenciaPago)
            }
            const res = await apiService.getDetalleRecibo(queryin);
            const response: { referencia: ReferenciaModel[] } = res.data;
            if (response.referencia.length === 0) {
                this.setState({
                    listaReferencias: [],
                    loadingReferencias: false
                })
                return;
            }
            this.setState({
                listaReferencias: response.referencia,
                loadingReferencias: false
            })

        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                this.setState({ loadingReferencias: false })
                console.log(error.response.status);
                console.log(error.response.data);
            }
        }
    }

    public async buscar(deposito: string) {

        deposito = deposito.trim()
        if (deposito.length >= 3 || deposito === '') {
            this.setState({
                queryin: deposito
            });

            this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, 1, deposito, 10, 0)
        }
    }
    public async putPago(idDeposito: number) {
        try {

            const res = await apiService.putPago(idDeposito, this.state.listIdPrendaria, this.state.PERFIL.username);
            if (res.data.codigo === 0) {
                this.getReferencias(this.state.deposito.fianza);
                this.setState({
                    modalExito: true, modalAutorizarPago: false,
                    textExito: this.props.language.language?.depositosGarantias.lblReferenceApplied,
                    listIdPrendaria: []
                })
                this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, 1, this.state.queryin, 10, 0)
            } else {
                this.setState({
                    modalError: true,
                    textError: this.props.language.language?.depositosGarantias.lblErrorApplyPayment!,
                    modalAutorizarPago: false,
                    listIdPrendaria: []
                })
            }
            this.getEstadisticas();


        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
                this.setState({
                    modalError: true,
                    textError: this.props.language.language?.depositosGarantias.lblErrorApplyPayment!,
                    modalAutorizarPago: false,
                    listIdPrendaria: []
                })
            }
        }
    }

    public async putCambioEstatus() {
        try {
            const auxestatus = this.state.checkboxProspecto ? 4 : 2;
            const res = await apiService.putCambioEstatus(this.state.listProspecto, auxestatus);
            if (res.data.codigo === 0) {
                this.setState({ modalCambioEstatus: false, modalExito: true, textExito: this.props.language.language?.depositosGarantias.lblAppliedAorrectly, listProspecto: [], checkboxInversion: false, checkboxProspecto: false })
                this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, this.state.currentPage, this.state.queryin, 10, 0)
            }
            this.getEstadisticas();
        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
            }
        }
    }

    public async getInfoUsuario(user: string) {

        try {
            const res = await apiService.getInfoUsuario(user);
            ///const response = res.data;
            this.setState({ infoUser: res.data })
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    public async getCoordenadas(idCoor: number) {

        try {
            const res = await apiService.getCoordenadas(idCoor);
            const response = res.data;
            this.setState({ coorX: response.corX, coorY: response.CorY })
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }




    postFile = async (e: any) => {
        this.setState({ loadingPostFile: true });
        try {
            e.preventDefault();
            await apiService.postFile(this.state.formData, this.state.PERFIL.username);
            //const response = String(res.data.lote);
            this.setState({
                formData: new FormData(),
                nombreArchivo: '',
                disEnviar: true,
                //textAlertt: 'se envio con exito ',
                //showLote: response,
                modalCarga: false,
                modalExito: true,
                loadingPostFile: false,
                valueSearch: '',
                textExito: this.props.language.language?.depositosGarantias.lblSentSuccess
            })
            this.getEstadisticas();
            await new Promise(done => setTimeout(() => {
                this.setState({
                    modalCarga: false,
                    //textAlertt: '',
                    formData: new FormData(),
                });
                this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, this.state.filterSearchValue, 1, this.state.queryin, 10, 0)
            }, 2000));

        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                this.setState({
                    textError: this.props.language.language?.depositosGarantias.lblMsjErrorSend!,
                })
                if (err.response.status === 401) {
                    this.setState({
                        textError: this.props.language.language?.depositosGarantias.lblMsjError401!,
                    })
                }
                if (err.response.status === 400 && (err.response.data.apimessage.message !== null && err.response.data.apimessage.message !== "")) {
                    this.setState({
                        textError: err.response.data.apimessage.message,
                    })
                }
                this.setState({
                    formData: new FormData(),
                    nombreArchivo: '',
                    disEnviar: true,
                    modalCarga: false,
                    modalError: true,
                    //textError: this.props.language.language?.depositosGarantias.lblMsjErrorSend!,
                    loadingPostFile: false,

                })
                console.log(err.response.status);
                console.log(err.response.data.error);
                console.log(err.response.data);
            }

        }
    }

    public async getEstadisticas() {
        this.setState({ loadingStatistics: true })
        try {
            const res = await apiService.getEstadisticas();
            if (res.data.length === 0) {
                this.setState({
                    listStatistics: [],
                    loadingStatistics: false,
                })

                return;
            }
            this.setState({ loadingStatistics: false, listStatistics: res.data })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({ loadingStatistics: false });
            }
        }
    }
    public handleCheckBoxChange(e: number, estatus: number) {
        let auxList = this.state.listProspecto;
        const found = auxList.find(element => element === e);
        if (found !== undefined) {
            auxList = this.state.listProspecto.filter((item) => item !== e)
        } else {
            auxList = [...auxList, e]

        }
        this.setState({
            listProspecto: auxList

        })
        if (auxList.length === 0) {
            this.setState({
                checkboxProspecto: false,
                checkboxInversion: false
            })
            return
        }

        if (estatus === 2) {
            this.setState({
                checkboxProspecto: false,
                checkboxInversion: true
            })
        } else {
            this.setState({
                checkboxProspecto: true,
                checkboxInversion: false
            })
        }


    }

    public handleCheckBoxChangeFiltro(e: InputChange, name: string): void {
        let auxEstatus: number = 0;
        let auxFiltro = this.state.filterSearchValue;
        let auxList = this.state.filterSearch;
        switch (name) {
            case FiltroEnum.refGeneradas:
                auxEstatus = 0;
                auxList = {
                    refGeneradas: !this.state.filterSearch.refGeneradas,
                    depositosRealizados: this.state.filterSearch.depositosRealizados,
                    coinciliados: this.state.filterSearch.coinciliados
                }

                break;
            case FiltroEnum.depositosRealizados:
                auxEstatus = 1;
                auxList = {
                    refGeneradas: this.state.filterSearch.refGeneradas,
                    depositosRealizados: !this.state.filterSearch.depositosRealizados,
                    coinciliados: this.state.filterSearch.coinciliados
                }

                break;
            case FiltroEnum.conciliados:
                auxEstatus = 2;
                auxList = {
                    refGeneradas: this.state.filterSearch.refGeneradas,
                    depositosRealizados: this.state.filterSearch.depositosRealizados,
                    coinciliados: !this.state.filterSearch.coinciliados
                }
                break;
            default:
                auxEstatus = parseInt(name);
                if (auxEstatus === 2) {
                    auxList = {
                        refGeneradas: this.state.filterSearch.refGeneradas,
                        depositosRealizados: this.state.filterSearch.depositosRealizados,
                        coinciliados: !this.state.filterSearch.coinciliados
                    }
                }
                break;
        }

        const found = auxFiltro.find(element => element === auxEstatus);
        if (found !== undefined) {
            auxFiltro = this.state.filterSearchValue.filter((item) => item !== auxEstatus)
        } else {
            auxFiltro = [...auxFiltro, auxEstatus]

        }
        this.setState({
            filterSearch: auxList,
            filterSearchValue: auxFiltro
        })
        this.getDepositos(this.state.fechaFinal, this.state.fechaInicial, auxFiltro, 1, this.state.queryin, 10, 0);
    }

    public handleCheckBoxChangePago(idPrendaria: number): void {
        let auxList = this.state.listIdPrendaria;
        const found = auxList.find(element => element === idPrendaria);
        if (found !== undefined) {
            auxList = auxList.filter((item) => item !== idPrendaria)
        } else {
            auxList = [...auxList, idPrendaria]
        }
        this.setState({ listIdPrendaria: auxList })

    }

    handleInputChangeSelect(e: InputChange): void {
        const name = e.target.name;
        let value = e.target.value;

        switch (name) {
            case 'fechaInicial':
                this.setState({ fechaInicial: value })
                this.validFechaInicial();
                break;
            case 'fechaFinal':
                this.setState({ fechaFinal: value })
                this.getDepositos(value, this.state.fechaInicial, this.state.filterSearchValue, 1, this.state.queryin, 10, 0);
                break;
        }

    }

    public descargaReciboPdf() {
        if (this.state.reciboBase64 !== '') {
            const linkSource = `data:application/pdf;base64,${this.state.reciboBase64}`;
            const downloadLink = document.createElement("a");
            const fileName = `${this.state.nombreRecibo}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
    }

    public async getReporte() {
        try {
            const response = await apiService.getReporte();
            let a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
            a.download = "referencias.xls";
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
            }
        }
    }
    public validFechaInicial(): boolean {
        const fechaInicial = this.state.fechaInicial;
        let initialDate = new Date(fechaInicial);
        const fechaFinal = this.state.fechaFinal!;
        if (fechaInicial.length === 0) {
            this.setState({ msgFechaI: '' })
        }
        //  console.log('fecha inicial: ' + fechaInicial.length);
        if (fechaInicial.length === 10) { // si esta llena la fecha inicial
            // si la fecha es menor al día de hoy
            //console.log('entro a las validaciones de fecha inicial');
            if (initialDate > maxDate) {
                this.setState({ msgFechaI: this.props.language.language?.depositosGarantias.lblDateINoValid })
                return false;
            }
            else if (fechaFinal) {
                // vamos a comparar si la fecha final no sea menor a la fecha inicial
                let finalDate = new Date(fechaFinal);
                if (initialDate === finalDate) {
                    this.setState({ msgFechaI: '' })
                    return true;
                }

                if (initialDate > finalDate) {
                    this.setState({ msgFechaI: this.props.language.language?.depositosGarantias.lblDateIElderly })
                    return false;
                } else {
                    return true;
                }
            }
            else { // inicializamos el  mensaje
                this.setState({ msgFechaI: '' })
                return true;
            }
        } {
            return false
        }
    }
    public validFechaFinal(): boolean {
        const fechaFinal = this.state.fechaFinal;
        let endDate = new Date(fechaFinal);
        const startDate = new Date(this.state.fechaInicial)
        if (fechaFinal.length === 0) {
            this.setState({ msgFechaF: '' })
        }
        if (fechaFinal.length === 10) { // si esta llena la fecha final
            // si la fecha final es menor a la inicial
            if (this.state.fechaInicial && endDate < startDate) {
                // le escribimos un mensaje
                this.setState({ msgFechaF: this.props.language.language?.depositosGarantias.lblDateFminor })
                return false;
            }
            else if (endDate > maxDate) {
                this.setState({ msgFechaF: this.props.language.language?.depositosGarantias.lblDateFNoValid })
                return false;
            }
            else {
                if (new Date(this.state.fechaInicial) === endDate) {
                    this.setState({ msgFechaI: '' })

                }
                this.setState({ msgFechaF: '' })
                return true;
            }
        } else {
            return false;
        }
    }

}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});
export default connect(mapStateToProps)(DepositosGarantia);

interface DepositosGarantiaState {
    modalRecibo: boolean,
    modalCarga: boolean,
    modalPago: boolean,
    modalError: boolean,
    formData: FormData,
    listaDepositos: DepositoModel[],
    nombreArchivo: string,
    alertFile: boolean,
    listaReferencias: ReferenciaModel[],
    disEnviar: boolean,
    //textAlertt: string,
    listPaginacion: number[],
    numPaginas: number,
    currentPage: number,
    numTotalDepositos: number,
    numRegistrosMax: number,
    numRegistrosMin: number,
    //showTable: boolean,
    //showLote: string,
    reciboBase64: string,
    nombreRecibo: string,
    estatusRecibo: number,
    queryin: string,
    deposito: DepositoModel,
    modalAutorizarPago: boolean,
    listIdPrendaria: number[],
    referenciaPago: string,
    modalExito: boolean,
    loadingReferencias: boolean,
    loadingRecibo: boolean,
    loadingDetalle: boolean,
    loadingPostFile: boolean,
    checkboxPago: boolean,
    valueSearch: string,
    textExito?: string,
    textError: string,
    urlFirmaPAgo: string,
    disFirmar: boolean,
    fechaInicial: string,
    fechaFinal: string,
    filterSearch: {
        refGeneradas: boolean,
        depositosRealizados: boolean,
        coinciliados: boolean
    },
    filterSearchValue: number[],
    msgFechaI?: string,
    msgFechaF?: string,
    listStatistics: EstadisticasModel[],
    loadingStatistics: boolean,
    listProspecto: number[],
    checkboxProspecto: boolean,
    checkboxInversion: boolean,
    modalCambioEstatus: boolean,
    valueSearchPago: string,
    infoUser: InfoUserModel,
    correoFiado: string,
    rgbStatistics: string,
    idPrendaria: number,
    PERFIL: UserModel,
    coorY: number,
    coorX: number,
    paramIdPrendaria: string



}



