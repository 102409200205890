import React, {ChangeEvent, useEffect, useState} from 'react';
import './ModalAuthorizationExpediente.scss';
import {
    Button,
    IconAdd,
    IconChecklist,
    IconCheckmark,
    IconEdit,
    IconErrorAlert,
    IconSearch, IconThumbsDown, IconThumbsUp,
    IconTrash,
    IconUpload, Modal
} from "@lmig/lmds-react";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import {MAXSIZEUPLOADFILE, openNewWindow, validFileImg, validRoles} from "../../../class/CommonClass";
import * as garantiasService from '../../../services/garantias/GarantiasService';
import {AxiosError} from "axios";
import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";
import {TypeDocModel} from "../../../models/garantias/TypeDocModel";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";
import {Alert, Dropdown, Spinner} from "react-bootstrap";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UserModel} from "../../../models/public/UserModel";
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import {MultiValue} from "react-select";
import {UploadDragExpediente} from "../upload-drag-expediente/UploadDragExpediente";
import {ModalFileMaxUpload} from "../../public/modal-file-max-upload/ModalFileMaxUpload";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";

interface ModalAuthorizationExpedienteProps {
    idDoc: number;
    resetIdDoc: () => void;

}


export const ModalAuthorizationExpediente: React.FC<ModalAuthorizationExpedienteProps> = (
    {idDoc,
        resetIdDoc
    }:ModalAuthorizationExpedienteProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const localStorageClass = new LocalStorageClass();

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

    const [sendingAuthorization, setSendingAuthorization] = useState<boolean>(false);

    const [errorQuoteReason, setErrorQuoteReason] = useState<boolean>(false);

    const [selectIdDoc, setSelectIdDoc] = useState<number>(0);




    const openModalAuthorization = async (idDoc: number) => {

        if (idDoc){
            setOpenModalConfirm(true);
            setSelectIdDoc(idDoc)
        }
    }

    const closeModalAuthorization = () => {
        setOpenModalConfirm(false);
        resetIdDoc()
    }


    const putQuoteExpedient = async  (idDocumento : number, estadoDocumento : number, motivos?: MotivoRechazoModel[]) => {
         setSendingAuthorization(true);
         setErrorQuoteReason(false);
         try {
             const res = await garantiasService.putQuoteExpedienteIdentificacion(idDocumento, estadoDocumento, motivos!);
             setSendingAuthorization(false);
             setOpenModalConfirm(false)
             closeModalAuthorization();

         } catch (error) {
             const err = error as AxiosError
             if (err.response) {
                 console.log(err.response.status)
                 console.log(err.response.data)
                 setSendingAuthorization(false);
                 setErrorQuoteReason(true);
             }
         }
    }


    useEffect(() => {
        if (idDoc){
            openModalAuthorization(idDoc);
        }

    },[idDoc]);



    return (
        <>
            {/*modal aceptar documento*/}
            <Modal
                isOpen={openModalConfirm}
                size="medium"
                modalStyle="standard"
                title={languageState.language?.expedientIdentificaction.expedientTitleConfirm}
                closeFunc={() =>
                {
                   closeModalAuthorization()
                }}>
                <div className="row">
                    <div className="col-md-12">
                        <h5>
                            {languageState.language?.expedientIdentificaction.areYouSure}
                        </h5>
                    </div>
                    <div className="col-md-6">
                        <br/>
                        <Button dynamicWidth
                                variant="secondary"
                                onClick={() => { closeModalAuthorization()}}>
                            {languageState.language?.expedientIdentificaction.cancel}
                        </Button>
                    </div>
                    <div className="col-md-6">
                        <br/>
                        {!sendingAuthorization ? (
                                <Button dynamicWidth
                                        variant="primary"
                                        onClick={() => {
                                            putQuoteExpedient(selectIdDoc,statesDocIdentification.autorizado);
                                        }}>
                                    {languageState.language?.expedientIdentificaction.authorize}
                                </Button>
                            ):
                            <ButtonSpinner label= {languageState.language?.expedientIdentificaction.authorizing!}/>
                        }
                    </div>
                </div>
            </Modal>








        </>
    );
};

