import React, { useEffect, useState} from 'react';
import './UnlockPrincipal.scss';
import {
    Badge,
    Button, Modal,
} from "@lmig/lmds-react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import Select from "react-select";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {SingleValue} from "react-select/dist/declarations/src/types";
import {BloqueoCliente} from "../../../models/garantias/BloqueoCliente";
import {InputChange, validRoles} from "../../../class/CommonClass";
import {MotivoBloqueo} from "../../../models/garantias/MotivoBloqueo";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UserModel} from "../../../models/public/UserModel";
import {dispatchPutLockPrincipal, dispatchPutUnLockPrincipal} from "../../../actions/FiadoActions";
import {TextAlert} from "../../public/text-alert/TextAlert";

interface LockPrincipalProps {
    idPrincipal: number;
    showModal: boolean;
    namePrincipal?: string
    clickShowModal: (show: boolean, idPrincipal?: number) => void;
}

export const UnlockPrincipal: React.FC<LockPrincipalProps> = ({idPrincipal, showModal, namePrincipal, clickShowModal}:LockPrincipalProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.unlockPrincipal);

    const dispatch = useDispatch();

    const localStorageClass = new LocalStorageClass();

    const initMotivoBloqueo: MotivoBloqueo = {
        motivo: {
            idMotivo: 0,
            descripcion: ''
        },
        descripcion: '',
        otrosMotivosBloqueo: ''
    }


    const initReason:MotivoRechazoModel ={ idMotivo: 0, descripcion: ''}

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [listReasons, setListReasons] = useState<MotivoRechazoModel []>([]);

    const [loadingReasons, setLoadingReasons] = useState<boolean>(true);

    const [errorReaseons, setErrorReaseons] = useState<boolean>(true);

    const [sendingAuthorization, setSendingAuthorization] = useState<boolean>(false);

    const [listConditionalOption, setListConditionalOption] = useState<ConditionOptionModel[]>([]);

    const [selectedReason, setSelectedReason] = useState<MotivoRechazoModel>(initReason);

    const [descReason, setDescReason] = useState<string>('');

    const [errorLockPrincipal, setErrorLockPrincipal] = useState<boolean>(true);

    const [motivoBloqueo, setMotivoBloqueo] = useState<MotivoBloqueo>(initMotivoBloqueo);

    const [loadingMotivoBloqueo, setLoadingMotivoBloqueo] = useState<boolean>(true);

    const [errorMotivoBloqueo, setErrorMotivoBloqueo] = useState<boolean>(true);

    const [errorAlBloquear, setErrorAlBloquear] = useState<boolean>(false);

    const [msgValidUnLock, setMsgValidUnLock] = useState<string>('');

    const openModal = async () => {
        setLoadingReasons(true);
        setErrorReaseons(false);
        setErrorAlBloquear(false);
        setDescReason('')
        setSelectedReason(initReason)
        setMsgValidUnLock('');
        try {
            const res = await garantiasService.getMotivosDesBloqueo();
            setListReasons(res.data);
            formatList(res.data);
            setLoadingReasons(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorReaseons(true);
                setLoadingReasons(false);

            }
        }
    }

    const formatList = (rejectList: MotivoRechazoModel[]) => {
        let  listaCondicionalAuxiliar: ConditionOptionModel[] = [];
        for(const i of rejectList){
            listaCondicionalAuxiliar.push({
                name: '',
                value: i.idMotivo,
                label: i.descripcion,
                status: 0})
        }
        setListConditionalOption(listaCondicionalAuxiliar);
    }

    const putUnLockPrincipal = async(idPrincipal: number, motivo: MotivoRechazoModel, descripcion: string) => {
        setLoadingReasons(true);
        setErrorLockPrincipal(false);
        setErrorAlBloquear(false);
        const newBloqueoCliente:  BloqueoCliente = {
            descripcion: descripcion,
            motivo: motivo
        }
        try {
            const res = await garantiasService.putDesBloqueaCliente(idPrincipal, newBloqueoCliente);
            console.log(res);
            if (res.status == 202){
                console.log(res.data);
                setLoadingReasons(false);
                setErrorAlBloquear(true);
                setMotivoBloqueo(res.data)

            }else{
                setDescReason('');
                setSelectedReason(initReason)
                setLoadingReasons(false);
                clickShowModal!(false, idPrincipal);
                dispatch(dispatchPutUnLockPrincipal(idPrincipal));
            }


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingReasons(false);
                setErrorLockPrincipal(true);

            }
        }
    }

    const handleInputChangeSelect = (e: SingleValue<ConditionOptionModel>) => {

        console.log(e)
        const auxselectedReasons: MotivoRechazoModel [] = []

        setSelectedReason({idMotivo: e!.value, descripcion: e!.label});
    }

    const handleInputChange = (e: InputChange) =>{
        setDescReason( e.target.value );
    }

    const getMotivosBloqueoFiado = async (idFiado: number) => {
        setLoadingMotivoBloqueo(true);
        setErrorMotivoBloqueo(false);
        try {
            const res = await garantiasService.getBloqueoCliente(idFiado);
            setMotivoBloqueo(res.data)
            setLoadingMotivoBloqueo(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorMotivoBloqueo(true);
                setLoadingMotivoBloqueo(false);

            }
        }
    }

    const onClickUnLock = () => {
        console.log(selectedReason.idMotivo);
        console.log(descReason)
        if (selectedReason.idMotivo == 0){
            setMsgValidUnLock(languageState?.msgValidReason!)
        }
        else if (descReason == ''){
            setMsgValidUnLock(languageState?.msgValidDescription!)
        }
        else if (selectedReason.idMotivo  && descReason != ''){
            putUnLockPrincipal(idPrincipal, selectedReason, descReason);
        }

    }

    useEffect(() => {
        if (showModal){
            openModal();
            getMotivosBloqueoFiado(idPrincipal);

        }

    },[showModal]);


    return (
        <>

            <Modal
                isOpen={showModal}
                size="medium"
                modalStyle="standard"
                title=""
                closeFunc={() => {
                    clickShowModal(false);
                }}
                id="modal-lock-principal"
            >
                <h4>
                    {namePrincipal? languageState?.titleModalto + ' ' + namePrincipal: languageState?.titleModal}
                </h4><br/>
                {  validRoles(PERFIL.roles,
                    localStorageClass.EJECUTIVODEGARANTIAS)?(
                    <>
                        {loadingReasons?(
                                <div className="col-12">
                                    <SpinnerLoading />
                                </div>
                            ):
                            (!errorReaseons)? (
                                    <div className="col-12 list-reject">


                                        {(errorAlBloquear)?<>
                                            <b>
                                                {languageState?.errToUnlock!}:
                                            </b>
                                            <Badge highlightType="negative">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: motivoBloqueo?.otrosMotivosBloqueo }}
                                                />

                                            </Badge><br/>

                                            <div className="col-md-12 col-12">
                                                <Button
                                                    dynamicWidth
                                                    variant="secondary"
                                                    onClick={()=>{
                                                        clickShowModal(false);
                                                    }}>
                                                    {languageState?.close!}
                                                </Button>
                                            </div>
                                        </>:<>
                                            <Select
                                                placeholder={languageState?.selectReasons}
                                                name="blocks"
                                                options={listConditionalOption}
                                                onChange={handleInputChangeSelect}
                                            />

                                            {listReasons.length == 0? (
                                                <div className="col-12">
                                                    <br/><br/>
                                                    <h5>{languageState?.reintent!}</h5>
                                                </div>
                                            ): null}

                                            <div className="add-description">
                                                {languageState?.addDescription}<br/>
                                            </div>
                                            <textarea
                                                className="form-control"
                                                onChange={handleInputChange}
                                                value={descReason}
                                                rows={3}>
                                            </textarea>
                                            <br/>
                                            <div className="row">
                                                {(loadingMotivoBloqueo)?<>
                                                    <div className="col-12 text-center">
                                                        {languageState?.loadingLocked!}
                                                        <SpinnerLoading />
                                                    </div>
                                                </>:(!errorMotivoBloqueo)?(

                                                    (motivoBloqueo?.otrosMotivosBloqueo)?
                                                        <div className="col-12">
                                                            <b>
                                                                {languageState?.otherReasonsLocked!}:
                                                            </b><br/>
                                                            <Badge highlightType="negative">
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: motivoBloqueo?.otrosMotivosBloqueo }}
                                                                />

                                                            </Badge>
                                                            <br/>

                                                        </div>:
                                                        <div className="col-12">
                                                                <b>
                                                                    {languageState?.textLocked!}:
                                                                </b><br/>
                                                                <Badge highlightType="negative">
                                                                    {(motivoBloqueo?.motivo!= null)?motivoBloqueo?.motivo!.descripcion: null}
                                                                </Badge>
                                                                <b>
                                                                    {languageState?.descLocked!}:
                                                                </b><br/>
                                                                {motivoBloqueo?.descripcion} <br/><br/>

                                                            </div>

                                                ): <div className="col-12">
                                                    <NegativeAlert
                                                        textAlert={languageState?.imSorryLocked!}/>
                                                </div>
                                                }
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <TextAlert
                                                        textAlert={msgValidUnLock}/><br/>
                                                </div>
                                            <div className="col-md-6 col-12">
                                                <Button
                                                dynamicWidth
                                                variant="secondary"
                                                onClick={()=>{
                                                    clickShowModal(false);
                                                }}>
                                                {languageState?.close!}
                                                </Button>
                                            </div>
                                            <div className="col-md-6 col-12">
                                            {!sendingAuthorization ? (
                                                <Button
                                                    dynamicWidth
                                                    variant="primary"
                                                    onClick={()=>{
                                                        onClickUnLock()
                                                    }}>
                                                    {languageState?.save!}
                                                </Button>
                                            ):
                                            <ButtonSpinner label={languageState?.saving!}/>
                                            }

                                            </div>

                                            </div>
                                        </>}



                                    </div>
                                ):
                                <div className="col-12">
                                    <NegativeAlert
                                        textAlert={languageState?.sorryReason!}/>
                                </div>
                        }

                    </>):
                    <>
                        <h4>
                            {languageState?.lockPrincipal}<br/>
                            {namePrincipal}
                        </h4><br/>
                        <div className="row">
                            {(loadingMotivoBloqueo)?<>
                                <div className="col-12 text-center">
                                    {languageState?.loadingLocked!}
                                    <SpinnerLoading />
                                </div>
                            </>:(!errorMotivoBloqueo)?(


                                (motivoBloqueo?.otrosMotivosBloqueo)?
                                    <div className="col-12">
                                        <b>
                                            {languageState?.otherReasonsLocked!}:
                                        </b><br/>
                                        <Badge highlightType="negative">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: motivoBloqueo?.otrosMotivosBloqueo }}
                                            />

                                        </Badge>
                                        <br/>

                                    </div>:
                                    <div className="col-12">
                                        <b>
                                            {languageState?.textLocked!}:
                                        </b><br/>
                                        <Badge highlightType="negative">
                                            {(motivoBloqueo?.motivo!= null)?motivoBloqueo?.motivo!.descripcion: null}
                                        </Badge>
                                        <b>
                                            {languageState?.descLocked!}:
                                        </b><br/>
                                        {motivoBloqueo?.descripcion} <br/><br/>

                                    </div>

                            ): <div className="col-12">
                                <NegativeAlert
                                    textAlert={languageState?.imSorryLocked!}/>
                            </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <Button
                                    dynamicWidth
                                    variant="secondary"
                                    onClick={()=>{
                                        clickShowModal(false);
                                    }}>
                                    {languageState?.close!}
                                </Button>
                            </div>


                        </div>
                    </>
                }
            </Modal>
        </>
    );
};