export enum StatusCompromisoEnum {
    porAutorizar = 1,
    vigente = 2,
    vencido = 3,
    rechazado = 4,
    revocado = 5,
    cumplido = 6,
    pendienteAceptarFiado = 7,
    rechazadoPorFiado = 8,
    porAutorizarPlazo = 9,
    revertirCumplimiento = 10
}