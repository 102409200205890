import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import NavBar from '../../../components/public/nav-bar/NavBar';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import './Crear-auditoria.scss'
import { Alert, Button, Form, GridCol, GridRow, IconButton, IconDocument, IconLegalClaim, IconUpload, Select, SelectOption, Table, TableCell, TableRow } from '@lmig/lmds-react';
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import { archAudit, CreateAudi, DetallesAuditOficina, FileBase64 } from '../../../models/buro-credito/Datos';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import { AxiosError } from 'axios';
import { UserModel } from '../../../models/public/UserModel';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import Swal from 'sweetalert2';
import Email from '@lmig/lmds-react/icons/Email';


export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface vencidProps {
    language: DefaultStateI
}



class CrearAuditoria extends Component<vencidProps & RouteComponentProps, Audistate> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    public archivosBase64: FileBase64[] = [];
    public initDatosAudi: CreateAudi = {
        tipoPersona: '',
        fechaInicio: '',
        fechaFin: '',
    }
    options: any;

    public initFile64: FileBase64 = {
        archivobase64: '',
        archivonombre: '',
        email: '',
        nombre: '',
        sistema: 0
    }

    constructor(props: any) {
        super(props);
        const audit: archAudit = {

            archAuditoriaBase64: '',
            nomAuditoriaBase64: '',
            nombreArchivo: '',
            nomzipCartasBase64: '',
            url: '',
            zipCartasBase64: '',
        };
        this.state = {
            estadoCaptura: 1,
            pdfShow: false,
            consulta: true,
            DatosAudi: this.initDatosAudi,
            variableAux: {
                tipoPersona: '',
                fechaInicio: '',
                fechaFin: '',
            },
            formData: new FormData(),
            PERFIL: this.localStorageClass.getProfile(),
            folioAuditoria: '',
            foliosProcesados: '',
            fechaConsulta: '',
            prueba: '',
            ListAuditOficina: [],
            validador: false,
            Consul_buro: false,
            btnnext: true,
            table: 0,
            idOficina: 0,
            file64: this.initFile64,
            datosAuditoria: audit,
            Auxfile64: this.initFile64,
            isValid1: false,
            isValid2: false,
            email: '',
            nombre: ''

        }
        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.Endquery = this.Endquery.bind(this);
        this.Send = this.Send.bind(this);
        this.EnviarDatos = this.EnviarDatos.bind(this);

    }





    render(): JSX.Element {
        return <>
            <div className="container-fluid audi" id="Create-Auditoria">
                <NavBar />

                <div className="Formulario" id='formulario'>

                    <BreadCumb title={this.props.language.language?.CreateAudi.NewAudi!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.CreateAudi.Home!, url: '/buro-credito/Auditoria' },
                            { title: this.props.language.language?.CreateAudi.NewAudi!, url: '/buro-credito/Crear-Auditoria' }
                        ]} />



                    <Form id="form-query" method="post" encType='multipart/form-data' >
                        <div className="col-md-12" id='consult'>
                            {this.state.estadoCaptura === 1 ? (
                                <div className="col-md-12" id='datos'>
                                    <div className="Auditoria-container">

                                        <br /><br />
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-4 ">
                                                    <p>{this.props.language.language?.CreateAudi.TipoPersona!}</p>

                                                    <Select
                                                        id="tipoPersona"
                                                        name='tipoPersona'
                                                        labelVisual={this.props.language.language?.CreateAudi.select!}
                                                        onChange={this.handleinputChange}
                                                        value={this.state.DatosAudi.tipoPersona.toString()}


                                                    >
                                                        <SelectOption value="2">
                                                            {this.props.language.language?.CreateAudi.TipoPersonaM!}
                                                        </SelectOption>
                                                        <SelectOption value="1">
                                                            {this.props.language.language?.CreateAudi.TipoPersonaF!}
                                                        </SelectOption>

                                                    </Select>

                                                </div>

                                                <div className="col-4 ">
                                                    <p>{this.props.language.language?.CreateAudi.FechaInicio!}</p>
                                                    <input
                                                        type='date'
                                                        id="fechaInicio"
                                                        name='fechaInicio'
                                                        className="form-control"
                                                        value={this.state.DatosAudi.fechaInicio}
                                                        onChange={this.handleinputChange}
                                                        onBlur={this.handleinputChange}
                                                    />
                                                    {this.state.isValid1 ? (
                                                        <div className="col-12">
                                                            <Alert id='alert' highlightType="negative">
                                                                {this.props.language.language?.createquery.fechaInco!}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>
                                                        </>}
                                                </div>
                                                <div className="col-4 ">
                                                    <p>{this.props.language.language?.CreateAudi.FechaFin!}</p>
                                                    <input
                                                        type='date'
                                                        id="fechaFin"
                                                        name='fechaFin'
                                                        className='form-control'
                                                        value={this.state.DatosAudi.fechaFin}
                                                        onChange={this.handleinputChange}
                                                        onBlur={this.handleinputChange}
                                                    />
                                                    {this.state.isValid2 ? (
                                                        <div className="col-12">
                                                            <Alert id='alert' highlightType="negative">
                                                                {this.props.language.language?.createquery.fechaInco!}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>
                                                        </>}
                                                </div>

                                            </div>

                                        </div>
                                        <br /><br /><br />

                                    </div>
                                    <br />

                                    <div className="container">
                                        <div className="card" id='cards-idcartas'>
                                            <div className="file-upload" id='image-upload-wrap'>

                                                <input
                                                    type="file"
                                                    id='file-upload-input'
                                                    accept='.xlsx, .xls, .csv'
                                                    onChange={this.handleChange}
                                                />
                                                <div className="card-body text-center">
                                                    <h3 className="card-title">{this.props.language.language?.CreateAudi.Autorizacion!}</h3>

                                                    <div className="text-information">
                                                        <IconUpload size="64" color='gray' /><br />
                                                        {this.props.language.language?.CreateAudi.arrastra!} <br />
                                                        {this.props.language.language?.CreateAudi.file!}


                                                        {this.state.consulta === false &&
                                                            <div className='col-12' id='alert'>
                                                                <Alert id='alert_up' highlightType="positive">
                                                                    {this.props.language.language?.CreateAudi.alert_up!}
                                                                </Alert>
                                                            </div>}
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <br /><br /> <br />
                                    <GridRow gutterSize="fixed-16" gutters>
                                        <GridCol base={12} md={6}>
                                            <Button dynamicWidth onClick={this.Endquery}>
                                                {this.props.language.language?.CreateAudi.PrevPage!}
                                            </Button>
                                        </GridCol>
                                        <GridCol base={12} md={6}>
                                            <Button dynamicWidth variant="primary" onClick={this.NextPage} disabled={this.state.btnnext} >
                                                {this.props.language.language?.CreateAudi.NextPage!}
                                            </Button>
                                        </GridCol>
                                    </GridRow>
                                </div>
                            ) : null}





                        </div>
                    </Form>
                    {this.state.estadoCaptura === 2 ? (
                        <>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm">
                                        <h4>{this.props.language.language?.CreateAudi.FolioAuditoria!}:{this.state.folioAuditoria}</h4>
                                    </div>
                                    <div className="col-sm">
                                        <h4>{this.props.language.language?.CreateAudi.FoliosProce!}:{this.state.foliosProcesados}</h4>
                                    </div>
                                    <div className="col-sm">
                                        <h4>{this.props.language.language?.CreateAudi.Fecha!}:{this.state.fechaConsulta}</h4>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {this.state.Consul_buro ? (
                                <div className="col-12">
                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                </div>
                            ) :
                                <>
                                    {this.state.table === 1 &&
                                        <div className="col-md-12 col-sm-12">
                                            <Table id='table-Auditoria' >
                                                <thead>
                                                    <TableRow >
                                                        <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                            {this.props.language.language?.CreateAudi.Oficina!}
                                                        </TableCell>
                                                        <TableCell dataHeader={'fechaCarga'} type="colHead" className='tb'>
                                                            {this.props.language.language?.CreateAudi.NumerFolios!}
                                                        </TableCell>
                                                        <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                            {this.props.language.language?.CreateAudi.Consultas!}
                                                        </TableCell>
                                                        <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                            {this.props.language.language?.CreateAudi.Reporte!}
                                                        </TableCell>

                                                    </TableRow>
                                                </thead>


                                                <tbody>
                                                    {this.state.validador === true ? (
                                                        <>
                                                            {
                                                                this.state.ListAuditOficina && this.state.ListAuditOficina.map((value: DetallesAuditOficina, index: number) => (


                                                                    <TableRow key={index} id={value.foliosDuplicados} >

                                                                        <TableCell type="rowHead" className={(value.oficina === 'Folios sin conciliar' ? "Rojo" : '' || value.oficina === 'Total' ? "Verde" : '')}>
                                                                            {value.oficina}
                                                                        </TableCell>
                                                                        <TableCell className={(value.oficina === 'Folios sin conciliar' ? "Rojo" : '' || value.oficina === 'Total' ? "Verde" : '')}>
                                                                            {value.totalNumFolios}
                                                                        </TableCell>
                                                                        <TableCell className={(value.oficina === 'Folios sin conciliar' ? "Rojo" : '' || value.oficina === 'Total' ? "Verde" : '')}>
                                                                            {value.foliosDuplicados}
                                                                        </TableCell>


                                                                        <TableCell dataHeader={'Estatus'} className="estatus">
                                                                            <IconButton background="transparent" onClick={() => this.handleView(value.idOficina, value.email, value.nombre)}>
                                                                                <IconDocument color="informative" size="24" />
                                                                            </IconButton>
                                                                        </TableCell>

                                                                    </TableRow>

                                                                ))
                                                            }


                                                        </>
                                                    ) : null}
                                                </tbody>

                                            </Table>
                                            <br />

                                        </div>
                                    }</>}

                            <div className="boton" >
                                <GridRow gutterSize="fixed-16" gutters>
                                    <GridCol base={12} md={6}>
                                        <Button className='btn-exit-audit' variant="secondary" dynamicWidth onClick={this.Endquery}>
                                            {this.props.language.language?.CreateAudi.Exit!}
                                        </Button>
                                    </GridCol>
                                    <GridCol base={12} md={6}>
                                        <Button dynamicWidth variant="primary" onClick={this.Send}>
                                            {this.props.language.language?.CreateAudi.Finalize!}
                                        </Button>
                                    </GridCol>
                                </GridRow>

                            </div>

                        </>) : null}



                </div>
            </div>

        </>
    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    //funcion regresar de pagina
    public PrevPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura - 1 })

    }

    //funcion siguiente página y mandamos llamar la funcion para enviar los datos
    public NextPage() {
        this.setState({ Consul_buro: true })
        const { estadoCaptura } = this.state
        this.EnviarDatos(this.state.formData);
        this.setState({ estadoCaptura: estadoCaptura + 1 })
    }

    //Ruta para regresar al Dashboard
    public Endquery() {
        this.props.history.push('/buro-credito/Auditoria');
    }

    //funcion para enviar los archivos ne base64
    public Send() {

        for (const i in this.archivosBase64) {
            //console.log(i, ':', this.archivosBase64[i]);
            var xs = []
            xs.push(this.archivosBase64[i])
            // console.log(xs[0])
            var pr = xs[0]
            this.postFileBase64(pr);
        }

        this.alert();

    }

    //para descagar el archivo excel
    public handleView(idOficina: string, email: string, nombre: string) {
        const idOfi = Number(idOficina);
        this.setState({ idOficina: idOfi }, () =>
            this.postURl());
        this.setState({ email: email })
        this.setState({ nombre: nombre })

    }



    //funcion para obtener la url de los archivos excel
    public async postURl() {


        try {

            const res = await getCreditbureau.getURLReporte(this.state.variableAux.fechaFin, this.state.variableAux.fechaInicio, this.state.folioAuditoria, this.state.idOficina, this.state.DatosAudi.tipoPersona);
            const urldata = JSON.stringify(res.data.url)
            const comillas = urldata.replace(/['"]+/g, '')
            // console.log(comillas)
            if (res.data.nombreArchivo === 'No existe') {
                this.alertArchivo();
            } else {
                var link = document.createElement('a');
                link.href = comillas;
                link.download = 'true';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            //console.log(res.data.nomAuditoriaBase64)
            //console.log(res.data.archAuditoriaBase64)
            this.setState({ datosAuditoria: res.data })
            var nombrepdf = this.state.datosAuditoria.nombreArchivo;
            var filebase64 = this.state.datosAuditoria.archAuditoriaBase64;

            this.setState({
                file64:
                {
                    ...this.state.file64,
                    archivobase64: filebase64,
                    archivonombre: nombrepdf,
                    email: this.state.email,
                    nombre: this.state.nombre,
                    sistema: 2
                }
            })


            if (this.state.datosAuditoria.archAuditoriaBase64 !== null) {

                this.archivosBase64.push(this.state.file64)
            }

            if (this.state.datosAuditoria.nomzipCartasBase64 !== null) {
                this.setState({
                    Auxfile64:
                    {
                        ...this.state.Auxfile64,
                        archivobase64: this.state.datosAuditoria.zipCartasBase64,
                        archivonombre: this.state.datosAuditoria.nomzipCartasBase64,
                        email: this.state.email,
                        nombre: this.state.nombre,
                        sistema: 2
                    }
                })
                console.log(this.state.Auxfile64)
                let found = this.archivosBase64.find((archivo: FileBase64) => archivo.archivonombre === this.state.Auxfile64.archivonombre);
                console.log(found)
                if (!found) {
                    this.archivosBase64.push(this.state.Auxfile64)
                }



            }


            console.log(this.archivosBase64)



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }

    public handleinputChange = (e: InputChange,): void => {
        e.persist();
        this.setState(preveState => ({
            DatosAudi: { ...this.state.DatosAudi, [e.target.name]: e.target.value }

        }))
        var x = this.state.DatosAudi
        this.setState({ variableAux: x })

        //console.log(this.state.DatosAudi)
        if (this.state.DatosAudi.fechaInicio !== '') {
            var fechaA = this.state.DatosAudi.fechaInicio.split("-")
            var fechA = fechaA[2] + "-" + fechaA[1] + "-" + fechaA[0];
            //this.setState({ fechaAutoAux: fech })
            // console.log(this.state.fechaAutoAux)
            var isValid1 = fechA.match(/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/);

            if (isValid1) {
                // console.log("fecha Correcta.");
                this.setState({ isValid1: false })
            } else {
                //console.log("Fecha Incorrecta");
                this.setState({ isValid1: true })
            }
        }
        if (this.state.DatosAudi.fechaFin !== '') {
            var fechaAs = this.state.DatosAudi.fechaFin.split("-")
            var fechAas = fechaAs[2] + "-" + fechaAs[1] + "-" + fechaAs[0];
            //this.setState({ fechaAutoAux: fech })
            // console.log(this.state.fechaAutoAux)
            var isValid2 = fechAas.match(/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/);

            if (isValid2) {
                // console.log("fecha Correcta.");
                this.setState({ isValid2: false })
            } else {
                //console.log("Fecha Incorrecta");
                this.setState({ isValid2: true })
            }
        }

    }


    public async EnviarDatos(formData: FormData) {
        try {
            var fecha = this.state.variableAux.fechaInicio.split("-")
            this.state.variableAux.fechaInicio = '';
            this.state.variableAux.fechaInicio = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
            // console.log(this.state.variableAux.fechaInicio)
            var fechas = this.state.variableAux.fechaFin.split("-")
            this.state.variableAux.fechaFin = ''
            this.state.variableAux.fechaFin = fechas[2] + '/' + fechas[1] + '/' + fechas[0];
            // console.log(this.state.variableAux)
            const res = await getCreditbureau.postFile(formData)
            const prueba = res.data
            const pruebas = Object.values(prueba)
            const pr = pruebas[0].toString()
            this.setState({ prueba: pr }, () =>
                this.getdetalleFoliosOficinas(this.state.variableAux.fechaFin, this.state.variableAux.fechaInicio, this.state.prueba));

            this.getDetalleFolProcess(this.state.prueba)

            console.log(this.state.prueba)

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }


    public handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({
            variableAux:
            {
                ...this.state.variableAux,
                tipoPersona: this.state.DatosAudi.tipoPersona,
                fechaInicio: this.state.DatosAudi.fechaInicio,
                fechaFin: this.state.DatosAudi.fechaFin,
            }
        })
        if (e.target.files![0].size > 10097152) {

            const fileObj = e.target.files && e.target.files[0];
            if (!fileObj) {
                return;

            }

            this.setState({ consulta: true })
            this.setState({ pdfShow: true })

            //console.log('fileObj is', fileObj);

            // 👇️ reset file input
            e.target.value = '';

            // 👇️ is now empty
            //console.log(e.target.files);

        } else {

            if (this.state.variableAux.fechaInicio === undefined && this.state.DatosAudi.fechaFin === undefined) {
                this.state.variableAux.fechaInicio = '';
                this.state.variableAux.fechaFin = ''
            }

            var fecha = this.state.variableAux.fechaInicio.split("-")
            this.state.variableAux.fechaInicio = '';
            this.state.variableAux.fechaInicio = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
            // console.log(this.state.variableAux.fechaInicio)
            var fechas = this.state.variableAux.fechaFin.split("-")
            this.state.variableAux.fechaFin = ''
            this.state.variableAux.fechaFin = fechas[2] + '/' + fechas[1] + '/' + fechas[0];
            // console.log(this.state.variableAux)

            if ((e)) {


                const formDataAuxiliar = this.state.formData
                formDataAuxiliar.set('fechaFin', this.state.variableAux.fechaFin);
                formDataAuxiliar.set('fechaInicio', this.state.variableAux.fechaInicio);
                formDataAuxiliar.set('fileName', e.target.files![0]);
                formDataAuxiliar.set('tipoPersona', this.state.DatosAudi.tipoPersona);
                formDataAuxiliar.set('usuario', this.state.PERFIL.username);
                this.setState({ formData: formDataAuxiliar })
                //    console.log(this.state.formData)
                this.setState({ consulta: false })
                //   console.log(e.target.files![0].arrayBuffer)
                this.setState({ pdfShow: true })
                this.setState({ btnnext: false })



            }

        }

    }


    public async getDetalleFolProcess(FolioAudit: string) {

        try {
            const res = await getCreditbureau.getDetalleFolProcess(FolioAudit)
            const folio = res.data.folioAuditoria
            const foliosProcesados = res.data.foliosProcesados
            const fechaConsulta = res.data.fechaConsulta
            this.setState({ folioAuditoria: folio })
            this.setState({ foliosProcesados: foliosProcesados })
            this.setState({ fechaConsulta: fechaConsulta })
            //this.getdetalleFoliosOficinas(this.state.folioAuditoria)
        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }

    public async getdetalleFoliosOficinas(fechaFin: string, fechaInicio: string, FolioAudit: string) {
        try {

            const res = await getCreditbureau.getdetalleFoliosOficinas(fechaFin, fechaInicio, FolioAudit)
            const response = res.data
            let x: DetallesAuditOficina[] = []//listado
            let y = {}
            res.data.forEach((Item: DetallesAuditOficina) => {
                if (Item.oficina === 'Folios sin conciliar') {
                    y = Item
                } else {
                    x.push(Item)
                }
            })
                ; (x as any).push(y)
            this.setState({ ListAuditOficina: x })



            if (response == null) {
                this.setState({ Consul_buro: true })
                this.setState({ validador: false })
                this.setState({ table: 0 })

            } else {
                this.setState({ Consul_buro: false })
                this.setState({ validador: true })
                this.setState({ table: 1 })
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    //Envio de archivos base64

    public async postFileBase64(file: FileBase64) {
        try {

            await getCreditbureau.postFileBase64(file);


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    //alertas
    public alert() {
        Swal.fire(
            'Archivos enviados con exito!',
            '',
            'success'
        ).then((result) => {
            if (result.isConfirmed) {
                this.Endquery();
            }

        })
    }

    public alertArchivo() {
        Swal.fire(
            'No existe archivo!',
            '',
            'error'
        )
    }

}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(CrearAuditoria);


interface Audistate {
    estadoCaptura: number;
    pdfShow: boolean;
    consulta: boolean;
    DatosAudi: CreateAudi;
    variableAux: CreateAudi;
    formData: FormData,
    PERFIL: UserModel,
    folioAuditoria: string,
    foliosProcesados: string,
    fechaConsulta: string,
    prueba: string,
    ListAuditOficina: DetallesAuditOficina[],
    validador: boolean,
    Consul_buro: boolean,
    btnnext: boolean,
    table: number,
    idOficina: number,
    file64: FileBase64,
    datosAuditoria: archAudit,
    Auxfile64: FileBase64,
    isValid1: boolean,
    isValid2: boolean,
    email: string,
    nombre: string

}




