import React  from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import './NavBarPublic.scss';
import logo from '../../../assets/ico/logo-web-liberty16.png';
import {useHistory} from "react-router-dom";
import SelectLanguage from "../select-language/SelectLanguage";
import environment from "../../../environments/environment";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import NameSystem from "../name-system/NameSystem";

const NavBarPublic = () => {
    const history = useHistory();
    const languageState = useSelector((state: RootStore) => state.language.language?.navBarPublic);

    const  URLSERF = environment.URLSERF
    const  URLVALFIANZA = environment.URLVALFIANZA
    const  URLSOPORTE = environment.URLSOPORTE
    const  URLRECUPERACFDI = environment.URLRECUPERACFDI
    const  URLPAGOLINEA = environment.URLPAGOLINEA
    const  URLOPERPREOCUPANTE = environment.URLOPERPREOCUPANTE

    const openNewWindow= (src: string,
                          width: number,
                          height: number) =>  {
        // eslint-disable-next-line no-restricted-globals
        const screenWidth = screen.width
        // eslint-disable-next-line no-restricted-globals
        const screenHeight = screen.height


        let left = (screenWidth/2) - (width / 2);
        let top = (screenHeight/2) - (height / 2);
        window.open (src,
            "_blank",
            "menubar=1," +
            "resizable=1," +
            "width=" + width + "," +
            "height=" + height +"," +
            "top=" + top +"," +
            "left=" + left
        )
    }

    return (
        <div id="nav-bar-public">
            <Navbar expand="md" fixed="top">
                <Navbar.Brand
                    onClick={()=>{
                        history.push('/');
                    }}
                >
                    <img
                    src={logo}
                    width="89.6" height="42.64"
                    className="d-inline-block align-top ico-liberty d-block d-sm-block d-md-block d-lg-none d-xl-none"
                    alt=""
                />


                </Navbar.Brand>
                <div id="select-language-sm">
                    <SelectLanguage />
                </div>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse className="justify-content-center  content-nav-bar" id="basic-navbar-nav">

                    <a className="logo-liberty-nav d-none d-sm-none d-md-none d-lg-block d-xl-block"
                       onClick={() => {
                           history.push('/')
                       }}>
                        <img
                            src={logo}
                            className="d-block mx-auto align-top logo-nav"
                            alt=""
                        />
                    </a>
                    <Nav className="mr-auto">

                        {(environment.env == "dev" || environment.env == "test")  &&
                        <Nav.Link className="link-nav">
                            <NameSystem/>
                        </Nav.Link>
                        }

                        {environment.env == "prod" &&
                        <Nav.Link
                            className="link-nav"
                            onClick={()=> {
                                openNewWindow(URLSERF, 550, 287)
                            }}
                        >
                            {languageState?.SERF}
                        </Nav.Link>}

                        {environment.env == "prod" &&
                        <Nav.Link className="link-nav"
                                  onClick={()=> {
                                      openNewWindow(URLVALFIANZA, 1020, 700)
                                  }}>
                            {languageState?.validLoans}
                        </Nav.Link>}


                        {environment.env == "prod" &&
                            <Nav.Link className="link-nav"
                                      onClick={()=> {
                                          openNewWindow(URLSOPORTE, 1020, 700)
                                      }}>
                                {languageState?.supportApps}
                            </Nav.Link>}


                        {(environment.env == "dev" )  &&
                            <Nav.Link className="link-nav"
                                      onClick={()=> {
                                          history.push('/login')
                                      }}>
                                {languageState?.login}
                            </Nav.Link>}

                    </Nav>

                    <Navbar.Text className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <SelectLanguage />
                    </Navbar.Text>

                </Navbar.Collapse>


            </Navbar>



        </div>
    );
};

export default NavBarPublic;
