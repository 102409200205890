import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { AxiosError } from 'axios';
import './MsgFirma.scss';
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import NavBar from "../../../components/public/nav-bar/NavBar";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import {
    Button,
    IconSuccessAlert,
    IconErrorAlert,
    Content
} from "@lmig/lmds-react";
import * as apiService from '../../../services/recibo-caja/ReciboCajaService';

interface MsgFirmaProps {
    language: DefaultStateI
}


class MsgFirma extends Component<MsgFirmaProps & RouteComponentProps, MsgFirmaState>{


    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        document.title = "Confirmacíon Firma"

        this.state = {
            id: '',
            doc: '',
            evento: '',
            loadingMsg: true,
            alert: false,
            seconds: 6,
            ref: "",
        }

    }

    render() {

        return <>
            <NavBar />
            <Content id="msg-frima" >
                <div className="contenedor">

                    <div className="center">

                        {this.state.loadingMsg ?
                            (<div className="col-12">
                                <SpinnerLoading text={this.props.language.language?.msgFirma.msgLoading!} />
                            </div>) :
                            (<div>
                                {this.state.alert ? (
                                    <h1>{this.props.language.language?.msgFirma.msgSuccess}</h1>) :
                                    (<h1>{this.props.language.language?.msgFirma.msgError}</h1>)}

                                {this.state.alert ? (<div><IconSuccessAlert color='positive' size='64' className="mb-4 mt-4" />
                                    <h2>{this.state.seconds}</h2></div>) :
                                    (<IconErrorAlert color='negative' size='64' className="mb-4 mt-4" />
                                    )
                                }
                                <p></p>
                                <Button
                                    variant='primary'
                                    size='small'
                                    type='button'
                                    className=""
                                    onClick={() => this.backWindow()}
                                >{this.props.language.language?.msgFirma.btnBack}</Button>
                            </div>)}

                    </div>

                </div>
            </Content>
        </>
    }

    componentDidMount() {
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        const id = urlParams.get('id');
        const doc = urlParams.get('doc');
        const evento = urlParams.get('event');
        const ref = urlParams.get('ref');
        this.setState({
            id: id!,
            doc: doc!,
            evento: evento!,
            ref: ref!,
        })
        if (evento === 'signing_complete' && id !== null && doc !== null && ref !== null) {
            this.putIdDocumento(doc!, id!);
            let sampleInterval = setInterval(() => {
                if (this.state.seconds > 0) {
                    this.setState({ seconds: this.state.seconds - 1 })
                    console.log(this.state.seconds);

                }
                if (this.state.seconds === 1) {
                    clearInterval(sampleInterval);
                    this.props.history.push(`/tesoreria?ref=${ref}`)
                }
            }, 1000);
            return () => {
                clearInterval(sampleInterval);
            };
        } else {
            this.setState({
                alert: false,
                loadingMsg: false,
                ref: ""
            })
        }



    }
    public backWindow() {
        this.props.history.push(`/tesoreria?ref=${this.state.ref}`);
    }
    public async putIdDocumento(doc: string, id: string) {
        try {

            const res = await apiService.putIdDocumento(doc, id);
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    alert: true,
                    loadingMsg: false
                })
                window.opener?.location.reload();
            } else {
                this.setState({
                    alert: false,
                    loadingMsg: false
                })
            }

        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                this.setState({
                    alert: false,
                    loadingMsg: false,
                    ref: ''
                })
                console.log(error.response.status);
                console.log(error.response.data);
            }
        }
    }

}


const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(MsgFirma);

interface MsgFirmaState {
    id: string,
    doc: string,
    evento: string,
    loadingMsg: boolean,
    alert: boolean,
    seconds: number;
    ref: string;
}