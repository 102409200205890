import axios from 'axios';
import environment from "../../environments/environment";
import {UserModel} from "../../models/public/UserModel";
import {LocalStorageClass} from "../../class/LocalStorageClass";
import {LoginModel} from "../../models/public/LoginModel";

const  API = environment.APISESSION
const localStorageClass = new LocalStorageClass();

const  headers = {
    Accept: 'application/json',
    'X-API-KEY': environment.APIKEYSESSION,
   // Authorization: localStorageClass.getProfileToken(),
    env: environment.env
}

export const getSigninUrb = async (urb: string) => {
    return await axios.get<UserModel>(API+'/signinurb?urb='+ urb , { headers: headers });
};
//https://lf-sgf-general-api-impl-development.us-east-1.np.paas.lmig.com/signin
export const postSignin= async (login: LoginModel) => {
    return await axios.post<UserModel>(API+'/signin', login , { headers: headers });
};

export const getValidaToken= async (token: string) => {
    return await axios.get<UserModel>(API+'/validaToken?Valida=' + token , { headers: headers });
};



