import { Component } from 'react';
import './FindClient.scss';
import { Button, Modal } from "@lmig/lmds-react";
import Autocomplete from 'react-autocomplete';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { RootStore } from '../../../Store';
import { connect } from 'react-redux';
import { UserModel } from '../../../models/public/UserModel';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { Funcionarios } from '../../../models/compromisos/Funcionarios';
import { CodigoRespuesta } from '../../../models/compromisos/CodigoRespuesta';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { NumeroCompromisos } from '../../../models/compromisos/NumeroCompromisos';
import { AxiosError } from 'axios';
import SuccessAlert from '@lmig/lmds-react/icons/SuccessAlert';



class FindComite extends Component<FindProps, FindState> {


    public idFuncionario: number;
    public idCompromiso: number;
    public busquedas: string;

    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        this.idFuncionario = 0;
        this.idCompromiso = this.props.idCompromiso;
        this.busquedas = '';


        const PERFIL = this.localStorageClass.getProfile();


        this.state = {
            numCompromisos: 0,
            listNumeroC: {
                compromisosApoyo: 0,
                compromisosBloqueoB: 0,
                compromisosBloqueoG: 0,
                numeroGarantia: 0,
                descripcionGarantia: ""
            },
            idFuncionarioSelecccionado: 0,
            openModalClose: false,
            notificacion: {
                codigo: 0,
                descripcion: ''
            },
            listFuncionarios: [],
            val: '',
            PERFIL: PERFIL,

        }
        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
        this.handleOnMenu = this.handleOnMenu.bind(this)
        this.handleSend = this.handleSend.bind(this)
    }

    componentDidMount() {

        console.log("ID COMPROMISO: " + this.idCompromiso)
        this.getComite();
        this.getNumeroDeCompromisos();
    }


    render(): JSX.Element {


        return (

            <div className={"expedient-warranty"}>
                <a className="open-expedient text-center" onClick={() => {
                    this.setState({ openModalClose: true })
                }}>

                    <Button variant="primary"
                        className="float-right w-100">
                        {this.props.language.language?.findClient.continuar}
                    </Button>
                </a>

                <Modal
                    isOpen={this.state.openModalClose}
                    size="large"
                    modalStyle="standard"
                    title={this.props.language.language?.findClient.seleccionaComite}
                    id="modal3"
                    closeFunc={() => {
                        this.setState({ openModalClose: false })
                    }}>

                    <h3> {this.props.language.language?.findClient.leyenda3} {this.state.numCompromisos} {this.props.language.language?.findClient.leyenda32}
                        <br />  {this.props.language.language?.findClient.leyenda2}<br /> <br />
                    </h3>

                    <div className="autocomplete-wrapper-cliente">
                        <Autocomplete
                            value={this.state.val}
                            items={this.state.listFuncionarios}
                            getItemValue={item => item.nombre + item.idFuncionario}
                            shouldItemRender={this.renderClientName}
                            renderMenu={item => (
                                <div className="dropdown">
                                    {item}
                                </div>
                            )}
                            renderItem={(item, isHighlighted) =>
                                <div className={`item ${isHighlighted ? 'selected-item' : 'title'}`} >
                                    {`${item.nombre}`}
                                </div>
                            }

                            onMenuVisibilityChange={this.handleOnMenu}
                            onChange={(event, val) => this.setState({ val })}
                            //onSelect={val => this.setState({ val }) }  
                            onSelect={this.handleInputChangeSelect}
                        />
                    </div>

                    <br />
                    <br />

                    <div className="select-fiados">
                        {this.state.idFuncionarioSelecccionado == 0 ? (
                            <Button variant="primary" disabled>
                                {this.props.language.language?.findClient.sent}
                            </Button>

                        ) : <Button
                            //href="/compromisos"
                            variant="primary" onClick={this.handleSend}>
                            {this.props.language.language?.findClient.sent}
                        </Button>}
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.openModalOperacionExitosa}
                    size="medium"
                    modalStyle="standard"
                    //className="modalExito"
                    id="modal2"
                    closeFunc={() => {
                        this.setState({ openModalOperacionExitosa: false })
                    }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-4 alert">
                                <SuccessAlert
                                    size="64"
                                    color="positive">
                                </SuccessAlert>
                            </div>
                            <h2 className="texto"> {this.props.language.language?.createEngagement.operacionExitosa} </h2>
                            <br />
                            <Button href="/compromisos"
                                variant="primary"
                                className="btn-ok">
                                {this.props.language.language?.createEngagement.aceptar}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    async getNumeroDeCompromisos(): Promise<void> {

        const res = await engagementService.getNumCompromisos(this.props.idFiado, this.props.idCompromiso);
        this.setState({ listNumeroC: res.data });

        //A partir del séptimo compromiso de desbloqueo por Fiado por el mismo concepto lo deberá de autorizar el Comité de Suscripción.
        if (this.state.listNumeroC.compromisosApoyo.toString() >= '7') {
            this.setState({ numCompromisos: this.state.listNumeroC.compromisosApoyo })
        }

        if (this.state.listNumeroC.compromisosBloqueoB.toString() >= '7') {
            this.setState({ numCompromisos: this.state.listNumeroC.compromisosBloqueoB })
        }

        if (this.state.listNumeroC.compromisosBloqueoG.toString() >= '7') {
            this.setState({ numCompromisos: this.state.listNumeroC.compromisosBloqueoG })
        }
    }

    async getComite(): Promise<void> {
        const res = await engagementService.getComite();
        this.setState({ listFuncionarios: res.data });
        console.log("Se obtiene el Comite " + this.state.listFuncionarios)
        //console.log(res.data) 

        this.clientData();
    }


    async postNotificaComiteS(idCompromiso: number, idFuncionario: number): Promise<void> {
        try {
            const res = await engagementService.postNotificaComiteS(idCompromiso, idFuncionario);
            this.setState({ notificacion: res.data })

            if (this.state.notificacion.codigo == 1) {

                console.log("Se envió correctamente la notificacion al funcionario")
            } else {

                console.log("Algo ocurrió en la notificacion")
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }

    renderClientName(state: { nombre: string, rfc: string }, val: string) {

        const nombreCompleto = state.nombre + '--' + state.rfc
        return (
            (nombreCompleto.toLowerCase().indexOf(val.toLowerCase()) !== -1)
        );
    }

    clientData() {
        let listaClientesAuxiliar: Funcionarios[];
        listaClientesAuxiliar = []

        for (const condition of this.state.listFuncionarios) {
            listaClientesAuxiliar.push({ idFuncionario: condition.idFuncionario, nombre: condition.nombre })
        }

        this.setState({ listFuncionarios: listaClientesAuxiliar })
        return [
            this.state.listFuncionarios,
            //console.log("Se obtiene la lista de Funcionarios")
        ]
    }

    handleInputChangeSelect(value: string, item: Funcionarios): void {

        console.log("ID FUNCIONARIO: " + item.idFuncionario)
        console.log("NOMBRE FUNCIONARIO: " + item.nombre)
        //console.log("VALUE: " + value)

        if (item.idFuncionario != 0) {
            this.setState({ val: item.nombre })
            this.idFuncionario = item.idFuncionario;
            this.setState({ idFuncionarioSelecccionado: this.idFuncionario })

        } else {
            this.setState({ idFuncionarioSelecccionado: 0 })
        }
    }

    handleSend(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void {
        console.log("Funcionario: " + this.state.idFuncionarioSelecccionado)

        this.setState({ openModalOperacionExitosa: true })
        this.setState({ openModalClose: false })

        if (this.state.idFuncionarioSelecccionado != 0) {

            this.postNotificaComiteS(this.idCompromiso, this.idFuncionario)
        } else {
            console.log("No has seleccionado un funcionario")
        }
    }

    handleOnMenu(isOpen: boolean): void {

        if (isOpen == true) {
            this.setState({ idFuncionarioSelecccionado: 0 })
            //console.log("Entro al menu")
            this.getComite();
        } else {
            this.setState({ idFuncionarioSelecccionado: 0 })
        }
    }
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(FindComite);


interface FindProps {

    idCompromiso: number;
    idFiado: number;
    language: DefaultStateI;
}

interface IListState {
    //cliente: Cliente[],

}

interface FindState {

    listFuncionarios: Funcionarios[],
    openModalClose?: boolean,
    openModalOperacionExitosa?: boolean,
    val: string,
    PERFIL: UserModel;
    notificacion: CodigoRespuesta;
    idFuncionarioSelecccionado: number;
    listNumeroC: NumeroCompromisos;
    numCompromisos: number;
}



