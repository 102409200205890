
export interface NavBarPublicLanguage {
    SERF: string;
    validLoans: string;
    supportApps: string;
    recuverCFDI: string;
    payinLine:string;
    worryingOperation: string;
    login: string;
}

export const NavBarPublicLanguageEnglish: NavBarPublicLanguage ={

    SERF:'SERF',
    validLoans:'Validate your Loans',
    supportApps: 'App Support',
    recuverCFDI: 'Recover CFDI',
    payinLine: 'Pay on line',
    worryingOperation: 'Worrying Operation',
    login:'Login'


}
export const NavBarPublicLanguageSpanish: NavBarPublicLanguage ={

    SERF:'SERF',
    validLoans:'Válida tu Fianza',
    supportApps: 'Soporte Apps',
    recuverCFDI: 'Recupera CFDI',
    payinLine: 'Pago en Línea',
    worryingOperation: 'Operación Preocupante',
    login: 'Iniciar Sesión'

}