export interface ObligorsDetailLanguage {
    title: string;
    home: string;
    loan: string;
    cumulo:string;
    avaiable:string;


}

export const ObligorsDetailLanguageEnglish: ObligorsDetailLanguage ={
    title: 'Obligors',
    home: 'Home',
    loan: 'Obligors',
    cumulo: 'Cumulus',
    avaiable: 'Loans'

}
export const ObligorsDetailLanguageSpanish: ObligorsDetailLanguage ={
    title: 'Obligado Solidario',
    home: 'Inicio',
    loan: 'Obligado Solidario',
    cumulo: 'Cúmulo',
    avaiable: 'Garantías'
}