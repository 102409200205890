import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import './ProductosReadMore.scss';
import {ProductoLineaAfianzamiento} from "../../../models/garantias/ProductoLineaAfianzamiento";
import {Link} from "@lmig/lmds-react";
interface ProductosReadMoreProps {
    idLineaAfianzamiento: number;
    productos: ProductoLineaAfianzamiento[]
}
export const ProductosReadMore: React.FC<ProductosReadMoreProps> = ({idLineaAfianzamiento, productos}:ProductosReadMoreProps)=> {

    useEffect(() => {

    },[]);

    return (
        <>
            <div id="productos-read-more">
                <div>
                    <input type="checkbox" className="read-more-state" id={'post-' + idLineaAfianzamiento}/>

                    <ul className="list-group list-group-flush read-more-wrap ">
                        {productos.map((j, index) =>
                            <li key={index} className={ 'list-group-item ' +(index> 0? 'read-more-target': null)}>
                                {j.nombre}
                            </li>
                        )}
                    </ul>

                  {(productos.length> 1 &&  <label htmlFor={'post-' + idLineaAfianzamiento} className="read-more-trigger"></label>)}

                </div>
            </div>
        </>
    );
}
