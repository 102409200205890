export const LOCK = "LOCK";
export const UNLOCK = "UNLOCK";

export const ERRUNLOCK = "ERRUNLOCK";

export const LOCKCOMMISSION = "LOCKCOMMISSION";
export const UNLOCKCOMMISSION = "UNLOCKCOMMISSION";


export const ADDOS = "ADDOS";


export type FIADOACTION = {
    idFiado: number;
    action: string;
    msg?: string;
}


export interface ActionLocked {
    type: typeof LOCK,
    payload: FIADOACTION
}

export interface ActionUnlocked {
    type: typeof UNLOCK,
    payload: FIADOACTION
}

export interface ActionErrUnlocked {
    type: typeof ERRUNLOCK,
    payload: FIADOACTION
}


export interface ActionLockedCommission {
    type: typeof LOCKCOMMISSION,
    payload: FIADOACTION
}

export interface ActionUnlockedCommission {
    type: typeof UNLOCKCOMMISSION,
    payload: FIADOACTION
}

export interface ActionAddOs {
    type: typeof ADDOS,
    payload: FIADOACTION
}

export type FiadoActionDispatchTypes = ActionLocked | ActionUnlocked |ActionErrUnlocked | ActionLockedCommission | ActionUnlockedCommission | ActionAddOs