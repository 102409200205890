
export interface LanguageModelPolizasRH {
    btnAccept : string,
    btnCancel : string,
    titlePage : string,
    searchBar : string,
    btnNewPolicy : string,
    folio : string,
    userSent : string,
    userApplied : string
    file : string,
    dateSent : string,
    dateApplication  : string,
    actions : string,
    status : string,
    statusApplied : string,
    statusSent : string,
    statusError : string,
    statusDeclined : string,
    statusPending : string,
    btnDownload : string,
    showing : string,
    showingTo : string,
    showingTotal : string,
    records : string,
    formatError : string,

    //Modal Nueva Poliza
    nuevaPolizaTitle : string,
    lblUploadBtn : string,
    selectFile : string,
    btnSent : string,

    //Modal Envio Exitoso
    sentSuccess : string,

    //Modal Poliza Rechazada
    policyRejected : string,
    lblRejected : string,

    //Modal error poliza
    errorTitle : string,

    //Modal autorizar poliza
    authorizeTitle : string,
    authorizeConfirmation : string,

    //Modal Rechazar poliza
    rejectPolicy : string,

    alertTextAreaEmpty : string,
}

export const languagePolizasIngles : LanguageModelPolizasRH = {
    btnAccept : 'Accept',
    btnCancel : 'Cancel',
    titlePage : 'Policies payroll',
    searchBar : 'Search by payroll folio',
    btnNewPolicy : '+ New Policy',
    folio : 'Folio',
    userSent : 'Sent by',
    file : 'File',
    dateSent : 'Date sent',
    userApplied : 'Attended by',
    dateApplication  : 'Application date',
    actions : 'Actions',
    status : 'Status',
    statusApplied : 'Applied',
    statusSent : 'Sent',
    statusError : 'Error',
    statusDeclined : 'Declined',
    statusPending : 'Pending',
    btnDownload : 'Download',
    showing : 'Showing records from',
    showingTo : 'to',
    showingTotal : 'out of a total of',
    records : 'records',
    formatError : 'Format file not supported.',

    //Modal Nueva Poliza
    nuevaPolizaTitle : 'Select a policy',
    lblUploadBtn : 'Upload',
    selectFile : 'Click "Upload" or drag a file.',
    btnSent : 'Send',

    //Modal Envio Exitoso
    sentSuccess : 'Successfully sent!',

    //Modal Poliza Rechazada
    policyRejected : 'Policy Rejected',
    lblRejected : 'Reason for rejecting the policy:',

    //Modal error poliza
    errorTitle : 'Policy Error',

    //Modal autorizar poliza
    authorizeTitle : 'Authorize policy',
    authorizeConfirmation : 'Are you sure you want to authorize the policy with folio ',
    rejectPolicy : 'Reject policy',

    alertTextAreaEmpty : 'Please add a coment.',

}

export const languagePolizasEspaniol : LanguageModelPolizasRH = {
    btnAccept : 'Aceptar',
    btnCancel : 'Cancelar',
    titlePage : 'Pólizas de nómina',
    searchBar : 'Buscar folio de nómina',
    btnNewPolicy : '+ Nueva Póliza',
    folio : 'Folio',
    userSent : 'Usuario envió',
    file : 'Archivo',
    dateSent : 'Fecha envió',
    userApplied : 'Usuario aplicó',
    dateApplication  : 'Fecha aplicación',
    actions : 'Acciones',
    status : 'Estatus',
    statusApplied : 'Aplicada',
    statusSent : 'Enviado',
    statusError : 'Error',
    statusDeclined : 'Rechazada',
    statusPending : 'Pendiente',
    btnDownload : 'Descargar',
    showing : 'Mostrando registros del',
    showingTo : 'al',
    showingTotal : 'de un total de ',
    records : 'registros',
    formatError : 'Formato no soportado.',

    //Modal Nueva Poliza
    nuevaPolizaTitle : 'Envió de póliza',
    lblUploadBtn : 'Cargar',
    selectFile : 'Click o arrastra para seleccionar un archivo.',
    btnSent : 'Enviar',

    //Modal Envio Exitoso
    sentSuccess : 'Enviado exitosamente!',

    //Modal Poliza Rechazada
    policyRejected : 'Póliza rechazada',
    lblRejected : 'Motivo del rechazo de la poliza:',

    //Modal error poliza
    errorTitle : 'Error de póliza',

    //Modal autorizar poliza
    authorizeTitle : 'Autorizar poliza',
    authorizeConfirmation : '¿Está seguro que desea autorizar la póliza con folio ',
    rejectPolicy : 'Rechazar póliza',

    //Alert msg
    alertTextAreaEmpty : 'Por favor agrega un comentario.',

}