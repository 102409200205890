import { ChangeEvent, Component } from "react";
import './AuthorizeEngagements.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import InfoEngagement from "../../../components/compromisos/info-engagement/InfoEngagement";
import { AlphanumericInput, Badge, Button, Checkbox, CheckboxGroup, Form, Modal, Radio, RadioGroup, } from "@lmig/lmds-react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { AxiosError } from 'axios';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { Compromiso } from '../../../models/compromisos/Compromiso';
import { formatValue } from "../../../class/CommonClass";
import { AutorizaCompromiso } from "../../../models/compromisos/AutorizaCompromiso";
import { CodigoRespuesta } from "../../../models/compromisos/CodigoRespuesta";
import { Cliente } from "../../../models/compromisos/Cliente";
import SuccessAlert from "@lmig/lmds-react/icons/SuccessAlert";
import '@lmig/lf-sgf-library-messages/src/index.css'
import { Correo } from "../../../models/compromisos/Correo";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { NegativeAlert } from "../../public/negative-alert/NegativeAlert";
import { BreadCumb } from "../../public/breadcumb/BreadCumb";
import { ElementosCompromiso } from "../../../models/compromisos/ElementosCompromiso";
import { UserModel } from "../../../models/public/UserModel";
import { Destinatario } from "../../../models/compromisos/Destinatario";
import Info from "@lmig/lmds-react/icons/Info";
import { CumpleConcepto } from "../../../models/compromisos/CumpleConcepto";


type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface IListProps {

    idCompromiso: number;
    idFiado: number;
    language: DefaultStateI
}


interface Params {
    idCompromiso: string;
    idFiado: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

interface BlockOption {
    value: number;
    label: string;
    estatus: number;
}


interface ConditionOption {
    value: number;
    label: string;
    estatus: number;
}


class CheckConcept extends Component<IListProps & RouteComponentProps<Params, SC, LocationState>, CheckConceptState> {

    public idFiado = 0;
    public idCompromiso = 0;
    public today: string;
    public last: string;
    public correoDestinario = '';
    public nombreDestinario = '';

    public localStorage = new LocalStorageClass();
    public localStorageClass = new LocalStorageClass();
    public initialEngagementStateAcepta: AutorizaCompromiso = {
        idCompromiso: 0,
        idAccion: 0,
        fechaComprometida: '',
        motivo: '',
        dictamen: '',
        nombreRep: '',
        usuario: '',
    }
    public initialEngagementState: Compromiso = {

        bloqueos: [],
        conFiado: 0,
        condiciones: [],
        datosFiado: {
            correo: '',
            fechaAceptaCompr: '',
            idCompromiso: 0,
            nombreRep: ''
        },
        dictamen: '',
        fechaComprometida: '',
        fechaCompromisoCumplido: '',
        fechaSolicitud: '',
        idCompromiso: 0,
        idFiado: 0,
        idSolicitante: '',
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoBloqueo: 0,
        tipoCompromiso: 0,
        tipoCumplimiento: 0,
        tipoSolicitante: 0

    }

    public initialMsg: Messages = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }


    public initialCheckConcept: CumpleConcepto = {
        conceptos: [0],
        dictamen: '',
        fechaComprometida: '',
        idAccion: 0,
        idCompromiso: 0,
        motivo: '',
        nombreRep: '',
        usuario: ''
    }

    constructor(props: any) {
        super(props);
        this.idCompromiso = parseInt(this.props.match.params.idCompromiso);
        this.idFiado = parseInt(this.props.match.params.idFiado);
        this.today = '';
        this.last = '';
        document.title = "Cumplimiento de Concepto"
        //document.title = `${this.props.language.language?.createEngagement.nombreDocumento}`

        this.state = {
            listaConceptos: [],
            conceptoCumplido: this.initialCheckConcept,
            conceptoSeleccionado: false,
            count: 0,
            PERFIL: this.localStorageClass.getProfile(),
            mensajesAlerta: this.initialMsg,
            compromiso: this.initialEngagementState,
            engagementObject: this.initialEngagementState,
            authorizeEngagementObject: this.initialEngagementStateAcepta,
            compromisoDetails: [{
                fiado: '',
                rfc: '',
                idCompromiso: 0,
                fechaSolicitud: '',
                bloqueos: [{

                    descripcion: '',
                    idBloqueo: 0,
                    estatus: 0
                }],
                condiciones: [{

                    idCondicion: 0,
                    descripcion: '',
                    estatus: 0
                }],
                fechaComprometida: '',
                idFiado: 0,
                monto: 0,
                motivo: '',
                numRenovaciones: 0,
                producto: '',
                statusCompromiso: 0,
                tipoCompromiso: 0,
                idFuncionario: ''
            }],
            BlockOption: [{
                value: 0,
                label: '',
                estatus: 0
            }],
            ConditionOption: [{
                value: 0,
                label: '',
                estatus: 0
            }],
            conFacultades: {
                codigo: 0,
                descripcion: ''
            },
            marcarCumplido: {
                codigo: 0,
                descripcion: ''
            },
            cliente: {
                idCliente: 0,
                razonSocial: '',
                rfc: '',
                agente: '',
                idAgente: 0
            },
            email: {
                correoDe: '',
                correoPara: '',
                de: '',
                fecha: '',
                idProyecto: '',
                idReferencia: '',
                mensaje: '',
                para: '',
                perfil: ''

            },
            revertido: {
                codigo: 0,
                descripcion: ''
            },
            destinatario: {
                nombre: '',
                email: ''

            }


        }

        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        let today = "";
        let last = "";
        const monthA = new Date().getMonth() + 6;
        let yearA = year;



        if (month < 10 && date < 10) {
            const monthAux = "0" + month
            const dateAux = "0" + date
            today = year + "-" + monthAux + "-" + dateAux
            this.today = today
        }

        if (month < 10 && date >= 10) {
            const monthAux = "0" + month
            today = year + "-" + monthAux + "-" + date
            this.today = today
        }

        if (month >= 10 && date >= 10) {
            today = year + "-" + month + "-" + date
            this.today = today
        }


        if (monthA < 10 && date < 10) {
            const monthAux = "0" + monthA
            const dateAux = "0" + date
            last = yearA + "-" + monthAux + "-" + dateAux
            this.last = last
        }

        if (monthA < 10 && date >= 10) {
            const monthAux = "0" + monthA
            last = yearA + "-" + monthAux + "-" + date
            this.last = last
        }

        if (monthA >= 10 && date >= 10) {
            last = yearA + "-" + monthA + "-" + date
            this.last = last
        }

        console.log("Fecha minima: " + this.today)
        console.log("Fecha máxima: " + this.last)


        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
        this.handleInputChangeDate = this.handleInputChangeDate.bind(this)
        this.handleOnchangeChangeSelect = this.handleOnchangeChangeSelect.bind(this)


    }

    componentDidMount() {

        //this.getFacultades();
        this.getClientAut();
        this.getClientInfo();

    }


    render() {
        return <>
         <NavBar />
            <div className="container-fluid " id="renewal-engagements">
               

                <div className="col-md-8">
                    <BreadCumb
                        title={this.props.language.language?.createEngagement.nombreDocumentoCumplimientoConcepto!}
                        listBreadcumbs={[
                            { title: 'Inicio', url: '/compromisos' },
                            { title: this.props.language.language?.createEngagement.nombreDocumentoCumplimientoConcepto!, url: this.props.location.pathname },
                        ]}
                    />
                </div>

                <div className="create-engagement">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-8 seccion-info ">
                                    <InfoEngagement id={this.idFiado} />
                                </div>
                            </div>
                        </div>

                        {/*   <div className="col-md-4">
                            <PipeLineStepsDetails
                                listSteps={[`Cumplimiento `]}
                                currentStep={this.state.count!} />
                        </div> */}

                        <div className="col-md-12">
                            <Form id="form-liberty">
                                {this.state.count == 0 ? (
                                    <div className="row">

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.tipoLabel}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={this.state.compromiso.descTipoCompromiso}
                                                disabled
                                            />
                                        </div>

                                        {(this.state.compromiso.tipoCompromiso == 1) ?
                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.condicionesLabel}</label>

                                                {this.state.ConditionOption.map((condition, index) =>

                                                    <div className="condition-input">
                                                        {condition.estatus == 0 ? (
                                                            <CheckboxGroup name="RadioGroup--default" >
                                                                <Checkbox value={`${condition.value} `} labelVisual={`${condition.label}`}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            this.setState({ conceptoSeleccionado: true })
                                                                            let newList = this.state.listaConceptos;
                                                                            newList.push(condition.value);
                                                                            console.log("Lista de Conceptos " + newList);
                                                                            this.setState(prevState => ({
                                                                                conceptoCumplido: { ...prevState.conceptoCumplido, conceptos: newList }
                                                                            }))
                                                                        } else {

                                                                            let newList = this.state.listaConceptos;
                                                                            const a = newList.findIndex(item => item === condition.value)
                                                                            newList.splice(a, 1)
                                                                            let listAux = newList

                                                                            this.setState(prevState => ({
                                                                                conceptoCumplido: { ...prevState.conceptoCumplido, conceptos: listAux }
                                                                            }))

                                                                            if (this.state.conceptoCumplido.conceptos.length == 0) {
                                                                                this.setState({ conceptoSeleccionado: false })
                                                                            }
                                                                        }
                                                                    }}></Checkbox>
                                                            </CheckboxGroup>
                                                        ) :
                                                            <Badge highlightType="positive"> {condition.label} </Badge>}
                                                    </div>
                                                )}
                                            </div> : null}

                                        {(this.state.compromiso.tipoCompromiso == 2) ?
                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.bloqueosLabel}</label>

                                                {this.state.BlockOption.map((bloq, index) =>
                                                    <div className="condition-input">
                                                        {bloq.estatus == 0 ? (
                                                            <CheckboxGroup name="RadioGroup--default" >
                                                                <Checkbox value={`${bloq.value} `} labelVisual={`${bloq.label}`}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            this.setState({ conceptoSeleccionado: true })
                                                                            let newList = this.state.listaConceptos;
                                                                            newList.push(bloq.value);
                                                                            console.log("Lista de Conceptos " + newList);
                                                                            this.setState(prevState => ({
                                                                                conceptoCumplido: { ...prevState.conceptoCumplido, conceptos: newList }
                                                                            }))
                                                                        } else {

                                                                            let newList = this.state.listaConceptos;
                                                                            const a = newList.findIndex(item => item === bloq.value)
                                                                            newList.splice(a, 1)
                                                                            let listAux = newList

                                                                            this.setState(prevState => ({
                                                                                conceptoCumplido: { ...prevState.conceptoCumplido, conceptos: listAux }
                                                                            }))

                                                                            if (this.state.conceptoCumplido.conceptos.length == 0) {
                                                                                this.setState({ conceptoSeleccionado: false })
                                                                            }
                                                                        }
                                                                    }}></Checkbox>
                                                            </CheckboxGroup>
                                                        ) :
                                                            <Badge highlightType="positive"> {bloq.label} </Badge>}
                                                    </div>
                                                )}
                                            </div> : null}

                                        <div className="col-md-7">
                                            <label className="label-input"> {this.props.language.language?.createEngagement.fecha} </label>
                                            <input
                                                type="date"
                                                name="fechaComprometida"
                                                className={"input-sdgf"}
                                                //min={}
                                                max={this.today}
                                                id="fechaComprometida"
                                                onChange={this.handleInputChangeDate} />

                                        </div>
                                        <div className="col-md-7">
                                            <NegativeAlert textAlert={this.state.mensajesAlerta.msgFecha} />
                                        </div>

                                        <div className="col-md-12">

                                            <div className="row buttons-navigation">
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        //onClick={() => this.back()}
                                                        href="/compromisos/cumplimientos">
                                                        {this.props.language.language?.createEngagement.regresar}
                                                    </Button>
                                                </div>
                                                <div className="col-md-6">
                                                    <br />
                                                    {this.state.conceptoSeleccionado == true ? (
                                                        <Button variant="primary" dynamicWidth
                                                            onClick={() => {
                                                                this.validaFormulario()

                                                                if (this.validaFormulario()) {
                                                                    this.setState({ openModalAutorizacion: true });
                                                                }

                                                            }}>
                                                            {this.props.language.language?.createEngagement.marcarCumplido}
                                                        </Button>

                                                    ) : <Button variant="primary" dynamicWidth disabled
                                                        onClick={() => {
                                                            this.setState({ openModalAutorizacion: true });
                                                        }}>
                                                        {this.props.language.language?.createEngagement.marcarCumplido}
                                                    </Button>}


                                                </div>
                                            </div>
                                        </div>

                                        <Modal
                                            isOpen={this.state.openModalClose}
                                            size="medium"
                                            modalStyle="standard"
                                            //title="Fiado"
                                            // className="modalExito"
                                            id="modal2"
                                            closeFunc={() => {
                                                this.setState({ openModalClose: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 alert">
                                                        <SuccessAlert
                                                            size="64"
                                                            color="positive">
                                                        </SuccessAlert>
                                                    </div>
                                                    <h2 className="texto">  {this.props.language.language?.createEngagement.operacionExitosa}</h2>
                                                    <br />
                                                    { }
                                                    <Button href="/compromisos/cumplimientos"
                                                        variant="primary"
                                                        className="btn-ok">
                                                        {this.props.language.language?.createEngagement.aceptar}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal
                                            isOpen={this.state.openModalAutorizacion}
                                            size="medium"
                                            modalStyle="standard"
                                            id="modal3"
                                            closeFunc={() => {
                                                this.setState({ openModalAutorizacion: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 info">
                                                        <Info
                                                            size="64"
                                                            color="informative">
                                                        </Info>
                                                    </div>

                                                    <h2 className="texto"> {this.props.language.language?.createEngagement.preguntaCumplimiento} </h2>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Button onClick={() => {
                                                                this.setState({ openModalAutorizacion: false })
                                                            }}
                                                                //href="/compromisos"
                                                                variant="secondary"
                                                                className="btn-cancel">
                                                                {this.props.language.language?.createEngagement.cancelar}
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Button
                                                                onClick={() => this.postConceptoCumplido()}
                                                                //href="/compromisos"
                                                                variant="primary">
                                                                {this.props.language.language?.createEngagement.aceptar}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                ) : null}
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    next(): void {
        this.setState({ count: this.state.count! + 1 })

    }

    back(): void {
        this.setState({ count: this.state.count! - 1 })
    }






    validaFormulario(): boolean {

        this.setState({ mensajesAlerta: this.initialMsg })
        const leyendaFecha = 'Por favor selecciona una Fecha'

        //VALIDA QUE EL FORMULARIO TENGA VALOR EN LA NUEVA FECHA
        if (this.state.authorizeEngagementObject.fechaComprometida == "") {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue(leyendaFecha) }
            }))
            return false
        }
        return true
    }

    async postConceptoCumplido(): Promise<void> {


        this.state.conceptoCumplido.usuario = this.state.PERFIL.username

        if (!this.validaFormulario()) {

            console.log("No cuenta con información en el formulario")

        } else {


            try {
                const res = await engagementService.postConceptoCumplido(this.state.conceptoCumplido);

                // actualizar el objeto compromiso con el nuevo creado
                this.setState({ marcarCumplido: res.data })
                console.log('Se marcó como cumplido el concepto ' + this.state.conceptoCumplido.idAccion)
                console.log(this.state.conceptoCumplido)
                this.setState({ openModalAutorizacion: false })
                this.setState({ openModalClose: true })

            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log("Error:" + err.response.status)
                    console.log("Error:" + err.response.data)

                }
            }
        }
    }


    handleInputChangeDate(e: InputChange): void {

        const value = e.target.value;
        const date = value
        const day = date?.substring(8, 10)
        const month = date?.substring(5, 7)
        const year = date?.substring(0, 4)

        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, fechaComprometida: formatValue(day + '/' + month + '/' + year) }
        }))

        this.setState(prevState => ({
            conceptoCumplido: { ...prevState.conceptoCumplido, fechaComprometida: formatValue(day + '/' + month + '/' + year) }
        }))
        //this.getFacultades();

        console.log("Fecha del Cumplimiento " + this.state.conceptoCumplido.fechaComprometida)

        if (this.state.authorizeEngagementObject.fechaComprometida != '') {
            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue('') }
            }))
        }
    }

    handleInputChangeSelect(e: InputChange): void {

        const name = e.target.name;
        const value = e.target.value;
        console.log(name + ' : ' + value)

        let c = parseInt(value)
        let conceptosAux: number[]
        conceptosAux = []

        conceptosAux.push(c)

        this.setState(prevState => ({
            conceptoCumplido: { ...prevState.conceptoCumplido, conceptos: conceptosAux }
        }))
        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, [name]: formatValue(value) }
        }))

    }

    handleOnchangeChangeSelect(event: ChangeEvent<HTMLInputElement>): void {

        const name = event.target.name;
        const value = event.target.value;
        console.log(name + ' : ' + value)

        let c = parseInt(value)
        let conceptos: number[]
        conceptos = [0, 5]



        this.setState(prevState => ({
            conceptoCumplido: { ...prevState.conceptoCumplido, conceptos: conceptos }
        }))

        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, idAccion: formatValue(value) }
        }))

        this.setState({ conceptoSeleccionado: true })

    }

    async getClientAut(): Promise<void> {

        let externo = 0;

        const res = await engagementService.getClientAut(externo, this.idCompromiso);
        this.setState({ compromiso: res.data });
        this.setState({ engagementObject: res.data })

        const date = this.state.compromiso.fechaComprometida?.toString()
        const day = date?.substring(8, 10)
        const month = date?.substring(5, 7)
        const year = date?.substring(0, 4)

        const completeDate = day + '/' + month + '/' + year

        //this.state.authorizeEngagementObject.motivo = this.state.compromiso.motivo
        //this.state.authorizeEngagementObject.fechaComprometida = ''
        //this.state.authorizeEngagementObject.nombreRep = this.state.compromiso.datosFiado.nombreRep
        this.state.authorizeEngagementObject.idCompromiso = this.state.compromiso.idCompromiso

        this.setState(prevState => ({
            conceptoCumplido: { ...prevState.conceptoCumplido, idCompromiso: this.state.compromiso.idCompromiso }
        }))

        console.log("Compromiso " + JSON.stringify(this.state.compromiso))


        let listaAuxiliar: ElementosCompromiso[];
        listaAuxiliar = []
        let listaAuxiliarB: BlockOption[];
        listaAuxiliarB = []
        let listaAuxiliarC: ConditionOption[];
        listaAuxiliarC = []

        let tipo: string;
        tipo = ''
        let monto: number;
        monto = 0
        let fechaComprometida: string | undefined;
        fechaComprometida = ''
        let fiadoS: string;
        fiadoS = ''

        if (this.state.compromiso.tipoCompromiso == tiposCompromiso.PORAPOYO) {

            tipo = 'Apoyo'
            this.setState(prevState => ({
                authorizeEngagementObject: { ...prevState.authorizeEngagementObject, motivo: '1' }
            }))

            this.setState(prevState => ({
                conceptoCumplido: { ...prevState.conceptoCumplido, motivo: '1' }
            }))

        } else if (this.state.compromiso.tipoCompromiso == tiposCompromiso.BLOQUEO) {
            tipo = 'Bloqueo'
            this.setState(prevState => ({
                authorizeEngagementObject: { ...prevState.authorizeEngagementObject, motivo: '2' }
            }))
            this.setState(prevState => ({
                conceptoCumplido: { ...prevState.conceptoCumplido, motivo: '2' }
            }))
        }
        this.state.compromiso.descTipoCompromiso = tipo

        for (const valor of this.state.compromiso.bloqueos) {
            const s = valor.estatus
            listaAuxiliarB.push({ value: valor.idBloqueo, label: valor.descripcion, estatus: s })
        }

        for (const valor of this.state.compromiso.condiciones) {
            const s = valor.estatus
            listaAuxiliarC.push({ value: valor.idCondicion, label: valor.descripcion, estatus: s })


        }


        this.setState(prevState => ({
            conceptoCumplido: { ...prevState.conceptoCumplido, idCompromiso: this.state.compromiso.idCompromiso }
        }))


        this.setState({ ConditionOption: listaAuxiliarC })
        this.setState({ BlockOption: listaAuxiliarB })
        this.setState({ compromisoDetails: listaAuxiliar })

    }


    async getFacultades(): Promise<void> {

        let externo = 0;

        const res1 = await engagementService.getClientAut(externo, this.idCompromiso);
        this.setState({ engagementObject: res1.data })

        const producto = parseInt(this.state.engagementObject.producto)
        const monto = this.state.engagementObject.monto
        const ejecutivo = this.state.PERFIL.clave!

        const res = await engagementService.getFacultades(ejecutivo, producto, monto);
        this.setState({ conFacultades: res.data });
    }

    async getClientInfo(): Promise<void> {

        const res = await engagementService.getClient(this.idFiado, 0);
        this.setState({ cliente: res.data });
        console.log("Se encuentra Ciente en Renovación por ID")
        console.log(this.state.cliente)
    }

   /*  async getDestinatrio(): Promise<void> {

        const res = await engagementService.getDestinatario(this.idCompromiso);
        this.setState({ destinatario: res.data });
        console.log("Se obtiene el destinatario")
        console.log(res.data)

    } */
}


const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(CheckConcept);



interface CheckConceptState {
    count?: number;
    PERFIL: UserModel;
    compromiso: Compromiso;
    engagementObject: Compromiso;
    authorizeEngagementObject: AutorizaCompromiso;
    openModalClose?: boolean;
    openModalAutorizacion?: boolean;
    compromisoDetails: ElementosCompromiso[];
    ConditionOption: ConditionOption[];
    BlockOption: BlockOption[];
    conFacultades: CodigoRespuesta;
    marcarCumplido: CodigoRespuesta;
    cliente: Cliente;
    email: Correo;
    revertido: CodigoRespuesta;
    mensajesAlerta: Messages;
    destinatario: Destinatario;
    conceptoSeleccionado: boolean;
    conceptoCumplido: CumpleConcepto;
    listaConceptos: number[]
}

interface Messages {
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgCorreo: string;
    msgFecha: string;
    msgMotivo: string;
}


interface ConceptoCumplido {
    fechaCumplimiento: string;
    idCompromiso: number;
    idConcepto: number;
    tiposCompromiso: number;

}

export enum tiposCompromiso {

    PORAPOYO = 1,
    BLOQUEO = 2,

}