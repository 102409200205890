export interface StatisticsWarrantiesLanguageModel {
    home: string;
    loadingStatistics: string;
    errorStatistics: string;
    principal: string;
    rfc: string;
    bondsIssued: string;
    options: string;
    createNewLoan: string;

}

export const StatisticsWarrantiesLanguageEnglish: StatisticsWarrantiesLanguageModel ={
    home: 'Home',
    loadingStatistics: 'Loading Statistics',
    errorStatistics: 'Sorry something happened while loading the tables, please check your connection and try again.',
    rfc: 'RFC',
    principal: 'Principal',
    bondsIssued: 'Loans Issued',
    options: 'Options',
    createNewLoan: 'Create new Loan'

}
export const StatisticsWarrantiesLanguageSpanish: StatisticsWarrantiesLanguageModel ={
    home: 'Inicio',
    loadingStatistics: 'Cargando Estadísticas',
    errorStatistics: 'Lo sentimos algo ha ocurrido al cargar las tablas, revise su conexión e intente de nuevo.',
    rfc: 'RFC',
    principal: 'Fiado',
    bondsIssued: 'Fianzas Emitidas',
    options: 'Opciones',
    createNewLoan: 'Crear nueva Garantía'
}