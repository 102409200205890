export interface CrearAuditoriaLanguageModel {
    //BreadCumb
    Home: string,
    NewAudi: string,
    //Vista
    TipoPersona: string,
    TipoPersonaM: string,
    TipoPersonaF: string,
    FechaInicio: string,
    FechaFin: string,
    select: string,

    //Card Archivo
    Autorizacion: string,
    arrastra:string,
    file:string,
    alert_up:string,

    //Tabla
    FolioAuditoria: string,
    FoliosProce: string,
    Fecha: string
    Oficina: string
    NumerFolios: string
    Consultas: string;
    Reporte: string;

    //Botones
    NextPage:string,
    PrevPage:string,
    Finalize:string,
    Exit:string


}

export const CrearAuditoriaLanguageEnglish: CrearAuditoriaLanguageModel = {

    //BreadCumb
    Home: 'Home',
    NewAudi: 'Create Audit',


    //Vista
    TipoPersona: 'Person Type',
    TipoPersonaF: 'Physical',
    TipoPersonaM: 'Moral',
    FechaInicio: 'Start date',
    FechaFin: 'Ending date',
    select: 'Select an option',

    //Card Archivo
    Autorizacion: 'Autorization',
    arrastra:'Drag and drop  file here or ',
    file:'Browse files',
    alert_up:'File Uploaded Successfully',

    //Tabla
    FolioAuditoria: 'Invoice Audit',
    FoliosProce: 'Processed folios',
    Fecha: 'Date',
    Oficina: 'Office',
    NumerFolios: 'Number Folios',
    Consultas: 'Duplicate queries',
    Reporte: 'Report',

    //Botones
    NextPage:'Continue',
    PrevPage:'Back',
    Finalize:'Send',
    Exit:'Exit',

}


export const CrearAuditoriaLanguageSpanish: CrearAuditoriaLanguageModel = {


    //BreadCumb
    Home: 'Home',
    NewAudi: 'Crear Auditoria',

    //Vista
    TipoPersona: 'Tipo Persona ',
    TipoPersonaF: 'Fisica',
    TipoPersonaM: 'Moral',
    FechaInicio: 'Fecha de Inicio',
    FechaFin: 'Fecha de Fin',
    select: 'Selecciona una opción',

    //Card Archivo
    Autorizacion: 'Autorización',
    arrastra:'Arrastra y suelta el archivo aquí o',
    file:'Subir Archivo',
    alert_up:'Archivo Cargado Correctamente',
    //Tabla
    FolioAuditoria:'Folio Auditoria',
    FoliosProce:'Folios Procesados',
    Fecha:'Fecha',
    Oficina:'Oficina',
    NumerFolios:'Numero de Folios',
    Consultas:'Consultas Duplicadas',
    Reporte:'Reporte',

    //Botones
    NextPage:'Continuar',
    PrevPage:'Regresar',
    Finalize:'Enviar',
    Exit:'Salir'
}