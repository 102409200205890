import React, { useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {CalificacionDocModel} from "../../../models/garantias/CalificacionDocModel";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {Dropdown, ListGroup} from "react-bootstrap";
import {Button, IconInfo, IconThumbsDown, IconThumbsUp, Modal} from "@lmig/lmds-react";
import './AuthorizationExpedientIdentification.scss';
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";

interface AuthorizationExpedientIdentificationProps {
    idDocumento: number | null;
    reloadExpedients?: (refresh: boolean) => void;
}

export const AuthorizationExpedientIdentification: React.FC<AuthorizationExpedientIdentificationProps> = ({idDocumento, reloadExpedients}:AuthorizationExpedientIdentificationProps)=> {

    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const localStorageClass = new LocalStorageClass();

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const initExpedient : CalificacionDocModel = {
        idDocumento: 0,
        estadoDocumento:  0,
        descripcionEstado: '',
        rechazo: []
    }

    const [quoteExpedient, seQuoteExpedient] = useState<CalificacionDocModel>(initExpedient);

    const [loadingQuoteExpedient, setLoadingQuoteExpedient] = useState<boolean>(true);

    const [errorQuoteExpedient, setErrorQuoteExpedient] = useState<boolean>(false);


    useEffect(() => {
        getDocQuote(idDocumento);

    },[]);

    const getDocQuote =  async (idDoc: number | null) => {
        setLoadingQuoteExpedient(true);
        setErrorQuoteExpedient(false);
        if (idDoc){

            try {
                const res = await garantiasService.getCalifArchivo(idDoc!);
                seQuoteExpedient(res.data);
                setLoadingQuoteExpedient(false);

            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                    setLoadingQuoteExpedient(false);
                    setErrorQuoteExpedient(true);
                }
            }
        }else {
            seQuoteExpedient(initExpedient)
            setLoadingQuoteExpedient(false);
        }

    }


    return (
        <>
            {(!loadingQuoteExpedient)?
                <div className="co-md-12 ">
                    { (quoteExpedient.estadoDocumento == statesDocIdentification.enCaptura ||
                        quoteExpedient.estadoDocumento == statesDocIdentification.enRevision) ? (
                            <span className="text-black-50">{quoteExpedient.descripcionEstado}</span>
                    ): null}

                    { (quoteExpedient.estadoDocumento == statesDocIdentification.autorizado ||
                        quoteExpedient.estadoDocumento == statesDocIdentification.guardadoDW) ? (
                        <span className="text-success">{quoteExpedient.descripcionEstado}</span>
                    ): null}

                    { (quoteExpedient.estadoDocumento == statesDocIdentification.rechazado) ? (
                        <>
                            <span className="text-danger">{quoteExpedient.descripcionEstado}</span>
                            <div className="row">
                                <div className="col-12">

                                    {quoteExpedient.rechazo.map((i,index) =>
                                        (<>
                                                    <span className="badge badge-pill badge-light">
                                                        <IconInfo
                                                            color="error"
                                                            size="16"
                                                            title={i.descripcion}/>
                                                        &nbsp;{i.descripcion}
                                                    </span> &nbsp;

                                            </>
                                        ))
                                    }
                                </div>

                            </div>
                        </>


                    ): null}

                </div>:(<div className="col-12">Cargando calificación...</div>)}


        </>
    );
};








