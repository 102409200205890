import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {LineaAfinzamiento} from "../../../models/garantias/LineaAfinzamiento";
import './EditLineaAfianzamiento.scss';
import {Alert, Button} from "@lmig/lmds-react";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {postRenovacionLineaAfianzamiento, putLineaAfianzamiento} from "../../../services/garantias/GarantiasService";
import {initLineaAfianzamiento, initMsgLineaAfianzamiento} from "../../../class/CommonClass";
import {SelectProductosLinea} from "../select-productos-linea/SelectProductosLinea";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import CurrencyInputSDGF from "../../public/currency-input-sdgf/CurrencyInputSDGF";
import {MsgNuevaLineaAfianzamiento} from "../../../models/garantias/MsgNuevaLineaAfianzamiento";
interface EditLineaAfianzamientoProps {
    idPrincipal: number;
    lineaAfianzamiento: LineaAfinzamiento;
    editedLinea: (lineaAfinzamiento: LineaAfinzamiento) => void;
    closeModal: () => void;
}
export const EditLineaAfianzamiento: React.FC<EditLineaAfianzamientoProps> = ({idPrincipal, lineaAfianzamiento, editedLinea, closeModal}:EditLineaAfianzamientoProps)=> {

    const languageState = useSelector((state: RootStore) => state.language.language?.lineaCreditoLanguage);

    const [editingLinea, setEditingLinea] = useState<boolean>(false);

    const [errorEditingLinea, setErrorEditingLinea] = useState<boolean>(false);

    const [msgErrorEditingLinea, setMsgErrorEditingLinea] = useState<string>('');

    const [editableLineaAfianzamiento, setEditableLineaAfianzamiento] = useState<LineaAfinzamiento>(initLineaAfianzamiento);

    const [msgLineaAfianzamiento, setMsgLineaAfianzamiento] = useState<MsgNuevaLineaAfianzamiento>(initMsgLineaAfianzamiento);

    const [clearProductos, setClearProductos] = useState<boolean>(false);

    const onClickUpdateLinea = async (newLineaAfianzamiento: LineaAfinzamiento) => {
        if (isValidLinea(newLineaAfianzamiento)) {
            setEditingLinea(true);
            setErrorEditingLinea(false);
            setMsgErrorEditingLinea('');
            try {
                const res = await garantiasService.postRenovacionLineaAfianzamiento(newLineaAfianzamiento);
                setEditingLinea(false);
                editedLinea(res.data);

            } catch (error) {
                const err = error as AxiosError
                setEditingLinea(false);
                setErrorEditingLinea(true);

                if (err.response?.status === 400){
                    // cachar los mensajes
                    setMsgErrorEditingLinea(err.response.data.message!);
                }
                if (err.response?.status === 404){
                    // cachar los mensajes
                    setMsgErrorEditingLinea(err.response.data.message!);
                }
                if (err.response) {
                    // error
                    setMsgErrorEditingLinea(err.response.data.message!);

                }
            }
        }
    }

    const isValidLinea = (newLineaAfianzamiento: LineaAfinzamiento): boolean => {
        // console.log(newLineaAfianzamiento);
        // console.log(initMsgLineaAfianzamiento);

        hideMsgs();

        if (newLineaAfianzamiento.productos.length == 0 ){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgProductos: languageState?.msgProduct!})
            // @ts-ignore
            document.getElementById('productos-linea').style.visibility = "visible";
            return false;
        }

        if (!newLineaAfianzamiento.montoMaximoEv){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgMontoMaximoEv: languageState?.enterMontoMaximoEv!})
            // @ts-ignore
            document.getElementById('enter-montoMaximoEv').style.visibility = "visible";
            return false;
        }
        if (!newLineaAfianzamiento.montoMaximoAc){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgMontoMaximoAC: languageState?.enterMontoMaximoAc!})
            // @ts-ignore
            document.getElementById('enter-monto-maximo').style.visibility = "visible";

            return false;
        }
        if (newLineaAfianzamiento.montoMaximoEv > newLineaAfianzamiento.montoMaximoAc){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgMontoMaximoAC: languageState?.exceedMontoMaximoAc!})
            // @ts-ignore
            document.getElementById('exceed-monto-maximo').style.visibility = "visible";
            return  false;
        }


        return  true;
    }

    const hideMsgs= () => {
        setMsgLineaAfianzamiento(initMsgLineaAfianzamiento)
        // @ts-ignore
        document.getElementById('productos-linea').style.visibility = "hidden";
        // @ts-ignore
        document.getElementById('enter-montoMaximoEv').style.visibility = "hidden";
        // @ts-ignore
        document.getElementById('enter-monto-maximo').style.visibility = "hidden";
        // @ts-ignore
        document.getElementById('exceed-monto-maximo').style.visibility = "hidden";
    }

    useEffect(() => {
        hideMsgs();
        setEditableLineaAfianzamiento(lineaAfianzamiento);
    },[idPrincipal]);

    return (
        <>
            <div id="edit-linea-afianzamiento" className="row">
                <div className="col-md-12">
                    <h4></h4><br/>
                   <SelectProductosLinea
                        idFiado={idPrincipal}
                        clear={clearProductos}
                        selectedProductsDefault={lineaAfianzamiento.productos}
                        onChange={values => {
                            setEditableLineaAfianzamiento({...editableLineaAfianzamiento, productos: values});
                        }}
                    />
                    <br/>
                    <Alert highlightType="negative" id="productos-linea" >
                        {languageState?.msgProduct}
                    </Alert>


                </div>

                <div className="col-md-6">
                    <label className="label-input">{languageState?.montoMaximoEv!}:</label>
                    <CurrencyInputSDGF
                        value={lineaAfianzamiento.montoMaximoEv}
                        name="montoMaximoEv"
                        placeholder={languageState?.montoMaximoEv!}
                        onValueChange={(value, name) => {
                            setEditableLineaAfianzamiento({...editableLineaAfianzamiento, montoMaximoEv: value});
                        }}

                    />
                    <br/>
                    <Alert highlightType="negative" id="enter-montoMaximoEv" >
                        {languageState?.enterMontoMaximoEv}
                    </Alert>
                </div>

                <div className="col-md-6">
                    <label className="label-input">{languageState?.montoMaximoAc!}:</label>
                    <CurrencyInputSDGF
                        value={lineaAfianzamiento.montoMaximoAc}
                        name="montoMaximoAc"
                        placeholder={languageState?.montoMaximoAc!}
                        onValueChange={(value, name) => {
                            setEditableLineaAfianzamiento({...editableLineaAfianzamiento, montoMaximoAc: value});
                        }}
                    />
                    <br/>
                    <Alert highlightType="negative" id="enter-monto-maximo" >
                        {languageState?.enterMontoMaximoAc}
                    </Alert>
                    <Alert highlightType="negative" id="exceed-monto-maximo" >
                        {languageState?.exceedMontoMaximoAc}
                    </Alert>
                </div>
                {(errorEditingLinea) &&
                    <div className="col-md-12">
                        <NegativeAlert textAlert={msgErrorEditingLinea}/>
                    </div>
                }


                <div className="col-md-6"></div>

                <div className="col-md-6">
                    <div className="row" id="buttons-linea">
                        <div className="col-md-6">
                            <Button dynamicWidth
                                    onClick={() => {closeModal()}}
                                    variant="secondary">
                                {languageState?.cancel}
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <Button dynamicWidth
                                    disabled={editingLinea? true: false}
                                    onClick={() => {onClickUpdateLinea(editableLineaAfianzamiento)}}
                                    variant="primary">
                                    {(editingLinea)?languageState?.editing: languageState?.edit}
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

