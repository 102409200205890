export interface ReviewDocsLanguage {
    openMenu: string;
    closeMenu: string;
    title: string;
    previous: string;
    next: string;

}

export const ReviewDocsLanguageEnglish: ReviewDocsLanguage ={
    openMenu: 'Open Menu',
    closeMenu: 'Close Menu',
    title: 'Warranty documents',
    previous: 'Previous',
    next: 'Next',
}


export const ReviewDocsLanguageSpanish: ReviewDocsLanguage ={
    openMenu: 'Open Menu',
    closeMenu: 'Close Menu',
    title: 'Documentos de la garantía',
    previous: 'Anterior',
    next: 'Siguiente',

}