
import React, { useEffect, useState} from "react";
import './SearchAutocomplete.scss';
import {ReactSearchAutocomplete} from "react-search-autocomplete";
import {ItemModel} from "../../../models/public/ItemModel";

interface SelectSearchAutocompleteProps {
    items: ItemModel[];
    placeHolder: string;
    onSelect: (item: ItemModel) => void;
    onSearch: (search: string) => void;
    onClear?: () => void;
}

export const SearchAutocomplete: React.FC<SelectSearchAutocompleteProps>  = (
    {items,placeHolder, onSelect, onSearch, onClear}) => {

    const [listItems, setListItems] = useState<ItemModel[]>([]);

    useEffect(() => {
        setListItems(items);
    },[items])


    const handleOnSearch = (string: string, results: ItemModel []) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
        onSearch(string);
    }

    const handleOnHover = (result: any) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item: ItemModel) => {
        // the item selected
        console.log(item)
        onSelect(item);
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const handleOnClear = () => {
      onClear!();
    }

    const formatResult = (item: ItemModel) => {
        return (
            <div>
                {item.name}
            </div>

        )
    }

    return (
        <div id="search-autocomplete">
            <ReactSearchAutocomplete
                items={items}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                onClear={handleOnClear}
                autoFocus
                placeholder={placeHolder}
                formatResult={formatResult}
            />
        </div>
    );

};


