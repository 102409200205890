export interface UnLockCommissionPrincipal {
    reintent: string;
    titleModal: string;
    titleModalto: string;
    close: string;
    saving: string;
    save: string;
    textLocked: string;

}

export const UnockCommissionPrincipalEnglish: UnLockCommissionPrincipal = {
    reintent: 'Reintent',
    titleModal: 'Unlock Commission to ',
    titleModalto: '',
    close: 'Close',
    save: 'Unlocking',
    saving: 'Saving',
    textLocked: 'The principal has been blocked by commission',

}
export const UnLockCommissionPrincipalSpanish: UnLockCommissionPrincipal = {
    reintent: 'Reintentar',
    titleModal: 'Desbloquear Comisión a ',
    titleModalto: 'Desbloquear la Comisión del Fiado',
    close: 'Cerrar',
    saving: 'Desbloqueando',
    save: 'Desbloquear',
    textLocked: 'El fiado ha sido bloqueado por comisión',

}
