import axios from "axios";
import { LocalStorageClass } from "../../class/LocalStorageClass";
import environment from "../../environments/environment";
import { PolizaModel } from "../../models/polizasRH/PolizaModel";

const API = environment.APIPOLIZASRH;
const localStorageClass = new LocalStorageClass();


const header = {Accept:"application/json",
                "Content-Type":"application/json",
                // Authorization : 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJSQU1JR09OIiwiaWF0IjoxNjU2MDc5ODk2LCJleHAiOjE2NjQ3MTk4OTZ9.fHDcTZk3kx966BGVh2n09j2SrF1rPHreCnGpBuJh4NB0_iUp0RdP4tGr9uCS0UoVNUaSDqKcZTIdf3nY59-Ttg',
                // 'X-API-KEY': '6nBoq8b7ODkNMF7HbIjhMxJGSnGAhzkH',
                //Authorization : 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJSQU1JR09OIiwiaWF0IjoxNjU2NTM2ODExLCJleHAiOjE2NjUxNzY4MTF9.LgLMz1Uglx0JIg88wklYz7LXcLb8d484-aSZDBmO5KxvplY9Sja2NOGPzWRksax_9-ZJSpCwmoKeEcqQ2J4gJg',
                Authorization : localStorageClass.getProfileToken(),
                idLenguaje: localStorageClass.getLanguageId(),
                lang: localStorageClass.getLanguageCode()
            };

const headersFormData = {
    Accept : "application/json",
    'Content-Type': 'multipart/form-data',
    Authorization : localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
};

const updateHeader = () => {
    header.Authorization = localStorageClass.getProfileToken();
    header.idLenguaje = localStorageClass.getLanguageId();
    header.lang = localStorageClass.getLanguageCode();

    headersFormData.Authorization = localStorageClass.getProfileToken();
    headersFormData.idLenguaje = localStorageClass.getLanguageId();
    headersFormData.lang = localStorageClass.getLanguageCode();
}

//  OBTENER polizas - GET
export const getPolizas = async () => {
    updateHeader();
    return await axios.get<PolizaModel[]>(`${API}/polizasRH?numRegistros=10&pagina=1`, {headers : header});
};

// get polizas para PAGINADOR - GET
export const getPolizasPaginador = async ( pagina : number) => {
        updateHeader();
    console.log('Service Paginador: ', pagina);
    
    return await axios.get<PolizaModel[]>(`${API}/polizasRH?numRegistros=10&pagina=${pagina}`, {headers : header});
};

// get polizas para PAGINADOR - GET (2da version)
export const getPolizasPaginador2 = async (numRegistros : number, pagina : number) => {
    updateHeader();
console.log('Service Paginador: ', pagina);

return await axios.get<PolizaModel[]>(`${API}/polizasRH?numRegistros=${numRegistros}&pagina=${pagina}`, {headers : header});
};

//  FILTRO obtener poliza - GET
export const getPolizaFiltro = async (folio : number) => {
        updateHeader();
    return await axios.get<PolizaModel[]>(`${API}/polizasRH?folioPoliza=${folio}`, {headers : header});
};

//  DESCARGA de poliza - GET
export const getDescargaArchivo = async (nombre : String) => {
        updateHeader();
    return await axios.get<PolizaModel>(`${API}/archivoPolizaRH/${nombre}`, {headers : header});
};

//obtener ESTATUS de poliza - GET
export const getEstatusPoliza = async (folio : number) => {
        updateHeader();
    return await axios.get<PolizaModel>(`${API}/estatusPoliza/${folio}`, {headers : header});
};

//obtener ERROR de poliza - GET
export const getErrorPoliza = async (folio : number) => {
        updateHeader();
    return await axios.get<PolizaModel[]>(`${API}/errorPoliza/${folio}`, {headers : header});
};





//envio de poliza - POST
export const postFile = async (formData : FormData) => {
        updateHeader();
    return await axios.post<any>(`${API}/polizaRH`, formData, {headers : headersFormData} );
};




//aceptar poliza - PUT
export const polizaAutorizada = async (folioPoliza : number) => { 
        updateHeader();   
    console.log('Autorizar poliza : ' + folioPoliza );
    return await axios.put<any>(`${API}/polizaAutorizada/${folioPoliza}`, null, {headers : header});
};

//rechazar poliza - PUT
export const polizaRechazada = async (folioPoliza : number, motivo : string) => {
        updateHeader();
    console.log('Rechazar poliza : ' + folioPoliza + ' Motivo : ' + motivo);
    return await axios.put<any>(`${API}/polizaRechazada/${folioPoliza}/${motivo}`, null, {headers : header});
};

