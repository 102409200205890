import React, {ChangeEvent, useEffect, useState} from 'react';
import './DownloadReporteCumulo.scss';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {Button, IconSearch, Modal} from "@lmig/lmds-react";
import {PillButton} from "@lmig/lmds-react-pill-button";
import { FaDownload, FaFileDownload, FaCloudDownloadAlt} from 'react-icons/fa';
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {Spinner} from "react-bootstrap";


interface DownloadReporteCumuloProps {
  idCliente: number;
  title: string;
  nameFile: string;
  errDownloading: string;
}


export const DownloadReporteCumulo: React.FC<DownloadReporteCumuloProps> = ({idCliente, title, nameFile, errDownloading}: DownloadReporteCumuloProps) => {
  // redux
  const languageState = useSelector((state: RootStore) => state.language);

  const [downloadingCumulo, setDownloadingCumulo] = useState<boolean>(false);

  const [errorDownloadingCumulo, setErrorDownloadingCumulo] = useState<boolean>(false);

  useEffect(() => {

  },[]);

  const onClickDownload = async () => {
    setDownloadingCumulo(true);
    setErrorDownloadingCumulo(false);
    try {
      const res = await garantiasService.getCumuloResponsabilidades(idCliente);

      if (res.status === 200) {
        setDownloadingCumulo(false);
        let a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/octet-stream" })
        );
        a.download = nameFile+ '.xls';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      else {
        setErrorDownloadingCumulo(true);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        console.log(err.response.status);
        console.log(err.response.data);
        setDownloadingCumulo(false);
        setErrorDownloadingCumulo(true);
      }
    }
  }

  return (
      <>

        {(!downloadingCumulo)?
            <a
                onClick={event => {onClickDownload()}}
                title={title}
                id="download-reporte-cumulo">
              <FaDownload/>
            </a>:
            <Spinner id="spinner-downloading-reporte-cumulo"
                     animation="border"
                     size="sm"
                     role="status"
                     variant="primary">

            </Spinner>

        }


        <Modal
            isOpen={errorDownloadingCumulo}
            size="small"
            modalStyle="standard"
            title={''}
            closeFunc={() => {
              setErrorDownloadingCumulo(false)
            }}
        >

          <div className="row" id="error-downloading-reporte-cumulo">
            <div className="col-md-12 h5 text-center">
              {
                errDownloading
              }
              <br/><br/>

            </div>

            <div className=" col-12">
              <Button
                  dynamicWidth
                  variant={"primary"}
                  className="w-100"
                  onClick={()=>{
                    setErrorDownloadingCumulo(false)
                  }}>
                Cerrar
              </Button>
            </div>

          </div>

        </Modal>

      </>
  );
};
