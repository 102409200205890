export interface SelectTypeUserLanguageModel {
    loadingProfile: string;
    isExecutiveGarantias: string;
    clientDirect: string;
    selectAgent: string;
    selectExecutiveGar: string;
    selectExecutive: string;
    selectOption: string;
    sorrySomethingHappened: string;
    searchOffices: string;
    loadingOffices: string;
    errorOffices: string;
}

export const SelectAgentLanguageEnglish: SelectTypeUserLanguageModel = {
    loadingProfile: 'Loading Profile',
    isExecutiveGarantias: 'Show Dashboard as Collaterals Executive',
    clientDirect: 'Direct customer',
    selectAgent: "Select Agent",
    selectExecutiveGar: 'Select an Guarantee Executive',
    selectExecutive: "Select an Account Executive",
    selectOption: "Select Option",
    sorrySomethingHappened: 'Sorry something happened while loading the profile, please check your connection and try again.',
    searchOffices: 'Filter executives by office',
    loadingOffices: 'Loading Offices',
    errorOffices: 'Error loading offices'
}
export const SelectAgentLanguageSpanish: SelectTypeUserLanguageModel = {
    loadingProfile: 'Cargando Perfil',
    isExecutiveGarantias: 'Mostrar Dashboard como Ejecutivo de Garantías',
    clientDirect: 'Cliente directo',
    selectAgent: "Selecciona un Agente",
    selectExecutiveGar: 'Selecciona un Ejecutivo de Garantías',
    selectExecutive: "Selecciona un Ejecutivo de Cuentas",
    selectOption: "Selecciona una Opción",
    sorrySomethingHappened: 'Lo sentimos algo ha ocurrido al cargar el perfil, revise su conexión e intente de nuevo.',
    searchOffices: 'Filtrar ejecutivos por oficina',
    loadingOffices: 'Cargando Oficinas',
    errorOffices: 'Error al cargar oficinas'
}