import React, {useEffect, useState, Fragment} from 'react';
import {useHistory} from "react-router-dom";
type Props = {
    children?: JSX.Element | JSX.Element[];
};
const ScrollToTop: React.FC<Props> = ({children}) => {
    const history = useHistory();

    useEffect(() => {
        const unlisten: any = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    },[]);

    return <Fragment>{children}</Fragment>;
};

export default ScrollToTop;
