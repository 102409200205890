import React, { useEffect, useState} from 'react';
import './PrincipalActions.scss';
import { useHistory} from "react-router-dom";
import * as apiService from "../../../services/api-gral/ApiGralService";
import {ClienteModel} from "../../../models/public/ClienteModel";
import { useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AxiosError} from "axios";
import { openNewWindowCenter, validRoles} from "../../../class/CommonClass";
import {LockPrincipal} from "../lock-principal/LockPrincipal";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UnlockPrincipal} from "../unlock-principal/UnlockPrincipal";
import {LockCommissionPrincipal} from "../lock-commission-principal/LockCommissionPrincipal";
import {UnLockCommissionPrincipal} from "../unlock-commission-principal/UnLockCommissionPrincipal";
import LockClosed from "../../../assets/ico/PNG/LockClosed.png";
import LockOpen from "../../../assets/ico/PNG/LockOpen.png";
import LockComission from "../../../assets/ico/PNG/LockComission.png";
import UnlockComission from "../../../assets/ico/PNG/UnlockComission.png";
import {LOCK, LOCKCOMMISSION, UNLOCK, UNLOCKCOMMISSION} from "../../../actions/FiadoActionTypes";
import {DocuwareModel} from "../../../models/public/DocuwareModel";
import DocuWare from "../../../assets/images/public/DocuWare.png";
import {InfoPrincipalLock} from "../info-principal-lock/InfoPrincipalLock";
interface InfoPrincipalProps {
    idPrincipal: number;
}

export const PrincipalActions: React.FC<InfoPrincipalProps> = ({idPrincipal}:InfoPrincipalProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const fiadoAction = useSelector((state: RootStore) => state.fiadoAction.fiadoAction);

    const localStorageClass = new LocalStorageClass();

    const clienteInit: ClienteModel = {
        idCliente: 0,
        idAgente: 0,
        razonSocial: '',
        rfc: '',
        agente: '',
        propietario: '',
        estado: 0,
        estadoBloqueoCliente: false,
        estadoBloqueoComision: false,
        descripcion: ''
    }

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [docuware, setDocuware] = useState<DocuwareModel>({
        url: null,
        isEmpty: true,
        items: null
    });

    const [loadigDocuware, setLoadingDocuware] = useState<boolean>(false);

    const [loadingPrincipal, setLoadingPrincipal] = useState<boolean>(false);

    const [infoPrincipal, setInfoPrincipal] = useState<ClienteModel>(clienteInit);

    const [showModalLock, setShowModalLock] = useState<boolean>(false);

    const [showModalLockInfo, setShowModalLockInfo] = useState<boolean>(false);

    const [showModalUnLock, setShowModalUnLock] = useState<boolean>(false);

    const [showModalLockCommission, setShowModalLockCommission] = useState<boolean>(false);

    const [showModalUnLockCommission, setShowModalUnLockCommission] = useState<boolean>(false);


    const getDocuware = async (idPrincipal: number) => {
        setLoadingDocuware(true);
        try {
            const res = await apiService.getDocuware(idPrincipal)
            setDocuware(res.data)
            setLoadingDocuware(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingDocuware(false);
            }
        }

    }

    const getUserDetail = async (idPrincipal: number) => {
        setLoadingPrincipal(true);
        try {
            const res = await apiService.getDetalleCliente(idPrincipal)
            setInfoPrincipal(res.data.cliente)
            setLoadingPrincipal(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingPrincipal(false);
            }
        }

    }

    const onClickOpenDocuware = async () => {
        try {
            const res = await apiService.getDocuware(idPrincipal)
            openNewWindowCenter(res.data.url!, 800, 600 )

            setDocuware(res.data)
            setLoadingDocuware(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingDocuware(false);
            }
        }
    }
    const updateFiadoAction= () => {
        switch (fiadoAction?.action) {
            case LOCK:
                if (fiadoAction?.idFiado == idPrincipal){

                    setInfoPrincipal({...infoPrincipal,estadoBloqueoCliente: true})
                }
                break;

            case UNLOCK:
                if (fiadoAction?.idFiado == idPrincipal){

                    setInfoPrincipal({...infoPrincipal,estadoBloqueoCliente: false})
                }
                break;

            case LOCKCOMMISSION:
                if (fiadoAction?.idFiado == idPrincipal){

                    setInfoPrincipal({...infoPrincipal,estadoBloqueoComision: true})
                }
                break;

            case UNLOCKCOMMISSION:
                if (fiadoAction?.idFiado == idPrincipal){

                    setInfoPrincipal({...infoPrincipal,estadoBloqueoComision: false})
                }
                break;
            default:
                if(youCanSeeDw()){
                    getDocuware(idPrincipal);
                }

                getUserDetail(idPrincipal);
                break;

        }
    }

    const youCanSeeDw  = (): boolean => {

        if (validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
            validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
            validRoles(PERFIL.roles, localStorageClass.AUXILIAR_JURIDICO ) ||
            validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO )){
            return true;

        }else {
            return false
        }

    }

    useEffect(() => {
        updateFiadoAction()
    },[idPrincipal, fiadoAction]);


    return (
        <div className="row" id="principal-actions">
            <div className="col-md-4 ">


                {(youCanSeeDw()) ? (
                    <>
                        {!docuware.isEmpty &&
                            <a
                                id="docu-ware"
                                onClick={event => {
                                    onClickOpenDocuware()
                                }}>
                                <img src={DocuWare} className="img-fluid float-left" />
                            </a>
                        }

                    </>

                ): null}
            </div>
            {/*bloqueo de fiado */}
            {(loadingPrincipal == false)?
                <>
                    {validRoles(PERFIL.roles,localStorageClass.EJECUTIVODEGARANTIAS)?(
                            (!infoPrincipal.estadoBloqueoCliente)?
                                <div className="col-md-4">
                                    <a
                                        onClick={() => {
                                            setShowModalLock(true);
                                        }}
                                        className="row lock-principal">
                                        <div className="col-3">
                                            <img src={LockOpen}
                                                 className="img-fluid d-block mx-auto" />
                                        </div>
                                        <div className="col-9">
                                        <span>
                                            {languageState.language?.infoPrincipal.unLocked!}
                                        </span>
                                        </div>


                                    </a>
                                </div>
                                :
                                <div className="col-md-4">
                                    <a
                                        onClick={() => {
                                            setShowModalUnLock(true);
                                        }}
                                        className="row unlock-principal">
                                        <div className="col-3">
                                            <img src={LockClosed}
                                                 className="img-fluid d-block mx-auto" />
                                        </div>
                                        <div className="col-9">
                                         <span>
                                            {languageState.language?.infoPrincipal.locked!}
                                         </span>
                                        </div>

                                    </a>
                                </div>
                        ):
                        <>
                            {(!infoPrincipal.estadoBloqueoCliente)?
                                <div className="col-md-4 ">
                                    <button type="button"
                                            className="btn btn-cumplido  rounded-pill">
                                            <span>
                                                {languageState.language?.infoPrincipal.unLocked!}
                                             </span>
                                    </button>
                                </div>
                                :
                                <div className="col-md-4 ">
                                    <button type="button"
                                            onClick={event => {setShowModalLockInfo(true)}}
                                            className="btn btn-vencido  rounded-pill">
                                         <span>
                                                {languageState.language?.infoPrincipal.locked!}
                                            </span>
                                    </button>
                                </div>
                            }
                        </>
                    }


                    {/*bloqueo de comisiones del fiado */}

                    {validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)?(
                        <>
                            {(infoPrincipal.estadoBloqueoComision)?
                                <div className="col-md-4 ">
                                    <a
                                        onClick={() => {
                                            setShowModalUnLockCommission(true);
                                        }}
                                        className="row unlock-principal">
                                        <div className="col-3">
                                            <img src={LockComission} className="img-fluid d-block mx-auto" />
                                        </div>
                                        <div className="col-9">
                                            <span>

                                            {languageState.language?.infoPrincipal.unlockedComision!}
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                :
                                <div className="col-md-4">
                                    <a
                                        onClick={() => {
                                            setShowModalLockCommission(true);
                                        }}
                                        className="row lock-principal">
                                        <div className="col-3">
                                            <img src={UnlockComission} className="img-fluid d-block mx-auto" />
                                        </div>
                                        <div className="col-9">
                                            <span>

                                            {languageState.language?.infoPrincipal.lockedComision!}
                                            </span>
                                        </div>

                                    </a>
                                </div>
                            }
                        </>
                    ):null}

                </>: null

            }


            {/*Modal de bloquear Fiado*/}
            <LockPrincipal
                idPrincipal={idPrincipal}
                namePrincipal={infoPrincipal.razonSocial}
                showModal={showModalLock}
                clickShowModal={show => {
                    setShowModalLock(show);
                }}/>


            {/*Modal de desbloquear Fiado*/}
            <UnlockPrincipal
                idPrincipal={idPrincipal}
                namePrincipal={infoPrincipal.razonSocial}
                showModal={showModalUnLock}
                clickShowModal={show => {
                    setShowModalUnLock(show);
                }}/>

            {/*Modal de bloquear comision Fiado*/}
            <LockCommissionPrincipal
                idPrincipal={idPrincipal}
                namePrincipal={infoPrincipal.razonSocial}
                showModal={showModalLockCommission}
                clickShowModal={show => {
                    setShowModalLockCommission(show);
                }}/>
            {/*Modal de desbloquear Comissión Fiado*/}
            <UnLockCommissionPrincipal
                idPrincipal={idPrincipal}
                namePrincipal={infoPrincipal.razonSocial}
                motivoBloqueo={infoPrincipal.descripcion}
                showModal={showModalUnLockCommission}
                clickShowModal={show => {
                    setShowModalUnLockCommission(show);
                }}/>

            {/*Modal de info de bloqueo Fiado*/}
            <InfoPrincipalLock
                idPrincipal={idPrincipal}
                namePrincipal={infoPrincipal.razonSocial}
                showModal={showModalLockInfo}
                clickShowModal={show => {
                    setShowModalLockInfo(show);
                }}/>

        </div>
    );
};
