export interface SelectLanguageModel {
    titleModal: string
    btnModal: string
}

export const SelectLanguageEnglish: SelectLanguageModel ={
    titleModal: 'Select Language',
    btnModal: 'Accept'
}
export const SelectLanguageSpanish: SelectLanguageModel ={
    titleModal: 'Selecciona un Idioma',
    btnModal: 'Aceptar'
}