import { Component } from "react";
import './DetailsEngagements.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import { RouteComponentProps } from "react-router-dom";
import '@lmig/lf-sgf-library-messages/src/index.css'
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { BreadCumbEngagements } from "../../../components/public/breadcumb/BreadCumbEngagements";
import {FormDetailEngagement} from "../../../components/compromisos/form-detail-engagement/FormDetailEngagement";
import { FormDetailEngagementByFiado } from "../../../components/compromisos/form-detail-engagement/FormDetailEngagementByFiado";

interface DetailsEngagementsProps {
    id: number;
    language: DefaultStateI
}

interface Params {
    idFiado: string;
    idCompromiso: string;
}

interface LocationState {
    ownerName: string;
}

interface SC {
    statusCode?: number;
}

class DetailsEngagementByFiado extends Component<DetailsEngagementsProps & RouteComponentProps<Params, SC, LocationState>, DetailState> {

    public idFiado = 0;
    public idCompromiso = 0;


    constructor(props: any) {
        super(props);
        this.idFiado = parseInt(this.props.match.params.idFiado);
        this.idCompromiso = parseInt(this.props.match.params.idCompromiso);
        document.title = "Detalle del Compromiso"
        this.state = {

        }

    }


    render(): JSX.Element {
        return <>
             <NavBar />
            <div className="container-fluid " id="details-engagements">
           

                <div className="col-md-8">
                        <BreadCumbEngagements
                            title={this.props.language.language?.createEngagement.nombreDocumentoDetalles!}
                            listBreadcumbs={[
                                { title: 'Inicio', url: '/compromisos' },
                                { title: this.props.language.language?.createEngagement.nombreDocumentoDetalles!,  url:this.props.location.pathname },
                            ]}
                        />
                </div>

                <FormDetailEngagementByFiado
                    idFiado={parseInt(this.props.match.params.idFiado)}
                    idCompromiso={parseInt(this.props.match.params.idCompromiso)}
                    isModal={false}
                />


            </div>
        </>
    }

    componentDidMount() {
    }


}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(DetailsEngagementByFiado);



interface DetailState {
}





