import React from "react";
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";
import "./CustomTable.scss";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import {
  AlphanumericInput,
  GridCol,
  GridRow,
  Heading,
  LoadingSpinner,
  Table as LibertyTable,
  TableCell,
  TableRow,
} from "@lmig/lmds-react";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

interface ICustomTable<T> {
  data: T[];
  columns: ColumnDef<T, any>[];
  title?: string;
  noDataMessage?: string;
  isLoading?: boolean;
  searchLabel?: string;
  searchAllLabel?: string;
}

/**
 * @component
 * @description Tabla construida con base en {@link https://tanstack.com/table/v8 Tanstack Table} de acuerdo con lo que indica
 * Liberty en sus {@link https://designsystem.lmig.com/components/table-advanced-features instrucciones de tablas avanzadas}.
 * Soporta por defecto el filtrado de columnas, paginación, ordenamiento y varias otras cosas comunes.
 * @param data Arreglo con elementos del tipo que se espera se imprima en la pantalla.
 * @param columns Arreglo de columnas de acuerdo con la {@link https://tanstack.com/table/v8/docs/api/core/column-def definición de columnas de Tanstack Table}.
 * @param title (Opcional) Título de la tabla
 * @param noDataMessage (Opcional) Mensaje para mostrar cuando no se encuentren datos en data
 * @param isLoading (Opcional) Indicador de carga. Se recomienda su uso durante peticiones asíncronas.
 * @param searchLabel (Opcional) Mensaje a mostrar en los inputs de búsqueda por columna.
 * @param searchAllLabel (Opcional) Mensaje a mostrar en el input de búsqueda general.
 *
 * @example <caption>Construir una tabla para mostrar un arreglo de usuarios.</caption>
 * const users: User[] = await getUsers();
 * const columnHelper = createColumnHelper<User>();
 * const columns = [
 *  columnHelper.accesor("username", {}),
 *  columnHelper.accesor("email", {}),
 * ];
 *
 * return (
 *  <CustomTable
 *    data={users}
 *    columns={columns}
 *    title="Users"
 *  />
 * )
 */
const CustomTable = <T,>(props: ICustomTable<T>) => {
  const {
    data,
    columns,
    title,
    noDataMessage,
    isLoading = false,
    searchLabel,
    searchAllLabel,
  } = props;

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    autoResetPageIndex: false,
  });

  return (
    <div className="custom-table">
      <div className="custom-table__content">
        <div className="custom-table__content__table">
          <GridRow>
            <GridCol  md={10} sm={12}>
              {title && <Heading type="h4-bold">{title}</Heading>}
            </GridCol>
            <GridCol  md={2} sm={12}>
              <DebouncedInput 
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block"
               
                placeholder={searchAllLabel || "Buscar en todas las columnas"}
               
              />
            </GridCol>
          </GridRow>
          <LibertyTable>
            <tbody>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const hasAccents = /[\u00C0-\u1EFF]/g.test(header.id);
                    if (hasAccents)
                      throw new Error(
                        "CustomTableError: Se detectaron acentos o caracteres especiales en las columnas de la tabla. Revisa que tus IDs no los contengan para evitar comportamiento inadecuado."
                      );
                    return (
                      <TableCell
                        key={header.id}
                        colSpan={header.colSpan}
                        type="colHead"
                        style={{ minWidth: header.getSize() }}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: " 🔼",
                                desc: " 🔽",
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Filter
                                  column={header.column}
                                  table={table}
                                  searchLabel={searchLabel || "buscar"}
                                />
                              </div>
                            ) : null}
                          </>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
              {!table.getRowModel().rows.length && (
                <TableRow>
                  <TableCell colSpan={table.getAllColumns().length}>
                    {noDataMessage || "No se han encontrado garantías vencidas"}
                  </TableCell>
                </TableRow>
              )}
              {table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </tbody>
          </LibertyTable>
        </div>

        {isLoading && (
          <div className="custom-table__content__loading-message">
            <LoadingSpinner />
          </div>
        )}
       
      </div>
    </div>
  );
};

function Filter({
  column,
  table,
  searchLabel,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
  searchLabel: string;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === "number" ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${searchLabel} (${column.getFacetedUniqueValues().size})`}
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  placeholder,
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  placeholder: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <AlphanumericInput
      value={value.toString()}
      onChange={(e) => setValue(e.target.value)}
      labelVisual={placeholder}
    />
  );
}

export default CustomTable;
