import { ChangeEvent, Component } from 'react';
import './Notification.scss';
import { RouteComponentProps } from "react-router-dom";
import { Button, Checkbox, CheckboxGroup, Form, GridCol, GridRow } from '@lmig/lmds-react';
import NavBarInvitado from '../../public/nav-bar/NavBarInvitado';
import { LocalStorageClass } from '../../../class/LocalStorageClass';


interface NotificationProps {

    idCompromiso: number;
    idFiado: number;

}

interface SteepsModel {
    aceptaTerminos: boolean
    PERFIL: string;
    
}

interface Params {
    idCompromiso: string;
    idFiado: string;

}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

class NotificationClient extends Component<NotificationProps & RouteComponentProps<Params, SC, LocationState>, SteepsModel> {

    public idCompromiso: number;
    public idFiado: number;
    public localStorageClass = new LocalStorageClass();
  

    constructor(props: any) {
        super(props);
        document.title = "Notificación Fiado"
        this.idCompromiso = parseInt(this.props.match.params.idCompromiso);
        this.idFiado = parseInt(this.props.match.params.idFiado);

        const PERFIL = this.localStorageClass.getProfile();

        this.state ={

            PERFIL: '',
            aceptaTerminos: false
        } 
       

        this.handleOpen= this.handleOpen.bind(this)
        this.handleInputChangeToggle = this.handleInputChangeToggle.bind(this)

    }
           


   

    render(){

        return <>

            <div className="container-text-client" id="noti-engagements-client">
                <NavBarInvitado />

                <div className="row" >

                    <div className="col-md-8">
                    <h3>Acepto que entregaré la información pendiente en el plazo acordado con la Afianzadora. 
                         <br/>  <br/>En el supuesto de que no se realice lo anterior acepto y reconozco que la emisión futura de pólizas quedará suspendida, hasta concluir con la entrega en mención. </h3>
                    </div>


                    <div className="col-md-12 bntC" >
                        <Form>
                            <br />
                            <br />
                            <div className="col-md-12 btnS">
                                <CheckboxGroup >
                                    <GridRow gutters="horizontal">
                                        <GridCol md="flex-initial">
                                            <Checkbox  onChange={this.handleInputChangeToggle} defaultChecked={false}   required={true}  id="one--grid" labelVisual="Acepta Términos" name="grid" value="si" />
                                        </GridCol>
                                    </GridRow>
                                </CheckboxGroup>
                            </div>

                       {this.state.aceptaTerminos == true ? (
                            <div className="col-md-4 btnC">
                                <br />
                                <Button dynamicWidth 
                                    variant="primary"
                                    onClick={this.handleOpen}
                                    href={"/compromisos/acepta-compromiso/"+ this.idCompromiso +"/fiado/" + this.idFiado  }>
                                    Continuar
                                </Button>
                            </div>
                        ): null} 

                        {this.state.aceptaTerminos == false ? (
                            <div className="col-md-4 btnC">
                                <br />
                                <Button dynamicWidth  disabled
                                    variant="primary"
                                    onClick={this.handleOpen}
                                    href={"/compromisos/acepta-compromiso/"+ this.idCompromiso +"/fiado/" + this.idFiado  }>
                                    Continuar
                                </Button>
                            </div>
                        ): null} 
                        </Form>
                    </div>
                </div>
            </div>

        </>


    }

  
    handleOpen(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void {
    
       
        console.log("Se abre la ventana")
        //window.open("/compromisos/acepta-compromiso/"+ this.idCompromiso +"/fiado/" + this.idPrincipal);
         
    }

    handleInputChangeToggle(e: ChangeEvent<HTMLInputElement> , isChecked?: boolean): void {
   
        let acepta = false;
      

        if(e.target.checked == true){
          acepta=true
         this.setState({ aceptaTerminos:  acepta} )
           

        }
        
        if (e.target.checked == false){
            acepta= false
            this.setState({ aceptaTerminos:  acepta} )
            
        }         

        
       // this.aceptaTerminos= acepta
        console.log("Acepta " + this.state.aceptaTerminos)
        
        
    } 
      

}


export default NotificationClient;


