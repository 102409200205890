import React, {useEffect, useState} from 'react';
import './PositiveAlert.scss';
import {Alert} from "@lmig/lmds-react";
interface NegativeAlertProps {
    textAlert: string
}

export const PositiveAlert: React.FC<NegativeAlertProps> = ({textAlert }: NegativeAlertProps) => {

    return (
        <>
            {textAlert?(
                <Alert highlightType="positive" >
                    {textAlert}<br/>
                </Alert>
            ): null}

        </>

    );
};

