import React, { useEffect, useState} from 'react';
import './UnLockCommissionPrincipal.scss';
import {
    Badge,
    Button, Modal,
} from "@lmig/lmds-react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";

import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {InputChange} from "../../../class/CommonClass";
import {dispatchPutLockCommissionPrincipal, dispatchPutUnLockCommissionPrincipal} from "../../../actions/FiadoActions";

interface UnLockCommissionPrincipalProps {
    idPrincipal: number;
    showModal: boolean;
    namePrincipal?: string;
    motivoBloqueo: string;
    clickShowModal: (show: boolean, idPrincipal?: number) => void;
}

export const UnLockCommissionPrincipal: React.FC<UnLockCommissionPrincipalProps> = ({idPrincipal, showModal, namePrincipal,motivoBloqueo, clickShowModal}: UnLockCommissionPrincipalProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.unLockCommissionPrincipal);

    const dispatch = useDispatch();

    const [sendingLock, setSendingLock] = useState<boolean>(false);

    const [descReason, setDescReason] = useState<string>('');

    const [errorLockPrincipal, setErrorLockPrincipal] = useState<boolean>(true);


    const putLockCommissionPrincipal = async(idPrincipal: number) => {
        setSendingLock(true);
        setErrorLockPrincipal(false);
        try {
            const res = await garantiasService.putDesBloqueaComisionCliente(idPrincipal);
            setSendingLock(false);
            clickShowModal!(false, idPrincipal);
            dispatch(dispatchPutUnLockCommissionPrincipal(idPrincipal));

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setSendingLock(false);
                setErrorLockPrincipal(true);

            }
        }
    }


    const handleInputChange = (e: InputChange) =>{
        setDescReason( e.target.value );
    }

    useEffect(() => {

    },[]);


    return (
        <>

            <Modal
                isOpen={showModal}
                size="medium"
                modalStyle="standard"
                title=""
                closeFunc={() => {
                    clickShowModal(false);
                }}
                id="modal-lock-commission-principal"
            >
                <div className="col-12 list-reject">
                    <h4>
                        {namePrincipal? languageState?.titleModalto + ' ' + namePrincipal: languageState?.titleModal}
                    </h4>
                </div>
                {motivoBloqueo?(
                    <div className="col-12">
                        {languageState?.textLocked!}:<br/><br/>
                        <Badge highlightType="negative">
                            {motivoBloqueo}
                        </Badge><br/>

                    </div>
                ): null}


                <div className="row">
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="secondary"
                            onClick={()=>{
                                clickShowModal(false);
                            }}>
                            {languageState?.close!}
                        </Button>
                    </div>
                  <div className="col-md-6 col-12">
                        {!sendingLock ? (
                                <Button
                                    dynamicWidth
                                    variant="primary"
                                    onClick={()=>{
                                        putLockCommissionPrincipal(idPrincipal);
                                    }}>
                                    {languageState?.save!}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState?.saving!}/>
                        }

                    </div>

                </div>



            </Modal>
        </>
    );
};