export interface NavBarLanguageModel {
    openMenu: string;
    closeMenu: string;
    emails: string,
    notifications: string,
    closeSession: string,
    panelHeader1: string;
    panelHeader2: string;
    panelHeader3: string;
    panelHeader4: string;
    panelHeader5: string;
    titulo1: string 
    titulo2: string 
    titulo3: string 
    titulo4: string
    titulo5: string
    titulo6:string
    titulo7:string
    titulo8:string
    titulo9:string
    titulo10:string
    titulo11:string
    titulo12:string
    titulo13:string,
    configuracion: string
    cerrarSesion: string
    cancelar: string
    leyenda: string
    aceptar: string
    sessionExpired: string;
    askContinue: string;
    pleaseLogin: string;
    
}

export const NavBarLanguageEnglish: NavBarLanguageModel ={
    openMenu: 'Open Menu',
    closeMenu: 'Close Menu',
    emails: 'Emails',
    notifications: 'Notifications',
    closeSession: 'Sign out',
    panelHeader1: 'Collaterals',
    panelHeader2: 'Engagments',
    panelHeader3: 'Credit Bureau',
    panelHeader4: 'Policies',
    panelHeader5: 'Treasury',
    titulo1: 'Compromise Management ', 
    titulo2: 'Authorize Compromises', 
    titulo3: 'Completed Compromises', 
    titulo4: 'Collaterals',
    titulo5: 'Review of Collaterals',
    titulo6:'Dashboard Credit Bureau',
    titulo7:'Policies RH',
    titulo8:'Credit Bureau Account Management',
    titulo9:'Cash Receipt',
    titulo10:'Consultation Requests',
    titulo11: 'Loans Report',
    titulo12: 'Expedient Report',
    titulo13:'Audit',
    configuracion: 'Settings',
    cerrarSesion: 'Exit',
    cancelar: 'Cancel',
    leyenda: 'Are you sure you want to exit?' ,
    aceptar: 'OK',
    sessionExpired: 'sessionExpired',
    askContinue: 'Do you want to continue in the system?',
    pleaseLogin: 'Please login again'

   
}


export const NavBarLanguageSpanish: NavBarLanguageModel ={

    openMenu: 'Open Menu',
    closeMenu: 'Close Menu',
    emails: 'Correos',
    notifications: 'Notificaciones',
    closeSession: 'Cerrar sesión',
    panelHeader1: 'Garantías',
    panelHeader2: 'Compromisos',
    panelHeader3: 'Buró de Crédito',
    panelHeader4: 'Polizas',
    panelHeader5: 'Tesorería',
    titulo1: 'Administración de Compromisos', 
    titulo2: 'Autorización de Compromisos', 
    titulo3: 'Cumplimiento de Compromisos', 
    titulo4: 'Garantías',
    titulo5: 'Revisión de Garantías',
    titulo6:'Dashboard Buró de Crédito',
    titulo7:'Polizas RH',
    titulo8:'Administración de cuenta Búro de Crédito',
    titulo9:'Recibo de Caja',
    titulo10:'Solicitudes de Consulta',
    titulo11: 'Reporte de Garantías',
    titulo12: 'Reporte de Expediente',
    titulo13:'Auditoria',
    configuracion: 'Configuración',
    cerrarSesion: 'Salir',
    cancelar: 'Cancelar',
    leyenda: '¿Estas seguro que deseas salir del módulo?',
    aceptar: 'Aceptar',
    sessionExpired: 'Tu sesión ha Caducado',
    askContinue: '¿Deseas continuar en el sistema?',
    pleaseLogin: 'Por favor vuelve a iniciar sesión'

}