import React, {ChangeEvent, useEffect, useState} from 'react';
import './DocumentoExpedienteMantenimiento.scss';
import {
    IconAdd,
    IconChecklist,
    IconCheckmark,
    IconEdit,
    IconErrorAlert,
    IconSearch,
    IconTrash,
    IconUpload
} from "@lmig/lmds-react";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import {MAXSIZEUPLOADFILE, openNewWindow, validFileImg} from "../../../class/CommonClass";
import * as garantiasService from '../../../services/garantias/GarantiasService';
import * as supportService from "../../../services/support/SupportService";
import {AxiosError} from "axios";
import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";
import {TypeDocModel} from "../../../models/garantias/TypeDocModel";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";
import {
    AuthorizationExpedientIdentification
} from "../../../components/garantias/authorization-expedient-identification/AuthorizationExpedientIdentification";
import {ModalFileMaxUpload} from "../../../components/public/modal-file-max-upload/ModalFileMaxUpload";
import {Spinner} from "react-bootstrap";
import {DragDropExpediente} from "../drag-drop-expediente/DragDropExpediente";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";

interface DocumentoMantenimientoProps {
    idPrincipal: number;
    typeDoc: TypeDocModel;
    index: number
}


export const DocumentoMantenimiento: React.FC<DocumentoMantenimientoProps> = (
    {idPrincipal,
        typeDoc,
        index
    }:DocumentoMantenimientoProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const [openModalExcedLimitFile, setOpenModalExcedLimitFile] = useState<boolean>(false);

    const [listDocuments, setListDocuments] = useState<ExpedienteIdetificacionModel []>([]);


    const [draggable, setDraggable] = useState<boolean>(true);

    const [uploading, setUploading] = useState<boolean>(false);



    const initUploading: ExpedienteIdetificacionModel = {
        idDocumento: 0,
        idTipoDocumento: typeDoc.id,
        idClave: null,
        descripcion: typeDoc.nombre,
        descripcionTipoDocumento: typeDoc.nombre,
        url:  '',
        observacion: null,
        estado:  statesDocIdentification.subiendo,
        obligatorio: null,
        detalle: 'Cargando Archivo'
    }

    const drop = (ev: React.DragEvent<HTMLDivElement>) => {
        const idHTML = ev.dataTransfer.getData("text");
        const idDoc: number = parseInt(idHTML.substr('id-doc-'.length, idHTML.length));

       /* let foundDoc: ExpedienteIdetificacionModel;
        const auxListDocuments = listExpediente;

        // buscar el elmento que quiero mover
        for (let i of auxListDocuments){


            for (let j = 0; j< i.documents.length; j++){
                if (i.documents[j].idDocumento== idDoc){
                    //console.log(i.documents[j].descripcion + '\n' + i.documents[j].idDocumento + '=='+ idDoc  )
                    foundDoc = i.documents[j]
                    // actualizar a la nueva categoria(tipo de documento)
                    foundDoc.idTipoDocumento = idTypeDestinity;
                    setIdTypeDestinity(0); // reiniciar
                    putExpedienteIdentificacion(foundDoc);

                    break;
                }
            }
        }*/

    }
    const  allowDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        const idDoc = (ev.target as HTMLLIElement).id;
        // si pasa por enciam de una zona que empiece con los id
        // con la nomenclatura 'type-doc-' se va actualizando el destino del
        // documento
        const idHTML = 'type-doc-';
        if (idDoc.substr(0,idHTML.length)==idHTML){
            //console.log(idDoc)
            const idType= parseInt(idDoc.substr(idHTML.length, idDoc.length));
           // setIdTypeDestinity(idType)
        }

        ev.preventDefault();

    }

    const drag = (ev: React.DragEvent<HTMLLIElement>) => {
        const idDoc = (ev.target as HTMLLIElement).id;
        ev.dataTransfer.setData("text", idDoc);
        //  console.log('drag:  id ' + idDoc )

    }


    const handleFileUploadInputDocIdentification = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e)
        console.log(e.target.files![0])
        console.log(e.target.files![0].name);
        // ; = 'archivo.pdf';  //e.target.files![0].name.trim()
        if (validFileImg(e)){
            const indiceDocIdentification= parseInt(e.target.name);
            const formData = new FormData();
            formData.append('idCliente', String(idPrincipal));
            formData.append('idTipoDocumento', String(typeDoc.id));
            formData.append('fileName', e.target.files![0]);
            e.target.value = '';// vaciar el input file
            postDocIdentification(formData, indiceDocIdentification);
        }
        else if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
            setOpenModalExcedLimitFile(true)
            e.target.value = '';// vaciar el input file
        }


    }


    const postDocIdentification = async (formData: FormData, indiceDocIdentification: number) => {



        const auxListDocuments : ExpedienteIdetificacionModel [] = [...listDocuments, initUploading]
        setListDocuments(auxListDocuments);
        setUploading(true);


        try {
            const res = await supportService.postExpedienteIdentificacion(formData);

            let newListDocuments : ExpedienteIdetificacionModel [] = []

            let posDel : number | null= null ;
            for (let i= 0; i < auxListDocuments.length ; i++){
                if (auxListDocuments[i].estado == statesDocIdentification.subiendo){
                    posDel = i;
                    break;
                }
            }

            auxListDocuments[posDel!] = res.data;

            newListDocuments = [...auxListDocuments]

            setListDocuments(newListDocuments);
            setUploading(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setUploading(false);

            }
        }
    }

    const putFile = async (formData: FormData, indiceFile: number) => {
        setUploading(true);

        const auxListDocuments : ExpedienteIdetificacionModel [] = [...listDocuments]

        auxListDocuments[indiceFile] = initUploading;

        setListDocuments(auxListDocuments)

        try {
            const res = await supportService.putExpedienteArchivo(formData);
            auxListDocuments[indiceFile] = res.data;
            setListDocuments(auxListDocuments)
            setUploading(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                // quitar el spiner de loading
                setUploading(false);

            }
        }
    }

    useEffect(() => {
        setListDocuments(typeDoc.documents)
    },[typeDoc.documents]);



    return (
        <>
            <div className="card" id="documento-expediente-mantenimiento">

                    <div className="card-header">
                        {typeDoc.nombre}
                        <div className={"float-right expedient-files"}>

                            {(uploading)?
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border"
                                             role="status"
                                             size="sm"
                                             variant="primary">

                                    </Spinner>
                                </div>:
                                null
                               /* <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        name={String(index)}
                                        accept=".pdf"
                                        disabled={uploading}
                                        onChange={handleFileUploadInputDocIdentification}
                                    />

                                    <IconAdd
                                        className="d-block mx-auto bg-white ico-add"
                                        size="24"
                                    />

                                </label>*/
                            }





                        </div>

                    </div>

                    <div className="card-body"
                         id={"type-doc-" + typeDoc.id}
                         onDrop={drop} onDragOver={allowDrop}>


                        {/*  {idTypeDestinity!=0 ? (

                            <div className={"w-100 drop-area text-center"}>
                                <br/>
                                {languageState.language?.expedientIdentificaction.dragHere}
                                <br/><br/>
                            </div>
                        ): null}*/}

                        <ul className="list-group list-group-flush">
                            {listDocuments.map((iDoc, index2) =>(
                                iDoc.idDocumento!=null  ? (
                                    <li
                                        key={index2}
                                        draggable={(iDoc.idDocumento == null || draggable == false)? false: true}
                                        onDragStart={drag}
                                        className= {`list-group-item draggable-${(iDoc.idDocumento == null || draggable == false) ? "false" : "true"}`}
                                        id={'id-doc-' + iDoc.idDocumento!}>

                                        <div className="row">
                                            <div className="col-sm-3 col-md-2">

                                                {iDoc.estado == statesDocIdentification.enCaptura ? (
                                                    <>
                                                        <IconEdit color="gray"
                                                                  size="16"
                                                                  title={languageState.language?.expedientIdentificaction.inCapture}/>
                                                    </>
                                                ): null}

                                                {iDoc.estado == statesDocIdentification.enRevision ? (
                                                    <>
                                                        <IconChecklist
                                                            color="caution" size="16"
                                                            title={languageState.language?.expedientIdentificaction.inReview}/>
                                                    </>
                                                ): null}

                                                {iDoc.estado == statesDocIdentification.rechazado ? (
                                                    <>
                                                        <IconErrorAlert
                                                            color="error"
                                                            size="16"
                                                            title={languageState.language?.expedientIdentificaction.reject}/>

                                                    </>
                                                ): null}

                                                {iDoc.estado == statesDocIdentification.autorizado ? (
                                                    <>
                                                        <IconCheckmark color="success"
                                                                       size="16"
                                                                       title={languageState.language?.expedientIdentificaction.authorized}
                                                        />
                                                        <IconCheckmark color="success"
                                                                       size="16"
                                                                       title={languageState.language?.expedientIdentificaction.authorized}/>
                                                    </>
                                                ): null}

                                                {iDoc.estado == statesDocIdentification.guardadoDW ? (
                                                    <>
                                                        <IconCheckmark color="success"
                                                                       size="16"
                                                                       title={languageState.language?.expedientIdentificaction.savedDW}
                                                        />
                                                        <IconCheckmark color="success"
                                                                       size="16"
                                                                       title={languageState.language?.expedientIdentificaction.savedDW}
                                                        />
                                                    </>
                                                ): null}

                                                {iDoc.estado == statesDocIdentification.subiendo  ? (

                                                    <div className="d-flex justify-content-center">
                                                        <Spinner animation="border"
                                                                 role="status"
                                                                 size="sm"
                                                                 variant="primary">

                                                        </Spinner>
                                                    </div>


                                                ): null}


                                                {iDoc.estado == statesDocIdentification.eliminando  ? (

                                                    <div
                                                        className="d-flex justify-content-center">
                                                        <Spinner animation="border"
                                                                 role="status"
                                                                 size="sm"
                                                                 variant="danger">

                                                        </Spinner>
                                                    </div>

                                                ): null}


                                            </div>

                                            <div className="col-sm-9 col-md-6 name-document">
                                                { iDoc.descripcion}<br/>

                                            </div>

                                            <div className="col-sm-12 col-md-4">
                                                <div className={"row actions-expedient expedient-files"}>

                                                    { iDoc.estado == statesDocIdentification.autorizado ||
                                                    iDoc.estado == statesDocIdentification.guardadoDW? (
                                                        <></>
                                                    ):
                                                        null
                                                    }

                                                    { iDoc.estado == statesDocIdentification.autorizado ||
                                                    iDoc.estado == statesDocIdentification.guardadoDW ||
                                                    iDoc.estado == statesDocIdentification.subiendo ||
                                                    iDoc.estado == statesDocIdentification.eliminando? (
                                                        <>

                                                        </>
                                                    ): null
                                                  /*  <div className="col">
                                                        <button className="btn btn-light"
                                                                onClick={() => {
                                                                     deleteFile(iDoc.idDocumento!)
                                                                }}>

                                                            <IconTrash
                                                                color="negative"
                                                                size="16"
                                                                title={languageState.language?.expedientIdentificaction.delete}
                                                            />
                                                        </button>
                                                    </div>*/
                                                    }


                                                    <div className="col">
                                                        {iDoc.url && iDoc.url!= null  ? (
                                                                <>
                                                                    <button className="btn btn-light"
                                                                            onClick={() => {
                                                                                openNewWindow(iDoc.url!);
                                                                            }}>
                                                                        <IconSearch
                                                                            color="informative"
                                                                            size="16"
                                                                            title={languageState.language?.expedientIdentificaction.watch}/>
                                                                    </button>
                                                                </>
                                                            ):
                                                            null
                                                        }
                                                    </div>



                                                </div>

                                            </div>

                                            <div className="col-md-12">

                                                <div className="col">
                                                    {
                                                        (uploading == false)?
                                                            <>
                                                                {(iDoc.idDocumento!= null && iDoc.documentoGuardado == false)?
                                                                    <DragDropExpediente
                                                                        idDocumento={iDoc.idDocumento}
                                                                        idTypoDoc={typeDoc.id}
                                                                        returnFormData={formData => {
                                                                            putFile(formData, index2);
                                                                        }}
                                                                    />
                                                                    :<>
                                                                        {(iDoc.idDocumento!=null  && iDoc.documentoGuardado == true)?
                                                                            <>
                                                                                <br/><b>Este Archivo, ya esta Guardado en S3</b>
                                                                            </>:
                                                                            null
                                                                        }

                                                                    </>

                                                                }

                                                            </>
                                                          :
                                                            <>

                                                            </>
                                                    }
                                                </div>



                                            </div>

                                        </div>
                                    </li>): null
                            ))}

                        </ul>
                    </div>

            </div>



            <ModalFileMaxUpload
                openModal={openModalExcedLimitFile}
                maxSize={MAXSIZEUPLOADFILE}
                clickShowModal={show1 => {
                    //alert(show1)
                    setOpenModalExcedLimitFile(show1)
                }}/>




        </>
    );
};

