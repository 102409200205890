export interface AuthorizationDocWarrantyLanguageModel {
    selectReason: string;
    sorryReason: string;
    reintent: string;
    close: string;
    save: string;
    saving: string;
    areYouSure: string;
    authorizeWarranty: string;
    authorize: string;
    cancel: string;
    authorizing: string;
    seleactAReason: string;
}

export const AuthorizationDocWarrantyLanguageEnglish: AuthorizationDocWarrantyLanguageModel ={
    selectReason: 'Select Rejection Reasons:',
    sorryReason: 'Sorry something happened loading the Reasons list, please check your connection and try again.',
    reintent: 'Reintent',
    close: 'Close',
    save: 'Save',
    saving: 'Saving',
    areYouSure: 'Are you sure you want to Authorize this Guarantee document?',
    authorizeWarranty: 'Authorize Collateral',
    authorize: 'Authorize',
    cancel: 'Cancel',
    authorizing: 'Authorizing',
    seleactAReason: 'Select at least one reason for rejection, please, to be able to save them'
}
export const AuthorizationDocWarrantyLanguageSpanish: AuthorizationDocWarrantyLanguageModel = {
    selectReason: 'Selecciona las Razones de Rechazo:',
    sorryReason: 'Lo sentimos algo ha ocurrido al cargar la lista de Motivos, revise su conexión e intente de nuevo.',
    reintent: 'Reintentar',
    close: 'Cerrar',
    save: 'Guardar',
    saving: 'Guardando',
    areYouSure: '¿Estás Seguro de que quieres Autorizar el documento de esta Garantía?',
    authorizeWarranty: 'Autorizar Garantía',
    authorize: 'Autorizar',
    cancel: 'Cancelar',
    authorizing: 'Autorizando',
    seleactAReason: 'Selecciona al menos un motivo de rechazo, por favor, para poder guardarlos'
}