import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import "./ReporteGarantias.scss";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import NavBar from "../../../components/public/nav-bar/NavBar";
import { Pagination } from "react-bootstrap";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import { ReporteGarantiasModel } from "../../../models/reporte-garantias/ReporteGarantiasModel";
import { PaginacionModel } from "../../../models/reporte-garantias/PaginacionModel";
import { ReporteGarantiasReporteModel } from "../../../models/reporte-garantias/ReporteGarantiasReporteModel";
import { AxiosError } from "axios";
import {Button, Alert} from "@lmig/lmds-react";
import Autocomplete from "react-autocomplete";
import CustomTable from "../../../components/public/custom-table/CustomTable";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import { InputChange, maxDate } from "../../../class/CommonClass";
import { BreadCumb } from "../../../components/public/breadcumb/BreadCumb";
import environment from "../../../environments/environment";
interface ReporteGarantiasProps {
  language: DefaultStateI;
}

class ReporteGarantias extends Component<
  ReporteGarantiasProps & RouteComponentProps,
  ReporteGarantiasState
> {
  public localStorageClass = new LocalStorageClass();
  constructor(props: any) {
    super(props);
    document.title = "Reporte de Garantias";
    const PERFIL = this.localStorageClass.getProfile();
    console.log("Impresion del perfil roles:" + PERFIL.roles);
    console.log("Impresion del perfil Id :" + PERFIL.id);
    console.log("Impresion del perfil username:" + PERFIL.username);
    this.handleInputChangeFiado = this.handleInputChangeFiado.bind(this);
    this.handleInputChangeAgente = this.handleInputChangeAgente.bind(this);
    this.handleInputChangeSelectAgent =
      this.handleInputChangeSelectAgent.bind(this);
    this.handleInputChangeSelectFiado =
      this.handleInputChangeSelectFiado.bind(this);
    this.handleBuscar = this.handleBuscar.bind(this);
    this.handleDateChangeBegin = this.handleDateChangeBegin.bind(this);
    this.handleDateChangeEnd = this.handleDateChangeEnd.bind(this);
    this.handlePaginator = this.handlePaginator.bind(this);
    this.formarURL = this.formarURL.bind(this);
    this.validaFechaDatePicker = this.validaFechaDatePicker.bind(this);
    this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this);
    this.validFechaFinal = this.validFechaFinal.bind(this);
    this.validFechaInicial = this.validFechaInicial.bind(this);

    this.state = {
      loadingStatistics: false,
      listGarantiasVencidas: [],
      listGarantiasVencidasFiados: [],
      listaReporteGarantias: [],
      listPaginacion: [],
      busquedaEjecutivo: false,
      tipoPerfil: PERFIL.roles.toString(),
      perfilActivo: "",
      eventoNombre: "",
      valAgente: "",
      valFiado: "",
      fechaFinal: "",
      fechaInicial: "",
      idAgente: 0,
      idCliente: 0,
      idEjecutivo: 0,
      idEjecutivoSesion: Number(PERFIL.id),
      page: 0,
      size: 0,
      numPaginas: 1,
      currentPage: 1,
      numTotalDepositos: 0,
      numRegistrosMax: 10,
      numRegistrosMin: 1,
      coleccionPaginado: "",
      validaFechaIni: false,
      validaFechaFin: false,
      msgFechaI: "",
      msgFechaF: "",
      leyendaBuscarAgente: "",
    };
  }
  handleBuscar(coleccion: any) {
    var temp = this.state.idAgente;
    var validaFechaTemp = this.validaFechaDatePicker();
    if (validaFechaTemp === false) {
      //this.setState({validaFechaInicial: 'VALIDA FECHA'});
      //alert("INGRESA LAS 2 FECHAS");
    } else {
      console.log("Peticion:" + coleccion);
      console.log("METODO BUSCAR PARAMETROS----");

      this.setState({ idAgente: coleccion.idAgente });
      this.setState({ idCliente: coleccion.idCliente });
      this.setState({ coleccionPaginado: coleccion });

      let cadenaUrl = this.formarURL();
      console.log("idAgente: " + this.state.idAgente);
      console.log("idCliente: " + this.state.idCliente);
      console.log("URL FINAL--->" + cadenaUrl);

      this.buscarReporteArmado(cadenaUrl, 1, 10);

      //this.setState({ valAgente: "" });
      this.setState({ validaFechaIni: false });
      this.setState({ validaFechaFin: false });
      this.setState({ valFiado: "" }); // envia espacio para cuando se teclee se mande el nombre con espacio para su busqueda y no de un 400
      this.setState({ idAgente: temp });
    }
  }
  validaFechaDatePicker() {
    if (this.state.fechaInicial === "" && this.state.fechaFinal !== "") {
      // alert("Ingresa Fecha Final");
      this.setState({ validaFechaIni: true });
      return false;
    } else if (this.state.fechaInicial !== "" && this.state.fechaFinal === "") {
      //    alert("Ingresa Fecha Inicial");
      this.setState({ validaFechaFin: true });
      return false;
    } else if (this.state.fechaInicial === "" && this.state.fechaFinal === "") {
      //this.setState({validaFecha:true});
      return true;
    }
    // this.setState({validaFecha:false});
    return true;
  }

  formatearFecha = (fecha: string) => {
    if (fecha != null) {
      const date = new Date(fecha);
      let dd = String(date.getDate() + 1).padStart(2, "0");
      let mm = String(date.getMonth() + 1).padStart(2, "0");
      let yyyy = date.getFullYear();
      return dd + "-" + mm + "-" + yyyy;
    } else return "";
  };
  formarURL() {
    const API = environment.APIGARANTIAS;
    let cadenaUrl = API.concat("/reportesGarantias/reporte?");
    

    if (this.state.fechaInicial != null && this.state.fechaInicial !== "") {
      cadenaUrl = cadenaUrl.concat(
        "&fechaInicial=" + this.formatearFecha(this.state.fechaInicial)
      );
    }
    if (this.state.fechaFinal != null && this.state.fechaFinal !== "") {
      cadenaUrl = cadenaUrl.concat(
        "&fechaFinal=" + this.formatearFecha(this.state.fechaFinal)
      );
    }
    if (this.state.idAgente > 0) {
      cadenaUrl = cadenaUrl.concat("&idAgente=" + this.state.idAgente);
    }
    if (this.state.idCliente != null && this.state.idCliente !== 0) {
      cadenaUrl = cadenaUrl.concat("&idCliente=" + this.state.idCliente);
    }
    console.log("-------URL FORMAURL: " + cadenaUrl);
    return cadenaUrl;
  }
  handleInputChangeAgente(
    e: React.ChangeEvent<HTMLInputElement>,
    clave: any
  ): void {
    const val = e.target.value;
    if (val === "" || val === null || val === " ") {
      this.setState({ idAgente: -1 });
    }
    this.setState({ valAgente: val });
    console.log("ID_AGENTE CHANGE AGENTE: " + this.state.idAgente);
    if (val.length > 2) {
      this.getConosultaAgentes(this.state.idEjecutivoSesion, clave.descripcion);
    }
    console.log("Agente Tecleo: " + clave);
  }
  handleInputChangeFiado(
    e: React.ChangeEvent<HTMLInputElement>,
    clave: string
  ): void {
    const val = e.target.value;

    this.setState({ valFiado: val });
    if (this.state.tipoPerfil === "AGENTE") {
      this.getConosultaFiados(
        this.state.idEjecutivoSesion,
        this.state.valFiado
      );
    } else {
      this.getConosultaFiados(Number(clave), this.state.valFiado);
    }
    console.log("Fiado Tecleadoo: " + clave);
    console.log("tecleo idEjecutivoSesion: " + this.state.idEjecutivoSesion);
  }
  handleInputChangeSelectAgent(clave: string, item: any): void {
    console.log("CLAVE: " + clave);
    this.setState({ idAgente: item.clave });
    this.setState({ valAgente: item.descripcion });
    console.log("idAgente CHANGE SELECT:" + this.state.idAgente);
    this.getConosultaFiados(item.clave, this.state.valFiado);
  }
  handleInputChangeSelectFiado(clave: string, item: any): void {
    console.log("ID_CLIENTE:" + clave);
    this.setState({ idCliente: item.clave });
    this.setState({ valFiado: item.descripcion });
  }
  handleDateChangeBegin(item: any) {
    var newStr = item.replaceAll("/", "-");
    //this.setState({ fechaInicial: newStr });
    return newStr;
  }
  handleDateChangeEnd(item: any) {
    var newStr = item.replaceAll("/", "-");
    this.setState({ fechaFinal: newStr });
  }

  renderAgentName(state: { clave: string; descripcion: string }, val: string) {
    const nombreCompleto = state.descripcion;
    return nombreCompleto.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  }
  renderFiadoName(state: { clave: string; descripcion: string }, val: string) {
    const nombreCompleto = state.descripcion;
    return nombreCompleto.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  }
  handlePaginator = (opcion: number) => {
    let pag: number = this.state.currentPage;

    if (opcion === 1) {
      pag = this.state.currentPage + 1;
      this.setState({ currentPage: pag });
    } else {
      pag = this.state.currentPage - 1;
      this.setState({ currentPage: pag });
    }
    this.buscarReporteArmado(this.formarURL(), this.state.currentPage, 10);
  };

  handleInputChangeSelect(e: InputChange): void {
    const name = e.target.name;
    let value = e.target.value;
    //toLocaleDateString

    switch (name) {
      case "fechaInicial":
        this.setState({ fechaInicial: value });
        //alert("FECHAINICIAL VALUE : " + fechaFiltrada);
        this.validFechaInicial();
        break;
      case "fechaFinal":
        this.setState({ fechaFinal: value });
        //alert("FECHAFINAL VALUE : " + fechaFiltrada);
        // this.getDepositos(value, this.state.fechaInicial, this.state.filterSearchValue, 1, this.state.queryin, 10);
        break;
    }
  }
  public validFechaFinal(): boolean {
    const fechaFinal = this.state.fechaFinal;
    let endDate = new Date(fechaFinal);
    const startDate = new Date(this.state.fechaInicial);
    if (fechaFinal.length == 0) {
      this.setState({ msgFechaF: "" });
    }
    if (fechaFinal.length == 10) {
      // si esta llena la fecha final
      // si la fecha final es menor a la inicial
      if (this.state.fechaInicial && endDate < startDate) {
        // le escribimos un mensaje
        this.setState({
          msgFechaF:
            this.props.language.language?.depositosGarantias.lblDateFminor,
        });
        return false;
      } else if (endDate > maxDate) {
        this.setState({
          msgFechaF:
            this.props.language.language?.depositosGarantias.lblDateFNoValid,
        });
        return false;
      } else {
        if (new Date(this.state.fechaInicial) === endDate) {
          this.setState({ msgFechaI: "" });
        }
        this.setState({ msgFechaF: "" });
        return true;
      }
    } else {
      return false;
    }
  }
  public validFechaInicial(): boolean {
    const fechaInicial = this.state.fechaInicial;
    let initialDate = new Date(fechaInicial);
    const fechaFinal = this.state.fechaFinal!;
    if (fechaInicial.length == 0) {
      this.setState({ msgFechaI: "" });
    }
    //  console.log('fecha inicial: ' + fechaInicial.length);
    if (fechaInicial.length == 10) {
      // si esta llena la fecha inicial
      // si la fecha es menor al día de hoy
      //console.log('entro a las validaciones de fecha inicial');
      if (initialDate > maxDate) {
        this.setState({
          msgFechaI:
            this.props.language.language?.depositosGarantias.lblDateINoValid,
        });
        return false;
      } else if (fechaFinal) {
        // vamos a comparar si la fecha final no sea menor a la fecha inicial
        let finalDate = new Date(fechaFinal);
        if (initialDate === finalDate) {
          this.setState({ msgFechaI: "" });
          return true;
        }

        if (initialDate > finalDate) {
          this.setState({
            msgFechaI:
              this.props.language.language?.depositosGarantias.lblDateIElderly,
          });
          return false;
        } else {
          return true;
        }
      } else {
        // inicializamos el  mensaje
        this.setState({ msgFechaI: "" });
        return true;
      }
    }
    {
      return false;
    }
  }

  columnHelper = createColumnHelper<ReporteGarantiasReporteModel>();
  columns: ColumnDef<ReporteGarantiasReporteModel, any>[] = [
    this.columnHelper.accessor("cliente", {
      header:
        "Fiado" || `${this.props.language.language?.reporteGarantias.fiado}`,
    }),
    this.columnHelper.accessor("garantia", {
      header:
        "Garantía" ||
        `${this.props.language.language?.reporteGarantias.garantia}`,
    }),
    this.columnHelper.accessor("agente", {
      header:
        "Agente" || `${this.props.language.language?.reporteGarantias.agente}`,
    }),
    this.columnHelper.accessor("fechaVencimiento", {
      header:
        "Fecha Vencimiento" ||
        `${this.props.language.language?.reporteGarantias.fecha_vencimiento}`,
      cell: (info) => <>{new Date(info.getValue()).toLocaleDateString()}</>,
    }),
    this.columnHelper.accessor("estatus", {
      header:
        "Estatus" ||
        `${this.props.language.language?.reporteGarantias.estatus}`,
    }),
    this.columnHelper.accessor("cumulo", {
      header:
        "Cumulo" || `${this.props.language.language?.reporteGarantias.cumulo}`,
    }),
  ];
  render() {
    return (
      <>
        <NavBar />
        <div id="reporte-garantias" className="container-fluid">
          <div className="reporte-garantia-container">
          <BreadCumb title={this.props.language.language?.reporteGarantias.titlePage!}
           listBreadcumbs={[
               {title:this.props.language.language?.statisticsWarranty.home!, url:'/garantias/'},
               {title:this.props.language.language?.reporteGarantias.titlePage!, url: this.props.location.pathname}]}/>
        

            <div className="row">
              <br />

              <div className="col">
                <input
                  type="date"
                  name="fechaInicial"
                  id="fechaInicial"
                  placeholder="Fecha Inicial"
                  value={this.state.fechaInicial}
                  className="input-date input"
                  onChange={(e) => this.handleInputChangeSelect(e)}
                  onMouseLeave={(e: React.MouseEvent<any>) =>
                    this.validFechaInicial()
                  }
                  onSelect={(event) => this.validFechaInicial()}
                  onBlur={(event: React.FocusEvent<any>) =>
                    this.validFechaInicial()
                  }
                />
                <Alert
                  hidden={this.state.validaFechaIni !== true}
                  highlightType="negative"
                >
                  {this.props.language.language?.reporteGarantias.fechaInicial}
                </Alert>
              </div>
              <div className="col">
                <input
                  type="date"
                  name="fechaFinal"
                  id="fechaFinal"
                  value={this.state.fechaFinal}
                  className="input-date input"
                  onChange={(e) => this.handleInputChangeSelect(e)}
                  onMouseLeave={(e: React.MouseEvent<any>) =>
                    this.validFechaFinal()
                  }
                  onSelect={(event) => this.validFechaFinal()}
                  onBlur={(event: React.FocusEvent<any>) =>
                    this.validFechaFinal()
                  }
                />
                <Alert
                  hidden={this.state.validaFechaFin !== true}
                  highlightType="negative"
                >
                  {this.props.language.language?.reporteGarantias.fechaFinal}
                </Alert>
              </div>
              {this.state.tipoPerfil === "EJECUTIVO_GARANTIAS" ||
              this.state.tipoPerfil === "EJECUTIVO_CUENTA" ? (
                <div className="col-3">
                  <div className="autocomplete-wrapper-agent">
                    <Autocomplete
                      inputProps={{
                        placeholder: `${this.props.language.language?.reporteGarantias.busquedaAgente}`,
                        style: {
                          fontSize: 16,
                          width: "100%",
                          padding: 15,
                          overflow: "hidden",
                        },
                      }}
                      value={this.state.valAgente}
                      items={this.state.listGarantiasVencidas}
                      getItemValue={(item) => item.clave + item.descripcion}
                      shouldItemRender={this.renderAgentName}
                      renderMenu={(item) => (
                        <div
                          className="dropdown"
                          style={{ overflowX: "hidden" }}
                        >
                          {item}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "selected-item" : "title"
                          }`}
                        >
                          {`${item.descripcion}`}
                        </div>
                      )}
                      onChange={this.handleInputChangeAgente}
                      onSelect={this.handleInputChangeSelectAgent}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-3">
                <div className="autocomplete-wrapper-agent">
                  <Autocomplete
                    inputProps={{
                      placeholder: `${this.props.language.language?.reporteGarantias.busquedaFiado}`,
                      style: { fontSize: 16, width: "100%", padding: 15 },
                    }}
                    value={this.state.valFiado}
                    items={this.state.listGarantiasVencidasFiados}
                    getItemValue={(item) => item.clave + item.descripcion}
                    shouldItemRender={this.renderFiadoName}
                    renderMenu={(item) => (
                      <div className="dropdown" style={{ overflowX: "hidden" }}>
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : "title"
                        }`}
                      >
                        {`${item.descripcion} `}
                      </div>
                    )}
                    onChange={this.handleInputChangeFiado}
                    onSelect={this.handleInputChangeSelectFiado}
                  />
                </div>
              </div>
              <div className="col">
                <Button
                  type="button"
                  variant="primary"
                  size="large"
                  onClick={(e) => this.handleBuscar(e)}
                  >
                  {"Buscar"}
                </Button>
              </div>
            </div>
            <br />
            <br />
            <hr></hr>
            <div>
          <CustomTable
            data={this.state.listaReporteGarantias}
            columns={this.columns}
          />
          <div
            className="container-center mt-5 "
            hidden={this.state.listaReporteGarantias.length > 0}
          ></div>
        </div>
        <Pagination className="float-md-right" id="pagination-table-warranty">
          <Pagination.Prev
            onClick={() =>
              this.state.currentPage > 1 ? this.handlePaginator(2) : null
            }
          />

          {this.state.listPaginacion.map((i, index) => (
            <Pagination.Item
              key={i}
              active={i === this.state.currentPage}
              onClick={() => (
                this.setState({ currentPage: i }),
                this.buscarReporteArmado(this.formarURL(), i, 10)
              )}
            >
              {i}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() =>
              this.state.currentPage < this.state.numPaginas
                ? this.handlePaginator(1)
                : null
            }
          />
        </Pagination>
          </div>
        </div>

      </>
    );
  }
  componentDidMount() {
    // this.getGarantiasVencidas(0, 1, 2);
    //this.setState(leyendaBuscarAgente : this.props.language.language?.reporteGarantias.titlePage);
  }
  public async getConosultaAgentes(idEjecutivo: number, nombre: string) {
    console.log("getConosultaAgentes-idEjecutivo:" + idEjecutivo);
    console.log("getConosultaAgentes-nombre:" + nombre);
    if (!nombre) {
      nombre = "";
    }
    this.setState({ loadingStatistics: true });
    try {
      const res = await garantiasService.getConsultaAgentes(idEjecutivo, nombre);
      if (res.data.valuesSeleccion.length === 0) {
        this.setState({
          listGarantiasVencidas: [],
        });
        return;
      }
      this.setState({
        loadingStatistics: false,
        listGarantiasVencidas: res.data.valuesSeleccion,
      });

      // await console.log(this.state.listGarantiasVencidas);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        console.log("axios error " + err.response.status);
        console.log("axios error " + err.response.data);
        this.setState({ loadingStatistics: false });
      }
    }
  }
  public async getConosultaFiados(idAgente: number, nombre: string) {
    this.setState({ loadingStatistics: true });
    try {
      console.log("getConosultaFiados-id_agente:" + idAgente);
      console.log("getConosultaFiados-nombre:" + nombre);
      const res = await garantiasService.getConsultaFiados(idAgente, nombre);
      if (res.data.valuesSeleccion.length === 0) {
        this.setState({
          listGarantiasVencidas: [],
        });
        return;
      }
      this.setState({
        loadingStatistics: false,
        listGarantiasVencidasFiados: res.data.valuesSeleccion,
      });

      // await console.log(this.state.listGarantiasVencidas);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        console.log("axios error " + err.response.status);
        console.log("axios error " + err.response.data);
        this.setState({ loadingStatistics: false });
      }
    }
    console.log("---------TERMINA getConosultaFiados---------");
  }
  public async buscarReporte(
    fechaInicial: string,
    fechaFinal: string,
    idAgente: number,
    idCliente: number,
    idEjecutivo: number,
    page: number,
    size: number
  ) {
    console.log("INICIA BUSQUEDA");
    try {
      const res = await garantiasService.getConsultaReporte(
        fechaInicial,
        fechaFinal,
        idAgente,
        idCliente,
        idEjecutivo,
        page,
        size
      );
      const response: {
        paginacion: PaginacionModel;
        GarantiasVencidas: ReporteGarantiasReporteModel[];
      } = res.data;

      if (response.GarantiasVencidas === null) {
        this.setState({
          listaReporteGarantias: [],
        });
        return;
      }
      console.log("GarantiasVencidas: " + response.GarantiasVencidas);
      console.log("totalPages: " + response.paginacion.totalPages);
      console.log("totalItems: " + response.paginacion.totalItems);

      await console.log(this.state.listaReporteGarantias);
      this.setState({
        listaReporteGarantias: response.GarantiasVencidas,
        numPaginas: response.paginacion.totalPages,
        numTotalDepositos: response.paginacion.totalItems,
      });
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        console.log("axios error " + err.response.status);
        console.log("axios error " + err.response.data);
        this.setState({ loadingStatistics: false });
      }
    }
    const array: number[] = [];
    for (let i = 1; i <= this.state.numPaginas; i++) {
      array.push(i);
    }
    this.setState({ listPaginacion: array });
  }
  public async buscarReporteArmado(
    cadenaUrl: string,
    page: number,
    size: number
  ) {
    try {
      page > 1
        ? this.setState({ numRegistrosMin: 1 + 10 * (page - 1) })
        : this.setState({ numRegistrosMin: 1 });
      page > 1
        ? this.setState({
            numRegistrosMax:
              10 * page > this.state.numTotalDepositos
                ? this.state.numTotalDepositos
                : 10 * page -
                  1 +
                  (this.state.numTotalDepositos % this.state.numPaginas),
          })
        : this.setState({ numRegistrosMax: 10 });
      const res = await garantiasService.getConsultaReporteArmado(
        cadenaUrl,
        page,
        size
      );
      const response: {
        paginacion: PaginacionModel;
        GarantiasVencidas: ReporteGarantiasReporteModel[];
      } = res.data;

      if (response.GarantiasVencidas === null) {
        this.setState({
          listaReporteGarantias: [],
        });
        return;
      }

      await console.log(this.state.listaReporteGarantias);
      this.setState({
        listaReporteGarantias: response.GarantiasVencidas,

        numPaginas: response.paginacion.totalPages,
        numTotalDepositos: response.paginacion.totalItems,
      });
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        console.log("axios error " + err.response.status);
        console.log("axios error " + err.response.data);
        this.setState({ loadingStatistics: false });
      }
    }
    const array: number[] = [];

    for (let i = 1; i <= this.state.numPaginas; i++) {
      array.push(i);
    }
    this.setState({ listPaginacion: array });
    console.log("ARRAY:--->" + array);
  }
}

const mapStateToProps = (state: RootStore) => ({
  language: state.language,
});
export default connect(mapStateToProps)(ReporteGarantias);

interface ReporteGarantiasState {
  loadingStatistics: boolean;
  listGarantiasVencidas: ReporteGarantiasModel[];
  listGarantiasVencidasFiados: ReporteGarantiasModel[];
  // paginacion: PaginacionModel,
  listPaginacion: number[];
  listaReporteGarantias: ReporteGarantiasReporteModel[];
  busquedaEjecutivo: boolean;
  tipoPerfil: string;
  perfilActivo: string;
  eventoNombre: string;
  valAgente: string;
  valFiado: string;
  fechaFinal: string;
  fechaInicial: string;
  idAgente: number;
  idCliente: number;
  idEjecutivo: number;
  idEjecutivoSesion: number;
  page: number;
  size: number;
  numPaginas: number;
  currentPage: number;
  numTotalDepositos: number;
  numRegistrosMin: number;
  numRegistrosMax: number;
  coleccionPaginado: any;
  validaFechaIni: boolean;
  validaFechaFin: boolean;
  msgFechaI?: string;
  msgFechaF?: string;
  leyendaBuscarAgente: string;
}
