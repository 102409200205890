export interface LanguageModelWarranties {
    warrantiesTitle: string;
    loadingStatics: string;
    openStatics: string;
    searchLabel: string;
    createWarranty: string;
    searchPrincipals: string;
    withSolv: string;
    filterwithSolv: string;
    withOutSolv: string;
    filterwithOutSolv: string;
    toExpire: string;
    errorFiados: string;
    seeDetails: string;
    noResults: string;
    searchFiados: string;
    loadingFiados: string;
    lockPrincipal: string;
    lockedPrincipal: string;
    unLockPrincipal: string
    unlockedPrincipal: string;
    searchButton: string;
}

export const languageWarrantiesEnglish: LanguageModelWarranties ={
    warrantiesTitle: 'Collaterals',
    loadingStatics: 'Loading Statistics',
    openStatics: 'Open',
    searchLabel: 'Write the name or RFC of a Principal',
    createWarranty: 'Create Collateral',
    searchPrincipals: 'Search',
    withSolv: 'With warranty available',
    filterwithSolv: 'With warranty available',
    withOutSolv: 'No guarantees available',
    filterwithOutSolv: 'No guarantees available',
    toExpire: 'To expire',
    errorFiados: 'Sorry something happened while loading Principals, please check your connection and try again.',
    seeDetails: 'See details',
    noResults: 'We\'re sorry, but we couldn\'t find any results.',
    searchFiados: 'Search Principals',
    loadingFiados: 'Searching Principals',
    lockPrincipal: 'Lock Principal',
    lockedPrincipal: 'User blocked',
    unLockPrincipal: 'Unlock user',
    unlockedPrincipal: 'User Unlocked',
    searchButton:  'Search'
}
export const languageWarrantiesSpanish: LanguageModelWarranties ={
    warrantiesTitle: 'Garantias',
    loadingStatics: 'Cargando Estadísticas',
    openStatics: 'Abrir',
    searchLabel: 'Buscar por nombre o RFC del fiado',
    createWarranty: 'Crear Garantía',
    searchPrincipals: 'Buscar',
    withSolv: 'Con garantías disponibles',
    filterwithSolv: 'Con garantías disponibles',
    withOutSolv: 'Sin garantías disponibles',
    filterwithOutSolv: 'Sin garantías disponibles',
    toExpire: 'Por vencer',
    errorFiados: 'Lo sentimos algo, ha ocurrido al cargar los Fiados, revise su conexión e intente de nuevo.',
    seeDetails: 'Ver Detalles',
    noResults: 'Lo sentimos, pero no hemos podido encontrar resultados.',
    searchFiados: 'Buscar Fiados',
    loadingFiados: 'Buscando Fiados',
    lockPrincipal: 'Bloquear Fiado',
    lockedPrincipal: 'Usuario bloqueado',
    unLockPrincipal: 'Desbloquear Usuario',
    unlockedPrincipal: 'Usuario Desbloqueado',
    searchButton: 'Buscar'
}