import React, { useEffect, useState} from 'react';
import './LockCommissionPrincipal.scss';
import {
    Button, Modal,
} from "@lmig/lmds-react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";

import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {InputChange} from "../../../class/CommonClass";
import {BloqueoCommission} from "../../../models/garantias/BloqueoCommission";
import {dispatchPutLockCommissionPrincipal, dispatchPutLockPrincipal} from "../../../actions/FiadoActions";
import {TextAlert} from "../../public/text-alert/TextAlert";

interface LockCommissionPrincipalProps {
    idPrincipal: number;
    showModal: boolean;
    namePrincipal?: string;
    clickShowModal: (show: boolean, idPrincipal?: number) => void;

}

export const LockCommissionPrincipal: React.FC<LockCommissionPrincipalProps> = ({idPrincipal, showModal, namePrincipal, clickShowModal}: LockCommissionPrincipalProps)=> {
    // redux
    const dispatch = useDispatch();

    const languageState = useSelector((state: RootStore) => state.language.language?.lockCommissionPrincipal);

    const [sendingLock, setSendingLock] = useState<boolean>(false);


    const [descReason, setDescReason] = useState<string>('');

    const [errorLockPrincipal, setErrorLockPrincipal] = useState<boolean>(true);

    const [msgValidLock, setMsgValidLock] = useState<string>('');

    const putLockCommissionPrincipal = async(idPrincipal: number, descripcion: string) => {
        setSendingLock(true);
        setErrorLockPrincipal(false);
        const bloqueoCommission:  BloqueoCommission = {
            descripcion: descripcion
        }
        try {
            const res = await garantiasService.putBloqueaComisionCliente(idPrincipal, bloqueoCommission);
            setSendingLock(false);
            clickShowModal!(false, idPrincipal);
            dispatch(dispatchPutLockCommissionPrincipal(idPrincipal));

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setSendingLock(false);
                setErrorLockPrincipal(true);

            }
        }
    }


    const handleInputChange = (e: InputChange) =>{
        setDescReason( e.target.value );
    }

    const onClickLock = () => {
        if (descReason != ''){
            putLockCommissionPrincipal(idPrincipal, descReason);
        }else if (descReason == ''){
            setMsgValidLock(languageState?.msgValidReason!)
        }

    }

    useEffect(() => {

    },[]);


    return (
        <>

            <Modal
                isOpen={showModal}
                size="medium"
                modalStyle="standard"
                title=""
                closeFunc={() => {
                    clickShowModal(false);
                }}
                id="modal-lock-commission-principal"
            >
                <div className="col-12 list-reject">
                    <h4>
                        {namePrincipal? languageState?.titleModalto + ' ' + namePrincipal: languageState?.titleModal}
                    </h4><br/>


                    <div className="add-description">{languageState?.addDescription}:<br/></div>
                    <textarea
                        className="form-control"
                        onChange={handleInputChange}
                        value={descReason}
                        rows={3}>
                                </textarea>


                </div>

                <div className="row">
                    <div className="col-12">
                        <TextAlert
                            textAlert={msgValidLock}/><br/>
                    </div>
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="secondary"
                            onClick={()=>{
                                clickShowModal(false);
                            }}>
                            {languageState?.close!}
                        </Button>
                    </div>
                  <div className="col-md-6 col-12">
                        {!sendingLock ? (
                                <Button
                                    dynamicWidth
                                    variant="primary"
                                    onClick={()=>{
                                        onClickLock();
                                    }}>
                                    {languageState?.save!}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState?.saving!}/>
                        }

                    </div>

                </div>



            </Modal>
        </>
    );
};