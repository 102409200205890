import React from "react";
import logo from '../../../assets/images/public/logo-web-liberty16.png';
import './AccessDenied.scss';
import NavBarPublic from "../../../components/public/nav-bar-public/NavBarPublic";

export const AccessDenied: React.FC = () => {


    return (
        <>
            <NavBarPublic/>
            <div className="container-fluid" id="access-denied">
                <div className="col-md-12 offset-md-2 pt-4 text-md-left">
                    <img src={logo} alt="Logo Liberty" className="img-fluid"/>

                    <h1>Si Autorización</h1>
                    <h3>Error no tiene privilegios para acceder a esta parte del sistema.</h3>


                </div>


            </div>
        </>

    );
};

