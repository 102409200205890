import { ChangeEvent, Component } from "react";
import './AuthorizeEngagements.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import InfoEngagement from "../../../components/compromisos/info-engagement/InfoEngagement";
import { AlphanumericInput, Button, Form, List, ListItem, Modal, } from "@lmig/lmds-react";
import { RouteComponentProps } from "react-router-dom";
import HistoryEngagements from "../history-engagements/HistoryEngagements";
import HistoryEngagementsF from "../history-engagements/HistoryEngagementsF";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { PipeLineStepsDetails } from "../../public/pipeline-steps/PipeLineStepsDetails";
import { AxiosError } from 'axios';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { Compromiso } from '../../../models/compromisos/Compromiso';
import { formatCurrency, formatValue, initPaginacion, validRoles } from "../../../class/CommonClass";
import { AutorizaCompromiso } from "../../../models/compromisos/AutorizaCompromiso";
import { CompromisoOption } from "../../../models/compromisos/CompromisoOption";
import { CodigoRespuesta } from "../../../models/compromisos/CodigoRespuesta";
import { Cliente } from "../../../models/compromisos/Cliente";
import SuccessAlert from "@lmig/lmds-react/icons/SuccessAlert";
import FindFuncionario from "../find-client/FindFuncionario";
import { MessagesAPIComponent } from "@lmig/lf-sgf-library-messages/src";
import environment from "../../../environments/environment";
import { Correo } from "../../../models/compromisos/Correo";
import '@lmig/lf-sgf-library-messages/src/index.css'
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { NegativeAlert } from "../../public/negative-alert/NegativeAlert";
import Info from "@lmig/lmds-react/icons/Info";
import { BreadCumbEngagements } from "../../public/breadcumb/BreadCumbEngagements";
import { ElementosCompromiso } from "../../../models/compromisos/ElementosCompromiso";
import { UserModel } from "../../../models/public/UserModel";
import { Destinatario } from "../../../models/compromisos/Destinatario";
import FindComite from "../find-client/FindComite";
import FindSubComite from "../find-client/FindSubComite";
import FindComiteBuro from "../find-client/FindComiteBuro";
import { NumeroCompromisos } from "../../../models/compromisos/NumeroCompromisos";
import { Bloqueos } from "../../../models/compromisos/Bloqueos";

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface IListProps {

    idCompromiso: number;
    idFiado: number;
    language: DefaultStateI
}

interface Params {
    idCompromiso: string;
    idFiado: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

interface BlockOption {
    value: number;
    label: string;
}


interface ConditionOption {
    value: number;
    label: string;
}


class AuthorizeEngagements extends Component<IListProps & RouteComponentProps<Params, SC, LocationState>, AuthorizeState> {

    public idFiado = 0;
    public idCompromiso = 0;
    public correoDestinario = '';
    public nombreDestinario = '';
    public today: string;
    public last: string;
    public localStorage = new LocalStorageClass();
    public localStorageClass = new LocalStorageClass();
    public initialEngagementStateAcepta: AutorizaCompromiso = {
        idCompromiso: 0,
        idAccion: 0,
        fechaComprometida: '',
        motivo: '',
        dictamen: '',
        nombreRep: '',
        usuario: '',
    }

    public initialMsg: Messages = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }

    public initialEngagementState: Compromiso = {
        bloqueos: [],
        conFiado: 0,
        condiciones: [],
        datosFiado: {
            correo: '',
            fechaAceptaCompr: '',
            idCompromiso: 0,
            nombreRep: ''
        },
        dictamen: '',
        fechaComprometida: '',
        fechaCompromisoCumplido: '',
        fechaSolicitud: '',
        idCompromiso: 0,
        idFiado: 0,
        idSolicitante: '',
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoBloqueo: 0,
        tipoCompromiso: 0,
        tipoCumplimiento: 0,
        tipoSolicitante: 0
    }

    public initialCodigoRespuesta: CodigoRespuesta = {
        codigo: 0,
        descripcion: ''
    }

    constructor(props: any) {
        super(props);
        this.idCompromiso = parseInt(this.props.match.params.idCompromiso);
        this.idFiado = parseInt(this.props.match.params.idFiado);
        this.today = '';
        this.last = '';
        document.title = "Autorización Compromiso del Fiado "
        //document.title = `${this.props.language.language?.authorizeEngagements.nombreDocumento}`

        const PERFIL = this.localStorageClass.getProfile();

        this.state = {
            numCompromisos: 0,
            listNumeroC: {
                compromisosApoyo: 0,
                compromisosBloqueoB: 0,
                compromisosBloqueoG: 0,
                numeroGarantia: 0,
                descripcionGarantia: ""
            },
            respuestaNotificacion: this.initialCodigoRespuesta,
            autorizaRO: false,
            autorizaComite: false,
            autorizaComiteBuro: false,
            autorizaSubComite: false,
            numeroCompromisosApoyo: 0,
            numeroCompromisosBloqueB: 0,
            numeroCompromisosBloqueoG: 0,
            numeroGarantia: 0,
            descripcionGarantia: "",
            count: 0,
            PERFIL: PERFIL,
            compromiso: this.initialEngagementState,
            mensajesAlerta: this.initialMsg,
            engagementObject: this.initialEngagementState,
            authorizeEngagementObject: this.initialEngagementStateAcepta,
            compromisoDetails: [{
                fiado: '',
                rfc: '',
                idCompromiso: 0,
                fechaSolicitud: '',
                bloqueos: [{
                    descripcion: '',
                    idBloqueo: 0,
                    estatus: 0
                }],
                condiciones: [{
                    idCondicion: 0,
                    descripcion: '',
                    estatus: 0
                }],
                fechaComprometida: '',
                idFiado: 0,
                monto: 0,
                motivo: '',
                numRenovaciones: 0,
                producto: '',
                statusCompromiso: 0,
                tipoCompromiso: 0,
                idFuncionario: ''

            }],

            BlockOption: [{
                value: 0,
                label: '',
            }],
            ConditionOption: [{
                value: 0,
                label: '',
            }],
            conFacultades: {
                codigo: 20,
                descripcion: ''
            },
            cliente: {
                idCliente: 0,
                razonSocial: '',
                rfc: '',
                agente: '',
                idAgente: 0
            },

            destinatario: {
                nombre: '',
                email: ''

            }

        }

        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        let today = "";
        let last = "";
        const monthA = month + 11;
        const yearA = year;

        if (month < 10) {

            const monthAux = "0" + month
            today = year + "-" + monthAux + "-" + date
            this.today = today

        } else {

            today = year + "-" + month + "-" + date
            this.today = today
        }

        if (monthA < 10) {

            const montAA = "0" + monthA
            last = yearA + "-" + montAA + "-" + date
            this.last = last

        } else {
            last = yearA + "-" + monthA + "-" + date
            this.last = last

        }

        console.log("Fecha minima: " + this.today)
        console.log("Fecha máxima: " + this.last)

        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)

    }

    componentDidMount() {
        console.log("CLAVE " + this.state.PERFIL.clave)
        this.getFacultades();
        this.getClientAut();
        this.getClientInfo();
        //this.getDestinatrio();
        //this.getNumeroDeCompromisos();

    }


    render() {
        return <>
         <NavBar />
            <div className="container-fluid " id="authorize-engagements">
               

                <div className="col-md-8">
                    <BreadCumbEngagements
                        title={this.props.language.language?.createEngagement.nombreDocumentoAutorizacion!}
                        listBreadcumbs={[
                            { title: 'Inicio', url: '/compromisos/autorizaciones' },
                            { title: this.props.language.language?.createEngagement.nombreDocumentoAutorizacion!, url: this.props.location.pathname },
                        ]}
                    />
                </div>

                <div className="create-engagement">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-8 seccion-info ">
                                    <InfoEngagement id={this.idFiado} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <PipeLineStepsDetails
                                listSteps={[`${this.props.language.language?.createEngagement.compromiso} `, `${this.props.language.language?.createEngagement.revision} `,
                                `${this.props.language.language?.createEngagement.dictamen} `]}
                                currentStep={this.state.count!} />
                        </div>

                        <div className="col-md-12">
                            <Form id="form-liberty">
                                {this.state.count == 0 ? (
                                    <div className="row">
                                        {this.state.compromisoDetails.map((i, index) =>
                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.tipoLabel}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={i.descTipoCompromiso}
                                                    disabled
                                                />
                                            </div>
                                        )}

                                        {(this.state.compromiso.tipoCompromiso == 1) ?

                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.condicionesLabel}</label>
                                                <List compact className="condition-input">
                                                    {this.state.ConditionOption.map((condition, index) =>
                                                        <ListItem>{condition.label}</ListItem>
                                                    )}
                                                </List>
                                            </div> : null}

                                        {(this.state.compromiso.tipoCompromiso == 2) ?

                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.bloqueosLabel}</label>
                                                <List compact className="condition-input">
                                                    {this.state.BlockOption.map((bloq, index) =>
                                                        <ListItem>{bloq.label}</ListItem>
                                                    )}
                                                </List>
                                            </div> : null}

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.producto}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={`${this.state.compromiso.producto}`} disabled
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.monto}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={formatCurrency(this.state.compromiso.monto)} disabled
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.nombreAgente}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={this.state.cliente.agente} disabled
                                            />
                                        </div>


                                        <div className="col-md-6">
                                            <label className="label-input">{this.props.language.language?.createEngagement.fecha}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={`${this.state.compromiso.fechaComprometida}`} disabled />
                                        </div>

                                        <div className="col-md-12">

                                            <label className="label-input">{this.props.language.language?.createEngagement.motivo}</label>
                                            <textarea value={`${this.state.compromiso.motivo}`} disabled className={"input-sdgf"} rows={4} cols={55} />

                                        </div>

                                        <div className="col-md-12">

                                            <div className="row buttons-navigation">
                                                <div className="col-md-4">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        //onClick={() => this.back()}
                                                        href="/compromisos">
                                                        {this.props.language.language?.createEngagement.regresar}
                                                    </Button>
                                                </div>
                                                <div className="col-md-4">
                                                    <br />

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) ? (
                                                        <HistoryEngagementsF idFiado={this.idFiado} />
                                                    ) : <HistoryEngagements idCompromiso={this.idCompromiso} />}

                                                </div>
                                                <div className="col-md-4">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="primary"
                                                        onClick={() => this.next()}>
                                                        {this.props.language.language?.createEngagement.continuar}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </Form>


                            {this.state.count == 1 ? (
                                <>


                                    <Form id="form-mensajes">
                                        <div className="row mensajes">

                                            <MessagesAPIComponent
                                                idAPI={2}
                                                disableCreate={false}
                                                idEspecifico={this.state.authorizeEngagementObject.idCompromiso!}
                                                emailRemitente={this.state.PERFIL.email}
                                                nombreRemitente={this.state.PERFIL.username}
                                                emailDest={this.state.destinatario.email}
                                                nombreDest={this.state.destinatario.nombre}
                                                TOKEN={this.state.PERFIL.accessToken}
                                                APIURL={environment.APIMENSAJES}
                                                APIKEY={environment.APIKEYMENSAJES}

                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row buttons-navigation">
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        onClick={() => this.back()}>
                                                        {this.props.language.language?.createEngagement.regresar}
                                                    </Button>
                                                    <br />
                                                </div>

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) || validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            //onClick={() => this.next()}
                                                            href="/compromisos">
                                                            {this.props.language.language?.createEngagement.finalizar}
                                                        </Button>
                                                        <br />
                                                    </div>) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 0
                                                    && this.state.autorizaRO == false
                                                    && this.state.autorizaComite == false
                                                    && this.state.autorizaSubComite == false
                                                    && this.state.autorizaComiteBuro == false ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <FindFuncionario idCompromiso={this.state.authorizeEngagementObject.idCompromiso} />
                                                        <br />
                                                    </div>

                                                ) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                    && this.state.autorizaRO == false
                                                    && this.state.autorizaComite == false
                                                    && this.state.autorizaSubComite == false
                                                    && this.state.autorizaComiteBuro == false ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => this.validaFecha()} >
                                                            {this.props.language.language?.createEngagement.continuar}
                                                        </Button>
                                                        <br />
                                                    </div>
                                                ) : null}


{/*                                                 {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 0
                                                    && this.state.autorizaRO == false ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            href='/compromisos'
                                                            onClick={() => this.postNotificaRO()}>
                                                            {this.props.language.language?.createEngagement.finalizar}
                                                        </Button>

                                                    </div>
                                                ) : null} */}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)
                                                    && this.state.autorizaRO == true ? (
                                                        <div className="col-md-6">
                                                        <br />
                                                        <Button variant="primary" dynamicWidth
                                                            onClick={() => 
                                                              
                                                                this.postNotificaRO()
                                                            }>
                                                            {this.props.language.language?.createEngagement.finalizar}
                                                        </Button>
                                                    </div>
                                                ) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)
                                                    && this.state.autorizaComite == true ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <FindComite idCompromiso={this.idCompromiso} idFiado={this.idFiado}></FindComite>
                                                    </div>
                                                ) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)
                                                    && this.state.autorizaSubComite == true ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <FindSubComite idCompromiso={this.idCompromiso} idFiado={this.idFiado} ></FindSubComite>
                                                    </div>
                                                ) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)
                                                    && this.state.autorizaComiteBuro == true ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <FindComiteBuro idCompromiso={this.idCompromiso} idFiado={this.idFiado} ></FindComiteBuro>
                                                    </div>
                                                ) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) || validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => this.next()}>
                                                            {this.props.language.language?.createEngagement.continuar}
                                                        </Button>
                                                        <br />
                                                    </div>
                                                ) : null}

                                            </div>

                                            <Modal
                                                    isOpen={this.state.openModalResponsableOficina}
                                                    size="medium"
                                                    modalStyle="standard"
                                                    id="modal5"
                                                    closeFunc={() => {
                                                        this.setState({ openModalResponsableOficina: false })
                                                    }}>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="col-md-4 info">
                                                                <Info
                                                                    size="64"
                                                                    color="informative">
                                                                </Info>
                                                            </div>

                                                            <h2 className="texto"> {this.props.language.language?.createEngagement.notificacionResponsableOficina} {this.state.numCompromisos} <br />
                                                                {this.props.language.language?.createEngagement.notificacionResponsableOficina2}</h2>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <br />
                                                                    <Button onClick={() => {
                                                                        this.setState({ openModalResponsableOficina: false })
                                                                    }}
                                                                        variant="secondary"
                                                                        className="btn-cancel">
                                                                        {this.props.language.language?.createEngagement.cancelar}
                                                                    </Button>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <br />
                                                                    <Button
                                                                        //onClick={() => this.postNotificaRO()}
                                                                        href="/compromisos"
                                                                        variant="primary">
                                                                        {this.props.language.language?.createEngagement.aceptar}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                        </div>
                                    </Form></>
                            ) : null}


                            {this.state.count == 2 ? (
                                <><Form id="form-dictamen">
                                    <div className="col-md-24">

                                        {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1 ?
                                            <>  <label className="label-input"></label>
                                                <textarea
                                                    name="dictamen"
                                                    placeholder={`${this.props.language.language?.createEngagement.redactaDictamen} `}
                                                    onChange={this.handleInputChangeSelect}
                                                    className={"input-sdgf"} rows={5} cols={5} />
                                            </> : null}

                                        {validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) || validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ?
                                            <>  <label className="label-input"></label>
                                                <textarea
                                                    name="dictamen"
                                                    placeholder={`${this.props.language.language?.createEngagement.redactaDictamen} `}
                                                    onChange={this.handleInputChangeSelect}
                                                    className={"input-sdgf"} rows={5} cols={5} />
                                            </> : null}

                                        <div className="col-md-12">
                                            <div className="row buttons-navigation">

                                                <div className="col-md-4">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        onClick={() => this.back()}>
                                                        {this.props.language.language?.createEngagement.regresar}
                                                    </Button>
                                                </div>

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                    || validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) || validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ? (
                                                    <>
                                                        <div className="col-md-4">
                                                            {this.state.authorizeEngagementObject.dictamen == null || this.state.authorizeEngagementObject.dictamen == '0'
                                                                || this.state.authorizeEngagementObject.dictamen == "" ? (
                                                                <><br /><Button variant="primary" dynamicWidth
                                                                    onClick={() => {
                                                                        this.setState({ openModalRechazo: true })
                                                                    }}
                                                                    disabled>
                                                                    {this.props.language.language?.createEngagement.rechazar}
                                                                </Button></>
                                                            ) :
                                                                <><br /><Button variant="primary" dynamicWidth
                                                                    onClick={() => {
                                                                        this.setState({ openModalRechazo: true })
                                                                    }}>
                                                                    {this.props.language.language?.createEngagement.rechazar}
                                                                </Button></>}
                                                        </div>
                                                    </>
                                                ) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                    || validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) || validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ? (
                                                    <>
                                                        <div className="col-md-4">
                                                            <br />
                                                            <Button variant="primary" dynamicWidth
                                                                onClick={() => {
                                                                    this.setState({ openModalAutorizacion: true })
                                                                }}>
                                                                {this.props.language.language?.createEngagement.autorizar}
                                                            </Button>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>

                                        <Modal
                                            isOpen={this.state.openModalClose}
                                            size="medium"
                                            modalStyle="standard"
                                            id="modal2"
                                           // className="modalExito"
                                            closeFunc={() => {
                                                this.setState({ openModalClose: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 alert">
                                                        <SuccessAlert
                                                            size="64"
                                                            color="positive">
                                                        </SuccessAlert>
                                                    </div>

                                                    <h2 className="texto">  {this.props.language.language?.createEngagement.operacionExitosa} </h2>

                                                    <br />
                                                    <Button href="/compromisos"
                                                        variant="primary"
                                                        className="btn-ok">
                                                        {this.props.language.language?.createEngagement.aceptar}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal>

                                        <Modal
                                            isOpen={this.state.openModalAutorizacion}
                                            size="medium"
                                            modalStyle="standard"
                                            id="modal3"
                                            closeFunc={() => {
                                                this.setState({ openModalAutorizacion: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 info">
                                                        <Info
                                                            size="64"
                                                            color="informative">
                                                        </Info>
                                                    </div>

                                                    <h2 className="texto"> ¿Estas seguro que deseas autorizar el compromiso? </h2>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Button onClick={() => {
                                                                this.setState({ openModalAutorizacion: false })
                                                            }}
                                                                //href="/compromisos"
                                                                variant="secondary"
                                                                className="btn-cancel">
                                                                Cancelar
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Button
                                                                onClick={() => this.postEngagementAut()}
                                                                //href="/compromisos"
                                                                variant="primary"
                                                            >
                                                                Aceptar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>

                                        <Modal
                                            isOpen={this.state.openModalRechazo}
                                            size="medium"
                                            modalStyle="standard"
                                            id="modal4"
                                            closeFunc={() => {
                                                this.setState({ openModalRechazo: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 info">
                                                        <Info
                                                            size="64"
                                                            color="informative">
                                                        </Info>
                                                    </div>

                                                    <h2 className="texto"> ¿Estas seguro que deseas rechazar el compromiso? </h2>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button onClick={() => {
                                                                this.setState({ openModalRechazo: false })
                                                            }}
                                                                //href="/compromisos"
                                                                variant="secondary"
                                                                className="btn-cancel">
                                                                Cancelar
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button
                                                                onClick={() => this.postEngagementRec()}
                                                                //href="/compromisos"
                                                                variant="primary"
                                                            >
                                                                Aceptar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>


                                        <Modal
                                            isOpen={this.state.openModalFechaVencida}
                                            size="medium"
                                            modalStyle="standard"
                                            id="modal5"
                                            closeFunc={() => {
                                                this.setState({ openModalFechaVencida: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 info">
                                                        <Info
                                                            size="64"
                                                            color="informative">
                                                        </Info>
                                                    </div>

                                                    <h2 className="texto"> Fecha Vencida</h2>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button onClick={() => {
                                                                this.setState({ openModalFechaVencida: false })
                                                            }}
                                                                //href="/compromisos"
                                                                variant="secondary"
                                                                className="btn-cancel">
                                                                Cancelar
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button
                                                                onClick={() => this.postEngagementRec()}
                                                                href="/compromisos"
                                                                variant="primary">
                                                                Aceptar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </Form></>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    next(): void {
        this.setState({ count: this.state.count! + 1 })

    }

    back(): void {
        this.setState({ count: this.state.count! - 1 })
    }

    validaFecha(): void {

        console.log("FECHA COMPROMETIDA " + this.state.compromiso.fechaComprometida)

        const day = this.state.compromiso.fechaComprometida?.substring(8, 10)
        const month = this.state.compromiso.fechaComprometida.substring(3, 5)
        const year = this.state.compromiso.fechaComprometida?.substring(5, 9)

        const mesActual = this.today?.substring(5, 7)

        if (month < mesActual) {

            console.log("MES COMPROMETIDO " + month)
            console.log("MES ACTUAL: " + mesActual)
            this.setState({ openModalFechaVencida: true })
            console.log("OPEN: " + this.state.openModalFechaVencida?.valueOf)
        } else {
            this.setState({ count: this.state.count! + 1 })
        }
    }

    async postNotificaRO(): Promise<void> {

        try {
            const res = await engagementService.postNotificaResponsableOficina(this.idCompromiso, this.idFiado);

            this.setState({ respuestaNotificacion: res.data })
            console.log(this.state.respuestaNotificacion)

            this.setState({ openModalResponsableOficina: true });
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }

       
    }


    async postEngagementRec(): Promise<void> {

        this.state.authorizeEngagementObject.idAccion = 4
        this.state.authorizeEngagementObject.usuario = this.state.PERFIL.username

        try {
            const res = await engagementService.postAutorizacion(this.state.authorizeEngagementObject);
            //console.log(res.data)
            //const response: Compromiso = res.data;

            // actualizar el objeto compromiso con el nuevo creado
            this.setState({ authorizeEngagementObject: res.data })
            console.log('Rechazo de Compromiso Exitoso')
            console.log(this.state.authorizeEngagementObject)

            this.setState({ openModalClose: true })
            this.setState({ openModalRechazo: false })
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }


    async postEngagementAut(): Promise<void> {

        this.state.authorizeEngagementObject.idAccion = 2
        this.state.authorizeEngagementObject.usuario = this.state.PERFIL.username

        try {
            const res = await engagementService.postAutorizacion(this.state.authorizeEngagementObject);

            // actualizar el objeto compromiso con el nuevo creado
            this.setState({ authorizeEngagementObject: res.data })
            console.log('Autorizacion de Compromiso Exitosa')
            console.log(this.state.authorizeEngagementObject)

            this.setState({ openModalClose: true })
            this.setState({ openModalAutorizacion: false })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }

    handleInputChangeSelect(e: InputChange): void {

        const name = e.target.name;
        const value = e.target.value;
        console.log(name + ' : ' + value)
        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, [name]: formatValue(value) }
        }))
    }

    async getNumeroDeCompromisos(): Promise<void> {

        const res = await engagementService.getNumCompromisos(this.idFiado, this.state.authorizeEngagementObject.idCompromiso);
        const numCompromisosAux: NumeroCompromisos = res.data;
        this.setState({ listNumeroC: numCompromisosAux });

        this.setState({ numeroCompromisosApoyo: numCompromisosAux.compromisosApoyo })

        this.setState({ numeroCompromisosBloqueB: numCompromisosAux.compromisosBloqueoB })

        this.setState({ numeroCompromisosBloqueoG: numCompromisosAux.compromisosBloqueoG })

        this.setState({ numeroGarantia: numCompromisosAux.numeroGarantia })

        this.setState({ descripcionGarantia: numCompromisosAux.descripcionGarantia })
        //El tercer y cuarto compromiso por el mismo concepto para desbloqueo por Fiado, los deberá de autorizar el Responsable de Oficina.
        //El quinto y el sexto compromiso de desbloqueo por Fiado por el mismo concepto lo deberá de autorizar el Subcomité de Suscripción
        //A partir del séptimo compromiso de desbloqueo por Fiado por el mismo concepto lo deberá de autorizar el Comité de Suscripción.

        if (this.state.compromiso.tipoCompromiso == 1) {

            console.log("TIPO COMPROMISO " + this.state.compromiso.tipoCompromiso + " POR APOYO")

            if (this.state.numeroCompromisosApoyo == 3 || this.state.numeroCompromisosApoyo == 4) {

                this.setState({ autorizaRO: true })
                this.setState({ autorizaComiteBuro: false })
                this.setState({ numCompromisos: this.state.numeroCompromisosApoyo })
                console.log('Enviado al Responsable de Oficina')

            } else if (this.state.numeroCompromisosApoyo == 5 || this.state.numeroCompromisosApoyo == 6) {

                this.setState({ autorizaSubComite: true })
                this.setState({ autorizaComiteBuro: false })
                console.log('Enviado al Subcomité de Suscripción')

            } else if (this.state.numeroCompromisosApoyo >= 7) {

                this.setState({ autorizaComite: true })
                this.setState({ autorizaComiteBuro: false })
                console.log('Enviado al Comité de Suscripción')
            }

            if (this.state.numeroCompromisosBloqueB >= 1) {

                this.setState({ autorizaComiteBuro: true })
                console.log('Enviado al Comité de Buró')
                this.setState({ autorizaRO: false })
                this.setState({ autorizaSubComite: false })
                this.setState({ autorizaComite: false })

            }
        } else if (this.state.compromiso.tipoCompromiso == 2 && this.state.compromiso.tipoBloqueo == 2) {

            console.log("TIPO COMPROMISO " + this.state.compromiso.tipoCompromiso + " POR BLOQUEO BURO")

            if (this.state.numeroCompromisosBloqueB >= 1) {

                this.setState({ autorizaComiteBuro: true })
                console.log('Enviado al Comité de Buró')
                this.setState({ autorizaRO: false })
                this.setState({ autorizaSubComite: false })
                this.setState({ autorizaComite: false })

            }

        } else if (this.state.compromiso.tipoCompromiso == 2 && this.state.compromiso.tipoBloqueo == 1) {

            console.log("TIPO COMPROMISO " + this.state.compromiso.tipoCompromiso + " -->  POR BLOQUEO GARANTIAS")
            console.log("CANTIDAD POR TIPO " + this.state.numeroGarantia)

            let descripcionAux: string
            descripcionAux = ""

            this.state.BlockOption.map((bloq, index) =>
                descripcionAux = bloq.label

            )
            console.log("TIPO DE GARANTIA COMPROMISO POR AUTORIZAR --> " + descripcionAux)

            if (descripcionAux.trim().includes(this.state.descripcionGarantia)) {

                if (this.state.numeroGarantia == 3 || this.state.numeroGarantia == 4) {

                    this.setState({ autorizaRO: true })
                    this.setState({ autorizaComiteBuro: false })
                    this.setState({ numCompromisos: this.state.numeroCompromisosApoyo })
                    console.log('Enviado al Responsable de Oficina Exitoso por tipo de Garantia: ' + this.state.descripcionGarantia)

                } else if (this.state.numeroGarantia == 5 || this.state.numeroGarantia == 6) {

                    this.setState({ autorizaSubComite: true })
                    this.setState({ autorizaComiteBuro: false })
                    console.log('Enviado al Subcomité de Suscripción por tipo de Garantia: ' + this.state.descripcionGarantia)

                } else if (this.state.numeroGarantia >= 7) {

                    this.setState({ autorizaComite: true })
                    this.setState({ autorizaComiteBuro: false })
                    console.log('Enviado al Comité de Suscripción por tipo de Garantia: ' + this.state.descripcionGarantia)

                }
            }

            if (this.state.numeroCompromisosBloqueoG == 3 || this.state.numeroCompromisosBloqueoG == 4) {

                this.setState({ autorizaRO: true })
                this.setState({ autorizaComiteBuro: false })
                //console.log('Enviado al Responsable de Oficina Exitoso')

            } else if (this.state.numeroCompromisosBloqueoG == 5 || this.state.numeroCompromisosBloqueoG == 6) {

                this.setState({ autorizaSubComite: true })
                this.setState({ autorizaComiteBuro: false })
                //console.log('Enviado al Subcomité de Suscripción')

            } else if (this.state.numeroCompromisosBloqueoG >= 7) {

                this.setState({ autorizaComite: true })
                this.setState({ autorizaComiteBuro: false })
                //console.log('Enviado al Comité de Suscripción')
            }
        }
    }

    async getClientAut(): Promise<void> {

        let externo = 0;

        const res = await engagementService.getClientAut(externo, this.idCompromiso);
        this.setState({ compromiso: res.data });
        this.setState({ engagementObject: res.data })


        const date = this.state.compromiso.fechaComprometida?.toString()
        const day = date?.substring(8, 10)
        const month = date?.substring(5, 7)
        const year = date?.substring(0, 4)

        const completeDate = day + '/' + month + '/' + year
        //console.log("Fecha: " + completeDate)


        this.state.authorizeEngagementObject.motivo = this.state.compromiso.motivo
        this.state.authorizeEngagementObject.fechaComprometida = completeDate
        this.state.authorizeEngagementObject.nombreRep = this.state.compromiso.datosFiado.nombreRep
        this.state.authorizeEngagementObject.idCompromiso = this.state.compromiso.idCompromiso

        this.getNumeroDeCompromisos()

        console.log("Sandy obtiene el Compromiso en la Autorizacion con el ID: " + this.state.compromiso.idCompromiso);

        let listaAuxiliarB: BlockOption[];
        listaAuxiliarB = []
        let listaAuxiliarC: ConditionOption[];
        listaAuxiliarC = []

        let tipo: string;
        tipo = ''

        for (const atributo of this.state.compromisoDetails) {

            if (this.state.compromiso.tipoCompromiso == tiposCompromiso.PORAPOYO) {
                tipo = 'Apoyo'
            } else if (this.state.compromiso.tipoCompromiso == tiposCompromiso.BLOQUEO) {
                tipo = 'Bloqueo'
            }
            atributo.descTipoCompromiso = tipo
        }

        for (const valor of this.state.compromiso.bloqueos) {
            listaAuxiliarB.push({ value: valor.idBloqueo, label: valor.descripcion })
        }

        for (const valor of this.state.compromiso.condiciones) {
            listaAuxiliarC.push({ value: valor.idCondicion, label: valor.descripcion })
        }

        this.setState({ ConditionOption: listaAuxiliarC })
        this.setState({ BlockOption: listaAuxiliarB })
    }


    async getFacultades(): Promise<void> {

        let externo = 0;

        const res1 = await engagementService.getClientAut(externo, this.idCompromiso);
        this.setState({ engagementObject: res1.data })
        console.log("Facultades")

        const producto = parseInt(this.state.engagementObject.producto)
        const monto = this.state.engagementObject.monto
        const ejecutivo = this.state.PERFIL.clave

        const res = await engagementService.getFacultades(ejecutivo!, producto, monto);

        this.setState({ conFacultades: res.data });

    }

    async getClientInfo(): Promise<void> {

        const res = await engagementService.getClient(this.idFiado, 0);
        this.setState({ cliente: res.data });
        console.log("Sandy encuentra Ciente en Autorizacion por ID")
        console.log(this.state.cliente)
        //this.clientData();
    }

  /*   async getDestinatrio(): Promise<void> {

        const res = await engagementService.getDestinatario(this.idCompromiso);
        this.setState({ destinatario: res.data });

        console.log("Se obtiene el destinatario")
        console.log(res.data)
    } */
}


const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(AuthorizeEngagements);



interface AuthorizeState {
    autorizaRO: boolean;
    autorizaComite: boolean;
    autorizaComiteBuro: boolean;
    autorizaSubComite: boolean;
    numeroCompromisosApoyo: number;
    numeroCompromisosBloqueB: number;
    numeroCompromisosBloqueoG: number;
    count?: number;
    PERFIL: UserModel;
    compromiso: Compromiso;
    engagementObject: Compromiso;
    authorizeEngagementObject: AutorizaCompromiso;
    openModalClose?: boolean;
    openModalResponsableOficina?: boolean;
    openModalAutorizacion?: boolean;
    openModalRechazo?: boolean;
    openModalFechaVencida?: boolean;
    compromisoDetails: ElementosCompromiso[];
    ConditionOption: ConditionOption[];
    BlockOption: BlockOption[];
    conFacultades: CodigoRespuesta;
    cliente: Cliente;
    mensajesAlerta: Messages;
    destinatario: Destinatario;
    respuestaNotificacion: CodigoRespuesta;
    listNumeroC: NumeroCompromisos;
    numeroGarantia: number;
    descripcionGarantia: string;
    numCompromisos: number;
}

interface Messages {
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgCorreo: string;
    msgFecha: string;
    msgMotivo: string;
}

export enum tiposCompromiso {

    PORAPOYO = 1,
    BLOQUEO = 2,

}
