import React, { useEffect, useState} from 'react';
import './InfoPrincipalLock.scss';
import {
    Badge,
    Button, Modal,
} from "@lmig/lmds-react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import Select from "react-select";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {SingleValue} from "react-select/dist/declarations/src/types";
import {BloqueoCliente} from "../../../models/garantias/BloqueoCliente";
import {InputChange, validRoles} from "../../../class/CommonClass";
import {MotivoBloqueo} from "../../../models/garantias/MotivoBloqueo";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UserModel} from "../../../models/public/UserModel";
import {dispatchPutLockPrincipal, dispatchPutUnLockPrincipal} from "../../../actions/FiadoActions";
import {TextAlert} from "../../public/text-alert/TextAlert";

interface InfoPrincipalLockProps {
    idPrincipal: number;
    showModal: boolean;
    namePrincipal?: string
    clickShowModal: (show: boolean, idPrincipal?: number) => void;
}

export const InfoPrincipalLock: React.FC<InfoPrincipalLockProps> = ({idPrincipal, showModal, namePrincipal, clickShowModal}:InfoPrincipalLockProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.unlockPrincipal);

    const dispatch = useDispatch();

    const localStorageClass = new LocalStorageClass();

    const initMotivoBloqueo: MotivoBloqueo = {
        motivo: {
            idMotivo: 0,
            descripcion: ''
        },
        descripcion: '',
        otrosMotivosBloqueo: ''
    }

    const [listReasons, setListReasons] = useState<MotivoRechazoModel []>([]);

    const [loadingReasons, setLoadingReasons] = useState<boolean>(true);

    const [errorReaseons, setErrorReaseons] = useState<boolean>(true);

    const [listConditionalOption, setListConditionalOption] = useState<ConditionOptionModel[]>([]);

    const [motivoBloqueo, setMotivoBloqueo] = useState<MotivoBloqueo>(initMotivoBloqueo);

    const [loadingMotivoBloqueo, setLoadingMotivoBloqueo] = useState<boolean>(true);

    const [errorMotivoBloqueo, setErrorMotivoBloqueo] = useState<boolean>(true);


    const openModal = async () => {
        setLoadingReasons(true);

        try {
            const res = await garantiasService.getMotivosDesBloqueo();
            setListReasons(res.data);
            formatList(res.data);
            setLoadingReasons(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorReaseons(true);
                setLoadingReasons(false);

            }
        }
    }

    const formatList = (rejectList: MotivoRechazoModel[]) => {
        let  listaCondicionalAuxiliar: ConditionOptionModel[] = [];
        for(const i of rejectList){
            listaCondicionalAuxiliar.push({
                name: '',
                value: i.idMotivo,
                label: i.descripcion,
                status: 0})
        }
        setListConditionalOption(listaCondicionalAuxiliar);
    }

    const getMotivosBloqueoFiado = async (idFiado: number) => {
        setLoadingMotivoBloqueo(true);
        setErrorMotivoBloqueo(false);
        try {
            const res = await garantiasService.getBloqueoCliente(idFiado);
            setMotivoBloqueo(res.data)
            setLoadingMotivoBloqueo(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorMotivoBloqueo(true);
                setLoadingMotivoBloqueo(false);

            }
        }
    }


    useEffect(() => {
        if (showModal){
            openModal();
            getMotivosBloqueoFiado(idPrincipal);

        }

    },[showModal]);


    return (
        <>

            <Modal
                isOpen={showModal}
                size="medium"
                modalStyle="standard"
                title=""
                closeFunc={() => {
                    clickShowModal(false);
                }}
                id="modal-info-prinicipal-lock"
            >

                <>
                    <h4>
                        {languageState?.lockPrincipal}<br/>
                        {namePrincipal}
                    </h4><br/>
                    <div className="row">
                        {(loadingMotivoBloqueo)?<>
                            <div className="col-12 text-center">
                                {languageState?.loadingLocked!}
                                <SpinnerLoading />
                            </div>
                        </>:(!errorMotivoBloqueo)?(


                            (motivoBloqueo?.otrosMotivosBloqueo)?
                                <div className="col-12">
                                    <b>
                                        {languageState?.otherReasonsLocked!}:
                                    </b><br/>
                                    <Badge highlightType="negative">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: motivoBloqueo?.otrosMotivosBloqueo }}
                                        />

                                    </Badge>
                                    <br/>

                                </div>:
                                <div className="col-12">
                                    <b>
                                        {languageState?.textLocked!}:
                                    </b><br/>
                                    <Badge highlightType="negative">
                                        {(motivoBloqueo?.motivo!= null)?motivoBloqueo?.motivo!.descripcion: null}
                                    </Badge>
                                    <b>
                                        {languageState?.descLocked!}:
                                    </b><br/>
                                    {motivoBloqueo?.descripcion} <br/><br/>

                                </div>

                        ): <div className="col-12">
                            <NegativeAlert
                                textAlert={languageState?.imSorryLocked!}/>
                        </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <Button
                                dynamicWidth
                                variant="secondary"
                                onClick={()=>{
                                    clickShowModal(false);
                                }}>
                                {languageState?.close!}
                            </Button>
                        </div>


                    </div>
                </>
            </Modal>
        </>
    );
};