import React, {Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import "./Engagements.scss";
import NavBar from "../../../components/public/nav-bar/NavBar";
import "react-circular-progressbar/dist/styles.css";
import {RootStore} from "../../../Store";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {connect} from "react-redux";
import {validRoles} from "../../../class/CommonClass";
import {UserModel} from "../../../models/public/UserModel";
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import {
  SelectUserEngagements,
  TypeProfileCompromisos
} from "../../../components/compromisos/select-user-engagements/SelectUserEngagements";
import {TypeProfileEngagmentsEnum} from "../../../models/compromisos/TypeProfileEngagmentsEnum";
import * as engagementService from "../../../services/compromisos/EngagementsService";
import {Estadisticas} from "../../../models/compromisos/Estadisticas";
import {Card, CardDeck} from "react-bootstrap";
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {Link as LinkLiberty} from "@lmig/lmds-react";
import FindClient from "../../../components/compromisos/find-client/FindClient";
import {TiposDeCompromisoEnum} from "../../../models/compromisos/TiposDeCompromisoEnum";
import {TableEngagements} from "../../../components/compromisos/table-engagements/TableEngagements";
import {UserEngagementsSelected} from "../../../models/compromisos/UserEngagementsSelected";

interface EngagementsProps {
  language: DefaultStateI;
}

class Engagements extends Component<EngagementsProps & RouteComponentProps, EngagementsState> {

  public localStorageClass = new LocalStorageClass();

  public initEstadisticaState: Estadisticas = {
    apoyo: {
      descripcion: "",
      porcentaje: 0,
    },
    bloqueo: {
      descripcion: "",
      porcentaje: 0,
    },
    vencidas: {
      descripcion: "",
      porcentaje: 0,
    },
    porVencer: {
      descripcion: "",
      porcentaje: 0,
    },
    porAutorizar: {
      descripcion: "",
      porcentaje: 0,
    },
  };

  constructor(props: any) {
    super(props);
    document.title = "Compromisos";
    this.state = {
      PERFIL:  this.localStorageClass.getProfile(),
      selectUser:{
        idEjecutivoCuetaYGar: null,
        idAgente: null,
        clienteDirecto: false,
      },
      estadisticas: this.initEstadisticaState,
      filtrosTipoCompromisos: TiposDeCompromisoEnum.DEFAULT
    }

  }

  render() {
    return (
      <>
          <NavBar />
        <div className="container-fluid" id="engagements">
      

          <div className="engagements-container">
            <BreadCumb
                title={(validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO)?this.props.language.language?.engagments.tituloFuncionario! :this.props.language.language?.engagments.titulo! )}
            />
            <SelectUserEngagements
                userSelected={user => {
                  this.loadDashboard(user);
                }}

            />

            <CardDeck className="cards-percentage pt-4">

              {/*Apoyo porcentaje */}
              {validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ? (
              <Card>
                <Card.Body>
                  <Card.Title>
                    {this.props.language.language?.engagments.titulo}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-6">
                      <CircularProgressbarWithChildren
                          value={this.state.estadisticas.apoyo.porcentaje}
                          text={`${this.state.estadisticas.apoyo.porcentaje}% / ${this.state.estadisticas.bloqueo.porcentaje}%`}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.5,
                            // Text size
                            textSize: "10px",
                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,
                            // Colors
                            pathColor: `rgba(6, 116, 140, ${15! / 100})`,
                            textColor: "#06748C",
                          })}
                      >
                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

                        <CircularProgressbarWithChildren
                            value={this.state.estadisticas.bloqueo.porcentaje}
                            //text={`${this.percentage2}% `}
                            styles={buildStyles({
                              // Rotation of path and trail, in number of turns (0-1)
                              rotation: 0.5,
                              // Text size
                              textSize: "10px",
                              // How long animation takes to go from one percentage to another, in seconds
                              pathTransitionDuration: 0.5,
                              // Colors
                              pathColor: `rgba(6, 116, 140, ${15! / 100})`,
                              textColor: "gray",
                            })}
                        >
                          {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                        </CircularProgressbarWithChildren>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="col-md-6  block">
                      <h4>{`${this.state.estadisticas.apoyo.porcentaje}%  ${this.props.language.language?.engagments.apoyo} `}</h4>
                      <h3 id="bloqueo-porcentaje">{`${this.state.estadisticas.bloqueo.porcentaje}%   ${this.props.language.language?.engagments.bloqueo} `}</h3>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="text-right bg-white">
                  <div className="row">
                    <div className="col-md-6">
                      {this.state.filtrosTipoCompromisos == TiposDeCompromisoEnum.PORAPOYO ? (
                          <LinkLiberty
                              onClick={()=>{this.onChangeFiltros(TiposDeCompromisoEnum.DEFAULT)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.quitarFiltro}
                          </LinkLiberty>
                      ) : (
                          <LinkLiberty
                              onClick={()=> {this.onChangeFiltros(TiposDeCompromisoEnum.PORAPOYO)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.opcionFiltrar1}
                          </LinkLiberty>
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.state.filtrosTipoCompromisos == TiposDeCompromisoEnum.BLOQUEO ? (
                          <LinkLiberty
                              onClick={()=> {this.onChangeFiltros(TiposDeCompromisoEnum.DEFAULT)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.quitarFiltro}
                          </LinkLiberty>
                      ) : (
                          <LinkLiberty
                              onClick={()=> {this.onChangeFiltros(TiposDeCompromisoEnum.BLOQUEO)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.opcionFiltrar2}
                          </LinkLiberty>
                      )}
                    </div>
                  </div>
                </Card.Footer>
              </Card>): null}

              {/*Por autorizar porcentaje*/}
              {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO)?
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        {this.props.language.language?.engagments.titulo4}
                      </Card.Title>
                      <div className="row">
                        <div className="col-md-6">
                          <CircularProgressbar
                              value={
                                this.state.estadisticas.porAutorizar.porcentaje
                              }
                              text={`${this.state.estadisticas.porAutorizar.porcentaje}%`}
                              styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0.25,
                                // Text size
                                textSize: "16px",
                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,
                                // Colors
                                pathColor: `rgba(6, 116, 140, ${15! / 100})`,
                                textColor: "#06748C",
                              })}
                          />
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="text-right bg-white ">

                          <LinkLiberty
                              href="/compromisos/autorizaciones"
                              //onClick={this.handleFiltrarPorAutorizar}
                              className={"h6"}
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.opcionFiltrar}
                          </LinkLiberty>
                    </Card.Footer>
                  </Card>:
                  null
              }
              {/* compromisos vencidos */}
              {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ?
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        {this.props.language.language?.engagments.titulo2}
                      </Card.Title>
                      <div className="row">
                        <div className="col-md-6">
                          <CircularProgressbar
                              value={this.state.estadisticas.vencidas.porcentaje}
                              text={`${this.state.estadisticas.vencidas.porcentaje}%`}
                              styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0.5,
                                // Text size
                                textSize: "16px",
                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,
                                // Colors
                                pathColor: `rgba(6, 116, 140, ${15! / 100})`,
                                textColor: "#06748C",
                              })}
                          />
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="text-right bg-white ">
                      {this.state.filtrosTipoCompromisos == TiposDeCompromisoEnum.VENCIDOS ? (
                          <LinkLiberty
                              onClick={()=>{this.onChangeFiltros(TiposDeCompromisoEnum.DEFAULT)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.quitarFiltro}
                          </LinkLiberty>
                      ) : (
                          <LinkLiberty
                              onClick={()=>{this.onChangeFiltros(TiposDeCompromisoEnum.VENCIDOS)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.opcionFiltrar}
                          </LinkLiberty>
                      )}
                    </Card.Footer>
                  </Card>:
                  null
              }
              {/* compromisos por vencer */}
              {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
              validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ?
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        {this.props.language.language?.engagments.titulo3}
                      </Card.Title>
                      <div className="row">
                        <div className="col-md-6">
                          <CircularProgressbar
                              value={this.state.estadisticas.porVencer.porcentaje}
                              text={`${this.state.estadisticas.porVencer.porcentaje}%`}
                              styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0.25,
                                // Text size
                                textSize: "16px",
                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,
                                // Colors
                                pathColor: `rgba(6, 116, 140, ${15! / 100})`,
                                textColor: "#06748C",
                              })}
                          />
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="text-right bg-white ">
                      {this.state.filtrosTipoCompromisos == TiposDeCompromisoEnum.PORVENCER ? (
                          <LinkLiberty
                              onClick={()=> {this.onChangeFiltros(TiposDeCompromisoEnum.DEFAULT)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.quitarFiltro}
                          </LinkLiberty>
                      ) : (
                          <LinkLiberty
                              onClick={()=> {this.onChangeFiltros(TiposDeCompromisoEnum.PORVENCER)}}
                              className="h6"
                              variant="standalone"
                          >
                            {this.props.language.language?.engagments.opcionFiltrar}
                          </LinkLiberty>
                      )}
                    </Card.Footer>
                  </Card>:
                  null
              }


            </CardDeck>

            <div className="row buttoms">
              <div className="col-md-9 col-lg-8" />
              {validRoles(
                  this.state.PERFIL.roles,
                  this.localStorageClass.AGENTE
              ) ||
              validRoles(
                  this.state.PERFIL.roles,
                  this.localStorageClass.PROMOTOR
              ) ||
              validRoles(
                  this.state.PERFIL.roles,
                  this.localStorageClass.EJECUTIVOCUENTA
              ) ? (
                  <div className="col-md-3 col-lg-4">
                  <FindClient
                      id={''}
                      qualify={false}
                      idAgente={''}
                  />
                  </div>
              ) : null}
            </div>

            <TableEngagements
                userSelected={this.state.selectUser}
                filtrosCompromisos={this.state.filtrosTipoCompromisos}/>

          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const selectUserAux: UserEngagementsSelected = this.state.selectUser;
    this.getEstadisticas(selectUserAux.idEjecutivoCuetaYGar, selectUserAux.idAgente, selectUserAux.clienteDirecto);

  }

  public loadDashboard(selectUser: UserEngagementsSelected){
    console.log(selectUser);
    this.setState({selectUser: selectUser});

    // inicializar las estadisticas y los compromisos
    this.getEstadisticas(selectUser.idEjecutivoCuetaYGar,selectUser.idAgente,selectUser.clienteDirecto);
  }

  public async getEstadisticas(idEjecutivoCuetaYGar: number | null, idAgente: number| null, clienteDirecto: boolean): Promise<void> {


    const res = await engagementService.getEstadisticas(
        idEjecutivoCuetaYGar,
        idAgente,
        clienteDirecto
    );
    this.setState({ estadisticas: res.data });
    //console.log("Se obtienen estadisticas")
  }

  public onChangeFiltros(filtro: TiposDeCompromisoEnum){
    this.setState({filtrosTipoCompromisos: filtro});
  }



}

const mapStateToProps = (state: RootStore) => ({
  language: state.language,
});

export default connect(mapStateToProps)(Engagements);

interface EngagementsState {
  PERFIL: UserModel;
  selectUser: UserEngagementsSelected;
  estadisticas: Estadisticas;
  filtrosTipoCompromisos: TiposDeCompromisoEnum;

}
const  initTipoPerfil: TypeProfileCompromisos = {
  id: 0,
  tipo: TypeProfileEngagmentsEnum.default
};

 export enum tipoFiltroCompromisos {
   No = 0,
   Yes = 1,
 }
