import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import NavBar from '../../../components/public/nav-bar/NavBar';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import AlphanumericInput from '@lmig/lmds-react/Form/AlphanumericInput/AlphanumericInput';
import { Alert, Badge, Button, GridCol, IconSeeMore, Table, TableCell, TableRow } from '@lmig/lmds-react';
import { HiPlus } from 'react-icons/hi';
import './Auditoria.scss'
import { Dropdown, Pagination } from 'react-bootstrap';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { initPaginacion, loadPaginationList } from '../../../class/CommonClass';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import { DashboarAuditorias } from '../../../models/buro-credito/Datos';
import { AxiosError } from 'axios';
import Search from '@lmig/lmds-react/icons/Search';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface vencidProps {
    language: DefaultStateI
}



class Auditoria extends Component<vencidProps & RouteComponentProps, Audistate> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    options: any;

    constructor(props: any) {
        super(props);
        this.state = {
            search: '',
            paginacion: initPaginacion,
            ListAuditoria: [],
            listPaginacion: [],
            valida_tabla: false,
            errorTableAudit: false,
            tablaAudit: 0,
            validador: true,
            loadingConsult: true,
            filtro: 0,
            spinerTable:false,
        }
        this.handlenewAudi = this.handlenewAudi.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getAudit = this.getAudit.bind(this);
    }





    render(): JSX.Element {
        return <>
            <div className="container-fluid audi" id="Auditoria">
                <NavBar />
                <div className="Auditoria-container">

                    <h2>{this.props.language.language?.auditorias.Title!}</h2>
                    <hr />
                    <br /><br />
                    <div className="container">
                        <div className="row">
                            <div className="col-8 ">
                                <AlphanumericInput
                                    id="Alphanumeric--default"
                                    name="search-agent"
                                    labelVisual={this.props.language.language?.auditorias.Search}
                                    onChange={this.handleInputChange}
                                //value={this.state.search}
                                />

                            </div>

                            <div className="col-4 ">
                                <GridCol >
                                    <Button size="large" variant="primary" onClick={this.handlenewAudi} >
                                        <HiPlus /> {this.props.language.language?.auditorias.NewAudit}
                                    </Button>
                                </GridCol>
                            </div>

                        </div>

                    </div>
                    <br /><br /><br />
                    {this.state.errorTableAudit ? (
                        <div className="col-12">
                            <Alert id='alert_up' highlightType="negative">
                                {this.props.language.language?.createquery.alertaDatos!}
                            </Alert>
                        </div>
                    ) :
                        <>
                            {this.state.spinerTable === true ? (
                                <div className="col-12">
                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                </div>
                            ) :

                                <>
                                    <div className="col-md-12 col-sm-12">
                                        <Table id='table-Auditoria' >
                                            <thead>
                                                <TableRow >
                                                    <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                        {this.props.language.language?.auditorias.Folio!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Periodo'} type="colHead" className='tb'>
                                                        {this.props.language.language?.auditorias.Periodo!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'TipoPersona'} type="colHead" className='tb'>
                                                        {this.props.language.language?.auditorias.TipoPersona!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Usuario'} type="colHead" className='tb'>
                                                        {this.props.language.language?.auditorias.Usuario!}
                                                    </TableCell>

                                                    <TableCell dataHeader={'Detalle'} type="colHead" className='tb'>
                                                        {this.props.language.language?.auditorias.Detalle!}
                                                    </TableCell>

                                                </TableRow>
                                            </thead>


                                            <tbody>
                                                {this.state.validador === true ? (
                                                    <>
                                                        {
                                                            this.state.ListAuditoria && this.state.ListAuditoria.map((value: DashboarAuditorias, index: number) => (

                                                                <TableRow key={index} id={value.folioAuditoria} >

                                                                    <TableCell type="rowHead">
                                                                        {value.folioAuditoria}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {value.periodoConsultas}
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {value.tipoPersona}
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {value.usuarioLogin}
                                                                    </TableCell>


                                                                    <TableCell dataHeader={'Estatus'} className="estatus">
                                                                        <Badge highlightType="informative" className='btn-audit' onClick={() => this.handleConsult(value.folioAuditoria, value.tipoPersona)}>
                                                                            {this.props.language.language?.auditorias.Consultar!}
                                                                        </Badge>

                                                                    </TableCell>

                                                                </TableRow>

                                                            ))
                                                        }

                                                    </>
                                                ) : null}

                                            </tbody>

                                        </Table>
                                        <br />
                                        {/*paginacion*/}
                                        < div className="row pagination-sdgf" >
                                            <div className="col-md-5 show-registers">
                                                {this.props.language.language?.creditbureau.creditmostrar!}&nbsp;
                                                {(this.state.paginacion.currentPage * this.state.paginacion.size) - (this.state.paginacion.size - 1)}  {this.props.language.language?.creditbureau.credital!}&nbsp;
                                                {(this.state.paginacion.currentPage * this.state.paginacion.size)}  {this.props.language.language?.creditbureau.creditotal!}&nbsp;
                                                {this.state.paginacion.totalItems}  {this.props.language.language?.creditbureau.creditrecors!}.
                                            </div>
                                            <div className="col-md-7 ">
                                                <Pagination className="float-md-right" >
                                                    <Pagination.Prev
                                                        onClick={() => {
                                                            this.clickGoToPage((this.state.listPaginacion[0]) - 1)

                                                        }}
                                                    />
                                                    {
                                                        this.state.listPaginacion.map((i, index) =>
                                                        (<Pagination.Item
                                                            key={i}
                                                            active={i === this.state.paginacion.currentPage}
                                                            onClick={() => {
                                                                this.clickGoToPage(i);
                                                            }}>
                                                            {i}
                                                        </Pagination.Item>

                                                        ))
                                                    }
                                                    <Pagination.Next
                                                        onClick={() => {
                                                            this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length - 1] + 1)
                                                        }}
                                                    />

                                                </Pagination>

                                            </div>

                                        </div>
                                    </div>

                 </>} </>}
                </div>


                <div className="container">

                </div>


            </div>

        </>
    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }
    componentDidMount() {
        this.getAudit(this.state.paginacion);
    }

    public handlenewAudi() {
        this.props.history.push('/buro-credito/Crear-Auditoria');
    }

    public handleConsult(value: string, tipopersona: string) {

        localStorage.setItem('FolioAuditoria', JSON.stringify(value));
        this.props.history.push('/buro-credito/View-Auditoria');
        localStorage.setItem('tipoPersona', JSON.stringify(tipopersona));
        this.props.history.push('/buro-credito/View-Auditoria');
    }

    public handleInputChange(e: InputChange): void {

        this.setState({
            search: e.target.value
        }, () => {
            this.getBusqueda(this.state.search, this.state.paginacion)
        })



    }


    //Obtenemos datos dashboard
    public async getAudit(paginacionAuditoria: PaginacionModel) {

        this.setState({ spinerTable: true })

        try {
            //console.log('entra aqui adentro')
            this.setState({ valida_tabla: true })
            const res = await getCreditbureau.GetAuditorias(this.state.filtro, paginacionAuditoria);
            // console.log('seguimos dentro')
            const response: { paginacionAuditoria: PaginacionModel, consultas: DashboarAuditorias[] } = res.data;
            response.paginacionAuditoria.size = paginacionAuditoria.size;
            //console.log(res.data.consultas)


            this.setState({
                paginacion: response.paginacionAuditoria,
                loadingConsult: false,
                ListAuditoria: response.consultas,
                listPaginacion: loadPaginationList(res.data.paginacionAuditoria),
                validador: true
            })

            if (response.consultas == null) {
                this.setState({ spinerTable: false })
                this.setState({ validador: true })
                this.setState({ errorTableAudit: true })
                this.setState({ valida_tabla: false })
                this.setState({ tablaAudit: 0 })

            } else {
                this.setState({ valida_tabla: false })
                this.setState({ errorTableAudit: false })
                this.setState({ spinerTable: false })
                //  console.log(this.state.ListAuditoria)
                this.setState({ tablaAudit: 1 })
            }




        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    //Obtenemos datos dashboard
    public async getBusqueda(serarch: string, paginacionAuditoria: PaginacionModel) {
        //  console.log('entra aqui ')

        try {
            //  console.log('entra aqui adentro')
            this.setState({ valida_tabla: true })
            const res = await getCreditbureau.GetBusquedaAudit(serarch, 1, paginacionAuditoria);
            //  console.log('seguimos dentro')
            const response = res.data;
            // response.paginacionAuditoria.size = paginacionAuditoria.size;
            //  console.log(res.data)


            this.setState({
                // paginacion: response.paginacionAuditoria,
                loadingConsult: false,
                ListAuditoria: response,
                // listPaginacion: loadPaginationList(res.data.paginacionAuditoria),
                validador: true
            })

            if (response == null) {
                this.setState({ validador: true })
                this.setState({ errorTableAudit: true })
                this.setState({ valida_tabla: false })
                this.setState({ tablaAudit: 0 })

            } else {
                this.setState({ valida_tabla: false })
                this.setState({ errorTableAudit: false })
                //(this.state.ListAuditoria)
                this.setState({ tablaAudit: 1 })
            }




        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    private clickGoToPage(page: number) {
        const totalPages: number = this.state.paginacion.totalPages;
        if (page >= 1 && page <= totalPages) {
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({ paginacion: auxPaginacion })
            this.getAudit(this.state.paginacion);
        }



    }







}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(Auditoria);


interface Audistate {

    search: string,
    paginacion: PaginacionModel,
    ListAuditoria: DashboarAuditorias[],
    listPaginacion: number[],
    validador: boolean,
    errorTableAudit: boolean,
    valida_tabla: boolean,
    tablaAudit: number,
    loadingConsult: boolean,
    filtro: number,
    spinerTable:boolean,
}