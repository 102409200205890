import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { AxiosError } from 'axios';
import './ConfirmacionBaja.scss';
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import NavBarLogin from "../../../components/public/nav-bar-login/NavBarLogin";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import {
    Button,
    IconThumbsUp,
    IconThumbsDown,
    Content
} from "@lmig/lmds-react";
import * as apiService from '../../../services/juridico/JuridicoService';

interface ConfirmacionBajaProps {
    language: DefaultStateI
}


class ConfirmacionBaja extends Component<ConfirmacionBajaProps & RouteComponentProps, ConfirmacionBajaState>{


    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        document.title = "Confirmacíon Baja"

        this.state = {
            loadingMsg: true,
            alert: false,
            lblConfirmacion: '',
        }

    }

    render() {

        return <>
            <NavBarLogin />
            <Content id="confirmacion-baja" >
                <div className="contenedor">

                    <div className="center">

                        {this.state.loadingMsg ?
                            (<div className="col-12">
                                <SpinnerLoading text="Actualizando estado" />
                            </div>) :
                            (<div>
                                {this.state.alert ? (<IconThumbsUp color='positive' size='64' className="mb-4 mt-4" />) :
                                    (<IconThumbsDown color='caution' size='64' className="mb-4 mt-4" />
                                    )}
                                <h1>
                                    {this.state.lblConfirmacion}
                                </h1>
                                <p></p>
                                <Button
                                    variant='primary'
                                    size='small'
                                    type='button'
                                    className=""
                                    onClick={() => this.closeWindow()}
                                >Cerrar</Button>
                            </div>)}

                    </div>

                </div>
            </Content>
        </>
    }

    componentDidMount() {
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        const cve = urlParams.get('cveBaja');
        const estado = urlParams.get('estado');
        const tipo = urlParams.get('tipo');
        console.log(cve, estado, tipo);
        if (cve !== null && estado !== null && tipo !== null) {
            const cveEU = encodeURIComponent(cve);
            this.postAutoriza(cveEU, estado)

        }
        else {
            this.setState({
                alert: false,
                loadingMsg: false,
                lblConfirmacion: "Error inesperado durante la operacion"
            })
        }

    }

    postAutoriza = async (cveBaja: string, estado: string) => {
        try {
            const res = await apiService.postAutoriza(cveBaja, estado);
            if (res.data.messageResponse.code === 1 || Number(estado) === 5) {
                this.setState({
                    alert: true,
                    loadingMsg: false,
                    lblConfirmacion: "La solicitud de baja fue rechazada con exito"
                })
                return;
            }
            if (res.data.messageResponse.code === 0) {
                this.setState({
                    alert: true,
                    loadingMsg: false,
                    lblConfirmacion: "La solicitud de baja fue autorizada con exito",

                })
                
                //window.opener?.location.reload();
            }

        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                this.setState({
                    alert: false,
                    loadingMsg: false
                })
                console.log(err.response.status);
                console.log(err.response.data.error);
                console.log(err.response.data);
                if (err.response.status === 406) {
                    this.setState({ lblConfirmacion: "Esta solicitud ya no se encuentra en proceso de autorizacion" })
                    return;
                }
                this.setState({ lblConfirmacion: "Error inesperado durante la operacion" })
            }

        }
    }

    public closeWindow() {
        window.close()
    }


}


const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(ConfirmacionBaja);

interface ConfirmacionBajaState {

    loadingMsg: boolean,
    alert: boolean,
    lblConfirmacion: string,
}