export interface LockCommissionPrincipal {
    reintent: string;
    titleModal: string;
    titleModalto: string;
    close: string;
    saving: string;
    save: string;
    addDescription: string;
    msgValidReason: string;

}

export const LockCommissionPrincipalEnglish: LockCommissionPrincipal = {
    reintent: 'Reintent',
    titleModal: 'Lock Principal Commission',
    titleModalto: 'Lock Commission to ',
    close: 'Close',
    save: 'Save',
    saving: 'Saving',
    addDescription: 'Please write the reason for blocking the commission',
    msgValidReason: 'Please enter a commission lock description.'

}
export const LockCommissionPrincipalSpanish: LockCommissionPrincipal = {
    reintent: 'Reintentar',
    titleModal: 'Bloquear Comisión del Fiado',
    titleModalto: 'Bloquear la Comisión a ',
    close: 'Cerrar',
    saving: 'Guardando',
    save: 'Guardar',
    addDescription: 'Escribe por favor la razón del bloqueo de la comisión',
    msgValidReason: 'Por favor, ingresa una descripción de bloqueo de comisión.'

}
