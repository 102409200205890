import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import './ReviewDocs.scss';
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { ExpedienteModel } from "../../../models/garantias/ExpedienteModel";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import { AxiosError } from "axios";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import {IconClose, IconMenu, IconButton, IconCaretRight, IconCaretLeft, IconBrokenLink} from "@lmig/lmds-react";
import { NegativeAlert } from "../../../components/public/negative-alert/NegativeAlert";
import { Accordion, Card, Nav, Navbar } from "react-bootstrap";
import logo from "../../../assets/ico/PNG/LibertyFianzas_BLUE_RGB.png";
import environment from "../../../environments/environment";
import { UserModel } from "../../../models/public/UserModel";
import * as apiTokenService from "../../../services/api-session/ApiSession";
import SelectLanguage from "../../../components/public/select-language/SelectLanguage";

interface Params {
    idWarranty: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

interface ReviewDocsProps {
    language: DefaultStateI
}

class ReviewDocs extends Component<ReviewDocsProps & RouteComponentProps<Params, SC, LocationState>, ReviewDocsState>{
    public localStorageClass = new LocalStorageClass();
    public idWarranty: number;

    constructor(props: any) {
        super(props);
        this.idWarranty = parseInt(this.props.match.params.idWarranty);

        document.title = "Documentos de garantía " + parseInt(this.props.match.params.idWarranty);
        const PERFIL = this.localStorageClass.getProfile();
        this.state = {
            loadingExpediente: true,
            errorExpediente: false,
            listExpediente: [],
            openSideBar: false,
            docSelected: 0,
            PERFIL: PERFIL,
        }
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.selectDoc = this.selectDoc.bind(this);

    }

    render() {
        return <>
            <div id="nav-bar">
                <Navbar fixed="top">
                    <Navbar.Brand>
                        <>
                            <a id="toogle-ico" onClick={this.open}>
                                {
                                    !this.state.openSideBar ?
                                        <IconMenu
                                            title={this.props.language.language?.reviewDocs.openMenu}
                                            size={{
                                                base: '32',
                                                md: '24',
                                                xl: '24'
                                            }}
                                            color="blue" />
                                        :
                                        <IconClose
                                            title={this.props.language.language?.reviewDocs.closeMenu}
                                            size={{
                                                base: '32',
                                                md: '24',
                                                xl: '24'
                                            }}
                                            color="blue" />
                                }

                            </a></>

                        <img
                            src={logo}
                            width="112" height="53.3"
                            onClick={this.open}
                            className="d-inline-block align-top ico-liberty"
                            alt="Logo Liberty Fianzas"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="me-auto">
                            <Nav.Link className={"select-language"}>

                            </Nav.Link>
                        </Nav>
                        <Navbar.Text className="">
                            <div className="row">
                                <div className="col-3">
                                    <SelectLanguage />
                                </div>
                            </div>

                        </Navbar.Text>
                    </Navbar.Collapse>
                </Navbar>

                <div id="mySidenav" className="panel-group bg-light side-nav">
                    <Accordion defaultActiveKey={this.menuSeccion(this.props.history.location.pathname)}>
                        <Card>

                            {this.state.loadingExpediente ? (
                                <div className="col-12">
                                    <SpinnerLoading text={this.props.language.language?.warrantyAuthorization.loadingDoc!} />
                                </div>
                            ) :
                                (!this.state.errorExpediente) ? (
                                    <div className="row">
                                        {this.state.listExpediente.length > 0 ? (
                                            <div className="col-md-12 expedient-files">
                                                {
                                                    this.state.listExpediente.map((i, index) =>
                                                    (
                                                        <Accordion.Toggle
                                                            as={Card.Header}
                                                            onClick={() => {
                                                                this.selectDoc(index)
                                                            }}
                                                            eventKey={i.descripcion}>
                                                            {i.descripcion}
                                                        </Accordion.Toggle>
                                                    ))
                                                }
                                                {
                                                    (this.state.listExpediente.length == 0) ? (
                                                        <div className="col-md-12 ">
                                                            {this.props.language.language?.warranty.notFoundDocs!}
                                                        </div>

                                                    ) : null
                                                }

                                            </div>
                                        ) : <div className="col-md-12 expedient-files">
                                            <NegativeAlert textAlert={this.props.language.language?.warranty.notFoundDocs!} />
                                        </div>
                                        }
                                    </div>
                                ) :
                                    <div className="col-12">
                                        <NegativeAlert textAlert={this.props.language.language?.warranty.notFoundDocs!} />
                                    </div>
                            }

                        </Card>
                    </Accordion>


                </div>

                <div id="coverNav" onClick={this.close} className="background-nav"></div>

            </div>
            <div className="background-red container-fluid" id="review-docs" style={{ backgroundColor: '#333635' }}>
                {this.state.loadingExpediente ? (
                    <div className="col-12">
                        <SpinnerLoading text={this.props.language.language?.warrantyAuthorization.loadingDoc!} />
                    </div>
                ) :
                    (!this.state.errorExpediente) ? (
                        <div className="row">
                            {this.state.listExpediente.length > 0 ? (
                                <div className="col-md-12 expedient-files">
                                    {
                                        (this.state.listExpediente.length == 0) ? (
                                            <div className="col-md-12">
                                                {this.props.language.language?.warranty.notFoundDocs!}
                                            </div>

                                        ) : <>
                                            <div className="row">
                                                <div className="col-md-12 container-center fontTitle">
                                                    <h3 className="title-seccion">
                                                        {this.props.language.language?.reviewDocs.title!} {this.props.match.params.idWarranty}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 container-center">
                                                    <IconButton onClick={() => this.selectDoc(this.state.docSelected - 1)} disabled={this.state.docSelected < 1}>
                                                        <IconCaretLeft label={this.props.language.language?.reviewDocs.previous!} size="16" className="fontTitle" />
                                                    </IconButton>
                                                </div>
                                                <div className="col-md-4 container-center fontTitle">
                                                    <h4 className="title-seccion">
                                                     {this.state.listExpediente[this.state.docSelected].descripcion!}<br />
                                                    </h4>
                                                </div>
                                                <div className="col-md-4 container-center">
                                                    <IconButton disabled={this.state.docSelected + 1 >= this.state.listExpediente.length} onClick={() => this.selectDoc(this.state.docSelected + 1)}>
                                                        <IconCaretRight label={this.props.language.language?.reviewDocs.next!} size="16" className="fontTitle" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <iframe className="mt-3" src={this.state.listExpediente[this.state.docSelected].url!}
                                                allowFullScreen></iframe>
                                        </>
                                    }

                                </div>
                            ) :
                                <div className="col-12 text-center" id="no-documents">
                                    <h4>
                                        {this.props.language.language?.warranty.notFoundDocs!} &nbsp;
                                        <IconBrokenLink color="caution"  />
                                    </h4>
                                </div>
                            }
                        </div >
                    ) :
                        <div className="col-12" id="no-documents">
                            <NegativeAlert textAlert={this.props.language.language?.warranty.notFoundDocs!} />
                        </div>
                }
            </div >
        </>
    }

    componentDidMount() {
        //si no hay un token guardado
        if (!this.state.PERFIL.accessToken) {
            // redireccionar
            this.redirectLogin();
        } else {
            // si hay token hacer las acciones correspondientes
            this.validToken(this.state.PERFIL.accessToken);

        }
        if (this.props.match.params.idWarranty) {
            const auxIdWarranty = parseInt(this.props.match.params.idWarranty);
            this.getExpedient(auxIdWarranty);
        }
    }

    async getExpedient(idWarranty: number) {
        this.setState({ loadingExpediente: true, errorExpediente: false })
        try {
            const res = await garantiasService.getExpediente(idWarranty);
            const listExpediente: ExpedienteModel[] = res.data;

            let newListExpediente: ExpedienteModel[] = [];
            // quitar los expedintes que no hayan cargado archivos
            listExpediente.map((i, index) => {
                if (i.url) {
                    newListExpediente.push(i)
                }
            });
            this.setState({ listExpediente: newListExpediente, loadingExpediente: false })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({ loadingExpediente: false, errorExpediente: true })

            }
        }
    }

    public open() {
        if (!this.state.openSideBar) {
            this.setState({ openSideBar: true });
            // @ts-ignore
            document.getElementById('mySidenav').style.width = '250px';
            // @ts-ignore
            document.getElementById('coverNav').style.width = '100%';
        } else {
            this.close()
        }

    };

    public close() {
        this.setState({ openSideBar: false })
        // @ts-ignore
        document.getElementById('mySidenav').style.width = '0';
        // @ts-ignore
        document.getElementById('coverNav').style.width = '0';


    };

    public menuSeccion(pathname: string) {
        // console.log(pathname)
        let seccion = '';
        for (let i = 1; i < pathname.length; i++) {
            const letra = pathname.substring(i, i + 1);
            if (letra != '/') {
                seccion += letra;
            } else {
                break;
            }
        }
        return seccion;
    }


    private selectDoc(index: number) {
        this.setState({ docSelected: index })
        this.close()
    }

    private redirectLogin() {
        const URLLOGIN = environment.URLLOGIN

        if (environment.env === "dev") {
            //history.push('/login')
            this.props.history.push("/login");
        }
        else if (environment.env === "test") {
            window.location.replace(URLLOGIN);
        }
        else if (environment.env === "prod") {
            window.location.replace(URLLOGIN);
        }


    }

    private validToken = async (token: string) => {
        try {
            const res = await apiTokenService.getValidaToken(this.state.PERFIL.accessToken);
            if (res.status !== 200) {
                this.redirectLogin();
            }
        } catch (error) {
            const err = error as AxiosError
            console.log(err);
            this.redirectLogin();
        }
    }


}

interface ReviewDocsState {
    loadingExpediente: boolean;
    errorExpediente: boolean;
    listExpediente: ExpedienteModel[];
    openSideBar: boolean;
    docSelected: number;
    PERFIL: UserModel;

}
const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(ReviewDocs);
