import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AlphanumericInput } from "@lmig/lmds-react";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import './Engagements.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import "react-circular-progressbar/dist/styles.css";
import TableEngagementsAuthorize from "../../../components/compromisos/table-enegagements-authorize/TableEngagementsAuthorize";
import { validRoles } from "../../../class/CommonClass";
import { UserModel } from "../../../models/public/UserModel";
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";





class AutoEngagements extends Component<IListProps & RouteComponentProps, IListState>{

    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {

        super(props);
        document.title = `${this.props.language.language?.autoEngagements.nombreDocumento}`

        const PERFIL = this.localStorageClass.getProfile();

        this.state = {
            PERFIL: PERFIL,

        }
    }


    render(): JSX.Element {

        return <>
              <NavBar />

            <div className="container-fluid ">

  
                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) || validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO)
                    || validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ? (
                    <div className="waranty-container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className={"title-seccion"}>
                                    {this.props.language.language?.autoEngagements.titulo} <br /><br />
                                </h2>
                            </div>

                            {/*    <div className="col-8">
                                    <AlphanumericInput
                                        className="float-right w-100"
                                        id="input--labelAsHeading"
                                        labelVisual={`${this.props.language.language?.autoEngagements.buscar}`} 
                                        value=""  />
                            </div> */}

                            <div className="col-12">
                                <TableEngagementsAuthorize />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    }
}

interface IListProps {

    language: DefaultStateI
}

interface IListState {

    PERFIL: UserModel;

}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(AutoEngagements);
