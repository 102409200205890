import React, { ChangeEvent, Component } from 'react';
import './FormCreateEngagments.scss';
import InfoEngagement from "../info-engagement/InfoEngagement";
import { PipeLineStepsCE } from "../../public/pipeline-steps/PipeLineStepsCE";
import { AlphanumericInput, Button, Checkbox, CheckboxGroup, Form, GridCol, GridRow, List, ListItem, Modal, Select, SelectOption, Toggle } from "@lmig/lmds-react";
import { NegativeAlert } from "../../public/negative-alert/NegativeAlert";
import SelectCondition from "../../public/select-type/SelectCondition";
import SelectBlocks from "../../public/select-type/SelectBlocks";
import AutoCompleteProduct from "../../public/select-type/AutoCompleteProduct";
import { formatValue, InputChange, validRoles } from "../../../class/CommonClass";
import { MessagesAPIComponent } from "@lmig/lf-sgf-library-messages/src";
import environment from "../../../environments/environment";
import FindFuncionario from "../find-client/FindFuncionario";
import SuccessAlert from "@lmig/lmds-react/icons/SuccessAlert";
import Info from "@lmig/lmds-react/icons/Info";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { CompromisoPOST } from "../../../models/compromisos/CompromisoPOST";
import { Compromiso } from "../../../models/compromisos/Compromiso";
import { AutorizaCompromiso } from "../../../models/compromisos/AutorizaCompromiso";
import * as engagementService from "../../../services/compromisos/EngagementsService";
import { AxiosError } from "axios";
import { ElementosCompromiso } from "../../../models/compromisos/ElementosCompromiso";
import { Bloqueos } from "../../../models/compromisos/Bloqueos";
import { Condiciones } from "../../../models/compromisos/Condiciones";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { UserModel } from "../../../models/public/UserModel";
import { TipoCompromiso } from "../../../models/compromisos/TipoCompromiso";
import { CodigoRespuesta } from "../../../models/compromisos/CodigoRespuesta";
import { Correo } from "../../../models/compromisos/Correo";
import { Destinatario } from "../../../models/compromisos/Destinatario";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { PrevPage } from "../../public/prev-page/PrevPage";
import { NumeroCompromisos } from '../../../models/compromisos/NumeroCompromisos';
import FindRO from '../find-client/FindComite';
import FindComite from '../find-client/FindComite';
import FindSubComite from '../find-client/FindSubComite';
import FindComiteBuro from '../find-client/FindComiteBuro';
import CurrencyInputSDGF from "../../../components/public/currency-input-sdgf/CurrencyInputSDGF";


interface FormCreateEngagmentsProps {
    idFiadoBueno: number;
    isModal: boolean; // true
    clickShowModal?: (show: boolean) => void;
    language: DefaultStateI
}

class FormCreateEngagmentsClase extends Component<FormCreateEngagmentsProps, FormCreateEngagmentsState> {
    public idFiado: number;
    public idCompromiso: number;
    public conFacultad: string;
    public today: string;
    public last: string;
    public correoDestinario = '';
    public nombreDestinario = '';
    public regresaCompromiso: boolean = false;
    public localStorageClass = new LocalStorageClass();

    public initialEngagementStatePOST: CompromisoPOST = {
        codigo: 0,
        descripcion: '',
        respuesta: {
            bloqueos: [],
            conFiado: 0,
            condiciones: [],
            datosFiado: {
                correo: '',
                fechaAceptaCompr: '',
                idCompromiso: 0,
                nombreRep: ''
            },
            dictamen: '',
            fechaComprometida: '',
            fechaCompromisoCumplido: '',
            fechaSolicitud: '',
            idCompromiso: 0,
            idFiado: 0,
            idSolicitante: 0,
            monto: 0,
            motivo: '',
            numRenovaciones: 0,
            producto: '',
            statusCompromiso: 0,
            tipoBloqueo: 0,
            tipoCompromiso: 0,
            tipoCumplimiento: 0,
            tipoSolicitante: 0
        }
    }

    public initialEngagementState: Compromiso = {
        bloqueos: [],
        conFiado: 0,
        condiciones: [],
        datosFiado: {
            correo: '',
            fechaAceptaCompr: '',
            idCompromiso: 0,
            nombreRep: ''
        },
        dictamen: '',
        fechaComprometida: '',
        fechaCompromisoCumplido: '',
        fechaSolicitud: '',
        idCompromiso: 0,
        idFiado: 0,
        idSolicitante: '',
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoBloqueo: 0,
        tipoCompromiso: 0,
        tipoCumplimiento: 0,
        tipoSolicitante: 0

    }

    public initialEngagementStateAcepta: AutorizaCompromiso = {
        idCompromiso: 0,
        idAccion: 0,
        fechaComprometida: '',
        motivo: '',
        dictamen: '',
        nombreRep: '',
        usuario: '',
    }

    public initialMsg: Messages = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }

    public initialCodigoRespuesta: CodigoRespuesta = {
        codigo: 0,
        descripcion: ''
    }


    constructor(props: FormCreateEngagmentsProps) {
        super(props);
        this.idFiado = this.props.idFiadoBueno;
        this.conFacultad = '0';
        this.idCompromiso = 0;
        this.today = '';
        this.last = '';

        this.state = {
            numCompromisos: 0,
            autorizaRO: false,
            autorizaComite: false,
            autorizaComiteBuro: false,
            autorizaSubComite: false,
            numeroCompromisosApoyo: 0,
            numeroCompromisosBloqueB: 0,
            numeroCompromisosBloqueoG: 0,
            numeroGarantia: 0,
            descripcionGarantia: "",
            correoFiado: '',
            mensajesAlerta: this.initialMsg,
            aceptaTerminos: false,
            loading: false,
            PERFIL: this.localStorageClass.getProfile(),
            count: 0,
            engagementObject: this.initialEngagementState,
            reponseObject: this.initialEngagementStatePOST,
            authorizeEngagementObject: this.initialEngagementStateAcepta,
            listTipos: [],
            openModalClose: false,
            compromiso: this.initialEngagementState,
            conFacultades: this.initialCodigoRespuesta,
            respuestaNotificacion: this.initialCodigoRespuesta,
            email: {
                correoDe: '',
                correoPara: '',
                de: '',
                fecha: '',
                idProyecto: '',
                idReferencia: '',
                mensaje: '',
                para: '',
                perfil: ''

            },
            compromisoDetails: [{
                fiado: '',
                rfc: '',
                idCompromiso: 0,
                fechaSolicitud: '',
                bloqueos: [{
                    descripcion: '',
                    idBloqueo: 0,
                    estatus: 0
                }],
                condiciones: [{
                    idCondicion: 0,
                    descripcion: '',
                    estatus: 0
                }],
                fechaComprometida: '',
                idFiado: 0,
                monto: 0,
                motivo: '',
                numRenovaciones: 0,
                producto: '',
                statusCompromiso: 0,
                tipoCompromiso: 0,
                idFuncionario: ''
            }],
            BlockOption: [{
                value: 0,
                label: '',
            }],
            ConditionOption: [{
                value: 0,
                label: '',
            }],
            destinatario: {
                nombre: '',
                email: ''

            },
            listNumeroC: {
                compromisosApoyo: 0,
                compromisosBloqueoB: 0,
                compromisosBloqueoG: 0,
                numeroGarantia: 0,
                descripcionGarantia: ""
            }
        }

        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        let today = "";
        let last = "";
        const monthA = new Date().getMonth() + 6;
        let yearA = year;



        if (month < 10 && date < 10) {
            const monthAux = "0" + month
            const dateAux = "0" + date
            today = year + "-" + monthAux + "-" + dateAux
            this.today = today
        }

        if (month < 10 && date >= 10) {
            const monthAux = "0" + month
            today = year + "-" + monthAux + "-" + date
            this.today = today
        }

        if (month >= 10 && date >= 10) {
            today = year + "-" + month + "-" + date
            this.today = today
        }


        if (monthA < 10 && date < 10) {
            const monthAux = "0" + monthA
            const dateAux = "0" + date
            last = yearA + "-" + monthAux + "-" + dateAux
            this.last = last
        }

        if (monthA < 10 && date >= 10) {
            const monthAux = "0" + monthA
            last = yearA + "-" + monthAux + "-" + date
            this.last = last
        }

        if (monthA >= 10 && date >= 10) {
            last = yearA + "-" + monthA + "-" + date
            this.last = last
        }

        console.log("Fecha minima: " + this.today)
        console.log("Fecha máxima: " + this.last)

        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
        this.selectedBlocks = this.selectedBlocks.bind(this)
        this.selectedConditions = this.selectedConditions.bind(this)
        this.selectedProducto = this.selectedProducto.bind(this)
        this.handleInputChangeDate = this.handleInputChangeDate.bind(this)
        this.handleInputChangeToggle = this.handleInputChangeToggle.bind(this)
        this.handleInputChangeEmail = this.handleInputChangeEmail.bind(this)
        this.handleInputChangeCheckBox = this.handleInputChangeCheckBox.bind(this)
        this.handleInputChangeMonto = this.handleInputChangeMonto.bind(this)

    }

    render(): JSX.Element {

        return (
            <div id="form-create-engagements">
                <div className="create-engagement">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-8 seccion-info ">
                                    <InfoEngagement id={this.idFiado} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <PipeLineStepsCE listSteps={[`${this.props.language.language?.createEngagement.compromiso} `, `${this.props.language.language?.createEngagement.aceptacion} `,
                            `${this.props.language.language?.createEngagement.revision} `, `${this.props.language.language?.createEngagement.dictamen} `]} currentStep={this.state.count!} />
                        </div>


                        <div className="col-md-12">

                            {this.regresaCompromiso == true ? (
                                <Form id="form-liberty">
                                    {this.state.count == 0 ? (

                                        <div className="row">
                                            {this.state.compromisoDetails.map((i, index) =>
                                                <div className="col-md-12">
                                                    <label className="label-input">{this.props.language.language?.createEngagement.tipoLabel}</label>
                                                    <AlphanumericInput
                                                        className="float-right w-100"
                                                        id="input--labelAsHeading"
                                                        labelVisual=""
                                                        disabled />
                                                </div>
                                            )}

                                            {this.state.compromiso.tipoCompromiso == 1 ?
                                                <div className="col-md-12">
                                                    <label className="label-input">{this.props.language.language?.createEngagement.condicionesLabel}</label>
                                                    <List compact className="condition-input">
                                                        {this.state.ConditionOption.map((condition, index) =>
                                                            <ListItem>{condition.label}</ListItem>
                                                        )}
                                                    </List>
                                                </div> : null}

                                            {this.state.compromiso.tipoCompromiso == 2 ?

                                                <div className="col-md-12">
                                                    <label className="label-input">{this.props.language.language?.createEngagement.bloqueosLabel}</label>
                                                    <List compact className="condition-input">
                                                        {this.state.BlockOption.map((bloq, index) =>
                                                            <ListItem>{bloq.label}</ListItem>
                                                        )}
                                                    </List>
                                                </div> : null}


                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.producto}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={this.state.compromiso.producto} disabled />
                                            </div>

                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.monto}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={`$ ${this.state.compromiso.monto}`} disabled />
                                            </div>

                                            {this.state.compromiso.datosFiado.correo != null && this.state.compromiso.statusCompromiso == 1 ? (
                                                <div className="col-md-12">
                                                    <label className="label-input">{this.props.language.language?.createEngagement.nombreRepresentante}</label>
                                                    <AlphanumericInput
                                                        className="float-right w-100"
                                                        id="input--labelAsHeading"
                                                        labelVisual=""
                                                        value={this.state.compromiso.datosFiado.nombreRep} disabled />
                                                </div>) : null}

                                            {this.state.compromiso.datosFiado.correo != null && this.state.compromiso.statusCompromiso == 7 ? (
                                                <div className="col-md-12">
                                                    <label className="label-input">{this.props.language.language?.createEngagement.email}</label>
                                                    <AlphanumericInput
                                                        className="float-right w-100"
                                                        id="input--labelAsHeading"
                                                        labelVisual=""
                                                        value={this.state.compromiso.datosFiado.correo} disabled />
                                                </div>) : null}

                                            {this.state.compromiso.datosFiado.correo != null && this.state.compromiso.statusCompromiso == 1 ? (
                                                <div className="col-md-6">
                                                    <label className="label-input">{this.props.language.language?.createEngagement.fecha}</label>
                                                    <AlphanumericInput
                                                        className="float-right w-100"
                                                        id="input--labelAsHeading"
                                                        labelVisual=""
                                                        value={this.state.compromiso.fechaComprometida} disabled />
                                                </div>) : null}

                                            {this.state.compromiso.datosFiado.correo == null ? (
                                                <div className="col-md-6">
                                                    <label className="label-input">{this.props.language.language?.createEngagement.fecha}</label>
                                                    <AlphanumericInput
                                                        className="float-right w-100"
                                                        id="input--labelAsHeading"
                                                        labelVisual=""
                                                        value={this.state.compromiso.fechaComprometida} disabled />
                                                </div>) : null}

                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.motivo}</label>
                                                <textarea value={this.state.compromiso.motivo} disabled className={"input-sdgf"} rows={4} cols={55} />
                                            </div>

                                            <div className="col-md-12">
                                                <div className="row buttons-navigation">
                                                    <div className="col-md-6">
                                                        {(this.props.isModal) ? (
                                                            <>
                                                                <Button dynamicWidth
                                                                    onClick={() => {
                                                                        this.props.clickShowModal!(false)
                                                                    }}
                                                                    variant="secondary">
                                                                    Cerrar
                                                                </Button>
                                                            </>
                                                        ) :
                                                            <PrevPage />
                                                        }
                                                    </div>
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => this.next()}>
                                                            {this.props.language.language?.createEngagement.continuar}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </Form>

                            ) :
                                <Form id="form-liberty">
                                {this.state.count == 0 ? (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.tipoLabel} </label>
                                            <Select
                                                id="select--default"
                                                labelA11y={`${this.props.language.language?.createEngagement.seleccionaTipo} `}
                                                labelVisual={`${this.props.language.language?.createEngagement.seleccionaTipo} `}
                                                onChange={this.handleInputChangeSelect}
                                                isRequired
                                                name="tipoCompromiso"
                                                placeholderOption={`${this.props.language.language?.createEngagement.seleccionaTipo} `} >
                                                {
                                                    this.state.listTipos.map((i, index) =>
                                                    (<SelectOption key={index} value={`${i.idTipoCompromiso} `}>
                                                        {i.descripcion}
                                                    </SelectOption>
                                                    ))
                                                }
                                            </Select>
                                            <NegativeAlert textAlert={this.state.mensajesAlerta.msgTipo} />

                                            {(this.state.engagementObject.tipoCompromiso == 1) ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="label-input">
                                                            {this.props.language.language?.createEngagement.condicionesLabel}</label>
                                                        <SelectCondition selectedConditions={this.selectedConditions} />
                                                    </div>
                                                    <NegativeAlert textAlert={this.state.mensajesAlerta.msgCondicion} />
                                                </div> : null}

                                            {(this.state.engagementObject.tipoCompromiso == 2) ?
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <label className="label-input">{this.props.language.language?.createEngagement.bloqueosLabel}</label>
                                                        <SelectBlocks idFiado={this.idFiado} selectedBlocks={this.selectedBlocks} />

                                                    </div>
                                                    <NegativeAlert textAlert={this.state.mensajesAlerta.msgBloqueo} />
                                                </div> : null}
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.producto}</label>
                                        </div>
                                        <div className="col-md-12">
                                            <AutoCompleteProduct selectedProducto={this.selectedProducto} />

                                            <NegativeAlert textAlert={this.state.mensajesAlerta.msgProducto} />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.monto}</label>
                                         <CurrencyInputSDGF
                                                    value={this.state.engagementObject.monto}
                                                    placeholder="Ingresa un Monto"
                                                    name="monto"
                                                    msgLimit={this.props.language.language?.createEngagement.limitAmount!}
                                                    onValueChange={(value, name) => {
                                                        this.setState(prevState => ({
                                                            engagementObject: { ...prevState.engagementObject, [name!]: formatValue(value!) }
                                                        }));
                                                    } } /> 

                                            <NegativeAlert textAlert={this.state.mensajesAlerta.msgMonto} />
                                        </div>

                                        <div className="col-md-12"> <br /> </div>

                                        <div className="col-md-6">
                                            <label className="label-input">{this.props.language.language?.createEngagement.fiadoLabel}</label>
                                        </div>
                                        <div className="col-md-1">
                                            <Toggle
                                                name="conFiado"
                                                compact
                                                dynamicWidth
                                                className="toogle"
                                                onChange={this.handleInputChangeToggle} />
                                        </div>

                                        {(this.state.engagementObject.conFiado == 1) ?

                                            <><div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.email}</label>
                                                <AlphanumericInput
                                                    name="correo"
                                                    labelVisual="fiado@mail.com"
                                                    value=""
                                                    onChange={this.handleInputChangeEmail} />
                                            </div>
                                                <NegativeAlert textAlert={this.state.mensajesAlerta.msgCorreo} />
                                            </>
                                            :
                                            <><div className="col-md-7">
                                                <label className="label-input">{this.props.language.language?.createEngagement.fecha}</label>
                                                <input
                                                    type="date"
                                                    name="fechaComprometida"
                                                    className={"input-sdgf"}
                                                    id="fechaComprometida"
                                                    min={this.today}
                                                    max={this.last}
                                                    onChange={this.handleInputChangeDate} />
                                            </div>
                                                <div className="col-md-7">
                                                    <NegativeAlert textAlert={this.state.mensajesAlerta.msgFecha} />
                                                </div>
                                            </>
                                        }

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.motivo}</label>
                                            <textarea required name="motivo"
                                                onChange={this.handleInputChangeSelect}
                                                className="motivo-input" rows={4} cols={55} />

                                            <NegativeAlert textAlert={this.state.mensajesAlerta.msgMotivo} />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row buttons-navigation">
                                                <div className="col-md-6">
                                                    <br />
                                                    {(this.props.isModal) ? (
                                                        <>
                                                            <Button dynamicWidth
                                                                onClick={() => {
                                                                    this.props.clickShowModal!(false)
                                                                }}
                                                                variant="secondary">
                                                                Cancelar
                                                            </Button>
                                                        </>
                                                    ) :
                                                        <PrevPage />
                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="primary"
                                                        //onClick={() => this.next()}
                                                        onClick={() => this.postEngagement()}>
                                                        {this.props.language.language?.createEngagement.continuar}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </Form>
                            }


                            <Form id="form-liberty">
                                {this.state.count == 1 ? (
                                    <div className="row">
                                        {/* LEYENDA CUANDO EL COMPROMISO ES SIN FIADO */}
                                        {this.state.correoFiado == '' && this.state.reponseObject.respuesta.statusCompromiso.toString() != '9' ? (
                                            <div className="container-create" >
                                                <div className="col-md-24">
                                                    <br /><br />
                                                    {this.props.language.language?.createEngagement.leyenda4}
                                                    <br /><br />
                                                    <hr />
                                                </div>

                                                <div className="col-md-12 btnS " >
                                                    <Form>
                                                        <br />
                                                        <br />
                                                        <div className="col-md-12 ">
                                                            <CheckboxGroup   >
                                                                <GridRow gutters="horizontal">
                                                                    <GridCol md="flex-initial">
                                                                        <Checkbox
                                                                            onChange={this.handleInputChangeCheckBox}
                                                                            defaultChecked={false} required={true} id="one--grid" labelVisual={`${this.props.language.language?.createEngagement.aceptaTerminos}`} name="grid" value="si" />
                                                                    </GridCol>
                                                                </GridRow>
                                                            </CheckboxGroup>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* LEYENDA CUANDO EL COMPROMISO ES CON FIADO */}
                                        {this.state.correoFiado != '' ? (
                                            <div className="col-md-12">
                                                <br /><br />
                                                {this.props.language.language?.createEngagement.leyenda2} {this.state.correoFiado}
                                                <br /><br />
                                                <hr />
                                            </div>
                                        ) : null}

                                        {/* LEYENDA CUANDO EXCEDE LOS 3 MESES DE PLAZO */}
                                        {this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' ? (
                                            <div className="col-md-12">
                                                <br /><br />
                                                {this.props.language.language?.createEngagement.leyenda3}
                                                <br /><br />
                                                <hr />
                                            </div>
                                        ) : null}

                                        <div className="col-md-12">
                                            <div className="row buttons-navigation">
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth variant="secondary" onClick={() => this.backRegresa()}> {this.props.language.language?.createEngagement.regresar}</Button>
                                                </div>

                                                {/*  NO FUE CON FIADO Y EL USUARIO TIENE FACULTADES */}
                                                {this.state.correoFiado == '' && this.state.conFacultades.codigo == 1 && this.state.reponseObject.respuesta.statusCompromiso.toString() != '9' ? (
                                                    <div className="col-md-6">
                                                        {this.state.aceptaTerminos == true ? (
                                                            <><br /><Button dynamicWidth variant="primary" onClick={() => this.next()}> {this.props.language.language?.createEngagement.continuar} </Button></>
                                                        ) : <><br /><Button dynamicWidth variant="primary" onClick={() => this.next()} disabled> {this.props.language.language?.createEngagement.continuar} </Button></>}
                                                    </div>) : null}


                                                {/*  NO FUE CON FIADO Y EL USUARIO NO TIENE FACULTADES */}
                                                {this.state.correoFiado == '' && this.state.conFacultades.codigo == 0  && validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ? (
                                                    <div className="col-md-6">
                                                        {this.state.aceptaTerminos == true || (this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' && this.state.aceptaTerminos == false) ? (
                                                            <><br />
                                                                {(this.props.isModal) ?
                                                                    <Button dynamicWidth variant="primary"
                                                                        onClick={
                                                                            () => {
                                                                                if (this.props.isModal) {
                                                                                    this.props.clickShowModal!(false)
                                                                                }
                                                                            }}>
                                                                        {this.props.language.language?.createEngagement.finalizar} </Button>
                                                                    :
                                                                    <Button dynamicWidth variant="primary"
                                                                        onClick={() => {
                                                                            if (this.state.autorizaRO == true) {
                                                                                this.postNotificaRO()
                                                                               // this.setState({ openModalResponsableOficina: true });
                                                                            } else if (this.state.autorizaRO == false ) {
                                                                                this.setState({ openModalClose: true });
                                                                            }
                                                                        }} > {this.props.language.language?.createEngagement.finalizar} </Button>
                                                                }
                                                            </>
                                                        ) : <><br /><Button dynamicWidth variant="primary" href="/compromisos" disabled> {this.props.language.language?.createEngagement.finalizar} </Button></>}
                                                    </div>) : null}

                                                {this.state.correoFiado == '' && this.state.conFacultades.codigo == 1  && validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' ? (
                                                    <div className="col-md-6">
                                                        {this.state.aceptaTerminos == true || (this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' && this.state.aceptaTerminos == false) ? (
                                                            <><br />
                                                                {(this.props.isModal) ?
                                                                    <Button dynamicWidth variant="primary"
                                                                        onClick={
                                                                            () => {
                                                                                if (this.props.isModal) {
                                                                                    this.props.clickShowModal!(false)
                                                                                }
                                                                            }}>
                                                                        {this.props.language.language?.createEngagement.finalizar} </Button>
                                                                    :
                                                                    <Button dynamicWidth variant="primary"
                                                                        onClick={() => {
                                                                            if (this.state.autorizaRO == true) {
                                                                                this.postNotificaRO()
                                                                                //this.setState({ openModalResponsableOficina: true });
                                                                            } else if (this.state.autorizaRO == false) {
                                                                                this.setState({ openModalClose: true });
                                                                            }
                                                                        }} > {this.props.language.language?.createEngagement.finalizar} </Button>
                                                                }
                                                            </>
                                                        ) : <><br /><Button dynamicWidth variant="primary" href="/compromisos" disabled> {this.props.language.language?.createEngagement.finalizar} </Button></>}
                                                    </div>) : null}

                                                    {this.state.correoFiado == '' && validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) || validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ? (
                                                    <div className="col-md-6">
                                                        {this.state.aceptaTerminos == true || (this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' && this.state.aceptaTerminos == false) ? (
                                                            <><br />
                                                                {(this.props.isModal) ?
                                                                    <Button dynamicWidth variant="primary"
                                                                        onClick={
                                                                            () => {
                                                                                if (this.props.isModal) {
                                                                                    this.props.clickShowModal!(false)
                                                                                }
                                                                            }}>
                                                                        {this.props.language.language?.createEngagement.finalizar} </Button>
                                                                    :
                                                                     <Button dynamicWidth variant="primary" href="/compromisos" > {this.props.language.language?.createEngagement.finalizar} </Button>
                                                                }
                                                            </>
                                                        ) :  <><br /><Button dynamicWidth variant="primary" href="/compromisos" disabled> {this.props.language.language?.createEngagement.finalizar} </Button></>}
                                                    </div>) : null}

                                                {/* FUE CON FIADO */}
                                                {this.state.correoFiado != '' ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth variant="primary" href="/compromisos" > {this.props.language.language?.createEngagement.finalizar} </Button>
                                                    </div>) : null}



                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <Modal
                                    isOpen={this.state.openModalResponsableOficina}
                                    size="medium"
                                    modalStyle="standard"
                                    id="modal6"
                                    closeFunc={() => {
                                        this.setState({ openModalResponsableOficina: false })
                                    }}>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-4 info">
                                                <Info
                                                    size="64"
                                                    color="informative">
                                                </Info>
                                            </div>

                                            <h2 className="texto"> {this.props.language.language?.createEngagement.notificacionResponsableOficina} {this.state.numCompromisos} <br />
                                                {this.props.language.language?.createEngagement.notificacionResponsableOficina2}</h2>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button onClick={() => {
                                                        this.setState({ openModalResponsableOficina: false })
                                                    }}
                                                        variant="secondary"
                                                        className="btn-cancel">
                                                        {this.props.language.language?.createEngagement.cancelar}
                                                    </Button>
                                                </div>
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button
                                                        //onClick={() => this.postEngagementRec()}
                                                        href="/compromisos"
                                                        variant="primary">
                                                        {this.props.language.language?.createEngagement.aceptar}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal
                                    isOpen={this.state.openModalClose}
                                    size="medium"
                                    modalStyle="standard"
                                    //title="Fiado"
                                    //className="modalExito"
                                    id="modal2"
                                    closeFunc={() => {
                                        this.setState({ openModalClose: false })
                                    }}>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-4 alert">
                                                <SuccessAlert
                                                    size="64"
                                                    color="positive">
                                                </SuccessAlert>
                                            </div>

                                            <h2> {this.props.language.language?.createEngagement.operacionExitosa} </h2>

                                            <br />
                                            <Button href="/compromisos"
                                                variant="primary"
                                                className="btn-ok">
                                                {this.props.language.language?.createEngagement.aceptar}
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>

                            </Form>

                            {this.state.count == 2 ? (
                                <>
                                    <div className="row">
                                        <Form id="form-mensajes">
                                            <MessagesAPIComponent
                                                idAPI={2}
                                                disableCreate={false}
                                                idEspecifico={this.state.authorizeEngagementObject.idCompromiso!}
                                                emailRemitente={this.state.PERFIL.email}
                                                nombreRemitente={this.state.PERFIL.username}
                                                emailDest={this.state.destinatario.email}
                                                nombreDest={this.state.destinatario.nombre}
                                                TOKEN={this.state.PERFIL.accessToken}
                                                APIURL={environment.APIMENSAJES}
                                                APIKEY={environment.APIKEYMENSAJES}
                                            />

                                            <div className="col-md-12">
                                                <div className="row buttons-navigation">
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="secondary"
                                                            onClick={() => this.back()}>
                                                            {this.props.language.language?.createEngagement.regresar}
                                                        </Button>
                                                    </div>

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) || validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR)
                                                        && this.state.autorizaRO == false ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button dynamicWidth
                                                                variant="primary"
                                                                //onClick={() => this.next()}
                                                                href="/compromisos">
                                                                {this.props.language.language?.createEngagement.finalizar}
                                                            </Button>
                                                        </div>) : null}

                                                    {/*  {validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) || validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR)
                                                        && this.state.correoFiado == '' && this.state.autorizaRO == true ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button variant="primary" dynamicWidth
                                                                onClick={() => {
                                                                    this.setState({ openModalResponsableOficina: true });
                                                                }}>
                                                                {this.props.language.language?.createEngagement.finalizar}
                                                            </Button>
                                                        </div>
                                                    ) : null} */}

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 0
                                                        && this.state.correoFiado == ''
                                                        && this.state.autorizaRO == false
                                                        && this.state.autorizaComite == false
                                                        && this.state.autorizaSubComite == false
                                                        && this.state.autorizaComiteBuro == false ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <FindFuncionario idCompromiso={this.state.compromiso.idCompromiso} />
                                                        </div>) : null}

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                        && this.state.correoFiado == ''
                                                        && this.state.autorizaRO == false
                                                        && this.state.autorizaComite == false
                                                        && this.state.autorizaSubComite == false
                                                        && this.state.autorizaComiteBuro == false ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button dynamicWidth
                                                                variant="primary"
                                                                onClick={() => this.next()}>
                                                                {this.props.language.language?.createEngagement.continuar}
                                                            </Button>
                                                        </div>
                                                    ) : null}

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                        && this.state.correoFiado == '' && this.state.autorizaRO == true ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button variant="primary" dynamicWidth
                                                                onClick={() => 
                                                                    this.postNotificaRO()
                                                                }>
                                                                {this.props.language.language?.createEngagement.finalizar}
                                                            </Button>
                                                        </div>
                                                    ) : null}

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                        && this.state.correoFiado == '' && this.state.autorizaComite == true ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <FindComite idCompromiso={this.idCompromiso} idFiado={this.idFiado}></FindComite>
                                                        </div>
                                                    ) : null}

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                        && this.state.correoFiado == '' && this.state.autorizaSubComite == true ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <FindSubComite idCompromiso={this.idCompromiso} idFiado={this.idFiado} ></FindSubComite>
                                                        </div>
                                                    ) : null}

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1
                                                        && this.state.correoFiado == '' && this.state.autorizaComiteBuro == true ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <FindComiteBuro idCompromiso={this.idCompromiso} idFiado={this.idFiado} ></FindComiteBuro>
                                                        </div>
                                                    ) : null}

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 0
                                                        && this.state.correoFiado != '' ? (
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button dynamicWidth
                                                                variant="primary"
                                                                //onClick={() => this.next()}
                                                                href="/compromisos">
                                                                {this.props.language.language?.createEngagement.finalizar}
                                                            </Button>
                                                        </div>
                                                    ) : null}

                                                </div>
                                                <Modal
                                                    isOpen={this.state.openModalResponsableOficina}
                                                    size="medium"
                                                    modalStyle="standard"
                                                    id="modal5"
                                                    closeFunc={() => {
                                                        this.setState({ openModalResponsableOficina: false })
                                                    }}>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="col-md-4 info">
                                                                <Info
                                                                    size="64"
                                                                    color="informative">
                                                                </Info>
                                                            </div>

                                                            <h2 className="texto"> {this.props.language.language?.createEngagement.notificacionResponsableOficina} {this.state.numCompromisos} <br />
                                                                {this.props.language.language?.createEngagement.notificacionResponsableOficina2}</h2>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <br />
                                                                    <Button                                                                                          
                                                                        variant="secondary"
                                                                        className="btn-cancel">
                                                                        {this.props.language.language?.createEngagement.cancelar}
                                                                    </Button>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <br />
                                                                    <Button
                                                                        //onClick={() => this.postEngagementRec()}
                                                                        href="/compromisos"
                                                                        variant="primary">
                                                                        {this.props.language.language?.createEngagement.aceptar}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </Form>
                                    </div></>
                            ) : null}

                            {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ? (
                                <div >
                                    {this.state.count == 3 ? (
                                        <div className="row">
                                            <Form id="form-dictamen">
                                                <div className="col-md-24">
                                                    <>
                                                    </>
                                                    {/*  {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.conFacultades.codigo == 1 ? (
                                                        <><label className="label-input"></label>
                                                            <textarea name="dictamen" placeholder={`${this.props.language.language?.createEngagement.redactaDictamen} `}
                                                                onChange={this.handleInputChangeSelect}
                                                                className="dictamen-input" rows={5} cols={20} /></>
                                                    ) : null} */}
                                                    <div className="col-md-12">
                                                        <div className="row buttons-navigation">

                                                            <div className="col-md-12">
                                                                <label className="label-input"></label>
                                                                <textarea name="dictamen"
                                                                          placeholder={`${this.props.language.language?.createEngagement.redactaDictamen} `}
                                                                    onChange={this.handleInputChangeSelect}
                                                                    className="dictamen-input" rows={5} cols={20} />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <br />
                                                                <Button dynamicWidth
                                                                    variant="secondary"
                                                                    onClick={() => this.back()}>
                                                                    {this.props.language.language?.createEngagement.regresar}
                                                                </Button>
                                                            </div>

                                                            <div className="col-md-4">
                                                                {this.state.engagementObject.dictamen == null || this.state.engagementObject.dictamen == '0'
                                                                    || this.state.engagementObject.dictamen == "" ? (
                                                                    <><br />
                                                                        <Button variant="primary" dynamicWidth
                                                                            onClick={() => {
                                                                                this.setState({ openModalRechazo: true });
                                                                            }} disabled>
                                                                            {this.props.language.language?.createEngagement.rechazar}
                                                                        </Button></>
                                                                ) :
                                                                    <><br />
                                                                        <Button variant="primary" dynamicWidth
                                                                            onClick={() => {
                                                                                this.setState({ openModalRechazo: true });
                                                                            }}>
                                                                            {this.props.language.language?.createEngagement.rechazar}
                                                                        </Button></>
                                                                }
                                                            </div>
                                                            <div className="col-md-4">
                                                                <br />
                                                                <Button variant="primary" dynamicWidth
                                                                    onClick={() => {
                                                                        this.setState({ openModalAutorizacion: true });
                                                                    }}>
                                                                    {this.props.language.language?.createEngagement.autorizar}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Modal
                                                        isOpen={this.state.openModalClose}
                                                        size="medium"
                                                        modalStyle="standard"
                                                        //title="Fiado"
                                                        //className="modalExito"
                                                        id="modal2"
                                                        closeFunc={() => {
                                                            this.setState({ openModalClose: false })
                                                        }}>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="col-md-4 alert">
                                                                    <SuccessAlert
                                                                        size="64"
                                                                        color="positive">
                                                                    </SuccessAlert>
                                                                </div>

                                                                <h2> {this.props.language.language?.createEngagement.operacionExitosa} </h2>

                                                                <br />
                                                                <Button href="/compromisos"
                                                                    variant="primary"
                                                                    className="btn-ok">
                                                                    {this.props.language.language?.createEngagement.aceptar}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Modal>

                                                    <Modal
                                                        isOpen={this.state.openModalAutorizacion}
                                                        size="medium"
                                                        modalStyle="standard"
                                                        id="modal3"
                                                        closeFunc={() => {
                                                            this.setState({ openModalAutorizacion: false })
                                                        }}>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="col-md-4 info">
                                                                    <Info
                                                                        size="64"
                                                                        color="informative">
                                                                    </Info>
                                                                </div>

                                                                <h2 className="texto"> {this.props.language.language?.createEngagement.preguntaAutoriza} </h2>

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <Button onClick={() => {
                                                                            this.setState({ openModalAutorizacion: false })
                                                                        }}
                                                                            //href="/compromisos"
                                                                            variant="secondary"
                                                                            className="btn-cancel">
                                                                            {this.props.language.language?.createEngagement.cancelar}
                                                                        </Button>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Button
                                                                            onClick={() => this.postEngagementAut()}
                                                                            //href="/compromisos"
                                                                            variant="primary"
                                                                        >
                                                                            {this.props.language.language?.createEngagement.aceptar}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal>

                                                    <Modal
                                                        isOpen={this.state.openModalRechazo}
                                                        size="medium"
                                                        modalStyle="standard"
                                                        id="modal4"
                                                        closeFunc={() => {
                                                            this.setState({ openModalRechazo: false })
                                                        }}>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="col-md-4 info">
                                                                    <Info
                                                                        size="64"
                                                                        color="informative">
                                                                    </Info>
                                                                </div>

                                                                <h2 className="texto"> {this.props.language.language?.createEngagement.preguntaRechaza} </h2>

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <br />
                                                                        <Button onClick={() => {
                                                                            this.setState({ openModalRechazo: false })
                                                                        }}
                                                                            //href="/compromisos"
                                                                            variant="secondary"
                                                                            className="btn-cancel">
                                                                            {this.props.language.language?.createEngagement.cancelar}
                                                                        </Button>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <br />
                                                                        <Button
                                                                            onClick={() => this.postEngagementRec()}
                                                                            //href="/compromisos"
                                                                            variant="primary">
                                                                            {this.props.language.language?.createEngagement.aceptar}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </Form>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    componentDidMount() {
        this.getTipoCompromisos();
        //this.getDestinatrio();

    }


    handleInputChangeSelect(e: InputChange): void {

        this.state.engagementObject.idFiado = this.idFiado

        this.state.engagementObject.idSolicitante = this.state.PERFIL.clave!

        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)) {
            this.state.engagementObject.tipoSolicitante = 1
        } else if (validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR)) {
            this.state.engagementObject.tipoSolicitante = 2
        } else if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)) {
            this.state.engagementObject.tipoSolicitante = 3
        }

        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            engagementObject: { ...prevState.engagementObject, [name]: formatValue(value) }
        }))

        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, [name]: formatValue(value) }
        }))

        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, idAccion: 2 }
        }))

        const sinAlerta = ''
        if (this.state.engagementObject.tipoCompromiso != 0) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgTipo: formatValue(sinAlerta) }
            }))
        }

        if (this.state.engagementObject.motivo != "" || parseInt(this.state.engagementObject.motivo) != 0) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgMotivo: formatValue(sinAlerta) }
            }))
        }
    }

    handleInputChangeCheckBox(e: ChangeEvent<HTMLInputElement>, isChecked?: boolean): void {

        let acepta = false;

        if (e.target.checked == true) {
            acepta = true
            this.setState({ aceptaTerminos: acepta })
        }

        if (e.target.checked == false) {
            acepta = false
            this.setState({ aceptaTerminos: acepta })
        }
        console.log("Acepta Términos: " + this.state.aceptaTerminos)
    }

    handleInputChangeEmail(e: InputChange): void {

        const name = e.target.value;
        const value = e.target.value;

        this.setState({ correoFiado: value })
        this.state.engagementObject.datosFiado = ({ correo: value, fechaAceptaCompr: '', idCompromiso: 0, nombreRep: '' })
    }


    handleInputChangeMonto(e: InputChange): void {

        const name = e.target.value;
        const value = e.target.value;

            if (isNaN(Number(value))){
                
                this.setState(prevState => ({
                    engagementObject : { ...prevState.engagementObject, monto: value }
                }))
               
            }

        

       
    }

    handleInputChangeDate(e: InputChange): void {

        const value = e.target.value;
        const date = value
        const day = date?.substring(8, 10)
        const month = date?.substring(5, 7)
        const year = date?.substring(0, 4)
        const mesActual = new Date().getMonth() + 1;

        if (mesActual < 10) {
            const mesActualAuxiliar = "0" + mesActual
            console.log("MES ACTUAL: " + mesActualAuxiliar)
        }

        if (month < mesActual) {
            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue("Fecha No Válida") }
            }))
        } else {
            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue("") }
            }))

            this.setState(prevState => ({
                engagementObject: { ...prevState.engagementObject, fechaComprometida: day + '/' + month + '/' + year }
            }))

            this.setState(prevState => ({
                authorizeEngagementObject: { ...prevState.authorizeEngagementObject, fechaComprometida: day + '/' + month + '/' + year }
            }))
        }
        this.getFacultades();
    }

    handleInputChangeToggle(e: ChangeEvent<HTMLInputElement> | any, isChecked?: boolean | any): void {

        let fiado = 0

        if (isChecked == true) {
            fiado = 1
            console.log("Con Fiado")

        } else {
            fiado = 0
            console.log("Sin Fiado")
            this.state.engagementObject.datosFiado.correo = ''
            this.setState({ correoFiado: '' })
        }

        this.setState(prevState => ({
            engagementObject: { ...prevState.engagementObject, conFiado: formatValue(fiado) }
        }))

        this.getFacultades();
    }


    validaFormulario(): boolean {

        this.setState({ mensajesAlerta: this.initialMsg })

        const leyendaTipo = 'Por favor selecciona un Tipo de Compromiso'
        const leyendaCondicion = 'Por favor selecciona al menos una Condición'
        const leyendaBloqueo = 'Por favor selecciona al menos un Bloqueo'
        const leyendaProducto = 'Por favor selecciona un Producto'
        const leyendaFecha = 'Por favor selecciona una Fecha'
        const leyendaCorreo = 'Por favor captura un Correo'
        const leyendaMonto = 'Por favor captura un Monto'
        const leyendaMotivo = 'Por favor captura un Motivo'

        //VALIDA QUE EL FORMULARIO TENGA VALORES
        if (this.state.engagementObject.tipoCompromiso == 0) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgTipo: formatValue(leyendaTipo) }
            }))
            return false
        }

        if (this.state.engagementObject.tipoCompromiso == 1 && this.state.engagementObject.condiciones.length == 0) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgCondicion: formatValue(leyendaCondicion) }
            }))
            return false
        }

        if (this.state.engagementObject.tipoCompromiso == 2 && this.state.engagementObject.bloqueos.length == 0) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgBloqueo: formatValue(leyendaBloqueo) }
            }))

            return false
        }

        if (this.state.engagementObject.producto == "") {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgProducto: formatValue(leyendaProducto) }
            }))

            return false
        }

        if (this.state.engagementObject.monto == 0 || this.state.engagementObject.monto == undefined) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgMonto: formatValue(leyendaMonto) }
            }))

            return false
        }

        if (this.state.engagementObject.conFiado == 0 && this.state.engagementObject.fechaComprometida == "") {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue(leyendaFecha) }
            }))

            return false
        }

        if (this.state.engagementObject.conFiado == 1 && this.state.engagementObject.datosFiado.correo == "") {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgCorreo: formatValue(leyendaCorreo) }
            }))

            return false
        }

        if (this.state.engagementObject.motivo == "") {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgMotivo: formatValue(leyendaMotivo) }
            }))
            return false
        }

        return true
    }

    async postEngagement(): Promise<void> {

        if (!this.validaFormulario()) {

            console.log("No cuenta con información en el formulario")

        } else {
            try {
                //ES CON FIADO --> VERIFICA EL CORREO ELECTRONICO QUE SEA VALIDO
                if (this.state.engagementObject.datosFiado.correo != '') {

                    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

                    if (emailRegex.test(this.state.engagementObject.datosFiado.correo)) {

                        console.log("Correo Válido")
                        console.log("El correo del Fiado es: " + this.state.correoFiado)


                        const res = await engagementService.postCompromiso(this.state.engagementObject);
                        this.setState({ reponseObject: res.data })
                        console.log("RESPUESTA: " + this.state.reponseObject)

                        this.idCompromiso = this.state.reponseObject.respuesta.idCompromiso

                        console.log('Creación de Compromiso Exitosa con ID: ')

                        this.state.authorizeEngagementObject.idCompromiso = this.idCompromiso

                        this.getClientAut(this.idCompromiso);

                        if (this.idCompromiso != null) {
                            this.next()
                            this.getNumeroDeCompromisos()
                        }

                    } else {
                        console.log("Correo Invalido")

                        this.setState(prevState => ({
                            mensajesAlerta: { ...prevState.mensajesAlerta, msgCorreo: formatValue('Por favor captura un correo válido') }
                        }))
                    }

                    //ES SIN FIADO
                } else if (this.state.engagementObject.datosFiado.correo == '') {

                    const res = await engagementService.postCompromiso(this.state.engagementObject);
                    this.setState({ reponseObject: res.data })
                    console.log("RESPUESTA: " + this.state.reponseObject)

                    this.idCompromiso = this.state.reponseObject.respuesta.idCompromiso

                    this.state.authorizeEngagementObject.idCompromiso = this.idCompromiso

                    console.log('Creación de Compromiso Exitosa con ID: ' + this.idCompromiso)

                    this.getClientAut(this.idCompromiso);

                    if (this.idCompromiso != null) {
                        this.next()
                        this.getNumeroDeCompromisos()
                    }
                }

            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log("Code Error:" + err.response.status)
                    console.log("Error:" + err.response.statusText)
                    this.setState({ count: 0 })
                }
            }
        }
    }

    async postEngagementAut(): Promise<void> {

       // this.state.authorizeEngagementObject.idAccion = 2
        this.state.authorizeEngagementObject.usuario = this.state.PERFIL.username
        try {
            const res = await engagementService.postAutorizacion(this.state.authorizeEngagementObject);

            // actualizar el objeto compromiso con la autorizacion
            this.setState({ authorizeEngagementObject: res.data })
            console.log('Autorizacion de Compromiso Exitosa')
            console.log(this.state.authorizeEngagementObject)

            this.setState({ openModalClose: true })
            this.setState({ openModalAutorizacion: false })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }

    async postEngagementRec(): Promise<void> {

        this.state.authorizeEngagementObject.idAccion = 4
        this.state.authorizeEngagementObject.usuario = this.state.PERFIL.username

        try {
            const res = await engagementService.postAutorizacion(this.state.authorizeEngagementObject);

            // actualizar el objeto compromiso con el rechazo
            this.setState({ authorizeEngagementObject: res.data })
            console.log('Rechazo de Compromiso Exitoso')
            console.log(this.state.authorizeEngagementObject)

            this.setState({ openModalClose: true })
            this.setState({ openModalRechazo: false })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }

    async postNotificaRO(): Promise<void> {

        try {
            const res = await engagementService.postNotificaResponsableOficina(this.idCompromiso, this.idFiado);

            this.setState({ respuestaNotificacion: res.data })
            console.log(this.state.respuestaNotificacion)

            this.setState({ openModalResponsableOficina: true })
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
       
    }


    next(): void {
        this.setState({ count: this.state.count! + 1 })
    }

    back(): void {
        this.setState({ count: this.state.count! - 1 })
    }

    backRegresa(): void {

        this.setState({ count: this.state.count! - 1 })
        this.regresaCompromiso = true
    }

    //CONSUMO DE ENDPOINTS
    async getTipoCompromisos(): Promise<void> {

        const res = await engagementService.getTipo();
        this.setState({ listTipos: res.data });
    }

    async getFacultades(): Promise<void> {

        const producto = parseInt(this.state.engagementObject.producto)
        const monto = this.state.engagementObject.monto
        const ejecutivo = this.state.PERFIL.clave!

        const res = await engagementService.getFacultades(ejecutivo, producto, monto);

        this.setState({ conFacultades: res.data });

        if (this.state.conFacultades.codigo == 0) {
            console.log(this.state.conFacultades.descripcion)

        } else if (this.state.conFacultades.codigo == 1) {
            console.log(this.state.conFacultades.descripcion)
        }
    }

    async getClientAut(idCompromiso: number): Promise<void> {

        let externo = 0;

        const res = await engagementService.getClientAut(externo, idCompromiso);
        this.setState({ compromiso: res.data });
        console.log("Se obtiene la información del Compromiso con el ID: " + idCompromiso);

        let listaAuxiliar: ElementosCompromiso[];
        listaAuxiliar = []
        let listaAuxiliarB: BlockOption[];
        listaAuxiliarB = []
        let listaAuxiliarC: ConditionOption[];
        listaAuxiliarC = []

        let tipo: string;
        tipo = ''
        let monto: string;
        monto = ''
        let fechaComprometida: string | undefined;
        fechaComprometida = ''
        let fiadoS: string;
        fiadoS = ''

        if (this.state.compromiso.tipoCompromiso == 1) {
            tipo = "Apoyo"

            fechaComprometida = this.state.compromiso.fechaComprometida?.toString();

            for (const valor of this.state.compromiso.condiciones) {
                listaAuxiliarC.push({ value: valor.idCondicion, label: valor.descripcion })
            }

        } else if (this.state.compromiso.tipoCompromiso == 2) {
            tipo = "Bloqueo"

            fechaComprometida = this.state.compromiso.fechaComprometida?.toString();

            for (const valor of this.state.compromiso.bloqueos) {
                listaAuxiliarB.push({ value: valor.idBloqueo, label: valor.descripcion })
            }
        }
        this.setState({ ConditionOption: listaAuxiliarC })
        this.setState({ BlockOption: listaAuxiliarB })
        this.setState({ compromisoDetails: listaAuxiliar })
    }


    selectedBlocks(listBlocks: Bloqueos[]) {

        for (const elemento of listBlocks) {

            console.log("Lista de Bloqueos Recibidos: " + elemento.descripcion)

            if (elemento.descripcion.includes("GARANTIAS")) {
                this.setState(prevState => ({
                    engagementObject: { ...prevState.engagementObject, tipoBloqueo: 1 }
                }))
            }

            if (elemento.descripcion.includes("BURO")) {
                this.setState(prevState => ({
                    engagementObject: { ...prevState.engagementObject, tipoBloqueo: 2 }
                }))
            }
        }
        //SETTEA LA LISTA DE BLOQUEOS EN EL OBJETO ENGAGEMENT
        this.setState(prevState => ({
            engagementObject: { ...prevState.engagementObject, bloqueos: listBlocks }
        }))

        if (this.state.engagementObject.tipoCompromiso == 2 && this.state.engagementObject.bloqueos.length == 0) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgBloqueo: formatValue('') }
            }))
        }
    }

    selectedConditions(listConditions: Condiciones[]) {

        for (const elemento of listConditions) {

            console.log("Lista de Condiciones Recibidas: " + elemento.descripcion)

            if (elemento.descripcion.includes("Garantía")) {
                this.setState(prevState => ({
                    engagementObject: { ...prevState.engagementObject, tipoBloqueo: 1 }
                }))
            }

            if (elemento.descripcion.includes("Buró")) {
                this.setState(prevState => ({
                    engagementObject: { ...prevState.engagementObject, tipoBloqueo: 2 }
                }))
            }
        }
        //SETTEA LA LISTA DE CONDICIONES SELECCIONADAS EN EL OBJETO ENGAGEMENT
        this.setState(prevState => ({
            engagementObject: { ...prevState.engagementObject, condiciones: listConditions }
        }))

        if (this.state.engagementObject.tipoCompromiso == 1 && this.state.engagementObject.condiciones.length == 0) {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgCondicion: formatValue('') }
            }))
        }
    }

    selectedProducto(producto: string, conAlertaProduct: boolean) {

        console.log("Seleccionó el producto: " + producto + " " + conAlertaProduct)

        //SETTEA EL PRODUCTO SELECCIONADO EN EL OBJETO ENGAGEMENT
        this.setState(prevState => ({
            engagementObject: { ...prevState.engagementObject, producto: producto }
        }))

        if (producto != '') {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgProducto: formatValue('') }
            }))
        }
    }
/* 
    async getDestinatrio(): Promise<void> {

        const res = await engagementService.getDestinatario(this.idCompromiso);
        this.setState({ destinatario: res.data });
        console.log("Se obtiene el destinatario: " + this.state.destinatario.nombre + " " + this.state.destinatario.email)

    } */


    async getNumeroDeCompromisos(): Promise<void> {

        const res = await engagementService.getNumCompromisos(this.idFiado, this.state.authorizeEngagementObject.idCompromiso);
        const numCompromisosAux: NumeroCompromisos = res.data;
        console.log(res.data);
        this.setState({ listNumeroC: numCompromisosAux });

        this.setState({ numeroCompromisosApoyo: numCompromisosAux.compromisosApoyo })

        this.setState({ numeroCompromisosBloqueB: numCompromisosAux.compromisosBloqueoB })

        this.setState({ numeroCompromisosBloqueoG: numCompromisosAux.compromisosBloqueoG })

        this.setState({ numeroGarantia: numCompromisosAux.numeroGarantia })

        this.setState({ descripcionGarantia: numCompromisosAux.descripcionGarantia })
        //El tercer y cuarto compromiso por el mismo concepto para desbloqueo por Fiado, los deberá de autorizar el Responsable de Oficina.
        //El quinto y el sexto compromiso de desbloqueo por Fiado por el mismo concepto lo deberá de autorizar el Subcomité de Suscripción
        //A partir del séptimo compromiso de desbloqueo por Fiado por el mismo concepto lo deberá de autorizar el Comité de Suscripción.

        if (this.state.engagementObject.tipoCompromiso == 1) {

            console.log("TIPO COMPROMISO " + this.state.compromiso.tipoCompromiso + " POR APOYO")

            if (this.state.numeroCompromisosApoyo == 3 || this.state.numeroCompromisosApoyo == 4) {

                this.setState({ autorizaRO: true })
                this.setState({ autorizaComiteBuro: false })
                this.setState({ numCompromisos: this.state.numeroCompromisosApoyo })
                console.log('Enviado al Responsable de Oficina')

            } else if (this.state.numeroCompromisosApoyo == 5 || this.state.numeroCompromisosApoyo == 6) {

                this.setState({ autorizaSubComite: true })
                this.setState({ autorizaComiteBuro: false })
                console.log('Enviado al Subcomité de Suscripción')

            } else if (this.state.numeroCompromisosApoyo >= 7) {

                this.setState({ autorizaComite: true })
                this.setState({ autorizaComiteBuro: false })
                console.log('Enviado al Comité de Suscripción')
            }

            if (this.state.numeroCompromisosBloqueB >= 1) {

                this.setState({ autorizaComiteBuro: true })
                console.log('Enviado al Comité de Buró')
                this.setState({ autorizaRO: false })
                this.setState({ autorizaSubComite: false })
                this.setState({ autorizaComite: false })

            }

        }
        else if (this.state.engagementObject.tipoCompromiso == 2 && this.state.engagementObject.tipoBloqueo == 2) {

            console.log("TIPO COMPROMISO " + this.state.compromiso.tipoCompromiso + " POR BLOQUEO BURO")

            if (this.state.numeroCompromisosBloqueB >= 1) {

                this.setState({ autorizaComiteBuro: true })
                console.log('Enviado al Comité de Buró')
                this.setState({ autorizaRO: false })
                this.setState({ autorizaSubComite: false })
                this.setState({ autorizaComite: false })

            }

        }
        else if (this.state.engagementObject.tipoCompromiso == 2 && this.state.engagementObject.tipoBloqueo == 1) {

            console.log("TIPO COMPROMISO " + this.state.compromiso.tipoCompromiso + " -->  POR BLOQUEO GARANTIAS")
            console.log("CANTIDAD POR TIPO " + this.state.numeroGarantia)

            let descripcionAux: string
            descripcionAux = ""

            this.state.BlockOption.map((bloq, index) =>
                descripcionAux = bloq.label

            )
            console.log("TIPO DE GARANTIA COMPROMISO POR AUTORIZAR --> " + descripcionAux)

            if (descripcionAux.trim().includes(this.state.descripcionGarantia)) {

                if (this.state.numeroGarantia == 3 || this.state.numeroGarantia == 4) {

                    this.setState({ autorizaRO: true })
                    this.setState({ autorizaComiteBuro: false })
                    this.setState({ numCompromisos: this.state.numeroCompromisosApoyo })
                    console.log('Enviado al Responsable de Oficina Exitoso por tipo de Garantia: ' + this.state.descripcionGarantia)

                } else if (this.state.numeroGarantia == 5 || this.state.numeroGarantia == 6) {

                    this.setState({ autorizaSubComite: true })
                    this.setState({ autorizaComiteBuro: false })
                    console.log('Enviado al Subcomité de Suscripción por tipo de Garantia: ' + this.state.descripcionGarantia)

                } else if (this.state.numeroGarantia >= 7) {

                    this.setState({ autorizaComite: true })
                    this.setState({ autorizaComiteBuro: false })
                    console.log('Enviado al Comité de Suscripción por tipo de Garantia: ' + this.state.descripcionGarantia)

                }
            }

            if (this.state.numeroCompromisosBloqueoG == 3 || this.state.numeroCompromisosBloqueoG == 4) {

                this.setState({ autorizaRO: true })
                this.setState({ autorizaComiteBuro: false })
                //console.log('Enviado al Responsable de Oficina Exitoso')

            } else if (this.state.numeroCompromisosBloqueoG == 5 || this.state.numeroCompromisosBloqueoG == 6) {

                this.setState({ autorizaSubComite: true })
                this.setState({ autorizaComiteBuro: false })
                //console.log('Enviado al Subcomité de Suscripción')

            } else if (this.state.numeroCompromisosBloqueoG >= 7) {

                this.setState({ autorizaComite: true })
                this.setState({ autorizaComiteBuro: false })
                //console.log('Enviado al Comité de Suscripción')
            }
        }
    }
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});
export default connect(mapStateToProps)(FormCreateEngagmentsClase);


interface FormCreateEngagmentsState {

    autorizaRO: boolean;
    autorizaComite: boolean;
    autorizaComiteBuro: boolean;
    autorizaSubComite: boolean;
    numeroCompromisosApoyo: number;
    numeroCompromisosBloqueB: number;
    numeroCompromisosBloqueoG: number;
    listNumeroC: NumeroCompromisos;
    count?: number;
    PERFIL: UserModel;
    compromiso: Compromiso;
    engagementObject: Compromiso;
    reponseObject: CompromisoPOST;
    listTipos: TipoCompromiso[];
    openModalClose?: boolean;
    openModalAutorizacion?: boolean;
    openModalRechazo?: boolean;
    openModalResponsableOficina?: boolean;
    conFacultades: CodigoRespuesta;
    respuestaNotificacion: CodigoRespuesta;
    authorizeEngagementObject: AutorizaCompromiso;
    loading: boolean
    email: Correo;
    aceptaTerminos: boolean;
    compromisoDetails: ElementosCompromiso[];
    ConditionOption: ConditionOption[];
    BlockOption: BlockOption[];
    mensajesAlerta: Messages;
    correoFiado: string;
    destinatario: Destinatario;
    numeroGarantia: number;
    descripcionGarantia: string;
    numCompromisos: number;
}

interface Messages {
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgCorreo: string;
    msgFecha: string;
    msgMotivo: string;
}

interface BlockOption {
    value: number;
    label: string;
}

interface ConditionOption {
    value: number;
    label: string;
}