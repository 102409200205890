
import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../../reducer/LanguageReducer';
import { RootStore } from '../../../../Store';
import NavBar from '../../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../../class/LocalStorageClass';
import Select from '@lmig/lmds-react/Form/Select/Select';
import { Alert, AlphanumericInput, Button, GridCol, GridRow, IconDownload,  Table, TableCell, TableRow } from '@lmig/lmds-react';
import './Vconsult.scss'
import { BreadCumb } from '../../../../components/public/breadcumb/BreadCumb';
import { Entidad } from '../../../../models/buro-credito/entidad';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../../../services/buro-credito/CreditbureauService"
import * as apiS3Services from "../../../../services/api-s3/ApiS3Services";
import { ConsultaBuro } from '../../../../models/buro-credito/ConsultaBuro';
import { Fisicas } from '../../../../models/buro-credito/Fisicas';
import { Morales } from '../../../../models/buro-credito/Morales';
import { PaginacionModel } from '../../../../models/public/PaginacionModel';
import { initPaginacion } from '../../../../class/CommonClass';
import { PdfFile } from '../../../../models/buro-credito/PdfFile';
import { SpinnerLoading } from '../../../../components/public/spinner-loading/SpinnerLoading';
import { PipeLineIcon } from '../../../../components/public/pipeline-steps/PipelineIcon';
import { RegistroFormato, tableFormatDocument } from '../../../../models/buro-credito/Datos';
import Swal from 'sweetalert2';

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface ConsultVVProps {
    language: DefaultStateI
}
interface Params {
    folios: string;
}



class ConsultVV extends Component<ConsultVVProps & RouteComponentProps<Params>, ConsultVVState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    options: any;
    public propiedades: ConsultaBuro[] = [];

    public initDatosF: tableFormatDocument = {
        estatus: '',
        fechaAutorizacion: '',
        fechaCarga: '',
        idFormato: '',
        idStatus: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        statusCarta:'',
        idCartaUsada:'0'
    }


    public initRegistroFormat: RegistroFormato = {
        fechaAutorizacion: '',
        fechaCarga: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        folioConsulta:''
    }

    public folios: number;
    constructor(props: any) {
        super(props);
        this.state = {
            estadoCaptura: 3,
            idWarranty: 0,
            loadingWarrantyDetail: true,
            errorWarrantyDetail: false,
            select: '',
            status: 0,
            validador: 1,
            entidad: [],
            datovalid: '',
            folio: '',
            FormFisicas: new FormData(),
            datos: [],
            datofisica: [],
            datoMoral: [],
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            idFormatoDelete: "",
            PDFObject: this.initPDF,
            Loading_info: false,
            mensajes: '',
            search: '',
            calif: '',
            fechaAutorizacionFormato: '',
            fechaConsultaBuro: '',
            viewCalif: false,
            prueba: this.initDatosF,
            Consul_buro: false,
            modalUpdate: false,
            tableFormatodOC: [],
            errortablaformat: false,
            registroFormato: this.initRegistroFormat,
            isValid: false,
            isValid1: false,
            
            variable: {
                folio: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                colonia: '',
                estado: '',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                codigoPostal: '',
                calle: '',
                numexterior: '',
                delegacionMunicipio: '',
                numinterior: '',
                telefono: '',
                estadoDomicilio: '',
                razonSocial: '',
                representante: '',
                codigoBuro: ''
            }
        }

        this.folios = parseInt(this.props.match.params.folios);
        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.Endquery = this.Endquery.bind(this);
        this.downl = this.downl.bind(this);
        this.getDatos = this.getDatos.bind(this);
        this.getCredit = this.getCredit.bind(this);
        this.getConsultRes = this.getConsultRes.bind(this);

    }

    public initPDF: PdfFile = {
        carpeta: '',
        nombreArchivo: ''
    }

    render(): JSX.Element {
        return <>
        <NavBar />
            <div className="container-fluid warranty" id="consult-query">
                
                <div className="consult col-12  col-md-12">

                    <BreadCumb title={this.props.language.language?.createquery.credittitle!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito' },
                            { title: this.props.language.language?.createquery.consult!, url: '/' }
                        ]} />

                    <div className="row" id="header-consult">

                        <div className="container">

                            <div className="Pipeline col-md-12 col-sm-12" id='pipeline-2'>
                                <PipeLineIcon
                                    listSteps={[this.props.language.language?.createquery.pipeline1!,
                                    this.props.language.language?.createquery.pipeline2!,
                                    this.props.language.language?.createquery.pipeline4!]}
                                    currentStep={this.state.estadoCaptura! - 1} />


                            </div>
                        </div>



                        <div className="Formulario" id='formulario-consult'>


                            <>
                                <Form id="form-consult" >
                                    {this.state.estadoCaptura === 1 ? (
                                        <>
                                            <div className="col-md-12" id='consult'>
                                                <h3>{[this.props.language.language?.createquery.data!]}</h3>
                                                <hr />

                                                <div className='col-12'>
                                                    <p>{[this.props.language.language?.createquery.entity!]}</p>
                                                    <Select
                                                        id="select--default"
                                                        isRequired
                                                        placeholderOption="Prueba"
                                                        labelVisual={`${this.state.variable.tipoEntidad} `}
                                                        disabled

                                                    >

                                                    </Select>
                                                    <br />
                                                    <p>{[this.props.language.language?.createquery.person!]}</p>
                                                    <Select
                                                        id="Persona"
                                                        name='persona'
                                                        isRequired
                                                        labelVisual={this.state.variable.tipoPersona}
                                                        disabled

                                                    >


                                                    </Select>
                                                </div>
                                                <br />
                                                <>

                                                    <div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>
                                                            {this.state.variable.tipoPersona === 'Fisica' &&
                                                                <>
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.rfc}
                                                                        value=""
                                                                        required={true} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.name!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.nombre}
                                                                        value="" disabled />


                                                                    <p>{[this.props.language.language?.createquery.second!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.segundoNombre}
                                                                        value="" disabled />
                                                                    <p>{[this.props.language.language?.createquery.last!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.apellidoPaterno}
                                                                        value="" disabled />
                                                                    <p>{[this.props.language.language?.createquery.mother!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.apellidoMaterno}
                                                                        value="" disabled />

                                                                    <p>{[this.props.language.language?.createquery.date!]}</p>
                                                                    <input
                                                                        name="fechaInicial"
                                                                        id="fechaInicial"
                                                                        className="input-sdgf"
                                                                        placeholder={this.state.variable.fechaNacimiento}
                                                                        disabled />

                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.telefono}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.sex!]}</p>
                                                                    <Select
                                                                        id="select--default"
                                                                        isRequired
                                                                        labelVisual={this.state.variable.sexo}
                                                                        onChange={function placeholderOptionOnChange() { }}
                                                                        disabled
                                                                    >

                                                                    </Select>
                                                                </>}

                                                            {this.state.variable.tipoPersona === 'Moral' &&
                                                                <>
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.rfc}
                                                                        value=""
                                                                        required={true} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.razonsocial!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.razonSocial}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.representante!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.representante}
                                                                        value="" disabled />
                                                                    <br />

                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.telefono}
                                                                        value="" disabled />


                                                                </>}
                                                        </div>
                                                    </div><br /><div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>

                                                            <p>C.P</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.codigoPostal}
                                                                required={true} disabled />


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.state!]}</p>
                                                            <Select
                                                                id="select--default"
                                                                isRequired
                                                                labelVisual={this.state.variable.estadoDomicilio}
                                                                onChange={function placeholderOptionOnChange() { }}
                                                                disabled
                                                            >

                                                            </Select>

                                                            <p>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="select--default"
                                                                isRequired
                                                                labelVisual={this.state.variable.delegacionMunicipio}
                                                                onChange={function placeholderOptionOnChange() { }}
                                                                disabled
                                                            >

                                                            </Select>


                                                            <p>{[this.props.language.language?.createquery.street!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.calle}
                                                                value="" disabled />
                                                            <p>{[this.props.language.language?.createquery.number!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.numexterior}
                                                                value="" disabled />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.numinterior}
                                                                value="" disabled />


                                                            <br />
                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div></>

                                            </div>



                                        </>) : null}
                                    {this.state.estadoCaptura === 2 ? (
                                        <>
                                            <div className="col-12" onClick={this.downl}>
                                                <div className="card" id='cards'>
                                                    <div className="card-body text-center"><br /><br />
                                                        <h3 className="card-title">{this.props.language.language?.createquery.format!}</h3><br />
                                                        <div className="icon-wrapper">
                                                            <IconDownload size="64" color='gray' />
                                                        </div>
                                                        {this.props.language.language?.createquery.downl!}
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <br /> <br />



                                            <br /><br />
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                        {this.props.language.language?.createquery.NextPage!}
                                                    </Button>
                                                </GridCol>
                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura === 3 ? (
                                        <>

                                            {this.state.variable.tipoPersona === 'Fisica' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detallef!}</h3>
                                                    {this.state.errorWarrantyDetail ? (
                                                        <div className="col-12">
                                                            <Alert id='alert_up' highlightType="negative">
                                                                {this.state.mensajes}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <div className='col-12'>

                                                            <br />
                                                            {this.state.Loading_info ? (
                                                                <div className="col-12">
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :
                                                                <div className="tabla" id='tabla'>
                                                                    {this.state.viewCalif === true &&
                                                                        <>
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col"  >
                                                                                        <h5 id='calif'>{this.props.language.language?.createquery.fechaCarga!}: {this.state.fechaConsultaBuro}</h5>
                                                                                    </div>
                                                                                    <div className="col"  >
                                                                                        <h5 id='calif'>{this.props.language.language?.createquery.fechaAutorizacion!}: {this.state.fechaAutorizacionFormato}</h5>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <h5 id='calif'>{this.props.language.language?.createquery.calif!}: {this.state.calif}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    <Table id="table-credit-B col-12" className='tabla_buro' >
                                                                        <thead>
                                                                            <TableRow>
                                                                                <TableCell dataHeader="A" type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.credit!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader="B" type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.grantor!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader="C" type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.saldo!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader="D" type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.amount!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader="E" type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.method!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader="F" type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.max!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader="G" type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.update!}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </thead>
                                                                        {this.state.datofisica.map((value: Fisicas, index: number) => (

                                                                            <>
                                                                                <tbody>
                                                                                    <TableRow key={index}>
                                                                                        <TableCell dataHeader="A" type="rowHead">
                                                                                            {value.tipoCredito}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B">
                                                                                            {value.tipoOtorgante}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B">
                                                                                            {value.saldoActual}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B">
                                                                                            {value.monto}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B">
                                                                                            {value.formatoPago}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B">
                                                                                            {value.maxMorosMopFechaImporte}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B">
                                                                                            {value.ultimaActualizacion}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>

                                                                                    </TableRow>
                                                                                </tbody>
                                                                            </>
                                                                        ))}
                                                                    </Table>
                                                                </div>
                                                            }
                                                        </div>}
                                                </>}
                                            {this.state.variable.tipoPersona === 'Moral' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detalle!}</h3>
                                                    <br />
                                                    {this.state.errorWarrantyDetail ? (
                                                        <div className="col-12">
                                                            <Alert id='alert_up' highlightType="negative">
                                                                {this.state.mensajes}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <div className='col-12'>
                                                            {this.state.Loading_info ? (
                                                                <div className="col-12" id='tabla_pr'>
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :
                                                                <><div className='calif'>
                                                                    {this.state.viewCalif === true &&
                                                                        <>
                                                                            <div className="container-date" >
                                                                                <div className="row">
                                                                                    <div className="col"  >
                                                                                        <h5 id='calif'>{this.props.language.language?.createquery.fechaCarga!}: {this.state.fechaConsultaBuro}</h5>
                                                                                    </div>
                                                                                    <div className="col"  >
                                                                                        <h5 id='calif'>{this.props.language.language?.createquery.fechaAutorizacion!}: {this.state.fechaAutorizacionFormato}</h5>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <h5 id='calif'>{this.props.language.language?.createquery.calif!}: {this.state.calif}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                </div>
                                                                    <div className="tabla" id='tabla'>


                                                                        <Table id="table-credit-B col-12" className='tala_buro'>
                                                                            <thead>
                                                                                <TableRow>
                                                                                    <TableCell dataHeader="A" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.contrato!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="B" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.tipooto!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="C" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.tipocre!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="D" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.vgente!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="E" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.dia1!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="F" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.dia30!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.dia60!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.dia90!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.dia120!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.dia180!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.histo!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead" className='tb'>
                                                                                        {this.props.language.language?.createquery.actua!}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </thead>
                                                                            {this.state.datoMoral.map((value: Morales, index: number) => (

                                                                                <>

                                                                                    <tbody>
                                                                                        <TableRow key={index}>
                                                                                            <TableCell type="rowHead">
                                                                                                {value.id}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.tipoOtorgante}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.tipoCredito}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.saldoVigente}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido1a29}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido30a59}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido60a89}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido90a119}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido120a179}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido180}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.historicoPagos}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.fechaActualizado}
                                                                                            </TableCell>

                                                                                        </TableRow>
                                                                                        <TableRow>

                                                                                        </TableRow>
                                                                                    </tbody>
                                                                                </>
                                                                            ))}
                                                                        </Table>
                                                                    </div></>
                                                            }
                                                        </div>}
                                                </>}
                                            <br /><br />
                                            <div className="boton">
                                                <GridRow gutterSize="fixed-16" gutters>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth onClick={this.PrevPage}>
                                                            {this.props.language.language?.createquery.PrevPage!}
                                                        </Button>
                                                    </GridCol>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth variant="primary" onClick={this.Endquery}>
                                                            {this.props.language.language?.createquery.finalize!}
                                                        </Button>
                                                    </GridCol>
                                                </GridRow>

                                            </div>
                                        </>

                                    ) : null}


                                </Form></>




                        </div>

                    </div>
                </div>
            </div >
        </>


    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    public NextPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })
    }

    public PrevPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura - 1 })
    }

    public Endquery() {
        this.props.history.push('/buro-credito');
    }

    public downl() {
        this.getregistroFormatoBuro();
    }

    

    componentDidMount() {
        this.getCredit();
       // this.getDatos(this.state.registroFormato.nombreArchivoCarta);
        this.getConsultRes();

    }


    

    


   


    public getDatos(nombreArchivoCarta: string): void {

        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')

        } else {
            var data = storedata.replace(/['"]+/g, '')

            this.setState({ folio: data })
            this.setState({
                PDFObject: { carpeta: 'buro', nombreArchivo: this.state.registroFormato.nombreArchivoCarta + `.pdf` }
            })


        }
    }

    async getCredit(): Promise<void> {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getConsul(dt);

                this.setState({ variable: res.data })
            }
           
           
           
                
           
           
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    async getregistroFormatoBuro(): Promise<void> {
        try {
            console.log(this.state.variable.idFormato!)
            if( this.state.variable.idFormato!.toString() === '0'){
                const acti = await getCreditbureau.getregistroActivo(this.state.variable.folio!, this.state.variable.idFormato!)
                const actiA = acti.data
               
                this.setState({registroFormato:actiA})
                console.log(this.state.registroFormato.nombreArchivoCarta)
                this.setState({
                   PDFObject: { carpeta: 'buro', nombreArchivo: this.state.registroFormato.folioConsulta + `.pdf` }
                })
                 this.postURl();
            }
            const res = await getCreditbureau.getregistroFormatoBuro(this.state.variable.idFormato!);
            console.log(res.data)
            this.setState({ registroFormato: res.data })
            if (this.state.registroFormato.folioConsulta !== '') {
                console.log('entra qui al vacio')
                this.setState({
                    PDFObject: { carpeta: 'buro', nombreArchivo: this.state.registroFormato.folioConsulta + `.pdf` }
                 })
           //    this.getDatos(this.state.registroFormato.nombreArchivoCarta);
           //     console.log('entra aqui es difente a 0')
            }else  {
                this.setState({
                    PDFObject: { carpeta: 'buro', nombreArchivo: this.state.registroFormato.nombreArchivoCarta + `.pdf` }
                 })
            }
            
            
           this.postURl();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }

    public async postURl() {
        try {
            const res = await apiS3Services.postUrl(this.state.PDFObject);
            const urldata = JSON.stringify(res.data.url)
            const comillas = urldata.replace(/['"]+/g, '')
            console.log(comillas)
            var win = window.open(comillas, '_blank');
            win?.focus();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    public async getConsultRes() {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data

                this.setState({ Loading_info: true })
                const res = await getCreditbureau.getConsultRes(dt)
                if (this.state.variable.tipoEntidad === 'Fiado') {
                    this.setState({ viewCalif: true })
                } else {
                    this.setState({ viewCalif: false })
                }
                this.getCalifBuro();
                const ob = (res.data.creditos)
                this.setState({ datofisica: ob })
                this.setState({ datoMoral: ob })
                const mensaje = res.data.mensaje;
                this.setState({ mensajes: mensaje });

                if (res.data.creditos == null) {
                    this.setState({ errorWarrantyDetail: true })
                } else {
                    const ob = (res.data.creditos)
                    this.setState({ datofisica: ob })
                    this.setState({ loadingConsult: false })
                    this.setState({ errorWarrantyDetail: false })

                }
                this.setState({ Loading_info: false })


            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                this.setState({ errorWarrantyDetail: true })

                this.setState({ Loading_info: false })
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    public async getCalifBuro() {

        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getCalifBuro(dt)
                if (this.state.variable.tipoEntidad === 'Fiado') {
                    this.setState({ viewCalif: true })
                } else {
                    this.setState({ viewCalif: false })
                }
                if (res.data.calificacion !== null) {
                    const calif = res.data.calificacion
                    const fechAuto = res.data.fechaAutorizacionFormato
                    const fechConsul = res.data.fechaConsultaBuro
                    this.setState({ calif: calif })
                    this.setState({ fechaAutorizacionFormato: fechAuto })
                    this.setState({ fechaConsultaBuro: fechConsul })

                } else {
                    const calif = 'No aplica';
                    this.setState({ calif: calif })
                }
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    public async postFechaAutoEdit(fecha:string, folio:string) {
        try {
           
           
                await getCreditbureau.postFechAuto(fecha, folio)
                //this.posTablaFormato();
            
            
        } catch {

        }

    }

    public async putDeletetablaForm() {
        try {
            await getCreditbureau.putDeletetablaForm(this.state.idFormatoDelete);
           


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    public alertDeleteFormat() {

        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        Swal.fire({
            title: 'Estas seguro de eliminar el archivo?',
            text: "No podras recuperarlo",
            icon: 'warning',
            confirmButtonColor: '#008040',
            cancelButtonColor: '#D32F2F',
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Eliminado!',
                    'Tu archivo a sido borrado con exito',
                    'success'
                )
                this.putDeletetablaForm();
            }

        })
    }


}


const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(ConsultVV);


interface ConsultVVState {

    estadoCaptura: number;
    idWarranty: number,
    loadingWarrantyDetail: boolean,
    errorWarrantyDetail: boolean,
    select: string,
    status: number,
    validador: number,
    entidad: Entidad[],
    datovalid: string,
    folio: string,
    datos: ConsultaBuro[],
    datofisica: Fisicas[],
    datoMoral: Morales[],
    FormFisicas: FormData,
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    PDFObject: PdfFile,
    search: string,
    variable: ConsultaBuro,
    Loading_info: boolean,
    mensajes: string,
    calif: string,
    viewCalif: boolean,
    fechaAutorizacionFormato: string,
    fechaConsultaBuro: string,
    Consul_buro: boolean,
    prueba: tableFormatDocument,
    tableFormatodOC: tableFormatDocument[],
    idFormatoDelete: string,
    modalUpdate: boolean,
    errortablaformat: boolean,
    registroFormato: RegistroFormato,
    isValid: boolean,
    isValid1: boolean,
}



