import React, {useEffect, useState} from "react";
import './SelectProductosLinea.scss';
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {MultiSelect} from "react-multi-select-component";
import {ProductoLineaAfianzamiento} from "../../../models/garantias/ProductoLineaAfianzamiento";
import {OptionsMultiSelect} from "../../../models/public/OptionsMultiSelect";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {Ramos} from "../../../models/garantias/Ramos";
interface SelectProductosLineaProps {
    idFiado: number;
    clear: boolean;
    onChange: (values: ProductoLineaAfianzamiento[]) => void;
    selectedProductsDefault?:ProductoLineaAfianzamiento[];
}
export const SelectProductosLinea: React.FC<SelectProductosLineaProps> = ({idFiado,clear, onChange, selectedProductsDefault}:SelectProductosLineaProps)=> {

    const languageState = useSelector((state: RootStore) => state.language.language?.selectProductosLinea);

    const [listProducts, setListProducts] = useState<ProductoLineaAfianzamiento[]>([]);

    const [listProductsOptions, setListProductsOptions] = useState<OptionsMultiSelect[]>([]);

    const [listProductsSelected, setListProductsSelected] = useState<OptionsMultiSelect[]>([]);

    const [loadingProductos, setLoadingProductos] = useState<boolean>(false);

    const [errorProductos, setErrorProductos] = useState<boolean>(false);

    const [listRamos, setListRamos] = useState<Ramos[]>([]);

    const [loadingRamos, setLoadingRamos] = useState<boolean>(false);

    const [errorRamos, setErrorRamos] = useState<boolean>(false);

    const [listOptionsRamos, setListOptionsRamos] = useState<OptionsMultiSelect[]>([]);

    const [listRamosSelected, setListRamosSelected] = useState<OptionsMultiSelect[]>([]);

    useEffect(() => {
        getRamos();



    },[clear, selectedProductsDefault]);

    const getRamos = async () => {
        setLoadingRamos(true);
        setErrorRamos(false);
        try {
            const res = await garantiasService.getCatalogoRamos();
            let auxListRamosOptions: OptionsMultiSelect[] = [];
            // guardar los productos
            let  auxListRamos: Ramos[] = res.data;

            // guardarlos en el multiselect
            for (const ramo of auxListRamos) {
                auxListRamosOptions.push({value: ramo.idRamo.toString(), label: ramo.descripcion});
            }

            setListOptionsRamos(auxListRamosOptions);
            setListRamos(auxListRamos);
            setLoadingRamos(false);

            if (selectedProductsDefault){
                if (selectedProductsDefault!.length> 0){
                    onLoadDefault(auxListRamos, selectedProductsDefault!);
                }

            }


        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingRamos(false);
                setErrorRamos(true);
            }
        }
        // marcar los seleccionados

    }

    const onLoadDefault = (listRamos:Ramos[], selectedProductsDefault: ProductoLineaAfianzamiento[]) => {
        //obtener los ramos
        let ramosOptionSelected: OptionsMultiSelect[] = [];
        let ramosSelected: Ramos [] = [];

        for (const  ramo  of listRamos){
            const found = selectedProductsDefault.find(producto => ramo.idRamo == producto.idRamo);

           // console.log('el ramo ' + ramo.idRamo + ' ' + ramo.descripcion + ' se encuentra  ' +(found? ' si ': ' no'))
          //  console.log(found)
            if (found!= undefined){
                ramosOptionSelected.push({value: ramo.idRamo.toString(), label: ramo.descripcion})
                ramosSelected.push(ramo);
            }
        }

      //  console.log(ramosOptionSelected);
      //  console.log(ramosSelected);
        setListRamosSelected(ramosOptionSelected)

        getProductos(idFiado, ramosSelected, selectedProductsDefault);
    }
    const getProductos = async (idFiado: number, ramosSelected: Ramos[], selectedProductsDefault?: ProductoLineaAfianzamiento[]) => {
       // console.log(ramosSelected);
       // console.log(selectedProductsDefault);
        setLoadingProductos(true);
        setErrorProductos(false)
        try {
            const res = await garantiasService.getProductosLineaAfianzamiento(idFiado, ramosSelected);
            let auxListProductosOptions: OptionsMultiSelect[] = [];
            // guardar los productos
            let  auxListProducts: ProductoLineaAfianzamiento[] = res.data;
            let auxListListProductsSelected:  OptionsMultiSelect[] = [];

            console.log(auxListProducts);
            console.log(auxListListProductsSelected)
       
            // guardarlos en el multiselect
            for (const producto of auxListProducts) {
                const found = listProductsSelected.find(p => parseInt(p.value) == producto.idTipo)
               // console.log(found)
                if (found){
                    auxListListProductsSelected.push(found)
                }
                auxListProductosOptions.push({value: producto.idTipo.toString(), label: producto.idTipo + ' - '+ producto.nombre});

            }

            console.log(auxListProducts);
            console.log(auxListListProductsSelected);
            if (selectedProductsDefault){

                for (const prod of selectedProductsDefault){
                    auxListProducts.unshift(prod)
                    auxListProductosOptions.unshift({value: prod.idTipo.toString(), label: prod.nombre})
                    auxListListProductsSelected.unshift({value: prod.idTipo.toString(), label: prod.nombre})
                }

            }




            console.log(auxListProducts);
            console.log(auxListListProductsSelected)

            setListProducts(res.data);

            setListProductsOptions(auxListProductosOptions);

            setListProductsSelected(auxListListProductsSelected);
   
            setLoadingProductos(false);
        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingProductos(false);
                setErrorProductos(true)
            }
        }
    }

    const onSelectedOptions = (options: OptionsMultiSelect[]) => {

        const newListProductsSelected: ProductoLineaAfianzamiento[] = [];

        if (options.length > 0){
            options.forEach((option, index) => {

                const found =
                    listProducts.find(producto => producto.idTipo == parseInt(option.value))
              //  console.log(found)
                if (found){
                    newListProductsSelected.push(found)
                }
            })
            onChange(newListProductsSelected);
        }

        setListProductsSelected(options)
    }
    const onSelectedOptionsRamos = (options: OptionsMultiSelect[]) => {

        const newListRamosSelected: Ramos[] = [];

        if (options.length > 0){
            options.forEach((option, index) => {

                const found = listRamos.find(ramo => ramo.idRamo == parseInt(option.value))
                //  console.log(found)
                if (found){
                    newListRamosSelected.push(found)
                }
            })


            console.log(newListRamosSelected)
            getProductos(idFiado, newListRamosSelected);
        }else {
            setListProducts([]);
            setListProductsOptions([]);
            setListProductsSelected([]);
        }

        setListRamosSelected(options);



    }


    return (
       <>

           {/*List ramos selected: {JSON.stringify(listRamosSelected)}<br/>*/}
           {loadingRamos? (
                   <div className="w-100">
                       <SpinnerLoading
                           text={languageState?.loadingRamos}/>
                   </div>
               ):
               (errorRamos)? (
                       <div className="col-md-12">
                           <NegativeAlert
                               textAlert={languageState?.errorLoadingRamos!}/>
                       </div>
                   ):
                    <>
                        <label className="label-input">{languageState?.ramos!}:</label><br/>
                        <MultiSelect
                            options={listOptionsRamos}
                            value={listRamosSelected}
                            onChange={onSelectedOptionsRamos}
                            labelledBy={languageState?.ramos!} // <- for accessiblity
                            overrideStrings={{
                                "selectSomeItems": languageState?.selectBranch!,
                                "search": languageState?.searchBranch!,
                                "selectAll": languageState?.selectAllBranch!,
                                "allItemsAreSelected": languageState?.allBranchSelected!
                            }}
                        />
                    </>


           }

           {/*List productos selected: {JSON.stringify(listProductsSelected)}*/}
           {loadingProductos? (
                   <div className="w-100">
                       <SpinnerLoading
                           text={languageState?.loadingProducts}/>
                   </div>
               ):
               (errorProductos)? (
                       <div className="col-md-12">
                           <NegativeAlert
                               textAlert={languageState?.errorLoadingProducts!}/>
                       </div>
                   ):
                   listProducts.length> 0 &&
                   <>
                       <br/>
                       <label className="label-input">{languageState?.products!}:</label><br/>
                       <MultiSelect
                           options={listProductsOptions}
                           value={listProductsSelected}
                           onChange={onSelectedOptions}
                           labelledBy={languageState?.products!} // <- for accessiblity
                           overrideStrings={{
                               "selectSomeItems": languageState?.selectProduct!,
                               "search": languageState?.searchProduct!,
                               "selectAll": languageState?.selectAllProducts!,
                               "allItemsAreSelected": languageState?.allProductsSelected!
                           }}
                       />
                   </>


           }




       </>

    );
}