import React from 'react';
import {estadoVigenciaGarantia} from "../../../models/garantias/EstadoVigenciaGarantia";


interface ButtonStatusWarrantyProps {
    status: number;
    description: string;
}

export const ButtonStatusWarranty: React.FC<ButtonStatusWarrantyProps> = ({status, description}:ButtonStatusWarrantyProps)=> {

    return (
        <>
                {status == estadoVigenciaGarantia.enCaptura?(
                    <button type="button"
                            className="btn btn-en-espera rounded-pill">
                        {description}
                    </button>
                ): null}
                {status == estadoVigenciaGarantia.enviada?(
                    <button type="button"
                            className="btn btn-autorizar rounded-pill">
                        {description}
                    </button>
                ): null}
                {status == estadoVigenciaGarantia.autorizada?(
                    <button type="button"
                            className="btn btn-cumplido rounded-pill">
                        {description}
                    </button>
                ): null}
                {status == estadoVigenciaGarantia.rechazada?(
                    <button type="button"
                            className="btn btn-vencido rounded-pill">
                        {description}
                    </button>
                ): null}

                {status == estadoVigenciaGarantia.vencida?(
                    <button type="button"
                            className="btn btn-vencido rounded-pill">
                        {description}
                    </button>
                ): null}

                {status == estadoVigenciaGarantia.enRevision?(
                    <button type="button"
                            className="btn btn-informacion rounded-pill">
                        {description}
                    </button>
                ): null}

            {status == estadoVigenciaGarantia.sustituida?(
                <button type="button"
                        className="btn btn-sustituida rounded-pill">
                    {description}
                </button>
            ): null}
            {status == estadoVigenciaGarantia.baja?(
                <button type="button"
                        className="btn btn-baja rounded-pill">
                    {description}
                </button>
            ): null}
                &nbsp;


        </>
    );
};







