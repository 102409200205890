import React, {Fragment, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Button} from "@lmig/lmds-react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
interface Props {

}
export const PrevPage: React.FC<Props> = ({ }:Props) => {
    const history = useHistory();
    const languageState = useSelector((state: RootStore) => state.language);
    const prevPage =  () => {
        history.goBack()
    };


    return <>
            <Button dynamicWidth
                    onClick={prevPage}
                    variant="secondary">
                {languageState.language?.warranty.btnReturn}
            </Button>
         </>;
};

