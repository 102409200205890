export interface WarrantyHistoryLanguageModel {
  title: string;
  loan: string;
  show: string;
  compare: string;
  version: string;
  warranty: string;
  type: string;
  amount: string;
  dateCapture: string;
  validity: string;
  captured: string;
  authorized: string;
  changes: string;
  showing: string;
  showingTo: string;
  showingTotal: string;
  records: string;
  loadingHistory: string;
}

export const WarrantyHistoryLanguageEnglish: WarrantyHistoryLanguageModel = {
  title: "Warranty History",
  loan: "Loan",
  show: "Show",
  compare: "Compare",
  version: "Version",
  warranty: "Warranty",
  type: "Type",
  amount: "Weighted Amount",
  dateCapture: "Date of capture",
  validity: "Vigency",
  captured: "Captured",
  authorized: "authorized",
  changes: "Changes",
  showing: "Showing records from",
  showingTo: "to",
  showingTotal: "out of a total of",
  records: "records.",
  loadingHistory: "Loading History",
};
export const WarrantyHistoryLanguageSpanish: WarrantyHistoryLanguageModel = {
  title: "Historial de Garantía",
  loan: "Fiado",
  show: "Mostrar",
  compare: "Comparar",
  version: "Versión",
  warranty: "Garantía",
  type: "Tipo",
  amount: "Monto Ponderado",
  dateCapture: "Fecha de Captura",
  validity: "Vigencia",
  captured: "Capturó",
  authorized: "Autorizó",
  changes: "Cambios",
  showing: "Mostrando registros del",
  showingTo: "al",
  showingTotal: "de un total de ",
  records: "registros.",
  loadingHistory: "Cargando Historial",
};
