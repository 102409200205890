import React from "react";
import logo from '../../../assets/images/public/logo-web-liberty16.png';
import './Notfound.scss';
import NavBarPublic from "../../../components/public/nav-bar-public/NavBarPublic";

export const NotFound: React.FC = () => {

    return (
       <>
           <NavBarPublic/>
           <div className="container-fluid" id="not-found">
               <div className="col-md-12 offset-md-2 pt-4 text-md-left">
                   <img src={logo} alt="Logo Liberty" className="img-fluid"/>

                   <h1>Error 404</h1>
                   <h3>No se ha encontrado el sitio web.</h3>
               </div>
           </div>
       </>

    );
};

