export interface ModalDeleteOsLanguage {
  deleteOS: string;
  delete: string;
  deleting: string;
  cancel: string
}

export const ModalDeleteOsLanguageEnglish: ModalDeleteOsLanguage = {
    deleteOS: 'Delete OS',
    delete: 'Delete',
    deleting: 'Deleting',
    cancel: 'Cancel'
}
export const ModalDeleteOsLanguageSpanish: ModalDeleteOsLanguage = {
    deleteOS: 'Borrar Obligado Solidario',
    delete: 'Borrar',
    deleting: 'Borrando',
    cancel: 'Cancelar'
}
