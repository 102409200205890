import React, { useEffect, useState} from 'react';
import './CreateWarranty.scss';
import {
    AlphanumericInput,
    Button,
    Select,
    SelectOption,
    Toggle,
} from "@lmig/lmds-react";
import { useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import CurrencyInputSDGF from "../../public/currency-input-sdgf/CurrencyInputSDGF";
import {TextAlert} from "../../public/text-alert/TextAlert";
import {formatValue, InputChange, maxDate, minToday, todayDate, sumDate, dateFormat} from "../../../class/CommonClass";
import {PrevPage} from "../../public/prev-page/PrevPage";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {GarantiaModel} from "../../../models/garantias/GarantiaModel";
import {AxiosError} from "axios";
import {GarantiaDireccionada} from "../../../models/garantias/GarantiaDireccionada";
import * as apiService from "../../../services/api-gral/ApiGralService";
import {TiposUsoGarantias} from "../../../models/garantias/TiposUsoGarantias";
import {ClavesGarantias} from "../../../models/garantias/ClavesGarantias";
import {MonedaModel} from "../../../models/public/MonedaModel";
import {InfoObligadoSolidario} from "../../../models/garantias/InfoObligadoSolidario";
import {estadoCapturaGarantia} from "../../../models/garantias/EstadoCapturaGarantia";
import {estadoVigenciaGarantia} from "../../../models/garantias/EstadoVigenciaGarantia";
import {PorcentajePonderacion} from "../../../models/garantias/PorcentajePonderacion";

import * as apiGralService from "../../../services/api-gral/ApiGralService";
import {GarantiasMessages} from "../../../models/garantias/GarantiasMessages";
import {statesQuoteDoc} from "../authorization-doc-warranty/AuthorizationDocWarranty";
import {SearchAutocomplete} from "../../public/search-autocomplete/SearchAutocomplete";
import {ItemModel} from "../../../models/public/ItemModel";

interface CreateWarrantyProps {
    idFiado: number;
    idGarantia: number | null;
    disabledInputs: boolean;
    returnGetProgresoGarantia: (idGarantia: number) => void;
    returnOpenExpediente: (idGarantia: number) => void;
    returnPutProgresoGarantia: (idGarantia: number, estadoCaptura: number, estadoGarantia?: number) => void;
}
export const CreateWarranty: React.FC<CreateWarrantyProps> = ({idFiado,
                                                                  idGarantia,
                                                                  disabledInputs,
                                                                  returnGetProgresoGarantia,
                                                                  returnOpenExpediente,
                                                                  returnPutProgresoGarantia}:CreateWarrantyProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.createWarranty);

    const [loadingWarrantyDetail, setLoadingWarrantyDetail] = useState<boolean>(false);

    const [errorWarrantyDetail, setErrorWarrantyDetail] = useState<boolean>(false);

    const [garantia, setGarantia] = useState<GarantiaModel>(initialGarantiaState);

    const [listTiposUso, setListTiposUso] = useState<TiposUsoGarantias[]>([]);

    enum tiposUso {
        direccionada = 1,
        exclusiva = 2
    }

    const [listClaves, setListClaves] = useState<ClavesGarantias[]>([]);

    const [listMonedas, setListMonedas] = useState<MonedaModel[]>([]);

    const [listPercents, setListPercents] = useState<PorcentajePonderacion[]>([]);

    const [loadingDireccionada, setLoadingDireccionada] = useState<boolean>(false);

    const [errorDireccionada, setErrorDireccionada] = useState<boolean>(false);

    const [direccionada, setDireccionada] = useState<GarantiaDireccionada>(initialGarantiaDireccionada);

    const [isExclusiveForPrincipal, setIsExclusiveForPrincipal] = useState<boolean>(false);

    const [garantiaMsgs, setGarantiaMsg] = useState<GarantiasMessages>(initialGarantiaMsg);

    const [searchClientesExclusiva, setSearchClientesExclusiva] = useState<string>('');


    const [errorObligors, setErrorObligors] = useState<boolean>(false);

    const [loadingObligors, setLoadingObligors] = useState<boolean>(false);

    const [listObligors, setListObligors] = useState<InfoObligadoSolidario[]>([]);

    const [visibleMontoPonderado, setVisibleMontoPonderado] = useState<boolean>(false);

    const [recommendMontoPonderado, setRecommendMontoPonderado] = useState<number>(0);

    const [sendingGarantia, setSendingGarantia] = useState<boolean>(false);

    const [isRechazada, setIsRechazada] = useState<boolean>(false);


    const [itemsObligors, setItemsObligors] = useState<ItemModel[]>([]);

    const [obligorSelected, setObligorSelected] = useState<ItemModel>({id: null, name: ''});


    useEffect(() => {
        getTiposUso();
        getClaves();
        getMoneda();
        if (idFiado != 0){
          //  console.log('idFiado' + idFiado);
            const garantiaAux = garantia;
            garantiaAux.idCliente = idFiado;
            setGarantia(garantiaAux);

            if (idGarantia != null && idGarantia!= 0 ){
                getWarrantyDetail(idGarantia);
            }
        }
        
    },[idGarantia, idFiado]);

    const getTiposUso = async () => {
        const res = await garantiasService.getTipoUsoGarantias();
        setListTiposUso(res.data);
    }

    const getClaves = async () => {
        const res = await garantiasService.getClavestipo();
        setListClaves(res.data);
    }

    const getMoneda = async () => {
        const res = await apiService.getCatalogoMoneda();
        const listMonedas: MonedaModel[] = res.data;
        setListMonedas(listMonedas);
        // vamos a seleccionarle la primer lista de la moneda por default
        if (res.data.length>0) {
            setGarantia({...garantia, idMoneda: listMonedas[0].idMoneda});
        }
    }


    // traer el detalle de una garantia
    const getWarrantyDetail = async (idWarranty: number) => {
        setLoadingWarrantyDetail(true);
        setErrorWarrantyDetail(false);
        console.log('traer el detalle de la garantía: ' + idGarantia);
        try {
            const res = await garantiasService.getGarantiaDetail(idWarranty);
            console.log(res.data);
            const auxGarantiaObject: GarantiaModel = res.data;
            auxGarantiaObject.idCliente = idFiado;
            console.log(auxGarantiaObject);
            setLoadingWarrantyDetail(false);

            if (auxGarantiaObject.estadoVigencia === estadoVigenciaGarantia.rechazada){
                setIsRechazada(true);
            }

            setGarantia(auxGarantiaObject);
            // cargar las garantias direccionadas
            if (auxGarantiaObject.idTipoUso == tiposUso.direccionada){
                getGarantiaDireccionada(auxGarantiaObject.idGarantia!);
            }

            // cargar las fianzas exclusivas
            if (auxGarantiaObject.idTipoUso == tiposUso.exclusiva){
                if(auxGarantiaObject.clientesExclusiva.length == 0 ){
                    setIsExclusiveForPrincipal(false);

                }else if (auxGarantiaObject.clientesExclusiva.length == 1){
                    setIsExclusiveForPrincipal(true);
                    const obligadoSolidarioAux: InfoObligadoSolidario = auxGarantiaObject.clientesExclusiva[0]

                        getSearchOs(idFiado, 'all');
                        setObligorSelected({id: obligadoSolidarioAux.idCliente, name:obligadoSolidarioAux.razonSocial});


                }
            }

            // traer el porcentaje de ponderación
            putPorcentajePonderacion(auxGarantiaObject);



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingWarrantyDetail(false);
                setErrorWarrantyDetail(true);
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    const getGarantiaDireccionada = async (idGarantia: number) => {
        setLoadingDireccionada(true);
        setErrorDireccionada(false);

        try {
            const res = await garantiasService.getGarantiaDireccionada(idGarantia);

            setDireccionada(res.data);
            setLoadingDireccionada(false);
            setErrorDireccionada(true);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingDireccionada(false);
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }

    }

    const getSearchOs = async (idClient: number, searchOs: string) => {

        setLoadingObligors(true);
        setErrorObligors(false);

        try {
            const res = await apiGralService.getBusquedaClienteOs(idClient, searchOs);
            setListObligors(res.data.clientes)
            formatList(res.data.clientes);
            setLoadingObligors(false);
            setErrorObligors(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingObligors(false);
                setErrorObligors(true);

                //       document.getElementById("selectLoans")!.focus();
            }
        }
    }

    const putPorcentajePonderacion = async (garantiaObject: GarantiaModel) => {
        const res = await garantiasService.putPorcentajePonderacion(garantiaObject);
        const auxListPercents: PorcentajePonderacion [] = res.data;
        setListPercents(auxListPercents);
        let montoPonderadoAux: number = garantiaObject.montoPonderado;
        if (auxListPercents.length>0){
            for (const i of  auxListPercents){
                if (i.automatico){

                    if(garantiaObject.montoPonderado == 0){ // si no hay un monto ponderado
                        montoPonderadoAux= formatValue(garantia.monto!) * i.porcentajePonderacion;
                    }
                    montoPonderadoAux = Number(montoPonderadoAux.toFixed(2)); //  redondear a dos decimales
                    garantiaObject.porcentajePonderacion = i.porcentajePonderacion;
                    garantiaObject.montoPonderado = montoPonderadoAux;
                    setGarantia(garantiaObject);
                    setRecommendMontoPonderado(i.porcentajePonderacion);
                    setVisibleMontoPonderado(true);

                }
            }
            if (auxListPercents.length == 1 && auxListPercents[0].porcentajePonderacion == 0){
                setRecommendMontoPonderado(0);
                setVisibleMontoPonderado(false);
            }
        }else {
            setRecommendMontoPonderado(0);
        }
    }

    const saveWarranty = async () => {
        const garantiaAux = garantia;
        if (!disabledInputs){
            if (isValidWarranty(garantiaAux, true, true)){
                setSendingGarantia(true); // activar el spinner de cargando
                if (garantiaAux.idGarantia){  // si ya tiene el id de garantía solo hay que modificar
                    try {
                        let sutitucion = true;
                        if (isRechazada){ // si esta rechazada que se vaya sobre la misma
                            sutitucion = false;
                            reinitStatusQuote(garantiaAux.idGarantia);
                        }
                        const res = await garantiasService.putGarantia(garantiaAux,sutitucion);
                        const response: GarantiaModel = res.data;

                        if (!isRechazada){
                            if (response.version > garantiaAux.version){ // si ya no coincide el id  es porque hubo una sustitución
                                //actualizar el progreso
                                returnPutProgresoGarantia(response.idGarantia!,estadoCapturaGarantia.documentacion, estadoVigenciaGarantia.enCaptura);
                            }
                        }else {
                            returnPutProgresoGarantia(response.idGarantia!,estadoCapturaGarantia.documentacion, estadoVigenciaGarantia.enviada);
                        }

                        // actualizar el objeto garantia con el nuevo que creamos
                        setGarantia(response);
                        if (response.idTipoUso == tiposUso.direccionada){
                            updateDireccionada(res.data.idGarantia!);
                        }else{
                            setSendingGarantia(false);
                            // abrir el expediente
                            returnOpenExpediente(res.data.idGarantia!);
                        }


                    } catch (error) {
                        const err = error as AxiosError
                        if (err.response) {
                            console.log(err.response.status)
                            console.log(err.response.data)
                            setSendingGarantia(false);
                            setGarantiaMsg({...garantiaMsgs, msgGarantia: languageState?.iCantUpdate!});
                        }
                    }
                }else{ // Crear una nueva garantia
                    try {
                        const res = await garantiasService.postGarantias(garantiaAux);
                        // console.log(res.data)
                        const response: GarantiaModel = res.data;
                        // actualizar el objeto garantia con el nuevo que creamos
                        setGarantia(response);
                        if (response.idTipoUso == tiposUso.direccionada){
                            saveDireccionada(response.idGarantia!);
                        }else {
                            setSendingGarantia(false);
                            // abrir el expediente
                            returnOpenExpediente(response.idGarantia!);
                            //actualizar el progreso
                            returnPutProgresoGarantia(response.idGarantia!,estadoCapturaGarantia.documentacion, estadoVigenciaGarantia.enCaptura);
                        }


                    } catch (error) {
                        const err = error as AxiosError
                        if (err.response) {
                            console.log(err.response.status)
                            console.log(err.response.data)
                            setSendingGarantia(false);
                            setGarantiaMsg({...garantiaMsgs, msgGarantia: languageState?.iCantSave!});

                        }
                    }
                }

            }
        }else{
            setSendingGarantia(true);
            try {
                const res = await garantiasService.postGarantias(garantiaAux);
                // console.log(res.data)
                const response: GarantiaModel = res.data;
                // actualizar el objeto garantia con el nuevo que creamos
                setGarantia(response);
                setSendingGarantia(false);
                // abrir el expediente
                returnOpenExpediente(response.idGarantia!);
                //actualizar el progreso
                returnPutProgresoGarantia(response.idGarantia!,estadoCapturaGarantia.documentacion);

            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                    setSendingGarantia(false);
                    setGarantiaMsg({...garantiaMsgs, msgGarantia: languageState?.valdatePercent!});

                }
            }
            // abrir el expediente
            returnOpenExpediente(garantiaAux.idGarantia!);
        }

    }

    const saveDireccionada = async (idGarantia: number) => {
        const auxGarDireccionada: GarantiaDireccionada = direccionada;
        if (auxGarDireccionada.fianza! <= 0){
            auxGarDireccionada.fianza = null;
        }
        if (auxGarDireccionada.inclusion! <= 0){
            auxGarDireccionada.inclusion = null;
        }

        auxGarDireccionada.idGarantia = idGarantia;

        setSendingGarantia(true);
        try {
            const res = await garantiasService.postGarantiaDireccionada(auxGarDireccionada);
            setDireccionada(res.data);
            setSendingGarantia(false);
            // abrir el expediente
            returnOpenExpediente(idGarantia);
            //actualizar el progreso
            returnPutProgresoGarantia(idGarantia,estadoCapturaGarantia.documentacion, estadoVigenciaGarantia.enCaptura);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setSendingGarantia(false);
                setGarantiaMsg({...garantiaMsgs, msgGarantia: languageState?.warrantyNoSaved!});

            }
        }

    }

    const isValidWarranty =  (warranty: GarantiaModel, validarFechas: boolean, validarPorcentaje: boolean): boolean => {
        setGarantiaMsg(initialGarantiaMsg);
        if (!warranty.idTipoUso){
            setGarantiaMsg({...garantiaMsgs, msgTipo: languageState?.valSelectTypeUse!});
            return false;
        }


        if (warranty.idTipoUso == tiposUso.direccionada ){//si es direccionada
            const auxGarDirecc = direccionada;

        }

        const isExclusiveForPrincipalAux: boolean = isExclusiveForPrincipal;

        if (warranty.idTipoUso == tiposUso.exclusiva ){ // si es exclusiva y no tiene fiados
            if (isExclusiveForPrincipalAux && warranty.clientesExclusiva.length == 0){
                setGarantiaMsg({...garantiaMsgs, msgTipeExclusive: languageState?.valTypeUseExclusive!});
                return false;
            }
        }

        if (!warranty.idClave){
            setGarantiaMsg({...garantiaMsgs, msgClave: languageState?.valselectKey!});

            return false;
        }
        if (warranty.idClave == 350 && !warranty.importe_maximo){
            setGarantiaMsg({...garantiaMsgs, msgClave: languageState?.valMaxAmount!});

            return false;
        }
        if (!warranty.idMoneda){
            setGarantiaMsg({...garantiaMsgs, msgMoneda: languageState?.valselectCurrency!});
            return false;
        }
        if (warranty.monto <= 0 || !warranty.monto){
            setGarantiaMsg({...garantiaMsgs, msgMonto: languageState?.valenterAmount!});
            return false;
        }
        if (validarPorcentaje){
            if (visibleMontoPonderado){
                if (!warranty.porcentajePonderacion || !warranty.montoPonderado){
                    setGarantiaMsg({...garantiaMsgs, msgPorcentaje: languageState?.valdatePercent!});
                    return false;
                }
            }

        }

        if (validarFechas){
            if (warranty.fechaInicial.length != 10){
                setGarantiaMsg({...garantiaMsgs, msgFechaI: languageState?.noValidDateInit!});
            }

            if (!validFechaInicial(warranty.fechaInicial, warranty.fechaFinal)){
                return false;
            }
            if (warranty.fechaFinal.length != 10){
                setGarantiaMsg({...garantiaMsgs, msgFechaF: languageState?.noValidDateEnd!});
            }
            if (!validFechaFinal(warranty.fechaInicial, warranty.fechaFinal)){
                return false;
            }
        }
        return true;
    }

    const updateDireccionada = async (idGarantia: number) => {
        const auxGarDireccionada: GarantiaDireccionada = direccionada;
        if (auxGarDireccionada.fianza! <= 0){
            auxGarDireccionada.fianza = null;
        }
        if (auxGarDireccionada.inclusion! < 0){
            setSendingGarantia(false);
            // abrir el expediente
            returnOpenExpediente(garantia.idGarantia!);

        }else{
            auxGarDireccionada.idGarantia = idGarantia;
            try {
                const res = await garantiasService.putGarantiaDireccionada(auxGarDireccionada);
                setDireccionada(res.data);
                setSendingGarantia(false);
                // abrir el expediente
                returnOpenExpediente(idGarantia);

            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                    setSendingGarantia(false);

                    setGarantiaMsg({...garantiaMsgs, msgGarantia: languageState?.sorryNotsaveDir!});

                }
            }
        }
    }

    const handleInputChangeGarantia = (e: InputChange) => {
        const name = e.target.name;
        let value = e.target.value;

        switch (name){
            case 'fechaInicial':
                setGarantia({...garantia,
                    fechaInicial: value,
                    fechaFinal: sumFechaFinal(formatValue(value), garantia.fechaFinal)
                });
                break;
            case 'fechaFinal':
                    setGarantia({...garantia, fechaFinal: value });
                    changeFechaFinal(garantia.fechaInicial, formatValue(value));
                break;

            case 'idTipoUso':
                initMontoPonderado();
                if (parseInt(value) == tiposUso.exclusiva){// si es una fianza de tipo exclusiva
                    // inicializar
                    if (isExclusiveForPrincipal){
                        setGarantia({...garantia,clientesExclusiva: [],[name] : formatValue(value)});
                    }else {
                        setGarantia({...garantia,clientesExclusiva: [],[name] : formatValue(value)});
                    }
                }else{
                    setGarantia({...garantia,clientesExclusiva: [],[name] : formatValue(value)});
                }

                break;

            case 'idClave':
                initMontoPonderado();
                setGarantia({...garantia, montoPonderado: 0, porcentajePonderacion: 0, [name] : formatValue(value)});
                setVisibleMontoPonderado(true);
                setGarantiaMsg({...garantiaMsgs, msgPorcentaje: '', msgSelectAmmountNotRecommend: ''});
                setRecommendMontoPonderado(0);

                break;

            case 'idMoneda':
                initMontoPonderado();
                setGarantia({...garantia,[name] : formatValue(value)});

                break;


            case 'porcentajePonderacion':
                let montoPonderadoAux = formatValue(garantia.monto!) * parseFloat(value);
                montoPonderadoAux = Number(montoPonderadoAux.toFixed(2)); //  redondear a dos decimales
                setGarantia({...garantia, porcentajePonderacion : formatValue(value), montoPonderado: montoPonderadoAux });

                // mostrarle una alerta si selecciona otro porcentaje que no sea el recomendado
                if (montoPonderadoAux != recommendMontoPonderado ){
                    setGarantiaMsg({...garantiaMsgs, msgSelectAmmountNotRecommend: languageState?.msgAnyPercent!});
                }

                break;

            default:
                setGarantia({...garantia,[name] : formatValue(value)});
                break
        }
    }

    const handleInputChangeDireccionada = (e: InputChange) => {
        const name = e.target.name;
        const value = e.target.value;
        const  vaLueN: number = parseInt(value);
        let validNumber = true;

        switch (name){
            case 'fianza':
                if (!isNaN(Number(vaLueN))){ // validamos si lo que esta escribiendo es un numero válido

                    setDireccionada({...direccionada, [name]: vaLueN});
                }
                break;
            case 'inclusion':
                if (!isNaN(Number(value))){ // validamos si lo que esta escribiendo es un numero válido
                    if (vaLueN < 0 ){
                        validNumber = false;
                    }
                    setDireccionada({...direccionada, [name]: vaLueN});
                }
                break;
            default:
                break;
        }
    }

    const handleToogle = (e: any) => {
        const  auxIsExclusiveMe = isExclusiveForPrincipal? false: true;
        setIsExclusiveForPrincipal(auxIsExclusiveMe);
        setGarantia({...garantia, clientesExclusiva: []});
        if (auxIsExclusiveMe == true){
            getSearchOs(idFiado, 'all');
        }
    }


    const onSearchItem = (search: string) => {
        console.log(search)
        if (search.length>2){
            const selectedObligor = listObligors.find((i) => i.razonSocial == search);
            console.log(selectedObligor);
            if (selectedObligor){
                setObligorSelected({id: selectedObligor?.idCliente, name:selectedObligor.razonSocial});
                setGarantia({...garantia, clientesExclusiva: [selectedObligor!]});
            }else {
                setObligorSelected({id: null, name: ''});
                setGarantia({...garantia, clientesExclusiva: []});
            }

        }

    }
    const onSelectItem = (itemSelected: ItemModel) => {
        setObligorSelected(itemSelected);

        const selectedObligor = listObligors.find((i) => i.idCliente === itemSelected.id);
         setGarantia({...garantia, clientesExclusiva: [selectedObligor!]});

    }

    const onClearItem = () => {
        setObligorSelected({id: null, name: ''});
        setGarantia({...garantia, clientesExclusiva: []});

    }

    const validFechaInicial = (fechaInicial: string, fechaFinal: string):  boolean => {
        let initialDate = new Date(fechaInicial);

        //  console.log('fecha inicial: ' + fechaInicial.length);
        if (fechaInicial.length == 10){ // si esta llena la fecha inicial
            // si la fecha es menor al día de hoy
            //console.log('entro a las validaciones de fecha inicial');
        /*    if (initialDate < startDate){
                // le escribimos un mensaje
                setGarantiaMsg({...garantiaMsgs, msgFechaI: languageState?.minDateInit!});
                return false;
            }*/
            if (initialDate > maxDate ) {
                setGarantiaMsg({...garantiaMsgs, msgFechaI: languageState?.noValidDateInit!});
                return false;
            }
            else if (fechaFinal){
                // vamos a comparar si la fecha final no sea menor a la fecha inicial
                let finalDate = new Date(fechaFinal);
                if (finalDate < initialDate){
                    setGarantiaMsg({...garantiaMsgs, msgFechaI:languageState?.maxDateInit!});
                    return false;
                }else {
                    return true;
                }
            }
            else { // inicializamos el  mensaje
                setGarantiaMsg({...garantiaMsgs, msgFechaI: ''});
                return true;
            }
        }{
            return false;
        }
    }

    const validFechaFinal = (fechaInicial: string, fechaFinal: string): boolean => {

        let endDate = new Date(fechaFinal);
        const startDate = new Date(minDateFinish())

        if (fechaFinal.length == 10){ // si esta llena la fecha final

            // si la fecha final es menor a la inicial
            if (fechaInicial && endDate < startDate){
                // le escribimos un mensaje
                setGarantiaMsg({...garantiaMsgs, msgFechaF: languageState?.minDateEnd!});
                return false;
            }
            else if (endDate > maxDate){
                setGarantiaMsg({...garantiaMsgs, msgFechaF:  languageState?.noValidDateEnd!});
                return false;
            }
            else if (endDate <= todayDate){ // si la fecha final es menor o igual a hoy
                setGarantiaMsg({...garantiaMsgs, msgFechaF:  languageState?.minDateEndToday!});
                return false;
            }  else {
                setGarantiaMsg({...garantiaMsgs, msgFechaF: ''});
                return true;
            }
        }else {
            return false;
        }
    }

    const minDateFinish = (): string =>{
        let minDate: string = ''
        const fechaInicial = garantia.fechaInicial;

        if (fechaInicial){
            minDate = sumDate(fechaInicial, 2);
        }else {
            minDate = minToday();
        }

        return minDate;
    }


    const initMontoPonderado = () => {
        setGarantia({...garantia, montoPonderado: 0, porcentajePonderacion: 0});
        setVisibleMontoPonderado(true);
        setGarantiaMsg({...garantiaMsgs, msgPorcentaje: '', msgSelectAmmountNotRecommend: ''});
        setRecommendMontoPonderado(0);
    }

    const formatList = (listObligorsF: InfoObligadoSolidario []) => {
        let selectedObligorsF = garantia.clientesExclusiva;
        let itemObligorsAux: ItemModel[]  = []

        console.log(listObligorsF)
     //   console.log('lista Obligados: '+listObligorsF)

        for (let i = 0; i < listObligorsF.length; i++){
            itemObligorsAux.push({
                id: Number(listObligorsF[i].idCliente!),
                name: listObligorsF[i].razonSocial

            })

        }


        setItemsObligors(itemObligorsAux);
    }
    
    const sumFechaFinal = (fechaInicial: string, fechaFinal: string): string => {
        const idClave: number =  garantia.idClave;
        let returnFechaFinal: string = '';
        let returnMessage: string = '';
        let returnMessageMaxDateInit: string = '';
        let returnMessageF: string = '';
        // si esta completa la fecha inicial, podemos hacer la suma
        if (fechaInicial.length == 10){
            const [year,month, day] = fechaInicial.split('-');
            let newDateI = new Date(+year, +month - 1, +day);


            // console.log('fechaInicial' + newDateI);

            if (newDateI > maxDate ) {
                returnMessage = languageState?.noValidDateInit!;
            }
            else {
                returnMessage = '';
                if (idClave){
                    const found = listClaves.find((p) => p.idClave === idClave);
                    if (found){
                        const nDaysMeses = 30;
                        let diasSuma = 0;
                        if (found.mesesVigencia == 12){
                            diasSuma = 365
                        }else if (found.mesesVigencia > 0){
                            diasSuma = nDaysMeses * found.mesesVigencia;
                        }else if(found.mesesVigencia == 0){
                            diasSuma = +2;

                        }
                        console.log('dias suma ' + diasSuma);
                        // le sumamos N meses y lo seteamos en fecha final
                        returnFechaFinal = sumDate(fechaInicial, diasSuma);
                        if (new Date(returnFechaFinal) <= todayDate){
                            returnFechaFinal = sumDate(dateFormat, 2)
                        }

                        // vamos a comparar si la fecha final no sea menor a la fecha inicial
                        if (newDateI > new Date(returnFechaFinal)  ){
                            //alert('fas')
                            returnMessageMaxDateInit = languageState?.maxDateInit!;
                        }else {
                            if (new Date(returnFechaFinal) <= todayDate){ // si la fecha final es menor o igual a hoy
                                returnMessageF = languageState?.minDateEndToday!;

                            }else {
                                if (new Date(returnFechaFinal) > maxDate && found.mesesVigencia> 0){
                                    returnMessageF = languageState?.noValidDateEnd!;
                                    returnFechaFinal= ''
                                }else {
                                    returnMessageF = '';
                                }

                            }

                        }

                    }
                }
                else {
                    returnFechaFinal = '';
                }
            }


            setGarantiaMsg({...garantiaMsgs, msgFechaI: returnMessage, msgFechaF: returnMessageF, msgFechaMaxDateInit:returnMessageMaxDateInit});

            return returnFechaFinal;
        }else {
            return  ''
        }


    }

    const changeFechaFinal = (fechaInicial: string, fechaFinal: string) => {

        console.log('fechaFinal ' + fechaFinal);

        if (fechaFinal.length == 10){ // si esta llena la fecha final
            const [year,month, day] = fechaFinal.split('-');
            let endDate = new Date(+year, +month - 1, +day);
            const startDate = new Date(minDateFinish())
            let returnMessageF: string = '';
            console.log('end date ' + endDate);
            console.log('startDate ' + startDate);
           // si la fecha final es menor a la inicial
            if (fechaInicial && endDate < startDate){
                // le escribimos un mensaje
                setGarantiaMsg({...garantiaMsgs, msgFechaF: languageState?.minDateEnd!});

            }
            else if (endDate > maxDate){
                setGarantiaMsg({...garantiaMsgs, msgFechaF:  languageState?.noValidDateEnd!});

            }
            else if (endDate <= startDate){ // si la fecha final es menor o igual a hoy
                setGarantiaMsg({...garantiaMsgs, msgFechaF:  languageState?.minDateEndToday!});


            }  else {
                setGarantiaMsg({...garantiaMsgs, msgFechaF: '', msgFechaMaxDateInit:''});

            }

        }
    }

    const reinitStatusQuote = async (idGarantia: number) => {
      // reinicializar los status de la calificación

        // monto
        try {
            const res = await garantiasService.putCalificaMontoGarantia(statesQuoteDoc.enRevision,idGarantia, []);

        } catch (error) {
            const err = error as AxiosError
        }

        // monto ponderado
        try {
            const res = await garantiasService.putCalificaPorcentajeGarantia(statesQuoteDoc.enRevision,idGarantia,[]);

        } catch (error) {
            const err = error as AxiosError

        }

        // vigencía

        try {
            const res = await garantiasService.putCalificaVigenciaGarantia(statesQuoteDoc.enRevision,idGarantia, []);

        } catch (error) {
            const err = error as AxiosError
        }

    }


    return (
      <div id="create-warranty">
            {(loadingWarrantyDetail) ? (
                    <div className="col-12">
                        <SpinnerLoading text={languageState?.loadingCollaterals!}/>
                    </div>
                ) :
                (!errorWarrantyDetail)?(
                        <div className="row">
                            <div className="col-md-12">

                                <Select
                                    id="idTipoUso"
                                    isRequired
                                    name="idTipoUso"
                                    labelA11y={languageState?.selectTypeUse!}
                                    labelVisual={languageState?.selectTypeUse!}
                                    value={String(garantia.idTipoUso)}
                                    onChange={handleInputChangeGarantia}
                                    disabled={disabledInputs}>
                                    <SelectOption value="0" >
                                        {languageState?.selectOption!}
                                    </SelectOption>
                                    {
                                        listTiposUso.map((i, index) =>
                                            (  <SelectOption value={ i.idTipo!.toString()}
                                                             key={index}>
                                                    {i.descripcion}
                                                </SelectOption>
                                            )
                                        )
                                    }

                                </Select>
                                <NegativeAlert textAlert={garantiaMsgs.msgTipo}/>

                                {/*Si es una garantìa direccionada*/}
                                {(garantia.idTipoUso == tiposUso.direccionada)?(
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <AlphanumericInput
                                                    name="fianza"
                                                    type="number"
                                                    filter={/^([0-9]){1,10}$/}
                                                    min={0}
                                                    labelVisual={languageState?.enterBond}
                                                    value={String(direccionada.fianza)}
                                                    onChange={handleInputChangeDireccionada}
                                                    disabled={disabledInputs}
                                                />

                                                <NegativeAlert textAlert={garantiaMsgs.msgDirecFianza}/>

                                            </div>
                                            <div className="col-md-6">
                                                <AlphanumericInput
                                                    name="inclusion"
                                                    type="number"
                                                    filter={/^([0-9]){1,5}$/}
                                                    min={0}
                                                    labelVisual={languageState?.enterInclusion}
                                                    value={String(direccionada.inclusion)}
                                                    onChange={handleInputChangeDireccionada}
                                                    disabled={disabledInputs}
                                                />

                                                <NegativeAlert textAlert={garantiaMsgs.msgDirecInclusion}/>
                                            </div>

                                        </div>
                                    </>
                                ): null}

                                {/*Si es una garantìa exclusiva*/}
                                {(garantia.idTipoUso == tiposUso.exclusiva)?(
                                    <>
                                        {/*crear un garantía para estas personas o para mi mismo*/}
                                        <Toggle
                                            compact
                                            dynamicWidth
                                            onChange={handleToogle}
                                            defaultChecked={isExclusiveForPrincipal}
                                            name='isExclusiveMe'
                                            checked={isExclusiveForPrincipal}
                                            labelVisual={languageState?.exclusiveToMe!} />

                                        {isExclusiveForPrincipal?(
                                            <div id="clients-exclusive">
                                                <SearchAutocomplete
                                                    items={itemsObligors}
                                                    placeHolder={(obligorSelected.name)}
                                                    onSelect={onSelectItem}
                                                    onSearch={onSearchItem}
                                                    onClear={onClearItem}
                                                />


                                            </div>
                                        ): null}
                                        <NegativeAlert textAlert={garantiaMsgs.msgTipeExclusive}/>
                                    </>
                                ): null}

                                <Select
                                    id="idClave"
                                    isRequired
                                    labelA11y={languageState?.selectKey!}
                                    labelVisual={languageState?.selectKey!}
                                    name="idClave"
                                    onChange={handleInputChangeGarantia}
                                    value={String(garantia.idClave)}
                                    disabled={disabledInputs}>
                                    <SelectOption value="0" >
                                        {languageState?.selectOption!}
                                    </SelectOption>
                                    {
                                        listClaves.map((i, index) =>
                                            (  <SelectOption key={index} value={ i.idClave!.toString()}>
                                                    {i.descripcion}
                                                </SelectOption>
                                            ))
                                    }


                                </Select>

                                <NegativeAlert textAlert={garantiaMsgs.msgClave}/>

                                {(garantia.idClave == 25)?(
                                    <>
                                        <CurrencyInputSDGF
                                            value={garantia.monto}
                                            name="importe_maximo"
                                            placeholder={languageState?.maximumAmount!}
                                            onValueChange={
                                                (value, name) => {
                                                    setGarantia({...garantia, importe_maximo: value});
                                                    initMontoPonderado();
                                                }
                                            }
                                            disabled={disabledInputs}
                                        />

                                        <NegativeAlert textAlert={garantiaMsgs.msgImporteMax}/>
                                    </>

                                ): null}

                                <Select
                                    id="idMoneda"
                                    name="idMoneda"
                                    isRequired
                                    labelA11y={languageState?.selectCurrency!}
                                    labelVisual={languageState?.selectCurrency!}
                                    onChange={handleInputChangeGarantia}
                                    value={String(garantia.idMoneda)}
                                    disabled={disabledInputs}>
                                    {
                                        listMonedas.map((i, index) =>
                                            (  <SelectOption value={ i.idMoneda!.toString()} key={index}>
                                                    {i.abreviacion} -  {i.nombre}
                                                </SelectOption>
                                            ))
                                    }
                                </Select>

                                <NegativeAlert textAlert={garantiaMsgs.msgMoneda}/>

                            </div>


                            <div className="col-md-12">
                                <label className="label-input">{languageState?.enterAmount!}:</label>

                                <CurrencyInputSDGF
                                    value={garantia.monto}
                                    name="monto"
                                    placeholder=""
                                    msgLimit={languageState?.limitAmount!}
                                    disabled={disabledInputs}
                                    onValueChange={
                                        (value, name) => {
                                            setGarantia({...garantia, monto: value,montoPonderado: 0, porcentajePonderacion: 0});

                                        }
                                    }
                                />

                                <NegativeAlert textAlert={garantiaMsgs.msgMonto}/>

                            </div>
                            {(visibleMontoPonderado)?(

                                <div className="col-md-6">
                                    <label className="label-input">{languageState?.selectPercent!}:
                                        &nbsp;
                                        {(recommendMontoPonderado)?(
                                            <span className="text-info recommned-percent">
                                                *{languageState?.recommendPercent + ' ' + recommendMontoPonderado}%
                                            </span>
                                        ): null}
                                    </label>

                                    <Select
                                        id="porcentajePonderacion"
                                        name="porcentajePonderacion"
                                        isRequired
                                        labelA11y={languageState?.selectPercent!}
                                        labelVisual={languageState?.selectPercent!}
                                        onChange={handleInputChangeGarantia}
                                        onFocus={()=> {
                                            console.log('es valida la gaantía ' +isValidWarranty(garantia, false, false));


                                            if (isValidWarranty(garantia, false, false)
                                            ){
                                                putPorcentajePonderacion(garantia);
                                            }
                                        }}
                                        value={String(garantia.porcentajePonderacion)}
                                        disabled={disabledInputs}>
                                        <SelectOption value="0" >
                                            {languageState?.selectOption!}
                                        </SelectOption>
                                        {
                                            listPercents.map((i, index) =>
                                                (  <SelectOption value={ i.porcentajePonderacion!.toString()} key={index}>
                                                        {i.porcentajePonderacion}
                                                    </SelectOption>
                                                ))
                                        }
                                    </Select>
                                    <TextAlert textAlert={garantiaMsgs.msgSelectAmmountNotRecommend}/>
                                    <NegativeAlert textAlert={garantiaMsgs.msgPorcentaje}/>


                                </div>

                            ):null}

                            {(visibleMontoPonderado || garantia.montoPonderado > 0)?(

                                <div className="col-md-6">
                                    <label className="label-input">{languageState?.weightedAmount!}:</label>

                                    <input type="text"
                                           className="input-sdgf"
                                           name="montoPonderado"
                                           value={garantia.montoPonderado}
                                           disabled={true}/>

                                </div>
                            ): null}


                            <div className="col-md-6">
                                <label className="label-input">{languageState?.dateStart!}:</label>
                                <input
                                    type="date"
                                    name="fechaInicial"
                                    id="fechaInicial"
                                    className="input-sdgf"
                                    max="9999-12-31"
                                    value={garantia.fechaInicial}
                                    onChange={handleInputChangeGarantia}
                                    disabled={disabledInputs}
                                />

                                <NegativeAlert textAlert={garantiaMsgs.msgFechaI}/>
                                <TextAlert textAlert={garantiaMsgs.msgFechaMaxDateInit}/>

                            </div>

                            <div className="col-md-6">
                                <label className="label-input">{languageState?.dateEnd!}:</label>
                                <input
                                    type="date"
                                    name="fechaFinal"
                                    id="fechaFinal"
                                    className="input-sdgf"
                                    max="9999-12-31"
                                    min={minDateFinish()}
                                    value={garantia.fechaFinal}
                                    onChange={handleInputChangeGarantia}
                                    disabled={disabledInputs}
                                />

                                <NegativeAlert textAlert={garantiaMsgs.msgFechaF}/>

                            </div>

                            <NegativeAlert textAlert={garantiaMsgs.msgGarantia}/>

                            <div className="col-md-12 buttons-navigation">
                                <div className="row">
                                    <div className="col-md-6">
                                        <br/>
                                        <PrevPage/>
                                    </div>
                                    <div className="col-md-6">
                                        <br/>
                                        {!sendingGarantia ? (
                                                <Button dynamicWidth
                                                        variant="primary"
                                                        onClick={() => saveWarranty()}>
                                                    {languageState?.btnContinue!}
                                                </Button>
                                            ):
                                            <ButtonSpinner label={languageState?.btnSending!}/>
                                        }

                                    </div>
                                </div>

                            </div>

                        </div>
                    ):
                    <div className="col-12">
                        <NegativeAlert textAlert={languageState?.errorCollateral!}/>
                    </div>
            }
       </div>
    );
};


export const initialGarantiaDireccionada: GarantiaDireccionada = {
    fianza: 0,
    idGarantia:0,
    inclusion: 0
}

export const initialGarantiaMsg: GarantiasMessages = {
    msgGarantia: '',
    msgTipo:'',
    msgDirecFianza: '',
    msgDirecInclusion: '',
    msgTipeExclusive:'',
    msgClave:'',
    msgImporteMax: '',
    msgMoneda:'',
    msgMonto: '',
    msgFechaI:'',
    msgFechaMaxDateInit: '',
    msgFechaF:'',
    msgPorcentaje: '',
    msgSelectAmmountNotRecommend: '',
    msgVigency: ''
}

export const initialGarantiaState: GarantiaModel = {
    idGarantia: null,
    folio: null,
    version: 0,
    idCliente:  0,
    idClave:  0,
    descripcionClave: '',
    idTipoUso: 0,
    descripcionTipoUso: '',
    idMoneda: 0,
    monto: 0,
    montoPonderado: 0,
    porcentajePonderacion: 0,
    fechaCaptura: '',
    fechaInicial: '',
    fechaFinal:'',
    estadoVigencia: 0,
    clientesExclusiva: []
}
