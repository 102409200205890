import React, { useEffect, useState} from 'react';
import './PrincipalStatistics.scss';
import {RootStore} from "../../../Store";
import { useSelector} from "react-redux";
import {ProgressBar} from "react-bootstrap";
import {formatCurrency} from "../../../class/CommonClass";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {CifrasFiadoModel} from "../../../models/garantias/CifrasFiadoModel";
import { FaSquare } from 'react-icons/fa';
import {ADDOS} from "../../../actions/FiadoActionTypes";

interface PrincipalStatisticsProps {
    idPrincipal: number;
}


export const PrincipalStatistics: React.FC<PrincipalStatisticsProps> = ({ idPrincipal}:PrincipalStatisticsProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const fiadoAction = useSelector((state: RootStore) => state.fiadoAction.fiadoAction);

    const [loadingCifras, setLoadingCifras] = useState<boolean>(false);

    const [errorCifras, setErrorCifras] = useState<boolean>(false);

    const [percentFiado, setPercentFiado] = useState<number>(0);

    const [percentOs, setPercentOs] = useState<number>(0);

    const [cifras, setCifras] = useState<CifrasFiadoModel>({
        garantiasTotales: 0,
        fianzasFiado: 0,
        fianzasOS: 0,
        garantiasDisponibles: 0,
        detallesCifras: []
    });
    const getCifras = async (idFiado: number) => {
        setLoadingCifras(true);
        setErrorCifras(false);
        try {
            const res = await garantiasService.getCifrasFiado(idFiado);

            setCifras(res.data);
            setLoadingCifras(false);
            setErrorCifras(true);
            getPercentFiado(res.data.fianzasFiado, res.data.garantiasTotales)
            getPercentOs(res.data.fianzasOS, res.data.garantiasTotales)
        }
        catch (error) {
            setLoadingCifras(false);
            setErrorCifras(true);
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }
    const getPercentFiado = (fianzasFiado: number, garantiasTotales: number) => {

        let total =  (fianzasFiado*100)/garantiasTotales

        if (total> 0 && total<1){
            total =1;
        }

        //console.log(total)
        setPercentFiado(total);

    }
    const getPercentOs = (fianzasOS: number, garantiasTotales: number) => {

        let total =  (fianzasOS*100)/garantiasTotales


        if (total> 0 && total<1){
            total =1;
        }

       setPercentOs(total)

    }

    const updateFiadoAction = () => {
        switch (fiadoAction?.action) {
            case ADDOS:
                // traer la informaciòn del usuario
                getCifras(idPrincipal)
                break;
            default:
                // traer la informaciòn del usuario
                getCifras(idPrincipal)
                break;
        }
    }

    useEffect(() => {
        updateFiadoAction();
    },[idPrincipal, fiadoAction]);

    const printDato = (dato: string): JSX.Element => {
        const splitString = dato.split("|");
        return <span>{splitString[0]}<br/>
            <span className="fecha-cal">{(splitString[1]!= 'null')?splitString[1]: ''}</span></span>;
    }



    return (
        <>
            <div className="row" id="principal-statistics">




                <div className="col-md-12 ">
                    <div id="garantias-totales">
                        <b>Garantías Totales:</b> {formatCurrency(cifras.garantiasTotales)}
                    </div>
                    <ProgressBar>
                        <ProgressBar id="progress-fianzas-fiado"  now={percentFiado} key={1} />
                        <ProgressBar id="progress-fianzas-os" now={percentOs} key={2} />
                    </ProgressBar>
                    <div className="row">
                        <div className="col-md-4 text-right">
                            <b><FaSquare id="squareFiado"/> Fianzas del Fiado:</b>
                            <div className="">{formatCurrency(cifras.fianzasFiado)}</div>
                        </div>
                        <div className="col-md-3 text-right">
                            <b><FaSquare id="squareOs"/> Fianzas Os:</b>
                            <div className="">{formatCurrency(cifras.fianzasOS)}</div>
                        </div>
                        <div className="col-md-5 text-right">
                            <b> <FaSquare id="squareDisponibles"/> Garantías disponibles:</b>
                            <div className="">{formatCurrency(cifras.garantiasDisponibles)}</div>
                        </div>

                    </div>

                    <div className="row counters">

                        {
                            cifras.detallesCifras.map((i, index) =>
                                (    <div key={index} className="col-md-2 text-center">
                                        {i.descripcion}<br/>
                                        {printDato(i.dato)}

                                    </div>
                                ))
                        }
                    </div>
                </div>

            </div>
        </>
    );
};

