import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import './ExpedienteMantenimiento.scss';
import {
    Badge
} from "@lmig/lmds-react";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import {MAXSIZEUPLOADFILE, openNewWindow, validFileImg, validRoles} from "../../../class/CommonClass";
import * as supportService from "../../../services/support/SupportService";
import {AxiosError} from "axios";
import {RootStore} from "../../../Store";
import { useSelector} from "react-redux";
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import {TypeDocModel} from "../../../models/garantias/TypeDocModel";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {ModalFileMaxUpload} from "../../../components/public/modal-file-max-upload/ModalFileMaxUpload";
import {DocumentoMantenimiento} from "../documento-expediente-mantenimiento/DocumentoExpedienteMantenimiento";

interface ExpedienteMantenimientoProps {
    idPrincipal: number;
}


export const ExpedienteMantenimiento: React.FC<ExpedienteMantenimientoProps> = ({ idPrincipal}:ExpedienteMantenimientoProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const [listExpediente, setListExpediente] = useState<TypeDocModel []>([]);

    const [loadingExpedient, setLoadingExpedient] = useState<boolean>(true);

    const [errorExpediente, setErrorExpediente] = useState<boolean>(true);

    const [expedientComplete, setExpedientComplete] = useState<boolean>(false);

    const [idTypeDestinity, setIdTypeDestinity] = useState<number>(0);
    


    const [openModalExcedLimitFile, setOpenModalExcedLimitFile] = useState<boolean>(false);

    const [draggable, setDraggable] = useState<boolean>(true);


    useEffect(() => {
        getExpedientIdentification(idPrincipal);
        setDraggable(true);

    },[idPrincipal]);

    const getExpedientIdentification =  async (idWarranty: number) => {
        setLoadingExpedient(true);
        setErrorExpediente(false);
        try {
            const res = await supportService.getExpedienteIdentificacion(idPrincipal);
            await reformatExpedientIdentificaction(res.data);
            setLoadingExpedient(false);
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingExpedient(false);
                setErrorExpediente(true);

            }
        }
    }

    // reformatear y reorganizar por categorias tomando idTipoDocumento como criterio
    const reformatExpedientIdentificaction = (listExpedientes: ExpedienteIdetificacionModel[]) => {

        const listDocuments: TypeDocModel [] = []

        let expedientAprove = true;

        listExpedientes.map((i, index) =>
            {

                // encontramos un documento igual
                const auxDoc: ExpedienteIdetificacionModel = i
                let documento: TypeDocModel ={
                    id: i.idTipoDocumento,
                    nombre: i.descripcion,
                    documents: [auxDoc]
                };

                for (let j= listExpedientes.length-1; j> index; j--){
                    // console.log(i.descripcion);
                    //  console.log(i.idTipoDocumento +'=='+ listExpedientes[j].idTipoDocumento)
                    if (i.idTipoDocumento == listExpedientes[j].idTipoDocumento){

                        // encontramos un documento igual
                        const auxDoc: ExpedienteIdetificacionModel = listExpedientes[j]
                        documento.documents.push(auxDoc);
                        // borrar el registro
                        listExpedientes.splice(j,1);

                    }
                }

                listDocuments.push(documento);

            }
        )

        // verificar que todos los documents sean aprobados o esten guardados en docuware
        for(const expediente of listDocuments){
            for(const doc of expediente.documents){
                if (doc.estado == statesDocIdentification.autorizado || doc.estado == statesDocIdentification.guardadoDW){

                }else {
                    expedientAprove = false
                    break;
                }
            }
            if (!expedientAprove){
                break
            }
        }

        setListExpediente(listDocuments);
        setLoadingExpedient(false);
        setExpedientComplete(expedientAprove)

    }





    return (
        <>
            <div className="" id="expediente-mantenimiento">


                <div className="col-md-12 status-expedient">
                    {expedientComplete?
                        <Badge className="justify-content-end" highlightType="positive">
                            {languageState?.language?.expedientIdentificaction.ExpedientComplete}
                        </Badge> :
                        <Badge className="justify-content-end" compact highlightType="negative">
                            {languageState.language?.expedientIdentificaction.ExpedientIncomplete}
                        </Badge>
                    }
                </div>
                {loadingExpedient?(
                        <SpinnerLoading/>
                    ):
                    <>
                        { errorExpediente?(
                                <NegativeAlert
                                    textAlert={languageState?.language?.expedientIdentificaction.ExpedientComplete!}/>
                            ):
                            <div className="card ">

                                {
                                    !loadingExpedient?
                                        listExpediente.map((iTypeDoc, index) =>
                                            (
                                                <div key={index}>
                                                    <DocumentoMantenimiento
                                                        idPrincipal={idPrincipal}
                                                        typeDoc={iTypeDoc}
                                                        index={index}
                                                    />
                                                </div>

                                            )) :
                                        <div>
                                            <SpinnerLoading text={languageState.language?.expedientIdentificaction.loadingExpedient}/>
                                        </div>
                                }
                            </div>
                        }
                    </>



                }


                <ModalFileMaxUpload
                    openModal={openModalExcedLimitFile}
                    maxSize={MAXSIZEUPLOADFILE}
                    clickShowModal={show1 => {
                        //alert(show1)
                        setOpenModalExcedLimitFile(show1)
                    }}/>


            </div>

        </>
    );
};

