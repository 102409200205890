export interface ReporteExpedienteLanguage {
    title: string;
    generateReport: string;
    generatingReport: string;
    dateInit: string;
    dateEnd: string;
    selectExecutive: string;
    noValidDateInit: string;
    noValidDateEnd: string;
    maxDateInit: string;
    minDateEnd: string;
    maxDateEnd: string;
}

export const reporteExpedienteEnglish: ReporteExpedienteLanguage ={
    title: 'Report Expedient',
    generateReport: 'Generate Report',
    generatingReport: 'Generating Report',
    dateInit: 'Start date',
    dateEnd: 'Final date',
    selectExecutive: "Select an Account Executive",
    noValidDateInit: 'The Start date is not valid, please enter it again.',
    noValidDateEnd: 'The End Date is not valid, please enter it again.',
    maxDateInit: 'The Start Date cannot be greater than the End Date, please enter it again.',
    minDateEnd: 'The end date cannot be less than to the start date.',
    maxDateEnd: '',
}
export const reporteExpedienteSpanish: ReporteExpedienteLanguage ={
    title: 'Reporte Expediente',
    generateReport: 'Generar Reporte',
    generatingReport: 'Generando Reporte',
    dateInit: 'Fecha de Inicio ',
    dateEnd: 'Fecha Final',
    selectExecutive: "Selecciona un Ejecutivo de Cuentas",
    noValidDateInit: 'La Fecha Inicial no válida, por favor ingrésala nuevamente.',
    noValidDateEnd: 'La Fecha Final no válida, por favor ingrésala nuevamente.',
    maxDateInit: 'La Fecha Inicial no puede ser mayor a la Fecha Final, por favor ingrésala nuevamente.',
    maxDateEnd: '',
    minDateEnd: 'La fecha Final no puede ser menor a la Fecha Inicial.',

}