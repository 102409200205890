
export interface HomeLanguage {
    title: string;
    textBanner: string;
    item1: string;
    item2: string;
    item3: string;
    item4: string;
    item5: string;
    showMore: string;
    li1:string;
    li2:string;
    li3:string;
    li4:string;
    li5:string;
    li6:string;
    li7:string;
    li8:string;
    li9:string;
    li10:string;
    li11:string;
    t1:string;
    source: string;
    supportTitle: string;
    supportText1: string;
    supportText2: string;
    supportText3: string;
    contactTitle: string;
    contactText: string;
    quoteTitle: string;
    quoteText: string;
}

export const HomeLanguageEnglish: HomeLanguage ={
    title: 'Liberty Fianzas',
    textBanner: 'Company with outstanding results in the Mexican Surety Sector,\n' +
        'with upward trajectory and now acquired by Liberty Mutual Group leading company\n' +
        'worldwide, changing its name to Liberty Fianzas in 2016.',
    item1: 'Leading insurer worldwide',
    item2: '#78 in the Fortune Magazine Ranking in 2021',
    item3: 'More than 45,000 employees around the world',
    item4: 'Insurance for Companies, Personal and International',
    item5: '29 Countries and economies around the world',
    showMore: 'Learn More',
    li1: 'Leading Insurance Company worldwide.*',
    li2: 'The company is among the top 100 largest companies in the United States\n according to the list of the first 500 companies of Fortune Magazine.*',
    li3: 'It has more than 45,000 employees around the world through 900 offices.*',
    li4: 'It is divided into 4 main business units:',
    li5: 'Personal Insurance',
    li6: 'Business Insurance',
    li7: 'International Insurance',
    li8: 'Global Specialty',
    li9: 'The Bonding Company is recognized as the second largest in the United States.*',
    li10: 'It has a worldwide presence.*',
    li11: 'It has subscribers trained to issue bonds in various countries.',
    t1: 'To learn more about Liberty Mutual Group, click on',
    source: 'Source official site:',
    supportTitle: 'System Technical Support.',
    supportText1: 'Contact the phone',
    supportText2: 'CDMX, or metropolitan area,',
    supportText3: 'option 6,\nMonday to Thursday from 9:00 a.m. to 3:00 p.m. and from 4:00 p.m. to 6:00 p.m. and Friday\nfrom 9:00 a.m. to 4:30 p.m.',
    contactTitle: 'Contact',
    contactText: 'Contact one of our executives through the contact form.',
    quoteTitle: 'Quoter',
    quoteText: 'If you require a quote please\ndo it through the quote form'



}
export const HomeLanguageSpanish: HomeLanguage ={
    title: 'Liberty Fianzas',
    textBanner: 'Empresa con resultados sobresalientes en el Sector Afianzador Mexicano,\n' +
        'con trayectoria ascendente y ahora adquirida por Liberty Mutual Group compañía líder\n' +
        'a nivel mundial, cambiando su nombre a Liberty Fianzas en el 2016.',
    item1: 'Aseguradora Líder a nivel mundial',
    item2: ' # 78 en el Ranking de la Revista Fortune en el 2021',
    item3: 'Más de 45,000 empleados alrededor del mundo',
    item4: 'Seguros para Empresas, Personales e Internacionales',
    item5: 'Presencía en 29 Países y economías alrededor del mundo',
    showMore: 'Ver más',
    li1: 'Aseguradora Líder a nivel mundial.*',
    li2: 'La compañía se encuentra entre las primeras 100 compañías más grandes de Estados Unidos\nde acuerdo al listado de las primeras 500 compañías de la Revista Fortune.*',
    li3: 'Tiene a más de 45,000 empleados alrededor del mundo a través de 900 oficinas.*',
    li4: 'Se encuentra dividida en 4 principales unidades de negocio:',
    li5: 'Seguros Personales',
    li6: 'Seguros para Empresas',
    li7: 'Seguros Internacionales',
    li8: 'Especialidad Global',
    li9: 'La Afianzadora es reconocida como la segunda más grande de Estados Unidos.*',
    li10: 'Tiene presencia a nivel mundial.*',
    li11: 'Cuenta con suscriptores capacitados para la emisión de fianzas en diversos países.',
    t1: 'Para saber más de Liberty Mutual Group da click en',
    source: 'Fuente:',
    supportTitle: 'Soporte Técnico del Sistema.',
    supportText1: 'Comuníquese al teléfono ',
    supportText2: 'CDMX, o área metropolitana,',
    supportText3: 'opción 6,\nde lunes a jueves de 9:00 a 15:00 y de 16:00 a 18:00 y viernes\nde 9:00 a 16:30 horas.',
    contactTitle: 'Contacto',
    contactText: 'Contáctese con alguno de nuestros ejecutivos a través del formulario de contacto.',
    quoteTitle: 'Cotizador',
    quoteText: ' Si requiere una cotización favor de\nhacerlo mediante el formulario del cotizador'

}