import React, {Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import {
    AlphanumericInput, Button,
    Checkbox,
    CheckboxGroup,
    IconErrorAlert, IconSearch,
    IconSeeMore,
    IconSuccessAlert
} from "@lmig/lmds-react";
import { Link } from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import './WarrantiesReview.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {initPaginacion, InputChange, validRoles} from "../../../class/CommonClass";
import {AxiosError} from "axios";
import {GarantiasPendientesModel} from "../../../models/garantias/GarantiasPendientesModel";
import {GarantiasRevisionModel} from "../../../models/garantias/GarantiasRevisionModel";
import {GarantiasfinalizadasModel} from "../../../models/garantias/GarantiasFinalizadasModel";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import SelectTypeUser, {
    initTipoPerfil,
    TypeProfileModel
} from "../../../components/garantias/select-type-user/SelectTypeUser";
import {FooterWarrantyReview} from "../../../components/garantias/footer-card-warranty-review/FooterWarrantyReview";
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {CheckBoxReviewEnum} from "../../../models/garantias/CheckBoxReviewEnum";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import SelectUserReview from "../../../components/garantias/select-user-review/SelectUserReview";

interface WarrantiesReviewProps{
    language: DefaultStateI
}
class WarrantiesReview extends Component<WarrantiesReviewProps & RouteComponentProps, WarrantiesReviewState>{

    public selectIndexPendient: null | number;

    public localStorageClass = new LocalStorageClass();
    public iniFiltersSearchReview: FilterSearchReview = {
        pendients: false,
        review:  false,
        finish: false
    };
    constructor(props: any) {
        super(props);
        document.title = "Revisión de Garantías"
        this.state = {
            PERFIL: this.localStorageClass.getProfile(),
            typeProfile: initTipoPerfil,
            paginacionPendients: initPaginacion,
            listPendients: [],
            loadingListPendients: true,
            errorListPendients: true,
            paginacionRevision: initPaginacion,
            listRevision: [],
            loadingListRevision: true,
            errorListRevision: false,
            paginacionFinish: initPaginacion,
            listFinish: [],
            loadingListFinish: true,
            errorListFinish: false,
            searchPrincipals: '',
            filtersSerach: this.iniFiltersSearchReview,
            draggable: true
        }
        //binding in constuctor
       // this.uploadEnRevision= this.uploadEnRevision.bind(this);
        this.selectIndexPendient=null;
        this.drag = this.drag.bind(this);
        this.drop = this.drop.bind(this)
        this.handleScroll = this.handleScroll.bind(this);
        this.handleInputChangeSearch = this.handleInputChangeSearch.bind(this);
        this.handleInputChangeCheckbox = this.handleInputChangeCheckbox.bind(this);

    }

    render(): JSX.Element {
        return <>
            <NavBar/>
            <div className="container-fluid" id="warranties-review">
            

                <div className="waranty-container">

                    <BreadCumb
                        title={this.props.language.language?.warrantiesReview.title!}
                        listBreadcumbs={[
                            {title: this.props.language.language?.warrantiesReview.home!, url:'/garantias/'},
                            {title: this.props.language.language?.warrantiesReview.title!, url:this.props.location.pathname}]}
                    />

                    <div className="seccion-botons">
                        <div className="row">
                            <div className="col-12">
                                <SelectUserReview reciveTypeProfile={
                                    (tipoPerfil: TypeProfileModel) =>{
                                        this.loadDashboard(tipoPerfil)
                                    }
                                }/>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-7"/>
                            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                <AlphanumericInput
                                    className="float-right w-100"
                                    labelVisual={this.props.language.language?.warrantiesReview.searchPrincipals!}
                                    labelA11y={this.props.language.language?.warrantiesReview.searchPrincipals!}
                                    name="search"
                                    autoComplete={"off"}
                                    onChange={this.handleInputChangeSearch}
                                    value={this.state.searchPrincipals}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-3 col-lg-2 ">
                                <Button  variant="primary"
                                         className="float-right w-100 btn-search-principals"
                                         onClick={()=> {this.serchPrincipals()}}>
                                    <IconSearch size="16" />&nbsp; {this.props.language.language?.warrantiesReview.searchButton!}
                                </Button>
                            </div>

                            <div className="col-12 col-sm-12 col-md-7"></div>

                            <div className="col-12 col-sm-12 col-md-5">
                                <CheckboxGroup>
                                    <div className="row">
                                        <div className="col-4">
                                            <Checkbox
                                                labelVisual={this.props.language.language?.warrantiesReview.pendients!}
                                                onChange={this.handleInputChangeCheckbox}
                                                name={CheckBoxReviewEnum.pendiente}
                                                checked={this.state.filtersSerach.pendients}/>
                                        </div>
                                        <div className="col-4">
                                            <Checkbox
                                                      labelVisual={this.props.language.language?.warrantiesReview.inReview!}
                                                      onChange={this.handleInputChangeCheckbox}
                                                      name={CheckBoxReviewEnum.revision}
                                                      checked={this.state.filtersSerach.review}/>
                                        </div>

                                        <div className="col-4">
                                            <Checkbox
                                                labelVisual={this.props.language.language?.warrantiesReview.finished!}
                                                onChange={this.handleInputChangeCheckbox}
                                                name={CheckBoxReviewEnum.finalizadas}
                                                checked={this.state.filtersSerach.finish                  }

                                            />
                                        </div>

                                    </div>

                                </CheckboxGroup>

                            </div>

                        </div>
                    </div>

                    <div className="row" id="panel-review">
                        <div className="col-md-4 pendient">
                            <div className="title">
                                {this.props.language.language?.warrantiesReview.pendients!}
                            </div>
                            {this.state.loadingListPendients ? (
                                    <div className="col-12">
                                        <SpinnerLoading text={this.props.language.language?.warrantiesReview.loadingPendients!}/>
                                    </div>) :
                                (this.state.errorListPendients)? (
                                        <div className="col-12">
                                            <NegativeAlert
                                                textAlert={this.props.language.language?.warrantiesReview.errorPendients!}/>
                                        </div>):
                                    <div className="col-12">
                                        {this.state.listPendients.map((i,index) => (
                                            <div className={(this.state.draggable == true)? "card card-draggable":"card"}
                                                 draggable={this.state.draggable}
                                                 onDragStart={this.drag}
                                                 key={index}
                                                 id={String(index)}>
                                                <div className="card-body">
                                                    <h5 className="card-title">
                                                        {i.razonSocial}
                                                    </h5>
                                                    <h6 className="card-subtitle mb-2 text-muted">
                                                        {i.garantias} {this.props.language.language?.warrantiesReview.warranties!}
                                                    </h6>
                                                    <div className="card-text">
                                                        {i.responsable}
                                                    {/*debería ser el responsable*/}
                                                    </div>

                                                    <div className="card-footer text-right">
                                                        <FooterWarrantyReview
                                                                  impact={i.impacto}
                                                                  idUserType={i.idTipoUsuario}
                                                                  time={i.tiempo}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                            }
                        </div>

                        <div className="col-md-4 revision" onDrop={this.drop} onDragOver={this.allowDrop}>
                            <div className="title">
                                {this.props.language.language?.warrantiesReview.inReview!}
                            </div>

                            {(this.state.errorListRevision) ? (
                                    <div className="col-12">
                                        <NegativeAlert
                                            textAlert={this.props.language.language?.warrantiesReview.errorReview!}/>
                                    </div>) :
                                <div className="col-12">
                                    {this.state.listRevision.map((i, index) => (
                                        <div key={index} className="card">
                                            <div className="card-body">
                                                <div className="card-title h5">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <Link
                                                                to={'/garantias/revision/fiado/' + i.idCliente + '/lote/' + i.idLote}>
                                                                {i.razonSocial}
                                                            </Link>
                                                        </div>

                                                        <div className="col-md-2">

                                                            {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)?
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light"
                                                                                     className="dropdown-more-options ">
                                                                        <IconSeeMore size="16"></IconSeeMore>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#">
                                                                            <Link
                                                                                to={"/garantias/crear-garantia/fiado/" + i.idCliente}>
                                                                                {this.props.language.language?.warrantiesReview.createWarranty!}
                                                                            </Link>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>:
                                                                null
                                                            }

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="card-text row">
                                                    <div className="col-md-4 col-lg-4">
                                                        <CheckboxGroup>
                                                            <Checkbox checked={true} labelVisual={i.garantias}
                                                                      name="default" value="one"/>
                                                        </CheckboxGroup>
                                                    </div>
                                                    <div className="col-md-12 col-lg-4 success">
                                                        <IconSuccessAlert size="16" color="positive" label=""/>
                                                        <span className="text-success">&nbsp;&nbsp;{i.aprobadas}</span>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 error">
                                                        <IconErrorAlert size="16" color="negative" label=""/>
                                                        <span className="text-danger">&nbsp;&nbsp;{i.rechazadas}</span>
                                                    </div>


                                                </div>

                                                <div className="card-footer text-right">
                                                    <FooterWarrantyReview
                                                        impact={i.impacto}
                                                        idUserType={i.idTipoUsuario}
                                                        time={i.tiempo}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {this.state.loadingListRevision ? (
                                        <div className="col-12">
                                            <SpinnerLoading text={this.props.language.language?.warrantiesReview.loadingReviews!}/>
                                        </div>) :null}
                                </div>
                            }

                        </div>

                        <div className="col-md-4 finish">

                            <div className="title">
                                {this.props.language.language?.warrantiesReview.finished!}
                            </div>


                            {(this.state.errorListFinish)? (
                                        <div className="col-12">
                                            <NegativeAlert
                                                textAlert={this.props.language.language?.warrantiesReview.errorFinished!}/>
                                        </div>):
                                    <div className="col-12">
                                        {this.state.listFinish.map((i,index) => (
                                            <div className="card" key={index}>
                                                <div className="card-body">
                                                    <h5 className="card-title ">
                                                        <div className="row">
                                                            <Link to={'/garantias/revision/fiado/'+ i.idCliente +'/lote/'+ i.idLote}>
                                                                {i.razonSocial}
                                                            </Link>

                                                            <div className="col-md-2">

                                                            </div>
                                                        </div>

                                                    </h5>

                                                    <div className="card-text row">
                                                        <div className="col-4">
                                                            <CheckboxGroup >
                                                                <Checkbox checked={true} labelVisual={i.garantias} name="default" value="one" />
                                                            </CheckboxGroup>
                                                        </div>
                                                        <div className="col-4 success">
                                                            <IconSuccessAlert size="16" color="positive" label="" />
                                                            <span className="text-success">&nbsp;&nbsp;{i.aprobadas}</span>
                                                        </div>
                                                        <div className="col-4 error">
                                                            <IconErrorAlert size="16" color="negative" label="" />
                                                            <span className="text-danger">&nbsp;&nbsp;{i.rechazadas}</span>
                                                        </div>


                                                    </div>

                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <IconSuccessAlert size="24" className=""/>{i.garantias} &nbsp;

                                                            </div>
                                                        </div>
                                                        <FooterWarrantyReview
                                                            impact={i.impacto}
                                                            idUserType={i.idTipoUsuario}
                                                            time={i.tiempo}/>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {this.state.loadingListFinish ? (
                                            <div className="col-12">
                                                <SpinnerLoading text={this.props.language.language?.warrantiesReview.loadingFinished!}/>
                                            </div>) : null}
                                    </div>

                            }

                        </div>

                    </div>



                </div>
            </div>
        </>
    }

    componentDidMount() {
        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
            validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)){

            if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)){
                this.setState({draggable: false})
            }
            window.addEventListener("scroll", this.handleScroll);
        }else {

            if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)  ){
                this.props.history.push('/garantias');
            }else {
                this.props.history.push('/401');
            }
        }


    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll);
    }

    public async getPendientWarranties(typeProfile: TypeProfileModel,paginacion: PaginacionModel, search: string){
        // garantias pendientes
        this.setState({loadingListPendients: true, errorListPendients: false})
        try {
            const res = await garantiasService.getGarantiasPendientes(typeProfile, paginacion, search);
            const response: {paginacion: PaginacionModel, pendiente: GarantiasPendientesModel[]} = res.data;
            response.paginacion.size = paginacion.size;

            if (paginacion.currentPage == initPaginacion.currentPage){ // si es la primera vez que va cargar algo se reemplaza T0d0
                this.setState({loadingListPendients: false,
                    paginacionPendients:response.paginacion,
                    listPendients: response.pendiente });

            }else {// agregar los nuevos registros sin borrar los anteriores
                const auxListPendientes = this.state.listPendients;


                for (const pendiente of response.pendiente) {
                    auxListPendientes.push(pendiente);
                }
                this.setState({loadingListPendients: false,
                    paginacionPendients:response.paginacion,
                    listPendients: auxListPendientes })
            }



        } catch (error) {
            this.setState({loadingListPendients: false, errorListPendients: true})
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }

    }

    public async getRevisionWarranties(typeProfile: TypeProfileModel,
                                       paginacion: PaginacionModel,
                                       search: string){
        // garantias en Revisión
        this.setState({loadingListRevision: true, errorListRevision: false})

        try {
            const res = await garantiasService.getGarantiasRevision(typeProfile, paginacion, search);
            const response: {paginacion: PaginacionModel, revision: GarantiasRevisionModel[]} = res.data;
            response.paginacion.size = paginacion.size;
            if (paginacion.currentPage == initPaginacion.currentPage){ // si es la primera vez que va cargar algo se reemplaza T0d0
                this.setState({loadingListRevision: false,
                    paginacionRevision:response.paginacion,
                    listRevision: response.revision });
            }else{// agregar los nuevos registros sin borrar los anteriores
                const auxListRevision = this.state.listRevision;

                for (const revision of response.revision){
                    auxListRevision.push(revision);
                }

                this.setState({loadingListRevision: false,
                    paginacionRevision:response.paginacion,
                    listRevision: auxListRevision });
            }


        } catch (error) {
            this.setState({loadingListRevision: false, errorListRevision: true})
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }

    }

    public async getFinishWarranties(typeProfile: TypeProfileModel,paginacion: PaginacionModel, search: string){
        // garantias en Revisión
        this.setState({loadingListFinish: true, errorListFinish: false})
        try {
            const res = await garantiasService.getGarantiasFinalizadas(typeProfile, paginacion, search);
            const response: {paginacion: PaginacionModel, finalizado: GarantiasfinalizadasModel[]} = res.data;
            response.paginacion.size = paginacion.size;

            if (paginacion.currentPage == initPaginacion.currentPage){ // si es la primera vez que va cargar algo se reemplaza T0d0
                this.setState({loadingListFinish: false,
                    paginacionFinish:response.paginacion,
                    listFinish: response.finalizado });

            }else {// agregar los nuevos registros sin borrar los anteriores
                const auxListFinalizadas = this.state.listFinish;

                for (const finalizado of response.finalizado) {
                    auxListFinalizadas.push(finalizado);
                }

                this.setState({
                    loadingListFinish: false,
                    paginacionFinish: response.paginacion,
                    listFinish: auxListFinalizadas
                });
            }

        } catch (error) {
            this.setState({loadingListFinish: false, errorListFinish: true})
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }

    }

    public async putEnRevisionWarranties(loteGarantia: GarantiasPendientesModel, indexPosition: number){
        // enviar lote a en revisión

        try {
            const res = await garantiasService.putRevisionGarantiasPendiente(loteGarantia);
            console.log(res.data);
            // actualizar todos pendientes y en revision
            this.setState({paginacionPendients: initPaginacion,
                                    listPendients:[],
                                    paginacionRevision: initPaginacion,
                                        listRevision:[]})

            this.getPendientWarranties(this.state.typeProfile, initPaginacion, this.state.searchPrincipals);
            this.getRevisionWarranties(this.state.typeProfile, initPaginacion, this.state.searchPrincipals);

   /*         let auxlistPendients = this.state.listPendients;
            let auxlistRevision = this.state.listRevision;
            auxlistPendients.splice(indexPosition, 1);
            auxlistRevision.splice(0,0,res.data);
            this.setState({listPendients: auxlistPendients, listRevision: auxlistRevision});*/

        } catch (error) {
            this.setState({loadingListRevision: false, errorListRevision: true})
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }

    }

    public handleInputChangeSearch  (e: InputChange) {
        const value = e.target.value;
        this.setState({searchPrincipals: value})
    }

    public handleInputChangeCheckbox  (e: InputChange) {
        const name = e.target.name;
        const auxFilterSearch:FilterSearchReview = this.state.filtersSerach;
        switch (name) {
            case CheckBoxReviewEnum.pendiente:
                auxFilterSearch.pendients = (auxFilterSearch.pendients) ? false : true;
                break;
            case CheckBoxReviewEnum.revision:
                auxFilterSearch.review = (auxFilterSearch.review) ? false : true;
                break;
            case CheckBoxReviewEnum.finalizadas:
                auxFilterSearch.finish = (auxFilterSearch.finish) ? false : true;
                break;
        }
        this.setState({filtersSerach: auxFilterSearch});

    }

    public serchPrincipals (){
        const searhAux = this.state.searchPrincipals;

        const auxFilterSearch:FilterSearchReview = this.state.filtersSerach;

        const typeProfileAux = this.state.typeProfile;

        if (auxFilterSearch.pendients){

            this.getPendientWarranties(typeProfileAux, initPaginacion, searhAux)
        }
        if (auxFilterSearch.review){
            this.getRevisionWarranties(typeProfileAux, initPaginacion, searhAux);
        }
        if (auxFilterSearch.finish){
            this.getFinishWarranties(typeProfileAux, initPaginacion, searhAux)
        }

        if (!auxFilterSearch.pendients && !auxFilterSearch.review && !auxFilterSearch.finish){
            this.getPendientWarranties(typeProfileAux, initPaginacion, searhAux)

            this.getRevisionWarranties(typeProfileAux, initPaginacion, searhAux);

            this.getFinishWarranties(typeProfileAux, initPaginacion, searhAux)
        }


    }

    public loadDashboard(typeProfile: TypeProfileModel){
        this.setState({typeProfile: typeProfile,listPendients:[], listRevision:[], listFinish:[]}) // inicializar las listas
        this.getPendientWarranties(typeProfile, this.state.paginacionPendients,this.state.searchPrincipals);
        this.getRevisionWarranties(typeProfile,this.state.paginacionRevision, this.state.searchPrincipals);
        this.getFinishWarranties(typeProfile, this.state.paginacionRevision, this.state.searchPrincipals);
    }

    public drag(ev: React.DragEvent<HTMLDivElement>) {

        const indexPosition = (ev.target as HTMLDivElement).id;
        ev.dataTransfer.setData("text", indexPosition);

        //this.setState({selectIndexPendient: index!})
       // this.uploadPendient();

    }

    public drop(ev: React.DragEvent<HTMLDivElement>) {
        const id = ev.dataTransfer.getData("text");
        const indexPosition: number = parseInt(id) ;
        console.log('drag: lote ' + JSON.stringify(this.state.listPendients[indexPosition]))
        // enviar los datos a En revisión
        this.putEnRevisionWarranties(this.state.listPendients[indexPosition], indexPosition);

    }

    public  allowDrop(ev: React.DragEvent<HTMLDivElement>) {
        ev.preventDefault();
    }

    public loadInfiniteScroll(bottom: boolean){
        if (bottom){
            const auxtypeProfile = this.state.typeProfile;
            //hacer las operaciones de la paginación
            // pendientes
            const  auxPaginacionPendients = this.state.paginacionPendients;
            const nextPagePendients = (auxPaginacionPendients.currentPage+1);
       //     console.log('paginacion actual Pendientes: ' + auxPaginacionPendients.currentPage);
      //      console.log('paginacion siguiente Pendientes: ' + nextPagePendients);
            if (nextPagePendients <= auxPaginacionPendients.totalPages){
      //          console.log('traer mas pendientes ' + nextPagePendients)
                if (!this.state.loadingListPendients){
                    auxPaginacionPendients.currentPage = nextPagePendients
                    this.getPendientWarranties(auxtypeProfile, auxPaginacionPendients, this.state.searchPrincipals);
                }
            }
          // revision
            const  auxPaginacionRevision = this.state.paginacionRevision;
            const nextPageRevision = (auxPaginacionRevision.currentPage+1);
       //     console.log('paginacion actual Revision: ' + auxPaginacionRevision.currentPage);
       //     console.log('paginacion siguiente Revision: ' + nextPageRevision);
            if (nextPageRevision <= auxPaginacionRevision.totalPages){
       //         console.log('traer mas revision ' + nextPagePendients)
                if (!this.state.loadingListRevision){
                    auxPaginacionRevision.currentPage = nextPageRevision
                    this.getRevisionWarranties(auxtypeProfile, auxPaginacionRevision, this.state.searchPrincipals);
                }
            }

            // finalizadas
            const  auxPaginacionFinish = this.state.paginacionFinish;
            const nextPageFinish = (auxPaginacionFinish.currentPage+1);
         //   console.log('paginacion actual Finish: ' + auxPaginacionFinish.currentPage);
         //   console.log('paginacion siguiente Finish: ' + nextPageFinish);
            if (nextPageFinish <= auxPaginacionFinish.totalPages){
         //       console.log('traer mas finish ' + nextPageFinish)
                if (!this.state.loadingListFinish){
                    auxPaginacionFinish.currentPage = nextPageFinish
                    this.getFinishWarranties(auxtypeProfile, auxPaginacionFinish, this.state.searchPrincipals);
                }
            }

        }else {
            // console.log('not at bottom')
        }

    }

    public handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        //  console.log('windowBottom ' + windowBottom + ' >= docHeight: ' + docHeight);
        if ( Math.round(windowBottom) >= docHeight-1) {
            console.log('bottom reached')
            this.loadInfiniteScroll(true)
        } else {
            this.loadInfiniteScroll(false)
        }
    }
}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});

export default connect(mapStateToProps) (WarrantiesReview);

interface WarrantiesReviewState {
    PERFIL: UserModel;
    typeProfile: TypeProfileModel,
    paginacionPendients: PaginacionModel;
    listPendients:GarantiasPendientesModel [];
    loadingListPendients: boolean;
    errorListPendients: boolean;
    paginacionRevision: PaginacionModel;
    listRevision:GarantiasRevisionModel [];
    loadingListRevision: boolean;
    errorListRevision: boolean;
    paginacionFinish: PaginacionModel;
    listFinish:GarantiasfinalizadasModel [];
    loadingListFinish: boolean;
    errorListFinish: boolean;
    searchPrincipals: string;
    filtersSerach: FilterSearchReview;
    draggable: boolean;

}
interface FilterSearchReview {pendients: boolean, review: boolean, finish: boolean}

