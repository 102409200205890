import {
    ADDOS,
    FIADOACTION,
    FiadoActionDispatchTypes,
    LOCK,
    LOCKCOMMISSION,
    UNLOCK,
    UNLOCKCOMMISSION
} from "../actions/FiadoActionTypes";

export interface DefaultStateI {
    fiadoAction?: FIADOACTION
}

const defaultState: DefaultStateI = {

};

const fiadoActionReducer = (state: DefaultStateI = defaultState,
                         action: FiadoActionDispatchTypes) : DefaultStateI => {
    switch (action.type) {
        case LOCK:
            return {
                fiadoAction: action.payload
            }
        case UNLOCK:
            return {
                fiadoAction: action.payload
            }
        case LOCKCOMMISSION:
            return {
                fiadoAction: action.payload
            }
        case UNLOCKCOMMISSION:
            return {
                fiadoAction: action.payload
            }
        case ADDOS:
            return {
                fiadoAction: action.payload
            }
        default:
            return state
    }
};
export default fiadoActionReducer;