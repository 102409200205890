import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {Button, Modal as ModalLiberty, Alert} from "@lmig/lmds-react";
import {
    initLineaAfianzamiento,
} from "../../../class/CommonClass";
import {LineaAfinzamiento} from "../../../models/garantias/LineaAfinzamiento";
import './CreateLineaAfianzamiento.scss';
import CurrencyInputSDGF from "../../public/currency-input-sdgf/CurrencyInputSDGF";
import {SelectProductosLinea} from "../select-productos-linea/SelectProductosLinea";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
interface CreateLineaAfianzamientoProps {
    idPrincipal: number;
    newLinea: (lineaAfianzamiento: LineaAfinzamiento) => void;
    closeModal: () => void;
}
export const CreateLineaAfianzamiento: React.FC<CreateLineaAfianzamientoProps> = ({idPrincipal, newLinea, closeModal}:CreateLineaAfianzamientoProps)=> {


    const languageState = useSelector((state: RootStore) => state.language.language?.lineaCreditoLanguage);


    const [lineaAfianzamiento, setLineaAfianzamiento] = useState<LineaAfinzamiento>(initLineaAfianzamiento);

    const [savingLinea, setSavingLinea] = useState<boolean>(false);

    const [clearProductos, setClearProductos] = useState<boolean>(false);

    const initMsgLineaAfianzamiento: MsgNuevaLineaAfianzamiento = {
        msgProductos: '',
        msgMontoMaximoEv: '',
        msgMontoMaximoAC:''
    }

    const [msgLineaAfianzamiento, setMsgLineaAfianzamiento] = useState<MsgNuevaLineaAfianzamiento>(initMsgLineaAfianzamiento);

    const saveLinea =  async () => {
        const newLineaAfianzamiento: LineaAfinzamiento = lineaAfianzamiento;

        if (isValidLinea(newLineaAfianzamiento)){
            setSavingLinea(true);
            try {
                const res = await garantiasService.posNuevaLineaAfianzamiento(newLineaAfianzamiento);
                setLineaAfianzamiento(initLineaAfianzamiento);
                setSavingLinea(false);
                setClearProductos(true);
                newLinea(res.data);

            }
            catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    setSavingLinea(false);
                }
            }
        }

    }
    const isValidLinea = (newLineaAfianzamiento: LineaAfinzamiento): boolean => {
        // console.log(newLineaAfianzamiento);
        // console.log(initMsgLineaAfianzamiento);

       hideMsgs();

        if (newLineaAfianzamiento.productos.length == 0 ){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgProductos: languageState?.msgProduct!})
            // @ts-ignore
            document.getElementById('productos-linea').style.visibility = "visible";
            return false;
        }

        if (!newLineaAfianzamiento.montoMaximoEv){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgMontoMaximoEv: languageState?.enterMontoMaximoEv!})
            // @ts-ignore
            document.getElementById('enter-montoMaximoEv').style.visibility = "visible";
            return false;
        }
        if (!newLineaAfianzamiento.montoMaximoAc){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgMontoMaximoAC: languageState?.enterMontoMaximoAc!})
            // @ts-ignore
            document.getElementById('enter-monto-maximo').style.visibility = "visible";

            return false;
        }
        if (newLineaAfianzamiento.montoMaximoEv > newLineaAfianzamiento.montoMaximoAc){
            setMsgLineaAfianzamiento({...msgLineaAfianzamiento, msgMontoMaximoAC: languageState?.exceedMontoMaximoAc!})
            // @ts-ignore
            document.getElementById('exceed-monto-maximo').style.visibility = "visible";
            return  false;
        }


        return  true;
    }

    const hideMsgs= () => {
        setMsgLineaAfianzamiento(initMsgLineaAfianzamiento)
        // @ts-ignore
        document.getElementById('productos-linea').style.visibility = "hidden";
        // @ts-ignore
        document.getElementById('enter-montoMaximoEv').style.visibility = "hidden";
        // @ts-ignore
        document.getElementById('enter-monto-maximo').style.visibility = "hidden";
        // @ts-ignore
        document.getElementById('exceed-monto-maximo').style.visibility = "hidden";
    }


    useEffect(() => {
        hideMsgs()
        setLineaAfianzamiento({...lineaAfianzamiento, idCliente: idPrincipal})
    },[idPrincipal]);

    return (
        <>
            <div id="create-line-afianzamiento" className="row">
                <div className="col-md-12">
                    <SelectProductosLinea
                        idFiado={idPrincipal}
                        clear={clearProductos}
                        onChange={values => {
                            //console.log(values);
                            setLineaAfianzamiento({...lineaAfianzamiento, productos: values});
                        }}
                    />
                    <Alert highlightType="negative" id="productos-linea" >
                        {languageState?.msgProduct}
                    </Alert>

                    <br/>

                </div>

                <div className="col-md-6">
                    <label className="label-input">{languageState?.montoMaximoEv!}:</label>
                    <CurrencyInputSDGF
                        value={lineaAfianzamiento.montoMaximoEv}
                        name="montoMaximoEv"
                        placeholder={languageState?.montoMaximoEv!}
                        onValueChange={(value, name) => {
                            setLineaAfianzamiento({...lineaAfianzamiento, montoMaximoEv: value});
                        }}
                    />
                    <br/>
                    <Alert highlightType="negative" id="enter-montoMaximoEv" >
                        {languageState?.enterMontoMaximoEv}
                    </Alert>


                </div>

                <div className="col-md-6">
                    <label className="label-input">{languageState?.montoMaximoAc!}:</label>
                    <CurrencyInputSDGF
                        value={lineaAfianzamiento.montoMaximoAc}
                        name="montoMaximoAc"
                        placeholder={languageState?.montoMaximoAc!}
                        onValueChange={(value, name) => {
                            setLineaAfianzamiento({...lineaAfianzamiento, montoMaximoAc: value});
                        }}
                    />
                    <br/>
                    <Alert highlightType="negative" id="enter-monto-maximo" >
                        {languageState?.enterMontoMaximoAc}
                    </Alert>
                    <Alert highlightType="negative" id="exceed-monto-maximo" >
                        {languageState?.exceedMontoMaximoAc}
                    </Alert>
                </div>

                <div className="col-md-5"></div>

                <div className="col-md-7">
                    <div className="row" id="buttons-linea">
                        <div className="col-md-6">
                            <Button dynamicWidth
                                    onClick={() => {closeModal()}}
                                    variant="secondary">
                                {languageState?.cancel}
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <Button dynamicWidth
                                    disabled={savingLinea? true: false}
                                    onClick={() => {saveLinea()}}
                                    variant="primary">
                                {(savingLinea)?languageState?.saving: languageState?.save}
                            </Button>
                        </div>
                    </div>
                </div>



            </div>

        </>


    );
}

interface MsgNuevaLineaAfianzamiento {
    msgProductos: string;
    msgMontoMaximoEv: string;
    msgMontoMaximoAC: string;
}