import {Dispatch} from "redux";
import {ADDOS, FiadoActionDispatchTypes, LOCK, LOCKCOMMISSION, UNLOCK, UNLOCKCOMMISSION} from "./FiadoActionTypes";

export const dispatchPutLockPrincipal = (idPrincipal: number) =>
    async (dispatch: Dispatch<FiadoActionDispatchTypes>) => {

        dispatch({
            type: LOCK,
            payload: {idFiado: idPrincipal, action: LOCK}
        })
};


export const dispatchPutUnLockPrincipal = (idPrincipal: number) =>
    async (dispatch: Dispatch<FiadoActionDispatchTypes>) => {

        dispatch({
            type: UNLOCK,
            payload: {idFiado: idPrincipal, action: UNLOCK}
        })
};


export const dispatchPutLockCommissionPrincipal = (idPrincipal: number) =>
    async (dispatch: Dispatch<FiadoActionDispatchTypes>) => {

        dispatch({
            type: LOCKCOMMISSION,
            payload: {idFiado: idPrincipal, action: LOCKCOMMISSION}
        })
    };


export const dispatchPutUnLockCommissionPrincipal = (idPrincipal: number) =>
    async (dispatch: Dispatch<FiadoActionDispatchTypes>) => {

        dispatch({
            type: UNLOCKCOMMISSION,
            payload: {idFiado: idPrincipal, action: UNLOCKCOMMISSION}
        })
    };

export const dispatchAddOsPrincipal = (idClient: number) =>
    async (dispatch: Dispatch<FiadoActionDispatchTypes>) => {

        dispatch({
            type: ADDOS,
            payload: {idFiado: idClient, action: ADDOS}
        })
    };
