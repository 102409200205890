import React, {useEffect, useState} from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
//import './NavBarPublic.scss';
import logo from '../../../assets/ico/PNG/LibertyFianzas_BLUE_RGB.png';

import {IconMenu, IconClose, IconSeeMore, IconBell, IconEmail, IconHomeWarranty, Button} from '@lmig/lmds-react'
import {useHistory} from "react-router-dom";
import SelectLanguage from '../select-language/SelectLanguage';

const NavBarInvitado = () => {
    const history = useHistory();
    return (
        <div id="nav-bar-public">
            <Navbar fixed="top">
                    <Navbar.Brand>
                        <a className="logo-liberty">
                            <img
                                src={logo}
                                width="112" height="53.3"
                                className="d-inline-block align-top"
                                alt="Logo Liberty Fianzas"
                            />
                        </a>

                    </Navbar.Brand>
                    <Navbar.Toggle />

                    <Navbar.Collapse className="justify-content-end">
                    <Nav className="me-auto">
                    <div className="col-2">
                               <h4>Notificación</h4>
                            </div>
                    </Nav>
                        <Navbar.Text className="">
                        <div className="row">
                            <div className="col-2">
                                <SelectLanguage />
                            </div>
                           
                        </div>
                        </Navbar.Text>
                    </Navbar.Collapse>
            </Navbar>

        </div>
    );
};

export default NavBarInvitado;
