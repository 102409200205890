import React, {useEffect, useState} from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import './NavBarLogin.scss';
import logo from '../../../assets/ico/logo-web-liberty16.png';

import {IconMenu, IconClose, IconSeeMore, IconBell, IconEmail, IconHomeWarranty, Button} from '@lmig/lmds-react'
import {Link, useHistory} from "react-router-dom";
import {openNewWindow} from "../../../class/CommonClass";
import SelectLanguage from "../select-language/SelectLanguage";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import environment from "../../../environments/environment";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";

const NavBarLogin= () => {
    const history = useHistory();
    const languageState = useSelector((state: RootStore) => state.language.language?.navBarPublic);


    const  URLSERF = environment.URLSERF
    const  URLVALFIANZA = environment.URLVALFIANZA
    const  URLSOPORTE = environment.URLSOPORTE
    const  URLRECUPERACFDI = environment.URLRECUPERACFDI
    const  URLPAGOLINEA = environment.URLPAGOLINEA
    const  URLOPERPREOCUPANTE = environment.URLOPERPREOCUPANTE

    const openNewWindow= (src: string,
                          width: number,
                          height: number) =>  {
        // eslint-disable-next-line no-restricted-globals
        const screenWidth = screen.width
        // eslint-disable-next-line no-restricted-globals
        const screenHeight = screen.height


        let left = (screenWidth/2) - (width / 2);
        let top = (screenHeight/2) - (height / 2);
        window.open (src,
            "_blank",
            "menubar=1," +
            "resizable=1," +
            "width=" + width + "," +
            "height=" + height +"," +
            "top=" + top +"," +
            "left=" + left
        )
    }

    return (
        <div id="nav-bar-login">
            <Navbar fixed="top">
                <Navbar.Brand>

                    <Link to="/">
                        <img
                            src={logo}
                            width="112" height="53.3"
                            className="d-inline-block align-top ico-liberty"
                            alt="Logo Liberty Fianzas"
                        />
                    </Link>

                </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">

                    <Navbar.Text className="">
                        <div className="row">
                            <div className="col-2 language">
                                <SelectLanguage />
                            </div>
                        </div>

                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>



        </div>
    );
};

export default NavBarLogin;
