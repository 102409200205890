import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "./assets/css/fonts.scss";
import { Provider } from "react-redux";
import store from "./Store";
// @ts-ignore
import Home from "./views/public/home/Home";
import App from "./App";



ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
function DetailsEngagementsByFiado(
  DetailsEngagementsByFiado: any
):
  | React.ComponentType<any>
  | React.ComponentType<
      import("react-router").RouteComponentProps<
        any,
        import("react-router").StaticContext,
        unknown
      >
    >
  | undefined {
  throw new Error("Function not implemented.");
}
