import { Badge, IconButton, Content, GridCol, GridRow, Button, Textarea, IconDownload, IconUpload, IconCheckmark, 
    IconThumbsUp, IconThumbsDown, IconCloseCircle, IconSend, Alert, IconSeeMore, IconErrorAlert, IconSuccessAlert, 
    AlphanumericInput, IconSearch, Heading} from '@lmig/lmds-react';
import { Modal } from '@lmig/lmds-react-modal';
import { Table, TableCell, TableRow } from '@lmig/lmds-react-table';
import { Component } from 'react'
import { RouteComponentProps } from 'react-router';
import NavBar from '../../../components/public/nav-bar/NavBar';
import '../../rh/polizas-rh/PolizasRH.scss';
import * as apiService from '../../../services/polizasRH/PolizasRHServices';
import { AxiosError } from 'axios';
import { PolizaModel } from '../../../models/polizasRH/PolizaModel';
import { Dropdown, Pagination } from 'react-bootstrap';
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import { UserModel } from '../../../models/public/UserModel';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import { connect } from 'react-redux';

import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {initPaginacion, loadPaginationList} from "../../../class/CommonClass";



interface PolizasRHProps {
    language: DefaultStateI;
}


class PolizasRH extends Component<PolizasRHProps & RouteComponentProps, PolizasRHState> {
    
    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        document.title = "Polizas"

        const PERFIL = this.localStorageClass.getProfile();
        //si no hay un token guardado
        if (!PERFIL.accessToken){
            // redireccionar
            this.props.history.push('/login');
        }
        this.state = {
            modalNuevaPoliza : false, 
            modalPolizaRechazada : false,
            modalEnvioExito : false,
            modalErrorPoliza : false,
            modalAutorizarPoliza : false,
            modalRechazarPoliza : false,
            modalPrueba : false,
            disEnviar : true,
            nombreArchivo : '',
            formData : new FormData(),
            listaPolizas : [],
                // [
                // {"folio": 1,
                // "motivo" : "Prueba",
                // "usuarioEnvio": "Juan Perez Sanches",
                // "archivo": "Poliza_RH_23062022.xlsx",
                // "fechaEnvio": "2022-06-23",
                // "usuarioAplico": "Luis Juarez",
                // "fechaAplico": "2022-06-23",
                // "estatus": "Enviado",
                // "numRegistros" : 15,
                // "paginas" : 2,
                // },],
            folioPoliza : 0,
            url : ' ',
            motivo : '',
            numRegistrosMax : 10,
            numRegistrosMin : 1,
            PERFIL : PERFIL,
            rolEjecutivo : 'USUARIO_CONTABLE',
            numTotalPolizas : 0,
            dragActive : false,
            disTextArea : false,
            disThumb : false,
            numPaginas : 1,
            currentPage : 1,
            allowPost : false,
            alertFile : false,
            alertEmpty : false,
            
            paginacion : initPaginacion,
            listPaginacion : [],

            newPaginacion : initPaginacion,
            newListPaginacion : [],
            numRegistros : 10,

            disPaginador : true
        }
    }

handleFileDrop = (e : any) => {
    e.preventDefault(); 
    console.log('Droped file'); 

    const formDataAux = this.state.formData;
    const file = e.dataTransfer.files[0];
            
    console.log(file.name);
    console.log(file.type);
    console.log(e.dataTransfer.files[0]);
    
    if(file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        formDataAux.append('fileName', e.dataTransfer.files[0]);
        this.setState({formData : formDataAux, nombreArchivo : file.name,  disEnviar : false, allowPost : true, alertFile : false});
    }else{
        this.setState({alertFile : true, disEnviar : true, allowPost : false});
    }

}

handleFileChange = (e : any) => {
    const formDataAux = this.state.formData;
    const cadena = e.target.value.split('\\');

    formDataAux.append('fileName', e.target.files![0]);
    
    this.setState({formData : formDataAux, nombreArchivo : cadena[cadena.length-1], disEnviar : false});
    
    console.log(e.target.files);
    console.log(this.state.formData);
    
}  

handleTextChange = (e : any) => {
    this.setState({motivo : e.target.value})
    console.log(e.target.value);
}

handlesubmit = async (e : any) => {
    e.preventDefault();
    await this.postFile(e);
    this.setState({modalNuevaPoliza : false, modalEnvioExito : true})
}


openModal = (folioPoliza : number, estatus : string, e : any,) => {
    e.preventDefault();

    console.log('folioPoliza: ' + folioPoliza);  
    console.log('estatus: ' + estatus);
    this.setState({folioPoliza : folioPoliza})  

    if(estatus.toUpperCase() === 'ERROR'){
        this.errorPoliza(folioPoliza);
        this.setState({modalErrorPoliza : true, disTextArea : true}) 
    }else if(estatus.toUpperCase() === 'RECHAZADA'){
        this.estatusPoliza(folioPoliza);
        this.setState({modalPolizaRechazada : true, disEnviar : true}) 
    }
}

modalThumbDown = (folioPoliza : number, estatus : string, e : any) => {
    e.preventDefault();

    const data = this.state.motivo;
    console.log('motivo: '+ data);
    
    console.log('folioPoliza: ' + folioPoliza);
    console.log('estatus: ' + estatus);
    this.setState({folioPoliza : folioPoliza}) 

    if (estatus.toUpperCase() === 'RECHAZADA') {
        this.estatusPoliza(folioPoliza);
        this.setState({ modalPolizaRechazada: true, disTextArea: true, disThumb: true})
    } else if (estatus.toUpperCase() === 'ERROR') {
        this.errorPoliza(folioPoliza);
        this.setState({ modalErrorPoliza: true, disTextArea: true, disThumb: true })
    }     
}


// handlePaginator = (opcion : number) => {
//     let pag : number = this.state.currentPage;

//     if(opcion === 1){
//         pag = this.state.currentPage + 1;
//         this.setState({currentPage : pag});
//     }else{
//         pag = this.state.currentPage - 1;
//         this.setState({currentPage : pag});
//     }

//     console.log(this.state.currentPage);
//     this.paginador(pag);
// }

formatearFecha = (fecha : string) => {
    if(fecha != null){
        const date = new Date(fecha)
        let dd = String(date.getDate()+1).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //mes is 0!
        let yyyy = date.getFullYear();

        // console.log(dd + '-' + mm + '-'  + yyyy );
        return dd + '-' + mm + '-'  + yyyy ;
    }else return '';
}

    render() {
        return (
            <>
                <NavBar /> 

                <Content id='polizas-rh'>
                    <h1 id='title-page'>{this.props.language.language?.polizasRH.titlePage}</h1>

                    <GridRow gutters justify='center'>
                        <GridCol base={9}>
                                {/* <div className="search" id='search'>
                                    <input id='input-search' 
                                        type="number" 
                                        pattern="[0-9]+"
                                        onChange={(e) => this.buscar(e.target.value)}
                                        placeholder={this.props.language.language?.polizasRH.searchBar} />
                                    <GoSearch size="18" color='gray'id='iconsearch'/>
                                </div> */}
                            <AlphanumericInput 
                                filter={/^[0-9]*$/} 
                                type='text'                                 
                                onChange={(e) => this.buscar(e.target.value)}
                                className='search w-75' 
                                labelVisual={
                                    <div id='input-buscar'>
                                        <IconSearch size={'16'}></IconSearch>
                                        {this.props.language.language?.polizasRH.searchBar}
                                    </div>} 
                                >
                            </AlphanumericInput>
                        </GridCol>

                        <GridCol base={2} >
                            <div id='boton-agregar-poliza'>

                            <Button 
                                variant='primary' 
                                size='small' 
                                type='button'
                                onClick={() => this.setState({modalNuevaPoliza : true})}
                                hidden={this.state.PERFIL.roles.indexOf(this.state.rolEjecutivo) !== -1 ? true : false}
                            >{this.props.language.language?.polizasRH.btnNewPolicy}</Button>
                            </div>
                        </GridCol>
                    </GridRow>


                    <Table id='tbl-moduloRH'>
                        <thead className="table-head">
                            <TableRow>
                                <TableCell type="colHead">
                                    {this.props.language.language?.polizasRH.folio}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.polizasRH.userSent}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.polizasRH.file}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.polizasRH.dateSent}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.polizasRH.userApplied}
                                </TableCell>
                                <TableCell type="colHead">
                                    {this.props.language.language?.polizasRH.dateApplication}
                                </TableCell>
                                <TableCell type="colHead" className="colStatus">
                                    {    this.props.language.language?.polizasRH.status   }
                                </TableCell>
                            </TableRow>
                        </thead>
                        <tbody>
                        {/* {this.props.data.filter((item, idx) => idx < 5).map(item => {} */}
                            {/* {this.state.listaPolizas.filter((poliza, index) => index < this.state.numRegistros).map((poliza, index) => ( */}
                            {/* {this.state.listaPolizas.slice(this.state.numRegistrosMin - 1, this.state.numRegistrosMax - 1).map((poliza, index) => (  */}
                            {this.state.listaPolizas.map((poliza, index) => ( 
                                <TableRow key={index}>
                                    <TableCell type="rowHead">{poliza.folio}</TableCell>
                                    <TableCell type='rowHead'>{poliza.usuarioEnvio}</TableCell>
                                    <TableCell type='rowHead'>
                                        <IconButton 
                                            onClick={() => this.descargaArchivo(poliza.archivo)}
                                            >
                                            <IconDownload 
                                                size={'24'}
                                                color='informative' 
                                                title={this.props.language.language?.polizasRH.btnDownload}
                                            ></IconDownload>
                                        </IconButton>
                                        <a title='title' id='boton-descarga' href={this.state.url} download="Poliza.xlsx"></a>
                                    </TableCell>
                                    <TableCell type='rowHead'>{this.formatearFecha(poliza.fechaEnvio)}</TableCell>
                                    <TableCell type='rowHead'>{poliza.usuarioAplico}</TableCell>
                                    <TableCell type='rowHead'>{this.formatearFecha(poliza.fechaAplico)}</TableCell>
                                    <TableCell type='rowHead'>
                                        {/* BADGES DE CONTABILIDAD */}
                                        {this.state.PERFIL.roles.indexOf(this.state.rolEjecutivo) !== -1 ?
                                            <div className='row mx-auto thumbs cursor colStatus'>
                                            <Badge
                                                className='row d-block mx-auto thumbs cursor'
                                                onClick={(e) => this.modalThumbDown(poliza.folio, poliza.estatus, e)}
                                                
                                                highlightType={
                                                    poliza.estatus.toUpperCase() === 'APLICADA' ? 'positive' :
                                                    poliza.estatus.toUpperCase() === 'ENVIADO' ? 'informative' :
                                                    poliza.estatus.toUpperCase() === 'RECHAZADA' ? 'negative' :
                                                    poliza.estatus.toUpperCase() === 'ERROR' ? 'negative' : 
                                                    'negative'
                                                }
                                                icon={
                                                    poliza.estatus.toUpperCase() === 'APLICADA' ? <IconCheckmark color="informative" size="16" /> :
                                                    poliza.estatus.toUpperCase() === 'ENVIADO' ? <IconSend color="informative" size="16" /> :
                                                    poliza.estatus.toUpperCase() === 'RECHAZADA' ? <IconCloseCircle color="informative" size="16" /> :
                                                    poliza.estatus.toUpperCase() === 'ERROR' ? <IconErrorAlert color="negative" size="16" /> :
                                                    <IconCloseCircle color="negative" size="16" /> 
                                                    }
                                                >
                                                {
                                                    poliza.estatus.toUpperCase() === 'APLICADA' ? this.props.language.language?.polizasRH.statusApplied : 
                                                    poliza.estatus.toUpperCase() === 'ENVIADO' ? this.props.language.language?.polizasRH.statusPending : 
                                                    poliza.estatus.toUpperCase() === 'RECHAZADA' ? this.props.language.language?.polizasRH.statusDeclined : 
                                                    poliza.estatus.toUpperCase() === 'ERROR' ? this.props.language.language?.polizasRH.statusError :
                                                    this.props.language.language?.polizasRH.statusError                                                    
                                                }
                                            </Badge>

                                             {/* THUMBS DE CONTABILIDAD */}
                                                    <Dropdown className='miDropdown'>
                                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" >
                                                            <IconSeeMore></IconSeeMore>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                                    <Dropdown.Item className='dropdown-item' >
                                                                        {/* onClick={ () => alert('Hello world!')}> */}
                                                                        <IconButton
                                                                        onClick={() =>  this.setState({modalAutorizarPoliza : true, folioPoliza : poliza.folio}) }
                                                                            // onClick={(e) => this.openModal(poliza.folio, poliza.estatus, e)}
                                                                            disabled={poliza.estatus.toUpperCase() === 'RECHAZADA' ? true 
                                                                                    : poliza.estatus.toUpperCase() === 'ERROR' ? true
                                                                                    : false}
                                                                            >
                                                                            <IconThumbsUp 
                                                                                size={'24'} 
                                                                                color={
                                                                                    poliza.estatus.toUpperCase() === 'APLICADA' ? 'positive' : 'gray'}
                                                                                disabled={this.state.disTextArea}
                                                                            ></IconThumbsUp> 
                                                                        </IconButton>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <IconButton
                                                                            onClick={(e) => poliza.estatus.toUpperCase() === 'ENVIADO' ? this.setState({modalRechazarPoliza : true, folioPoliza : poliza.folio, disTextArea : false, motivo : ''})
                                                                                        :  poliza.estatus.toUpperCase() === 'APLICADA' ? this.setState({modalRechazarPoliza : true, folioPoliza : poliza.folio, disTextArea : false, motivo : ''})
                                                                                        :  this.modalThumbDown(poliza.folio, poliza.estatus, e)} 
                                                                            // onClick={(e) => (this.modalThumbDown(poliza.folio, poliza.estatus, e), console.log(this.state.motivo) )}
                                                                            // onClick={(e) => this.modalesConta(poliza.folio, poliza.estatus, e)}
                                                                            >
                                                                            <IconThumbsDown 
                                                                                size={'24'} 
                                                                                color={
                                                                                    poliza.estatus.toUpperCase() === 'RECHAZADA' ? 'negative' : 
                                                                                    poliza.estatus.toUpperCase() === 'ERROR' ? 'negative' : 'gray'
                                                                            }></IconThumbsDown> 
                                                                        </IconButton>
                                                                    </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                            </div> 
                                            :                                         
                                            // BADGES DE RH
                                            <Badge 
                                                className='cursor d-block mx-auto thumbs' onClick={(e) => this.openModal(poliza.folio, poliza.estatus, e)}
                                                highlightType={
                                                    poliza.estatus.toUpperCase() === 'APLICADA' ? 'positive' : 
                                                    poliza.estatus.toUpperCase() === 'ENVIADO' ? 'caution' :
                                                    poliza.estatus.toUpperCase() === 'RECHAZADA' ? 'negative' : 
                                                    poliza.estatus.toUpperCase() === 'ERROR' ? 'negative' : 
                                                    'negative' }
                                                icon={
                                                    poliza.estatus.toUpperCase() === 'APLICADA' ? <IconCheckmark color="informative" size="16" /> :
                                                    poliza.estatus.toUpperCase() === 'ENVIADO' ? <IconSend color="informative" size="16" /> :
                                                    poliza.estatus.toUpperCase() === 'RECHAZADA' ? <IconCloseCircle color="informative" size="16" /> :
                                                    poliza.estatus.toUpperCase() === 'ERROR' ? <IconErrorAlert color="negative" size="16" /> :
                                                    <IconCloseCircle color="negative" size="16" /> 
                                                    }
                                                    >
                                                {
                                                    poliza.estatus.toUpperCase() === 'APLICADA' ? this.props.language.language?.polizasRH.statusApplied : 
                                                    poliza.estatus.toUpperCase() === 'ENVIADO' ? this.props.language.language?.polizasRH.statusSent : 
                                                    poliza.estatus.toUpperCase() === 'RECHAZADA' ? this.props.language.language?.polizasRH.statusDeclined : 
                                                    poliza.estatus.toUpperCase() === 'ERROR' ? this.props.language.language?.polizasRH.statusError :
                                                    this.props.language.language?.polizasRH.statusError                                                    
                                                }
                                                
                                            </Badge> 
                                        }
                                    </TableCell>
                                    
                                </TableRow>
                            ))}
                                
                        </tbody>
                    </Table>

                {/* primera paginacion  */}
                    {/* <div className="row pagination-sdgf mt-5">
                            <div className="col-md-5 show-registers">
                            {this.props.language.language?.polizasRH.showing} {this.state.numRegistrosMin} {this.props.language.language?.polizasRH.showingTo} {this.state.numTotalPolizas > 10 ? this.state.numRegistrosMax : this.state.numTotalPolizas} {this.props.language.language?.polizasRH.showingTotal}  {this.state.numTotalPolizas} {this.props.language.language?.polizasRH.records}.
                            </div>
                            <div className="col-md-7 ">
                                <Pagination className="float-md-right" id="pagination-table-warranty">
                                    <Pagination.Prev
                                        onClick={() => this.state.currentPage > 1 ? this.handlePaginator(2) : null }
                                    />
                                     {
                                        this.state.listPaginacion.map((i, index) =>
                                            ( 
                                                <Pagination.Item
                                                        key={i}
                                                        active={i === this.state.currentPage} 
                                                        onClick={() => (this.setState({currentPage : i}), this.paginador(i) )}
                                                    >
                                                    {i}
                                                </Pagination.Item>
                                            ))
                                    }
                                    <Pagination.Next
                                        onClick={() => this.state.currentPage < this.state.numPaginas ? this.handlePaginator(1) : null}
                                    />

                                </Pagination>

                            </div>
                        </div> */}



                {/* NUEVA PAGINACION */}
                {this.state.disPaginador ? (
                        <div className="row pagination-sdgf mt-3 mb-3" >
                            <div className="col-md-5 show-registers">
                            {this.props.language.language?.polizasRH.showing}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size)- (this.state.paginacion.size-1)} {this.props.language.language?.polizasRH.showingTo}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size)}&nbsp;
                            {this.props.language.language?.polizasRH.showingTotal} {this.state.numTotalPolizas} {this.props.language.language?.polizasRH.records}.
                            </div>
                            <div className="col-md-7 ">
                                <Pagination className="float-md-right" id="pagination-table-warranty">
                                    <Pagination.Prev
                                        onClick={() => 
                                            // this.clickGoToPage((this.state.newListPaginacion[0])-1)
                                            this.clickGoToPage(this.state.paginacion.currentPage - 1)

                                        }

                                    />
                                     {
                                        this.state.newListPaginacion.map((i, index) =>
                                            ( 
                                                <Pagination.Item
                                                        key={i}
                                                        active={i === this.state.paginacion.currentPage}
                                                        onClick={()=>{
                                                            this.clickGoToPage(i)
                                                        }}>
                                                    {/* Pagina {this.state.currentPage} -  */}
                                                    {i}
                                                </Pagination.Item>
                                            ))
                                    }
                                    <Pagination.Next
                                        onClick={() => 
                                            // console.log("click!")
                                            this.clickGoToPage(this.state.paginacion.currentPage + 1)
                                                
                                        }                       
                                    />

                                </Pagination>

                            </div>
                        </div>
                ) : null}

                        

                    
            

            {/* Modal Nueva Poliza */}  
                <Modal
                    id='modal-nueva-poliza'
                    isOpen={this.state.modalNuevaPoliza}
                    size="medium"
                    modalStyle="standard"
                    title={this.props.language.language?.polizasRH.nuevaPolizaTitle}
                    className='modalPolizas'
                    closeFunc={() => this.setState({ modalNuevaPoliza: false })}
                >
                    <form id='form-nueva-poliza' onSubmit={(e) => this.postFile(e)} className='form-row form-border'>
                    <div className="drag-area container-center" 
                        onDragOver={(e) => {e.preventDefault(); console.log('Drag over');}}
                        onDragLeave={(e) => {e.preventDefault(); console.log('Drag leave');}}
                        onDrop={(e) => this.handleFileDrop(e)}
                        >
                        <div className='col-12 mt-5'>
                                <IconButton id='boton-upload' className='cursor mb-4' 
                                        onClick={() => document.getElementById('input-file')?.click()} background='teal'>
                                    <IconUpload label={this.props.language.language?.polizasRH.lblUploadBtn} size={'64'}></IconUpload>
                                </IconButton>
                                <br />
                                <p>{this.props.language.language?.polizasRH.selectFile}</p>
                        </div>
                        <div className='col-12'>
                                <label id='lblFile'>
                                    {this.state.nombreArchivo}
                                    <input id='input-file' type='file' name='file' accept='.xlsx' hidden
                                        onChange={(e) => this.handleFileChange(e)}></input>
                                    {this.state.alertFile ? 
                                        <Alert compact highlightType="negative">{this.props.language.language?.polizasRH.formatError}</Alert> : null
                                    }
                                </label><br /><br />
                                
                        </div>
                                <Button type='submit' disabled={this.state.disEnviar} variant='primary' size='medium'>{this.props.language.language?.polizasRH.btnSent}</Button>
                    </div>
                    </form>
                </Modal>           

                {/* Modal Envio Exitoso */}
                <Modal
                    id='modal-envio-exito'
                    isOpen={this.state.modalEnvioExito}
                    size="medium"
                    modalStyle="standard"
                    title=""
                    className='modalPolizas'
                    closeFunc={() => this.setState({ modalEnvioExito: false })}
                >
                    
                    <form id='form-envio-exitoso' onSubmit={(e) => this.handleFileChange} className='form-row'>
                        <div className='container mt-5'>
                            <div className='row mt-5 mb-5'>
                                <div className='col-12 mb-5'>
                                    <IconSuccessAlert id='icono-envio-exito' color='inverse' size='64'></IconSuccessAlert>
                                </div>
                                <div className='col-12 mb-5'>
                                    <h2>{this.props.language.language?.polizasRH.sentSuccess}</h2>
                                </div>
                                <div className='col-12'>
                                    <Button variant='primary' size='small' onClick={() => this.setState({ modalEnvioExito: false, modalNuevaPoliza : false })}
                                    >{this.props.language.language?.polizasRH.btnAccept}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>     

                {/* Modal Poliza Rechazada */}
                <Modal
                    id='modal-poliza-rechazada'
                    isOpen={this.state.modalPolizaRechazada}
                    size="medium"
                    modalStyle="standard"
                    title={this.props.language.language?.polizasRH.policyRejected}
                    className='modalPolizas'
                    closeFunc={() => this.setState({ modalPolizaRechazada: false })}
                >
                    
                        <div className='container'>
                            <div className='row'>
                                <label className='mt-5'>{this.props.language.language?.polizasRH.lblRejected}</label>
                                <div className='col-12 mt-2'>
                                    <Textarea rows={5}
                                        className='textArea'
                                        defaultValue={this.state.motivo}
                                        disabled
                                    ></Textarea>
                                    <br /><br />
                                    <Button variant='primary' size='small' onClick={() => this.setState({ modalPolizaRechazada: false})}
                                    >{this.props.language.language?.polizasRH.btnAccept}</Button>
                                </div>
                            </div>
                        </div>
                </Modal>  

                {/* Modal error poliza */}
                <Modal
                    id='modal-error'
                    isOpen={this.state.modalErrorPoliza}
                    size="medium"
                    modalStyle="error"
                    title={this.props.language.language?.polizasRH.errorTitle}
                    className='modalPolizas'
                    closeFunc={() => this.setState({ modalErrorPoliza: false })}
                >
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 mt-2'>
                                    <br /><br />
                                    <Textarea rows={5}
                                        className='textArea'
                                        // placeholder={this.state.motivo} 
                                        defaultValue={this.state.motivo}
                                        disabled
                                    ></Textarea>
                                    <br /><br />
                                    <Button variant='primary' size='small' onClick={() => this.setState({ modalErrorPoliza: false})}
                                    >{this.props.language.language?.polizasRH.btnAccept}</Button>
                                </div>
                            </div>
                        </div>
                </Modal> 

                 {/* Modal Autorizar Poliza */}
                 <Modal
                    id='modal-autorizar-poliza'
                    isOpen= {this.state.modalAutorizarPoliza}
                    size="medium"
                    modalStyle="standard"
                    title={this.props.language.language?.polizasRH.authorizeTitle}
                    className='modalPolizas'
                    closeFunc={() => this.setState({ modalAutorizarPoliza: false })}
                >
                    
                        <div className='container mt-5'>
                            <div className='row mt-5 mb-5'>
                                <div className='col-12 mb-5'>
                                    {this.props.language.language?.polizasRH.authorizeConfirmation} <strong>{this.state.folioPoliza}</strong>?
                                </div>
                                <div id='botones-autorizar-poliza'>
                                    <div className='col-4'>
                                        <Button 
                                            type='button' 
                                            variant='secondary' 
                                            size='small'
                                            onClick={() => this.setState({modalAutorizarPoliza : false})}
                                        >{this.props.language.language?.polizasRH.btnCancel}</Button>   
                                    </div>
                                    <div id='boton-autorizar-poliza' className='col-4'>
                                        <Button 
                                            type='button' 
                                            variant='primary' 
                                            size='small'
                                            onClick={() => this.aceptarPoliza(this.state.folioPoliza)}
                                        >{this.props.language.language?.polizasRH.btnAccept}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal>     



                {/* Modal Rechazar Poliza */}
                <Modal
                    id='modal-rechazar-poliza'
                    isOpen={this.state.modalRechazarPoliza}
                    size="large"
                    modalStyle="standard"
                    title={this.props.language.language?.polizasRH.rejectPolicy}
                    className='modalPolizas'
                    closeFunc={() => this.setState({ modalRechazarPoliza: false })}
                >

                    <form id='form-rechazar-poliza' onSubmit={(e) => this.rechazarPoliza(this.state.folioPoliza, this.state.motivo, e)}>
                        <div className='container'>
                            <div className='row'>
                                {/* Alerta */}
                                {/* <Alert highlightType="negative" >
                                    Your account is past due
                                </Alert> */}
                                </div>
                                <div className='row'>
                                <div className='col-12 mt-5'>
                                    <Textarea rows={5}
                                        className='textArea'
                                        placeholder={this.props.language.language?.polizasRH.lblRejected}
                                        value={this.state.motivo}
                                        cols={50}
                                        maxLength={250}
                                        onChange={this.handleTextChange}
                                        required={true}

                                    ></Textarea>
                                </div>
                            </div>

                        </div>
                        <br /><br />
                        <div id='botones-rechazar-poliza' className='row'>
                            <div className='col-4 offset-2'>
                                <Button  type='button' variant='secondary' size='small'
                                onClick={() => this.setState({modalRechazarPoliza : false, motivo : ''})}
                                >{this.props.language.language?.polizasRH.btnCancel}</Button>
                            </div>
                            <div id='boton-rechazar-poliza' className='col-4'>
                                <Button type='submit' variant='primary' size='small' 
                                    // onClick={(e) => this.rechazarPoliza(this.state.folioPoliza, this.state.motivo, e)}
                                >{this.props.language.language?.polizasRH.btnAccept }</Button>
                            </div>
                        </div>
                    </form>
                </Modal> 

                {/* Modal Prueba */}
                <Modal
                    id='modal-prueb-poliza'
                    isOpen={this.state.modalPrueba}
                    size="medium"
                    modalStyle="standard"
                    title="Nueva póliza"
                    className='modalPolizas'
                    closeFunc={() => this.setState({ modalPrueba: false })}
                >

                <form id='form-modal-prueba' onSubmit={(e) => this.postFile(e)} className='form-row form-border'>
                    <div className="drag-area container-center" 
                        onDragOver={(e) => {e.preventDefault(); console.log('Drag over');}}
                        onDragLeave={(e) => {e.preventDefault(); console.log('Drag leave');}}
                        onDrop={(e) => this.handleFileDrop(e)}
                        >
                        <div className="icon col-12 mt-5">
                            <IconUpload size={'64'} color='teal'></IconUpload>
                        </div>
                        <Button className='mt-5' type='button' variant='secondary' size='small'>Browse</Button>
                        <input className='mt-5' type='file' name='file' accept='.xlsx'></input>
                    </div>
                        <Button className='mt-5' type='submit' variant='primary' size='medium'>Enviar</Button>
                </form>
                </Modal>      
                {/* <ExpedientWarranty id='1' qualify/> */}
                </Content>
            </>
        )
    }
    

    componentDidMount () {
            this.getPolizas();
            console.log(this.state.PERFIL);            
            console.log(this.state.PERFIL.accessToken);

            this.clickGoToPage(1)

    }

    public async getPolizas () {
        try {
            const res = await apiService.getPolizas();
            this.setState({
                listaPolizas : res.data, 
                numPaginas : res.data[0].paginas, 
                numTotalPolizas : res.data[0].numRegistros,
                newListPaginacion : loadPaginationList(this.state.paginacion)
            });

                console.log(this.state.newListPaginacion.length)
            
                this.nuevoPaginador(this.state.numRegistros, this.state.paginacion)
            // this.listaPaginacion();
            
            
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                this.setState({
                    modalErrorPoliza : true, 
                    motivo : err.response.data.error
                })
                console.log(err.response.status);
                console.log(err.response.data);                
            }
        }
    }


    public async nuevoPaginador(numeroRegistros : number, paginacion : PaginacionModel){
        console.log("Entrando al nuevo metodo de paginador...");
        try {
            const res = await apiService.getPolizasPaginador2(numeroRegistros, paginacion.currentPage);
            
            console.log(res);

            paginacion.totalItems = res.data[0].numRegistros;
            paginacion.totalPages = res.data[0].paginas;
            
            console.log("loadPaginationList: " + loadPaginationList(paginacion));

            this.setState({
                    listaPolizas : res.data,
                    newListPaginacion: loadPaginationList(paginacion), 
                    currentPage : paginacion.currentPage});
            
            console.log("Current page: " + paginacion.currentPage)
            
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                this.setState({
                    modalErrorPoliza : true, 
                    motivo : err.response.data.error
                })
                console.log(err.response.status);
                console.log(err.response.data);                
            }
        }
    }

    private clickGoToPage (page: number) {
        console.log('Entrando a clickGoToPage...')
        const totalPages: number = this.state.numPaginas;
        if (page>=1 &&  page <= totalPages){
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({paginacion: auxPaginacion})
            this.nuevoPaginador(this.state.numRegistros, auxPaginacion);
        }else{
            console.log('No entro a la condicion...')
        }

    }

    // public listaPaginacion () {
    //     console.log('Entrando a la lista de paginacion...');

    //     let x = this.state.currentPage > 1 ?  this.state.currentPage : 1;
    //     const array: number[] = [];
    //     if( this.state.numPaginas > 1){
    //         for (let i = x; i <=  this.state.numPaginas; i++) { // Math.ceil(this.state.listaPolizas.length/10); i++) {
    //             array.push(i);
    //         }
    //     }

    //     this.setState({ listPaginacion: array })

    // }


    // public async paginador (pagina : number) {
    //     try {
    //         // this.state.numRegistrosMax + 10 < this.state.numTotalPolizas ? this.setState({numRegistrosMax : this.state.numRegistrosMax + 10}) : this.setState({numRegistrosMax : this.state.numTotalPolizas})
    //         const res = await apiService.getPolizasPaginador(pagina);
    //         this.setState({listaPolizas : res.data, numTotalPolizas : res.data[0].numRegistros});
            
    //         pagina > 1 ? this.setState({numRegistrosMin : 1 + (10 * (pagina - 1))}) : this.setState({numRegistrosMin : 1});
    //         pagina > 1 ? this.setState({numRegistrosMax : 
    //             ((10 * pagina) > this.state.numTotalPolizas ? 
    //                 this.state.numTotalPolizas : ((10 * pagina ))
    //                 // this.state.numTotalPolizas : ((10 * pagina - 1) + this.state.numTotalPolizas % this.state.numPaginas)
    //         )})
    //             :
    //                 this.setState({numRegistrosMax : 10});
            
    //         this.listaPaginacion();
    //         console.log(res.data); 
    //     } catch (error) {
    //         const err = error as AxiosError;
    //         if(err.response){
    //             console.log(err.response.status);
    //             console.log(err.response.data);                
    //         }
    //     }
    // }

    postFile = async (e : any) => {
        try {
            e.preventDefault();
            console.log(this.state.formData);
            
            const res = await apiService.postFile(this.state.formData);
            console.log(res.data);
            console.log(res.status);
            
            this.setState({modalNuevaPoliza : false, modalEnvioExito : true, formData : new FormData(), nombreArchivo : '', disEnviar : true})
            this.getPolizas();

        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                this.setState({modalNuevaPoliza : false, 
                                modalErrorPoliza : true, 
                                formData : new FormData(), 
                                nombreArchivo : '', 
                                disEnviar : true,
                                motivo : 'Formato de Póliza incorrecto.' //err.response.data.error
                            })
                console.log(err.response.status);
                console.log(err.response.data.error);                
                console.log(err.response.data); 
            }
            
        }
    }

    public async aceptarPoliza(folioPoliza : number) {
        try {
            const res = await apiService.polizaAutorizada(folioPoliza);
            console.log(res.data); 
            this.setState({modalAutorizarPoliza : false});
            this.getPolizas();
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                this.setState({modalAutorizarPoliza : false, 
                    modalErrorPoliza : true, 
                    motivo : err.response.data.error
                })
                console.log(err.response.status);
                console.log(err.response.data);                
            }
        }
    }

    private async rechazarPoliza(folioPoliza : number, motivo : string, e : any) {
        try {   
            e.preventDefault();
            if(this.state.motivo === ''){
                this.setState({alertEmpty : true, disEnviar : true})
            }else{
                console.log('folio: ' + folioPoliza + ' -   motivo: ' + motivo);
                
                const res = await apiService.polizaRechazada(folioPoliza, motivo);
                console.log(res.data); 

                this.setState({modalRechazarPoliza : false, motivo : ''})
                this.getPolizas();
            }
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                this.setState({modalRechazarPoliza : false, 
                    modalErrorPoliza : true, 
                    motivo : err.response.data.error
                })
                console.log(err.response.status);
                console.log(err.response.data);                
            }    
        }
    }

    descargaArchivo = async (archivo : String) => {
        try {
            const res = await apiService.getDescargaArchivo(archivo);
            this.setState({url : res.data.archivo});   
            
            console.log('archivo: ' + archivo);            
            console.log('res: ' + res.data.archivo); 

            document.getElementById('boton-descarga')?.click();       
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                console.log(err.response.status);
                console.log(err.response.data);                
            }
        }
    }

    public async buscar(folio : string) {
        console.log(folio);
        
        try {
            if(folio){
                const res = await apiService.getPolizaFiltro(Number(folio));
                console.log(res.data); 
                this.setState({listaPolizas : res.data, disPaginador : false})           
            }else{
                const res = await apiService.getPolizas();
                this.setState({listaPolizas : res.data, disPaginador : true})           
            }
            console.log('folio: ' + folio);        
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                console.log(err.response.status);
                console.log(err.response.data);                
            }
        }
    }

    async estatusPoliza (folio : number) {
        try {
            const res = await apiService.getEstatusPoliza(folio);        
            console.log(res.data); 
            this.setState({motivo : res.data.motivo});
            console.log('Motivo: ' + this.state.motivo);
            
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                console.log(err.response.status);
                console.log(err.response.data);                
            }
        }
    }

    async errorPoliza (folio : number) {
        try {
            const res = await apiService.getErrorPoliza(folio);        
            console.log(res.data[0]); 
            this.setState({motivo : res.data[0].motivo});
            console.log('Motivo: ' + this.state.motivo);
            
        } catch (error) {
            const err = error as AxiosError;
            if(err.response){
                console.log(err.response.status);
                console.log(err.response.data);                
            }
        }
    }
}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language,
});


export default connect(mapStateToProps) (PolizasRH);

interface PolizasRHState {
    modalNuevaPoliza : boolean,
    modalEnvioExito : boolean,
    modalErrorPoliza : boolean,
    modalPolizaRechazada : boolean,
    modalAutorizarPoliza : boolean,
    modalRechazarPoliza : boolean,
    modalPrueba : boolean,
    disEnviar : boolean,
    nombreArchivo : string,
    formData : FormData,
    listaPolizas : PolizaModel[],
    folioPoliza : number,
    url : string,
    motivo : string,
    paginacion : PaginacionModel,
    numRegistrosMax : number,
    numRegistrosMin : number,
    listPaginacion : number [],
    PERFIL : UserModel,
    rolEjecutivo : string,
    numTotalPolizas : number,
    dragActive : boolean,
    disTextArea : boolean,
    disThumb : boolean,
    numPaginas : number,
    currentPage : number,
    allowPost : boolean,
    alertFile : boolean,
    alertEmpty : boolean,

    newPaginacion : PaginacionModel,
    newListPaginacion : number[],
    numRegistros : number,

    disPaginador : boolean
}


