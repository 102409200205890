import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {
    Button,
    Table,
    TableCell,
    TableRow,
    IconAddItem,
    Modal as ModalLiberty
} from "@lmig/lmds-react";
import {
    formatCurrency,
    initLineaAfianzamiento,
    initPaginacion,
    loadPaginationList,
    validRoles
} from "../../../class/CommonClass";
import {LineaAfinzamiento} from "../../../models/garantias/LineaAfinzamiento";
import {Modal, ProgressBar} from "react-bootstrap";
import './LineaDeAfianzamiento.scss';
import {
    FaTrashAlt, FaSync
} from "react-icons/fa";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {CreateLineaAfianzamiento} from "../create-linea-afianzamiento/CreateLineaAfianzamiento";
import {DeleteLineaAfianzamiento} from "../delete-linea-afianzamiento/DeleteLineaAfianzamiento";
import {EditLineaAfianzamiento} from "../edit-linea-afianzamiento/EditLineaAfianzamiento";
import {ProductosReadMore} from "../productos-read-more/ProductosReadMore";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
interface LineaCreditoProps {
    idPrincipal: number;
}
export const LineaDeAfianzamiento: React.FC<LineaCreditoProps> = ({idPrincipal}:LineaCreditoProps)=> {
    const languageState = useSelector((state: RootStore) => state.language.language?.lineaCreditoLanguage);

    const localStorageClass = new LocalStorageClass();

    const [loadingLineaAfianzamiento, setLoadingLineaAfianzamiento] = useState<boolean>(true);

    const [erroLineaAfianzamiento, setErrorLineaAfianzamiento] = useState<boolean>(false);

    const [listLineasAfianzamiento, setListLineasAfianzamiento] = useState<LineaAfinzamiento []>([]);

    const [paginacion, setPaginacion] = useState<PaginacionModel>(initPaginacion);

    const [listPaginacion, setListPaginacion] = useState<number[]>([]);

    const [openModalLinea, setOpenModalLinea] = useState<boolean>(false);

    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

    const [deleteLineaAfianzamiento, setDeleteLineaAfianzamiento] = useState<LineaAfinzamiento>(initLineaAfianzamiento);

    const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);

    const [editLineaAfianzamiento, setEditLineaAfianzamiento] = useState<LineaAfinzamiento>(initLineaAfianzamiento);

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const getLineasDeAfianzamiento = async (idFiado: number) => {
        setLoadingLineaAfianzamiento(true);
        setErrorLineaAfianzamiento(false);
        try {
            const res = await garantiasService.getLineaAfianzamiento(idFiado);
            loadPagination(res.data);
            setLoadingLineaAfianzamiento(false);

        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingLineaAfianzamiento(false);
                setErrorLineaAfianzamiento(true);
            }
        }

    }

    const loadPagination = (lineasAfianzamiento: LineaAfinzamiento[]) => {
        const page = 10;
        const totalitems = (lineasAfianzamiento.length);
        const paginacion: PaginacionModel = {
            currentPage: 1,
            totalItems: totalitems,
            totalPages: Math.ceil(totalitems/ page) ,
            size: 10
        }
        setPaginacion(paginacion);
        setListLineasAfianzamiento(lineasAfianzamiento);
        setListPaginacion(loadPaginationList(paginacion));
    }

    const clickGoToPage = (page: number) => {

    }

    const openModalNewLine = () => {
        setOpenModalLinea(true);

    }
    const openModalConfirmDelete = (pos: number) => {
        setDeleteLineaAfianzamiento(listLineasAfianzamiento[pos])
        setOpenModalDelete(true);

    }
    const openModalToEdit = (pos: number) => {
        setEditLineaAfianzamiento(listLineasAfianzamiento[pos])
        setOpenModalEdit(true);
    }

    const deletedLineaAfianzamiento = (lineaAf: LineaAfinzamiento) => {
      const auxListLineasAfianzamiento:LineaAfinzamiento [] = [...listLineasAfianzamiento];
      let pos = 0;
        auxListLineasAfianzamiento.map((value, index) => {
            if (lineaAf.idLineaAf == value.idLineaAf){
                pos = index;
            }
        })
        auxListLineasAfianzamiento.splice(pos, 1);
        setListLineasAfianzamiento(auxListLineasAfianzamiento);
        setOpenModalDelete(false);
    }

    const editedLineaAfianzamiento = (newLinea: LineaAfinzamiento, oldLinea: LineaAfinzamiento) => {
        const auxListLineasAfianzamiento:LineaAfinzamiento [] = [...listLineasAfianzamiento];
        let pos = 0;
        for (let i= 0; i< auxListLineasAfianzamiento.length; i++){

          //  console.log(auxListLineasAfianzamiento[i].folio + ' == ' +oldLinea.folio );
            if (auxListLineasAfianzamiento[i].folio == oldLinea.folio){
                pos = i;
                break;
            }
        }

        auxListLineasAfianzamiento.splice(pos, 1);
        auxListLineasAfianzamiento.splice(0, 0, newLinea); // agregamos la nueva linea de afianzamiento
        setListLineasAfianzamiento(auxListLineasAfianzamiento);
        setOpenModalEdit(false);
    }

    useEffect(() => {
        getLineasDeAfianzamiento(idPrincipal)
    },[idPrincipal]);

    return (
        <>
            {loadingLineaAfianzamiento? (
                    <div className="w-100">
                        <SpinnerLoading
                            text={languageState?.loadingLineasAfianzamiento!}/>
                    </div>
                ):
                (erroLineaAfianzamiento)? (
                        <div className="col-md-12">
                            <NegativeAlert
                                textAlert={languageState?.errorLineasAfianzamiento!}/>
                        </div>
                    ):

                    <div className="row" id="linea-de-afianzamiento">
                        <div className="col-md-9">
                        </div>
                        <div className="col-md-3">
                            {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
                                validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)?
                                    <Button dynamicWidth
                                            className="w-100"
                                            variant="primary"
                                            id="create-line"
                                            onClick={event => {
                                                openModalNewLine()
                                            }}
                                    >
                                        <IconAddItem size="16"
                                                     title= {languageState?.createLine!}
                                                     color="blue"/>
                                        &nbsp;
                                        {languageState?.createLine!}
                                    </Button>
                                    :
                                    null
                            )}

                            <br/>
                        </div>
                        <div className="col-md-12">

                            {(listLineasAfianzamiento.length> 0) &&
                                <Table>
                                    <thead>
                                    <TableRow>
                                        <TableCell  dataHeader={languageState?.invoice} className="text-center" type="colHead">
                                            {languageState?.invoice}
                                        </TableCell>
                                        <TableCell dataHeader={languageState?.products} className="text-center " type="colHead">
                                            {languageState?.products}
                                        </TableCell>
                                        <TableCell dataHeader={languageState?.montoMaximoEv}  type="colHead">
                                            {languageState?.montoMaximoEv}
                                        </TableCell>
                                        <TableCell dataHeader={languageState?.movements} className="text-center" type="colHead">
                                            {languageState?.movements}
                                        </TableCell>

                                        <TableCell dataHeader={languageState?.actions} className="text-center" type="colHead">
                                            {languageState?.actions}
                                        </TableCell>

                                    </TableRow>
                                    </thead>
                                    <tbody>
                                    {
                                        listLineasAfianzamiento.map((i, index) =>
                                            (
                                                <TableRow key={index}>
                                                    <TableCell dataHeader={languageState?.invoice} type="rowHead" className="text-center">
                                                        {i.folio}
                                                    </TableCell>
                                                    <TableCell dataHeader={languageState?.products} className="text-center" >
                                                        <ProductosReadMore
                                                            idLineaAfianzamiento={i.idLineaAf!}
                                                            productos={i.productos}
                                                        />

                                                    </TableCell>
                                                    <TableCell dataHeader={languageState?.montoMaximoEv} >
                                                        {formatCurrency(i.montoMaximoEv)}
                                                    </TableCell>

                                                    <TableCell dataHeader={languageState?.movements} >
                                                        <div className="row">
                                                            <div className="col-6 text-left ">
                                                                <b>{languageState?.amountAccumulated}</b><br/>
                                                                {formatCurrency(i.montoAC)}
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <b>{languageState?.montoMaximoAc}</b><br/>
                                                                {formatCurrency(i.montoMaximoAc)}
                                                            </div>
                                                        </div>
                                                        <ProgressBar>
                                                            <ProgressBar id="progress-monto-maxiomo-ac"  now={(i.montoAC * 100)/i.montoMaximoAc} key={1} />
                                                        </ProgressBar>
                                                    </TableCell>
                                                    <TableCell dataHeader={languageState?.actions} >
                                                        <div className="row">
                                                            <div className="col-6 text-center">
                                                                <button onClick={event => {
                                                                    openModalConfirmDelete(index)
                                                                }} type="button" className="btn btn-light text-danger">
                                                                    <FaTrashAlt title={languageState?.deleteLineaAfianzamiento}/>
                                                                </button>

                                                            </div>
                                                            <div className="col-6 text-center">
                                                                <button onClick={event => {
                                                                    openModalToEdit(index)
                                                                }} type="button" className="btn btn-light text-primary">
                                                                    <FaSync title={languageState?.renewal}/>

                                                                </button>
                                                            </div>

                                                        </div>

                                                    </TableCell>


                                                </TableRow>
                                            ))
                                    }

                                    </tbody>
                                </Table>
                            }

                            <div className="row pagination-sdgf">
                               {/*
                               <div className="col-md-5 show-registers">
                                    {languageState?.showing}&nbsp;
                                    {(paginacion.currentPage * paginacion.size)- (paginacion.size-1)}  {languageState?.to}&nbsp;
                                    {(paginacion.currentPage * paginacion.size)}  {languageState?.of}&nbsp;
                                    {paginacion.totalItems}  {languageState?.entries}.
                                </div>
                                <div className="col-md-7 ">
                                    <Pagination className="float-md-right" id="pagination-table-warranty">
                                        <Pagination.Prev
                                            onClick={()=>{
                                                clickGoToPage((listPaginacion[0])-1)
                                            }}
                                        />
                                        {
                                            listPaginacion.map((i, index) =>
                                                ( <Pagination.Item
                                                        key={i}
                                                        active={i === paginacion.currentPage}
                                                        onClick={()=>{
                                                            clickGoToPage(i)
                                                        }}>
                                                        {i}
                                                    </Pagination.Item>

                                                ))
                                        }
                                        <Pagination.Next
                                            onClick={()=>{
                                                clickGoToPage(listPaginacion[listPaginacion.length-1]+1)
                                            }}
                                        />

                                    </Pagination>

                                </div>
                                */}

                            </div>


                        </div>

                    </div>

            }
            <Modal
                show={openModalLinea}
                size="lg"
                aria-labelledby=""
                onHide={() => {setOpenModalLinea(false)}}

            >
                <Modal.Header closeButton>
                    <Modal.Title>{languageState?.createLine}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateLineaAfianzamiento
                        idPrincipal={idPrincipal}
                        newLinea={newLinea => {
                            const newlistLineasAfianzamiento: LineaAfinzamiento [] = [...listLineasAfianzamiento];
                            newlistLineasAfianzamiento.splice(0, 0, newLinea); // agregamos la nueva linea de afianzamiento
                            setListLineasAfianzamiento(newlistLineasAfianzamiento); // seteamos la nueva lista de lineas de afianzamento
                            loadPagination(newlistLineasAfianzamiento); // creamos la paginacion
                            setOpenModalLinea(false);
                        }}
                        closeModal={() => {
                            setOpenModalLinea(false)
                        }}/>

                </Modal.Body>

            </Modal>

            <ModalLiberty
                isOpen={openModalDelete}
                size="medium"
                modalStyle="standard"
                title={languageState?.deleteLineaAfianzamiento}
                closeFunc={() => setOpenModalDelete(false)}>
                <DeleteLineaAfianzamiento
                    deleteLineaAfianzamiento={deleteLineaAfianzamiento}
                    deletedLinea={lineaAf => {
                        deletedLineaAfianzamiento(lineaAf);
                    }}
                    closeModal={() => {setOpenModalDelete(false)}}
                    />
            </ModalLiberty>



            <Modal
                show={openModalEdit}
                size="lg"
                aria-labelledby=""
                onHide={() => {setOpenModalEdit(false)}}

            >
                <Modal.Header closeButton>
                    <Modal.Title>{languageState?.editLineaAfianzamiento + ' ' + editLineaAfianzamiento.folio} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditLineaAfianzamiento
                        idPrincipal={idPrincipal}
                        lineaAfianzamiento={editLineaAfianzamiento}
                        editedLinea={lineaAfinzamiento => {
                            editedLineaAfianzamiento(lineaAfinzamiento, editLineaAfianzamiento)
                        }}
                        closeModal={() => {setOpenModalEdit(false)}}/>

                </Modal.Body>

            </Modal>


        </>
    );
}
