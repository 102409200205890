export interface ViewAuditoriasLanguageModel {

    //BreadCumb
    Home: string,
    NewAudi:string,
    //Tabla
    FolioAuditoria:string,
    FoliosProce:string,
    Fecha:string
    Oficina:string
    NumerFolios:string
    Consultas:string;
    Reporte:string;

    //Botones
    Finalizar:string;


}

export const ViewAuditoriasLanguageEnglish: ViewAuditoriasLanguageModel = {

    //BreadCumb
    Home:'Home',
    NewAudi:'View Audit',
     //Tabla
     FolioAuditoria:'Invoice Audit',
    FoliosProce:'Processed folios',
    Fecha:'Date',
    Oficina:'Office',
    NumerFolios:'Number Folios',
    Consultas:'Duplicate queries',
    Reporte:'Report',
    Finalizar:'Finalize'

}


export const ViewAuditoriasLanguageSpanish: ViewAuditoriasLanguageModel = {

    //BreadCumb
    Home:'Home',
    NewAudi:'Visualizar Auditoria',
    FolioAuditoria:'Folio Auditoria',
    FoliosProce:'Folios Procesados',
    Fecha:'Fecha',
    Oficina:'Oficina',
    NumerFolios:'Numero de Folios',
    Consultas:'Consultas Duplicadas',
    Reporte:'Reporte',
    Finalizar:'Finalizar'


}