export interface CreditBureauLanguageModel {
    credittitle:string,
    creditproced:string,
    creditactive:string,
    creditopen:string,
    creditsearch:string,
    creditbutton:string,
    creditoverdue:string,
    creditFechAuto:string,
    creditfolio:string,
    creditBCalif:string,
    creditUsername:string,
    creditname:string,
    credittype:string,
    creditoffice:string,
    creditbc:string,
    creditdate:string,
    creditstatus:string,
    creditconsult: string,
    creditmostrar:string,
    credital:string,
    creditotal:string,
    creditrecors:string,
    data:string,
    alert:string,
    enprocess:string,
    vigent:string,
    vencid:string,
    error:string,
    subirCarta:string,
    archivoSelect:string,
    btnSave:string,
    titleCarta:string,
    titleViewCarta:string
  

}

export const CreateCreditBureauLanguageEnglish: CreditBureauLanguageModel ={
    credittitle:'Credit Bureau',
    creditproced:'In Process',
    creditactive: 'Active',
    creditopen: 'Open',
    creditsearch:'Search...',
    creditbutton:'New Query',
    creditBCalif:'Score BC',
    creditoverdue:'Overdue',
    creditFechAuto:'date letter',
    creditfolio:'Invoice',
    creditname:'Name',
    credittype:'Type',
    creditoffice:'Office',
    creditdate:'consultation date',
    creditbc:'Invoice BC',
    creditstatus:'Status',
    creditUsername: 'UserName',
    creditconsult:'Consult',
    creditmostrar:'Showing records of',
    credital:'to the',
    creditotal:'out of a total of',
    creditrecors:'records',
    data:'No data found',
    alert:'No queries available',
    enprocess:'In process',
    vigent:'In force',
    vencid:'Expired',
    error:'Error',
    subirCarta:'Upload letter',
    archivoSelect:'Selected file',
    btnSave:'Save and continue',
    titleCarta:'Add letter',
    titleViewCarta:'Consult the Charters'
    
}


export const CreateCreditBureauLanguageSpanish: CreditBureauLanguageModel ={
    credittitle:'Buró de Crédito',
    creditproced: 'En Proceso',
    creditactive: 'Vigentes',
    creditopen: 'Abrir',
    creditUsername:'Usuario',
    creditsearch:'Buscar...',
    creditbutton:'Nueva Consulta',
    creditoverdue:'Vencidas',
    creditBCalif:'Calificación BC',
    creditfolio:'Folio',
    creditname:'Nombre/Razon Social',
    credittype:'Tipo',
    creditFechAuto:'Fecha Carta',
    creditdate:'Fecha Consulta',
    creditbc:'Folio BC',
    creditoffice:'Oficina',
    creditstatus:'Estatus',
    creditconsult:'Consultar',
    creditmostrar:'Mostrando registros de',
    credital:'al',
    creditotal:'de un total de',
    creditrecors:'registros',
    data:'No se encontraron datos',
    alert:'No hay consultas disponibles',
    enprocess:'En proceso',
    vigent:'Vigentes',
    vencid:'Venvidas',
    error:'Error',
    subirCarta:'Subir Carta',
    archivoSelect:'Archivo Seleccionado',
    btnSave:'Guardar y continuar',
    titleCarta:'Agregar carta',
    titleViewCarta:'Consultar Cartas'
}