export interface AutoEngagmentsLanguageModel {
    
   
    titulo: string 
    nombreDocumento: string 
    buscar: string
    
}

export const AutoEngagmentsLanguageEnglish: AutoEngagmentsLanguageModel ={
    
    
    titulo: 'Authorize Compromises',
    nombreDocumento: 'Authorizations',
    buscar: 'Search',

   
}


export const AutoEngagmentsLanguageSpanish: AutoEngagmentsLanguageModel ={
    
    
    titulo: 'Autorización de Compromisos',
    nombreDocumento: 'Autorizaciones',
    buscar: 'Buscar',
    

}