
import React, { ChangeEvent, Children, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../../reducer/LanguageReducer';
import { RootStore } from '../../../../Store';
import NavBar from '../../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../../class/LocalStorageClass';
import Select from '@lmig/lmds-react/Form/Select/Select';
import SelectOption from '@lmig/lmds-react/Form/Select/SelectOption';
import { Alert, AlphanumericInput, Badge, Button, GridCol, GridRow, Heading, IconDocumentMulti, IconDownload, IconUpload, Modal, Show, Table, TableCell, TableRow } from '@lmig/lmds-react';
import './Create-Consult.scss'
import { BreadCumb } from '../../../../components/public/breadcumb/BreadCumb';
import { PipeLineIcon } from '../../../../components/public/pipeline-steps/PipelineIcon';
import Dropzone from 'react-dropzone';
import { Entidad } from '../../../../models/buro-credito/entidad';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../../../services/buro-credito/CreditbureauService"
import * as apiS3Services from "../../../../services/api-s3/ApiS3Services";
import { ConsultaBuro } from '../../../../models/buro-credito/ConsultaBuro';
import { DelegacionMunicipio, estado } from '../../../../models/buro-credito/cp';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { Fisicas } from '../../../../models/buro-credito/Fisicas';
import { Morales } from '../../../../models/buro-credito/Morales';
import { PaginacionModel } from '../../../../models/public/PaginacionModel';
import { initPaginacion, loadPaginationList, validFilepdf, validRoles } from '../../../../class/CommonClass';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { SpinnerLoading } from '../../../../components/public/spinner-loading/SpinnerLoading';
import Autocomplete from 'react-autocomplete';
import { UserModel } from '../../../../models/public/UserModel';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { PDFMoralesAgent } from '../../../../components/buro-credito/PDFMoralesAgent';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { PDFFileAgent } from '../../../../components/buro-credito/PDFFileAgent';
import { PillButton } from '@lmig/lmds-react-pill-button/dist/PillButton';
import { foliosVigent } from '../../../../models/buro-credito/Folios_vigentes';
import { TiScissors } from 'react-icons/ti';

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface CreatequeryProps {
    language: DefaultStateI
}


class Createconsult extends Component<CreatequeryProps & RouteComponentProps, CreatequeryState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    public idAgente = "";
    public archivo = "";
    public initConsult: ConsultaBuro = {
        nombre: '',
        tipoEntidad: '',
        tipoPersona: '',
        rfc: '',
        segundoNombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaNacimiento: '',
        nombreAgente: '',
        sexo: '',
        codigoPostal: '',
        calle: '',
        colonia: '',
        delegacionMunicipio: '',
        numexterior: '',
        folio: '',
        estado: '',
        estadoRepublica: '',
        numinterior: '',
        telefono: '',
        estadoDomicilio: '',
        razonSocial: '',
        representante: '',
        codigoBuro: '',
        pais: '157',
        rolSolicitante:''
    }

    options: any;
    valido: boolean | undefined;

    constructor(props: any) {
        super(props);
        const est: estado = {

            estado: "",
            codigoBuro: "",
            delegacionMunicipio: [
                {
                    nombre: "",

                }
            ]
        };
        this.state = {
            estadoCaptura: 1,
            idWarranty: 0,
            loadingWarrantyDetail: false,
            errorWarrantyDetail: false,
            select: '',
            PERFIL: this.localStorageClass.getProfile(),
            status: '',
            validador: 1,
            isOpen: true,
            isClose: false,
            openPDF: true,
            entidad: [],
            listFiadoOption: [],
            datovalid: '',
            consultObject: this.initConsult,
            cp: '',
            mensajeConsult: [],
            estados: est,
            valido: true,
            show: true,
            folio: '',
            datofisica: [],
            condicion: false,
            Consul_buro: false,
            datoMoral: [],
            mensajeF: '',
            mensajeM: '',
            Loading: true,
            formData: new FormData,
            FormFisicas: new FormData,
            validafile: 0,
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            consulta: true,
            datoCaptura: [],
            datoConsul: [],
            search: '',
            tipopersona: '',
            table: 0,
            alerta: 0,
            value: '',
            alerta1: 0,
            alerta2: 0,
            rfc: '',
            boton: true,
            visible:true,
            variable: {
                folio: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                colonia:'',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                codigoPostal: '',
                calle: '',
                estado: '',
                numexterior: '',
                delegacionMunicipio: '',
                numinterior: '',
                telefono: '',
                estadoDomicilio: '',
                razonSocial: '',
                representante: '',
                codigoBuro: ''
            }
        }


        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.Return = this.Return.bind(this);
        //this.handleinputChange = this.handleinputChange.bind(this);
        this.postDatos = this.postDatos.bind(this);
        this.Submit = this.Submit.bind(this);
        this.Cerrar = this.Cerrar.bind(this);
        this.getDatosFiado = this.getDatosFiado.bind(this);
        this.handleOnMenuE = this.handleOnMenuE.bind(this);
        this.handleInputChangeAgent = this.handleInputChangeAgent.bind(this);
        this.handleInputChangeSelectAgent = this.handleInputChangeSelectAgent.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.getEstado = this.getEstado.bind(this);
        this.PdfVis = this.PdfVis.bind(this);
        this.getDatos = this.getDatos.bind(this);
    }

    render(): JSX.Element {
        return <>
            <NavBar />
            <div className="container-fluid warranty" id="create-query">
            
                <div className="create">

                    <BreadCumb title={this.props.language.language?.createquery.credittitle!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito/Solicitudes' },
                            { title: this.props.language.language?.createquery.Create!, url: '/buro-credito/Crear-Solicitud' }
                        ]} />

                    <div className="row" id="header-create">

                        <div className="container">

                            <div className="Pipeline col-md-12 col-sm-12" id='pipeline'>
                                <PipeLineIcon
                                    listSteps={[this.props.language.language?.createquery.pipeline1!,
                                    this.props.language.language?.createquery.pipeline2!,
                                    this.props.language.language?.createquery.pipeline4!]}
                                    currentStep={this.state.estadoCaptura! - 1} />


                            </div>
                        </div>


                        <div className="Formulario" id='formulario'>



                            <Form id="form-query" onSubmit={this.postDatos}>
                                <div className="col-md-12" id='consult'>
                                    {this.state.estadoCaptura == 1 ? (

                                        <div className="col-md-12" id='datos'>

                                            <Modal className='Modal-fiados' isOpen={this.state.isOpen} size="large"
                                                modalStyle="standard" closeFunc={() => {
                                                    this.setState({ isOpen: false });
                                                    this.props.history.push('/buro-credito/Solicitudes');
                                                }}>

                                                <ModalBody>
                                                    <div className="autocomplete-wrapper-fiado" >
                                                        <h1>Buscar Fiado</h1>


                                                        <Autocomplete
                                                            inputProps={{
                                                                placeholder: "Buscar Fiado",
                                                                style: { fontSize: 16, width: "100%", padding: 20 }
                                                            }}
                                                            value={this.state.value}
                                                            items={this.state.listFiadoOption}
                                                            getItemValue={item => item.nombre + item.rfc}
                                                            shouldItemRender={this.renderFiado}
                                                            renderMenu={item => (
                                                                <div className="dropdown">
                                                                    {item}
                                                                </div>
                                                            )}
                                                            renderItem={(item, isHighlighted) => (
                                                                <div
                                                                    className={`item ${isHighlighted ? "selected-item" : "title"
                                                                        }`}
                                                                >
                                                                    {`${item.rfc}--${item.razonSocial} `}
                                                                </div>
                                                            )}

                                                            onMenuVisibilityChange={this.handleOnMenuE}
                                                            onChange={this.handleInputChangeAgent}
                                                            //onSelect={val => this.setState({ val }) }  
                                                            onSelect={this.handleInputChangeSelectAgent}
                                                        // onChange={(event, value) => this.setState({ value }) }
                                                        //onSelect={ value => this.setState({ value }) }

                                                        />
                                                    </div>
                                                    <div className="autocomplete-wrapper-agent"></div>

                                                    <Button id='btn-fiado-busqueda' variant="primary" onClick={this.Cerrar} disabled={this.state.visible}>Continuar</Button>


                                                </ModalBody>
                                            </Modal>

                                            <Modal isOpen={this.state.isClose} size="medium"
                                                className='Modal-fiados'
                                                closeFunc={() => {
                                                    this.setState({ isClose: false });
                                                }}
                                            >
                                                <ModalBody>


                                                    <Alert highlightType="negative">
                                                        <h5> Este RFC {this.state.rfc} ya cuenta con consultas vigentes</h5>
                                                        <h5>Total: {this.state.mensajeConsult.length} </h5>
                                                    </Alert>

                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button onClick={() => {
                                                        this.setState({ isOpen: false });
                                                        this.props.history.push('/buro-credito/Solicitudes');
                                                    }}>Cancelar</Button>
                                                    <Button variant="primary" onClick={() => {
                                                        this.setState({ isClose: false })
                                                    }}>
                                                        Continuar
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                            
                                            <br />
                                            {this.state.alerta == 2 &&


                                                <>
                                                    
                                                    <div className="col-md-12" id='datos'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />


                                                        <div className='col-12' >

                                                            <p>RFC</p>

                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='rfc'
                                                                labelVisual
                                                                value={this.state.consultObject.rfc}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange}
                                                                max={13}
                                                                min={10} />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.name!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='nombre'
                                                                labelVisual
                                                                value={this.state.consultObject.nombre}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />



                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.second!]}</p>

                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='segundoNombre'
                                                                labelVisual
                                                                value={this.state.consultObject.segundoNombre}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />
                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.last!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='apellidoPaterno'
                                                                labelVisual
                                                                value={this.state.consultObject.apellidoPaterno}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.mother!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='apellidoMaterno'
                                                                labelVisual
                                                                value={this.state.consultObject.apellidoMaterno}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.date!]}</p>

                                                            <input
                                                                type='date'
                                                                id="fechaNacimiento"
                                                                name='fechaNacimiento'
                                                                className='form-control'
                                                                min="1936-01-01" max="2005-12-31"
                                                                placeholder={this.state.consultObject.fechaNacimiento}
                                                                value={this.state.consultObject.fechaNacimiento}
                                                                onChange={(e) => this.handleinputChange(e)}
                                                                onBlur={this.handleinputChange}

                                                            />
                                                            <br />
                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='telefono'
                                                                labelVisual
                                                                value={this.state.consultObject.telefono}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            <br />

                                                            <p>{[this.props.language.language?.createquery.sex!]}</p>
                                                            <Select
                                                                id="sexo"
                                                                name='sexo'
                                                                labelVisual={this.props.language.language?.createquery.select!}
                                                                value={this.state.consultObject.sexo}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange}
                                                            >
                                                                <SelectOption value="M">
                                                                    Hombre
                                                                </SelectOption>
                                                                <SelectOption value="F">
                                                                    Mujer
                                                                </SelectOption>
                                                            </Select>
                                                        </div><br /><div className="col-md-12" id='datos'>
                                                            <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                            <hr />


                                                            <div className='col-12'>

                                                                <p>C.P</p>
                                                                <AlphanumericInput
                                                                    id="Alphanumeric--default"
                                                                    name='codigoPostal'
                                                                    labelVisual
                                                                    value={this.state.consultObject.codigoPostal}
                                                                    onChange={this.handleinputChange}
                                                                    onBlur={this.handleinputChange} />



                                                                {this.state.alerta2 == 2 &&
                                                                    <>
                                                                        <Alert highlightType="negative">
                                                                            {[this.props.language.language?.createquery.codig!]}
                                                                        </Alert>
                                                                    </>}

                                                                <br />
                                                                <p>{[this.props.language.language?.createquery.state!]}</p>

                                                                <input
                                                                    id="estado"
                                                                    name='estado'
                                                                    className='form-control'
                                                                    onChange={this.handleinputChange}
                                                                    placeholder={this.state.consultObject.estado}
                                                                    value={this.state.estados?.estado}
                                                                    disabled
                                                                />
                                                                <input
                                                                    id="pais"
                                                                    name='pais'
                                                                    onChange={this.handleinputChange}
                                                                    value='157'
                                                                    hidden />



                                                                <br />
                                                                <p>{[this.props.language.language?.createquery.town!]}</p>
                                                                <Select
                                                                    id="delegacionMunicipio"
                                                                    name='delegacionMunicipio'
                                                                    onChange={this.handleinputChange}
                                                                    labelVisual
                                                                    value={this.state.consultObject.delegacionMunicipio}
                                                                >
                                                                    <SelectOption value="0">
                                                                        {this.state.consultObject.delegacionMunicipio}
                                                                    </SelectOption>

                                                                    {this.state.estados.delegacionMunicipio.map((i: DelegacionMunicipio, index: number) => (
                                                                        <SelectOption value={i.nombre.toString()} key={index}>
                                                                            {i.nombre}
                                                                        </SelectOption>
                                                                    ))}


                                                                </Select>

                                                                <br />


                                                                <p>{[this.props.language.language?.createquery.street!]}</p>
                                                                <AlphanumericInput
                                                                    id="Alphanumeric--default"
                                                                    name='calle'
                                                                    labelVisual
                                                                    value={this.state.consultObject.calle}
                                                                    onChange={this.handleinputChange}
                                                                    onBlur={this.handleinputChange} />

                                                                <br />
                                                                <p>{[this.props.language.language?.createquery.number!]}</p>
                                                                <AlphanumericInput
                                                                    id="Alphanumeric--default"
                                                                    name='numexterior'
                                                                    labelVisual
                                                                    value={this.state.consultObject.numexterior}
                                                                    onChange={this.handleinputChange}
                                                                    onBlur={this.handleinputChange} />

                                                                <br />
                                                                <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                                <AlphanumericInput
                                                                    id="Alphanumeric--default"
                                                                    name='numinterior'
                                                                    labelVisual
                                                                    value={this.state.consultObject.numinterior}
                                                                    onChange={this.handleinputChange}
                                                                    onBlur={this.handleinputChange} />

                                                                <br />
                                                                <GridRow gutterSize="fixed-16" gutters>

                                                                    <GridCol base={12} md={12}>
                                                                        <Button dynamicWidth variant="primary" onClick={this.NextPage} >
                                                                            {this.props.language.language?.createquery.NextPage!}
                                                                        </Button>
                                                                    </GridCol>
                                                                </GridRow>
                                                            </div>


                                                        </div>


                                                    </div>

                                                </>
                                            }

                                            {this.state.alerta == 1 &&


                                                <>


                                                    <div className="col-12" id='datos'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>

                                                            <p>RFC</p>

                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='rfc'
                                                                labelVisual
                                                                value={this.state.consultObject.rfc}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange}
                                                                max={10}
                                                                min={10} />


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.razonsocial!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='razonSocial'
                                                                labelVisual
                                                                value={this.state.consultObject.razonSocial}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.representante!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='representante'
                                                                labelVisual
                                                                value={this.state.consultObject.representante}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            {this.state.alerta1 == 1 &&
                                                                <>
                                                                    <Alert highlightType="negative">
                                                                        {[this.props.language.language?.createquery.obli!]}
                                                                    </Alert>
                                                                </>}
                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='telefono'
                                                                labelVisual
                                                                value={this.state.consultObject.telefono}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            <br />

                                                        </div>
                                                    </div><br /><div className="col-md-12" id='datos'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col'>

                                                            <p>C.P</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='codigoPostal'
                                                                labelVisual
                                                                value={this.state.consultObject.codigoPostal}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />



                                                            {this.state.alerta2 == 2 &&
                                                                <>
                                                                    <Alert highlightType="negative">
                                                                        {[this.props.language.language?.createquery.codig!]}
                                                                    </Alert>
                                                                </>}

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.state!]}</p>

                                                            <input
                                                                id="estadoRepublica"
                                                                name='estadoRepublica'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                placeholder={this.state.consultObject.estadoRepublica}
                                                                value={this.state.estados?.estado}
                                                                disabled
                                                            />

                                                            <input
                                                                id="pais"
                                                                name='pais'
                                                                onChange={this.handleinputChange}
                                                                value='157'
                                                                hidden />

                                                          


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="delegacionMunicipio"
                                                                name='delegacionMunicipio'
                                                                onChange={this.handleinputChange}
                                                                labelVisual={this.state.consultObject.colonia}
                                                                value={this.state.consultObject.delegacionMunicipio}

                                                            >

                                                                <SelectOption value="0">
                                                                    {this.state.consultObject.delegacionMunicipio}
                                                                </SelectOption>
                                                                {this.state.estados.delegacionMunicipio.map((i: DelegacionMunicipio, index: number) => (
                                                                    <SelectOption value={i.nombre.toString()} key={index}>
                                                                        {i.nombre}
                                                                    </SelectOption>
                                                                ))}


                                                            </Select>

                                                            <br />


                                                            <p>{[this.props.language.language?.createquery.street!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='calle'
                                                                labelVisual
                                                                value={this.state.consultObject.calle}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.number!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='numexterior'
                                                                labelVisual
                                                                value={this.state.consultObject.numexterior}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='numinterior'
                                                                labelVisual
                                                                value={this.state.consultObject.numinterior}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />


                                                            <br />
                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}  >
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div>


                                                </>
                                            }
                                        </div>



                                    ) : null}
                                    {this.state.estadoCaptura == 2 ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-6" >
                                                    <div className="card" id='cards'>
                                                        <div className="card-body text-center">
                                                            <h3 className="card-title">{this.props.language.language?.createquery.format!}</h3><br /><br />
                                                            {this.state.variable.tipoPersona == 'Fisica' &&
                                                                <>
                                                                    <div className="icon-wrapper">
                                                                        {this.state.condicion == true &&
                                                                            <>
                                                                                <PDFDownloadLink document={<PDFFileAgent />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                                    {({ loading }) =>
                                                                                        loading ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <IconDownload size="64" color='gray' />
                                                                                        )
                                                                                    }
                                                                                </PDFDownloadLink>
                                                                            </>}
                                                                    </div>
                                                                </>}

                                                            {this.state.variable.tipoPersona == 'Moral' &&
                                                                <>
                                                                    <div className="icon-wrapper">
                                                                        {this.state.condicion == true &&
                                                                            <>
                                                                                <PDFDownloadLink document={<PDFMoralesAgent />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                                    {({ loading }) =>
                                                                                        loading ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <IconDownload size="64" color='gray' />
                                                                                        )
                                                                                    }
                                                                                </PDFDownloadLink>
                                                                            </>}
                                                                    </div>
                                                                </>}
                                                            {this.props.language.language?.createquery.downl!}
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card" id='cards'>
                                                        <div className="file-upload" id='image-upload-wrap'>

                                                            <input
                                                                type="file"
                                                                id='file-upload-input'
                                                                accept='application/pdf'
                                                                onChange={this.handleChange} />
                                                            <div className="card-body text-center">
                                                                <h3 className="card-title">{this.props.language.language?.createquery.auto!}</h3> <br /><br />

                                                                <div className="text-information">
                                                                    <IconUpload size="64" color='gray' /><br />
                                                                    {this.props.language.language?.createquery.arrastra!} <br />
                                                                    {this.props.language.language?.createquery.file!}

                                                                    {this.state.consulta == false &&
                                                                        <div className='col-12' id='alert'>
                                                                            <Alert id='alert_up' highlightType="positive">
                                                                                {this.props.language.language?.createquery.alert_up!}
                                                                            </Alert>
                                                                        </div>}

                                                                </div>
                                                            </div>
                                                            <Button className='btn-archivo' size="small" variant="primary" onClick={this.PdfVis} hidden={this.state.openPDF}>
                                                                Visualizar archivo
                                                            </Button>
                                                        </div>


                                                    </div>



                                                    <Modal isOpen={this.state.isOpen} size="large"
                                                        className='Modal-pdf'
                                                        closeFunc={() => {
                                                            this.setState({ isOpen: false });
                                                        }}
                                                    >

                                                        <iframe className="iframe-pdf" src={this.archivo} allowFullScreen ></iframe>


                                                    </Modal>
                                                </div>
                                            </div>

                                            <br /><br />
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth variant="primary" onClick={this.Submit} disabled={this.state.consulta} >
                                                        {this.props.language.language?.createquery.NextPage!}
                                                    </Button>
                                                </GridCol>
                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura == 3 ? (
                                        <>

                                            <div className="col-12" >
                                                <div className="card" id='cards'>
                                                    <div className="card-body text-center"><br /><br />
                                                        <br />
                                                        <div className="icon-wrapper">
                                                            <BsFillCheckCircleFill size="64" color='green' /><br /><br />
                                                        </div>
                                                        <h4 className="card-title">Datos enviados a revision</h4>

                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <br /><br />
                                            <div className="boton" >
                                                <GridRow gutterSize="fixed-16" gutters>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth onClick={this.PrevPage}>
                                                            {this.props.language.language?.createquery.PrevPage!}
                                                        </Button>
                                                    </GridCol>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth variant="primary" onClick={this.Return}>
                                                            {this.props.language.language?.createquery.finalize!}
                                                        </Button>
                                                    </GridCol>
                                                </GridRow>

                                            </div>


                                        </>

                                    ) : null}
                                </div>
                            </Form>



                        </div>

                    </div>
                </div>
            </div >
        </>


    }




    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    public Submit(e: InputChange) {
        e.preventDefault();

        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })
        this.postFileValidacion(this.state.formData);
        console.log(this.state.consultObject)
    }

    public NextPage() {
        const { estadoCaptura } = this.state
        this.postDatos();
        this.setState({ status: '0' })
        console.log('estatus para pendientes' + this.state.status)

        this.setState({ estadoCaptura: estadoCaptura + 1 })
    }

    public PrevPage() {
        const { estadoCaptura } = this.state

        this.setState({ estadoCaptura: estadoCaptura - 1 })

    }

    public Return() {
        this.props.history.push('/buro-credito/Solicitudes');
        window.location.replace('');
    }

    public PdfVis() {
        this.setState({ isOpen: true });
    }


    componentDidMount() {
        this.getDatos();
    }





    renderFiado(state: { razonSocial: string, rfc: string }, value: string) {
        const nombreCompleto = state.razonSocial + state.rfc
        return (
            (nombreCompleto.toLowerCase().indexOf(value.toLowerCase()) !== -1)

        );



    }

    Cerrar() {
        this.setState({ isOpen: false })
        if (this.state.mensajeConsult.length !== 0) {
            this.setState({ isClose: true })
        } else {
            this.setState({ isClose: false })
        }

    }

    public handleinputChange = async (e: InputChange,) => {
        e.persist();
        await this.setState(preveState => ({
            consultObject: { ...this.state.consultObject, [e.target.name]: e.target.value }

        }))
        this.state.consultObject.solicitante = this.state.PERFIL.username;
        this.state.consultObject.rolSolicitante= this.state.PERFIL.roles.toString() ;
        this.state.consultObject.pais = '157';
        this.state.consultObject.estadoRepublica = this.state.consultObject.estado!;
        this.state.consultObject.codigoBuro = this.state.estados?.codigoBuro;
        console.log(this.state.consultObject)
        this.getEstado();
    }

    public seleccion() {

       
        this.setState({
            consultObject: {
                folio: this.state.consultObject.folio,
                nombre: this.state.consultObject.nombre,
                tipoEntidad: this.state.consultObject.nombre,
                tipoPersona: this.state.consultObject.nombre,
                rfc: this.state.consultObject.nombre,
                codigoBuro: this.state.consultObject.codigoBuro,
                estadoRepublica: this.state.consultObject.estado!,
                segundoNombre: this.state.consultObject.segundoNombre,
                apellidoPaterno: this.state.consultObject.apellidoPaterno,
                apellidoMaterno: this.state.consultObject.apellidoMaterno,
                fechaNacimiento: this.state.consultObject.fechaNacimiento,
                sexo: this.state.consultObject.sexo,
                colonia: this.state.consultObject.colonia,
                codigoPostal: this.state.consultObject.codigoPostal,
                calle: this.state.consultObject.calle,
                numexterior: this.state.consultObject.numexterior,
                delegacionMunicipio: this.state.consultObject.delegacionMunicipio,
                numinterior: this.state.consultObject.numinterior,
                telefono: this.state.consultObject.telefono,
                estadoDomicilio: this.state.consultObject.codigoBuro,
                razonSocial: this.state.consultObject.razonSocial,
                representante: this.state.consultObject.representante,
                pais: this.state.consultObject.pais


            }
        })



        console.log(this.state.consultObject + 'si edita')
    }


    public async getEstado() {
        try {
            const res = await getCreditbureau.getestado(this.state.consultObject.codigoPostal);
            if (res.data?.estado) {
                this.setState({ estados: res.data })
            }



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    handleInputChangeSelectAgent(value: string, item: FiadoOption): void {
        this.setState({ value: item.rfc });
        this.idAgente = item.rfc;
        console.log("Se selecciona el ejecutivo con ID: " + this.idAgente);
        //me faltaba el this.idAgente para que se sellecionaran los datos
        this.getDatosFiado(this.idAgente);
        this.getFolios(this.idAgente);
        this.setState({ rfc: this.idAgente })
        this.setState({ visible:false })



    }

    public async getFolios(value: string) {
        try {
            const res = await getCreditbureau.getClienteConsulta(this.idAgente, this.state.PERFIL.username);
            const ob = Object(res.data)
            this.setState({ mensajeConsult: ob })
           

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {

                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    handleInputChangeAgent(e: React.ChangeEvent<HTMLInputElement>, value: string): void {
        //Los datos que obtenemos del complete lo guardamos y mandamos a busqueda
        const val = e.target.value;
        this.setState({ value: val });
        this.getDatosFiado(this.state.value);

    }

    public getDatos(): void {

        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            this.setState({ folio: data })


        }
    }

    public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
       

        if (validFilepdf(e)) {
            var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            const dt = data;
            const formDataAuxiliar = this.state.formData
            formDataAuxiliar.append('archivo', e.target.files![0]);
            formDataAuxiliar.append('carpeta', 'buro');
            formDataAuxiliar.append('nombreArchivo', dt + '.pdf');
            this.setState({ formData: formDataAuxiliar })
            this.setState({ consulta: false })
            console.log(e)
            this.setState({ openPDF: false })
            var prueba = (window.URL).createObjectURL(e.target.files![0])
            console.log(prueba)
            this.archivo = prueba;

            this.statusChange();

        }
                
    
            
           
        } else {
        }
        console.log(this.state.folio)


    }

    public async postFileValidacion(formData: FormData) {

        try {

            this.setState({ status: '1' })
            const res = await apiS3Services.postFile(formData)
            const arrayFile = res.data
            console.log(arrayFile);
            console.log(this.state.status + 'estutos cuando suben archivo')

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }

        }
        this.statusChange();
    }

    public async statusChange() {
        console.log('antes de enviar el estatus es :' + this.state.status)
        console.log(this.state.consultObject)
        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data.toString();
            const res = await getCreditbureau.putStatusAgent(this.state.status, dt)

        }
    }

    clientData() {

        console.log("Entra a client data")

        let listaClientesAuxiliar: FiadoOption[];
        listaClientesAuxiliar = []


        for (const condition of this.state.datoCaptura) {

            listaClientesAuxiliar.push({
                razonSocial: condition.razonSocial, rfc: condition.rfc
            })
        }


        this.setState({ listFiadoOption: listaClientesAuxiliar })



        return [
            this.state.listFiadoOption
        ]
    }

    async getCredit(): Promise<void> {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getConsul(dt);
                const dat = Object.values(res.data)
                if (dat.length == 0) {
                    this.setState({ condicion: false })
                } else {

                    this.setState({ condicion: true })
                    this.setState({ variable: res.data })
                }
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    async getConsult(busqueda: string): Promise<void> {
        try {
            const res = await getCreditbureau.getConsultaAgente(busqueda, this.state.PERFIL.accessToken, this.state.PERFIL.username);
            this.setState({ datoCaptura: Object.values(res.data) })
            this.clientData();



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {

                this.setState({ value: busqueda })
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    async getDatosFiado(value: string): Promise<void> {
        try {
            console.log("Antes de realizar la consulta" + this.idAgente)
            const res = await getCreditbureau.getConsultaAgente(this.idAgente, this.state.PERFIL.accessToken, this.state.PERFIL.username);
            this.state.consultObject.nombreAgente = this.state.PERFIL.username;

            var ob = Object.values(res.data)
            this.setState({ consultObject: ob[0] })
            var objet = Object.values(res)
            this.setState({ datoCaptura: [] })
            this.setState({ datoCaptura: Object.values(res.data) })
            this.state.consultObject.tipoEntidad = '1'
            this.idAgente = " ";


            if (objet[0].length !== 0) {
                if (objet[0].length === 1) {
                    console.log('si entra aqui')
                    if (this.state.consultObject.tipoPersona === 'Fisica') {
                        console.log(this.state.consultObject.nombre + 'espero funcione')
                        this.state.consultObject.tipoPersona = '1'
                        this.state.consultObject.sexo = this.state.consultObject.sexo
                        this.state.consultObject.idDireccion = this.state.datoCaptura[0].codigoPostal
                        this.state.consultObject.solicitante = this.state.PERFIL.username
                        console.log(this.state.consultObject)
                        this.setState({ alerta: 2 })

                    }
                    if (this.state.consultObject.tipoPersona === 'Moral') {
                        this.state.consultObject.tipoPersona = '2'
                        this.state.consultObject.sexo = this.state.consultObject.sexo
                        this.state.consultObject.idDireccion = this.state.datoCaptura[0].codigoPostal
                        this.state.consultObject.nombre = this.state.datoCaptura[0].razonSocial
                        this.state.consultObject.solicitante = this.state.PERFIL.username
                        console.log(this.state.consultObject)
                        this.setState({ alerta: 1 })

                    }
                }

            } else {
                this.setState({ datoCaptura: [] })
            }



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }






    public alert() {
        Swal.fire(
            'Datos guardados correctamente!',
            '',
            'success'
        )
    }



    handleOnMenuE(isOpen: boolean): void {
        if (isOpen === true) {
            console.log("Abre Menu")
            const busqueda = 'a'
            this.getConsult(busqueda);

        }
    }





    public async postDatos() {


        try {

            var fecha = this.state.consultObject.fechaNacimiento.split("-")
            this.state.consultObject.fechaNacimiento = '';
            this.state.consultObject.fechaNacimiento = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
            console.log(this.state.consultObject + 'antes de enviar datos')
            const res = await getCreditbureau.postArchivo(this.state.consultObject);

            this.setState({ status: '0' })
            this.setState({ datoCaptura: [] })
            console.log('antes' + res.data)
            this.alert();
            console.log(res.data)
            //this.setState({ folio: res.data.folio })
            this.setState({ tipopersona: res.data.tipoPersona })
            localStorage.setItem('FolioConsulta', JSON.stringify(res.data.folio));

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log('datos guardados incorrectamente')
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
        this.getCredit();
        this.statusChange();

    }




}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(Createconsult);


interface CreatequeryState {

    estadoCaptura: number;
    idWarranty: number,
    loadingWarrantyDetail: boolean,
    errorWarrantyDetail: boolean,
    select: string,
    status: string,
    validador: number,
    entidad: Entidad[],
    datovalid: string,
    consultObject: ConsultaBuro,
    cp: string,
    estados: estado,
    valido: boolean,
    FormFisicas: FormData,
    show: boolean,
    datofisica: Fisicas[],
    datoMoral: Morales[],
    Loading: boolean,
    formData: FormData,
    validafile: number,
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    consulta: boolean,
    folio: string,
    datoCaptura: ConsultaBuro[],
    datoConsul: ConsultaBuro[],
    search: string,
    tipopersona: string,
    alerta: number,
    alerta1: number,
    alerta2: number,
    boton: boolean,
    variable: ConsultaBuro,
    Consul_buro: Boolean,
    table: number,
    mensajeF: string,
    mensajeM: string,
    condicion: boolean,
    value: string,
    PERFIL: UserModel,
    listFiadoOption: FiadoOption[],
    isOpen: boolean,
    isClose: boolean,
    mensajeConsult: foliosVigent[],
    openPDF: boolean,
    rfc: string,
    visible:boolean



}
interface FiadoOption {
    razonSocial: string;
    rfc: string;
}


function setProductName(value: any) {
    throw new Error('Function not implemented.');
}

function searchProducts(value: any) {
    throw new Error('Function not implemented.');
}

