export interface AuthorizationAmountWarrantyLanguageModel {
    sorryDictamen: string;
    reintent: string;
    noResults: string;
    authorizeWarranty: string;
    areYouSure: string;
    authorize: string;
    cancel: string;
    authorizing: string;
    selectReason: string;
    sorryReason: string;
    close: string;
    saving: string;
    save: string;
    seleactAReason: string;

}

export const AuthorizationAmountWarrantyEnglish: AuthorizationAmountWarrantyLanguageModel = {
    sorryDictamen: 'We\'re sorry, something has happened while loading the Collateral Opinion, check your connection and try again.',
    reintent: 'Reintent',
    noResults: 'No results found.',
    authorizeWarranty: 'Authorize Collateral',
    areYouSure: 'Are you sure you want to Authorize the amount of this Collaterall?',
    authorize: 'Authorize',
    cancel: 'Cancel',
    authorizing: 'Authorizing',
    selectReason: 'Select Rejection Reasons:',
    sorryReason: 'Sorry something happened loading the Reasons list, please check your connection and try again.',
    close: 'Close',
    save: 'Save',
    saving: 'Saving',
    seleactAReason: 'Select at least one reason for rejection, please, to be able to save them'

}
export const AuthorizationAmountWarrantySpanish: AuthorizationAmountWarrantyLanguageModel = {
    sorryDictamen: 'Lo sentimos algo, ha ocurrido al cargar el Dictamen de La Garantía, revise su conexión e intente de nuevo.',
    reintent: 'Reintentar',
    noResults: 'No se Encontraron Resultados.',
    authorizeWarranty: 'Autorizar Garantía',
    areYouSure: '¿Estás Seguro de que quieres Autorizar el monto de esta Garantía?',
    authorize: 'Autorizar',
    cancel: 'Cancelar',
    authorizing: 'Autorizando',
    selectReason: 'Selecciona las Razones de Rechazo:',
    sorryReason: 'Lo sentimos algo ha ocurrido al cargar la lista de Motivos, revise su conexión e intente de nuevo.',
    close: 'Cerrar',
    saving: 'Guardando',
    save: 'Guardar',
    seleactAReason: 'Selecciona al menos un motivo de rechazo, por favor, para poder guardarlos'

}
