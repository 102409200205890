import React, {Fragment, useState} from 'react';
import {BrowserRouter, Route, Switch, withRouter} from "react-router-dom";
import ScrollToTop from "./components/public/scroll-to-top/ScrollToTop";
import Home from "./views/public/home/Home";
import SinginURB from "./views/public/singin-urb/SinginURB";
import environment from "./environments/environment";
import VentanaMantenimiento from "./views/public/ventana-mantenimiento/VentanaMantenimiento";
import Login from "./views/public/login/Login";
import RecoverPsw from "./views/public/recover-psw/RecoverPsw";
import Menu from "./views/public/menu/Menu";
import Cuenta from "./views/public/cuenta/Cuenta";
import Warranties from "./views/garantias/warranties/Warranties";
import StatisticsWarranties from "./views/garantias/statistics/Statistics";
import PrincipalDetail from "./views/garantias/principal-detail/PrincipalDetail";
import ObligorsDetails from "./views/garantias/obligor-details/ObligorsDetails";
import WarrantiesReview from "./views/garantias/warranties-review/WarrantiesReview";
import ReviewLote from "./views/garantias/review-lote/ReviewLote";
import WarrantyReview from "./views/garantias/warranty-review/WarrantyReview";
import Warranty from "./views/garantias/warranty/Warranty";
import ReviewDocs from "./views/garantias/review-docs/ReviewDocs";
import DocumentsLoans from "./views/garantias/documents-loans/DocumentsLoans";
import Docuware from "./views/garantias/docuware/Docuware";
import ReporteExpediente from "./views/garantias/reporte-expediente/ReporteExpediente";
import WarrantyHistory from "./views/garantias/warranties-history/WarrantyHistory";
import WarrantyComparison from "./views/garantias/warranty-comparison/WarrantyComparison";
import Engagements from "./views/compromisos/engagements/Engagements";
import SinginURBGarantias from "./views/public/singin-urb/SingInURBGarantias";
import CreateEngagement from "./views/compromisos/create-engagements/CreateEngagement";
import DetailsEngagements from "./views/compromisos/details-engagements/DetailsEngagements";
import DetailsEngagementByFiado from "./views/compromisos/details-engagements/DetailsEngagementByFiado";
import CheckConcept from "./components/compromisos/authorization/CheckConcept";
import CheckConceptByFiado from "./components/compromisos/authorization/CheckConceptByFiado";
import AuthorizeEngagements from "./components/compromisos/authorization/AuthorizeEngagements";
import RenewalEngagements from "./components/compromisos/renewal-engagement/RenewalEngagements";
import RevertCheck from "./components/compromisos/renewal-engagement/RevertCheck";
import RevertCheckByFiado from "./components/compromisos/renewal-engagement/RevertCheckByFiado";
import RevocationEngagements from "./components/compromisos/revocation-engagement/RevocationEngagements";
import AutoEngagements from "./views/compromisos/engagements/AutoEngagements";
import CheckEngagements from "./views/compromisos/engagements/CheckEngagements";
import CheckEngagementsByClient from "./views/compromisos/engagements/CheckEngagementsByClient";
import NotificactionClient from "./components/compromisos/authorization/NotificationClient";
import AuthorizeEngagementsClient from "./components/compromisos/authorization/AuthorizaEngagementsClient";
import Exitosa from "./components/compromisos/authorization/Exitosa";
import Creditbureau from "./views/buro-credito/credit-bureau/Creditbureau";
import Createquery from "./views/buro-credito/create-query/CreateQuery";
import ConsultPross from "./views/buro-credito/consult/v-consult/vconsult";
import InPross from "./views/buro-credito/consult/in-pross/Inpross";
import ErrorConsult from "./views/buro-credito/consult/error-con/ErrorConsult";
import Filtrar from "./views/buro-credito/filtrar/Proceso";
import FiltrarVigente from "./views/buro-credito/filtrar/ConsultaVi";
import FiltrarVencida from "./views/buro-credito/filtrar/ConsultaVe";
import Vencid from "./views/buro-credito/consult/vencid/Vencid";
import ConfigCuenta from "./views/buro-credito/Configuracion/ConfigCuenta";
import Request from "./views/buro-credito/requests/Request";
import Enrevision from "./views/buro-credito/requests/Revision-Solicitud";
import autorizadas from "./views/buro-credito/consultas_buro/autorizadas/autorizadas";
import Rechazadas from "./views/buro-credito/consultas_buro/rechazadas/Rechazadas";
import Pendiente from "./views/buro-credito/consultas_buro/pendientes/Pendiente";
import RevisionAgent from "./views/buro-credito/consultas_buro/revision/Revision-agent";
import EditarRechazadas from "./views/buro-credito/consultas_buro/editar/EditarRechazadas";
import CreateConsult from "./views/buro-credito/consultas_buro/create-consult/Create-Consult";
import Dashboard from "./views/buro-credito/dashboard-agent/Dashboard";
import ConsultEnrev from "./views/buro-credito/filtrar-agent/ConsultEnrev";
import ConsultAuto from "./views/buro-credito/filtrar-agent/ConsultAuto";
import ConsultRecha from "./views/buro-credito/filtrar-agent/ConsultRecha";
import PolizasRH from "./views/rh/polizas-rh/PolizasRH";
import DepositosGarantia from "./views/tesoreria/depositos-garantia/DepositosGarantia";
import MsgFirma from "./views/tesoreria/msg-firma/MsgFrima";
import ReporteGarantias from "./views/reportes/garantias-vencidas/ReporteGarantias";
import BajasReclamaciones from "./views/juridico/bajas-reclamaciones/BajasReclamaciones";
import ConfirmacionBaja from "./views/juridico/confirmacion-baja/ConfirmacionBaja";
import {AccessDenied} from "./views/public/access-denied/AccessDenied";
import {NotFound} from "./views/public/not-found/NotFound";
import {UserModel} from "./models/public/UserModel";
import {LocalStorageClass} from "./class/LocalStorageClass";
import CrearAuditoria from './views/buro-credito/crear-auditoria/Crear-Auditorias';
import Auditoria from './views/buro-credito/auditoria/Auditorias';
import ViewAuditorias from './views/buro-credito/viewAditoria/ViewAuditorias';
import HistoryReview from './views/garantias/history-review/HistoryReview';
import FooterPublic from './components/public/footer-public/FooterPublic';

function App() {
    //const localStorageClass = new LocalStorageClass();

  //  const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    return (
        <BrowserRouter basename={"/"}>
            <Fragment>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" component={Home} />

                    <Route
                        exact
                        path="/cargando-sdgf/:urb/:clave/:origen"
                        component={withRouter(SinginURB)}
                    />
                    {environment.env != "prod" && (
                        <Route exact path="/ventana-mantenimiento" component={VentanaMantenimiento} />
                    )}

                    {environment.env != "prod" && (
                        <Route exact path="/login" component={Login} />
                    )}
                    {environment.env != "prod" && (
                        <Route exact path="/recuperar" component={RecoverPsw} />
                    )}
                    {environment.env != "prod" && (
                        <Route exact path="/menu" component={Menu} />
                    )}

                    {environment.env != "prod" && (
                        <Route exact path="/cuenta" component={Cuenta} />
                    )}
                    {/*Garantias */}

                    <Route
                        exact
                        path="/garantias"
                        component={withRouter(Warranties)}
                    />

                    <Route
                        exact
                        path="/garantias/estadistica/:nameStatistics/:idStatistics/:typeProfile/:idProfile"
                        component={withRouter(StatisticsWarranties)}
                    />

                    <Route
                        exact
                        path="/garantias/fiado/:idPrincipal"
                        component={withRouter(PrincipalDetail)}
                    />

                    <Route
                        exact
                        path="/garantias/obligado-solidario/:idSolidary"
                        component={withRouter(ObligorsDetails)}
                    />

                    <Route
                        exact
                        path="/garantias/revision"
                        component={withRouter(WarrantiesReview)}
                    />

                    <Route
                        exact
                        path="/garantias/revision/fiado/:idFiado/lote/:idLote"
                        component={withRouter(ReviewLote)}
                    />

                    <Route
                        exact
                        path="/garantias/revision/fiado/:idFiado/garantia/:idFolio"
                        component={withRouter(WarrantyReview)}
                    />
                    {//Historial garantia
                    }

                        <Route
                            exact
                            path="/garantias/historial"
                            component={withRouter(WarrantyHistory)}
                        />
                        <Route
                            exact
                            path="/garantias/historial/comparacion"
                            component={withRouter(WarrantyComparison)}
                        />




                    <Route
                        exact
                        path="/garantias/crear-garantia/fiado/:idFiado"
                        component={withRouter(Warranty)}
                    />


                    <Route
                        exact
                        path="/garantias/fiado/:idFiado/garantia/:idFolio"
                        component={withRouter(Warranty)}
                    />

                    <Route
                        exact
                        path="/garantias/revision-documentos/:idWarranty"
                        component={withRouter(ReviewDocs)}
                    />

                    <Route
                        exact
                        path="/garantias/documento-pdf/"
                        component={withRouter(DocumentsLoans)}
                    />

                    <Route
                        exact
                        path="/garantias/dw/"
                        component={withRouter(Docuware)}
                    />


                    <Route
                        exact
                        path="/garantias/reporte-expediente/"
                        component={withRouter(ReporteExpediente)}
                    />

                    {/*Compromisos */}
                    <Route
                        exact
                        path="/compromisos"
                        component={withRouter(Engagements)}
                    />
                    <Route
                        exact
                        path="/cargando-sdgf/:urb/:clave/:origen/fiado/:idFiado"
                        component={withRouter(SinginURBGarantias)}
                    />
                    <Route
                        exact
                        path="/compromisos/crear-compromiso/:idFiadoBueno"
                        component={withRouter(CreateEngagement)}
                    />
                    <Route
                        exact
                        path="/compromisos/detalle/:idCompromiso/fiado/:idFiado/"
                        component={withRouter(DetailsEngagements)}
                    />
                    <Route
                        exact
                        path="/compromisos/detail/:idCompromiso/fiado/:idFiado/"
                        component={withRouter(DetailsEngagementByFiado)}
                    />
                    <Route
                        exact
                        path="/compromisos/cumplimiento/:idCompromiso/fiado/:idFiado/"
                        component={withRouter(CheckConcept)}
                    />
                    <Route
                        exact
                        path="/cumplimiento/:idCompromiso/fiado/:idFiado/"
                        component={withRouter(CheckConceptByFiado)}
                    />
                    <Route
                        exact
                        path="/compromisos/autorizacion/:idCompromiso/fiado/:idFiado"
                        component={withRouter(AuthorizeEngagements)}
                    />
                    <Route
                        exact
                        path="/compromisos/renovacion/:idCompromiso/fiado/:idFiado"
                        component={withRouter(RenewalEngagements)}
                    />
                    <Route
                        exact
                        path="/compromisos/revertir/:idCompromiso/fiado/:idFiado"
                        component={withRouter(RevertCheck)}
                    />
                    <Route
                        exact
                        path="/revertir/:idCompromiso/fiado/:idFiado/"
                        component={withRouter(RevertCheckByFiado)}
                    />
                    <Route
                        exact
                        path="/compromisos/revocacion/:idCompromiso/fiado/:idFiado"
                        component={withRouter(RevocationEngagements)}
                    />
                    <Route
                        exact
                        path="/compromisos/autorizaciones"
                        component={withRouter(AutoEngagements)}
                    />
                    <Route
                        exact
                        path="/compromisos/cumplimientos"
                        component={withRouter(CheckEngagements)}
                    />
                    <Route
                        exact
                        path="/cumplimientos/fiado/:idFiado"
                        component={withRouter(CheckEngagementsByClient)}
                    />
                    <Route
                        exact
                        path="/compromisos/notificacion/:idCompromiso/fiado/:idFiado"
                        component={withRouter(NotificactionClient)}
                    />
                    <Route
                        exact
                        path="/compromisos/acepta-compromiso/:idCompromiso/fiado/:idFiado"
                        component={withRouter(AuthorizeEngagementsClient)}
                    />
                    <Route
                        exact
                        path="/solicitudExitosa"
                        component={withRouter(Exitosa)}
                    />

                    {/*Buro de credito */}

                    <Route
                        exact
                        path="/buro-credito"
                        component={withRouter(Creditbureau)}
                    />
                    <Route
                        exact
                        path="/buro-credito/crear-consulta"
                        component={withRouter(Createquery)}
                    />
                    <Route
                        exact
                        path="/buro-credito/consultar/proceso"
                        component={withRouter(ConsultPross)}
                    />
                    <Route
                        exact
                        path="/buro-credito/consultar/consulta-en-proceso"
                        component={withRouter(InPross)}
                    />
                    <Route
                        exact
                        path="/buro-credito/consultar/error"
                        component={withRouter(ErrorConsult)}
                    />
                    <Route
                        exact
                        path="/buro-credito/filtrar-consultas/en-proceso"
                        component={withRouter(Filtrar)}
                    />
                    <Route
                        exact
                        path="/buro-credito/filtrar-consultas/vigentes"
                        component={withRouter(FiltrarVigente)}
                    />
                    <Route
                        exact
                        path="/buro-credito/filtrar-consultas/vencidas"
                        component={withRouter(FiltrarVencida)}
                    />
                    <Route
                        exact
                        path="/buro-credito/consultar/info-vencidas"
                        component={withRouter(Vencid)}
                    />
                    <Route
                        exact
                        path="/buro-credito/Configuracion-cuenta"
                        component={withRouter(ConfigCuenta)}
                    />
                    
                        <Route
                            exact
                            path="/buro-credito/Auditoria"
                            component={withRouter(Auditoria)}
                        />
                    
                  
                      
                        <Route
                            exact
                            path="/buro-credito/Crear-Auditoria"
                            component={withRouter(CrearAuditoria)}
                        />
                     
                        <Route
                            exact
                            path="/buro-credito/View-Auditoria"
                            component={withRouter(ViewAuditorias)}
                        />

                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Solicitudes-consulta"
                            component={withRouter(Request)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/consultar/enrevision"
                            component={withRouter(Enrevision)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Solicitudes/Consultas_autorizadas"
                            component={withRouter(autorizadas)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Solicitudes/Consultas_rechazadas"
                            component={withRouter(Rechazadas)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Solicitudes/Consulta_pendiente"
                            component={withRouter(Pendiente)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Solicitudes/Consultas_enrevision"
                            component={withRouter(RevisionAgent)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Solicitudes/Editar_consulta"
                            component={withRouter(EditarRechazadas)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Crear-Solicitud"
                            component={withRouter(CreateConsult)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/Solicitudes"
                            component={withRouter(Dashboard)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/filtrar-Solicitudes/En-revision"
                            component={withRouter(ConsultEnrev)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/filtrar-Solicitudes/Autorizadas"
                            component={withRouter(ConsultAuto)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/buro-credito/filtrar-Solicitudes/Rechazadas"
                            component={withRouter(ConsultRecha)}
                        />
                    )}

                    {/*Polizas RH*/}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/rh/polizas"
                            component={withRouter(PolizasRH)}
                        />
                    )}

                    {/*Tesoreria*/}


                    <Route
                        exact
                        path="/tesoreria"
                        component={withRouter(DepositosGarantia)}
                    />


                    <Route
                        exact
                        path="/confirmacion-firma"
                        component={withRouter(MsgFirma)}
                    />

                    {/*Reporte Garantias*/}

                    {environment.env == "dev" && (
                        <Route
                            exact
                            path="/reporte-garantias"
                            component={withRouter(ReporteGarantias)}
                        />
                    )}

                    {/*Modulo Jurico*/}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/bajas-reclamaciones"
                            component={withRouter(BajasReclamaciones)}
                        />
                    )}
                    {environment.env != "prod" && (
                        <Route
                            exact
                            path="/confirmacion-baja"
                            component={withRouter(ConfirmacionBaja)}
                        />
                    )}

                    <Route
                        exact
                        path="/401"
                        component={AccessDenied} />

                    <Route component={NotFound} />

                </Switch>
                {environment.env == "dev"  &&
                    <FooterPublic/>
                }

            </Fragment>
        </BrowserRouter>
    );
}

export default App;
