export interface FindClientLanguageModel {
    
    nuevoCompromiso: string
    tituloModal: string 
    aceptar: string
    agente: string
    continuar: string
    sent: string
    leyenda: string
    leyenda2: string
    seleccionaF: string
    seleccionaComite: string,
    seleccionaComiteBuro: string
    seleccionaSubComite: string
    leyenda3: string
    leyenda32: string
    tituloModal2: string 
    
}

export const FindClientLanguageEnglish: FindClientLanguageModel ={
    
    nuevoCompromiso: 'New Compromise', 
    tituloModal: 'Find Client',    
    aceptar: 'OK',
    agente: 'Agent',
    continuar: 'Continue',
    sent: 'Send',
    leyenda: 'You are not authorized to approve this compromise.',
    leyenda2: 'Please select an Authorized Underwriter Sr.',
    seleccionaF: 'Select an Authorized Underwriter Sr.',
    seleccionaComite:  'Select an Authorized Underwriter Sr.',
    seleccionaSubComite:  'Select an Authorized Underwriter Sr.',
    seleccionaComiteBuro: 'Underwriter Sr. Authorized',
    leyenda3: 'The Principal already have ',
    leyenda32: ' previous compromises',
    tituloModal2: "Search an Agent"

   
}
export const FindClientLanguageSpanish: FindClientLanguageModel ={
    
    nuevoCompromiso: 'Crear Compromiso',  
    tituloModal: 'Buscar Fiado',
    aceptar: 'Aceptar',
    agente: 'Agente',
    continuar: 'Continuar',
    sent: 'Enviar',
    leyenda: 'No cuentas con las facultades para autorizar el compromiso.',
    leyenda2: 'Por favor selecciona un funcionario para enviar la solicitud',
    seleccionaF: 'Selecciona un Funcionario',
    seleccionaComite:  'Selecciona un miembro del Comité',
    seleccionaComiteBuro: 'Funcionario Autorizado',
    seleccionaSubComite:  'Selecciona un miembro del Subcomité',
    leyenda3: 'El Fiado ya cuenta con ',
    leyenda32: ' compromisos',
    tituloModal2: "Buscar Agente"

 
}