export enum EstatusGarantiaEnum {
    pendiente = 'pendiente',
    revision = 'revision',
    vencida = 'vencida',
    vigente = 'vigente'
}

export interface FiltersSearchWarantyModel{
    pendiente: boolean;
    revision: boolean;
    vencida: boolean;
    vigente: boolean
    showLoansPrincipal: boolean;
}