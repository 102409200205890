

export interface StatusRowWarrantyLanguageModel {
    review: string;
    edit: string;
    delete: string;
    titleModalDel: string;
    areYouSureDel: string;
    confirmDel: string;
    cancelDel: string;
    cancellingWar: string;
    history:string ;

}

export const StatusRowWarrantyLanguageEnglish: StatusRowWarrantyLanguageModel = {
    review: 'Review',
    edit: 'Edit',
    delete: 'Delete',
    titleModalDel: 'Cancellation of Loan',
    areYouSureDel: 'Are you sure you want to cancel the Loan?',
    confirmDel: 'Confirm',
    cancelDel: 'Cancel',
    cancellingWar: 'Canceling warranty',
    history:'Historial'

}
export const StatusRowWarrantyLanguageSpanish: StatusRowWarrantyLanguageModel = {
    review: 'Revisar',
    edit: 'Editar',
    delete: 'Baja',
    titleModalDel : 'Baja de Garantía',
    areYouSureDel: '¿Estás seguro que quieres dar de baja la garantía?',
    confirmDel: 'Confirmar',
    cancelDel: 'Cancelar',
    cancellingWar: 'Dando de baja garantía',
    history:'Historial'

}
