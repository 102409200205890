import React, { useEffect, useState} from 'react';
import { Spinner} from 'react-bootstrap';
import './InfoPrincipal.scss';
import {Link as LinkLiberty, Modal,} from "@lmig/lmds-react";
import {Link, Route, useHistory} from "react-router-dom";
import * as apiService from "../../../services/api-gral/ApiGralService";
import {ClienteModel} from "../../../models/public/ClienteModel";
import { useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AxiosError} from "axios";
import {EntrevistaIframeModel} from "../../../models/public/EntrevistaIframeModel";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {openNewWindow, openNewWindowCenter, validRoles} from "../../../class/CommonClass";
import {AddObligor} from "../add-obligor/AddObligor";
import {InfoObligadoSolidario} from "../../../models/garantias/InfoObligadoSolidario";
import {LockPrincipal} from "../lock-principal/LockPrincipal";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UnlockPrincipal} from "../unlock-principal/UnlockPrincipal";
import {LockCommissionPrincipal} from "../lock-commission-principal/LockCommissionPrincipal";
import {UnLockCommissionPrincipal} from "../unlock-commission-principal/UnLockCommissionPrincipal";

import LockClosed from "../../../assets/ico/PNG/LockClosed.png";
import LockOpen from "../../../assets/ico/PNG/LockOpen.png";
import LockComission from "../../../assets/ico/PNG/LockComission.png";
import UnlockComission from "../../../assets/ico/PNG/UnlockComission.png";
import {Interview} from "../interview/Interview";
import {LOCK, LOCKCOMMISSION, UNLOCK, UNLOCKCOMMISSION} from "../../../actions/FiadoActionTypes";
interface InfoPrincipalProps {
    idPrincipal: number;
    idPrincipaBack?: number;
    label: string;
    openObligors: boolean;
    addOS: boolean;
}

export const InfoPrincipal: React.FC<InfoPrincipalProps> = ({idPrincipal,idPrincipaBack, label, openObligors , addOS}:InfoPrincipalProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);
    const localStorageClass = new LocalStorageClass();

    const clienteInit: ClienteModel = {
        idCliente: 0,
        idAgente: 0,
        razonSocial: '',
        rfc: '',
        agente: '',
        propietario: '',
        estado: 0,
        estadoBloqueoCliente: false,
        estadoBloqueoComision: false,
        descripcion: ''
    }

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [infoPrincipal, setInfoPrincipal] = useState<ClienteModel>(clienteInit);

    const [obligors, setObligors] = useState<InfoObligadoSolidario []>([]);

    const [interview, setInterview] = useState<EntrevistaIframeModel>({url: '', mensaje: ''});

    const [loadingInterview, setLoadingInterview] = useState<boolean>(false);

    const [errorInterview, setErrorInterview] = useState<boolean>(false);


    const getUserDetail = async (idPrincipal: number) => {
        const res = await apiService.getDetalleCliente(idPrincipal)
        //console.log(res.data)
        setInfoPrincipal(res.data.cliente)
        setObligors(res.data.obligadosSolidarios)
    }

    useEffect(() => {
        getUserDetail(idPrincipal);
    },[idPrincipal]);


    return (
        <>
            <div className="col-md-12" id="info-principal">
                <h6 className="info-text">
                    {label}
                </h6>
                {(idPrincipaBack!= 0 && idPrincipaBack)?
                    <Link to={"/garantias/fiado/" + idPrincipaBack}>
                        <LinkLiberty caretPosition="left"  variant="standalone">
                            {languageState.language?.infoPrincipal.returnPrincipal!}
                        </LinkLiberty>
                        <br/>
                    </Link>: null
                }

                <h5 className="info-text">{infoPrincipal.razonSocial}</h5>


                <h6 className="info-text font-weight-light">
                    {infoPrincipal.rfc}
                </h6>
                <h6 className="info-text">
                    {languageState.language?.infoPrincipal.agents!}: {infoPrincipal.agente}
                </h6>

                {((validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                        validRoles(PERFIL.roles, localStorageClass.PROMOTOR) ) &&
                    validRoles(PERFIL.roles, localStorageClass.AUDITOR))?
                    <></>:
                    <div className="info-text interview-text">

                        <LinkLiberty
                            variant="standalone">
                            <Interview idPrincipal={idPrincipal}
                                       textInterview={languageState.language?.infoPrincipal.showMore!}/>
                        </LinkLiberty>

                    </div>
                }
            </div>




        </>
    );
};