export interface ExpedientIdentificationLanguageModel {
    ExpedientIncomplete: string;
    ExpedientComplete: string;
    ExpedientTitleModal: string;
    expedientTitleConfirm: string;
    loadingExpedient: string;
    notfoundDocument: string;
    selectReason: string;
    imSorry: string;
    dragHere: string;
    inCapture: string;
    inReview: string;
    reject: string;
    authorized: string;
    savedDW: string;
    uploadFile: string;
    delete: string;
    watch: string;
    quote: string;
    acept: string;
    rejectDoc: string;
    areYouSure: string;
    cancel: string;
    authorize: string;
    authorizing: string;
    noResults: string;
    sorryReason: string;
    save: string;
    saving: string;
    close: string;
    imSorryQuote: string;
    add: string;
    download: string;


}

export const ExpedientIdentificationLanguageEnglish: ExpedientIdentificationLanguageModel ={
    ExpedientIncomplete: 'Incomplete File',
    ExpedientComplete: 'Complete File',
    ExpedientTitleModal: 'Identification File',
    loadingExpedient: 'Loading Identification File',
    expedientTitleConfirm: 'Confirm',
    notfoundDocument: 'Missing Document',
    selectReason: 'Select Rejection Reasons:',
    imSorry: 'Sorry, the ID file could not be loaded, something happened, check your connection and try again.',
    dragHere: 'Drag and drop your file here or',
    inCapture: 'On Capture',
    inReview: 'In Review',
    reject: 'Rejected',
    authorized: 'Authorized',
    savedDW: 'Saved in Docuware',
    uploadFile: 'Upload file',
    delete: 'Delete',
    watch: 'Watch',
    quote: 'Quote',
    acept: 'Agree',
    rejectDoc: 'Reject',
    areYouSure: 'Are you sure you want to Authorize a Document?',
    cancel: 'Cancel',
    authorize: 'Authorize',
    authorizing: 'Authorizing',
    noResults: 'No results found',
    sorryReason: 'Sorry something happened loading the Reasons list, please check your connection and try again.',
    save: 'Save',
    saving: 'Saving',
    close: 'Close',
    imSorryQuote: 'Sorry, the document could not be graded, something happened, check your connection and try again.',
    add: 'Add Doc',
    download: 'Download'

}
export const ExpedientIdentificationLanguageSpanish: ExpedientIdentificationLanguageModel ={

    ExpedientIncomplete: 'Expediente Incompleto',
    ExpedientComplete: 'Expediente Completo',
    ExpedientTitleModal: 'Expediente de Identificación',
    expedientTitleConfirm: 'Confirmar',
    loadingExpedient: 'Cargando Expediente de Identificación',
    notfoundDocument: 'Falta Documento',
    selectReason: 'Selecciona las Razones de Rechazo:',
    imSorry: 'Lo sentimos, no se pudo cargar el expediente de Identificación, algo ha pasado revise su conexión e intente de nuevo.',
    dragHere: 'Arrastre y suelte su archivo aquí',
    inCapture: 'En Captura',
    inReview: 'En Revisión',
    reject: 'Rechazado',
    authorized: 'Autorizado',
    savedDW: 'Guardado en Docuware',
    uploadFile: 'Subir Archivo',
    delete :'Eliminar',
    watch: 'Ver',
    quote: 'Calificar',
    acept: 'Aceptar',
    rejectDoc: 'Rechazar',
    areYouSure: '¿Estás Seguro de que quieres Autorizar un Documento?',
    cancel: 'Cancelar',
    authorize: 'Autorizar',
    authorizing: 'Autorizando',
    noResults: 'No se Encontraron Resultados',
    sorryReason: 'Lo sentimos algo ha ocurrido al cargar la lista de Motivos, revise su conexión e intente de nuevo.',
    save: 'Guardar',
    saving: 'Guardando',
    close: 'Cerrar',
    imSorryQuote: 'Lo sentimos, no se ha podido calificar el documento, algo ha pasado, revise su conexión e intente de nuevo.',
    add: 'Agregar Documento',
    download: 'Descargar'
}