import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {Dropdown} from "react-bootstrap";
import {Button, IconInfo, IconThumbsDown, IconThumbsUp, Modal} from "@lmig/lmds-react";
import './AuthorizationPercentWarranty.scss';
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {PositiveAlert} from "../../public/positive-alert/PositiveAlert";
import {InformativeAlert} from "../../public/informative-alert/InformativeAlert";
import {statesQuoteDoc} from "../authorization-doc-warranty/AuthorizationDocWarranty";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import Select, {MultiValue} from "react-select";
import {CalificacionPorcentajeModel} from "../../../models/garantias/CalificacionPorcentajeModel";
import {MotivoEnum} from "../../../models/garantias/MotivoEnum";
import {validRoles} from "../../../class/CommonClass";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";

interface AuthorizationPercentWarrantyProps {
    idWarranty: number;
    returnQuote?: (quoted: CalificacionPorcentajeModel) => void;

}

export const AuthorizationPercentWarranty: React.FC<AuthorizationPercentWarrantyProps> = (
    {idWarranty, returnQuote}:AuthorizationPercentWarrantyProps)=> {

    const localStorageClass = new LocalStorageClass();




    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.authorizationPercentWarranty);

    const specialActionsState = useSelector((state: RootStore) => state.specialActions.specialActions);

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [openModalRejection, setOpenModalRejection] = useState<boolean>(false);

    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

    const [quoteAmount, setQuoteAmount] = useState<CalificacionPorcentajeModel>(initQuotePercent);

    const [loadingQuoteAmount, setLoadingQuoteAmount] = useState<boolean>(true);

    const [errorQuoteAmount, setErrorQuoteAmount] = useState<boolean>(true);

    const [listReasons, setListReasons] = useState<MotivoRechazoModel []>([]);

    const [loadingReasons, setLoadingReasons] = useState<boolean>(true);

    const [errorReaseons, setErrorReaseons] = useState<boolean>(true);

    const [sendingAuthorization, setSendingAuthorization] = useState<boolean>(false);

    const [listConditionalOption, setListConditionalOption] = useState<ConditionOptionModel[]>([]);

    const [selectedReasons, setSelectedReasons] = useState<MotivoRechazoModel []>([]);

    const [selectReasonsPlease, setSelectReasonsPlease] = useState<boolean>(false);

    const [autorizePonderation, setAutorizePonderation] = useState<boolean>(false);

    useEffect(() => {
        if (specialActionsState!= undefined){

            for (const action of specialActionsState!){
                if (action.descripcionAccion == "Autorizar poderación"){
                 //   console.log('specialActionsState')
                   // console.log(action)
                    setAutorizePonderation(true);
                }

            }

        }


        if (idWarranty){
            getQuotePercent(idWarranty);

        }else {
            setLoadingQuoteAmount(false)
            setErrorQuoteAmount(false)
        }


    },[specialActionsState]);



    const getQuotePercent =  async (idWarranty: number) => {

        setLoadingQuoteAmount(true);
        setErrorQuoteAmount(false);
        setSendingAuthorization(true);
        try {
            const res = await garantiasService.getCalifPorcentaje(idWarranty);
            const quoteAmonutAux: CalificacionPorcentajeModel = res.data;
            setQuoteAmount(quoteAmonutAux)
            setLoadingQuoteAmount(false);
            setOpenModalConfirm(false);
            setSendingAuthorization(false);

            returnQuote!(quoteAmonutAux);



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingQuoteAmount(false);
                setErrorQuoteAmount(true);
                setSendingAuthorization(false);

            }
        }

    }

    const quoteAmountWarranty = (quoteAmount: number) => {
        switch (quoteAmount){
            case statesQuoteDoc.aprobado:
                setOpenModalConfirm(true);
                break
            case statesQuoteDoc.rechazado:
                openModal();
                break;
        }
    }

    const openModal = async () => {
        setOpenModalRejection(true);
        setLoadingReasons(true);
        setErrorReaseons(false);
        try {
            const res = await garantiasService.getMotivosRechazo(MotivoEnum.porcentajePonderacion);
            setListReasons(res.data);
            formatList(res.data);
            setLoadingReasons(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorReaseons(true);
                setLoadingReasons(false);

            }
        }
    }

    const putQuoteWarranty = async(estadoMonto: number, idGarantia: number, motivos?: MotivoRechazoModel[]) => {
        setLoadingReasons(true);
        setErrorQuoteAmount(false);

        try {
            const res = await garantiasService.putCalificaPorcentajeGarantia(estadoMonto,idGarantia, motivos!);
            setLoadingReasons(false);
            const quote: CalificacionPorcentajeModel  = res.data;
            setQuoteAmount(quote);
            setOpenModalRejection(false);
            setOpenModalConfirm(false);
            returnQuote!(quote);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingReasons(false);
                setErrorQuoteAmount(true);

            }
        }
    }

    const formatList = (rejectList: MotivoRechazoModel[]) => {
        let  listaCondicionalAuxiliar: ConditionOptionModel[] = [];
        for(const i of rejectList){
            listaCondicionalAuxiliar.push({
                name: '',
                value: i.idMotivo,
                label: i.descripcion,
                status: 0})
        }
        setListConditionalOption(listaCondicionalAuxiliar);
    }

    const handleInputChangeSelect = (e: MultiValue<ConditionOptionModel>) => {

        console.log(e)
        const auxselectedReasons: MotivoRechazoModel [] = []
        for (const i of e){
            auxselectedReasons.push({idMotivo: i.value, descripcion: i.label})
        }
        setSelectedReasons(auxselectedReasons);
    }

    return (

        <>

            {loadingQuoteAmount ? (
                    <div className="col-12">
                        <SpinnerLoading />

                    </div>
                ) :
                (!errorQuoteAmount)?(
                        <>

                            <div className="col-md-2"  id="authorization-percent-warranty">



                                    {validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)?

                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" className="dropdown-more-options" >
                                                {(quoteAmount.estadoPorcentaje == statesQuoteDoc.pendiente ||
                                                    quoteAmount.estadoPorcentaje == statesQuoteDoc.enCaptura ||
                                                    quoteAmount.estadoPorcentaje == statesQuoteDoc.enRevision)? (
                                                    <IconThumbsUp
                                                        color="gray"
                                                        size="16"
                                                        title={quoteAmount.descripcionEstado!}/>
                                                ): null}
                                                {quoteAmount.estadoPorcentaje == statesQuoteDoc.aprobado? (
                                                    <IconThumbsUp
                                                        color="informative"
                                                        size="16"
                                                        title={quoteAmount.descripcionEstado!}/>
                                                ): null}
                                                { quoteAmount.estadoPorcentaje ==  statesQuoteDoc.rechazado ? (
                                                    <IconThumbsDown
                                                        color="negative"
                                                        size="16"
                                                        title={quoteAmount.descripcionEstado!}/>
                                                ): null}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>

                                                { (quoteAmount.estadoPorcentaje == statesQuoteDoc.pendiente ||
                                                    quoteAmount.estadoPorcentaje == statesQuoteDoc.enCaptura ||
                                                    quoteAmount.estadoPorcentaje == statesQuoteDoc.enRevision) ? (
                                                    <>
                                                        <Dropdown.Item
                                                            onClick={() =>{
                                                                quoteAmountWarranty(statesQuoteDoc.aprobado)
                                                            }}
                                                        >
                                                            <IconThumbsUp
                                                                color="informative"
                                                                size="16"
                                                                title=""/>

                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() =>{
                                                                setSelectReasonsPlease(false);
                                                                quoteAmountWarranty(statesQuoteDoc.rechazado)
                                                            }}
                                                        >
                                                            <IconThumbsDown
                                                                color="negative"
                                                                size="16"
                                                                title=""/>

                                                        </Dropdown.Item>
                                                    </>
                                                ): null}
                                                {(quoteAmount.estadoPorcentaje == statesQuoteDoc.aprobado) ? (
                                                    <>
                                                        <Dropdown.Item
                                                            onClick={() =>{
                                                                setSelectReasonsPlease(false);
                                                                quoteAmountWarranty(statesQuoteDoc.rechazado)
                                                            }}
                                                        >
                                                            <IconThumbsDown
                                                                color="negative"
                                                                size="16"
                                                                title=""/>

                                                        </Dropdown.Item>
                                                    </>
                                                ): null}
                                                { quoteAmount.estadoPorcentaje == statesQuoteDoc.rechazado  ? (
                                                    <>
                                                        <Dropdown.Item
                                                            onClick={() =>{
                                                                quoteAmountWarranty(statesQuoteDoc.aprobado )
                                                            }}
                                                        >

                                                            <IconThumbsUp
                                                                color="informative"
                                                                size="16" />

                                                        </Dropdown.Item>

                                                    </>
                                                ): null}

                                            </Dropdown.Menu>

                                        </Dropdown>
                                        :
                                        <Button variant="secondary">

                                            {(quoteAmount.estadoPorcentaje == statesQuoteDoc.pendiente ||
                                                quoteAmount.estadoPorcentaje == statesQuoteDoc.enCaptura ||
                                                quoteAmount.estadoPorcentaje == statesQuoteDoc.enRevision)? (
                                                <IconThumbsUp
                                                    color="gray"
                                                    size="16"
                                                    title={quoteAmount.descripcionEstado!}/>
                                            ): null}
                                            {quoteAmount.estadoPorcentaje == statesQuoteDoc.aprobado? (
                                                <IconThumbsUp
                                                    color="informative"
                                                    size="16"
                                                    title={quoteAmount.descripcionEstado!}/>
                                            ): null}
                                            { quoteAmount.estadoPorcentaje ==  statesQuoteDoc.rechazado ? (
                                                <IconThumbsDown
                                                    color="negative"
                                                    size="16"
                                                    title={quoteAmount.descripcionEstado!}/>
                                            ): null}
                                        </Button>

                                    }

                            </div>
                            {idWarranty?(
                                <div className="col-md-12">
                                    <br/>
                                    {quoteAmount.estadoPorcentaje == statesQuoteDoc.enCaptura ? (
                                        <InformativeAlert textAlert={quoteAmount.descripcionEstado!}/>
                                    ): null}
                                    {quoteAmount.estadoPorcentaje == statesQuoteDoc.enRevision ? (
                                        <InformativeAlert textAlert={quoteAmount.descripcionEstado!}/>
                                    ): null}
                                    {quoteAmount.estadoPorcentaje == statesQuoteDoc.rechazado ? (
                                        <>
                                            <NegativeAlert textAlert={quoteAmount.descripcionEstado!+ ': '}/>
                                            {quoteAmount.rechazo.map((i,index) =>
                                                (
                                                    <span key={index} className="badge badge-pill badge-light">
                                                           <IconInfo
                                                               color="error"
                                                               size="16"
                                                               title={i.descripcion}/> &nbsp;
                                                        {i.descripcion} &nbsp;
                                                    </span>
                                                ))
                                            }
                                        </>
                                    ): null}
                                    {quoteAmount.estadoPorcentaje == statesQuoteDoc.aprobado ? (
                                        <PositiveAlert textAlert={quoteAmount.descripcionEstado!}/>
                                    ): null}

                                </div>
                            ): null}
                        </>
                    ):
                    <div className="col-12">
                        <NegativeAlert
                            textAlert={languageState?.sorryDictamen!}/><br/>
                        <div className="row">
                            <div className="col-md-4"/>

                            <div className="col-md-4">
                                <Button dynamicWidth
                                        variant="secondary"
                                        onClick={() => {
                                            // recargar dictamen
                                            getQuotePercent(idWarranty)
                                        }
                                        }>{languageState?.reintent!}
                                </Button>
                            </div>
                            <div className="col-md-4"/>

                        </div>

                    </div>
            }
            {/*modal rechazar monto*/}
            <Modal
                isOpen={openModalRejection}
                size="medium"
                modalStyle="standard"
                title={''}
                closeFunc={() => {setOpenModalRejection(false)}}
                id="modal-rechazo-percent-warranty">
                {loadingReasons?(
                        <div className="col-12">
                            <SpinnerLoading />
                        </div>
                    ):
                    (!errorReaseons)? (
                            <div className="col-12 list-reject">
                                <h4>{languageState?.selectReason}</h4><br/>

                                <Select
                                    placeholder={languageState?.selectReason}
                                    isMulti
                                    name="blocks"
                                    options={listConditionalOption}
                                    onChange={handleInputChangeSelect}
                                />

                                { (selectReasonsPlease)?<NegativeAlert
                                    textAlert={languageState?.seleactAReason!}/>: null
                                }
                                {listReasons.length==0? (
                                    <div className="col-12">
                                        <br/><br/>
                                        <h5>{languageState?.reintent!}</h5>
                                    </div>
                                ): null}


                            </div>):
                        <div className="col-12">
                            <NegativeAlert
                                textAlert={languageState?.sorryReason!}/>
                        </div>
                }

                <div className="row">
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="secondary"
                            onClick={()=>{
                                setOpenModalRejection(false);
                            }}>
                            {languageState?.close!}
                        </Button>
                    </div>
                    <div className="col-md-6 col-12">
                        {!sendingAuthorization ? (
                                <Button
                                    dynamicWidth
                                    variant="primary"
                                    onClick={()=>{
                                        if (selectedReasons.length>0){
                                            putQuoteWarranty(statesQuoteDoc.rechazado, idWarranty, selectedReasons)
                                        }else{

                                            setSelectReasonsPlease(true);
                                        }

                                    }}>
                                    {languageState?.save!}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState?.saving!}/>
                        }

                    </div>

                </div>
            </Modal>
            {/*modal confirmar autorización*/}
            <Modal
                isOpen={openModalConfirm}
                size="medium"
                modalStyle="standard"
                title={languageState?.authorizeWarranty!}
                closeFunc={() => {setOpenModalConfirm(false)}}
                id="modal-comfirm">
                <h5>
                    {languageState?.areYouSure!}
                </h5>
                <div className="row">

                    <div className="col-md-6">
                        <br/>
                        <Button dynamicWidth
                                variant="secondary"
                                onClick={() => {setOpenModalConfirm(false)}}>
                            {languageState?.cancel!}
                        </Button>
                    </div>
                    <div className="col-md-6">
                        <br/>
                        {!sendingAuthorization ? (
                                <Button dynamicWidth
                                        variant="primary"
                                        onClick={() => {
                                            putQuoteWarranty(statesQuoteDoc.aprobado, idWarranty)
                                        }}>
                                    {languageState?.authorize!}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState?.authorizing!}/>
                        }
                    </div>

                </div>


            </Modal>




        </>
    );
};

export const initQuotePercent : CalificacionPorcentajeModel = {
    idGarantia: 0,
    estadoPorcentaje: statesQuoteDoc.pendiente,
    descripcionEstado:'',
    rechazo: []
}

