import React, {useEffect, useState} from 'react';
import {Pagination,} from 'react-bootstrap';
import {Checkbox, CheckboxGroup, Select, SelectOption, Table, TableCell, TableRow} from "@lmig/lmds-react";
import './TableLoansPrincipal.scss';
import {ListGarantiaModel} from "../../../models/garantias/ListGarantiaModel";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {AxiosError} from "axios";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {
    formatCurrency,
    formatValue,
    initPaginacion,
    InputChange,
    loadPaginationList
} from "../../../class/CommonClass";
import {EstatusGarantiaEnum, FiltersSearchWarantyModel} from "../../../models/garantias/EstatusGarantiaEnum";
import {StatusRowWarranty} from "../status-row-warranty/StatusRowWarranty";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {InfoObligadoSolidario} from "../../../models/garantias/InfoObligadoSolidario";
import {ClienteModel} from "../../../models/public/ClienteModel";
import * as apiService from "../../../services/api-gral/ApiGralService";
import {AcordionTruncate} from "../acordion-truncate/AcordionTruncate";
import {MultiSelect} from "react-multi-select-component";
import {OptionsMultiSelect} from "../../../models/public/OptionsMultiSelect";
import { FaSearch } from 'react-icons/fa';
import {ADDOS} from "../../../actions/FiadoActionTypes";



interface TableWarrantyProps {
    idPrincipal: number
    showFiado: boolean;
    validWarranty?: boolean;
}

export const TableLoansPrincipal: React.FC<TableWarrantyProps> = ({ idPrincipal, showFiado, validWarranty}:TableWarrantyProps)=> {

    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const fiadoAction = useSelector((state: RootStore) => state.fiadoAction.fiadoAction);

    const [listGarantias, setListGarantias] = useState<ListGarantiaModel []>([]);
    const [loadingGarantias, setLoadingGarantias] = useState<boolean>(true);
    const [errorGarantias, setErrorGarantias] = useState<boolean>(false);
    const [paginacion, setPaginacion] = useState<PaginacionModel>(initPaginacion);
    const [listPaginacion, setListPaginacion] = useState<number[]>([]);

    const initFilter: FiltersSearchWarantyModel ={
        pendiente: false,
        revision: false,
        vencida: false,
        vigente: false,
        showLoansPrincipal: true
    }

    const [search, setSearch] = useState<string>('');

    const [filterSearch, setfilterSearch] = useState<FiltersSearchWarantyModel>(initFilter);

    const [clientInfo, setClientInfo] = useState<ClienteModel>({
        idCliente: 0,
        idAgente: 0,
        razonSocial: '',
        rfc:'',
        agente: '',
        propietario: '',
        estado: 0,
        estadoBloqueoCliente: false,
        estadoBloqueoComision: false,
        descripcion: ''
    });

    const [listObligorsClient, setListObligorsClient] = useState<InfoObligadoSolidario[]>([]);

    const [selectedObligors, setSelectedObligors] = useState<InfoObligadoSolidario[]>([]);

    const [listOptionsOS, setListOptionsOS] = useState<OptionsMultiSelect[]>([]);

    const [listOptionsOSSelected, setListOptionsOSSelected] = useState<OptionsMultiSelect[]>([]);

    const getInfoClient = async (idPrincipal: number) => {

        const res = await apiService.getDetalleCliente(idPrincipal);
        loadingOptionsOs(res.data.obligadosSolidarios)
        setClientInfo(res.data.cliente)
        setListObligorsClient(res.data.obligadosSolidarios);

        //y luego traer las garantìas
        getWarranties(idPrincipal, paginacion, [], search, filterSearch);

    }

    const getWarranties = async (idPrincipal: number,
                                  paginacion: PaginacionModel,
                                  obligadoSolidarios:InfoObligadoSolidario [],
                                  search: string | null,
                                  filtersSearch: FiltersSearchWarantyModel) => {

            setLoadingGarantias(true);
            setErrorGarantias(false)
            if (search == ''){search = null}
            try {
                const res = await garantiasService.getGarantias(idPrincipal,paginacion,obligadoSolidarios,filtersSearch, search );
                const response: {paginacion: PaginacionModel, elementos: ListGarantiaModel[]} = res.data;
                response.paginacion.size = paginacion.size;
                setListPaginacion(loadPaginationList(response.paginacion));
                setLoadingGarantias(false);
                setPaginacion(response.paginacion);
                setListGarantias(response.elementos)


            }
            catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                    setLoadingGarantias(false);
                    setErrorGarantias(true)

                }
            }
    };

    const loadingOptionsOs = (listObligors: InfoObligadoSolidario []) => {
        const listOption: OptionsMultiSelect[] = [];
        listObligors.forEach((i, index) => {
            listOption.push({value: i.idCliente.toString(), label: i.razonSocial})
        })
        setListOptionsOS(listOption)

    }

    const clickGoToPage = (page: number) => {
        const totalPages: number = paginacion.totalPages;
        if (page>=1 &&  page <= totalPages){
            const auxPaginacion = paginacion;
            auxPaginacion.currentPage = page;
            setPaginacion(auxPaginacion);
            getWarranties(idPrincipal, auxPaginacion, selectedObligors,  search, filterSearch);
        }

    }

    const handleInputChangeSelect = (e: InputChange) => {
        let auxPaginacion = paginacion;
        auxPaginacion.size = formatValue(e.target.value)
        auxPaginacion.currentPage = 1;
        getWarranties(idPrincipal, auxPaginacion, selectedObligors,  search, filterSearch);
        setPaginacion(auxPaginacion);

    }

    const handleInputChange = (e: InputChange) => {
        let value = e.target.value;
        const name = e.target.name;
        setSearch(value)
    }
    // filters
    const handleCheckBoxChange = (e: InputChange) => {
        const name = e.target.name;
        // console.log(e.target.value);
        // console.log(e.target.name);
        let auxFiltersSearch = filterSearch;
        switch (name){

            case EstatusGarantiaEnum.pendiente:
                auxFiltersSearch.pendiente = auxFiltersSearch.pendiente? false: true
                setListGarantias([]);
                setfilterSearch(auxFiltersSearch)
                getWarranties(idPrincipal, paginacion, selectedObligors,  search, auxFiltersSearch);
                break;

            case EstatusGarantiaEnum.revision:
                auxFiltersSearch.revision = auxFiltersSearch.revision? false: true
                setListGarantias([]);
                setfilterSearch(auxFiltersSearch)
                getWarranties(idPrincipal, paginacion, selectedObligors,  search, auxFiltersSearch);

                break;

            case EstatusGarantiaEnum.vigente:
                auxFiltersSearch.vigente = auxFiltersSearch.vigente? false: true
                setListGarantias([]);
                setfilterSearch(auxFiltersSearch)
                getWarranties(idPrincipal, paginacion, selectedObligors,  search, auxFiltersSearch);
                break;

            case EstatusGarantiaEnum.vencida:
                auxFiltersSearch.vencida = auxFiltersSearch.vencida? false: true
                setListGarantias([]);
                setfilterSearch(auxFiltersSearch)
                getWarranties(idPrincipal, paginacion, selectedObligors,  search, auxFiltersSearch);
                break;

            case 'showLoansPrincipal':

                let newSelectedObligors = [...selectedObligors]

                auxFiltersSearch.showLoansPrincipal = auxFiltersSearch.showLoansPrincipal? false: true
                setfilterSearch(auxFiltersSearch)

                if (newSelectedObligors.length == 0 && !auxFiltersSearch.showLoansPrincipal){
                    cleanFilters();
                }else {
                    getWarranties(idPrincipal, paginacion, newSelectedObligors,  search, filterSearch);
                }

                break;
        }

    }

    const selectedObligor = (options: OptionsMultiSelect[]) => {

        const newListObligorsSelected:  InfoObligadoSolidario[] = [];

        let auxfilterSearch: FiltersSearchWarantyModel = filterSearch;
        if (options.length > 0){
            options.forEach((option, index) => {
                console.log(option)
                console.log(listObligorsClient)
                const found = listObligorsClient.find(obligor => obligor.idCliente == parseInt(option.value))
                console.log(found)
                if (found){
                    newListObligorsSelected.push(found)
                }
            })

            auxfilterSearch.showLoansPrincipal = false;
        }else {
            auxfilterSearch.showLoansPrincipal = true;
        }
        getWarranties(idPrincipal, paginacion, newListObligorsSelected, search, auxfilterSearch);
        setfilterSearch(auxfilterSearch)
        setListOptionsOSSelected(options)

    }
    const cleanFilters = () => {
        let auxfiltersSearch: FiltersSearchWarantyModel  = filterSearch;
        auxfiltersSearch.pendiente = false;
        auxfiltersSearch.revision = false;
        auxfiltersSearch.vencida = false;
        auxfiltersSearch.vigente = false;
        auxfiltersSearch.showLoansPrincipal = true;

        let obligorsClientAux: InfoObligadoSolidario[] = listObligorsClient;

        getWarranties(idPrincipal, paginacion, obligorsClientAux, search, filterSearch);
        setSelectedObligors([]);
        auxfiltersSearch.showLoansPrincipal = false;
        setfilterSearch(auxfiltersSearch)
    }

    const onClickSearch = () => {
        getWarranties(idPrincipal, paginacion, selectedObligors,  search, filterSearch);
    }

    const onKeyDownSearch = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            getWarranties(idPrincipal, paginacion, selectedObligors,  search, filterSearch);
        }
    }

    const updateFiadoAction = () => {
        switch (fiadoAction?.action) {
            case ADDOS:
                // traer la informaciòn del usuario
                getInfoClient(idPrincipal);
                break;
            default:
                // traer la informaciòn del usuario
                getInfoClient(idPrincipal);
                break;
        }
    }

    useEffect(() => {
        updateFiadoAction();
    },[idPrincipal, fiadoAction]);

    return (
        <div id="table-warranties">
            {/*mostrar N entradas*/}

            <div className="row" id="seccion-botons">

                    <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                        <Select
                            isRequired
                            name="nEntries"
                            labelA11y=""
                            labelVisual={languageState.language?.tableWarranty.show + ' '+ String(paginacion.size) + ' ' + languageState.language?.tableWarranty.registers}
                            value={String(paginacion.size)}
                            onChange={handleInputChangeSelect}
                            placeholderOption="">
                            <SelectOption value="10">
                                10
                            </SelectOption>
                            <SelectOption value="25">
                                25
                            </SelectOption>
                            <SelectOption value="50">
                                50
                            </SelectOption>
                            <SelectOption value="100">
                                100
                            </SelectOption>
                        </Select>

                    </div>

                    <div className="col-12 col-sm-12 col-md-1 col-lg-4"/>

                    <div className="col-12 col-sm-12 col-md-4 col-lg-3">
                        <MultiSelect
                            options={listOptionsOS}
                            value={listOptionsOSSelected}
                            onChange={selectedObligor}
                            disabled={loadingGarantias}
                            labelledBy="Select" // <- for accessiblity
                            overrideStrings={{
                                "selectSomeItems": languageState.language?.tableWarranty.filtersObligors!,
                                "search": languageState.language?.tableWarranty.searchOs!,
                                "selectAll": languageState.language?.tableWarranty.selectAllOs!,
                                "allItemsAreSelected":languageState.language?.tableWarranty.allOsSelected!
                            }}
                        />
                    </div>

                    <div className="col-12 col-sm-12 col-md-4 col-lg-3">

                         <div className="input-group mb-3">
                            <input type="text" className="form-control"
                                   onChange={handleInputChange}
                                   onKeyDown={onKeyDownSearch}
                                   value={search}
                                   disabled={loadingGarantias}
                                   placeholder={languageState.language?.tableWarranty.search}
                        />

                             <div className="input-group-append">
                                 <button className="btn btn-outline-secondary"
                                         disabled={loadingGarantias}
                                         onClick={event => {onClickSearch()}}
                                         type="button" id="button-addon2">  <FaSearch />
                                 </button>
                             </div>

                        </div>
                    </div>

                <div className="col-12 col-sm-12 col-md-6">
                    <br/>
                </div>

                <div className="col-12 col-sm-12 col-md-6">

                        <CheckboxGroup disabled={loadingGarantias} onChange={function onChange(){}} >

                            <div className="row">
                                <div className="col">
                                    <Checkbox id={'checkbox-' + EstatusGarantiaEnum.vigente}
                                              className="float-right"
                                              labelVisual={languageState.language?.tableWarranty.current}
                                              onChange={handleCheckBoxChange}
                                              name={EstatusGarantiaEnum.vigente}
                                              checked={filterSearch.vigente}/>
                                </div>
                                <div className="col">
                                    <Checkbox id={'checkbox-' + EstatusGarantiaEnum.revision}
                                              className="float-right"
                                              labelVisual={languageState.language?.tableWarranty.review}
                                              onChange={handleCheckBoxChange}
                                              name={EstatusGarantiaEnum.revision}
                                              checked={filterSearch.revision}/>
                                </div>
                                <div className="col">
                                    <Checkbox
                                        id={'checkbox-' + EstatusGarantiaEnum.pendiente}
                                        className="float-right"
                                        labelVisual={languageState.language?.tableWarranty.pendient}
                                        onChange={handleCheckBoxChange}
                                        name={EstatusGarantiaEnum.pendiente}
                                        checked={filterSearch.pendiente}

                                    />
                                </div>
                                <div className="col">
                                    <Checkbox
                                        id={'checkbox-' + EstatusGarantiaEnum.vencida}
                                        className="float-right"
                                        labelVisual={languageState.language?.tableWarranty.expire}
                                        onChange={handleCheckBoxChange}
                                        name={EstatusGarantiaEnum.vencida}
                                        checked={filterSearch.vencida}

                                    />
                                </div>
                            </div>

                        </CheckboxGroup>

                    </div>


            </div>

            {loadingGarantias? (
                    <div className="w-100">
                        <SpinnerLoading
                            text={languageState.language?.tableWarranty.loadingWarranties}/>
                    </div>
                ):
                (errorGarantias)? (
                    <div className="col-md-12">
                            <NegativeAlert
                                textAlert={languageState.language?.tableWarranty.errorWarranties!}/>
                        </div>
                    ):
                    <div className="col-md-12 ">
                        {/*data table*/}
                        <Table className="">
                            <thead>
                            <TableRow className="text-center">
                                <TableCell dataHeader={languageState.language?.tableWarranty.invoice} type="colHead">
                                    {languageState.language?.tableWarranty.invoice}
                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.warranty} type="colHead">
                                    <div className="warranty-name">
                                        {languageState.language?.tableWarranty.warranty}
                                    </div>

                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.typeWarranty} type="colHead">
                                    {languageState.language?.tableWarranty.typeWarranty}
                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.valueWarranty} type="colHead">
                                    {languageState.language?.tableWarranty.valueWarranty}
                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.amountWeighted} type="colHead">
                                    {languageState.language?.tableWarranty.amountWeighted}
                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.dateCapture} type="colHead">
                                    {languageState.language?.tableWarranty.dateCapture}
                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.vigency} type="colHead">
                                    {languageState.language?.tableWarranty.vigency}
                                </TableCell>
                                {showFiado  ? (
                                    <TableCell dataHeader={languageState.language?.tableWarranty.fiado} type="colHead">
                                        {languageState.language?.tableWarranty.fiado}
                                    </TableCell>): null}
                                <TableCell dataHeader={languageState.language?.tableWarranty.propietary} type="colHead">
                                    {languageState.language?.tableWarranty.propietary}
                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.relevantDescription} type="colHead">
                                    {languageState.language?.tableWarranty.relevantDescription}
                                </TableCell>
                                <TableCell dataHeader={languageState.language?.tableWarranty.status} type="colHead">
                                    {languageState.language?.tableWarranty.status}
                                </TableCell>
                            </TableRow>
                            </thead>
                            <tbody>
                            {
                                listGarantias.map((i, index) =>
                                    (     <TableRow key={i.idGarantia}>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.invoice} type="rowHead">
                                                {i.folio}{(i.version> 0)? '-' + i.version: null}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.warranty} className="desc-gar">
                                                {i.descripcionClave}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.typeWarranty}>
                                                {i.descripcionTipoUso}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.valueWarranty}>
                                                {formatCurrency(i.monto)}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.amountWeighted} >
                                                {formatCurrency(i.montoPonderado)}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.dateCapture}
                                                       className="capture-gar">
                                                {i.fechaCaptura}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.vigency}
                                            className="vigency-gar">
                                                {i.fechaInicial}<br/> {i.fechaFinal}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.propietary}>
                                                {i.propietario}
                                            </TableCell>

                                            {showFiado  ? (
                                                <TableCell dataHeader={languageState.language?.tableWarranty.fiado}>
                                                    {i.idCliente}
                                                </TableCell>): null}
                                            <TableCell dataHeader={languageState.language?.tableWarranty.relevantDescription}
                                            className="desc-rel">
                                                {(i.descRelevante)? <AcordionTruncate length={50} text={i.descRelevante!}/> : null}
                                            </TableCell>
                                            <TableCell dataHeader={languageState.language?.tableWarranty.status} className="estatus">

                                                <StatusRowWarranty
                                                    idWarranty={i.idGarantia}
                                                    folio={i.folio}
                                                    version={i.version}
                                                    idPrincipal={i.idCliente}
                                                    estadoVigencia={i.estadoVigencia}
                                                    descEstadoVigencia={i.descEstadoVigencia}
                                                    returnDelete={del => {
                                                        if (del){
                                                            clickGoToPage(paginacion.currentPage)
                                                       }
                                                    }}
                                                />

                                            </TableCell>

                                        </TableRow>
                                    ))
                            }

                            </tbody>
                        </Table>

                        <div className="row pagination-sdgf">
                            <div className="col-md-5 show-registers">
                                {languageState.language?.tableWarranty.showing}&nbsp;
                                {(paginacion.currentPage * paginacion.size)- (paginacion.size-1)}  {languageState.language?.tableWarranty.to}&nbsp;
                                {(paginacion.currentPage * paginacion.size)}  {languageState.language?.tableWarranty.of}&nbsp;
                                {paginacion.totalItems}  {languageState.language?.tableWarranty.entries}.
                            </div>
                            <div className="col-md-7 ">
                                <Pagination className="float-md-right" id="pagination-table-warranty">
                                    <Pagination.Prev
                                        onClick={()=>{
                                            clickGoToPage((listPaginacion[0])-1)
                                        }}
                                    />
                                    {
                                        listPaginacion.map((i, index) =>
                                            ( <Pagination.Item
                                                    key={i}
                                                    active={i === paginacion.currentPage}
                                                    onClick={()=>{
                                                        clickGoToPage(i)
                                                    }}>
                                                    {i}
                                                </Pagination.Item>

                                            ))
                                    }
                                    <Pagination.Next
                                        onClick={()=>{
                                            clickGoToPage(listPaginacion[listPaginacion.length-1]+1)
                                        }}
                                    />

                                </Pagination>

                            </div>

                        </div>


                    </div>

            }




        </div>
    );
};