export interface ConfigAccountLanguageModel {
   title: string,
   fisica:string,
   morales:string,
   user:string,
   password:string,
   cancel:string,
   edit:string,
   confirm:string,
   send:string,
   alert_conf:string,
   new_contra:string,
   continue:string
  

}

export const  ConfigAccountLanguageEnglish:  ConfigAccountLanguageModel ={
   title:'Account Management',
   fisica:'Physical Person User',
   morales:'Moral User',
   user:'User',
   password:'Password',
   cancel:'Cancel',
   edit:'Edit',
   confirm: 'Confirm Password',
   send: 'Update',
   alert_conf:'Passwords do not match',
   new_contra:'Password updated successfully',
   continue:'Continue'

}


export const  ConfigAccountLanguageSpanish:  ConfigAccountLanguageModel ={
   title: 'Administración de cuenta',
   fisica:'Usuario Fisica',
   morales:'Usuario Moral',
   user:'Usuario',
   password:'Contraseña',
   cancel:'Cancelar',
   edit:'Editar',
   confirm:'Confirmar Contraseña',
   send:'Actualizar',
   alert_conf:'Las contraseñas no coinciden',
   new_contra:'Contraseña actualizada exitosamente',
   continue:'Continuar'
}