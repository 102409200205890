import { Button } from '@lmig/lmds-react/Button/Button';
import Link from '@lmig/lmds-react/Link/Link';
import React, { Component } from 'react'
import { Card, CardDeck, Dropdown, Pagination } from 'react-bootstrap';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { connect } from 'react-redux';
import { RouteComponentProps} from 'react-router-dom';
import { initPaginacion, InputChange, loadPaginationList } from '../../../class/CommonClass';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import NavBar from '../../../components/public/nav-bar/NavBar';
import  {  TypeProfileModel } from '../../../components/garantias/select-type-user/SelectTypeUser';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import './Dashboard.scss'
import { HiPlus } from "react-icons/hi";
import { Alert, AlphanumericInput, Badge, GridCol, IconSeeMore, Table, TableCell, TableRow } from '@lmig/lmds-react';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import { AxiosError } from 'axios';
import { ConsultaBuro } from '../../../models/buro-credito/ConsultaBuro';
import { Datos } from '../../../models/buro-credito/Datos';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { UserModel } from '../../../models/public/UserModel';

interface Params {
    folio: string;
}

interface BureauProps {
    language: DefaultStateI
}

class Dashboard extends Component<BureauProps & RouteComponentProps<Params>, BureauState> {

    public localStorageClass = new LocalStorageClass();
    public folio: number;



    constructor(props: any) {
        super(props);
        document.title = "Buró de Crédito"



        this.state = {
            status: 0,
            listBureau: [],
            listPorcent: [],
            valida_tabla: false,
            search: '',
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            PERFIL: this.localStorageClass.getProfile(),
            errorTableDetail: false,
            filterSearch: {
                folio: ''
            },
            validador: true


        }
        this.folio = parseInt(this.props.match.params.folio);
        this.handlenew = this.handlenew.bind(this);
        this.getCredit = this.getCredit.bind(this);
        this.process = this.process.bind(this);
        this.vigent = this.vigent.bind(this);
        this.vencid = this.vencid.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
    }



    render() {
        return <>
                <NavBar />
            <div className="container-fluid " id="creditbureau">
        
                <div className="creditbureau-container" >
                    <BreadCumb
                        title={this.props.language.language?.creditbureau.credittitle!}
                    />



                    <CardDeck className="cards-percentage">
                        {this.state.listPorcent.map((i, index) =>
                        (
                            <Card key={i.id}>
                                <Card.Body>
                                    <Card.Title>{i.nombre}</Card.Title>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <CircularProgressbar
                                                value={i.porcentaje}
                                                text={`${i.porcentaje}%`}
                                                styles={buildStyles({
                                                    // Rotation of path and trail, in number of turns (0-1)
                                                    rotation: 0.25,
                                                    // Text size
                                                    textSize: '16px',
                                                    // How long animation takes to go from one percentage to another, in seconds
                                                    pathTransitionDuration: 0.5,
                                                    // Colors
                                                    pathColor: `rgba(6, 116, 140, 0.5)`,
                                                    textColor: '#06748C',

                                                })} />
                                        </div>
                                        <div className="col-md-6 ">
                                            <Card.Text>

                                            </Card.Text>
                                        </div>

                                    </div>

                                </Card.Body>
                                <Card.Footer className="text-right bg-white">
                                    {i.id === '1' &&
                                        <Link onClick={this.process}>
                                            {this.props.language.language?.creditbureau.creditopen!}

                                        </Link>
                                    }

                                    {i.id === '2' &&
                                        <Link onClick={this.vigent}>
                                            {this.props.language.language?.creditbureau.creditopen!}

                                        </Link>
                                    }
                                    {i.id === '3' &&
                                        <Link onClick={this.vencid}>
                                            {this.props.language.language?.creditbureau.creditopen!}

                                        </Link>
                                    }
                                    {i.id === '4' &&
                                        <Link onClick={this.pendientes}>
                                            {this.props.language.language?.creditbureau.creditopen!}

                                        </Link>
                                    }
                                </Card.Footer>
                            </Card>
                        ))
                        }
                    </CardDeck>
                    <br />
                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-8 " id="search-agent">



                                <AlphanumericInput
                                    id="Alphanumeric--default"
                                    name="search-agent"
                                    labelVisual={this.props.language.language?.creditbureau.creditsearch}
                                    onChange={this.handleInputChange}
                                    value={this.state.search}
                                />



                            </div>

                            <div className="col-4 ">
                                <GridCol >
                                    <Button variant="primary" onClick={this.handlenew}>
                                        <HiPlus /> {this.props.language.language?.creditbureau.creditbutton!}
                                    </Button>
                                </GridCol>
                            </div>

                        </div>

                    </div>

                </div>

                <br />
                <div className="col-md-12">
                    {this.state.errorTableDetail ? (
                        <div className="col-12">
                            <Alert highlightType="negative">
                                {this.props.language.language?.request.alert!}
                            </Alert>
                        </div>
                    ) :
                        <>

                            {this.state.valida_tabla === true ? (
                                <div className="col-12">
                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                </div>
                            ) :

                                <>

                                    {/*data table*/}
                                    <Table id='table-credit' >
                                        <thead>
                                            <TableRow >
                                                <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditfolio!}
                                                </TableCell>
                                                <TableCell dataHeader={'Nombre'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditname!}
                                                </TableCell>
                                                <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.credittype!}
                                                </TableCell>

                                                <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditdate!}
                                                </TableCell>
                                                <TableCell dataHeader={'Estatus'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditstatus!}
                                                </TableCell>


                                            </TableRow>
                                        </thead>

                                        <tbody>

                                            {this.state.validador === true ? (
                                                <>
                                                    {
                                                        this.state.listBureau.map((value: ConsultaBuro, index: number) => (

                                                            <TableRow key={index} id={value.folio} >

                                                                <TableCell type="rowHead">
                                                                    {value.folio}
                                                                </TableCell>


                                                                <TableCell>
                                                                    {value.nombre + " " + value.apellidoPaterno}
                                                                </TableCell>
                                                                <TableCell >
                                                                    {value.tipoEntidad}
                                                                </TableCell>




                                                                <TableCell >
                                                                    {value.fechaConsulta}
                                                                </TableCell>

                                                                <TableCell dataHeader={'Estatus'} className="estatus">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            {value.estado === '1' ? (
                                                                                <Badge highlightType="informative" >
                                                                                    {this.props.language.language?.request.revison!}
                                                                                </Badge>
                                                                            ) : null}
                                                                            {value.estado === '2' ? (
                                                                                <Badge highlightType="positive">
                                                                                    {this.props.language.language?.request.autorizada!}
                                                                                </Badge>
                                                                            ) : null}
                                                                            {value.estado === '3' ? (
                                                                                <Badge highlightType="negative">
                                                                                    {this.props.language.language?.request.rechazada!}
                                                                                </Badge>
                                                                            ) : null}
                                                                            {value.estado === '4' ? (
                                                                                <Badge highlightType="caution">
                                                                                    Pendiente
                                                                                </Badge>
                                                                            ) : null}
                                                                            {value.estado === '5' ? (
                                                                                    <Badge highlightType="negative">
                                                                                        &nbsp;&nbsp;{this.props.language.language?.creditbureau.error!}&nbsp;&nbsp;
                                                                                    </Badge>
                                                                                ) : null}
                                                                        </div>
                                                                        <div className="col">
                                                                            {value.estado ==='1' ? (
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                        <IconSeeMore></IconSeeMore>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => this.handleProcess(value.folio!)} >
                                                                                            {this.props.language.language?.creditbureau.creditconsult!}
                                                                                        </Dropdown.Item>

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            ) : null}
                                                                            {value.estado === '2' ? (
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                        <IconSeeMore></IconSeeMore>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => this.handleConsulta(value.folio!)

                                                                                        }>
                                                                                            {this.props.language.language?.creditbureau.creditconsult!}
                                                                                        </Dropdown.Item>

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            ) : null}
                                                                            {value.estado === '3' ? (
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                        <IconSeeMore></IconSeeMore>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => this.handleVencid(value.folio!)

                                                                                        }>
                                                                                            {this.props.language.language?.creditbureau.creditconsult!}
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => this.handleEdit(value.folio!)

                                                                                        }>
                                                                                            Editar
                                                                                        </Dropdown.Item>

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            ) : null}
                                                                            {value.estado === '4' ? (
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                        <IconSeeMore></IconSeeMore>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => this.handlePendiente(value.folio!)

                                                                                        }>
                                                                                            {this.props.language.language?.creditbureau.creditconsult!}
                                                                                        </Dropdown.Item>

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            ) : null}
                                                                            {value.estado === '5' ? (
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                            <IconSeeMore></IconSeeMore>
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item onClick={() => this.handleError(value.folio!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.creditconsult!}
                                                                                            </Dropdown.Item>

                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                ) : null}
                                                                        </div>
                                                                    </div>



                                                                </TableCell>

                                                            </TableRow>



                                                        ))
                                                    }

                                                </>
                                            ) : null}


                                        </tbody>
                                    </Table>
                                </>}
                        </>}
                    {this.state.validador === false ? (
                        <>
                            <p>{this.props.language.language?.creditbureau.data!}</p>
                        </>) : null}

                    {/*paginacion*/}
                    < div className="row pagination-sdgf" >
                        <div className="col-md-5 show-registers">
                            {this.props.language.language?.creditbureau.creditmostrar!}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size) - (this.state.paginacion.size - 1)}  {this.props.language.language?.creditbureau.credital!}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size)}  {this.props.language.language?.creditbureau.creditotal!}&nbsp;
                            {this.state.paginacion.totalItems}  {this.props.language.language?.creditbureau.creditrecors!}.
                        </div>
                        <div className="col-md-7 ">
                            <Pagination className="float-md-right" >
                                <Pagination.Prev
                                    onClick={() => {
                                        this.clickGoToPage((this.state.listPaginacion[0]) - 1)

                                    }}
                                />
                                {
                                    this.state.listPaginacion.map((i, index) =>
                                    (<Pagination.Item
                                        key={i}
                                        active={i === this.state.paginacion.currentPage}
                                        onClick={() => {
                                            this.clickGoToPage(i);
                                        }}>
                                        {i}
                                    </Pagination.Item>

                                    ))
                                }
                                <Pagination.Next
                                    onClick={() => {
                                        this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length - 1] + 1)
                                    }}
                                />

                            </Pagination>

                        </div>

                    </div>


                </div>

            </div>
        </>

    }
    loadDashboard(tipoPerfil: TypeProfileModel) {
        throw new Error('Method not implemented.');
    }

    public handlenew() {
        this.props.history.push('/buro-credito/Crear-Solicitud');
    }

    componentDidMount() {
        this.getCredit(this.state.search, this.state.paginacion);
        this.getPorecentaje();
    }

    public handleInputChange(e: InputChange): void {
        const value = e.target.value;
        this.setState({ search: value })
        this.getCredit(this.state.search, this.state.paginacion)
    }

    public async getCredit(search: string, paginacion: PaginacionModel) {


        try {

            this.setState({ errorTableDetail: true })
            const res = await getCreditbureau.getConsulAgent(this.state.search,0, '1', paginacion, this.state.PERFIL.username);
            const response: { paginacion: PaginacionModel, consultas: ConsultaBuro[] } = res.data;
            response.paginacion.size = paginacion.size;


            this.setState({
                paginacion: response.paginacion,
                loadingConsult: false,
                listBureau: response.consultas,
                listPaginacion: loadPaginationList(res.data.paginacion),
                validador: true
            })

            if (response.consultas == null) {


                this.setState({ validador: true })
                this.setState({ valida_tabla: true })

            } else {
                this.setState({ valida_tabla: false })

                this.setState({ errorTableDetail: false })
            }




        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    private clickGoToPage(page: number) {
        const totalPages: number = this.state.paginacion.totalPages;
        if (page >= 1 && page <= totalPages) {
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({ paginacion: auxPaginacion })
            this.getCredit(this.state.search, this.state.paginacion);
        }



    }


    public handleError(value: string) {


        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/consultar/error');
    }


    public process() {
        this.props.history.push('/buro-credito/filtrar-Solicitudes/En-revision');
    }

    public vigent() {
        this.props.history.push('/buro-credito/filtrar-Solicitudes/Autorizadas');
    }
    public vencid() {
        this.props.history.push('/buro-credito/filtrar-Solicitudes/Rechazadas');
    }
    public pendientes() {

        this.props.history.push('/buro-credito/filtrar-Solicitudes/Pendiente');
    }
    public handleConsulta(value: string) {

        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/Solicitudes/Consultas_autorizadas');
    }

    public handlePendiente(value: string) {

        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/Solicitudes/Consulta_pendiente');
    }
    public handleProcess(value: string) {

        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/Solicitudes/Consultas_enrevision');
    }
    public handleVencid(value: string) {


        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/Solicitudes/Consultas_rechazadas');
    }
    public handleEdit(value: string) {


        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/Solicitudes/Editar_consulta');
    }
    public async getPorecentaje() {
        try {
            const res = await getCreditbureau.getPorecentaje(this.state.PERFIL.roles.toString(),this.state.PERFIL.username);
            this.setState({ listPorcent: res.data })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }





}






function mapStateToProps(state: RootStore) {
    return ({
        language: state.language
    });
}


export default connect(mapStateToProps)(Dashboard);

interface BureauState {
    status: number,
    listBureau: ConsultaBuro[];
    listPorcent: Datos[];
    search: string;
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    filterSearch: SearchModel,
    validador: boolean,
    valida_tabla: boolean,
    PERFIL: UserModel,
    errorTableDetail: boolean
}

export interface SearchModel {
    folio: string;
}





