import React, { useEffect, useState } from 'react';
import { Accordion, Card, ModalBody, ModalFooter, Nav, Navbar } from 'react-bootstrap';
import './NavBar.scss';
import logo from '../../../assets/ico/PNG/LibertyFianzas_BLUE_RGB.png';
import womanImg from '../../../assets/ico/Woman_at_Desk8.png';

import {
    IconMenu,
    IconClose,
    IconBell,
    Modal, GridRow, GridCol, Button,
    IconHelp, Heading,
} from '@lmig/lmds-react'
import { useHistory } from "react-router-dom";
import SelectLanguage from "../select-language/SelectLanguage";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { UserModel } from "../../../models/public/UserModel";
import { validRoles } from "../../../class/CommonClass";
import environment from "../../../environments/environment";
import Dropdown from 'react-bootstrap/Dropdown';
import { getActionSpecials } from "../../../actions/SpecialActions";
import * as apiTokenService from "../../../services/api-session/ApiSession";
import * as apiService from '../../../services/api-gral/ApiGralService';
import {AxiosError} from "axios";
import {getPerfilEjeGar} from "../../../services/api-gral/ApiGralService";
import {NavLF, FooterLF} from "@lmig/lf-react-components/src/";
import '@lmig/lf-react-components/src/index.css'

import {getLanguage} from "../../../actions/SelectLanguageActions";
import { LanguageModel } from '../../../models/public/LanguageModel';
import NavBarOld from './NavBarOld';

const URLVIDEO = environment.URLVIDEO

const NavBar = () => {
    const dispatch = useDispatch();

    const localStorageClass = new LocalStorageClass();

    const history = useHistory();

    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    // side bar
    const [openSideBar, setOpenSideBar] = useState(false);
    // modales
    const [openModalClose, setOpenModalClose] = useState(false);

    const [openModalVideo, setOpenModalVideo] = useState(false);

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [isOpen, setIsOpen] = useState(false);

    const [visibleReporteExpediente, setVisibleReporteExpediente] = useState(false);

    useEffect(() => {
        // si hay token hacer las acciones correspondientes
        if (PERFIL.accessToken) {
            // obtener los permisos especiales
            obtainActionsSpecials();
            validToken(PERFIL.accessToken);

        }

    }, [history.location.pathname]);

    const validToken = async (token: string) => {
        try {
            const res = await apiTokenService.getValidaToken(PERFIL.accessToken);
            //   console.log(res.data)
            validOficina()


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                if (err.response.status == 401) {
                    setIsOpen(true);
                }


            }
        }
    }

    const validOficina = async () => {
        setVisibleReporteExpediente(false)
        if (validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)) {
            try {
                const res = await apiService.getPerfilEjeGar();

                if (res.data.oficina == 'OFICINA CENTRAL LIBERTY FIANZAS') {
                    setVisibleReporteExpediente(true);
                }


            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    setVisibleReporteExpediente(false);
                }
            }
        }

    }

    const open = () => {
        if (!openSideBar) {
            setOpenSideBar(true);
            // @ts-ignore
            document.getElementById('mySidenav').style.width = '250px';
            // @ts-ignore
            document.getElementById('coverNav').style.width = '100%';
        } else {
            close()
        }

    };

    const close = () => {
        setOpenSideBar(false);
        // @ts-ignore
        document.getElementById('mySidenav').style.width = '0';
        // @ts-ignore
        document.getElementById('coverNav').style.width = '0';


    };

    const closeSession = () => {
        localStorage.removeItem(localStorageClass.PERFIL);
        redirectLogin();

    }

    const redirectLogin = () => {
        const URLLOGIN = environment.URLLOGIN
        if (environment.env == "dev") {
            history.push('/login')
        }
        else if (environment.env == "test") {
            window.location.replace(URLLOGIN);
        }
        else if (environment.env == "prod") {
            window.location.replace(URLLOGIN);
        }


    }

    const menuSeccion = (pathname: string) => {
        // console.log(pathname)
        let seccion = '';
        for (let i = 1; i < pathname.length; i++) {
            const letra = pathname.substring(i, i + 1);
            if (letra != '/') {
                seccion += letra;
            } else {
                break;
            }
        }
        return seccion;
    }

    const obtainActionsSpecials = async () => {
        await dispatch(getActionSpecials());

    }

    const selectLanguage = (languageSelect: LanguageModel) => {
        
        dispatch(getLanguage(languageSelect.idLenguaje));
    }

    

    return (
        <>
        {/* {(environment.env == 'dev')?
        <NavLF
                env={"dev"}
                roles={PERFIL.roles}
                returnLanguage={(language) => {selectLanguage(language)}
                }
              
            />:
            
       } */}
            <NavBarOld/>
        </>


    );
};

export default NavBar;