import environment from "../../environments/environment";
import {LocalStorageClass} from "../../class/LocalStorageClass";
import axios from "axios";
import {mensajeF} from "../../models/buro-credito/Fisicas";
import {mensaje} from "../../models/buro-credito/Morales";

const APIBURO= environment.APIBURO;
const localStorageClass = new LocalStorageClass();

const headers = {
    Accept: 'application/json',
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),
}

//Datos tabla Fisicas
export const getFisicas = async (

    rfc: string,
    nombre: string,
    segundoNombre:string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    fechaNacimiento: string,
    codigoPostal: string,
    calle: string,
    estadoDomicilio:string,
    codigoEstadoBuro:string,
    delegacionMunicipio:string,
    numexterior: string,
    folio:string,
    tipoConsulta:string,
    usuario:string,
    tipo:string,
    fechaAutorizaFormato:string,
    idFiado:number) => {
    return await axios.get<mensajeF>(`${APIBURO}/obtenerCreditosFisica?rfc=`+rfc+ `&nombre=`+ nombre+`&segundoNombre=`+ segundoNombre+`&apellidoPaterno=`+apellidoPaterno+`&apellidoMaterno=`+apellidoMaterno+`&fechaNacimiento=`+fechaNacimiento+
        `&codigoPostal=`+codigoPostal+`&calle=`+calle+`&estado=`+estadoDomicilio+`&codigoEstadoBuro=`+codigoEstadoBuro+`&delegacionMunicipio=`+delegacionMunicipio+`&numero=`+numexterior
        +`&pais=`+157+ `&nacionalidad=`+'MX'+`&folio=`+folio+`&tipoConsulta=`+tipoConsulta+`&usuario=`+usuario+`&tipo=`+tipo+`&fechaAutorizaFormato=`+fechaAutorizaFormato+`&idFiado=`+idFiado,  { headers: headers });
};

export const getMorales = async (
    rfc:string,
    nombre:string,
    calle:string,
    delegacionMunicipio:string,
    codigoPostal:string,
    estadoDomicilio:string,
    codigoEstadoBuro:string,
    numexterior:string,
    folio:string,
    tipoConsulta:string,
    usuario:string,
    tipo:string,
    fechaAutorizaFormato:string,
    idFiado:number
) => {
    return await axios.get<mensaje>(`${APIBURO}/obtenerCreditosMoral?rfc=` + rfc + `&nombre=`+nombre+ `&calle=`+calle+
        `&ciudad=`+codigoEstadoBuro+`&delegacionMunicipio=`+delegacionMunicipio+`&codigoPostal=`+codigoPostal+
        `&codigoEstadoBuro=`+estadoDomicilio+`&estado=`+codigoEstadoBuro+`&pais=`+157+ `&numero=`+numexterior+ `&folio=`+folio+ `&TipoConsulta=`+tipoConsulta+`&usuario=`+usuario+`&tipo=`+tipo+`&fechaAutorizaFormato=`+fechaAutorizaFormato+`&idFiado=`+idFiado,{ headers: headers });
};