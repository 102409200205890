export interface HistoryReviewLanguageModel {
  title: string;
  loan: string;
  
}

export const HistoryReviewLanguageModelLanguageEnglish: HistoryReviewLanguageModel = {
  title: "Warranty review",
  loan: "Loan",
  
};

export const HistoryReviewLanguageModelLanguageSpanish: HistoryReviewLanguageModel = {
  title: "Revision de garantía",
  loan: "Fiado",
  
};
