import React, {createRef, KeyboardEventHandler, useEffect, useState} from 'react';
import './TableEngagements.scss';
import {RootStore} from "../../../Store";
import {connect, useSelector} from "react-redux";
import {LocalStorageClass} from '../../../class/LocalStorageClass';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import {PaginacionModel} from '../../../models/public/PaginacionModel';
import {ElementosCompromiso} from '../../../models/compromisos/ElementosCompromiso';
import {UserModel} from "../../../models/public/UserModel";
import {TypeProfileCompromisos} from "../select-user-engagements/SelectUserEngagements";
import {TiposDeCompromisoEnum} from "../../../models/compromisos/TiposDeCompromisoEnum";
import {
    formatCurrency,
    formatValue,
    initPaginacion,
    InputChange,
    loadPaginationList,
    validRoles
} from "../../../class/CommonClass";
import {AxiosError} from "axios";
import {
    AlphanumericInput,
    Button,
    IconSeeMore,
    Popover,
    Select,
    SelectOption,
    Table,
    TableCell,
    TableRow
} from "@lmig/lmds-react";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {IconMyAccount} from "@lmig/lmds-react/_esm";
import Info from "@lmig/lmds-react/icons/Info";
import ReactTooltip from "react-tooltip";
import {Dropdown, Pagination} from "react-bootstrap";
import {TypeProfileEngagmentsEnum} from "../../../models/compromisos/TypeProfileEngagmentsEnum";
import {UserEngagementsSelected} from "../../../models/compromisos/UserEngagementsSelected";
import {Historial} from "../../../models/compromisos/Historial";

interface TableEngagementsProps {
    userSelected: UserEngagementsSelected;
    filtrosCompromisos: TiposDeCompromisoEnum;
}

export const TableEngagements: React.FC<TableEngagementsProps> = ({ userSelected, filtrosCompromisos }: TableEngagementsProps) => {

    const languageState = useSelector((state: RootStore) => state.language.language?.tableEngagements);

    const localStorageClass = new LocalStorageClass();


    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [paginacion, setPaginacion] = useState<PaginacionModel>(initPaginacion);

    const [listPaginacion, setListPaginacion] = useState<number[]>([]);

    const [listCompromisos, setListCompromisos] = useState<ElementosCompromiso []>([]);

    const [loadingCompromisos, setLoadingCompromisos] = useState<boolean>(true);

    const [errorCompromisos, setErrorCompromisos] = useState<boolean>(false);

    const [searchTerm, setSearchTerm] = useState('')


    let inputElement = createRef<HTMLInputElement>();

    let escribiendo = false;

    useEffect(() => {
        console.log('filtros compromisos ' + filtrosCompromisos );

        if (searchTerm.length == 0){
            getCompromisos(userSelected, filtrosCompromisos, initPaginacion, '')
        }else {
            const delayDebounceFn = setTimeout(() => {
                console.log(searchTerm)
                // Send Axios request here
                getCompromisos(userSelected, filtrosCompromisos, initPaginacion, searchTerm)
            }, 700)

            return () => clearTimeout(delayDebounceFn)
        }


    }, [userSelected, filtrosCompromisos, searchTerm]);

    const getCompromisos = async (userSelected: UserEngagementsSelected,
                            filtrosCompromisos: TiposDeCompromisoEnum,
                            paginacion: PaginacionModel, search: string) => {
        setLoadingCompromisos(true);
        setErrorCompromisos(false);

        try {
            const res = await engagementService.getCompromisosEstadisticas(
                paginacion,
                userSelected.idEjecutivoCuetaYGar,
                userSelected.idAgente,
                userSelected.clienteDirecto,
                filtrosCompromisos,
                search );

                for (const atributo of res.data.elementos) {

                    //console.log("ID FUNCIONARIO ASIGNADO: " + atributo.idFuncionario.toString() + " para el compromiso " + atributo.idCompromiso)

                    const historico = await getHistorialCompromiso(atributo.idCompromiso)


                    //console.log("CLAVE LOGUEADO: " + PERFIL.clave)

                    let asignado: string;
                    asignado = ''

                    let user: string;
                    user = ''

                    let estatus: string;
                    estatus = ''

                    let tipo: string;
                    tipo = ''


                    if (atributo.statusCompromiso == statusCompromiso.PORAUTORIZAR) {
                        estatus = 'Por Autorizar'

                    } else if (atributo.statusCompromiso == statusCompromiso.VIGENTE) {
                        estatus = 'Vigente'

                        for (const h of historico) {
                            if (h.idAccion = 2) {
                                user = h.usuario
                            }

                        }

                    } else if (atributo.statusCompromiso == statusCompromiso.VENCIDO) {
                        estatus = 'Vencido'
                    } else if (atributo.statusCompromiso == statusCompromiso.RECHAZADO) {
                        estatus = 'Rechazado'

                        for (const h of historico) {
                            if (h.idAccion = 4) {
                                user = h.usuario
                            }

                        }

                    } else if (atributo.statusCompromiso == statusCompromiso.REVOCADO) {
                        if (validRoles(PERFIL.roles, localStorageClass.AGENTE)) {
                            estatus = 'Revocado'
                        } else {
                            estatus = 'Revocado'

                            for (const h of historico) {
                                if (h.idAccion = 5) {
                                    user = h.usuario
                                }

                            }
                        }

                    } else if (atributo.statusCompromiso == statusCompromiso.CUMPLIDO) {
                        estatus = 'Cumplido'

                        for (const h of historico) {
                            if (h.idAccion = 6) {
                                user = h.usuario
                            }

                        }

                    } else if (atributo.statusCompromiso == statusCompromiso.PORACEPTARF) {
                        estatus = 'Por Aceptar Fiado'
                    } else if (atributo.statusCompromiso == statusCompromiso.RECHADOF) {
                        estatus = 'Rechazado Por Fiado'

                    } else if (atributo.statusCompromiso == statusCompromiso.AUTORIZAPLAZO) {
                        estatus = 'Por Autorizar Plazo'
                    }
                    atributo.descStatusCompromiso = estatus
                    atributo.username = user



                    if (atributo.tipoCompromiso == tiposCompromiso.PORAPOYO) {
                        tipo = 'Apoyo'
                    } else if (atributo.tipoCompromiso == tiposCompromiso.BLOQUEO) {
                        tipo = 'Bloqueo'
                    }

                    atributo.descTipoCompromiso = tipo

                }
                res.data.paginacion.size = paginacion.size;
                setListCompromisos(res.data.elementos);
                setPaginacion(res.data.paginacion);
                setLoadingCompromisos(false);
                setListPaginacion(loadPaginationList(res.data.paginacion));



        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingCompromisos(false);
                setErrorCompromisos(true);

            }
        }
      
    }

    const getHistorialCompromiso = async (idCompromiso: number): Promise<Historial[]> => {

        const res = await engagementService.getHistorialCompromiso(idCompromiso);
        const response = res.data;

        let listaHistorial: Historial[] = response;

        console.log("--> " + JSON.stringify(listaHistorial))

        return listaHistorial

    }

    const handleInputChangeSelect = (e: InputChange) => {
        let auxPaginacion = paginacion;
        auxPaginacion.size = formatValue(e.target.value)
        auxPaginacion.currentPage = 1;
        setPaginacion(auxPaginacion);

    }

    const handleInputKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
  /* //     console.log(e);
        const key = e.key;
        const keyCode = e.keyCode;
     //   console.log(key);
    //    console.log(keyCode);
         varSearchText = inputElement.current?.value!;
   //     console.log(inputElement.current?.value);

        if (varSearchText.length==0){
            varSearchText = ''
            getCompromisos(userSelected, filtrosCompromisos, initPaginacion, '');

        }else {
            await new Promise(done => setTimeout(() => {

                    console.log('buscar 2: ' + varSearchText);
                  //  if (!escribiendo){
                        getCompromisos(userSelected, filtrosCompromisos, initPaginacion, varSearchText!);
                  //  }

                // escribiendo = false;
            }, 550));
        }
*/
  }

    const handleInputKeyDown= async (e: React.KeyboardEvent<HTMLInputElement>) => {
        //console.log(e);
        escribiendo = true;

    }

    const clickGoToPage = (page: number) => {
        const totalPages: number = paginacion.totalPages;
        if (page>=1 &&  page <= totalPages){
            const auxPaginacion = paginacion;
            auxPaginacion.currentPage = page;
            setPaginacion(auxPaginacion);

            getCompromisos(userSelected, filtrosCompromisos, auxPaginacion, searchTerm);
          //  getInfoClient(idPrincipal, auxPaginacion, selectedObligors,  search, filterSearch);
        }

    }

    return (
        <>
          <div className="col-12">
                <div className="row seccion-botons" id="nShow-n-entries">
                    <div className="col-12 col-sm-12 col-md-3">
                        <Select
                            isRequired
                            name="nEntries"
                            labelA11y=""
                            labelVisual={languageState?.show + ' '+
                                String(paginacion.size) + ' ' +
                                languageState?.registers}
                            value={String(paginacion.size)}
                            onChange={handleInputChangeSelect}
                            placeholderOption="">
                            <SelectOption value="10"> 10 </SelectOption>
                            <SelectOption value="25"> 25 </SelectOption>
                            <SelectOption value="50"> 50 </SelectOption>
                            <SelectOption value="100"> 100 </SelectOption>
                        </Select>
                    </div>
                    <div className="col-12 col-sm-12 col-md-5" />
                    <div className="col-12 col-sm-12 col-md-4">
                        <AlphanumericInput
                            className="float-right w-100"
                            innerRef={inputElement}
                            autoComplete="off"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                           /* onKeyUp={handleInputKeyUp}
                            onKeyDown={handleInputKeyDown}*/
                            labelVisual={languageState?.search} />
                    </div>
                </div>
            </div>
            {loadingCompromisos?(
                <div className="col-12">
                    <SpinnerLoading text={languageState?.spinner}/>
                </div>):
                <>
                    <Table id="demo-table">
                        <thead>
                        {listCompromisos.length != 0 ? (
                            <TableRow>
                                    <TableCell dataHeader="" type="colHead">
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.folio}  type="colHead" className="extrasE">
                                        {languageState?.folio}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.rfc} type="colHead" className="extras">
                                        {languageState?.rfc}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.nombre} type="colHead" className="extras">
                                        {languageState?.nombre}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.tipo} type="colHead">
                                        {languageState?.tipo}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.producto} type="colHead">
                                        {languageState?.producto}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.monto} type="colHead" className="extras">
                                        {languageState?.monto}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.condiciones} type="colHead">
                                        {languageState?.condiciones}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.motivo} type="colHead">
                                        {languageState?.motivo}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.fecha}  type="colHead" className="extras">
                                        {languageState?.fecha}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.renovaciones}  type="colHead">
                                        {languageState?.renovaciones}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.estatus}  type="colHead" className="extras">
                                        {languageState?.estatus}
                                    </TableCell>
                                    <TableCell dataHeader="" type="colHead">  </TableCell>
                                </TableRow>
                        ) :<TableRow>
                                <TableCell dataHeader="A" type="colHead" >  </TableCell>
                            </TableRow> }
                        </thead>
                        <tbody>
                        {listCompromisos.length == 0 ? (
                            <><br />
                                <TableRow>
                                    <TableCell dataHeader="" type="colHead" className="extras"> {`${languageState?.sinRegistros}`}  </TableCell>
                                </TableRow>
                            </>
                        ) : null}
                        {listCompromisos.map((i, index) =>
                            <TableRow key={index}>
                                <TableCell dataHeader="A" type="rowHead"> <IconMyAccount size="24" /> </TableCell>
                                <TableCell dataHeader="K" type="rowHead" className="extras"> {i.idCompromiso} </TableCell>
                                <TableCell dataHeader="A" type="rowHead"> {i.rfc} </TableCell>
                                <TableCell dataHeader="Nombre"> {i.fiado}  </TableCell>
                                <TableCell dataHeader="Tipo"> {i.descTipoCompromiso} </TableCell>
                                <TableCell dataHeader="Producto">

                                    <Info title=" " data-tip={i.producto} data-for='getContent' size="16" className="prod"> </Info>
                                    <ReactTooltip id='getContent' className='custom-color-no-arrow'
                                                  getContent={(dataTip) => `${languageState?.producto}: ${dataTip}`} />

                                </TableCell>
                                <TableCell dataHeader="Monto" className="extras">{formatCurrency(i.monto)}  </TableCell>
                                <TableCell dataHeader="Condiciones">

                                    {i.condiciones == null ? (
                                        <>
                                            <Info title=" " data-tip={i.bloqueos.map((j, index) => j.descripcion)} data-for='getContent1' size="16" className="cond"> </Info>
                                            <ReactTooltip id='getContent1' className='custom-color-no-arrow' getContent={(dataTip) => `${languageState?.bloqueos}: ${dataTip} `} /></>
                                    ) : null}

                                    {i.condiciones != null ? (
                                        <>
                                            <Info title=" " data-tip={i.condiciones.map((j, index) => j.descripcion)} data-for='getContent2' size="16" className="cond"> </Info>
                                            <ReactTooltip id='getContent2' className='custom-color-no-arrow' getContent={(dataTip) => `${languageState?.condiciones}: ${dataTip}`} /></>
                                    ) : null}

                                </TableCell>
                                <TableCell dataHeader="Motivo">

                                    <Info title=" " data-tip={i.motivo} data-for='getContent3' size="16" className="prod"> </Info>
                                    <ReactTooltip id='getContent3' className='custom-color-no-arrow' getContent={(dataTip) => ` ${languageState?.motivo}: ${dataTip} `} />

                                </TableCell>

                                {i.descStatusCompromiso == 'Por Aceptar Fiado' ? (
                                        <TableCell dataHeader="Fecha" className="extras"> --/--/----  </TableCell>
                                    ) :
                                    <TableCell dataHeader="Fecha" className="extras"> {i.fechaComprometida}  </TableCell>}

                                <TableCell dataHeader="Renovacion" className="extras"> {i.numRenovaciones}  </TableCell>
                                <TableCell dataHeader="Estatus" className="extras">
                                    <div className="row">
                                        {i.descStatusCompromiso == 'Por Autorizar' ? (
                                            <div className={"col-8"}>
                                                {i.idFuncionario == PERFIL.clave || i.idFuncionario == "0" && (validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA)
                                                    || validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO)) ? (
                                                        <Button
                                                            href={"/compromisos/autorizacion/" + i.idCompromiso + "/fiado/" + i.idFiado}
                                                            className="btn btn-autorizar rounded-pill" size="small" >
                                                            {i.descStatusCompromiso!}
                                                        </Button>
                                                    ) :

                                                    <Button
                                                        data-tip={""} data-for='getContentBB'
                                                        className="btn btn-autorizar rounded-pill" size="small">
                                                        {i.descStatusCompromiso!}
                                                    </Button>}
                                                {validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) || validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO) ? (
                                                    <ReactTooltip id='getContentBB' className='custom-color-no-arrow' getContent={(dataTip) => `El compromiso se encuentra asignado a otro Funcionario. ${dataTip}`} />
                                                ) : null}
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Vigente' ? (
                                            <div className={"col-8"}>
                                                <Button
                                                    data-tip={`Autorizado por: ${i.username}`} data-for='getContentVigente'
                                                    className="btn btn-cumplido rounded-pill" size="small">
                                                    {i.descStatusCompromiso!}
                                                </Button>
                                                <ReactTooltip id='getContentVigente' className='custom-color-no-arrow' getContent={(dataTip) => ` ${dataTip}`} />
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Vencido' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-vencido rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Rechazado' ? (
                                            <div className={"col-8"}>
                                                <Button
                                                    data-tip={`Rechazado por: ${i.username}`} data-for='getContentRechazo'
                                                    className="btn btn-rechazado rounded-pill" size="small">
                                                    {i.descStatusCompromiso!}
                                                </Button>
                                                <ReactTooltip id='getContentRechazo' className='custom-color-no-arrow' getContent={(dataTip) => `${dataTip}`} />
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Cumplido' ? (
                                            <div className={"col-8"}>
                                                <Button
                                                    data-tip={`Cumplido por: ${i.username}`} data-for='getContentCumplido'
                                                    className="btn btn-cumplido rounded-pill" size="small">
                                                    {i.descStatusCompromiso!}
                                                </Button>
                                                <ReactTooltip id='getContentCumplido' className='custom-color-no-arrow' getContent={(dataTip) => ` ${dataTip}`} />
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Por Aceptar Fiado' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-paf rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Rechazado Por Fiado' ? (
                                            <div className={"col-8"}>
                                                <button
                                                    type="button" className="btn btn-rpf rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}
                                        {i.descStatusCompromiso == 'Por Autorizar Plazo' ? (
                                            <div className={"col-8"}>
                                                {validRoles(PERFIL.roles, localStorageClass.AGENTE) || validRoles(PERFIL.roles, localStorageClass.PROMOTOR) || validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ? (
                                                    <Popover
                                                        id="demo-id"
                                                        trigger={<Button className="btn btn-autorizar rounded-pill" size="small" > {i.descStatusCompromiso!} </Button>}
                                                    >
                                                        Favor de Contactar a su Responsable de Oficina para autorización
                                                    </Popover>
                                                ) : <Button
                                                    href={"/compromisos/autorizacion/" + i.idCompromiso + "/fiado/" + i.idFiado}
                                                    className="btn btn-autorizar rounded-pill" size="small">
                                                    {i.descStatusCompromiso!}
                                                </Button>}
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Revocado' ? (
                                            <div className={"col-8"}>
                                                <Button
                                                    data-tip={`Revocado por: ${i.username}`} data-for='getContentRevocado'
                                                    className="btn btn-revocado rounded-pill" size="small">
                                                    {i.descStatusCompromiso!}
                                                </Button>
                                                <ReactTooltip id='getContentRevocado' className='custom-color-no-arrow' getContent={(dataTip) => ` ${dataTip}`} />
                                            </div>
                                        ) : null}
                                    </div>
                                </TableCell>
                                <TableCell dataHeader="J" type="rowHead">
                                    <div className={"col-6"}>
                                        {validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                                        validRoles(PERFIL.roles, localStorageClass.PROMOTOR) ? (
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" className="dropdown-more-options" id="dropdown-basic-2">
                                                        <IconSeeMore></IconSeeMore>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu align={'left'}>
                                                        {i.descStatusCompromiso == 'Vencido'  || i.descStatusCompromiso == 'Cumplido' ? (
                                                            <Dropdown.Item
                                                                href={"/compromisos/renovacion/" + i.idCompromiso + '/fiado/' + i.idFiado}>
                                                                {languageState?.solicitarRenovacion}
                                                            </Dropdown.Item>
                                                        ) : null}

                                                        <Dropdown.Item
                                                            href={"/compromisos/detalle/" + i.idCompromiso + "/fiado/" + i.idFiado}> {languageState?.verDetalles}
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) :
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" className="dropdown-more-options" id="dropdown-basic-2">
                                                    <IconSeeMore></IconSeeMore>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu align={'left'}>

                                                    {i.descStatusCompromiso == 'Vencido' ? (
                                                        <Dropdown.Item
                                                            href={"/compromisos/renovacion/" + i.idCompromiso + '/fiado/' + i.idFiado}> {languageState?.solicitarRenovacion}
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {i.descStatusCompromiso == 'Cumplido' || i.descStatusCompromiso == 'Vigente' ? (
                                                        <Dropdown.Item //onClick={}
                                                            href={"/compromisos/revertir/" + i.idCompromiso + '/fiado/' + i.idFiado}> {languageState?.revertirCumplido}
                                                        </Dropdown.Item>
                                                    ) : null}

                                                    {i.descStatusCompromiso == 'Revocado' || i.descStatusCompromiso == 'Rechazado' ? (
                                                        null
                                                    ) : <Dropdown.Item
                                                        href={"/compromisos/revocacion/" + i.idCompromiso + '/fiado/' + i.idFiado}>{languageState?.solicitarRevocacion}
                                                    </Dropdown.Item>}

                                                    <Dropdown.Item
                                                        href={"/compromisos/detalle/" + i.idCompromiso + "/fiado/" + i.idFiado}> {languageState?.verDetalles}
                                                    </Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }
                                    </div>
                                </TableCell>
                            </TableRow>)}
                        </tbody>
                    </Table>


                    <div className="row pagination-sdgf">
                        <div className="col-md-5 show-registers">
                            {languageState?.showing}&nbsp;
                            {(paginacion.currentPage * paginacion.size)- (paginacion.size-1)}  {languageState?.to}&nbsp;
                            {(paginacion.currentPage * paginacion.size)}  {languageState?.of}&nbsp;
                            {paginacion.totalItems}  {languageState?.entries}.
                        </div>
                        <div className="col-md-7 ">
                            <Pagination className="float-md-right" id="pagination-table-warranty">
                                <Pagination.Prev
                                    onClick={()=>{
                                        clickGoToPage((listPaginacion[0])-1)
                                    }}
                                />
                                {
                                    listPaginacion.map((i, index) =>
                                        ( <Pagination.Item
                                                key={i}
                                                active={i === paginacion.currentPage}
                                                onClick={()=>{
                                                    clickGoToPage(i)
                                                }}>
                                                {i}
                                            </Pagination.Item>

                                        ))
                                }
                                <Pagination.Next
                                    onClick={()=>{
                                        clickGoToPage(listPaginacion[listPaginacion.length-1]+1)
                                    }}
                                />

                            </Pagination>

                        </div>

                    </div>


                </>

            }

        </>
    );
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(TableEngagements);


const localStorageClass = new LocalStorageClass();

export enum typeProfileTableEngagement {

    AGENTEC = 'AGENTE',
    PROMOTOR = 'PROMOTOR',
    EJECUTIVO = 'EJECUTIVO_CUENTA',
    FUNCIONARIO = 'FUNCIONARIO',
    BURO = 'BURO'
}

export enum statusCompromiso {

    PORAUTORIZAR = 1,
    VIGENTE = 2,
    VENCIDO = 3,
    RECHAZADO = 4,
    REVOCADO = 5,
    CUMPLIDO = 6,
    PORACEPTARF = 7,
    RECHADOF = 8,
    AUTORIZAPLAZO = 9
}

export enum tiposCompromiso {

    PORAPOYO = 1,
    BLOQUEO = 2,

}

