import { combineReducers } from "redux";
import languageReducer from "./LanguageReducer";
import specialActionReducer from "./SpecialActionReducer";
import permissionReducer from "./PermissionReducer";
import ExpedienteIdentificationReducer from "./ExpedienteIdentificationReducer";
import fiadoActionReducer from "./FiadoActionReducer";

const RootReducer = combineReducers({
  language: languageReducer,
  specialActions: specialActionReducer,
  permissions: permissionReducer,
  expediente: ExpedienteIdentificationReducer,
  fiadoAction: fiadoActionReducer
});

export default RootReducer;
