import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AlphanumericInput } from "@lmig/lmds-react";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import './Engagements.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import "react-circular-progressbar/dist/styles.css";
import TableEngagementsAuthorize from "../../../components/compromisos/table-enegagements-authorize/TableEngagementsAuthorize";
import { validRoles } from "../../../class/CommonClass";
import { UserModel } from "../../../models/public/UserModel";
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";
import TableCheckEngagementsByFiado from "../../../components/compromisos/table-check-enganments-by-fiado/TableCheckEngamentsByFiado";
import InfoEngagement from "../../../components/compromisos/info-engagement/InfoEngagement";


interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

class CheckEngagementsByClient extends Component<IListProps & RouteComponentProps<Params, SC, LocationState>, IListState>{

    public localStorageClass = new LocalStorageClass();
    public fiado=  parseInt(this.props.match.params.idFiado)

    constructor(props: any) {

        super(props);
        document.title = "Cumplimientos Por Cliente"


        this.state = {
            PERFIL: this.localStorageClass.getProfile(),

        }
    }

    render(): JSX.Element {
        return <>
         <NavBar />
            <div className="container-fluid ">

               

                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
                validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) ||
                validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ||
                validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)
                    ? (
                    <div className="waranty-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8 seccion-info ">
                                        <InfoEngagement id={this.fiado} />
                                    </div>
                                </div>
                            </div>
                           <div className="col-12">

                           </div>
                            <div className="col-12">
                                <TableCheckEngagementsByFiado language={this.props.language} idFiado={this.fiado} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    }
}

interface IListProps {

    language: DefaultStateI
}

interface IListState {

    PERFIL: UserModel;

}

interface Params {
    idCompromiso: string;
    idFiado: string;
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(CheckEngagementsByClient);
