import React, {Component} from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import './Statistics.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {
    IconAddItem, IconSeeMore, Select, SelectOption,
    Table, TableCell, TableRow
} from "@lmig/lmds-react";
import {Dropdown, Pagination} from "react-bootstrap";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {
    formatCurrency,
    formatValue,
    initPaginacion,
    InputChange,
    loadPaginationList,
    validRoles
} from "../../../class/CommonClass";
import {initTipoPerfil, TypeProfileModel} from "../../../components/garantias/select-type-user/SelectTypeUser";
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import {ListGarantiaModel} from "../../../models/garantias/ListGarantiaModel";
import {ListClienteModel} from "../../../models/garantias/ListClienteModel";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import {TypeProfileEnum} from "../../../models/garantias/TypeProfileEnum";
import {StatusRowWarranty} from "../../../components/garantias/status-row-warranty/StatusRowWarranty";
import {RootStore} from "../../../Store";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {connect} from "react-redux";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {UserModel} from "../../../models/public/UserModel";
import {AcordionTruncate} from "../../../components/garantias/acordion-truncate/AcordionTruncate";

interface Params {
    idStatistics: string;
    nameStatistics: string;
    typeProfile: string
    idProfile: string;

}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}
interface StatisticsWarrantiesProps {
    language: DefaultStateI
}
class Statistics extends Component<StatisticsWarrantiesProps & RouteComponentProps<Params, SC, LocationState>, StatisticsWarrantiesState>{

    public localStorageClass = new LocalStorageClass();
    public idStatistics: number = 0;

    constructor(props: any) {
        super(props);
        document.title = this.props.match.params.nameStatistics
        this.idStatistics = parseInt(this.props.match.params.idStatistics);
        this.state = {
            PERFIL:this.localStorageClass.getProfile(),
            typeProfile: returnTypeProfile(parseInt(this.props.match.params.idProfile),this.props.match.params.typeProfile),
            loadingStatistics: true,
            errorStatistics: false,
            listGarantias: [],
            visibleClientes: false,
            listClientes: [],
            visibleGarantias: false,
            paginacion: initPaginacion,
            listPaginacion: []
        }
        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
    }

    render() {
        return <>
            <NavBar/>
            <div className="container-fluid " id="statistics-warranties">
    
                <div className="waranty-container">

                    <BreadCumb title={this.props.match.params.nameStatistics}
                               listBreadcumbs={[
                                   {title:this.props.language.language?.statisticsWarranty.home!, url:'/garantias/'},
                                   {title:this.props.match.params.nameStatistics, url: this.props.location.pathname}]}/>


                    <div className={"seccion-botons"}>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-3">
                                <Select
                                    isRequired
                                    name="nEntries"
                                    labelA11y=""
                                    labelVisual={this.props.language.language?.tableWarranty.show + ' '+ String(this.state.paginacion.size) + ' ' + this.props.language.language?.tableWarranty.registers}
                                    value={String(this.state.paginacion.size)}
                                    onChange={this.handleInputChangeSelect}
                                    placeholderOption="">
                                    <SelectOption value="10">
                                        10
                                    </SelectOption>
                                    <SelectOption value="25">
                                        25
                                    </SelectOption>
                                    <SelectOption value="50">
                                        50
                                    </SelectOption>
                                    <SelectOption value="100">
                                        100
                                    </SelectOption>
                                </Select>

                            </div>
                            <div className="col-12 col-sm-12 col-md-5 "/>
                            <div className="col-12 col-sm-12 col-md-4">
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        {this.state.loadingStatistics? (
                                <div className="col-12">
                                    <SpinnerLoading text={this.props.language.language?.statisticsWarranty.loadingStatistics!}/>
                                </div>
                            ):
                            this.state.errorStatistics? (
                                    <div className="col-12">
                                        <NegativeAlert
                                            textAlert={this.props.language.language?.statisticsWarranty.errorStatistics!}/>
                                    </div>):
                            <div className="col-12">
                                <div className="row">
                                    {this.state.visibleGarantias?(
                                        <div id="table-warranties" className="col-12">
                                            {/*data table*/}
                                            <Table  tableName={this.props.language.language?.principalDetail.tableLoans}>
                                                <thead>
                                                <TableRow className="text-center">
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.invoice} type="colHead">
                                                        {this.props.language.language?.tableWarranty.invoice}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.warranty} type="colHead">
                                                        {this.props.language.language?.tableWarranty.warranty}
                                                    </TableCell>
                                                    <TableCell dataHeader= {this.props.language.language?.tableWarranty.typeWarranty} type="colHead">
                                                        {this.props.language.language?.tableWarranty.typeWarranty}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.valueWarranty} type="colHead">
                                                        {this.props.language.language?.tableWarranty.valueWarranty}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.amountWeighted}  type="colHead">
                                                        {this.props.language.language?.tableWarranty.amountWeighted}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.dateCapture} type="colHead">
                                                        {this.props.language.language?.tableWarranty.dateCapture}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.vigency} type="colHead">
                                                        {this.props.language.language?.tableWarranty.vigency}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.fiado} type="colHead">
                                                        {this.props.language.language?.tableWarranty.fiado}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.relevantDescription} type="colHead">
                                                        {this.props.language.language?.tableWarranty.relevantDescription}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.status} type="colHead">
                                                        {this.props.language.language?.tableWarranty.status}
                                                    </TableCell>
                                                </TableRow>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.listGarantias.map((i, index) =>
                                                        (     <TableRow key={i.idGarantia}>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.invoice} type="rowHead">
                                                                    {i.folio}{(i.version> 0)? '-' + i.version: null}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.warranty} className="desc-gar">
                                                                    {i.descripcionClave}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.typeWarranty}>
                                                                    {i.descripcionTipoUso}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.valueWarranty}>
                                                                    {formatCurrency(i.monto)}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.amountWeighted} className="text-right">
                                                                    {formatCurrency(i.montoPonderado)}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.dateCapture}
                                                                           className="capture-gar">
                                                                    {i.fechaCaptura}

                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.vigency}
                                                                           className="vigency-gar">
                                                                    {i.fechaInicial}<br/>
                                                                    {i.fechaFinal}
                                                                </TableCell>

                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.fiado} className="fiado">
                                                                    {i.razonSocial!}
                                                                </TableCell>


                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.relevantDescription}
                                                                    className="desc-rel"
                                                                >
                                                                    {(i.descRelevante)? <AcordionTruncate length={50} text={i.descRelevante!}/> : null}

                                                                </TableCell>

                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.status} className="estatus">

                                                                    <StatusRowWarranty
                                                                        idWarranty={i.idGarantia}
                                                                        folio={i.folio}
                                                                        version={i.version}
                                                                        idPrincipal={i.idCliente}
                                                                        estadoVigencia={i.estadoVigencia}
                                                                        descEstadoVigencia={i.descEstadoVigencia}
                                                                        returnDelete={del => {
                                                                            if (del){
                                                                                this.clickGoToPage(this.state.paginacion.currentPage);
                                                                            }
                                                                        }}

                                                                    />

                                                                </TableCell>

                                                            </TableRow>
                                                        ))
                                                }

                                                </tbody>
                                            </Table>
                                        </div>
                                    ):null}

                                    {this.state.visibleClientes?(
                                        <div id="table-clients"  className="col-12">
                                            <Table tableName="">
                                                <thead>
                                                <TableRow>

                                                    <TableCell dataHeader={this.props.language.language?.statisticsWarranty.rfc} type="colHead">
                                                        {this.props.language.language?.statisticsWarranty.rfc}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.statisticsWarranty.principal} type="colHead">
                                                        {this.props.language.language?.statisticsWarranty.principal}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.tableWarranty.dateStart} type="colHead">
                                                        {this.props.language.language?.tableWarranty.dateStart}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.statisticsWarranty.bondsIssued} type="colHead">
                                                        {this.props.language.language?.statisticsWarranty.bondsIssued}
                                                    </TableCell>
                                                    <TableCell dataHeader={this.props.language.language?.statisticsWarranty.options} type="colHead">
                                                        {this.props.language.language?.statisticsWarranty.options}
                                                    </TableCell>

                                                </TableRow>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.listClientes.map((i, index) =>
                                                        (
                                                            <TableRow>
                                                                <TableCell dataHeader={this.props.language.language?.statisticsWarranty.rfc} type="rowHead">
                                                                    {i.rfc}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.statisticsWarranty.principal}  >
                                                                    {i.razonSocial}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.tableWarranty.dateStart}>
                                                                    {i.fechaRegistro}
                                                                </TableCell>
                                                                <TableCell dataHeader={this.props.language.language?.statisticsWarranty.bondsIssued}>
                                                                    {i.fianzasEmitidas}
                                                                </TableCell>

                                                                <TableCell dataHeader= {this.props.language.language?.statisticsWarranty.options}>
                                                                    <div className="row">

                                                                        <div className={"col-8"}>
                                                                            {(
                                                                                validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)
                                                                            )?(
                                                                                    <></>
                                                                                ):
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="light" className="dropdown-more-options" id="dropdown-basic-2">
                                                                                        <IconSeeMore></IconSeeMore>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item  onClick={() =>{
                                                                                            this.props.history.push('/garantias/crear-garantia/fiado/'+ i.idCliente);
                                                                                        }}>
                                                                                            {this.props.language.language?.statisticsWarranty.createNewLoan}
                                                                                        </Dropdown.Item>

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </TableCell>

                                                            </TableRow>

                                                        ))
                                                }

                                                </tbody>
                                            </Table>
                                        </div>
                                    ):null}

                                </div>
                                {/*paginacionPendients*/}
                                <div className="row pagination-sdgf" >
                                    <div className="col-md-5 show-registers">
                                        {this.props.language.language?.tableWarranty.showing}&nbsp;
                                        {(this.state.paginacion.currentPage * this.state.paginacion.size)- (this.state.paginacion.size-1)}  {this.props.language.language?.tableWarranty.to}&nbsp;
                                        {(this.state.paginacion.currentPage * this.state.paginacion.size)} {this.props.language.language?.tableWarranty.of}&nbsp;
                                        {this.state.paginacion.totalItems} {this.props.language.language?.tableWarranty.entries}.
                                    </div>
                                    <div className="col-md-7 ">
                                        <Pagination className="float-md-right" id="pagination-table-warranty">
                                            <Pagination.Prev
                                                onClick={()=>{
                                                    this.clickGoToPage((this.state.listPaginacion[0])-1)

                                                }}
                                            />
                                            {
                                                this.state.listPaginacion.map((i, index) =>
                                                    ( <Pagination.Item
                                                            key={i}
                                                            active={i === this.state.paginacion.currentPage}
                                                            onClick={()=>{
                                                                this.clickGoToPage(i)
                                                            }}>
                                                            {i}
                                                        </Pagination.Item>

                                                    ))
                                            }
                                            <Pagination.Next
                                                onClick={()=>{
                                                    this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length-1]+1)
                                                }}
                                            />

                                        </Pagination>

                                    </div>

                                </div>

                            </div>
                        }

                    </div>



                </div>
            </div>
        </>
    }

    componentDidMount(){
        this.getStatistics(this.idStatistics, this.state.paginacion);
    }

    private async getStatistics(idStatics: number, paginacion:  PaginacionModel) {
        this.setState({loadingStatistics: true, visibleGarantias: false, visibleClientes: false})
        try {
            const res = await garantiasService.getEstadisticaDetalle(idStatics, this.state.typeProfile,paginacion);
            console.log(res.data)

            res.data.paginacion.size = paginacion.size;

            if (res.data.detalleGarantia!= null){
                this.setState({
                    visibleGarantias: true,
                    listGarantias: res.data.detalleGarantia,
                    paginacion: res.data.paginacion
                   })
            }else if (res.data.detalleCliente!= null){
                this.setState({
                    visibleClientes: true,
                    listClientes: res.data.detalleCliente,
                    paginacion: res.data.paginacion
                   })
            }

            this.setState({loadingStatistics:false, listPaginacion: loadPaginationList(res.data.paginacion)});

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

                this.setState({loadingStatistics: false, visibleGarantias: false, visibleClientes: false})

            }
        }
    }
    // tabla de garantias

    private clickGoToPage (page: number) {
        const totalPages: number = this.state.paginacion.totalPages;
        if (page>=1 &&  page <= totalPages){
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({paginacion: auxPaginacion})
            this.getStatistics(this.idStatistics, auxPaginacion);
        }

    }

    public handleInputChangeSelect (e: InputChange)  {
        let auxPaginacion = this.state.paginacion;;
        auxPaginacion.size = formatValue(e.target.value)
        auxPaginacion.currentPage = 1;
        this.getStatistics(this.idStatistics, auxPaginacion);
        this.setState({paginacion: auxPaginacion})

    }



}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});

export default connect(mapStateToProps) (Statistics);

interface StatisticsWarrantiesState{
    PERFIL: UserModel;
    typeProfile: TypeProfileModel;
    loadingStatistics:  boolean;
    errorStatistics: boolean;
    listGarantias: ListGarantiaModel [];
    visibleGarantias: boolean;
    listClientes: ListClienteModel [];
    visibleClientes: boolean;
    paginacion: PaginacionModel;
    listPaginacion: number[];
}




export const returnTypeProfile = (idProfile : number, type : string): TypeProfileModel  => {
    let typeProfile: TypeProfileModel = initTipoPerfil;
    if (type == TypeProfileEnum.idEjGarantias){
        typeProfile ={id: idProfile,tipo: TypeProfileEnum.idEjGarantias }
    }
    if (type == TypeProfileEnum.idEjecutivo){
        typeProfile ={id: idProfile,tipo: TypeProfileEnum.idEjecutivo }
    }
    if (type == TypeProfileEnum.idAgente){
        typeProfile ={id: idProfile,tipo: TypeProfileEnum.idAgente }
    }
    if (type == TypeProfileEnum.idPromotor){
        typeProfile ={id: idProfile,tipo: TypeProfileEnum.idPromotor }
    }

    return typeProfile;
}