export interface LoginLanguage {
    loading: string;
    login: string;
    username: string;
    password: string;
    errorUserPsw: string;
    recoverPsw: string;
}

export const LoginLanguageEnglish: LoginLanguage ={
    loading: 'Loading',
    login: 'Login',
    username: 'Username',
    password: 'Password',
    errorUserPsw: 'Incorrect Username or Password',
    recoverPsw: 'Forget your password?'


}
export const LoginLanguageSpanish: LoginLanguage ={

    loading: 'Cargando',
    login: 'Iniciar Sesión',
    username: 'Usuario',
    password: 'Contraseña',
    errorUserPsw: 'Usuario o Contraseña Incorrectos',
    recoverPsw: '¿Olvidaste tu Contraseña?'


}