import {LanguageModelWarranties} from "../views/garantias/warranties/WarrantiesLanguage";
import {SelectLanguageModel} from "../components/public/select-language/SelectLanguageModel";
import {SelectTypeUserLanguageModel} from "../components/garantias/select-type-user/SelectTypeUserLanguageModel";
import {EngagmentsLanguageModel} from "../views/compromisos/engagements/EngagmentsLanguageModel";
import {AuthorizeEngagementsLanguageModel} from "../components/compromisos/authorization/AuthorizeEngagementsLanguageModel";
import {SelectFiadoLanguageModel} from "../components/public/select-type/SelectFiadoLanguageModel";
import {FindClientLanguageModel} from "../components/compromisos/find-client/FindClientLanguageModel";
import {WarrantyLanguageModel} from "../views/garantias/warranty/WarrantyLanguageModel";
import {ExpedientIdentificationLanguageModel} from "../components/garantias/expedient-identification/ExpedientIdentificationLanguageModel";
import {CreateEngagemetLanguageModel} from "../views/compromisos/create-engagements/CreateEngagementLanguageModel";
import {TableEngagementsLanguage} from "../components/compromisos/table-engagements/TableEngagementsLanguage";
import {CheckEngagmentsLanguageModel} from "../views/compromisos/engagements/CheckEngagemetsLanguageModel";
import {AutoEngagmentsLanguageModel} from "../views/compromisos/engagements/AutoEngagementsLanguageModel";
import {NavBarLanguageModel} from "../components/public/nav-bar/NavBarLanguageModel";
import {WarrantiesReviewLanguageModel} from "../views/garantias/warranties-review/WarrantiesReviewLanguage";
import {PrincipalDetailLanguage} from "../views/garantias/principal-detail/PrincipalDetailLanguage";
import {TableWarrantyLanguageModel} from "../components/garantias/table-loans-principal/TableLoansPrincipalLangugage";
import {InfoPrincipalLanguage} from "../components/garantias/info-principal/InfoPrincipalLanguage";
import {ReviewLoteLanguageModel} from "../views/garantias/review-lote/ReviewLoteLanguage";
import {StatisticsWarrantiesLanguageModel} from "../views/garantias/statistics/StatisticsWarrantiesLanguge";
import {StatusRowWarrantyLanguageModel} from "../components/garantias/status-row-warranty/StatusRowWarrantyLanguage";
import {AuthorizationAmountWarrantyLanguageModel} from "../components/garantias/authorization-amount-warranty/AuthorizationAmountWarrantyLanguage";
import {AuthorizationDocWarrantyLanguageModel} from "../components/garantias/authorization-doc-warranty/AuthorizationDocWarrantyLanguage";
import {AddObligorLanguage} from "../components/garantias/add-obligor/AddObligorLanguage";
import {WarrantyReviewLanguage} from "../views/garantias/warranty-review/WarrantyReviewLanguage";
import {AuthorizationDictumWarrantyLanguage} from "../components/garantias/authorization-dictum-warranty/AuthorizationDictumWarrantyLanguage";
import {BreadCumbPrevLanguage} from "../components/public/breadcumb-prev/BreadCumbPrevLanguage";
import {ObligorsDetailLanguage} from "../views/garantias/obligor-details/ObligorsDetailsLanguage";
import {LockPrincipalLanguage} from "../components/garantias/lock-principal/LockPrincipalLanguage";
import {UnlockPrincipalLanguage} from "../components/garantias/unlock-principal/UnlockPrincipalLanguage";
import {ModalFileMaxUploadLanguage} from "../components/public/modal-file-max-upload/ModalFileMaxUploadLanguage";
import {AuthorizationPercentWarrantyLanguageModel} from "../components/garantias/authorization-percent-warranty/AuthorizationPercentWarrantyLanguage";
import {CreatequeryLanguageModel} from "../views/buro-credito/create-query/CreatequeryLanguage";
import {CreditBureauLanguageModel} from "../views/buro-credito/credit-bureau/CreditbureauLanguage";
import {HomeLanguage} from "../views/public/home/HomeLanguage";
import {NavBarPublicLanguage} from "../components/public/nav-bar-public/NavBarPublicLanguage";
import {filtrarLanguageModel } from "../views/buro-credito/filtrar/filtrar";
import {LoginLanguage} from "../views/public/login/LoginLanguage";
import {LanguageModelPolizasRH} from "../views/rh/polizas-rh/PolizasRHLanguage";
import {ReviewDocsLanguage} from "../views/garantias/review-docs/ReviewDocsLanguage";
import {RecoverPswLanguage} from "../views/public/recover-psw/RecoverPswLanguage";
import {AuthorizationVigencyWarrantyLanguage} from "../components/garantias/authorization-vigency-warranty/AuthorizationVigencyWarrantyLanguage";
import {ConfigAccountLanguageModel} from "../views/buro-credito/Configuracion/ConfigCuentaLanguage";
import {
    FormCreateEngagmentsLanguage
} from "../components/compromisos/form-create-engagments/FormCreateEngagmentsLanguage";
import { LanguageModelDepositosGarantias } from "../views/tesoreria/depositos-garantia/DepositosGarantiasLanguage";
import { LanguageModelReporteGarantias } from "../views/reportes/garantias-vencidas/ReporteGarantiasLanguage"
import { LanguageModelMsgFirma } from "../views/tesoreria/msg-firma/MsgFirmaLanguage";
import {LockCommissionPrincipal} from "../components/garantias/lock-commission-principal/LockCommissionPrincipalLanguage";
import { UnLockCommissionPrincipal} from "../components/garantias/unlock-commission-principal/UnLockCommissionPrincipalLanguage";
import { RequestsLanguageModel } from "../views/buro-credito/requests/Requests";
import {CreateWarrantyLanguageModel} from "../components/garantias/create-warranty/CreateWarrantyLanguage";
import {
    SelectUserEngagementsLanguage
} from "../components/compromisos/select-user-engagements/SelectUserEngagementsLanguage";
import {ReporteExpedienteLanguage} from "../views/garantias/reporte-expediente/ReporteExpedienteLanguage";
import {ComentariosFiadoLanguage} from "../components/garantias/comentarios-fiado/ComentariosFiadoLanguage";
import {ResumeGarantiasLanguageModel} from "../components/garantias/resume-garantias/ResumeGarantiasLanguage";
import {StatusRowPrincipalLanguage} from "../components/garantias/status-row-principal/StatusRowPrincipalLanguage";
import {ModalDeleteOsLanguage} from "../components/garantias/modal-delete-os/ModalDeleteOsLanguage";
import {InfoPrincipalLockLanguage} from "../components/garantias/info-principal-lock/InfoPrincipalLockLanguage";
import { AuditoriasLanguageModel } from "../views/buro-credito/auditoria/AuditoriasLanguage";
import { ViewAuditoriasLanguageModel } from "../views/buro-credito/viewAditoria/ViewAuditoriasLanguage";
import { CrearAuditoriaLanguageModel } from "../views/buro-credito/crear-auditoria/CrearAuditoriaLanguage";
import {LineaDeAfianzamientoLanguage} from "../components/garantias/linea-de-afianzamiento/LineaDeAfianzamientoLanguage";
import {
    SelectProductosLineaLanguage
} from "../components/garantias/select-productos-linea/SelectProductosLineaLanguage";
import {WarrantyHistoryLanguageModel} from "../views/garantias/warranties-history/WarrantyHistoryLanguage";
import { WarrantyComparisionLanguage } from "../views/garantias/warranty-comparison/WarrantyComparisonLanguage";
import { HistoryReviewLanguageModel } from "../views/garantias/history-review/HistoryReviewLanguage";




export const SELECT_LANGUAGE_ENGLISH = "SELECT_LANGUAGE_ENGLISH";
export const SELECT_LANGUAGE_SPANISH = "SELECT_LANGUAGE_SPANISH";


export type LanguageModel = {
    saludo: string,
    // vistas
    warranties: LanguageModelWarranties,   
    findClient: FindClientLanguageModel,
    createEngagement: CreateEngagemetLanguageModel,
    engagments: EngagmentsLanguageModel,
    warranty: WarrantyLanguageModel
    warrantiesReview: WarrantiesReviewLanguageModel,
    principalDetail: PrincipalDetailLanguage,
    reviewLote: ReviewLoteLanguageModel,
    statisticsWarranty: StatisticsWarrantiesLanguageModel,
    warrantyAuthorization: WarrantyReviewLanguage,
    obligorsDetail: ObligorsDetailLanguage,
    creditbureau:CreditBureauLanguageModel,
    createquery:CreatequeryLanguageModel
    homeLanguage: HomeLanguage,
    createfiltrar:filtrarLanguageModel,
    login: LoginLanguage,
    polizasRH : LanguageModelPolizasRH,
    recoverPsw: RecoverPswLanguage,
    configAccount:ConfigAccountLanguageModel,
    depositosGarantias:LanguageModelDepositosGarantias,
    reporteGarantias:LanguageModelReporteGarantias,
    msgFirma : LanguageModelMsgFirma,
    request:RequestsLanguageModel,
    auditorias:AuditoriasLanguageModel,
    ViewAudi:ViewAuditoriasLanguageModel,
    CreateAudi:CrearAuditoriaLanguageModel,
    WarrantyHistoryLanguage: WarrantyHistoryLanguageModel,
    WarrantyComparisonLanguage: WarrantyComparisionLanguage;
    HistoryReviewLanguage: HistoryReviewLanguageModel;
    // componentes
    selectLanguage: SelectLanguageModel
    selectAgent: SelectTypeUserLanguageModel,
    authorizeEngagements: AuthorizeEngagementsLanguageModel,
    selectFiado: SelectFiadoLanguageModel,
    expedientIdentificaction: ExpedientIdentificationLanguageModel,
    tableEngagements: TableEngagementsLanguage,
    checkEngagements: CheckEngagmentsLanguageModel,
    autoEngagements: AutoEngagmentsLanguageModel,
    navBar: NavBarLanguageModel,
    tableWarranty: TableWarrantyLanguageModel,
    infoPrincipal: InfoPrincipalLanguage,
    statusRowWarranty: StatusRowWarrantyLanguageModel,
    authorizationAmountWarranty: AuthorizationAmountWarrantyLanguageModel,
    authorizationPercentWarranty: AuthorizationPercentWarrantyLanguageModel,
    authorizationDocWarranty: AuthorizationDocWarrantyLanguageModel,
    addObligor: AddObligorLanguage,
    authorizationDictumWarranty: AuthorizationDictumWarrantyLanguage,
    BreadCumbPrev: BreadCumbPrevLanguage,
    lockPrincipal: LockPrincipalLanguage,
    unlockPrincipal: UnlockPrincipalLanguage,
    modalFileMaxUpload: ModalFileMaxUploadLanguage,
    navBarPublic: NavBarPublicLanguage,
    reviewDocs: ReviewDocsLanguage,
    authorizationDateWarrantyLanguage: AuthorizationVigencyWarrantyLanguage,
    formCreateEngagmentsLanguage: FormCreateEngagmentsLanguage,
    lockCommissionPrincipal: LockCommissionPrincipal,
    unLockCommissionPrincipal: UnLockCommissionPrincipal,
    createWarranty:CreateWarrantyLanguageModel,
    selectUserEngagments: SelectUserEngagementsLanguage,
    reporteExpediente: ReporteExpedienteLanguage
    comentariosFiado: ComentariosFiadoLanguage,
    resumeGarantias: ResumeGarantiasLanguageModel,
    statusRowPrincipal: StatusRowPrincipalLanguage,
    modalDeleteOs: ModalDeleteOsLanguage
    infoPrincipalLock: InfoPrincipalLockLanguage,
    lineaCreditoLanguage:  LineaDeAfianzamientoLanguage,
    selectProductosLinea: SelectProductosLineaLanguage
}


export interface LanguageEnglish {
    type: typeof SELECT_LANGUAGE_ENGLISH,
    payload: LanguageModel
}

export interface LanguageSpanish {
    type: typeof SELECT_LANGUAGE_SPANISH,
    payload: LanguageModel
}

export type SelectLanguageDispatchTypes = LanguageEnglish | LanguageSpanish