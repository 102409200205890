export interface BreadCumbPrevLanguage {
    prev:string

}

export const BreadCumbPrevLanguageEnglish: BreadCumbPrevLanguage ={
    prev: 'Return to Previous Page'

}
export const BreadCumbPrevLanguageSpanish: BreadCumbPrevLanguage ={
    prev: 'Regresar a la Página Anterior'
}
