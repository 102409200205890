import { ChangeEvent, Component } from 'react';
import './Exitosa.scss';
import { RouteComponentProps } from "react-router-dom";
import { Button, Checkbox, CheckboxGroup, Form, GridCol, GridRow } from '@lmig/lmds-react';
import NavBarInvitado from '../../public/nav-bar/NavBarInvitado';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import SuccessAlert from '@lmig/lmds-react/icons/SuccessAlert';

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface Params {


}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

class Exitosa extends Component<RouteComponentProps<Params, SC, LocationState>> {



    render() {

        return <>

            <div className="container-text" id="noti-engagements">
                <NavBarInvitado />



                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-4 alert">
                            <SuccessAlert
                               
                                size="64"
                                color="positive">

                            </SuccessAlert>
                        </div>

                        <h1 className="texto"> Solicitud Exitosa</h1>

                        <br />
                        <br />
                        <Button
                            //onClick={this.handleClose}
                            variant="primary"
                            href={"https://www.libertyfianzas.com/"}
                            className="btn-ok">
                            Aceptar
                        </Button>
                    </div>
                </div>

            </div>


        </>


    }




}


export default Exitosa;


