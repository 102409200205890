import axios from 'axios';
import environment from "../../environments/environment";
import {MonedaModel} from "../../models/public/MonedaModel";
import {LocalStorageClass} from "../../class/LocalStorageClass";
import {ClienteModel} from "../../models/public/ClienteModel";
import {EjecutivoListModel} from "../../models/public/EjecutivoListModel";
import {EjecutivoPerfilGarModel} from "../../models/public/EjecutivoPerfilGarModel";
import {AgenteListModel} from "../../models/public/AgenteListModel";
import {EjecutivoPerfilCuentaModel} from "../../models/public/EjecutivoPerfilCuentaModel";
import {PerfilAgenteModel} from "../../models/public/PerfilAgenteModel";
import {PaginacionModel} from "../../models/public/PaginacionModel";
import {TypeProfileModel} from "../../components/garantias/select-type-user/SelectTypeUser";
import {TypeProfileEnum} from "../../models/garantias/TypeProfileEnum";
import {FiadoModel} from "../../models/garantias/FiadoModel";
import {FilterSearchModel} from "../../views/garantias/warranties/Warranties";
import {LanguageModel} from "../../models/public/LanguageModel";
import {EntrevistaIframeModel} from "../../models/public/EntrevistaIframeModel";
import {ObligatorModel} from "../../models/public/ObligatorModel";
import {InfoObligadoSolidario} from "../../models/garantias/InfoObligadoSolidario";
import {InformacionUsuario} from '../../models/public/InformacionUsuario';
import {CodigoRespuesta} from '../../models/compromisos/CodigoRespuesta';
import {SpecialAction} from "../../models/public/SpecialAction";
import {OficinasModel} from "../../models/public/OficinasModel";
import Permission from '../../models/users-management/Permission';
import {PerfilAuditor} from "../../models/public/PerfilAuditor";
import {PerfilPromotor} from "../../models/garantias/PerfilPromotor";
import {ComentarioModel} from "../../models/garantias/ComentarioModel";
import {DocuwareModel} from "../../models/public/DocuwareModel";

const  API = environment.APIGRAL
const localStorageClass = new LocalStorageClass();

const  headerJson = {
    Accept: 'application/json',
    'X-API-KEY': environment.APIKEYGRAL,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),
    env: environment.env,
}

const updateHeader = () => {
    headerJson.Authorization = localStorageClass.getProfileToken();
    headerJson.lang = localStorageClass.getLanguageCode();
    headerJson.idLenguaje = localStorageClass.getLanguageId();

}

export const getClientesBusqueda= async ( typeProfile: TypeProfileModel,
                                          idOficina: number | null,
                                          paginacion: PaginacionModel,
                                          busqueda?: string,
                                          filtersSearch?: FilterSearchModel) => {
    let queryParams= '';
    if (typeProfile.tipo == TypeProfileEnum.idEjGarantias){
        queryParams += '&idEjGarantias='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjecutivo){
        queryParams += '&idEjecutivo='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAgente){
        queryParams += '&idAgente='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idPromotor){
        queryParams += '&idPromotor='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAuditor){
        queryParams += '&idAuditor='+ typeProfile.id
    }
    if (idOficina){
        queryParams += '&idOficina='+ idOficina
    }
    if (busqueda){
        queryParams += '&busqueda='+encodeURIComponent(busqueda)
    }
    if (filtersSearch){
        if (filtersSearch.conSolvencia){
            queryParams += '&conSolvencia='+ true
        }
        if (filtersSearch.sinSolvencia){
            queryParams += '&sinSolvencia='+ true
        }
        if (filtersSearch.porVencer){
            queryParams += '&porVencer='+ true
        }
    }
    if (paginacion.currentPage){
        queryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        queryParams += '&size='+ paginacion.size
    }

    updateHeader();
    return await axios.get<{
        paginacion: PaginacionModel,
        detalle: FiadoModel[]}>(API+'/cliente/busqueda?'+ queryParams  , { headers: headerJson });
};

export const getDetalleCliente = async (idClient: number) => {
    updateHeader();
    return await axios.get<{ cliente: ClienteModel, obligadosSolidarios: InfoObligadoSolidario[]}>(API+'/cliente/detalle/'+  idClient , { headers: headerJson });
};
export const getCatalogoMoneda = async () => {
    updateHeader();
    return await axios.get<MonedaModel[]>(API+'/moneda' , { headers: headerJson });
};
export const getEjecutivosCuenta = async (idEjecutivoGarantia: number| null , busqueda: string | null, idOficina: number| null) => {

    let queryParams = '';
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }
    if (idEjecutivoGarantia){
        queryParams += '&idEjecutivoGarantia='+ idEjecutivoGarantia
    }
    if (idOficina){
        queryParams += '&idOficina='+ idOficina
    }
    updateHeader();
    return await axios.get<EjecutivoListModel[]>(API+'/ejecutivo?' + queryParams, { headers: headerJson });
};

export const getAgentes = async (idEjecutivoGarantias: number | null, idEjecutivo: number| null, busqueda: string | null) => {

    let queryParams= '';
    if (idEjecutivoGarantias){
        queryParams += '&idEjecutivoGarantia='+ idEjecutivoGarantias
    }
    if (idEjecutivo){
        queryParams += '&idEjecutivo='+ idEjecutivo
    }
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }
    updateHeader();
    return await axios.get<AgenteListModel[]>(API+'/agente?'+queryParams, { headers: headerJson });
};

//ejecutivoPerfilEjeGar
export const getPerfilEjeGar = async () => {
    updateHeader();
    return await axios.get<EjecutivoPerfilGarModel>(API+'/ejecutivoPerfilEjeGar', { headers: headerJson });
};

export const getPerfilEje = async () => {
    updateHeader();
    return await axios.get<EjecutivoPerfilCuentaModel>(API+'/ejecutivoPerfilEje', { headers: headerJson });
};

export const getPerfilAgente = async () => {
    updateHeader();
    return await axios.get<PerfilAgenteModel>(API+'/agentePerfil', { headers: headerJson });
};
export const getPerfilAuditor = async () => {
    updateHeader();
    return await axios.get<PerfilAuditor>(API+'/auditorPerfil', { headers: headerJson });
};
export const getPerfilPromotor = async () => {
    updateHeader();
    return await axios.get<PerfilPromotor>(API+'/promotorPerfil', { headers: headerJson });
};
export const getLanguage = async () => {
    updateHeader();
    return await axios.get<LanguageModel []>(`${API}/lenguaje` , { headers: headerJson });
}

export const getObtenerEntrevista = async (idFiado: number) => {
    updateHeader();
    return await axios.get<EntrevistaIframeModel>(`${API}/cliente/entrevista?idCliente=${idFiado}` , { headers: headerJson });
}

export const getPosiblesOs = async ( busquedaOS: string, idCliente: number,
                                    paginacion: PaginacionModel) => {

    let paginacionQueryParams= '';
    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    updateHeader();
    return await axios.get<{
        paginacion: PaginacionModel,
        detalleCliente: ObligatorModel[]}>(API  + `/posiblesOs/${idCliente}/${busquedaOS}?` + paginacionQueryParams , { headers: headerJson });
};

///cliente/os/{idCliente}
export const putAgregaOs = async (idCliente: number,os: ObligatorModel[]) => {
    updateHeader();
    return await axios.put<any>(`${API}/cliente/os/${idCliente}` , os, { headers: headerJson });
};

export const getBusquedaOs = async ( idCliente: number, busquedaOS: string) => {
    updateHeader();
    // /cliente/os/{idCliente}/{busqueda}
    return await axios.get<{obligadosSolidarios: InfoObligadoSolidario[]}>(`${API}/cliente/detalle/${idCliente}/${busquedaOS}`  , { headers: headerJson });
};
// cliente/os/{idCliente}/{busqueda}
export const getBusquedaClienteOs = async ( idCliente: number, busquedaOS: string) => {
    updateHeader();
    return await axios.get<{clientes: InfoObligadoSolidario[]}>(`${API}/cliente/os/${idCliente}/${encodeURIComponent(busquedaOS)}`  , { headers: headerJson });
};
// accionEspecial
export const getEspecialAction = async () => {
    updateHeader();
    return await axios.get<SpecialAction[]>(`${API}/accionEspecial`  , { headers: headerJson });
}
;
export const getPermissions = async () => {
    updateHeader();
    return await axios.get<Permission[]>(`${API}/user/permissions`  , { headers: headerJson });
};

export const getInformacionUsuario = async ( idUsuario: string) => {
    updateHeader();
    return await axios.get<InformacionUsuario>(`${API}/informacionUsuario?username=${idUsuario}`  , { headers: headerJson });

    //https://lf-sgf-apigeneral-impl-development.us-east-1.np.paas.lmig.com/informacionUsuario?idUsuario=2179
};

export const getCodigoConfirmacion = async ( username: string, correo: string) => {
    updateHeader();
    return await axios.get<number>(`${API}/codigoConfirmacion?email=${correo}&username=${username}`  , { headers: headerJson });

    //https://lf-sgf-apigeneral-impl-development.us-east-1.np.paas.lmig.com/codigoConfirmacion?email=mdsk1516%40gmail.com&username=ABERZUNZAGF
};
export const postValidaCodigo = async (username : string,  codigo: number,  nuevoValor: string,  tipoOperacion:number) => {
  
    updateHeader();
    return await axios.post<CodigoRespuesta>(`${API}/validaCodigoConfirmacion?codigo=${codigo}&nuevoValor=${nuevoValor}&tipoOperacion=${tipoOperacion}&username=${username}` , { headers: headerJson });

    //https://lf-sgf-apigeneral-impl-development.us-east-1.np.paas.lmig.com/validaCodigoConfirmacion?codigo=38254&nuevoValor=mdsk%40gmail.com&tipoOperacion=2&username=ABERZUNZAGF
};

export const getBusquedaOficinas = async (busqueda: string) => {
    updateHeader();
    return await axios.get<OficinasModel []>(`${API}/oficina?busqueda=${encodeURIComponent(busqueda)}`  , { headers: headerJson });
};


export const getComentario = async (idCliente: number) => {
    //se envia privacidad 1 para que devuelva todos los comentarios
    updateHeader();
    return await axios.get<ComentarioModel[]>(`${API}/comment/${idCliente}?idPrivacidad=1`  , { headers: headerJson });
};

export const postComentario = async (idFiado: number,comentario: string ) => {
    //se envia categoria 3 que es general
    //se envia privacidad 1 para que lo puedan ver los  internos 
    const coment = {
        "comentario": `${comentario}`,
        "idCategoria": `3`,
        "idCliente": `${idFiado}`,
        "idComentarioOrigen": "null",
        "idPrivacidad": `1`
    }
    updateHeader();
    return await axios.post(`${API}/comment`, coment  , { headers: headerJson });
};

export const getDocuware = async (idCliente: number) => {
    //se envia privacidad 1 para que devuelva todos los comentarios
    updateHeader();
    return await axios.get<DocuwareModel>(`${API}/docuware/view?idCliente=${idCliente}`  , { headers: headerJson });
};


