import { Component } from 'react';

import {
    Table, TableCell, TableRow
} from "@lmig/lmds-react";
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { UserModel } from '../../../models/public/UserModel';
import { Historial } from '../../../models/compromisos/Historial';
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";

interface TableEngagementsProps {

    idCompromiso: number;
    language: DefaultStateI

}

interface SteepsModel {
    openModalClose?: boolean;
    listHistorialCompromiso: Historial[];
    PERFIL: UserModel;

}


class TableHistoryEngagements extends Component<TableEngagementsProps, SteepsModel> {

    public idCompromiso= this.props.idCompromiso
    public localStorageClass = new LocalStorageClass();


    constructor(props: any) {
        super(props);

        const PERFIL = this.localStorageClass.getProfile();
     
        this.state = {
            PERFIL: PERFIL,
            openModalClose: false,
            listHistorialCompromiso:[],

        }


    }

    componentDidMount() {

        this.getHistorialCompromiso();
        
    }

    render(): JSX.Element {

        return (
            <>
                <Table id="demo-table" tableName=" " className="col-24">
                    <thead>
                        <TableRow>
                            <TableCell dataHeader="C" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.fechaActualizacion}</TableCell>
                            <TableCell dataHeader="D" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.fechaCumplimiento}</TableCell>
                            <TableCell dataHeader="E" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.usuario} </TableCell>
                            <TableCell dataHeader="F" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.accionesH}</TableCell>
                            <TableCell dataHeader="G" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.estatus}</TableCell>
                          


                        </TableRow>
                    </thead>
                    <tbody>

                    {this.state.listHistorialCompromiso.map((i, index) =>
                        <TableRow>
                        
                            
                            <TableCell dataHeader="C" className="extras">{i.fechaActualizacion}   </TableCell>

                            {i.fechaComprometida == "0001-01-01 BC" ? (
                                <TableCell dataHeader="D" className="extras">
                                            <text >--/--/----</text>
                                </TableCell>
                                 
                            ): <TableCell dataHeader="D" className="extras">{i.fechaComprometida} </TableCell>}
                           
                            <TableCell dataHeader="E" className="extras"> {i.usuario}  </TableCell>
                            <TableCell dataHeader="F" >
                            <div>
                                    {i.idAccion == 1 ? (
                                        <div className="extras">
                                           <text >Registrado</text>

                                        </div>
                                    ) : null}

                                    {i.idAccion == 2 ? (
                                       <div className="extras">
                                              <text >Autorizado </text>
                                        </div>
                                    ) : null}

                                    {i.idAccion == 3 ? (
                                        <div className="extras">
                                             <text >Renovado </text>
                                        </div>
                                    ) : null}

                                    {i.idAccion == 4? (
                                      <div className="extras">
                                             <text >Rechazado </text>
                                        </div>
                                    ) : null}

                                    {i.idAccion == 5? (
                                        <div className="extras">
                                            <text >Revocado </text>
                                        </div>
                                    ) : null}

                                    {i.idAccion == 6 ? (
                                        <div className="extras">
                                           <text >Cumplido </text>
                                        </div>
                                    ) : null}

                                    {i.idAccion == 7 ? (
                                        <div className="extras">
                                            <text >Aceptado Fiado  </text>
                                        </div>
                                    ) : null}

                                    {i.idAccion == 8 ? (
                                        <div className="extras">
                                            <text >Rechazado por Fiado </text>
                                        </div>
                                    ) : null}

                                    {i.idAccion == 10 ? (
                                        <div className="extras">
                                            <text >Revertido </text>
                                        </div>
                                    ) : null}
                                </div>                                
                                
                            </TableCell>
                            <TableCell dataHeader="G" >  
                               
                            <div >
                                    {i.idCompromisoStatus == 1 ? (
                                       <div className="extras">
                                             <text >Por Autorizar </text>

                                        </div>
                                    ) : null}

                                    {i.idCompromisoStatus == 2 ? (
                                        <div className="extras">
                                            <text >Vigente </text>
                                        </div>
                                    ) : null}

                                    {i.idCompromisoStatus == 3 ? (
                                        <div className="extras">
                                             <text >Vencido </text>
                                        </div>
                                    ) : null}

                                    {i.idCompromisoStatus == 4? (
                                       <div className="extras">
                                            <text >Rechazado </text>
                                        </div>
                                    ) : null}

                                    {i.idCompromisoStatus == 6 ? (
                                       <div className="extras">
                                           <text >Cumplido </text>
                                        </div>
                                    ) : null}

                                    {i.idCompromisoStatus == 7 ? (
                                        <div className="extras">
                                             <text >Por Aceptar Fiado </text>
                                        </div>
                                    ) : null}

                                    {i.idCompromisoStatus == 8 ? (
                                       <div className="extras">
                                             <text >Rechazado Por Fiado </text>
                                        </div>
                                    ) : null}
                                      {i.idCompromisoStatus == 9 ? (
                                       <div className="extras">
                                             <text>Revertido</text>
                                        </div>
                                    ) : null}
                                </div>
                                  
                            </TableCell>
                        </TableRow> )} 

                    </tbody>
                </Table>
            </>
        );
    }


    async getHistorialCompromiso(): Promise<void> {   

        const res = await engagementService.getHistorialCompromiso(this.idCompromiso);
        this.setState({ listHistorialCompromiso: res.data });
        console.log("Se obtiene el historial del Compromiso ID: " + this.idCompromiso);
        //console.log(this.state.listHistorialCompromiso)

        let listHistorialCompromisoAux: Historial[] = res.data;
       

        for(const a of listHistorialCompromisoAux ) {

            let fechaAux = a.fechaActualizacion.substring(0,16)
            a.fechaActualizacion= fechaAux
        }

        this.setState({ listHistorialCompromiso: listHistorialCompromisoAux });
      
        
    } 

   
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(TableHistoryEngagements);

