import React, {useEffect, useState,} from 'react';
import './AddObligor.scss';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AlphanumericInput, Button, IconSearch} from "@lmig/lmds-react";
import * as apiGralService from '../../../services/api-gral/ApiGralService';
import {AxiosError} from "axios";
import {ObligatorModel} from "../../../models/public/ObligatorModel";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {initPaginacion, InputChange} from "../../../class/CommonClass";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {ListGroup} from "react-bootstrap";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {SearchAutocomplete} from "../../public/search-autocomplete/SearchAutocomplete";

interface AddObligorProps {
    idClient: number;
    showModal: boolean
    clickShowModal: (show: boolean) => void;
    added?: () => void;
}

export const AddObligor: React.FC<AddObligorProps> = ({idClient, showModal, clickShowModal, added}:AddObligorProps)=> {

    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.addObligor);

    const [loadingObligors, setLoadingObligors] = useState<boolean>(false);

    const [errorObligors, setErrorObligors] = useState<boolean>(false);

    const [listObligors, setListObligors] = useState<ObligatorModel[]>([]);

    const [paginacion, setPaginacion] = useState<PaginacionModel>(initPaginacion);

    const [searchOS, setSearchOS] = useState<string>('');

    const [selectedObligors, setSelectedObligors] = useState<ObligatorModel[]>([]);

    const [savingObligors, setSavingObligors] = useState<boolean>(false);

    const [errorSaveObligors, setErrorSaveObligors] = useState<boolean>(false);

    const [firstSearch, setFirstSearch] = useState<boolean>(false);

    const getObligor = async (searchOS: string, idClient: number, paginacion: PaginacionModel) => {
        setFirstSearch(true);
        setLoadingObligors(true);
        setErrorObligors(false);
        try {
            const res = await apiGralService.getPosiblesOs(searchOS,idClient,paginacion);
            const detalleCliente = res.data.detalleCliente
            formatList(detalleCliente);
            setLoadingObligors(false);
            setErrorObligors(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingObligors(false);
                setErrorObligors(true);
            }
        }
    };

    const handleInputChange = (e: InputChange) => {
        let value = e.target.value;
        console.log(value);
        setSearchOS(value);
    }

    const onClickSelectObligor = (obligator: ObligatorModel) => {

        let listSelectedObligorsAux: ObligatorModel[] = selectedObligors;

        let newListObligors: ObligatorModel[] = [];

        let found = false;
        // revisamos que no este antes agregado a la lista de los seleccionados
        for (let i = 0; i < listSelectedObligorsAux.length; i++){
            //  console.log(listSelectedObligorsAux[i].idCliente + '==' + obligator.idCliente)
            if (listSelectedObligorsAux[i].idCliente == obligator.idCliente){
                //listSelectedObligorsAux.splice(0,1);
                found = true;
                // console.log('found');
                break;

            }
        }

        // guardar los que ya estaban
        for (const i of listSelectedObligorsAux){
            newListObligors.push(i);
        }
        // agregar el nuevo en caso de que haya
        if (!found){

            newListObligors.push(obligator);
            // vacíar la lista
            let auxListObligors = listObligors;
            for (let i = 0; i < auxListObligors.length; i++){
                if (auxListObligors[i].idCliente == obligator.idCliente){
                    auxListObligors.splice(i,1);
                }
            }
            setListObligors(auxListObligors)

        }

        setSelectedObligors(newListObligors)

    }

    const deleteSelectObligor = (pos: number) => {
        //console.log('delete pos: ' + pos);

        let newSelectedObligors = [...selectedObligors]
        newSelectedObligors.splice(pos, 1);
        setSelectedObligors(newSelectedObligors)


    }

    const formatList = (listObligorsF: ObligatorModel[]) => {
        let selectedObligorsF = [...selectedObligors]
        let founds: number [] = []

        for (let i = 0; i < listObligorsF.length; i++){

            for (let j = 0; j < selectedObligorsF.length; j++){

                if (listObligorsF[i].idCliente == selectedObligorsF[j].idCliente){
                    founds.push(i)
                }
            }

        }
        for (const i of founds.reverse()){
            console.log('['+ i+ ']'+listObligorsF[i].idCliente)
            listObligorsF.splice(i,1);
        }
        setListObligors(listObligorsF);
    }

    const saveOS = async (idClient: number,selectedObligors: ObligatorModel[] ) => {
        setSavingObligors(true);
        setErrorSaveObligors(false);
        try {
            const res = await apiGralService.putAgregaOs(idClient,selectedObligors);
            setSavingObligors(false);
            setErrorSaveObligors(false);
            setSelectedObligors([])
            clickShowModal(false);
            added!();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                // quitar el spiner de loading
                setSavingObligors(false);
                setErrorSaveObligors(true);

            }
        }
    }

    useEffect(() => {

    },[]);

    return (
        <>
            <div id="modal-list-obligors">
                <div className=" list-obligors">
                    <div className="row">
                        <div className="col-9">

                            <AlphanumericInput
                                className={"w-100"}
                                labelVisual={languageState?.searchOs}
                                autoComplete="off"
                                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>)=> {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        getObligor(searchOS, idClient, paginacion);
                                    }
                                }
                                }
                                onChange={(e: InputChange)=> {
                                    setSearchOS(e.target.value)
                                }}
                                value={searchOS}
                            />
                        </div>
                        <div className="col-3">
                            <Button
                                dynamicWidth
                                className={"w-100"}
                                variant={"primary"}
                                onClick={()=>{
                                    getObligor(searchOS, idClient, paginacion);
                                }}>
                                <IconSearch size="16" />&nbsp;
                            </Button>
                        </div>

                    </div>




                    <div className="w-100">
                        {selectedObligors.map((i, index) => (
                            <span key={index} className="badge badge-pill badge-light">
                                {i.razonSocial}
                                <div className="float-right">
                                    &nbsp;&nbsp;
                                    <a onClick={()=>{
                                        deleteSelectObligor(index)
                                    }} className="delete-select-reason">
                                        x
                                    </a>

                                </div>
                            </span>
                        ))}
                    </div>
                    {loadingObligors?(
                            <div className="col-12">
                                <SpinnerLoading text={languageState?.loadingOs}/>
                            </div>
                        ):
                        (!errorObligors)? (
                                <>
                                    {/*{JSON.stringify(selectedObligors)}*/}

                                    <ListGroup as="ul" className="list">
                                        {listObligors.map((i, index) => (
                                            <ListGroup.Item
                                                key={index}
                                                action
                                                onClick={()=>{
                                                    onClickSelectObligor(i)
                                                }}>
                                                <div className="row">
                                                    <div className="col-md-4 rfc">
                                                        {i.rfc}
                                                    </div>
                                                    <div className="col-md-8 name">
                                                        {i.razonSocial}
                                                    </div>

                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    {listObligors.length == 0 && firstSearch ? (
                                        <div className="col-12">
                                            <br/><br/>
                                            <h5>{languageState?.noResults}</h5>
                                        </div>
                                    ): null}


                                </>):
                            <div className="col-12">
                                <NegativeAlert
                                    textAlert={languageState?.errorOs!}/>
                            </div>
                    }
                    <div className="row btns-add-obligor">
                        {(errorObligors)?
                            <div className="col-12">
                                {languageState?.errorSaving!}
                            </div>:null
                        }

                        <div className="col-md-6 col-12">
                            <Button
                                dynamicWidth
                                variant="secondary"
                                onClick={()=>{
                                    clickShowModal(false)
                                }}>
                                {languageState?.close!}
                            </Button>
                        </div>
                        <div className="col-md-6 col-12">
                            {savingObligors?
                                <ButtonSpinner label={languageState?.saving!}/>:
                                <Button
                                    dynamicWidth
                                    variant={"primary"}
                                    onClick={()=>{
                                        saveOS(idClient, selectedObligors);
                                    }}>
                                    {languageState?.save!}
                                </Button>
                            }

                        </div>

                    </div>

                </div>


            </div>

        </>
    );
};