import React, { useEffect, useState } from 'react';
import './FormDetailEngagment.scss';
import { RootStore } from "../../../Store";
import { useSelector } from "react-redux";
import InfoEngagement from "../info-engagement/InfoEngagement";
import { PipeLineStepsDetails } from "../../public/pipeline-steps/PipeLineStepsDetails";
import { AlphanumericInput, Badge, Button, Form, List, ListItem } from "@lmig/lmds-react";
import { formatCurrency, validRoles } from "../../../class/CommonClass";
import HistoryEngagementsF from "../history-engagements/HistoryEngagementsF";
import HistoryEngagements from "../history-engagements/HistoryEngagements";
import { MessagesAPIComponent } from "@lmig/lf-sgf-library-messages/src";
import environment from "../../../environments/environment";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { Compromiso } from "../../../models/compromisos/Compromiso";
import { Destinatario } from "../../../models/compromisos/Destinatario";
import * as engagementService from "../../../services/compromisos/EngagementsService";
import { UserModel } from "../../../models/public/UserModel";
import { PrevPage } from "../../public/prev-page/PrevPage";
import { Remitente } from '../../../models/compromisos/Remitente';
import { Correo } from '../../../models/compromisos/Correo';

interface FormDetailEngagementProps {
    idCompromiso: number;
    idFiado: number;
    isModal: boolean;
    clickShowModal?: (show: boolean) => void;
}

export const FormDetailEngagement: React.FC<FormDetailEngagementProps> = ({ idCompromiso, idFiado, isModal, clickShowModal }: FormDetailEngagementProps) => {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const localStorageClass = new LocalStorageClass();

    const initialEngagementState: Compromiso = {
        bloqueos: [],
        conFiado: 0,
        condiciones: [],
        datosFiado: {
            correo: '',
            fechaAceptaCompr: '',
            idCompromiso: 0,
            nombreRep: ''
        },
        dictamen: '',
        fechaComprometida: '',
        fechaCompromisoCumplido: '',
        fechaSolicitud: '',
        idCompromiso: 0,
        idFiado: 0,
        idSolicitante: '',
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoBloqueo: 0,
        tipoCompromiso: 1,
        tipoCumplimiento: 0,
        tipoSolicitante: 0
    }

    const initialMsg: Messages = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [count, setCount] = useState<number>(1);

    const [mensajesAlerta, setMensajesAlerta] = useState<Messages>(initialMsg);

    const [compromiso, setCompromiso] = useState<Compromiso>(initialEngagementState);

    const [compromisoDetails, setCompromisoDetails] = useState<any[]>([{
        fiado: '',
        rfc: '',
        idCompromiso: 0,
        fechaSolicitud: '',
        bloqueos: [{

            descripcion: '',
            idBloqueo: 0
        }],
        condiciones: [{
            idCondicion: 0,
            descripcion: ''


        }],
        fechaComprometida: '',
        idFiado: 0,
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoCompromiso: 0,
    }]);

    const [blockOption, setBlockOption] = useState<any[]>([{
        value: 0,
        label: '',
    }]);

    const [conditionOption, setConditionOption] = useState<any[]>([{
        value: 0,
        label: '',
        status: 0
    }]);

    const [cliente, setCliente] = useState<any>({
        idCliente: 0,
        razonSocial: '',
        rfc: '',
        agente: '',
        idAgente: 0
    });

    const [destinatarios, setDestinatarios] = useState<Destinatario[]>([{
        nombre: 'Sandra Diaz',
        email: 'mdsk'

    }]);

    const [destinatario, setDestinatario] = useState<Destinatario>({
        nombre: '',
        email: ''

    });

    const [remitente, setRemitente] = useState<Remitente>({
        nombre: '',
        email: ''

    });

    const [remitentes, setRemitentes] = useState<Remitente[]>([{
        nombre: 'Eduardo',
        email: 'djedu'

    }]);

    const [correos, setCorreos] = useState<Correo[]>([{
        correoDe: '',
        correoPara: '',
        de: '',
        fecha: '',
        idProyecto: '',
        idReferencia: '',
        mensaje: '',
        para: '',
        perfil: ''

    }]);

    useEffect(() => {
        getClientAut(idCompromiso);
        getClientInfo(idFiado);


    }, []);

    const getClientAut = async (idEngagement: number) => {

        let externo = 0;

        const res = await engagementService.getClientAut(externo, idEngagement);
        const compromisoAux: Compromiso = res.data
        setCompromiso(res.data);

        let listaAuxiliarB: BlockOption[];
        listaAuxiliarB = []
        let listaAuxiliarC: ConditionOption[];
        listaAuxiliarC = []

        let tipo: string;
        tipo = ''

        for (const atributo of compromisoDetails) {

            if (compromisoAux.tipoCompromiso == tiposCompromiso.PORAPOYO) {
                tipo = 'Apoyo'
            } else if (compromisoAux.tipoCompromiso == tiposCompromiso.BLOQUEO) {
                tipo = 'Bloqueo'
            }
            atributo.descTipoCompromiso = tipo
            console.log("Tipo " + compromisoAux.tipoCompromiso + "Descripcion " + atributo.descTipoCompromiso)
        }

        for (const a of compromisoAux.bloqueos) {
            listaAuxiliarB.push({ value: a.idBloqueo, label: a.descripcion })
            //console.log("ID BLOQ: " + a.descripcion)
        }

        for (const b of compromisoAux.condiciones) {
            const s = b.estatus
            listaAuxiliarC.push({ value: b.idCondicion, label: b.descripcion, estatus: s })
            //console.log("CONDICIONES: " + listaAuxiliarC)
        }




        setConditionOption(listaAuxiliarC)
        setBlockOption(listaAuxiliarB)

    }

    const getClientInfo = async (idPrincipal: number) => {
        const res = await engagementService.getClient(idPrincipal, 0);
        setCliente(res.data)
        //console.log("Sandy encuentra Ciente en Detalle por ID")
        //console.log(cliente)
    }

  

    const next = () => {
        setCount(count + 1)
       // getDestintarioRemitente(idCompromiso);
    }

    const back = () => {
        setCount(count - 1)
    }

    return (
        <>
            <div id="form-detail-engagement">
                <div className="create-engagement">
                    <div className="row">

                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-8 ">
                                    <InfoEngagement id={idFiado} />
                                </div>
                            </div>
                        </div>

                        {compromiso.dictamen != null ? (
                            <div className="col-md-4">
                                <PipeLineStepsDetails
                                    listSteps={[`${languageState.language?.createEngagement.compromiso} `, `${languageState.language?.createEngagement.revision} `,
                                    `${languageState.language?.createEngagement.dictamen} `]}
                                    currentStep={3} />
                            </div>


                        ) : <div className="col-md-4">
                            <PipeLineStepsDetails
                                listSteps={[`${languageState.language?.createEngagement.compromiso} `, `${languageState.language?.createEngagement.revision} `,
                                `${languageState.language?.createEngagement.dictamen} `]}
                                currentStep={count!} />
                        </div>}


                        <div className="col-md-12">
                            <Form id="form-liberty">
                                {count == 1 ? (

                                    <div className="row">

                                        {compromisoDetails.map((i, index) =>
                                            <div className="col-md-12">
                                                <label className="label-input">{languageState.language?.createEngagement.tipoLabel}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={i.descTipoCompromiso}
                                                    disabled />
                                            </div>
                                        )}

                                        {compromiso.tipoCompromiso == 1 ?
                                            <div className="col-md-12">
                                                <label className="label-input">{languageState.language?.createEngagement.condicionesLabel}</label>
                                               
                                                    {conditionOption.map((condition, index) =>

                                                        <div className="condition-input">
                                                            {condition.estatus == 0 ? (
                                                                 <List compact >
                                                                <ListItem>{condition.label}</ListItem>
                                                                </List>
                                                            ) :
                                                                <Badge highlightType="positive"> {condition.label} </Badge>}
                                                        </div>
                                                    )}
                                                

                                            </div> : null}

                                        {compromiso.tipoCompromiso == 2 ?

                                            <div className="col-md-12">
                                                <label className="label-input">{languageState.language?.createEngagement.bloqueosLabel}</label>
                                                <List compact className="condition-input">
                                                    {blockOption.map((bloq, index) =>
                                                        <ListItem>{bloq.label}</ListItem>
                                                    )}
                                                </List>
                                            </div> : null}


                                        <div className="col-md-12">
                                            <label className="label-input">{languageState.language?.createEngagement.producto}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={compromiso.producto} disabled
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{languageState.language?.createEngagement.monto}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={formatCurrency(compromiso.monto)} disabled
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{languageState.language?.createEngagement.nombreAgente}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={cliente.agente} disabled
                                            />
                                        </div>

                                        {/* {compromiso.datosFiado.correo != null && compromiso.statusCompromiso == 1 ? (

                                            <div className="col-md-12">
                                                <label className="label-input">{languageState.language?.createEngagement.nombreRepresentante}</label>

                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={compromiso.datosFiado.nombreRep} disabled />

                                            </div>) : null}

                                        {compromiso.datosFiado.correo != null && compromiso.statusCompromiso == 7 ? (

                                            <div className="col-md-12">
                                                <label className="label-input">{languageState.language?.createEngagement.email}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={compromiso.datosFiado.correo} disabled
                                                />
                                            </div>

                                        ) : null}

                                        {compromiso.datosFiado.correo != null && compromiso.statusCompromiso == 1 ? (

                                            <div className="col-md-6">
                                                <label className="label-input">{languageState.language?.createEngagement.fecha}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={compromiso.fechaComprometida} disabled />
                                            </div>

                                        ) : null}

                                        {compromiso.datosFiado.correo == null ? (

                                            <div className="col-md-6">
                                                <label className="label-input">{languageState.language?.createEngagement.fecha}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value={compromiso.fechaComprometida} disabled />
                                            </div>

                                        ) : null} */}


                                        <div className="col-md-12">

                                            <label className="label-input">{languageState.language?.createEngagement.motivo}</label>
                                            <textarea value={compromiso.motivo} disabled className={"input-sdgf"} rows={4} cols={55} />

                                        </div>


                                        <div className="col-md-12">

                                            <div className="row buttons-navigation">
                                                <div className="col-md-4">
                                                    <br />
                                                    {(isModal) ? (
                                                        <>
                                                            <Button dynamicWidth
                                                                onClick={() => {
                                                                    clickShowModal!(false)
                                                                }}
                                                                variant="secondary">
                                                                {languageState.language?.createEngagement.regresar}

                                                            </Button>
                                                        </>
                                                    ) :
                                                        <PrevPage />
                                                    }


                                                </div>
                                                <div className="col-md-4">
                                                    <br />

                                                    {validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO) ? (
                                                        <HistoryEngagementsF idFiado={idFiado} />
                                                    ) : <HistoryEngagements idCompromiso={idCompromiso} />}

                                                </div>
                                                {compromiso.statusCompromiso != 7 ? (

                                                    <div className="col-md-4">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => next()}>
                                                            {languageState.language?.createEngagement.continuar}
                                                        </Button>
                                                    </div>


                                                ) : <div className="col-md-4">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="primary"
                                                        //onClick={() => this.next()}
                                                        href="/compromisos">
                                                        {languageState.language?.createEngagement.finalizar}
                                                    </Button>
                                                </div>}

                                            </div>
                                        </div>

                                    </div>

                                ) : null}
                            </Form>

                            {count == 2 ? (
                                <>


                                    <Form id="form-mensajes">
                                        <div className="row mensajes">

                                            <MessagesAPIComponent idAPI={2}
                                                idEspecifico={idCompromiso!}
                                                                  disableCreate={false}
                                                emailRemitente={PERFIL.email}
                                                nombreRemitente={PERFIL.username}
                                                emailDest={destinatario.email}
                                                nombreDest={destinatario.nombre}
                                                TOKEN={PERFIL.accessToken}
                                                APIURL={environment.APIMENSAJES}
                                               APIKEY={environment.APIKEYMENSAJES}
                                            />

                                        </div>

                                        <div className="col-md-12">
                                            <div className="row buttons-navigation">
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        onClick={() => back()}>
                                                        {languageState.language?.createEngagement.regresar}
                                                    </Button>
                                                </div>
                                                {compromiso.dictamen != null ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => next()}>
                                                            {languageState.language?.createEngagement.continuar}
                                                        </Button>
                                                    </div>


                                                ) : <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="primary"
                                                        //onClick={() => this.next()}
                                                        href="/compromisos">
                                                        {languageState.language?.createEngagement.finalizar}
                                                    </Button>
                                                </div>}

                                            </div>
                                        </div>
                                    </Form></>

                            ) : null}


                            {count == 3 ? (
                                <><Form id="form-dictamen">
                                    <div className="row">

                                        {compromiso.dictamen == null ? (
                                            <div className="col-md-12 text-center">
                                                <label className="label-input"></label>
                                                <textarea value={compromiso.dictamen}

                                                    placeholder={`${languageState.language?.createEngagement.sinDictamen} `}
                                                    disabled className={"input-sdgf"} rows={5} cols={5} />

                                            </div>
                                        ) : null}

                                        {compromiso.dictamen != null ? (
                                            <div className="col-md-12 text-center">
                                                <label className="label-input"></label>
                                                <textarea value={compromiso.dictamen}
                                                    disabled className={"input-sdgf"} rows={5} cols={5} />
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="col-md-12">
                                        <div className="row buttons-navigation">
                                            <div className="col-md-4">
                                                <br />
                                                <Button dynamicWidth
                                                    variant="secondary"
                                                    onClick={() => back()}>
                                                    {languageState.language?.createEngagement.regresar}
                                                </Button>
                                            </div>

                                            <div className="col-md-4">
                                                <br />
                                                <Button href='/compromisos'
                                                    dynamicWidth
                                                    variant="primary">
                                                    {languageState.language?.createEngagement.finalizar}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form></>

                            ) : null}



                        </div>
                    </div>
                </div>
            </div>
        </>);
}

interface Messages {
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgCorreo: string;
    msgFecha: string;
    msgMotivo: string;
}

interface BlockOption {
    value: number;
    label: string;
}

interface ConditionOption {
    value: number;
    label: string;
    estatus: number;
}

export enum tiposCompromiso {
    PORAPOYO = 1,
    BLOQUEO = 2,

}