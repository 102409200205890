
import React, { Component } from 'react'
import { Dropdown, Pagination } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps} from 'react-router-dom';
import { initPaginacion, InputChange, loadPaginationList } from '../../../class/CommonClass';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import NavBar from '../../../components/public/nav-bar/NavBar';
import { TypeProfileModel } from '../../../components/garantias/select-type-user/SelectTypeUser';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import './Request.scss'
import { Alert, AlphanumericInput, Badge, IconSeeMore, Select, SelectOption, Table, TableCell, TableRow } from '@lmig/lmds-react';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import { AxiosError } from 'axios';
import { ConsultaBuro } from '../../../models/buro-credito/ConsultaBuro';
import { Datos } from '../../../models/buro-credito/Datos';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { UserModel } from '../../../models/public/UserModel';
import { List_Office } from '../../../models/buro-credito/folio';

interface Params {
    folio: string;
}

interface BureauProps {
    language: DefaultStateI
}

class Request extends Component<BureauProps & RouteComponentProps<Params>, BureauState> {

    public localStorageClass = new LocalStorageClass();
    public folio: number;



    constructor(props: any) {
        super(props);
        document.title = "Buró de Crédito"



        this.state = {
            status: 0,
            listBureau: [],
            listPorcent: [],
            valida_tabla: false,
            search: '',
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            PERFIL: this.localStorageClass.getProfile(),
            errorTableDetail: false,
            Oficina: [],
            idOficina: '0',
            filterSearch: {
                folio: ''
            },
            validador: true


        }
        this.folio = parseInt(this.props.match.params.folio);
        this.getCredit = this.getCredit.bind(this);
        this.process = this.process.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOffice = this.handleOffice.bind(this);
    }



    render() {
        return <>
         <NavBar />
            <div className="container-fluid " id="creditbureau">
               
                <div className="creditbureau-container" >
                    <BreadCumb
                        title={this.props.language.language?.request.titleRequest!}
                    />
                    <br />
                    <br />



                    <div className="container">

                        <div className="row">
                            <div className="col-4">
                                <Select
                                    id="select--default"
                                    labelVisual="Filtrado por Oficina"
                                    onChange={this.handleOffice}
                                    onBlur={this.handleOffice}
                                    placeholderOption="Filtrado por Oficina"
                                    value={this.state.idOficina}
                                >
                                    <SelectOption value="0">
                                       Todas las oficinas
                                    </SelectOption>

                                    {this.state.Oficina.map((i: List_Office, index: number) => (
                                        <SelectOption value={i.idOficina.toString()} key={index}>
                                            {i.descripcionOficina}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </div>
                            <div className="col">
                                <AlphanumericInput
                                    id="Alphanumeric--default"
                                    name="search"
                                    labelVisual={this.props.language.language?.creditbureau.creditsearch}
                                    onChange={this.handleInputChange}
                                    value={this.state.search}
                                />
                            </div>
                        </div>








                    </div>

                </div>

                <br />
                <div className="col-md-12">

                    {this.state.errorTableDetail === true ? (
                        <div className="col-12">
                            <Alert highlightType="negative">
                                {this.props.language.language?.request.alert!}
                            </Alert>
                        </div>
                    ) :
                        <>

                            {this.state.valida_tabla === true ? (
                                <div className="col-12">
                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                </div>
                            ) :

                                <>
                                    {/*data table*/}
                                    <Table id='table-credit' >

                                        <thead>
                                            <TableRow >
                                                <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditfolio!}
                                                </TableCell>
                                                <TableCell dataHeader={'Nombre'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditname!}
                                                </TableCell>
                                                <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.credittype!}
                                                </TableCell>
                                                <TableCell dataHeader={'Oficina'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditoffice!}
                                                </TableCell>

                                                <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditdate!}
                                                </TableCell>
                                                <TableCell dataHeader={'Estatus'} type="colHead" className='tb'>
                                                    {this.props.language.language?.creditbureau.creditstatus!}
                                                </TableCell>


                                            </TableRow>
                                        </thead>

                                        <tbody>


                                            {
                                                this.state.listBureau.map((value: ConsultaBuro, index: number) => (

                                                    <TableRow key={index} id={value.folio} >

                                                        <TableCell type="rowHead">
                                                            {value.folio}
                                                        </TableCell>


                                                        <TableCell>
                                                            {value.nombre + " " + value.apellidoPaterno+" "+value.apellidoMaterno}
                                                        </TableCell>
                                                        <TableCell >
                                                            {value.tipoEntidad}
                                                        </TableCell>
                                                        <TableCell >
                                                            {value.nombreOficina}
                                                        </TableCell>
                                                        <TableCell >
                                                            {value.fechaConsulta}
                                                        </TableCell>
                                                        <TableCell dataHeader={'Estatus'} className="estatus">
                                                            <div className="row">
                                                                <div className="col">
                                                                    {value.estado === '1' ? (
                                                                        <Badge highlightType="informative" >
                                                                            {this.props.language.language?.request.revison!}
                                                                        </Badge>
                                                                    ) : null}

                                                                </div>
                                                                <div className="col">
                                                                    {value.estado === '1' ? (
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                <IconSeeMore></IconSeeMore>
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => this.handleProcess(value.folio!)} >
                                                                                    {this.props.language.language?.request.consult!}
                                                                                </Dropdown.Item>

                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    ) : null}


                                                                </div>
                                                            </div>



                                                        </TableCell>

                                                    </TableRow>



                                                ))
                                            }




                                        </tbody>

                                    </Table>
                                </>}
                        </>}


                    {this.state.validador === false ? (
                        <>
                            <p>{this.props.language.language?.creditbureau.data!}</p>
                        </>) : null}

                    {/*paginacion*/}
                    < div className="row pagination-sdgf" >
                        <div className="col-md-5 show-registers">
                            {this.props.language.language?.creditbureau.creditmostrar!}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size) - (this.state.paginacion.size - 1)}  {this.props.language.language?.creditbureau.credital!}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size)}  {this.props.language.language?.creditbureau.creditotal!}&nbsp;
                            {this.state.paginacion.totalItems}  {this.props.language.language?.creditbureau.creditrecors!}.
                        </div>
                        <div className="col-md-7 ">
                            <Pagination className="float-md-right" >
                                <Pagination.Prev
                                    onClick={() => {
                                        this.clickGoToPage((this.state.listPaginacion[0]) - 1)

                                    }}
                                />
                                {
                                    this.state.listPaginacion.map((i, index) =>
                                    (<Pagination.Item
                                        key={i}
                                        active={i === this.state.paginacion.currentPage}
                                        onClick={() => {
                                            this.clickGoToPage(i);
                                        }}>
                                        {i}
                                    </Pagination.Item>

                                    ))
                                }
                                <Pagination.Next
                                    onClick={() => {
                                        this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length - 1] + 1)
                                    }}
                                />

                            </Pagination>

                        </div>

                    </div>


                </div>

            </div>
        </>

    }
    loadDashboard(tipoPerfil: TypeProfileModel) {
        throw new Error('Method not implemented.');
    }



    componentDidMount() {
        this.getCredit(this.state.paginacion)
        this.getlistaOficina();
    }

    public handleInputChange = (e: InputChange) => {
        this.setState({
            search: e.target.value
          },() => {
            this.getCredit(this.state.paginacion)
          })
        
        
        
    }
    public handleOffice  = (e: InputChange) => {
        this.setState({
        idOficina: e.target.value
      },() => {
        this.getCredit(this.state.paginacion)
      })
       
       
    }

    public async getCredit(paginacion: PaginacionModel) {


        try {

            this.setState({ errorTableDetail: true })
            const res = await getCreditbureau.getConEject(this.state.search,0, this.state.idOficina, 'AGENTE',paginacion,this.state.PERFIL.username);
            const response: { paginacion: PaginacionModel, consultas: ConsultaBuro[] } = res.data;
            response.paginacion.size = paginacion.size;


            this.setState({
                paginacion: response.paginacion,
                loadingConsult: false,
                listBureau: response.consultas,
                listPaginacion: loadPaginationList(res.data.paginacion),
                validador: true
            })

            //console.log(this.state.listBureau)

            if (response.consultas == null) {

            } else {
                this.setState({ valida_tabla: false })
                this.setState({ errorTableDetail: false })
            }




        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    

    private clickGoToPage(page: number) {
        const totalPages: number = this.state.paginacion.totalPages;
        if (page >= 1 && page <= totalPages) {
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({ paginacion: auxPaginacion })
            this.getCredit(this.state.paginacion);
        }



    }


    public async getlistaOficina() {
        try {
            const res = await getCreditbureau.getlistaOficina(this.state.PERFIL.username);
            const dat = Object(res.data);
            this.setState({ Oficina: dat })
           // console.log(dat)
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    public process() {
        this.props.history.push('/buro-credito/filtrar-Solicitudes/En-revision');
    }



    public handleProcess(value: string) {

        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/consultar/enrevision');
    }

    





}






function mapStateToProps(state: RootStore) {
    return ({
        language: state.language
    });
}


export default connect(mapStateToProps)(Request);

interface BureauState {
    status: number,
    listBureau: ConsultaBuro[];
    listPorcent: Datos[];
    search: string;
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    filterSearch: SearchModel,
    validador: boolean,
    valida_tabla: boolean,
    PERFIL: UserModel,
    errorTableDetail: boolean,
    Oficina: List_Office[],
    idOficina: string
}

export interface SearchModel {
    folio: string;
}





