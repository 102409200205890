export interface ModalFileMaxUploadLanguage {
    modalExcedLimitFile: string;
    ok: string;
}

export const ModalFileMaxUploadLanguageEnglish: ModalFileMaxUploadLanguage ={
    modalExcedLimitFile: 'The maximum size to upload a file is',
    ok: 'Ok'

}
export const ModalFileMaxUploadLanguageSpanish: ModalFileMaxUploadLanguage ={
    modalExcedLimitFile: 'El tamaño máximo para subir un archivo es de',
    ok: 'Aceptar',
}