export interface WarrantiesReviewLanguageModel {
    title: string;
    home: string;
    pendients: string;
    loadingPendients: string;
    errorPendients: string;
    warranties: string;
    inReview: string;
    loadingReviews: string;
    errorReview: string;
    createWarranty: string;
    finished: string;
    loadingFinished: string;
    errorFinished: string;
    searchPrincipals: string;
    searchButton: string;

}

export const WarrantiesReviewLanguageEnglish: WarrantiesReviewLanguageModel ={
    title: 'Review of Collaterals',
    home: 'Home',
    pendients: 'Pending',
    loadingPendients: 'Loading Pending Collaterals',
    errorPendients: 'Sorry, something has happened while loading Colaterals Pending, check your connection and try again.',
    warranties: 'Collaterals',
    inReview: 'In Review',
    loadingReviews: 'Loading Collaterals in Review',
    errorReview: 'Sorry, something has happened while loading Colaterals in Review, please check your connection and try again.',
    createWarranty: 'Create Collateral',
    finished: 'Finished',
    loadingFinished: 'Loading Finalized Collaterals',
    errorFinished: 'Sorry, something has happened while loading Colaterals Ended, please check your connection and try again.',
    searchPrincipals: 'Search Principals',
    searchButton:  'Search'
}
export const WarrantiesReviewLanguageSpanish: WarrantiesReviewLanguageModel ={
    title: 'Revisión de Garantías',
    home: 'Inicio',
    pendients: 'Pendientes',
    loadingPendients: 'Cargando Garantías Pendientes',
    errorPendients: 'Lo sentimos algo ha ocurrido al cargar Garantías Pendientes, revise su conexión e intente de nuevo.',
    warranties: 'Garantías',
    inReview: 'En Revisión',
    loadingReviews: 'Cargando Garantías en Revisión',
    errorReview: 'Lo sentimos algo ha ocurrido al cargar Garantías en Revisión, revise su conexión e intente de nuevo.',
    createWarranty: 'Crear Garantía',
    finished: 'Finalizados',
    loadingFinished: 'Cargando Garantías Finalizadas',
    errorFinished: 'Lo sentimos algo ha ocurrido al cargar Garantías Finalizadas, revise su conexión e intente de nuevo.',
    searchPrincipals: 'Buscar Fiados',
    searchButton: 'Buscar'

}