export interface WarrantyComparisionLanguage {
  title: string;
  loan: string;
  watchFile: string;
  brokenLink: string;
  noData: string;
  typeUse: string;
  fianza: string;
  fiados: string;
  guaranteeType: string;
  currency: string;
  amount: string;
  weighing: string;
  weightedAmount: string;
  validity: string;
  loadingDocuments: string;
  loadingComparison: string;
  documents: string;
}

export const WarrantyComparisionLanguageEnglish: WarrantyComparisionLanguage = {
  title: "Loans comparison",
  loan: "Loan",
  watchFile: "View File",
  brokenLink: "No link",
  noData: "No data",
  typeUse: "Type of use",
  fianza: "Loan",
  fiados: "Principals/Obligors",
  guaranteeType: "Guarantee Type",
  currency: "Currency",
  amount: "Amount",
  weighing: "Weighting",
  weightedAmount: "Weighted Amount",
  validity: "Validity",
  loadingDocuments: "Loading Documents",
  loadingComparison: "Loading Comparison",
  documents: "Documents",
};
export const WarrantyComparisionLanguageSpanish: WarrantyComparisionLanguage = {
  title: "Comparación de garantía",
  loan: "Fiado",
  watchFile: "Ver Archivo",
  brokenLink: "Sin link",
  noData: "Sin datos",
  typeUse: "Tipo de uso",
  fianza: "Fianza",
  fiados: "Fiados/Obligados",
  guaranteeType: "Tipo de Garantía",
  currency: "Moneda",
  amount: "Monto",
  weighing: "Ponderación",
  weightedAmount: "Monto Ponderado",
  validity: "Vigencia",
  loadingDocuments: "Cargando Documentos",
  loadingComparison: "Cargando Comparacion",
  documents: "Documentos",
};
