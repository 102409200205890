import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import * as apiTokenService from "../../../services/api-session/ApiSession";
import logo from '../../../assets/images/public/logo-web-liberty16.png';
import './SinginURB.scss';
import { AxiosError } from "axios";
import { NegativeAlert } from "../../../components/public/negative-alert/NegativeAlert";
import { Button } from "@lmig/lmds-react";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
interface ISection {
    id: number;
}
interface Params {
    urb: string;
    clave: string;
    origen: string;
    idFiado: string;
}

interface LocationState {
}

interface SC {
    statusCode?: number;
}
class SinginURBGarantias extends Component<ISection & RouteComponentProps<Params, SC, LocationState>, SinginURBState> {

    public URB: string;
    public clave: string;
    public origin: string;
    public fiado: number;
    public localStorageClass = new LocalStorageClass();
    constructor(props: any) {
        super(props);
        this.URB = this.props.match.params.urb;
        this.clave = this.props.match.params.clave;
        this.origin = this.props.match.params.origen;
        this.fiado = parseInt(this.props.match.params.idFiado);
        document.title = "Cargando Información "
        this.state = {
            loading: true,
            errorSinginURB: false,
        }



    }
    render() {
        return <>
            <div id="singin-urb">
                <img src={logo} alt="Logo" id="logo-liberty" className="img-fluid d-block mx-auto" />
                {this.state.loading ? (
                    <>
                        <SpinnerLoading text={"Iniciando Sesión"} />
                    </>
                ) :
                    <>
                        <br />

                         <NegativeAlert
                            textAlert={'Lo sentimos un error ha pasado, revise su conexión y vuela a intentarlo.'} /> 

                        <br />
                        <Button dynamicWidth
                            variant="primary"
                            //href={"/cumplimientos/fiado/" + this.fiado}
                            onClick={() => this.getSignURB(this.URB, this.fiado)}>
                           Reintentar
                        </Button>
                    </>
                }

            </div>

        </>
    }
    componentDidMount() {

        console.log("FIADO: " +  this.fiado)
        this.getSignURB(this.URB, this.fiado);

    }

    private async getSignURB(urb: string, fiado: number) {
        this.setState({ loading: true, errorSinginURB: false })
        try {
            const res = await apiTokenService.getSigninUrb(urb);
            if (this.clave) {
                res.data.clave = this.clave;
            }
            await this.localStorageClass.setSomeString(this.localStorageClass.PERFIL, JSON.stringify(res.data));
            // esperar tres segundos para redireccionar
            await new Promise(done => setTimeout(() => {
                // this.redirect(this.origin)

                this.props.history.push('/cumplimientos/fiado/' + fiado);
            }, 3000));
            await this.setState({ loading: false })
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({ loading: false, errorSinginURB: true })
            }
        }

    }


    private async redirect(origin: string) {
        switch (origin) {
            case originSerf.engangmentsW:

                await this.props.history.push('/cumplimientos/fiado/' + this.fiado);
                break;

        }
    }
}
interface SinginURBState {
    loading: boolean,
    errorSinginURB: boolean

}
export enum originSerf {

    engangmentsW = 'compromisos-garantias'
}

export default SinginURBGarantias;
