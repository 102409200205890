import React, {} from "react";
import {Link} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import './BreadCumbEngagements.scss';
export interface BreadCumbListModel {
    title: string,
    url: string
}
interface BreadCumbProps {
    title: string
    listBreadcumbs?: BreadCumbListModel[]
}
export const BreadCumbEngagements: React.FC<BreadCumbProps> = ({ title, listBreadcumbs}:BreadCumbProps) => {

    return <>
        <div className={"row"} id="bread-cumb-engagements">
            <div className="col-md-12">
                <h3 className={"title-seccion"}> {title}
                    <br/>
                </h3>

                {listBreadcumbs? (
                    <Breadcrumb className="bc">
                        {// @ts-ignore
                            listBreadcumbs?.map((i, index) =>(
                                <Breadcrumb.Item key={index}>
                                        <Link  to={i.url}> {i.title}</Link>
                                </Breadcrumb.Item>
                             ))
                        }
                    </Breadcrumb>
                ): null
                }

            </div>
        </div>
    </>;
};

