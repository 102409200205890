export interface FormCreateEngagmentsLanguage {
    noValidDate: string;
    compromiso: string;
    aceptacion: string;
    revision: string;
    dictamen: string;
    tipoLabel: string;
    seleccionaTipo:string;
    selectOption: string;
    condicionesLabel:string;
    bloqueosLabel: string;
    producto:string;
    monto:string;
    limitAmount: string;
    fiadoLabel:string;
    fecha: string;
    motivo: string
    regresar: string
    continuar: string;
    saving: string;
    cancel: string;
    dateNoValid: string;
    email:string;
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgFecha: string;
    msgCorreo: string;
    msgMotivo: string;
    aceptoCompromiso: string;
    aceptaTerminos: string;
    sendSolicitud: string;
    exceedThreeMonths: string;
    finalizar: string
}

export const languageFormCreateEngagmentsEnglish: FormCreateEngagmentsLanguage ={
    noValidDate: 'Invalid Date',
    compromiso: 'Compromise',
    aceptacion: 'Acceptance',
    revision: 'Review',
    dictamen: 'Resolution',
    tipoLabel: 'Type',
    seleccionaTipo: 'Select a Type of Compromise',
    selectOption: 'Select an option ',
    condicionesLabel: 'Authorization Conditions',
    bloqueosLabel: 'Active Blocks',
    producto: 'Product',
    monto: 'Amount',
    limitAmount: 'Exceeds the allowed limit',
    fiadoLabel: 'Principals Compromise',
    fecha: 'Due Date',
    motivo: 'Subject',
    regresar: 'Back',
    continuar: 'Continue',
    saving: 'Saving',
    cancel: 'Cancel',
    dateNoValid: 'Invalid Date',
    email: 'Principal E-mail',
    msgTipo: 'Please select a Commitment Type',
    msgCondicion: 'Please select at least one Condition.',
    msgBloqueo: 'Please select at least one Block',
    msgProducto: 'Please select a Product',
    msgMonto: 'Please enter an Amount',
    msgFecha: 'Please select a date',
    msgCorreo: 'Please capture an Email',
    msgMotivo: 'Please capture a Reason',
    aceptoCompromiso: 'I hereby agree that I will register pending information according with the deadline provided by the Surety. In case I do not fulfil the above obligation, I agree and recognize that the future issuance of bonds for my client will be suspended as long as I do not conclude the required register.',
    aceptaTerminos: 'Accept Terms and Conditions',
    sendSolicitud: 'E-mail with the request has been sent to the Principal',
    exceedThreeMonths: 'The term of the commitment exceeds 3 months. Please wait for your authorization',
    finalizar: 'Finish'
}
export const languageFormCreateEngagmentsSpanish: FormCreateEngagmentsLanguage ={
    noValidDate: 'Fecha No Válida',
    compromiso: 'Compromiso',
    aceptacion: 'Aceptación',
    revision: 'Review',
    dictamen: 'Resolution',
    tipoLabel: 'Tipo',
    seleccionaTipo: 'Selecciona un Tipo de Compromiso',
    selectOption: 'Selecciona una opción',
    condicionesLabel: 'Condiciones de Autorizacion',
    bloqueosLabel: 'Bloqueos Activos',
    producto: 'Producto',
    monto: 'Monto',
    limitAmount: 'Excede el limite permitido',
    fiadoLabel: 'Compromiso con Fiado',
    fecha: 'Fecha de Cumplimiento',
    motivo: 'Motivo',
    regresar: 'Regresar',
    continuar: 'Continuar',
    saving: 'Guardando',
    cancel: 'Cancelar',
    dateNoValid: 'Fecha No Válida',
    email: 'Correo Electrónico',
    msgTipo: 'Por favor selecciona un Tipo de Compromiso',
    msgCondicion: 'Por favor selecciona al menos una Condición.',
    msgBloqueo: 'Por favor selecciona al menos un Bloqueo',
    msgProducto: 'Por favor selecciona un Producto',
    msgMonto: 'Por favor captura un Monto',
    msgFecha: 'Por favor selecciona una fecha',
    msgCorreo: 'Por favor captura un Correo',
    msgMotivo: 'Por favor captura un Motivo',
    aceptoCompromiso: 'Acepto que realizaré el registro de la información pendiente en el plazo acordado con la Afianzadora. En el supuesto de que no se realice lo anterior acepto y reconozco que la emisión futura de pólizas a cargo de mi cliente quedará suspendida, hasta concluir con el registro en mención.',
    aceptaTerminos: 'Acepta Términos',
    sendSolicitud: 'Se ha enviado la solicitud de aceptación al fiado a su cuenta de correo',
    exceedThreeMonths: 'El plazo del compromiso excede los 3 meses. Por favor espera su autorización',
    finalizar: 'Finalizar'

}