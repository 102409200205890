import axios from 'axios';
import environment from '../../environments/environment'
import {TiposUsoGarantias} from "../../models/garantias/TiposUsoGarantias";
import {ClavesGarantias} from "../../models/garantias/ClavesGarantias";
import {ExpedienteModel} from "../../models/garantias/ExpedienteModel";
import {GarantiaModel} from "../../models/garantias/GarantiaModel";
import {ArchivoGarantia} from "../../models/garantias/ArchivoGarantia";
import {LocalStorageClass} from "../../class/LocalStorageClass";
import {ExpedienteIdetificacionModel} from "../../models/garantias/ExpedienteIdetificacionModel";
import {ResponsableGarantiaModel} from "../../models/garantias/ResponsableGarantiaModel";
import {PaginacionModel} from "../../models/public/PaginacionModel";
import {ListGarantiaModel} from "../../models/garantias/ListGarantiaModel";
import {ProgresoGarantiaModel} from "../../models/garantias/ProgresoGarantiaModel";
import {TypeProfileModel} from "../../components/garantias/select-type-user/SelectTypeUser";
import {TypeProfileEnum} from "../../models/garantias/TypeProfileEnum";
import {EstadisticasModel} from "../../models/garantias/EstadisticasModel";
import {ListClienteModel} from "../../models/garantias/ListClienteModel";
import {CifrasFiadoModel} from "../../models/garantias/CifrasFiadoModel";
import {GarantiasRevisionModel} from "../../models/garantias/GarantiasRevisionModel";
import {GarantiasPendientesModel} from "../../models/garantias/GarantiasPendientesModel";
import {GarantiasfinalizadasModel} from "../../models/garantias/GarantiasFinalizadasModel";
import {FiltersSearchWarantyModel} from "../../models/garantias/EstatusGarantiaEnum";
import {CalificacionMontoModel} from "../../models/garantias/CalificacionMontoModel";
import {MotivoRechazoModel} from "../../models/garantias/MotivoRechazoModel";
import {CalificacionDocModel} from "../../models/garantias/CalificacionDocModel";
import {PropietarioGarantiaModel} from "../../models/garantias/PropietarioGarantiaModel";
import {DictamenGarantiaModel} from "../../models/garantias/DictamenGarantiaModel";
import {InfoObligadoSolidario} from "../../models/garantias/InfoObligadoSolidario";
import {BloqueoCliente} from "../../models/garantias/BloqueoCliente";
import {PorcentajePonderacion} from "../../models/garantias/PorcentajePonderacion";
import {CalificacionPorcentajeModel} from "../../models/garantias/CalificacionPorcentajeModel";
import {LoteRevisionModel} from "../../models/garantias/LoteRevisionModel";
import {CalificacionVigenciaModel} from "../../models/garantias/CalificacionVigenciaModel";
import {MotivoEnum} from "../../models/garantias/MotivoEnum";
import {GarantiaDireccionada} from "../../models/garantias/GarantiaDireccionada";
import {ExpedienteCatalogo} from "../../models/garantias/ExpedienteCatalogo";
import {BloqueoCommission} from "../../models/garantias/BloqueoCommission";
import {MotivoBloqueo} from "../../models/garantias/MotivoBloqueo";
import {EjecutivoGar} from "../../models/garantias/EjecutivoGar";
import {ReporteExpedienteModel} from "../../models/garantias/ReporteExpedienteModel";
import {ReporteGarantiasModel} from "../../models/reporte-garantias/ReporteGarantiasModel";
import {ReporteGarantiasReporteModel} from "../../models/reporte-garantias/ReporteGarantiasReporteModel";
import {ResumenGarantias} from "../../models/garantias/ResumenGarantias";
import {ProductoLineaAfianzamiento} from "../../models/garantias/ProductoLineaAfianzamiento";
import {LineaAfinzamiento} from "../../models/garantias/LineaAfinzamiento";
import {Ramos} from "../../models/garantias/Ramos";
import { HistorialModel } from '../../models/garantias/HistorialModel';
import {ComparacionGarantias} from "../../models/garantias/ComparacionGarantias";


// obtener la dirección de la api y quitar las comillas del JSON
const  API = environment.APIGARANTIAS
const localStorageClass = new LocalStorageClass();


const  headersJson = {
    Accept: 'application/json',
    'X-API-KEY': environment.APIKEYGARANTIAS,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),
    env: environment.env
}

const  headersFormData = {
    Authorization: localStorageClass.getProfileToken(),
    'X-API-KEY': environment.APIKEYGARANTIAS,
    'Content-Type': 'multipart/form-data',
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),
    env: environment.env
}

const updateHeader = () => {
    headersJson.Authorization = localStorageClass.getProfileToken();
    headersJson.idLenguaje = localStorageClass.getLanguageId();
    headersJson.lang = localStorageClass.getLanguageCode();

    headersFormData.Authorization = localStorageClass.getProfileToken();
    headersFormData.idLenguaje = localStorageClass.getLanguageId();
    headersFormData.lang = localStorageClass.getLanguageCode();
}


export const getTipoUsoGarantias = async () => {
    updateHeader();
    return await axios.get<TiposUsoGarantias[]>(API+'/tiposUso' , { headers: headersJson });
};
export const getClavestipo = async () => {
    updateHeader();
    return await axios.get<ClavesGarantias[]>(API+'/claves/' , { headers: headersJson });
};
export const getExpediente = async (idFiado: number) => {
    updateHeader();
    return await axios.get<ExpedienteModel[]>(`${API}/expediente/` + idFiado, {headers: headersJson});
};

export const getCatalogoExpediente = async (idExpediente: number) => {
    updateHeader();
    return await axios.get<ExpedienteCatalogo[]>(`${API}/expedienteCatalogo/` + idExpediente, {headers: headersJson});
};

export const getGarantias = async (
    idCliente: number,
    paginacion: PaginacionModel,
    obligadoSolidarios:InfoObligadoSolidario [],
    filtersSearch?: FiltersSearchWarantyModel,
    busqueda?: string | null) => {
    let paginacionQueryParams= '';

    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    let queryParams= '';
    queryParams+= '&idCliente=' + idCliente;
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }

    if (filtersSearch){
        if (filtersSearch.vencida){
            queryParams += '&vencida=true'
        }
        if (filtersSearch.pendiente){
            queryParams += '&pendiente=true'
        }
        if (filtersSearch.revision){
            queryParams += '&revision=true'
        }
        if (filtersSearch.vigente){
            queryParams += '&vigente=true'
        }

    }
    updateHeader();

    return await axios.put<{paginacion: PaginacionModel, elementos: ListGarantiaModel[]}>(
        `${API}/garantia/filtro/os/${filtersSearch!.showLoansPrincipal}?` + paginacionQueryParams + queryParams ,
        obligadoSolidarios,{ headers: headersJson });
}

export const getGarantiaDetail = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<GarantiaModel>(`${API}/garantia/detalle/` + idGarantia , { headers: headersJson });
}
export const postGarantias = async (garantia: GarantiaModel) => {
    updateHeader();
    return await axios.post<GarantiaModel>(`${API}/garantia` , garantia, { headers: headersJson });
};

export const postGarantiaDireccionada = async (inclusionGarantia: GarantiaDireccionada) => {
    updateHeader();
    return await axios.post<GarantiaDireccionada>(`${API}/garantia/direccionada` , inclusionGarantia, { headers: headersJson });
};
export const getGarantiaDireccionada = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<GarantiaDireccionada>(`${API}/garantia/direccionada?idGarantia=${idGarantia}` , { headers: headersJson });
};
export const putGarantiaDireccionada = async (inclusionGarantia: GarantiaDireccionada) => {
    updateHeader();
    return await axios.put<GarantiaDireccionada>(`${API}/garantia/direccionada` , inclusionGarantia, { headers: headersJson });
};
export const putGarantia = async (garantia: GarantiaModel, sustitucion: boolean) => {
    updateHeader();
    //sustitucion  false es para que se vaya sobre la misma
    // true es para qu eme haga una copia
    return await axios.put<GarantiaModel>(`${API}/garantia?sustitucion=${sustitucion}` , garantia, { headers: headersJson });
};

export const postArchivoGarantia = async (formdata: FormData) => {
    updateHeader();
    return await axios.post<ArchivoGarantia>(`${API}/archivoGarantia` , formdata, { headers: headersFormData });
};
export const deleteArchivoGarantia = async (idDocumento: number) => {
    return await axios.delete<any>(`${API}/archivoGarantia/` + idDocumento , { headers: headersJson });
};

export const getGarantiaProgreso = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<ProgresoGarantiaModel>(`${API}/garantia/progreso/` + idGarantia , { headers: headersJson });
}
export const putGarantiaProgreso = async (idGarantia: number, estadoCaptura: number,estadoGarantia?: number) => {
    let queryParams= '';
    if (estadoCaptura){
        queryParams += '&estadoCaptura='+ estadoCaptura
    }
    if (estadoGarantia){
        queryParams += '&estadoGarantia='+ estadoGarantia
    }
    updateHeader();
    return await axios.put<ProgresoGarantiaModel>(`${API}/garantia/progreso/` + idGarantia + '?' + queryParams , null,{ headers: headersJson });
}

export const postExpediente= async (expediente: ExpedienteModel) => {
    updateHeader();
    return await axios.post<ExpedienteModel>(`${API}/expediente/doc` , expediente, { headers: headersJson });
};
export const putExpediente= async (expediente: ExpedienteModel) => {
    updateHeader();
    return await axios.put<ExpedienteModel>(`${API}/expediente/doc` , expediente, { headers: headersJson });
};
export const getExpedienteCompleto = async (idPrincipal: number) => {
    updateHeader();
    return await axios.get<{expedienteCompleto: boolean}>(`${API}/archivo/${idPrincipal}/completo`, {headers: headersJson});
};
export const getExpedienteIdentificacion = async (idPrincipal: number) => {
    updateHeader();
    return await axios.get<ExpedienteIdetificacionModel[]>(`${API}/archivo/` + idPrincipal, {headers: headersJson});
};
export const postExpedienteIdentificacion = async (formdata: FormData) => {
    updateHeader();
    return await axios.post<ExpedienteIdetificacionModel>(`${API}/archivo` , formdata, { headers: headersFormData });
};

export const putExpedienteArchivo = async (formdata: FormData) => {
    updateHeader();
    return await axios.put<ExpedienteIdetificacionModel>(`${API}/archivo` , formdata, { headers: headersFormData });
};
export const putExpedienteIdentificacion = async (expediente: ExpedienteIdetificacionModel) => {
    updateHeader();
    return await axios.put<ExpedienteIdetificacionModel>(`${API}/archivop` , expediente, { headers: headersJson });
};
export const putQuoteExpedienteIdentificacion = async (idDocumento : number, estadoDocumento: number, motivos?: MotivoRechazoModel[]) => {
    let queryParams= '';
    queryParams+= '&estadoDocumento=' + estadoDocumento
    let body: object = []
    if (motivos?.length!>0){
        body = motivos!
    }
    updateHeader();
    return await axios.put<ExpedienteIdetificacionModel>(`${API}/archivo/califica/` + idDocumento +'?' + queryParams , body, { headers: headersJson });
};
export const deleteFileExpediente = async (idDocumento: number) => {
    updateHeader();
    return await axios.delete<any>(`${API}/archivo/` + idDocumento , { headers: headersJson });
};

export const getResponsableGarantia = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<ResponsableGarantiaModel>(`${API}/responsableGar/?idGarantia=` + idGarantia, {headers: headersJson});
};

export const getEstadisticas = async (typeProfile: TypeProfileModel, idOficina: number| null) => {
    let queryParams= '';
    if (typeProfile.tipo == TypeProfileEnum.idEjGarantias){
        queryParams += '&idEjGarantias='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjecutivo){
        queryParams += '&idEjecutivo='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAgente){
        queryParams += '&idAgente='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idPromotor){
        queryParams += '&idPromotor='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAuditor){
        queryParams += '&idAuditor='+ typeProfile.id
    }
    if (idOficina){
        queryParams += '&idOficina='+ idOficina
    }
    updateHeader();
    return await axios.get<EstadisticasModel[]>(`${API}/estadistica?` + queryParams, {headers: headersJson});
};

export const getEstadisticaDetalle = async (idEstadistica: number, typeProfile: TypeProfileModel, paginacion: PaginacionModel) => {
    let queryParams= '';
    if (typeProfile.tipo == TypeProfileEnum.idEjGarantias){
        queryParams += '&idEjGarantias='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjecutivo){
        queryParams += '&idEjecutivo='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAgente){
        queryParams += '&idAgente='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAgente){
        queryParams += '&idAgente='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idPromotor){
        queryParams += '&idPromotor='+ typeProfile.id
    }


    let paginacionQueryParams= '';
    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    updateHeader();
    return await axios.get<{
        paginacion: PaginacionModel,
        detalleCliente: ListClienteModel [] | null,
        detalleGarantia: ListGarantiaModel [] | null}>
    (`${API}/estadistica/${idEstadistica}/?` + queryParams + paginacionQueryParams, {headers: headersJson});
};

export const getCifrasFiado = async (idCliente: number) => {
    updateHeader();
    return await axios.get<CifrasFiadoModel>(`${API}/cliente/cifras/` + idCliente , { headers: headersJson });
}

export const getGarantiasPendientes = async (typeProfile: TypeProfileModel,paginacion: PaginacionModel, busqueda: string) => {
    let queryParams= '';
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjGarantias){
        queryParams += '&idEjGarantias='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjecutivo){
        queryParams += '&idEjecutivo='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAgente){
        queryParams += '&idAgente='+ typeProfile.id
    }
    let paginacionQueryParams= '';

    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }

    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, pendiente: GarantiasPendientesModel[]}>(
        `${API}/revisionGarantias/pendiente?` + queryParams + paginacionQueryParams , { headers: headersJson });
}

export const getGarantiasRevision = async (typeProfile: TypeProfileModel,paginacion: PaginacionModel, busqueda: string) => {
    let queryParams= '';
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjGarantias){
        queryParams += '&idEjGarantias='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjecutivo){
        queryParams += '&idEjecutivo='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAgente){
        queryParams += '&idAgente='+ typeProfile.id
    }
    let paginacionQueryParams= '';
    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, revision: GarantiasRevisionModel[]}>(
        `${API}/revisionGarantias/revision?` + queryParams + paginacionQueryParams , { headers: headersJson });
}


export const getGarantiasFinalizadas = async (typeProfile: TypeProfileModel, paginacion: PaginacionModel, busqueda: string) => {
    let queryParams= '';
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }

    if (typeProfile.tipo == TypeProfileEnum.idEjGarantias){
        queryParams += '&idEjGarantias='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idEjecutivo){
        queryParams += '&idEjecutivo='+ typeProfile.id
    }
    if (typeProfile.tipo == TypeProfileEnum.idAgente){
        queryParams += '&idAgente='+ typeProfile.id
    }
    let paginacionQueryParams= '';
    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, finalizado: GarantiasfinalizadasModel[]}>(
        `${API}/revisionGarantias/finalizado?` + queryParams + paginacionQueryParams , { headers: headersJson });
}

export const putRevisionGarantiasPendiente = async (garantiaLoteRevision: GarantiasPendientesModel) => {
    updateHeader();
    return await axios.put<GarantiasRevisionModel>(`${API}/revisionGarantias/pendiente` , garantiaLoteRevision, { headers: headersJson });
};

export const getGarantiasLote = async (idLote: number, paginacion: PaginacionModel,  busqueda?: string | null) => {
    let paginacionQueryParams= '';

    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    let queryParams= '';
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }
    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, elementos: ListGarantiaModel[]}>(`${API}/garantia/` + idLote + '?' + paginacionQueryParams + queryParams , { headers: headersJson });
}

export const getCalifMonto = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<CalificacionMontoModel>(`${API}/garantia/califica/` + idGarantia , { headers: headersJson });
}

export const getCalifPorcentaje = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<CalificacionPorcentajeModel>(`${API}/garantia/califica/porcentaje/` + idGarantia , { headers: headersJson });
}

export const getCalifVigencia = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<CalificacionVigenciaModel>(`${API}/garantia/califica/vigencia/` + idGarantia , { headers: headersJson });
}

export const getMotivosRechazoExpedineteIdentificacion = async (idDocumento: number) => {
    updateHeader();
    return await axios.get<MotivoRechazoModel[]>(`${API}/motivo/identificacion/` + idDocumento , { headers: headersJson });
}
export const getMotivosRechazo = async (motivo: MotivoEnum) => {
    updateHeader();
    return await axios.get<MotivoRechazoModel[]>(`${API}/motivo/${motivo}` , { headers: headersJson });
}

export const getMotivosRechazoGarantia = async (idGarantia: number, idTipoDocumento: number) => {
    updateHeader();
    return await axios.get<MotivoRechazoModel[]>(`${API}/motivo/garantias/${idGarantia}?idTipoDocumento=${idTipoDocumento}`, { headers: headersJson });
}

export const getMotivosBloqueo = async () => {
    updateHeader();
    return await axios.get<MotivoRechazoModel[]>(`${API}/motivo/bloqueo` , { headers: headersJson });
}

export const getMotivosDesBloqueo = async () => {
    updateHeader();
    return await axios.get<MotivoRechazoModel[]>(`${API}/motivo/desbloqueo` , { headers: headersJson });
}

export const putCalificaMontoGarantia = async (estadoMonto: number, idGarantia: number, motivos?: MotivoRechazoModel[]) => {
    let queryParams= '';
    queryParams += '&estadoMonto='+ estadoMonto;
    let body: object = []
    if (motivos?.length!>0){
        body = motivos!
    }
    updateHeader();
    return await axios.put<CalificacionMontoModel>(`${API}/garantia/califica/`+ idGarantia + '/?' + queryParams, body, { headers: headersJson });
};

export const putCalificaPorcentajeGarantia = async (estadoMonto: number, idGarantia: number, motivos?: MotivoRechazoModel[]) => {
    let queryParams= '';
    queryParams += '&estadoPorcentaje='+ estadoMonto;
    let body: object = []
    if (motivos?.length!>0){
        body = motivos!
    }
    updateHeader();
    return await axios.put<CalificacionPorcentajeModel>(`${API}/garantia/califica/porcentaje/`+ idGarantia + '/?' + queryParams, body, { headers: headersJson });
};

export const putCalificaVigenciaGarantia = async (estadoVigencia: number, idGarantia: number, motivos?: MotivoRechazoModel[]) => {
    let queryParams= '';
    queryParams += '&estadoVigencia='+ estadoVigencia;
    let body: object = []
    if (motivos?.length!>0){
        body = motivos!
    }
    updateHeader();
    return await axios.put<CalificacionVigenciaModel>(`${API}/garantia/califica/vigencia/`+ idGarantia + '?' + queryParams, body, { headers: headersJson });
};

export const getCalifExpediente = async (idDocumento: number) => {
    updateHeader();
    return await axios.get<CalificacionDocModel>(`${API}/expediente/califica/` + idDocumento , { headers: headersJson });
}

export const getCalifArchivo = async (idDocumento: number) => {
    updateHeader();
    return await axios.get<CalificacionDocModel>(`${API}/archivo/califica/` + idDocumento , { headers: headersJson });
}

export const putCalifiExpediente = async (estadoDocumento: number, idDocumento: number, motivos?: MotivoRechazoModel[]) => {
    let queryParams= '';

    queryParams += '&estadoDocumento='+ estadoDocumento;
    let body: object = []
    if (motivos?.length!>0){
        body = motivos!
    }
    updateHeader();
    return await axios.put<CalificacionDocModel>(`${API}/expediente/califica/`+ idDocumento + '/?' + queryParams, body, { headers: headersJson });
};

export const putBloqueaCliente = async (idCliente: number, bloqueoCliente: BloqueoCliente) => {
    updateHeader();
    return await axios.put<CalificacionMontoModel>(`${API}/bloquea/`+ idCliente , bloqueoCliente, { headers: headersJson });
};
export const putDesBloqueaCliente = async (idCliente: number, bloqueoCliente: BloqueoCliente) => {
    updateHeader();
    return await axios.put<MotivoBloqueo>(`${API}/desbloquea/`+ idCliente , bloqueoCliente, { headers: headersJson });
};
export const getBloqueoCliente = async (idFiado: number) => {
    updateHeader();
    return await axios.get<MotivoBloqueo>(`${API}/bloquea?idCliente=` + idFiado , { headers: headersJson });
}
export const putBloqueaComisionCliente = async (idCliente: number, bloqueoComision: BloqueoCommission) => {
    updateHeader();
    return await axios.put<BloqueoCommission>(`${API}/bloquea/comision/`+ idCliente , bloqueoComision, { headers: headersJson });
};
export const putDesBloqueaComisionCliente = async (idCliente: number) => {
    updateHeader();
    return await axios.put<CalificacionMontoModel>(`${API}/desbloquea/comision/`+ idCliente , null, { headers: headersJson });
};



export const getPropietarioGar = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<PropietarioGarantiaModel>(`${API}/garantia/propietarioGar?idGarantia=` + idGarantia , { headers: headersJson });
}

export const getDictamenGar = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<DictamenGarantiaModel>(`${API}/garantia/dictamen/` + idGarantia , { headers: headersJson });
}

export const getGarantiaLotenRevision = async (idGarantia: number) => {
    updateHeader();
    return await axios.get<LoteRevisionModel>(`${API}/lote/revision/` + idGarantia , { headers: headersJson });
}

export const putDictamenGar = async ( idGarantia: number, estado: number, descripcionDictamenObj: DictamenGarantiaModel) => {

    updateHeader();
    return await axios.put<DictamenGarantiaModel>(`${API}/garantia/dictamen/${idGarantia}/?estado=${estado}`,
        descripcionDictamenObj, { headers: headersJson });
};

export const putPorcentajePonderacion = async (garantia: GarantiaModel) => {
    updateHeader();
    return await axios.put<PorcentajePonderacion []>(`${API}/porcentajePonderacion` , garantia, { headers: headersJson });
};

export const deleteBajaGarantia = async (idGarantia: number) => {
    updateHeader();
    return await axios.delete<{estado: number, mensaje: string}>(`${API}/garantia/baja?idGarantia=${idGarantia}`, { headers: headersJson });
}

export const getEjecutivosGarantias = async () => {
    updateHeader();
    return await axios.get<EjecutivoGar []>(`${API}/ejecGarantias` , { headers: headersJson });
}

export const postReporteExpediente = async (reporteExpediente: ReporteExpedienteModel) => {
    updateHeader();
    return await axios.post(`${API}/garantia/reporteExpediente` ,reporteExpediente, { responseType: 'arraybuffer', headers: headersJson });
}

export const getConsultaReporteGarantias = async (idEjecutivo:number,idAgente: number, nombre: string) => {

    updateHeader();
    return await axios.get<{valuesSeleccion:ReporteGarantiasModel[]}>(`${API}/reportesGarantias/items?idAgente=${idAgente}&nombre=${nombre}`, { headers: headersJson });

};

export const getConsultaAgentes = async (idEjecutivo:number,nombre:string) => {
    updateHeader();
    return await axios.get<{valuesSeleccion:ReporteGarantiasModel[]}>(`${API}/reportesGarantias/items?idEjecutivo=${idEjecutivo}&nombre=${nombre}`, { headers: headersJson });

};
export const getConsultaFiados = async (idAgente:number,nomFiado:string) => {
    updateHeader();
    return await axios.get<{valuesSeleccion:ReporteGarantiasModel[]}>(`${API}/reportesGarantias/items?idAgente=${idAgente}&nombre=${nomFiado}`, { headers: headersJson });

};

export const getConsultaReporte = async (fechaInicial:string,fechaFinal:string,idAgente:number,idCliente:number,idEjecutivo:number,page:number,size:number) => {
    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, GarantiasVencidas: ReporteGarantiasReporteModel[] }>(`${API}/reportesGarantias/reporte?fechaFinal=${fechaFinal}&fechaInicial=${fechaInicial}&idAgente=${idAgente}&idCliente=${idCliente}&idEjecutivo=${idEjecutivo}&page=${1}&size=${10}`, { headers: headersJson });
};
export const getConsultaReporteArmado = async (urlArmada:string,page:number,size:number) => {
    console.log("URL EN PETICION: "+urlArmada)
    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, GarantiasVencidas: ReporteGarantiasReporteModel[] }>(`${urlArmada}&page=${page}&size=${size}`, { headers: headersJson });

};
export const getResumen = async (idCliente:number,paginacion: PaginacionModel) => {
    let paginacionQueryParams= '';
    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    updateHeader();
    return await axios.get<{elementos: ResumenGarantias[],paginacion: PaginacionModel}>(`${API}/resumen?${paginacionQueryParams}&idCliente=${idCliente}`, { headers: headersJson });

};

export const getTotalResumen = async (idCliente:number) => {
    updateHeader();
    return await axios.get<ResumenGarantias>(`${API}/total?idCliente=${idCliente}`, { headers: headersJson });

};
export const getProductosLineaAfianzamiento = async (idCliente:number, ramos:  Ramos[]) => {
    updateHeader();
    let queryParam = '';
    for (const ramo of ramos){
        queryParam+='&idRamo='+ ramo.idRamo;
    }

    return await axios.get<ProductoLineaAfianzamiento[]>(`${API}/lineaAfianzamiento/productos?idCliente=${idCliente}${queryParam}`, { headers: headersJson });
};
//
export const getLineaAfianzamiento = async (idCliente:number) => {
    updateHeader();
    return await axios.get<LineaAfinzamiento[]>(`${API}/lineaAfianzamiento/${idCliente}`, { headers: headersJson });
};
export const posNuevaLineaAfianzamiento = async (nuevaLineaAfianzamiento: any) => {
    updateHeader();
    return await axios.post<LineaAfinzamiento>(`${API}/lineaAfianzamiento`, nuevaLineaAfianzamiento,{ headers: headersJson });
};

export const deleteLineaAfianzamiento = async (idLineaAf:number) => {
    updateHeader();
    return await axios.delete<{mensaje: string}>(`${API}/lineaAfianzamiento/?idLineaAf=${idLineaAf}`, { headers: headersJson });
};

export const putLineaAfianzamiento = async (nuevaLineaAfianzamiento:LineaAfinzamiento) => {
    updateHeader();
    return await axios.put<LineaAfinzamiento>(`${API}/lineaAfianzamiento?idLineaAf=${nuevaLineaAfianzamiento.idLineaAf}`, nuevaLineaAfianzamiento, { headers: headersJson });
};

export const postRenovacionLineaAfianzamiento = async (nuevaLineaAfianzamiento:LineaAfinzamiento) => {
    updateHeader();
    return await axios.post<LineaAfinzamiento>(`${API}/lineaAfianzamiento/renovacion?idLineaAf=${nuevaLineaAfianzamiento.idLineaAf}`, nuevaLineaAfianzamiento, { headers: headersJson });
};

export const getCumuloResponsabilidades = async (idCliente:number) => {
    updateHeader();
    return await axios.get<any>(`${API}/reportesGarantias/cumuloResponsabilidades?idCliente=${idCliente}`, { responseType: 'arraybuffer', headers: headersJson });
};

export const getCatalogoRamos = async () => {
    updateHeader();
    return await axios.get<Ramos []>(`${API}/catalogo/ramos`, { headers: headersJson });
};
export const getHistorial = async (folio: number, page: number, size: number) => {
    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, elementos: HistorialModel[]}>(`${API}/historial/${folio}?page=${page}&size=${size}`, { headers: headersJson });
};

export const getCompararHistorial = async (idGarantia1: number, idGarantia2: number) => {
    updateHeader();
    return await axios.get<ComparacionGarantias[]>(`${API}/historial/comparar?idGarantias=${idGarantia1}&idGarantias=${idGarantia2}`, { headers: headersJson });
};