import {UserModel} from "../models/public/UserModel";
import {initPerfil, initListLanguages} from "./CommonClass";
import {LanguageModel} from "../models/public/LanguageModel";
import {directionModal} from "../models/public/DirectionModal";

export class LocalStorageClass {
    public DIRMODAL = 'DIRMODAL';
    // language
    public LANGUAGESELECT = 'LANGUAGESELECT';
    // key Name
    public PERFIL = 'PERFIL';

    // Garantias
    public EJECUTIVODEGARANTIAS = 'EJECUTIVO_GARANTIAS';
    public AGENTE = 'AGENTE';
    public AUDITOR = 'AUDITOR';
    //public AGENTE = 'AGENTE_COMPROMISOS';
    // Compromisos
    public EJECUTIVOCUENTA = 'EJECUTIVO_CUENTA';
    public FUNCIONARIO = 'FUNCIONARIO';
    public PROMOTOR = 'PROMOTOR';
    // buro de crádito
    public BURO = 'BURO';

    // Polizas RH
    public USUARIOCONTABLE = 'USUARIO_CONTABLE';
    //TESORERIA
    public CAJERO = 'CAJERO';

    //Juridico
    public AUXILIAR_JURIDICO = 'AUXILIAR_JURIDICO';

    constructor() {
    }

    public setSomeString(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public getSomeString(key: string): string {
        return <string>localStorage.getItem(key);
    }

    public setSomeBoolean(key: string, value: boolean): void {
        localStorage.setItem(key,  value + '');
    }


    public  getSomeBoolean(key: string): boolean {
        if (localStorage.getItem(key) == 'true'){
            return true;
        }
        return  false;

    }

    public getProfile(): UserModel {
        let USER: UserModel = initPerfil;
        if (this.getSomeString(this.PERFIL)){
            USER = JSON.parse(this.getSomeString(this.PERFIL))
            return USER
        }else{
            return  initPerfil;
        }

    }


    public getProfileToken(): string {
        let USER: UserModel = initPerfil

        if (this.getSomeString(this.PERFIL)){
            USER = JSON.parse(this.getSomeString(this.PERFIL))

            if (USER.accessToken){
                return  USER.accessToken
            }else{
                return '';
            }

        }else{
            return  '';
        }
    }

    public getLanguageId(): string {
        let LANGUAGE: LanguageModel = initListLanguages[0];

        if (this.getSomeString(this.LANGUAGESELECT)){
            LANGUAGE = JSON.parse(this.getSomeString(this.LANGUAGESELECT))

            if (LANGUAGE.idLenguaje){
                return  String(LANGUAGE.idLenguaje);
            }else{
                return '';
            }

        }else{
            return  String(LANGUAGE.idLenguaje);
        }
    }

    public getLanguageCode(): string {
        let LANGUAGE: LanguageModel = initListLanguages[0];

        if (this.getSomeString(this.LANGUAGESELECT)){
            LANGUAGE = JSON.parse(this.getSomeString(this.LANGUAGESELECT))

            if (LANGUAGE.idLenguaje){
                return  String(LANGUAGE.lang);
            }else{
                return '';
            }

        }else{
            return  String(LANGUAGE.lang);
        }
    }


    public getDirModal(): string {

        if (this.getSomeString(this.DIRMODAL)){
            return  this.getSomeString(this.DIRMODAL);

        }else{
            return directionModal.left;
        }
    }


}

