export interface RequestsLanguageModel {
    titleRequest:string,
    folio:string,
    name:string,
    type:string,
    agent:string,
    date:string,
    status:string,
    consult:string,
    revison:string,
    autorizada:string,
    rechazada:string,
    alert:string
    }
    
    export const RequestsLanguageEnglish: RequestsLanguageModel ={
    titleRequest:'Consultation Requests',
    folio:'Invoice',
    name:'Name',
    type:'Type',
    agent:'Agent',
    date:'Date',
    status:'Status',
    consult:'Consult',
    revison:'In Review',
    autorizada:'Authorized',
    rechazada:'Rejected',
    alert:'No queries available'
    
    }
    
    export const RequestsLanguageSpanish: RequestsLanguageModel ={
    titleRequest:'Solicitudes de Consulta',
    folio:'Folio',
    name:'Nombre',
    type:'Tipo',
    agent:'Agente',
    date:'Fecha',
    status:'Estatus',
    consult:'Consulta',
    revison:'En revisión',
    autorizada:'Autorizada',
    rechazada:'Rechazada',
    alert:'No hay consultas disponibles'
    }